<template>
  <el-collapse-item
    class="accordion-item"
    :name="accordionData.name"
    :disabled="disabledState"
  >
    <template slot="title">
      <div class="accordion-title">
        <div class="title" :class="{ 'is-disabled': accordionData.disabled }">
          {{ accordionData.title }}
        </div>
        <div
          class="description"
          :class="{ 'is-disabled': accordionData.disabled }"
        >
          {{ accordionData.description }}
        </div>
      </div>
    </template>
    <slot name="content" />
    <slot name="footer" />
  </el-collapse-item>
</template>

<script>
export default {
  props: {
    accordionData: {
      type: Object | Array,
      default: () => {},
    },
    disabledState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emails: [],
    }
  },
}
</script>

<style lang="scss">
.accordion-item {
  .delivery-option {
    .el-select.pl-12 {
      padding: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.is-disabled {
  opacity: 0.5;
}
.title {
  width: 100%;
  line-height: 30px;
  font-weight: 600;
  font-size: 16px;
  color: #2d2d2d;
}
.description {
  font-size: 14px;
  line-height: 18px;
  color: #2d2d2d;
}

.form-margin {
  margin: 32px 0;
}
.accordion-title {
  margin: 8px 0;
}
</style>
