<template lang="pug">
.intro
  .intro-step-container
    .logo-container
      img(src="../../assets/text-logo.svg")
    section.intro-step(v-hammer:swipe='onSwipe')
      .intro-content
        .intro-icon.first
          i.icon.icon-lamp.desktop
          i.icon.icon-lamp-m.tablet
        h2.intro-title Constellation’s web-based customer service platform, Energy Manager, puts energy management at your fingertips 24/7.
        ul.intro-text.ul-text
          li Single, Secure Login—Direct access to your electricity and natural gas information online.
          li Transaction History—View invoices and payment history anywhere.
        p.intro-text You can access Constellation Energy Manager at
          span &nbsp;
          a(href="https://energymanager.constellation.com") https://energymanager.constellation.com
        p.intro-text You can use your Constellation Energy Manager account to access Pear.ai without a separate login.
        el-button.button-back(@click="goBack") Back
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/utilities';

.intro {
  margin-top: 84px;

  @include respond-to($phone) {
    background-position: 40px 30px;
    background-size: 87px 34px;
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 82px;

    @include respond-to($phone) {
      margin-bottom: 0;
    }
  }

  &-step {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &-content {
    padding-left: 60%;
    padding-right: 100px;

    @include respond-to($tablet-l) {
      padding-left: 50%;
      padding-right: 100px;
      padding-top: 0;
      margin-top: 0;
      padding-bottom: 80px;
    }
    @include respond-to($tablet-p) {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: flex-start;
      height: 100%;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 0;
      margin-top: 0;
    }
  }
  &-icon {
    position: absolute;
    left: -68px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 709px;
    width: 709px;
    border-radius: 50%;
    box-sizing: border-box;
    z-index: -1;
    transform: translateY(-15%);

    @include respond-to($tablet-l) {
      width: 509px;
      height: 509px;
    }
    @include respond-to($tablet-p) {
      position: static;
      flex: none;
      display: flex;
      align-items: flex-end;
      width: auto;
      height: 200px;
      padding-bottom: 20px;
      margin: 0;
      background-image: none !important;
      transform: none;
      margin-bottom: 30px;
    }
    .icon {
      margin: 0;
    }
    &.first {
      background-image: linear-gradient(279.67deg, #ffe031 0%, #dbb917 100%);
    }
  }
  &-title {
    margin: 0 0 32px;
    color: #333333;
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;

    @include respond-to($phone) {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: normal;
    }
  }
  &-text {
    margin: 0;
    color: #626262;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 40px;

    a {
      color: #0080ff;
    }

    &.ul-text {
      padding-left: 18px;
    }

    @include respond-to($phone) {
      font-size: 14px;
      line-height: normal;
      font-weight: 500;
    }
  }
}
.button-back {
  padding: 0;
  width: 100px;
  height: 32px;
  background: #0080ff;
  border-radius: 16px;
  font-weight: 800;
  font-size: 13px;
  line-height: 18px;
  text-align: center;

  color: #ffffff;
}
.desktop {
  @include respond-to($tablet-p) {
    display: none;
  }
}
.tablet {
  display: none;
  @include respond-to($tablet-p) {
    display: block;
  }
}
</style>
