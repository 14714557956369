<template lang="pug">
.filter-item.filter-item-location
  .filter-title
    span(v-if="title") {{ title }}
  .select-box
    select(v-model="type")
      option(v-for="item in options",
            :key="item.value",
            :value="item.value") {{ item.label }}
    i.el-select__caret.el-input__icon.el-icon-arrow-down

  select-location(:is-visible="isLocationModalVisible",
                :selectedLocations="selectedLocations",
                @onChange="selectLocations",
                @close="isLocationModalVisible = false")
  .selected-locations(v-if="type === 'custom'", @click="isLocationModalVisible = true")
    template(v-if="selectedLocations.length")
      span(v-for="(location, index) in selectedLocations", v-if="index <= maxShownLocations")
        | {{ index < selectedLocations.maxShownLocations ? `${location.address}, ` : `${location.address}` }}
        | {{ index === maxShownLocations ? '...' : '' }}
      br
      br
      p
        span Tap on window to change locations.
    template(v-else)
      p Any locations selected
        span Tap on window to select locations.
</template>

<script>
import SelectLocation from '@/pages/Dashboard/Dashboard/Controls/SelectLocation.vue'
import _ from 'lodash'

export default {
  components: {
    SelectLocation,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    maxShownLocations: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      isLocationModalVisible: false,
      selectedLocations: [],
      type: this.value.length ? 'custom' : 'all',
      options: [
        {
          label: 'All locations',
          value: 'all',
        },
        {
          label: 'Select custom locations',
          value: 'custom',
        },
      ],
    }
  },
  watch: {
    type(val) {
      if (val === 'all') {
        this.selectLocations([])
      }
    },
  },
  mounted() {
    this.setSelectedLocations()
  },
  beforeMount() {
    const { locations, value } = this
    if (!_.isEmpty(locations) && !_.isEmpty(value)) {
      this.selectedLocations = value.map(
        (id) => locations.find((l) => parseInt(l.id) === id) || {},
      )
    }
  },
  methods: {
    setSelectedLocations() {
      const locations = this.$store.getters['locations/locations']
      this.selectedLocations = this.value.map(
        (id) => locations.find((l) => parseInt(l.id) === id) || {},
      )
    },
    selectLocations(val) {
      this.filter.location_ids = val.map((e) => Number(e.id))
      this.selectedLocations = val
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../../styles/_utilities';

.selected-locations {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 200px;
  padding: 14px;
  margin-top: 10px;
  color: #606266;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  background-color: #fff;
  overflow-y: auto;

  p {
    margin: 0;
    font-size: 14px;
    line-height: 21px;

    span {
      display: block;
      color: #2893f9;
    }
  }
  .icon {
    position: absolute;
    top: 5px;
    left: 5px;
    margin-right: 0;
  }
}
.select-box {
  position: relative;
  width: 100%;

  select {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 36px;
    padding: 0 20px;
    color: #909399;
    font-size: 16px;
    line-height: 36px;
    border-radius: 10px;
    border: 1px solid #dcdfe6;
    background-color: #fff;
    box-sizing: border-box;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    appearance: none;
    outline: none;

    &.show-label {
      opacity: 0;

      &:focus {
        opacity: 1;

        ~ label {
          opacity: 0;
        }
      }
    }
  }
  i {
    position: absolute;
    top: 0;
    right: 5px;
    color: #c0c4cc;
  }
}
</style>

<style lang="scss">
@import '../../../../../styles/_utilities';

.location-control_list {
  @include respond-to($phone) {
    max-height: calc(100vh - 320px) !important;
    padding-bottom: 0 !important;
  }
  @include respond-to($phone-l) {
    max-height: calc(100vh - 320px) !important;
    padding-bottom: 0 !important;
  }
}
</style>
