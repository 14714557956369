<template>
  <div class="filter">
    <div class="filter-container">
      <div class="filter-item query">
        <div class="filter-title">Search</div>
        <el-input
          class="filter-item-inner query"
          v-model="filters.query"
          prefix-icon="el-icon-search"
          :placeholder="'Search by Billing ID, SAID'"
          data-testid="searchFilter"
        />
      </div>
      <div class="filter-item filter-item-container">
        <div class="filter-title">Invoice Date</div>
        <div class="filter-item-inner invoice-date">
          <el-date-picker
            v-model="statementDateMin"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="Start date"
            data-testid="invoiceStartDate"
          />
          <span class="to">to</span>
          <el-date-picker
            v-model="statementDateMax"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="End date"
            data-testid="invoiceEndDate"
          />
        </div>
      </div>
      <div class="filter-item">
        <div class="filter-title">Vendor</div>
        <div class="filter-item-inner">
          <el-select
            style=""
            :value="filters.vendorCodes"
            @change="changeVendorCodes"
            multiple
            filterable
            clearable
            placeholder="Select"
            data-testid="vendorFilter"
          >
            <el-option
              class="vendor-options"
              v-for="vendorCode in filtersData.vendorCodes"
              :key="vendorCode.pretty_name"
              :title="vendorCode.pretty_name"
              :label="vendorCode.pretty_name"
              :value="JSON.stringify(vendorCode.codes)"
              data-testid="vendorFilterItem"
            />
          </el-select>
        </div>
      </div>
      <div class="filter-item">
        <div class="filter-title">Location</div>
        <div class="filter-item-inner">
          <el-select
            :value="filters.locationIds"
            @change="changeLocations"
            multiple
            filterable
            clearable
            placeholder="Select"
            data-testid="locationFilter"
          >
            <el-option
              v-for="location in filtersData.locationIds"
              :key="`${location.name}-${location.id}`"
              :label="location.name"
              :value="String(location.id)"
              data-testid="locationFilterItem"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="filter-item">
        <div class="filter-title">Cost Range</div>
        <div class="filter-item-inner cost-range">
          <el-input
            v-model="filters.amountDueMin"
            type="number"
            :placeholder="'Min'"
            data-testid="costRangeMin"
          />
          <span class="to">to</span>
          <el-input
            v-model="filters.amountDueMax"
            type="number"
            :placeholder="'Max'"
            data-testid="costRangeMax"
          />
        </div>
      </div>
      <div class="filter-item">
        <div class="filter-title">Commodity</div>
        <div class="filter-item-inner">
          <el-select
            :value="filters.commodities"
            @change="changeCommodities"
            multiple
            filterable
            clearable
            placeholder="Select"
            data-testid="commodityFilter"
          >
            <el-option
              v-for="commodity in commodities"
              :key="commodity"
              :value="commodity"
              data-testid="commodityFilterItem"
            />
          </el-select>
        </div>
      </div>
      <div class="filter-item">
        <div class="filter-title">Billing Line Item description</div>
        <el-input
          class="filter-item-inner query"
          v-model="filters.billingLineItemDescription"
          placeholder="Search"
          prefix-icon="el-icon-search"
          data-testid="searchFilter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, throttle } from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  created() {
    this.debounceChangeFilters = debounce(this.$changeFilters, 500)
    this.throttledChangeFilters = throttle(this.$changeFilters, 500)
  },
  watch: {
    'filters.query'(value) {
      this.setExclude(this.excludeArr)
      this.debounceChangeFilters({
        searchQuery: value === '' ? null : value,
        offset: 0,
      })
    },
    'filters.billingLineItemDescription'(value) {
      this.setExclude(this.excludeArr)
      this.debounceChangeFilters({
        billingLineItemDescription: value === '' ? null : value,
        offset: 0,
      })
    },
    'filters.amountDueMin'(value) {
      this.setExclude(this.excludeArr)
      this.debounceChangeFilters({
        amountDueMin: value === '' ? null : value,
        offset: 0,
      })
    },
    'filters.amountDueMax'(value) {
      this.setExclude(this.excludeArr)
      this.debounceChangeFilters({
        amountDueMax: value === '' ? null : value,
        offset: 0,
      })
    },
  },
  data() {
    return {
      excludeArr: ['extra', 'filters'],
    }
  },
  computed: {
    ...mapGetters({
      filtersData: 'bills/filtersData',
      filters: 'bills/filters/filters',
    }),
    statementDateMin: {
      get() {
        return this.filters.statementDateMin
      },
      set(value) {
        this.setExclude(this.excludeArr)
        const statementDateMinFromVal = value
        this.$changeFilters({
          statementDateMin: statementDateMinFromVal,
          offset: 0,
        })
      },
    },
    statementDateMax: {
      get() {
        return this.filters.statementDateMax
      },
      set(value) {
        this.setExclude(this.excludeArr)
        const statementDateMaxFromVal = value
        this.$changeFilters({
          statementDateMax: statementDateMaxFromVal,
          offset: 0,
        })
      },
    },
    commodities() {
      if (this.filtersData && this.filtersData.commodities) {
        const commodities = this.filtersData.commodities
        commodities.sort((x, y) => (x < y ? -1 : 1))
        commodities.sort((x, y) =>
          x === 'NATURALGAS' ? -1 : y === 'NATURALGAS' ? 1 : 0,
        )
        commodities.sort((x, y) =>
          x === 'ELECTRIC' ? -1 : y === 'ELECTRIC' ? 1 : 0,
        )
        return commodities
      }
    },
  },
  methods: {
    ...mapActions({
      $changeFilters: 'bills/filters/changeFilters',
    }),
    ...mapMutations({
      setExclude: 'bills/set_exclude',
    }),
    changeLocations(value) {
      this.setExclude(this.excludeArr)
      this.throttledChangeFilters({ locationIds: [...value], offset: 0 })
    },
    changeCommodities(value) {
      this.setExclude(this.excludeArr)
      this.$changeFilters({ commodities: [...value], offset: 0 })
    },
    changeVendorCodes(value) {
      this.setExclude(this.excludeArr)
      const vendorList = value.reduce((reduced, currentVendorCode) => {
        if (
          currentVendorCode[0] === '[' &&
          currentVendorCode[currentVendorCode.length - 1] === ']'
        ) {
          currentVendorCode = JSON.parse(currentVendorCode)
          reduced.push(...currentVendorCode)
        } else {
          // is just a string not an array
          reduced.push(currentVendorCode)
        }
        return reduced
      }, [])

      // new Set(vendorList) this removes duplicates from the array (in case you add a vendor with multiple codes twice)
      this.$changeFilters({ vendorCodes: [...new Set(vendorList)], offset: 0 })
    },
  },
}
</script>
<style lang="scss" scoped>
.filter {
  .filter-container {
    .to {
      font-size: 14px;
      color: #7d89a6;
    }
    .query {
      width: 180px;
      ::v-deep .el-input {
        &__inner {
          padding-left: 28px;
          padding-right: 8px;
        }
        &__prefix {
          left: 0;
        }
      }
    }
    .invoice-date {
      ::v-deep .el-input {
        width: 120px;
        font-size: 12px;
        &__prefix {
          left: 4px;
        }
        &__inner {
          padding: 0 28px;
        }
      }
    }
    .cost-range {
      ::v-deep .el-input {
        &__inner {
          padding-right: 0;
        }
      }
    }
  }
}
.vendor-options {
  max-width: 200px;
}
</style>
