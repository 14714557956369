import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'
import { hosts } from '@/lib/api.configs'
import qs from 'qs'

export const getScheduledReports = (customerId, params) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/reports/scheduled?${qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
    )
    .then(dataMiddleware)

export const updateScheduledReports = (customerId, scheduledReportId, body) =>
  http(hosts.v3)
    .put(
      `/customers/${customerId}/reports/scheduled/${scheduledReportId}`,
      body,
    )
    .then(dataMiddleware)

export const deleteScheduledReports = (customerId, scheduledReportId) =>
  http(hosts.v3)
    .delete(`/customers/${customerId}/reports/scheduled/${scheduledReportId}`)
    .then(dataMiddleware)

export const runNowScheduledReports = (customerId, scheduledReportId) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/reports/scheduled/${scheduledReportId}/run`)
    .then(dataMiddleware)
