<template>
  <div v-loading="isLoading">
    <h2>Choose the type of budget you want to create</h2>
    <el-collapse v-model="activeCollapseItem" accordion>
      <accordion-item
        v-for="budgetingTemplate in budgetingTemplates"
        :key="budgetingTemplate.readableId"
        :id="`accordion_item_${budgetingTemplate.readableId}`"
        :accordionData="budgetingTemplate"
        :disabledState="!budgetingTemplate.isEnabled"
        class="reports-accordion-item"
      >
        <template #content>
          <new-budget-creator-form
            :budgeting-template="budgetingTemplate"
            :is-on-create-budget-tab="true"
          />
        </template>
      </accordion-item>
    </el-collapse>
    <el-pagination
      class="pagination-container"
      background
      layout="prev, pager, next, sizes"
      v-if="
        (total > pagination.pageSizes[0] && pagination.offset === 0) ||
        pagination.offset !== 0
      "
      :page-size="+pagination.limit"
      :page-sizes="pagination.pageSizes"
      :total="total"
      :current-page="currentPage"
      @current-change="changeOffset"
      @size-change="changeLimit"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AccordionItem from '@/components/AccordionItem'
import NewBudgetCreatorForm from '../Modals/Components/NewBudgetCreatorForm'

export default {
  components: { NewBudgetCreatorForm, AccordionItem },
  watch: {
    pagination: {
      async handler(pag) {
        const { limit, offset } = pag
        const { customerId } = this.$route.params
        this.addRouteParams(pag)
        await this.getBudgetingTemplates({
          customerId,
          params: { limit, offset },
        })
      },
      deep: true,
    },
  },
  data() {
    return {
      activeCollapseItem: '',
    }
  },
  beforeDestroy() {
    this.activeCollapseItem = ''
  },
  computed: {
    ...mapGetters({
      budgetingTemplates: 'createBudgeting/budgetingTemplates',
      isLoading: 'createBudgeting/isLoading',
      pagination: 'createBudgeting/pagination',
      currentPage: 'createBudgeting/currentPage',
      total: 'createBudgeting/total',
    }),
  },
  methods: {
    ...mapActions({
      changePagination: 'createBudgeting/changePagination',
      getBudgetingTemplates: 'createBudgeting/getBudgetingTemplates',
    }),
    changeLimit(limit) {
      this.changePagination({ limit, offset: 0 })
    },
    changeOffset(page) {
      const offset = (page - 1) * this.pagination.limit
      this.changePagination({ offset })
    },
    addRouteParams({ limit, offset }) {
      this.$router.push({
        query: {
          limit,
          offset,
        },
      })
    },
  },
}
</script>
