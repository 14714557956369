import { completedReports } from '@/api'
import * as _ from 'lodash'

const initialState = () => {
  return {
    reports: [],
    totalResults: null,
    params: null,
    exclude: [],
    filters: {
      formats: null,
      types: null,
    },
    pagination: {
      limit: 10,
      offset: 0,
      pageSizes: [10, 20, 30, 40, 100],
    },
    loading: true,
  }
}

export default {
  namespaced: true,
  state: initialState(),

  getters: {
    reports: (state) => state.reports.data && state.reports.data,
    filters: (state) => _.get(state, 'reports.filters', null) || state.filters,
    totalResults: (state) => state.totalResults && state.totalResults,
    pagination: (state) => state.pagination,
    currentPage: (state) =>
      state.pagination.offset / state.pagination.limit + 1,
    loading: (state) => state.loading,
  },

  mutations: {
    setReports(state, payload) {
      state.reports = payload
      if (state.exclude.length === 0) {
        state.filters.types = _.get(state, 'reports.filters.types', null)
        state.filters.formats = _.get(state, 'reports.filters.formats', null)
        state.exclude = ['filters', 'extra']
      }
    },
    setTotalResults(state, payload) {
      state.totalResults = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    reset({ commit }) {
      commit('resetState')
    },
    async getCompletedReports({ commit, state }, { customerId, params = {} }) {
      try {
        params.exclude = state.exclude
        const reports = await completedReports.getReports({
          customerId,
          params,
        })
        await commit('setTotalResults', Number(reports.totalResults))
        commit('setReports', reports)
        commit('setLoading', false)
      } catch (e) {
        console.log(e)
        commit('setLoading', false)
      }
    },
  },
}
