<template>
  <ValidationProvider
    class="base-form-component"
    :vid="vid"
    :name="$attrs.name"
    :mode="$attrs.mode"
    :immediate="immediate"
    :rules="rules"
    v-slot="{ flags, errors, validate }"
  >
    <el-form-item
      :error="isEditMode ? errors[0] : ''"
      :label="'temp'"
      :label-width="labelWidth"
    >
      <span slot="label">
        {{ $attrs.label }}
        <el-popover
          width="300"
          v-if="$attrs.icon"
          trigger="hover"
          :content="$attrs.content"
          placement="top"
        >
          <i
            class="color"
            slot="reference"
            v-if="$attrs.icon"
            :class="$attrs.icon"
          />
        </el-popover>
      </span>
      <slot :flags="flags" :validate="validate" />
    </el-form-item>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: String,
    },
  },
}
</script>
<style lang="scss" scoped>
.base-form-component {
  .el-form-item {
    ::v-deep .el-form-item__error {
      padding-left: 14px;
      position: static;
    }
    ::v-deep .el-form-item__label {
      line-height: normal;
      font-size: 14px;
      word-break: break-word;
    }

    ::v-deep .el-form-item__content {
      line-height: normal;
    }

    .color {
      color: #0080ff;
    }
  }
}
</style>
