<template lang="pug">
  .month-control.control(v-if="widgetIsVisible")
    .control-buttons(v-if='!isMonthPickerVisible', :class="{'control-buttons-ipad': isIpad}")
      el-button.button-control(v-if="months[months.length - 1]",
                              size='medium',
                              round,
                              data-action='choose-first-month',
                              @click="selectTimePeriod(convertTitleQueryDate(months[months.length - 1]))") {{ convertTitleQueryDate(months[months.length - 1]) }}
      el-button.button-control(v-if="months[months.length - 2]", size='medium', round, @click="selectTimePeriod(convertTitleQueryDate(months[months.length - 2]))") {{ convertTitleQueryDate(months[months.length - 2]) }}
      el-button.button-control(size='medium', round, @click='showMonthPicker') Choose the month
    .month-picker(v-else)
      .year-select
        el-button(type='text', icon='el-icon-arrow-left', @click='prevYear')
        span {{ year }}
        el-button(type='text', icon='el-icon-arrow-right', @click='nextYear')
      el-button(v-for='(month, index) in ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]',
                :key="index",
                :disabled="getStatus(index + 1) ? false : true",
                round,
                @click='selectTimePeriod(convertQueryDate(index + 1))') {{ month }}
</template>

<script>
import { DateTime } from 'luxon'
import { mapGetters, mapState } from 'vuex'
import scrollToBottom from '@/mixins/scrollToBottom'
import * as R from 'ramda'

export default {
  mixins: [scrollToBottom],
  props: {
    monthMessage: {
      type: Object,
    },
  },
  data() {
    return {
      widgetIsVisible: false,
      isMonthPickerVisible: false,
      year: new Date().getFullYear(),
      month: '',
      months: [],
    }
  },
  mounted() {
    this.getMonths()
    this.year = this.searchYear()
  },
  computed: {
    ...mapGetters({ location: 'chat/getLocation' }),
    ...mapState({
      customer_id: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    isIpad() {
      return this.$deviceInfo.deviceOS === 'ipad'
    },
  },
  methods: {
    getMonths() {
      this.months = this.monthMessage.payload.data
      this.widgetIsVisible = true
      this.scrollOnBottom = true
    },
    searchYear() {
      const tmp = this.months.slice().sort().reverse()[0]
      if (!tmp) return 2018
      if (DateTime.fromSQL(tmp).toFormat('yyyy') === 'Invalid DateTime') { return 2018 }
      return DateTime.fromSQL(tmp).toFormat('yyyy')
    },
    convertTitleQueryDate(month) {
      return DateTime.fromSQL(month).toFormat('LLLL yyyy')
    },
    convertQueryDate(month) {
      return DateTime.fromFormat(
        `${month < 10 ? '0' : ''}${month}-${this.year}`,
        'MM-yyyy',
      ).toFormat('LLLL yyyy')
    },
    getStatus(month) {
      return (
        this.months.indexOf(
          `${this.year}-${month < 10 ? '0' : ''}${month}-01`,
        ) >= 0
      )
    },
    showMonthPicker() {
      this.$emit('openCalendar')
      this.isMonthPickerVisible = true
      this.scrollOnBottom = true
    },
    prevYear() {
      this.year--
    },
    nextYear() {
      let currentYear = new Date().getFullYear()
      if (this.year !== currentYear) {
        this.year++
      }
    },
    selectTimePeriod(message) {
      this.$emit('selectTimePeriod', message)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../../styles/_utilities';

.month-control {
  overflow-x: auto;
}

.control-buttons .el-button {
  min-width: auto;
  max-width: auto;
}

.control-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .button-control {
    border-radius: 40px;
  }

  .el-button {
    @include respond-to($phone) {
      max-width: 100%;
      margin-right: 0;
    }
  }
}

.month-picker {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 340px;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  border: 1px solid #dde5e6;
  margin-left: 63px;
  border-top-left-radius: 0;

  @include respond-to($phone) {
    width: 100%;
    max-width: 340px;
    border-top-left-radius: 10px;
    margin-left: 0;
  }

  > .el-button {
    width: 70px;
    padding: 6px 0;
    margin: 15px 0 0;
    font-size: 10px;

    &:not(.is-disabled) {
      color: #2893f9;
      border-color: #7d89a6;

      &:hover {
        color: #fff;
        border-color: #2893f9;
        background-color: #2893f9;
      }
    }
    &.is-disabled {
      color: #7d89a6;
      border-color: #dcdfe6;
    }
  }
}
.year-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  color: #222222;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #dcdfe6;

  .el-button {
    margin: 0;
    color: #979797;
  }
}
</style>
