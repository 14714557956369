export default {
  beforeMount() {
    this.checkie()
  },
  methods: {
    checkie() {
      if (
        window.navigator.userAgent.indexOf('MSIE ') > 0 ||
        // eslint-disable-next-line no-useless-escape
        !!navigator.userAgent.match(/Trident.*rv\:11\./)
      ) {
        this.$deviceInfo.isIe = true
      } else {
        this.$deviceInfo.isIe = false
      }
    },
  },
}
