import Login from './Login'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import ActivateAccount from './ActivateAccount.vue'
import Onboarding from './Onboarding.vue'
import Settings from './Settings.vue'
import AzureAD from './AzureAD.vue'
import LearnMore from './LearnMore.vue'

export default {
  path: '/auth',
  meta: {
    auth: false,
  },
  redirect: () => `/auth/login`,
  component: {
    template: `<router-view></router-view>`,
  },
  children: [
    {
      path: 'login',
      name: 'login',
      component: Login,
      meta: { auth: false },
    },
    {
      path: 'forgot',
      name: 'forgot',
      component: ForgotPassword,
      meta: { auth: false },
    },
    {
      path: 'learn-more',
      name: 'learn-more',
      component: LearnMore,
      meta: { auth: false },
    },
    {
      path: 'reset/:token',
      component: ResetPassword,
      meta: { auth: false },
    },
    {
      path: 'activate',
      component: ActivateAccount,
      meta: { auth: false },
    },
    {
      path: 'settings',
      name: 'settings',
      component: Settings,
      meta: { auth: true },
    },
    {
      path: 'onboarding',
      name: 'onboarding',
      component: Onboarding,
      meta: { auth: true },
    },
    {
      path: 'azure-ad',
      name: 'azure-ad',
      component: AzureAD,
      meta: { auth: false },
    },
  ],
}
