import { http } from '@/lib'
import { dataMiddleware } from '../lib/http'
import { hosts } from '@/lib/api.configs'

async function sendEventToChatbase(customerId, property, value) {
  return http(hosts.v3)
    .post(`/customers/${customerId}/sam/statsChatbase`, {
      property: property,
      value: value,
    })
    .then(dataMiddleware)
}

export { sendEventToChatbase }
