<template>
  <validation-observer ref="deliveryMethods">
    <el-form class="delivery-option" label-position="top">
      <base-select-multiple
        ref="selectDeliveryMethods"
        placeholder="Select"
        :label="report.formBody.deliveryMethods.label"
        :ordered="true"
        v-model="deliveryMethods"
        :isEditMode="isEditMode"
        clearable
        size="medium"
        icon="el-icon-info"
        :content="deliveryMethodMsg"
        :multiple="true"
      >
        <el-option
          v-for="(type, index) in report.formBody.deliveryMethods.types"
          :key="index"
          :value="type"
          :disabled="disableSftpOption(type)"
        />
      </base-select-multiple>
      <input-with-tags
        v-show="hasEmailDelivery"
        ref="emailTagsInput"
        placeholder="Enter email address"
        :label="report.formBody.emailRecipients.label"
        :value="newEmailRecipient"
        @update-email-recipients="updateEmailRecipients"
        :isEditMode="isEditMode"
        name="email"
        size="medium"
        :rules="requireEmailRecipients"
        :arrayOfTags="emailRecipients"
        @emit-delete="handleDeleteEmail"
      />
      <base-select
        v-show="hasSftpDelivery"
        name="Sftp Address"
        ref="sftpSelect"
        :rules="requireSftpDelivery"
        :label="report.formBody.sftpConnection.label"
        :ordered="true"
        :isEditMode="isEditMode"
        size="medium"
        clearable
        :value="sftpConnectionId"
        @input="updateSftpConnectionId"
      >
        <el-option
          class="options"
          v-for="(connection, index) in report.formBody.sftpConnection.list"
          :key="index"
          :value="connection.id"
          :label="connection.name"
        >
          <div class="sftp-options-template">
            <span class="sftp-name">{{ connection.name }}</span>
            <span class="sftp-host-and-folder">
              {{ getConnectionPath(connection) }}
            </span>
          </div>
        </el-option>
      </base-select>
    </el-form>
  </validation-observer>
</template>
<script>
import { mapGetters } from 'vuex'
import Preview from '../Tabs/Create/Preview'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import BaseDatePicker from '@/components/FormComponents/BaseDatePicker'
import InputWithTags from '@/components/FormComponents/InputWithTags'
import DisabledButton from '@/components/Buttons/DisabledButton'
import BaseSelectMultiple from '@/components/FormComponents/BaseSelectMultiple'
import { ValidationObserver } from 'vee-validate'

export default {
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    componentType: {
      type: String,
      default: 'div',
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseSelectMultiple,
    BaseSelect,
    BaseDatePicker,
    InputWithTags,
    DisabledButton,
    Preview,
    ValidationObserver,
  },
  data() {
    return {
      newEmailRecipient: '',
      deliveryMethods: [],
      deliveryMethodMsg:
        'Delivery Method (Email or SFTP) is optional, all completed reports are available for download from the Completed Reports.',
      isEditMode: true,
      sftpConnectionId: null,
      emailRecipients: [],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
      filtersData: 'reports/filtersData/bli_filters',
      previewLoading: 'reports/previewLoading',
      previewMeta: 'reports/previewMeta',
      previewList: 'reports/preview',
      currentCustomerId: 'customers/currentCustomerId',
    }),
    getConnectionPath() {
      return (connection) => `${connection.host}/${connection.folder}`
    },
    disableSftpOption() {
      return (type) =>
        type === 'SFTP' && this.report.formBody.sftpConnection.list.length === 0
    },
    hasEmailDelivery() {
      return this.deliveryMethods.includes('Email')
    },
    hasSftpDelivery() {
      return this.deliveryMethods.includes('SFTP')
    },
    requireSftpDelivery() {
      return this.hasSftpDelivery ? 'required' : ''
    },
    requireEmailRecipients() {
      return this.hasEmailDelivery &&
        this.emailRecipients.length === 0 &&
        !this.$refs.emailTagsInput.inputValue === ''
        ? 'required|email'
        : 'email'
    },
  },
  watch: {
    opened: {
      immediate: true,
      handler(value) {
        this.init()
      },
    },
    deliveryMethods(value) {
      if (!this.hasSftpDelivery) {
        this.sftpConnectionId = null
      }
      if (!this.hasEmailDelivery) {
        this.newEmailRecipient = ''
        this.emailRecipients = []
        this.reset()
      }
      this.$emit('remove-delivery-methods', {
        emailRecipients: this.emailRecipients,
        sftpConnectionId: this.sftpConnectionId,
      })
    },
  },
  methods: {
    init() {
      if (this.report) {
        this.sftpConnectionId = null
        this.emailRecipients = []
        if (
          this.report.sftpConnection &&
          this.report.emailRecipients &&
          this.report.emailRecipients.length
        ) {
          this.deliveryMethods = ['SFTP', 'Email']
          this.sftpConnectionId = this.report.sftpConnection.id
          this.emailRecipients = this.report.emailRecipients
          return
        }
        if (this.report.sftpConnection) {
          this.deliveryMethods = ['SFTP']
          this.sftpConnectionId = this.report.sftpConnection.id
          return
        }
        if (this.report.emailRecipients && this.report.emailRecipients.length) {
          this.deliveryMethods = ['Email']
          this.emailRecipients = this.report.emailRecipients
        }
      }
    },
    reinit() {
      this.$refs.emailTagsInput.reinit()
    },
    reset() {
      this.$refs.emailTagsInput.reset()
    },
    validation() {
      return this.$refs.deliveryMethods.validate()
    },
    handleDeleteEmail(tag) {
      this.emailRecipients.splice(this.emailRecipients.indexOf(tag), 1)
    },
    updateEmailRecipients(e) {
      this.emailRecipients = e
      this.$emit('update-email-recipients', e)
    },
    updateSftpConnectionId(e) {
      this.sftpConnectionId = e
      this.$emit('update-sftp-conn-id', e)
    },
  },
}
</script>
<style lang="scss" scoped>
.sftp-options-template {
  display: flex;
  flex-direction: column;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}
.button {
  font-size: 16px;
  font-weight: 600;
}
.template-form {
  display: flex;
  flex-direction: column;
}

.delivery-option {
  display: inline-flex;
  flex-wrap: wrap;
  span {
    margin-right: 10px;
  }
}

.sftp-name {
  margin: 0;
}

.sftp-host-and-folder {
  margin: 0;
  font-size: 10px;
  line-height: 10px;
}

.options {
  height: 55px;
}

.delivery-option > div {
  flex: 1 1 auto;
  border: 1px red solid;
  text-align: center;
  margin: 5px;
}
</style>
