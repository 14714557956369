<template>
  <el-popover v-bind="$attrs">
    <el-button
      class="pa-0"
      slot="reference"
      :icon="icon"
      :type="type"
      :disabled="disabled"
    >
      <slot name="text" />
    </el-button>
  </el-popover>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.pa-0 {
  padding: 0;
}
</style>
