<template>
  <el-dialog
    :title="title"
    :visible="dialogVisible"
    :close-on-click-modal="closeOnMask"
    @close="close"
    @open="open"
    :width="width"
    :append-to-body="appendToBody"
    :destroy-on-close="destroyOnClose"
    :show-close="showClose"
    :center="center"
  >
    <div>
      <slot name="content" />
    </div>
    <div
      slot="footer"
      :class="[
        isLeft
          ? 'dialog-footer-left'
          : isRight
          ? 'dialog-footer-right'
          : 'dialog-footer-center',
        'dialog-footer',
        $attrs.classes,
      ]"
    >
      <slot name="footer" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
      required: true,
    },
    isLeft: {
      type: Boolean,
      default: false,
    },
    closeOnMask: {
      type: Boolean,
      default: true,
    },
    isRight: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    destroyOnClose: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    open() {
      this.$emit('open')
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  margin-top: 1em;
  margin-bottom: 2em;
  &-center {
    justify-content: center;
  }
  &-right {
    justify-content: flex-end;
  }
  &-left {
    justify-content: space-between;
  }
}
.footer-width {
  width: 65%;
}
</style>
