<template>
  <div
    v-if="!isEditMode"
    @mouseenter="onHover"
    @mousemove="onHover"
    @mouseleave="onLeave"
    class="button-container"
  >
    <el-button :disabled="!isEditMode" type="primary" class="button">
      {{ buttonName }}
    </el-button>
    <div ref="tooltip" class="btn-tooltip" v-show="tooltip">
      <slot />
    </div>
  </div>
  <div v-else class="button-container">
    <el-button class="button" type="primary" v-on="$listeners">
      {{ buttonName }}
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    buttonName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tooltip: false,
    }
  },
  methods: {
    onHover(position) {
      this.tooltip = true
      let x = position.clientX
      let y = position.clientY
      this.$refs.tooltip.style.top = y + 20 + 'px'
      this.$refs.tooltip.style.left = x + 20 + 'px'
    },
    onLeave() {
      this.tooltip = false
    },
  },
}
</script>

<style lang="scss" scoped>
.button-container {
  ::v-deep .el-button[class*='text'] {
    padding: 0;
  }
  .button-size {
    width: 15%;
  }
  .plain-button {
    color: #2893f9;
    border-color: #2893f9;
  }
  .button-container {
    height: 42px;
    margin-top: 0;
    margin-right: 16px;
    .button {
      width: 100%;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .btn-tooltip {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 8px;
    max-width: 250px;
    height: fit-content;
    &-text {
      color: #4a4a4a;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
    }
  }
  .wrapper.el-button {
    display: inline-block;
    padding: 0;
    margin: 0;
    border: none;
  }
}
</style>
