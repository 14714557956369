<template lang="pug">
  .filter-item.filter-item-date
    .filter-title(v-if="title")
      div {{ title }}
      .select-box
        select(v-model="year")
          option(v-for="y in years",
          :key="y",
          :value="y") {{ y }}
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    this.filter.year = +`${_.get(this.value, 'year', new Date().getFullYear())}`
  },
  data() {
    return {
      years: _.range(2014, 2050),
      year: _.get(this.value, 'year', new Date().getFullYear()),
    }
  },
  watch: {
    year(val) {
      this.filter.year = val || new Date().getFullYear()
    },
  },
}
</script>

<style lang="scss" scoped>
.select-box {
  position: relative;
  width: 100%;

  select {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 36px;
    padding: 0 20px;
    color: #909399;
    font-size: 16px;
    line-height: 36px;
    border-radius: 10px;
    border: 1px solid #dcdfe6;
    background-color: #fff;
    box-sizing: border-box;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    appearance: none;
    outline: none;

    &.show-label {
      opacity: 0;

      &:focus {
        opacity: 1;

        ~ label {
          opacity: 0;
        }
      }
    }
  }
  i {
    position: absolute;
    top: 0;
    right: 5px;
    color: #c0c4cc;
  }
}
.month-input-box {
  margin-right: auto;
  display: flex;
  width: 135px;
  height: 36px;
  border: 1px solid #d8dce6;
  border-radius: 10px;

  input {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 36px;
    padding: 0 20px;
    color: #909399;
    font-size: 16px;
    line-height: 36px;
    white-space: nowrap;
    text-align: center;
    appearance: none;
    border: 0;
    background-color: #fff;
    background-image: none;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-sizing: border-box;
    overflow: hidden;
    outline: none;
    z-index: 1;

    + button {
      border-radius: 0 10px 10px 0;
    }
  }
  button {
    flex: none;
    height: 36px;
    width: 36px;
    color: #7b7b7b;
    font-size: 18px;
    border: 0;
    border-radius: 10px 0 0 10px;
    background-color: #f2f6fb;
    outline: none;

    &:active {
      color: #409eff;
    }
  }
}
.date-input-box {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 36px;
  overflow: hidden;

  .placeholder {
    font-size: 16px;
    line-height: 20px;
  }

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    border-radius: 10px;
    background-color: #fff;
  }

  i {
    z-index: 99;
  }

  input {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 36px;
    color: transparent;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    border: 1px solid #d8dce6;
    border-radius: 10px;
    appearance: none;
    background-color: transparent;
    background-image: none;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-sizing: border-box;
    overflow: hidden;
    outline: none;
    z-index: 1;
  }

  i {
    position: relative;
    top: 1px;
    margin-right: 5px;
    color: #909399;
  }
  .placeholder {
    position: absolute;
    top: 3px;
    left: 10px;
    color: #909399;
    font-size: 16px;
  }
  .value {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 38px;
    color: #909399;
    font-size: 16px;
  }
}
.filter-item-inner {
  margin-top: 20px;
}

.filter-title {
  > div {
    margin-bottom: 10px;
  }
}
</style>
