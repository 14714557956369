import { http } from '@/lib'
import { hosts } from '@/lib/api.configs'
import { dataMiddleware } from '@/lib/http'

export function getCustomerCarbonFootprint(customerId) {
  return http(hosts.v3, () => true)
    .get(`/customers/${customerId}/carbon-footprint`)
    .then(dataMiddleware)
}
export function getCustomerSustainabilityAttrs(customerId, locationId) {
  return http(hosts.v3)
    .get(
      `/customers/${customerId}/locations/${locationId}/sustainability-attributes`,
    )
    .then(dataMiddleware)
}
export function createCustomerSustainabilityAttrs(
  customerId,
  locationId,
  body,
) {
  return http(hosts.v3)
    .post(
      `/customers/${customerId}/locations/${locationId}/sustainability-attributes`,
      body,
    )
    .then(dataMiddleware)
}
export function getChatListForCurrentCustomer(customerId) {
  return http(hosts.v3).get(`customers/${customerId}/chat-tags`)
}
