import { hosts } from '@/lib/api.configs'
import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'

const list = (customerId, locationId, startDate, endDate) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/locations/${locationId}/weather?startDate=${startDate}&endDate=${endDate}`,
    )
    .then(dataMiddleware)

export { list }
