<template>
  <BaseFormComponent
    :vid="vid"
    :name="$attrs.name"
    :isEditMode="isEditMode"
    :label="label"
    :rules="rules"
    :labelWidth="labelWidth"
  >
    <span v-if="isEditMode" class="form-item">
      <span v-if="required" class="required-star">*</span>
      <el-date-picker
        v-model="innerValue"
        :type="type"
        :size="$attrs.size"
        :class="[$attrs.classes]"
        :default-value="$attrs['default-value']"
        :picker-options="$attrs['picker-options']"
        :disabled="$attrs.disabled"
        :range-separator="$attrs['range-separator']"
        :start-placeholder="$attrs['start-placeholder']"
        :end-placeholder="$attrs['end-placeholder']"
        :format="$attrs['format'] || 'MM/dd/yyyy'"
        @change="onPick"
        :value-format="$attrs['value-format'] || 'yyyy-MM-dd'"
        v-on="$listeners"
        :placeholder="placeholder"
      ></el-date-picker>
    </span>
    <span v-else>{{ innerValue || message }}</span>
  </BaseFormComponent>
</template>

<script>
import BaseFormComponent from './BaseFormComponent'

export default {
  components: {
    BaseFormComponent,
  },
  props: {
    vid: {
      type: String,
      default: 'date',
    },
    type: {
      type: String,
      default: 'date',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    onPick: {
      type: Function,
      default: () => '',
    },
    value: {
      type: null,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Pick a day',
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: String,
      default: '125px',
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>
<style style="scss">
@import '../../styles/_formcomponent.scss';
</style>
