import { render, staticRenderFns } from "./MessageItemBasicCard.vue?vue&type=template&id=1d823872&scoped=true&lang=pug&"
import script from "./MessageItemBasicCard.vue?vue&type=script&lang=js&"
export * from "./MessageItemBasicCard.vue?vue&type=script&lang=js&"
import style0 from "./MessageItemBasicCard.vue?vue&type=style&index=0&id=1d823872&prod&lang=scss&scoped=true&"
import style1 from "./MessageItemBasicCard.vue?vue&type=style&index=1&id=1d823872&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d823872",
  null
  
)

export default component.exports