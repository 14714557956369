import axios from 'axios'

import { hosts } from './api.configs'
import store from '../store'
import router from '../router'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

function getAuthHeader() {
  return (
    localStorage.getItem('authToken') &&
    `Bearer ${localStorage.getItem('authToken')}`
  )
}

export function initializeAxiosInterceptors() {
  createAuthRefreshInterceptor(instance, refreshAuthLogic, {
    statusCodes: [401, 500],
    pauseInstanceWhileRefreshing: true,
    onRetry: (requestConfig) => {
      return {
        ...requestConfig,
        headers: {
          Authorization: getAuthHeader(),
        },
      }
    },
  })
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const request = error.config
      if (request && request.skipAuthRefresh) throw error
      if (error && error.response && error.response.status === 401) {
        if (request.url === '/auth/refresh-token') {
          await store.dispatch('user/logout')
          await router.push({ name: 'login' })
          return
        }
        return instance(request)
      }
      throw error
    },
  )
}

function setAuthTokenInLS(token, refreshToken) {
  localStorage.setItem('authToken', token)
  localStorage.setItem('refreshToken', refreshToken)
}

async function getNewAuthToken() {
  instance.defaults.baseURL = hosts.v3
  const {
    data: { token, refreshToken },
  } = await instance.post(`/auth/refresh-token`, {
    skipAuthRefresh: true,
    refreshToken: localStorage.getItem('refreshToken'),
  })
  return { token, refreshToken }
}

const _statusMiddleware = (status) => {
  if (status === 403) {
    const customersList = store.getters['customers/customersList']
    if (customersList.length === 0) {
      router.push({ path: '/home/no-customers' })
      return
    }
    if (window.location.pathname !== '/auth/login') {
      router.push({ path: '/error/403' })
      return
    }
  }

  return status >= 200 && status < 300
}

const refreshAuthLogic = async (failedRequest) => {
  const { token, refreshToken } = await getNewAuthToken()
  setAuthTokenInLS(token, refreshToken)
  failedRequest.response.config.headers['Authorization'] = `Bearer ${token}`
}

let instance = axios.create({
  headers: {
    Authorization: getAuthHeader(),
  },
})

const http = (baseURL = hosts.api, validateStatus) => {
  instance.defaults.headers['Authorization'] = getAuthHeader()
  instance.defaults.baseURL = baseURL
  instance.defaults.validateStatus = validateStatus || _statusMiddleware
  return instance
}

export const dataMiddleware = (response) => response.data

export default http
