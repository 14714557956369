<template lang="pug">
  .user-menu(:class="{'is-active': (isUserMenuVisible || 'dashboard.settings' === $route.name) && !$deviceInfo.isMobileDevice}")
    .user-menu-mobile
      .user-menu__wrapper
        .user-menu__info(:title='`${firstName} ${lastName}`') {{ firstName }} {{ lastName }}
        .user-menu__actions
          router-link(data-action='go-to-dashboard-settings',
            @click.native='close()',
            :to='{ name: "dashboard.settings" }')
            i.icon.icon-settings
          i.icon.icon-logout(@click="logout")
    el-dropdown.menu-settings(data-action='navbar-dropdown-open',
      trigger='click',
      placement='bottom-end',
      ref="menu-settings",
      @visible-change="openUserMenu")
      .el-dropdown-link
        .user-menu__name(:title='`${firstName} ${lastName}`') {{ firstName }} {{ lastName }}
      el-dropdown-menu.user-menu__dropdown(slot='dropdown')
        li
          .user-menu__wrapper
            .user-menu__info(:title='`${firstName} ${lastName}`') {{ firstName }} {{ lastName }}
            .user-menu__actions
              router-link(data-action='go-to-dashboard-settings',
                title="Settings"
                @click.native='close()',
                :to='{ name: "dashboard.settings" }')
                i.icon.icon-settings
              i.icon.icon-logout(@click="logout", title="Log Out")
          .user-menu__email(:title='email') {{ email }}
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { pathOr } from 'ramda'

export default {
  mounted() {
    window.addEventListener('scroll', this.hideDropdown)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.hideDropdown)
  },
  data() {
    return {
      isUserMenuVisible: false,
    }
  },
  computed: {
    ...mapState({
      email: pathOr('', ['user', 'user', 'email']),
      firstName: pathOr('', ['user', 'user', 'profile', 'firstName']),
      lastName: pathOr('', ['user', 'user', 'profile', 'lastName']),
    }),
  },
  methods: {
    ...mapActions({
      $logout: 'user/logout',
      $clearBillsFiltersData: 'bills/filtersData/clearBillsFiltersData',
      $clearLocationsFiltersData:
        'locations/filtersData/clearLocationsFiltersData',
      $clearReportsFiltersData: 'reports/filtersData/clearReportsFiltersData',
    }),
    hideDropdown() {
      const el = this.$refs['menu-settings']
      if (el) {
        this.$refs['menu-settings'].hide()
      }
    },
    async logout() {
      await this.$logout()
      await this.$clearBillsFiltersData()
      await this.$clearLocationsFiltersData()
      await this.$clearReportsFiltersData()
      this.$router.push({ name: 'login' })
    },
    openUserMenu(isVisible) {
      this.isUserMenuVisible = isVisible
    },
    close() {
      this.hideDropdown()
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../styles/utilities';

.user-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  min-height: 50px;
  padding: 0 30px;
  border-top: solid 1px #dde5e6;

  &__name,
  &__info,
  &__email {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__name {
    color: #ffffff;
    cursor: pointer;
    max-width: 180px;
  }

  &__info {
    max-width: 145px;
  }

  &__email {
    max-width: 200px;
    color: #606266;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0;
  }

  @include respond-to($tablet-p) {
    &__name {
      cursor: pointer;
      max-width: 160px;
    }
  }

  &__dropdown {
    padding: 17px 15px;
    width: 235px;
    list-style: none;
    box-sizing: border-box;

    .icon {
      &-logout {
        cursor: pointer;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    line-height: 22px;
    color: #000000;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    margin-bottom: 7px;
  }

  &__actions {
    box-sizing: border-box;

    i {
      margin: 0 0 0 10px;
    }
  }

  &.is-active {
    .el-dropdown-link {
      color: #2893f9;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-mobile {
    display: none;
  }

  @include respond-to($phone) {
    .menu-settings {
      display: none;
    }

    &-mobile {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 46px;
      height: 46px;

      .user-menu__wrapper {
        width: 100%;
        align-items: center;
        margin: 0;
      }

      .user-menu__info {
        max-width: calc(100% - 100px);
        font-size: 16px;
        color: #606266;
        line-height: 20px;
      }

      .icon {
        margin-left: 25px;
        height: 20px;
        width: 20px;
      }
    }
  }
}
</style>
