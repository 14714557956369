<template lang="pug">
  li.hierarchy-item
    .hierarchy-item-container(:class='{"is-parent": !node.parentId}')
      .label(:class='{"selected": isSelected, "match": isMatched}')
        i.el-icon-caret-bottom(v-if="node.name" :class="arrowPosition" @click="handleExpand")
        i.el-icon-location(v-if="!node.name && node.location" )
        span.node-name(v-if="isMatched", :class='{"is-expanded": expanded || unfold, "node-name-group": isGroup}',
          @dblclick="handleExpand",
          @click="nodeClick(node)")
          span {{ matchedParts.before }}
          span.matched-fragment {{ matchedParts.match }}
          span {{ matchedParts.after }}
        span.node-name(v-if="node.name || node.location", :class='{"is-expanded": expanded || unfold, "node-name-group": isGroup }',
          @dblclick="handleExpand",
          @click="nodeClick(node)") {{ node.name || node.location.name }}
        i.icon.icon-data(v-if='$deviceInfo.isMobileDevice && isSelected', @click="showInfo")
      ul(v-if="node.children && node.children.length && (expanded || unfold)")
        hierarchy-item(v-for="child in node.children",
          :key="child.id",
          :node="child",
          :nodeClick="nodeClick",
          :matched="matched",
          :showInfo="showInfo",
          :selectedLocation="selectedLocation")
</template>

<script>
import _ from 'lodash'

export default {
  name: 'HierarchyItem',
  props: {
    node: {
      type: Object,
      required: true,
      default: () => {},
    },
    selectedLocation: {
      type: Object,
      required: false,
      default: () => {},
    },
    nodeClick: {
      type: Function,
      required: false,
      default: () => {},
    },
    showInfo: {
      type: Function,
      required: false,
      default: () => {},
    },
    matched: {
      type: Object,
      required: false,
      default: () => ({ query: '', matches: [] }),
    },
  },
  beforeMount() {
    this.checkExpand()
    if (!this.node.parentId) {
      this.expanded = true
    }
    this.checkSelectedTree()
  },
  beforeUpdate() {
    this.checkExpand()
  },
  data() {
    return {
      expanded: false,
      unfold: false,
      clicked: false,
      checked: false,
      query: '',
    }
  },
  computed: {
    arrowPosition() {
      return !(this.expanded || this.unfold)
        ? 'transform-right'
        : 'transform-bottom'
    },
    isSelected() {
      return this.node.id === _.get(this.selectedLocation, ['id'], null)
    },
    isGroup() {
      return _.has(this.node, ['name'])
    },
    isMatched() {
      return this.matched.matches.some((m) => m.id === this.node.id)
    },
    matchedParts() {
      const name = this.node.name || this.node.location.name
      const index = name.toLowerCase().indexOf(this.matched.query)
      const len = this.matched.query.length
      return {
        before: name.substr(0, index),
        match: name.substr(index, len),
        after: name.substr(index + len),
      }
    },
  },
  watch: {
    selectedLocation() {
      this.checkSelectedTree()
    },
  },
  methods: {
    handleExpand() {
      if (!this.node.location) {
        this.expanded = this.clicked
          ? !this.expanded
          : (this.expanded = !this.unfold)
        this.unfold = this.expanded
        this.clicked = true
      }
    },
    checkExpand() {
      if (!_.isEmpty(this.matched.matches)) {
        if (this.matched.query !== this.query) {
          this.clicked = false
          this.query = this.matched.query
        }
        if (this.clicked) {
          this.unfold = this.expanded
        } else {
          this.unfold = this.matched.matches.some((m) =>
            _.get(m, ['path'], []).includes(this.node.id),
          )
        }
      } else {
        this.unfold = false
      }
    },
    checkSelectedTree() {
      const path = _.get(this.selectedLocation, ['path'], [])
      if (path.includes(this.node.id)) {
        this.expanded = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.transform {
  &-right {
    transform: rotate(-90deg);
    transition-timing-function: ease-out;
    transition: 0.3s;
  }
  &-bottom {
    transition-timing-function: ease-out;
    transition: 0.3s;
  }
}
.hierarchy-item {
  ul {
    list-style: none;
  }

  .icon-data {
    padding-left: 30px;
    text-align: right;

    &::before {
      width: 16px;
      height: 15px;
      background-repeat: no-repeat;
    }
  }

  &-container {
    .label {
      display: flex;
      align-items: baseline;
      cursor: pointer;
      margin-bottom: 9px;

      &.selected {
        .node-name {
          font-weight: 500;
          color: #0080ff;

          &-group {
            font-size: 14px;
          }
        }

        .el-icon-location {
          color: #0080ff;
        }
      }

      &.match {
        .matched-fragment {
          background-color: #dae7ff;
        }
      }

      .node-name {
        margin: 0 12px 0 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 19px;
        color: #3b3e48;

        &-group {
          font-size: 14px;
          font-weight: 500;
        }

        &.is-expanded {
          font-weight: 900;
        }
      }
    }

    &.is-parent {
      > .label {
        > .node-name {
          font-size: 18px;
          font-weight: 900;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
