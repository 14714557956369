<template lang="pug">
.filter-item-inner.filter-item-date
  template(v-if='!$deviceInfo.isMobileDevice')
    el-date-picker(v-model='startDate',
                  placeholder='Start date',
                  type='date',
                  :size='size',
                  popper-class='start-date',
                  value-format="yyyy-MM-dd",
                  data-action="filter-datepicker_start-date",
                  @change='onChangeStartDate')
    span to
    el-date-picker(v-model='endDate',
                  placeholder='End date',
                  type='date',
                  :size='size',
                  popper-class='end-date',
                  data-action="filter-datepicker_end-date",
                  value-format="yyyy-MM-dd",
                  @change="onChangeEndDate")
  template(v-else)
    .date-input-box
      input(v-model='startDate',
            type='date',
            data-action="filter-datepicker_start-date-mobile",
            placeholder='Start date')
      span.placeholder(v-if='!startDate')
        i.el-input__icon.el-icon-date
        | Start date
      span.value {{ startDate }}
    span to
    .date-input-box
      input(v-model='endDate',
            type='date',
            data-action="filter-datepicker_end-date-mobile",
            placeholder='End date')
      span.placeholder(v-if='!endDate')
        i.el-input__icon.el-icon-date
        | End date
      span.value {{ endDate }}
</template>

<script>
import { isNil, prop } from 'ramda'
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'

export default {
  props: {
    filterName: { type: String, default: null },
    onChange: { type: Function, required: true },
    size: { type: String, default: 'mini' },
  },
  data() {
    return {
      startDate: null,
      endDate: null,
    }
  },
  computed: {
    ...mapGetters({ currentCustomerId: 'customers/currentCustomerId' }),
  },
  watch: {
    currentCustomerId() {
      this.startDate = null
      this.endDate = null
    },
  },
  mounted() {
    this.checkQuery()
  },
  methods: {
    onChangeStartDate(value) {
      if (this.filterName) {
        this.onChange({
          [`${this.filterName}_min`]: isNil(value)
            ? null
            : DateTime.fromFormat(value, 'yyyy-LL-dd').toISO(),
        })
      } else {
        this.onChange({
          start_date: isNil(value)
            ? null
            : DateTime.fromFormat(value, 'yyyy-LL-dd').toISO(),
        })
      }
    },
    onChangeEndDate(value) {
      if (this.filterName) {
        this.onChange({
          [`${this.filterName}_max`]: isNil(value)
            ? null
            : DateTime.fromFormat(value, 'yyyy-LL-dd')
              .plus({ days: 1 })
              .toISO(),
        })
      } else {
        this.onChange({
          end_date: isNil(value)
            ? null
            : DateTime.fromFormat(value, 'yyyy-LL-dd')
              .plus({ days: 1 })
              .toISO(),
        })
      }
    },
    checkQuery() {
      let minDateFromQuery = null
      let maxDateFromQuery = null

      if (this.filterName) {
        minDateFromQuery = prop(`${this.filterName}_min`, this.$route.query)
        maxDateFromQuery = prop(`${this.filterName}_max`, this.$route.query)
      } else {
        minDateFromQuery = prop('start_date', this.$route.query)
        maxDateFromQuery = prop('end_date', this.$route.query)
      }

      if (minDateFromQuery) {
        const date = DateTime.fromISO(minDateFromQuery).toFormat('yyyy-MM-dd')
        this.startDate = date
        this.onChangeStartDate(date)
      }
      if (maxDateFromQuery) {
        const date = DateTime.fromISO(maxDateFromQuery).toFormat('yyyy-MM-dd')
        this.endDate = date
        this.onChangeEndDate(date)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/utilities';

.filter-item-date {
  .date-input-box {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 36px;
    overflow: hidden;

    .placeholder {
      font-size: 16px;
      line-height: 20px;
    }

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      border-radius: 10px;
      background-color: #fff;
    }

    i {
      z-index: 99;
    }

    input {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 36px;
      color: transparent;
      font-size: 16px;
      border: 1px solid #d8dce6;
      border-radius: 10px;
      appearance: none;
      background-color: transparent;
      background-image: none;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      box-sizing: border-box;
      outline: none;
      z-index: 1;

      @include respond-to($phone) {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px !important;
        line-height: 24px !important;
        white-space: nowrap;
        color: transparent;
      }
    }

    i {
      position: relative;
      top: 1px;
      margin-right: 5px;
      color: #909399;
    }
    .placeholder {
      position: absolute;
      top: 3px;
      left: 10px;
      color: #909399;
      font-size: 16px;
    }
    .value {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 38px;
      color: #909399;
      font-size: 16px;
    }
  }
}
</style>
