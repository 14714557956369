<template>
  <BaseModal
    :dialogVisible="showModal"
    id="removePropertyModal"
    @close="close"
    :width="'35%'"
  >
    <template #content>
      <ErrorCard :statusMessage="createError" />
      <p class="message">
        Are you sure you want to remove
        <span class="bold">
          {{ propertyName }}
          {{ !isRemovePropertyUse ? `(Property ID  ${propertyId})` : '' }}
        </span>
        from this location?
        {{
          isRemovePropertyUse
            ? 'This may affect the overall ENERGY STAR® score.'
            : 'This will also remove all the property uses associated with this ENERGY STAR® property.'
        }}
      </p>
    </template>
    <template #footer class="dialog-footer-center">
      <el-button
        class="button-size plain-button"
        id="removePropertyClose"
        @click="close"
      >
        Cancel
      </el-button>
      <el-button
        class="button-size"
        type="primary"
        id="removePropertyYes"
        @click="removeProperty"
        :loading="isLoading"
      >
        Yes
      </el-button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import ErrorCard from '@/components/Cards/ErrorCard'
import { energystar } from '@/api'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    propertyName: {
      type: String,
      default: '',
    },
    propertyId: {
      type: Number,
      default: 0,
    },
    propertyUseId: {
      type: String,
      default: '',
    },
    isRemovePropertyUse: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseModal,
    ErrorCard,
  },
  data() {
    return {
      showModal: false,
      createError: '',
      isLoading: false,
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  methods: {
    close() {
      this.$emit('close')
      this.createError = ''
    },
    async removeProperty() {
      const { customerId, locationId } = this.$route.params
      try {
        this.isLoading = true
        if (this.isRemovePropertyUse) {
          await energystar.removePropertyUse({
            customerId,
            propertyId: this.propertyId,
            propertyUseId: this.propertyUseId,
          })
        } else {
          await energystar.removeProperty({
            customerId,
            propertyId: this.propertyId,
            locationId,
          })
        }
        this.isLoading = false
        this.createError = ''
        this.$emit('refresh-page')
        this.close()
      } catch (e) {
        this.createError = e.response.data.message
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.button-size {
  width: 45%;
}
.plain-button {
  color: #2893f9;
  border-color: #2893f9;
}
.message {
  margin: 0;
  text-align: center;
  word-break: break-word;
}
.bold {
  font-weight: bold;
}
</style>
