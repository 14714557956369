<template>
  <BaseModal
    :dialogVisible="innerVisible"
    @close="close"
    :title="'Create a new ENERGY STAR® Meter'"
    :width="'50%'"
    :appendToBody="appendToBody"
    id="createMetersModal"
  >
    <template #content>
      <ErrorCard :statusMessage="createError" />
      <ValidationObserver ref="observer">
        <el-form label-position="left" class="fixed-form-container">
          <BaseInput
            v-model="createMeter.name"
            placeholder="Enter ES Meter name"
            :required="true"
            name="name"
            label="Name:"
            :labelWidth="'200px'"
            rules="required"
            size="small"
            :isEditMode="true"
          />
          <BaseSelect
            label="Select Commodity Type:"
            name="commodity"
            size="small"
            :labelWidth="'200px'"
            :required="true"
            rules="required"
            :isEditMode="true"
            v-model="createMeter.commodity"
          >
            <el-option
              v-for="(v, i) in commodities"
              :key="i"
              :label="v"
              :value="v"
            />
          </BaseSelect>
          <BaseDatePicker
            v-model="createMeter.firstBillDate"
            name="billdate"
            label="Select First Bill Date:"
            :labelWidth="'200px'"
            rules="required"
            size="small"
            :required="true"
            :isEditMode="true"
          />
        </el-form>
      </ValidationObserver>
    </template>
    <template #footer>
      <el-button class="button-size" type="primary" @click="close">
        Cancel
      </el-button>
      <el-button
        class="button-size"
        id="createEnergyStarMeter"
        type="primary"
        :disabled="valid"
        @click="validateAndCreate"
      >
        Create ENERGY STAR® Meter
      </el-button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import { ValidationObserver } from 'vee-validate'
import BaseInput from '@/components/FormComponents/BaseInput'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import BaseDatePicker from '@/components/FormComponents/BaseDatePicker'
import ErrorCard from '@/components/Cards/ErrorCard'
import { energystar } from '@/api'

export default {
  components: {
    BaseModal,
    BaseInput,
    BaseSelect,
    BaseDatePicker,
    ValidationObserver,
    ErrorCard,
  },
  props: {
    innerVisible: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    propertyId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      createMeter: {
        name: '',
        commodity: '',
        firstBillDate: '',
        locationId: this.$route.params.locationId,
      },
      commodities: [
        'ELECTRIC',
        'NATURALGAS',
        'LIGHTING',
        'SOLARPV',
        'PROPANE',
        'CHILLEDWATER',
        'HOTWATER',
        'STEAM',
        'OIL2',
        'OIL4',
        'OIL6',
      ],
      createError: '',
      valid: false,
    }
  },
  methods: {
    close() {
      this.resetForm()
      this.$emit('close')
    },
    resetForm() {
      this.createMeter.name = ''
      this.createMeter.commodity = ''
      this.createMeter.firstBillDate = ''
      this.$refs.observer.reset()
    },
    validateAndCreate() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          this.valid = true
          this.createMeters()
        }
      })
    },
    async createMeters() {
      const { customerId } = this.$route.params
      try {
        await energystar.postMeter({
          customerId,
          propertyId: this.propertyId,
          body: this.createMeter,
        })
        this.$emit('close-and-create')
        this.resetForm()
        this.valid = false
      } catch (e) {
        this.createError = 'There was a problem with the request'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fixed-form-container {
  width: 395px;
}
.button-size {
  width: 45%;
}
</style>
