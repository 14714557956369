import Dashboard from './Dashboard'
import DashboardWidgets from './Dashboard/View'
import DashboardWidgetsDetails from './Dashboard/Details'
import Home from './Home'
import Sam from './Sam/Chat'
import Bills from './Bills/Bills.vue'
import BillView from './Bills/View.vue'
import Locations from './Locations/Locations.vue'
import LocationView from './Locations/View.vue'
import LocationsHierarchy from './Locations/LocationsHierarchy'
import Analytics from './Analytics/View'
import Sustainability from './Sustainability/View'
import PowerBI from './PowerBI/View'
import Reports from './Reports/Reports.vue'
import Budgeting from './Budgeting/Budgeting.vue'
import Payments from './Payments/Payments.vue'
import Settings from './Settings/Settings'
import store from '../../store'

const redirectWithCustomerId = (to) => {
  let customerId = store.getters['customers/currentCustomerId']
  return {
    path: `/${customerId}${to.path}`,
    query: to.query,
  }
}

export default {
  path: '/',
  redirect: () => {
    let customerId =
      store.getters['customers/currentCustomerId'] ||
      (store.getters['user/currentUser'] || {}).customer_id
    if (!customerId) {
      return `/auth/login`
    }
    return `/${customerId}/home`
  },
  name: 'dashboard',
  meta: { auth: true },
  component: Dashboard,
  children: [
    {
      name: 'dashboard.home',
      path: ':customerId/home',
      component: Home,
      meta: {
        auth: true,
        title: 'Home',
      },
    },
    {
      name: 'dashboard.widgets',
      path: ':customerId/dashboard',
      component: DashboardWidgets,
      meta: {
        auth: true,
        title: 'Dashboard',
      },
    },
    {
      name: 'dashboard.widgets.details',
      path: ':customerId/dashboard/:widgetId',
      component: DashboardWidgetsDetails,
      meta: {
        auth: true,
        title: 'Dashboard',
      },
    },
    {
      name: 'dashboard.sam',
      path: ':customerId/sam',
      component: Sam,
      meta: {
        auth: true,
        title: 'Sam',
      },
    },
    {
      name: 'dashboard.bills',
      path: ':customerId/bills',
      component: Bills,
      meta: {
        auth: true,
        title: 'Bills',
      },
    },
    {
      name: 'dashboard.bills.info',
      path: ':customerId/bills/:billId',
      component: BillView,
      meta: {
        auth: true,
        title: 'Bills',
      },
    },
    {
      name: 'dashboard.locations',
      path: ':customerId/locations',
      component: Locations,
      meta: {
        auth: true,
        title: 'Locations',
      },
    },
    {
      name: 'dashboard.locations.hierarchy',
      path: ':customerId/locations/hierarchy',
      component: LocationsHierarchy,
      meta: {
        auth: true,
        title: 'Locations',
      },
    },
    {
      name: 'dashboard.locations.info',
      path: ':customerId/locations/:locationId',
      component: LocationView,
      meta: {
        auth: true,
        title: 'Locations',
      },
    },
    {
      name: 'dashboard.locations.info.tab',
      path: ':customerId/locations/:locationId/:tab',
      component: LocationView,
      meta: {
        auth: true,
        title: 'Locations',
      },
    },
    {
      name: 'dashboard.reports',
      path: ':customerId/reports',
      component: Reports,
      meta: {
        auth: true,
        title: 'Reports',
      },
    },
    {
      name: 'dashboard.budgeting',
      path: ':customerId/budgeting/:bgTab',
      component: Budgeting,
      meta: {
        auth: true,
        title: 'Budgeting',
      },
    },
    {
      name: 'dashboard.analytics',
      path: ':customerId/analytics',
      component: Analytics,
      meta: {
        auth: true,
        title: 'Analytics',
      },
    },
    {
      name: 'dashboard.analytics.changeReportModal',
      path: ':customerId/analytics/changeReportModal',
      component: Analytics,
      meta: {
        auth: true,
        title: 'Analytics',
      },
    },
    {
      name: 'dashboard.powerbi',
      path: ':customerId/powerbi_beta',
      component: PowerBI,
      meta: {
        auth: true,
        title: 'Analytics',
      },
    },
    {
      name: 'dashboard.reports.tab',
      path: ':customerId/reports/:tab',
      component: Reports,
      meta: {
        auth: true,
        title: 'Reports',
      },
    },
    {
      name: 'dashboard.payments',
      path: ':customerId/payments',
      component: Payments,
      meta: {
        auth: true,
        title: 'Payment Files',
      },
    },
    {
      name: 'dashboard.sustainability',
      path: ':customerId/sustainability',
      component: Sustainability,
      meta: {
        auth: true,
        title: 'Sustainability',
      },
    },
    {
      name: 'dashboard.settings',
      path: 'settings',
      component: Settings,
      meta: {
        auth: true,
        title: 'Settings',
      },
    },
    { path: '/home', redirect: redirectWithCustomerId },
    { path: '/dashboard', redirect: redirectWithCustomerId },
    { path: '/dashboard/:widgetId', redirect: redirectWithCustomerId },
    { path: '/sam', redirect: redirectWithCustomerId },
    { path: '/bills', redirect: redirectWithCustomerId },
    { path: '/bills/:billId', redirect: redirectWithCustomerId },
    { path: '/locations', redirect: redirectWithCustomerId },
    { path: '/locations/:locationId', redirect: redirectWithCustomerId },
    { path: '/reports', redirect: redirectWithCustomerId },
    { path: '/powerbi', redirect: redirectWithCustomerId },
    { path: '/payments', redirect: redirectWithCustomerId },
    { path: '/settings', redirect: redirectWithCustomerId },
  ],
}
