<template lang="pug">
  div
    message-item-text(v-if='!(message.payload.messages instanceof Array)',
                      :item='message.payload.message',
                      :sender-id='message.sender_id',
                      :class='message.sender_id === "98" ? "sam" : "customer"')
    template(v-else v-for='(item) in message.payload.messages')
      message-item-text(v-if='item.message === "text"',
                      :item='getItem(item)',
                      :sender-id='message.sender_id',
                      :messages='message.payload.messages',
                      :calendarOpened='calendarOpened',
                      class="sam")
      message-item-text(v-if='item.payload && item.payload.pearAi && item.payload.pearAi.textCard',
                      :item='item.payload.pearAi.textCard',
                      :sender-id='message.sender_id',
                      :messages='message.payload.messages',
                      :calendarOpened='calendarOpened',
                      class="sam",
                      @sendMessage='$emit("sendMessage", $event)')
      message-item-quick-replies(v-if='item.message === "quickReplies"',
                                :item='getItem(item)',
                                class='sam',
                                @sendMessage='$emit("sendMessage", $event)')
      message-item-table-view(v-if='item.payload && item.payload.pearAi && item.payload.pearAi.tableCard',
                              :messageData='item.payload.pearAi.tableCard',
                              @sendMessage='$emit("sendMessage", $event)')
      message-item-table-list-view(v-if='item.payload && item.payload.pearAi && item.payload.pearAi.tableList',
                              :messageData='item.payload.pearAi.tableList',
                              @sendMessage='$emit("sendMessage", $event)')
      message-item-basic-card(v-if='item.payload && item.payload.pearAi && item.payload.pearAi.basicCard',
                              :messageData='item.payload.pearAi.basicCard')
      select-bill(v-if='item.payload && \
                  item.payload.pearAi && \
                  item.payload.pearAi.missingParamWidget && \
                  item.payload.pearAi.missingParamWidget.name === "select_bill"',
                  :messageData='item.payload.pearAi.missingParamWidget.payload',
                  @sendMessage='$emit("sendMessage", $event)')
    .chat-date(v-if="showDate")
      span.chat-date-label(v-text="getDayString(previousMessage)")
      hr.chat-date-line
</template>

<script>
import MessageItemText from './MessageItemText.vue'
import MessageItemQuickReplies from './MessageItemQuickReplies.vue'
import MessageItemTableView from './MessageItemTableView.vue'
import MessageItemTableListView from './MessageItemTableListView.vue'
import MessageItemBasicCard from './MessageItemBasicCard.vue'
import SelectLocation from './Controls/SelectLocation.vue'
import SelectBill from './Controls/SelectBill.vue'

export default {
  components: {
    MessageItemText,
    MessageItemQuickReplies,
    MessageItemTableView,
    MessageItemTableListView,
    MessageItemBasicCard,
    SelectLocation,
    SelectBill,
  },
  data() {
    return {
      messageType: 'text',
      messageData: {},
    }
  },
  props: {
    calendarOpened: {
      type: Boolean,
    },
    message: {
      type: Object,
      required: true,
    },
    showDate: {
      type: Boolean,
    },
    previousMessage: {
      type: Object,
    },
    getDayString: {
      type: Function,
    },
  },
  methods: {
    getItem(item) {
      return item[item.message]
    },
  },
}
</script>

<style lang="scss" scoped>
.chat-date {
  text-align: center;
  color: #7d89a6;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}
.chat-date-label {
  position: relative;
  display: inline-block;
  background: #f7f8fa;
  height: 1em;
  font-size: 1em;
  margin: 0;
  padding: 0 1em;
  z-index: 200;
}
.chat-date-line {
  display: none;
  /*border: 1px solid #e8e8e8;*/
  /*position: relative;*/
  /*margin: -0.75em 0 1em 0;*/
  /*padding: 0;*/
}
</style>
