<template lang="pug">
.locations-list
  .page-header
    .page-title Locations
    .locations-list-total Qty of locations:
      b {{ total || 'N/A' }}
  filter-container
  el-table.locations-table(ref="locationsTable",
                          @sort-change='sortTable',
                          @row-click='handleClick',
                          :data='locations',
                          v-loading.body.lock='loading',
                          data-element="locations-list",
                          :cell-style='() => "border-bottom: 1px solid #ebeef5 !important;"')
    el-table-column(label='Description', prop='name', min-width='150', sortable)
    el-table-column(label='Type', prop='buildingType', width='120', sortable)
    el-table-column(label='Service Address', prop='address', width='300', sortable)
    el-table-column(label='State', prop='state', sortable)
      template(slot-scope='scope')
        span {{ removeCountry(scope.row.state) }}
    el-table-column(label='Zip', prop='postcode', sortable)
    el-table-column(label='Area (Sq. Ft)', prop='squareFeet', sortable)

</template>

<script>
import FilterContainer from './Filters/FilterContainer.vue'
import { isNil } from 'ramda'
import { mapGetters } from 'vuex'
import { serializeOrderBy } from '@/lib/helpers'

export default {
  components: {
    FilterContainer,
  },
  computed: {
    ...mapGetters({
      order: 'locations/filters/order',
      total: 'locations/total',
      loading: 'locations/loading_list',
      locations: 'locations/locations',
    }),
  },
  methods: {
    sortTable({ prop, order }) {
      const { path, query } = this.$route

      if (isNil(prop) || isNil(order)) {
        this.$router.push({
          path: path,
          query: {
            ...query,
            orders: [],
          },
        })
      } else {
        if (prop.startsWith('payload')) {
          prop = prop.replace('.', '->')
        }

        this.$router.push({
          path: path,
          query: {
            ...query,
            orderBy: [serializeOrderBy(prop, order)],
          },
        })
      }
    },
    handleClick(row) {
      this.$router.push({
        name: 'dashboard.locations.info',
        params: { locationId: row.id },
      })
    },
    removeCountry(state) {
      return (state || '').replace(/^.*-/g, '')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/utilities';

.locations {
  &-table {
    .el-button {
      padding: {
        top: 9px;
        bottom: 9px;
      }
      text-decoration: underline;
    }

    .el-button:hover {
      font-weight: bold;
    }
  }
}
.locations-list-total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #7d89a6;
  font-size: 12px;

  b {
    padding-left: 10px;
    color: #3b3e48;
    font-size: 16px;
    font-weight: 900;
  }
}
</style>

<style lang="scss">
.locations-table {
  th {
    font-weight: 500;
  }
  .amount-due {
    font-weight: 900;
  }
}
</style>
