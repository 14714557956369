<template>
  <div class="network-error-container">
    <div class="content-inner widget-details" v-loading.lock="loading">
      <div class="page-header">
        <span
          class="page-back"
          v-if="!$deviceInfo.isMobileDevice"
          data-action="btn-widget-details-back"
          @click="$router.push({ name: 'dashboard.widgets' })"
        >
          <i class="icon icon-back"></i>
        </span>
        <span class="page-title">{{ widget.name || '' }}</span>
        <template v-if="!$deviceInfo.isMobileDevice">
          <el-button
            type="primary"
            data-action="btn-export-pdf"
            round="round"
            size="mini"
            @click="exportPdf"
            :disabled="!widgetClone"
          >
            Export PDF
          </el-button>
          <el-button
            type="primary"
            data-action="btn-export-csv"
            round="round"
            size="mini"
            @click="exportCsv"
            :disabled="!widgetClone"
          >
            Export CSV
          </el-button>
        </template>
        <template v-else>
          <div
            class="mobile-export"
            @click="exportOpen = !exportOpen"
            :class="{
              'mobile-export-open': exportOpen,
              disabled: !widgetClone,
            }"
          >
            <div class="mobile-export-button" @click="exportPdf">
              Export PDF file
            </div>
            <div class="mobile-export-button" @click="exportCsv">
              Export CSV file
            </div>
          </div>
        </template>
      </div>
      <div
        class="page-actions filter"
        :class="{ 'mobile-filter': $deviceInfo.isMobileDevice }"
      >
        <filter-show-container
          v-if="widget.type"
          :widgetFilters="filterSimple"
          :title="''"
          :widget-type="widget.type"
        ></filter-show-container>
      </div>
      <filter-mobile-container
        :widget-id="widget.id"
        :widget-filters="widget.payload || {}"
        :widget-type="widget.type || ''"
        @submit-filters="submitFilters"
        @reset-filters="resetFilters"
      ></filter-mobile-container>
      <div class="page-content" v-if="widgetData.length">
        <div class="widget" ref="widget">
          <component
            v-bind="widgetOptions"
            :is="chartType"
            :data="widgetData"
            :draggable="true"
          ></component>
        </div>
        <div class="table-container">
          <div class="table-title">Table Data</div>
          <el-table
            class="table"
            :data="widgetData"
            :class="{ wide: costsByChargeTypeWidget }"
          >
            <el-table-column
              v-if="widgetData[0].location"
              label="Description"
              prop="location"
            ></el-table-column>
            <el-table-column
              v-if="widgetData[0].utility"
              label="Utility"
              prop="utility"
              class-name="utility"
            ></el-table-column>
            <el-table-column
              v-if="monthlyWidgetType &amp;&amp; widgetData[0].month"
              label="Month"
              prop="month"
            ></el-table-column>
            <el-table-column
              v-if="vendorWidgetType &amp;&amp; widgetData[0].vendor"
              label="Vendor"
              prop="vendor"
            ></el-table-column>
            <el-table-column
              v-if="consumptionWidgetType &amp;&amp; widgetData[0].uom &amp;&amp; widgetData[0].consumption"
              label="Consumption"
              lass-name="consumption"
            >
              <template slot-scope="scope">
                {{ scope.row.consumption | unit(scope.row.uom) }}
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="widgetData[0].utility"
              label="Cost"
              prop="value"
            >
              <template slot-scope="scope">
                {{ scope.row.tableValue | unit('USD') }}
              </template>
            </el-table-column>
            <template v-else-if="costsByChargeTypeWidget">
              <el-table-column label="Month" prop="month"></el-table-column>
              <el-table-column
                v-for="{ name, title } in chargesByVendorLabels"
                :key="name"
                :label="title"
                :prop="name"
              >
                <template slot-scope="scope">
                  {{ scope.row[name] | unit('USD') }}
                </template>
              </el-table-column>
            </template>
            <el-table-column
              v-else-if="widget.type === 'highest_unit_cost_locations'"
              label="Unit Cost"
              prop="value"
            >
              <template slot-scope="scope">
                {{ (+scope.row.value || 0).toFixed(2) }} $/{{ scope.row.uom }}
              </template>
            </el-table-column>
            <el-table-column v-else label="Cost" prop="value">
              <template slot-scope="scope">
                {{ scope.row.costs | unit('USD') }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <h4 class="no-data" v-else>NO DATA FOR VIEW</h4>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import _ from 'lodash'
import { dashboard } from '@/api'
import { mapState, mapGetters, mapActions } from 'vuex'
import FilterContainer from './Filters/FilterContainer.vue'
import FilterShowContainer from './Filters/FilterShowContainer'
import FilterMobileContainer from './Filters/FilterMobileContainer.vue'
import Bar from '@/components/Charts/BarChart.vue'
import Pie from '@/components/Charts/PieChart.vue'
import Stack from '@/components/Charts/StackChart.vue'
import { chargesByVendorLabels } from '../../../components/Charts/data'
import { filters, svg2pdf, filesaver, Events } from '@/lib'
import JsPDF from 'jspdf'
import 'jspdf-autotable'
import { detect } from 'detect-browser'
import canvg from 'canvg'

const mapFilters = (f) => {
  if (f.filter === 'time_period') {
    const type = f.time_period.type
    return { time_period: { ..._.omit(f.time_period, 'type') }, type }
  } else {
    return { [f.filter]: f[f.filter] }
  }
}

export default {
  components: {
    FilterContainer,
    FilterShowContainer,
    FilterMobileContainer,
    Bar,
    Pie,
    Stack,
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      widgetTypes: 'widgets/widgetTypes',
      checkResources: 'resources/checkResources',
    }),
    filterSimple() {
      const currentFilters = {}
      const widgetFilter = _.get(this.widget, ['payload', 'filters'], [])
      widgetFilter.forEach((value, index, arr) => {
        const filterType = value.filter
        currentFilters[filterType] = value[filterType]
      })
      return {
        ...filters.defaultFilters(this.widget.type, 'show'),
        ...currentFilters,
      }
    },
    chartType() {
      if (this.widget.type) {
        return R.path([this.widget.type, 'chart_types', '0'], this.widgetTypes)
      }
    },
    costsByChargeTypeWidget() {
      return this.widget.type === 'costs_by_charge_type'
    },
    vendorWidgetType() {
      return ['charges_by_vendor'].includes(this.widget.type)
    },
    monthlyWidgetType() {
      return [
        'monthly_cost_by_location',
        'monthly_consumption_by_location',
      ].includes(this.widget.type)
    },
    consumptionWidgetType() {
      return [
        'monthly_consumption_by_location',
        'highest_consumption_locations',
      ].includes(this.widget.type)
    },
    widgetOptions() {
      const options = {}
      const isMobile = this.$deviceInfo.isMobileDevice

      this.widgetData.map((current) => {
        current.unitCosts = `${Math.floor(current.value * 100) / 100} $/${
          current.uom
        }`
        return current
      })

      options.detailView = true

      options.barItemWidth = isMobile ? 10 : 15
      options.barWidth = isMobile ? 35 : 55

      if (this.chartType === 'bar' || this.chartType === 'stack') {
        options.barWidth = 55
        options.width =
          (this.widgetData.length < 3
            ? this.widgetData.length + 1
            : this.widgetData.length) * options.barWidth
      } else {
        options.width = isMobile ? 300 : 500
      }

      if (isMobile && this.chartType === 'pie') {
        options.height = 300
      }
      if (this.chartType === 'pie') {
        options.pieSize = isMobile ? 240 : 300
        options.showLegend = !isMobile
      }
      options.labels = this.defineWidgetLabels()
      return options
    },
    isBrowserIE() {
      return detect().name === 'ie'
    },
  },
  data() {
    return {
      widget: {},
      widgetData: [],
      allWidgetData: [],
      loading: true,
      widgetFilterVisible: false,
      chargesByVendorLabels,
      isAddFilterOpened: false,
      isEditFilterOpened: false,
      editFilterType: '',
      totalCount: 0,
      exportOpen: false,
      widgetClone: null,
    }
  },
  watch: {
    checkResources: {
      async handler(val) {
        if (val) {
          if (!this.widgetTypes) {
            await this.setWidgetsTypes()
          }
          await this.loadWidget()
          await this.setLocationFilters({ limit: 1000 })

          const svg = document.querySelector('.widget svg')
          if (svg) {
            this.widgetClone = svg.cloneNode(true)
            if (this.widget.type === 'cost_by_utility') {
              this.widgetClone.setAttribute('width', 500)
              this.widgetClone.setAttribute('viewBox', '0 0 550 360')
            } else {
              this.widgetClone.setAttribute('width', 980)
              this.widgetClone.setAttribute('viewBox', '0 0 980 320')
            }
            const css =
              '<![CDATA[ .axisX text,.axisY text{fill:#000}.axisX path,.axisX>g>line,.axisY line,.axisY path{stroke:#d8d8d8}.axisY>.tick:nth-of-type(1){display:none}.bar{fill:#84b2fa} ]]>'
            const style = document.createElement('style')
            this.widgetClone.insertBefore(style, this.widgetClone.firstChild)
            style.type = 'text/css'
            style.appendChild(document.createTextNode(css))
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setWidgetsTypes: 'widgets/setWidgetsTypes',
      setLocationFilters: 'locations/filters/setFilters',
    }),
    defineWidgetLabels() {
      switch (this.widget.type) {
        case 'highest_unit_cost_locations':
          return { x: 'location', y: 'unitCosts' }
        case 'highest_cost_locations':
          return { x: 'location', y: 'costs' }
        case 'highest_consumption_locations':
          return { x: 'location', y: 'consumption' }
        case 'monthly_cost_by_location':
          return { x: 'month', y: 'costs' }
        case 'monthly_consumption_by_location':
          return { x: 'month', y: 'consumption' }
        case 'charges_by_vendor':
          return { x: 'vendor', y: 'costs' }
        default:
          return {}
      }
    },
    async loadWidget() {
      const options = {
        customerId: this.currentCustomerId,
        widgetId: this.$route.params.widgetId,
      }

      try {
        this.widget = await dashboard.getWidget(options)
        const { data, meta } = await dashboard.getWidgetData(
          options,
          this.widget.type,
        )
        this.totalCount = _.get(meta, 'total_count', 0)
        this.widgetData = data
        this.loading = false
      } catch (error) {
        const code = R.pathOr('', ['response', 'data', 'code'], error)
        if (code === 404) {
          Events.$emit('not.found')
        }
      }
    },
    async submitFilters(currentFilters) {
      const {
        time_period,
        location_id,
        utility_type,
        utility_type_list,
        year,
      } = currentFilters
      const filters = _.get(this.widget, 'payload.filters', [])
        .slice()
        .map((f) => mapFilters(f))

      if (!_.isEmpty(time_period)) {
        const existing = _.get(
          _.find(filters, (item) =>
            _.keys(item).find((i) => i === 'time_period'),
          ),
          'time_period.period',
          {},
        )
        const type = _.get(time_period, 'type', 'all')
        const period = { ...existing, ..._.omit(time_period, 'type') }
        _.remove(filters, (item) =>
          _.keys(item).find((i) => i === 'time_period'),
        )
        if (period.start_date && period.end_date && type === 'period') {
          filters.push({ time_period: { period }, type })
        } else if (type === 'months') {
          filters.push({ time_period: { months: time_period.count }, type })
        } else if (type === 'year_to_date') {
          filters.push({ time_period: { year_to_date: true }, type })
        }
      }

      if (!_.isEmpty(location_id)) {
        _.remove(filters, (item) =>
          _.keys(item).find((i) => i === 'location_id'),
        )
        if (!_.isEmpty(location_id.location_ids)) {
          filters.push({
            location_id: {
              location_ids: location_id.location_ids,
            },
          })
        }
      }

      if (!_.isEmpty(utility_type)) {
        _.remove(filters, (item) =>
          _.keys(item).find((i) => i === 'utility_type'),
        )
        filters.push({ utility_type })
      }

      if (!_.isEmpty(year)) {
        _.remove(filters, (item) => _.keys(item).find((i) => i === 'year'))
        filters.push({ year })
      }

      if (!_.isEmpty(utility_type_list)) {
        _.remove(filters, (item) =>
          _.keys(item).find((i) => i === 'utility_type_list'),
        )
        filters.push({ utility_type_list })
      }

      this.loading = true
      await dashboard.updateWidget({
        customerId: this.currentCustomerId,
        widget: { id: this.widget.id, filters },
      })
      this.widgetFilterVisible = false
      await this.loadWidget()
    },
    async resetFilters() {
      this.loading = true
      await dashboard.updateWidget({
        customerId: this.currentCustomerId,
        widget: { id: this.widget.id, filters: [] },
      })
      this.widgetFilterVisible = false
      await this.loadWidget()
    },
    showFilter({ currentTarget: el }) {
      el.classList.add('is-active')
      this.widgetFilterVisible = true
    },
    onPopperHide() {
      document
        .querySelectorAll('.button-chart-filter')
        .forEach((e) => e.classList.remove('is-active'))
    },
    async exportPdf() {
      if (!this.widgetClone) {
        return
      }
      if (_.isEmpty(this.allWidgetData)) {
        this.allWidgetData = await this.getAllWidgetData()
      }
      this.createPdf()
    },
    async exportCsv() {
      if (_.isEmpty(this.allWidgetData)) {
        this.allWidgetData = await this.getAllWidgetData()
      }
      const divider = ','
      let columns = this.tableCsvColumns().map((row) => row.dataKey)
      let body = this.tableCsvBody()
      if (this.widget.type === 'cost_by_utility') {
        body.forEach((el, i, arr) => {
          arr[i].tableValue = +arr[i].tableValue
        })
        body = _.sortBy(body, ['tableValue']).reverse()
      }
      body = body.map((row) => {
        const line = []
        columns.forEach((col) => line.push(row[col]))
        return line.join(divider)
      })
      columns = this.tableCsvColumns().map((row) => row.header)
      filesaver.saveTextAs(
        `${columns.join(divider)}\n${body.join('\n')}`,
        `${this.widget.name}.csv`,
      )
    },

    createPdf() {
      const options = { backgroundColor: 'white', encoderOptions: 1 }
      if (this.isBrowserIE) {
        options.canvg = canvg
      }
      svg2pdf.svgAsPngUri(this.widgetClone, options).then((uri) => {
        const pdf = new JsPDF({
          orientation: 'portrait',
          unit: 'mm',
        })

        const margin = { top: 15, right: 14, bottom: 15, left: 14 }
        const page = { width: 210, height: 297 }

        let xPos = margin.left
        let yPos = margin.top
        let yDelta = 10

        pdf.setFontSize(16)

        let widthOfPage = page.width - margin.left - margin.right
        const splitTitleOnLines = pdf.splitTextToSize(
          this.widget.name,
          widthOfPage,
        )
        splitTitleOnLines.forEach((line) => {
          pdf.text(xPos, yPos, line)
          yPos += yDelta
        })

        let body = this.tableFilterBody()
        let columns = this.tableFilterColumns()
        pdf.autoTable({
          body,
          columns,
          columnStyles: {
            0: { minCellWidth: 30 },
            1: { minCellWidth: 50 },
            2: { minCellWidth: 60 },
          },
          theme: 'plain',
          startY: yPos,
        })

        yDelta = 25
        yPos += yDelta
        const imgWidthCostByUtility = 138
        const imgWidth = 181
        const imgHeight = 80
        if (uri) {
          if (this.widget.type === 'cost_by_utility') {
            pdf.addImage(
              uri,
              'PNG',
              xPos,
              yPos,
              imgWidthCostByUtility,
              imgHeight,
            )
          } else {
            pdf.addImage(uri, 'PNG', xPos, yPos, imgWidth, imgHeight)
          }
        }

        yDelta = 80
        yPos += yDelta + 10
        pdf.setFontSize(14)
        pdf.text('Table Data', xPos, yPos)

        yDelta = 5
        yPos += yDelta
        body = this.tablePdfBody()
        columns = this.tablePdfColumns()
        pdf.autoTable({
          body,
          columns,
          startY: yPos,
          styles: {
            fontSize: 8,
          },
        })
        pdf.save(`${this.widget.name}.pdf`)
      })
    },
    tablePdfColumns() {
      const header = []
      const row = this.allWidgetData[0]
      if (row.location) {
        header.push({ header: 'Description', dataKey: 'location' })
      }
      if (row.utility) {
        header.push({ header: 'Utility', dataKey: 'utility' })
      }
      if (this.monthlyWidgetType && row.month) {
        header.push({ header: 'Month', dataKey: 'month' })
      }
      if (this.vendorWidgetType && row.vendor) {
        header.push({ header: 'Vendor', dataKey: 'vendor' })
      }
      if (this.consumptionWidgetType && row.uom && row.consumption) {
        header.push({ header: 'Consumption', dataKey: 'consumption' })
      } else if (row.utility) {
        header.push({ header: 'Cost', dataKey: 'tableValue' })
      } else if (this.costsByChargeTypeWidget) {
        header.push({ header: 'Month', dataKey: 'month' })
        this.chargesByVendorLabels.forEach((label) => {
          header.push({ header: label.title, dataKey: label.name })
        })
      } else if (this.widget.type === 'highest_unit_cost_locations') {
        header.push({ header: 'Unit Cost', dataKey: 'value' })
      } else {
        header.push({ header: 'Cost', dataKey: 'value' })
      }
      return header
    },
    tablePdfBody() {
      let body = []
      this.allWidgetData.forEach((row) => {
        const bodyItem = {}
        if (row.location) {
          bodyItem.location = row.location
        }
        if (row.utility) {
          bodyItem.utility = row.utility
        }
        if (this.monthlyWidgetType && row.month) {
          bodyItem.month = row.month
        }
        if (this.vendorWidgetType && row.vendor) {
          bodyItem.vendor = row.vendor
        }
        if (this.consumptionWidgetType && row.uom && row.consumption) {
          bodyItem.consumption = filters.unit(row.consumption, row.uom)
        } else if (row.utility) {
          bodyItem.tableValue = filters.unit(row.tableValue, 'USD')
        } else if (this.costsByChargeTypeWidget) {
          bodyItem.month = row.month
          this.chargesByVendorLabels.forEach((label) => {
            bodyItem[label.name] = filters.unit(row[label.name], 'USD')
          })
        } else if (this.widget.type === 'highest_unit_cost_locations') {
          bodyItem.value = `${row.unitCosts}  $/${row.uom}`
        } else {
          bodyItem.value = filters.unit(row.costs, 'USD')
        }
        body.push(bodyItem)
      })
      return body
    },
    tableCsvColumns() {
      const titles = []
      let header
      const row = this.allWidgetData[0]
      if (row.location) {
        titles.push({ header: 'Description', dataKey: 'location' })
      }
      if (row.utility) {
        titles.push({ header: 'Utility', dataKey: 'utility' })
      }
      if (this.monthlyWidgetType && row.month) {
        titles.push({ header: 'Month', dataKey: 'month' })
      }
      if (this.vendorWidgetType && row.vendor) {
        titles.push({ header: 'Vendor', dataKey: 'vendor' })
      }
      if (this.consumptionWidgetType && row.uom && row.consumption) {
        header = `Consumption ${filters.unitCsv(row.uom)}`
        titles.push({ header, dataKey: 'consumption' })
      } else if (row.utility) {
        header = `Cost ${filters.unitCsv('USD')}`
        titles.push({ header, dataKey: 'tableValue' })
      } else if (this.costsByChargeTypeWidget) {
        titles.push({ header: 'Month', dataKey: 'month' })
        this.chargesByVendorLabels.forEach((label) => {
          header = `${label.title} ${filters.unitCsv('USD')}`
          titles.push({ header, dataKey: label.name })
        })
      } else if (this.widget.type === 'highest_unit_cost_locations') {
        header = `Unit Cost $/${filters.unitCsv(row.uom)}`
        titles.push({ header, dataKey: 'value' })
      } else {
        header = `Cost ${filters.unitCsv('USD')}`
        titles.push({ header, dataKey: 'value' })
      }
      return titles
    },
    tableCsvBody() {
      let body = []
      this.allWidgetData.forEach((row) => {
        const bodyItem = {}
        if (row.location) {
          bodyItem.location = row.location
        }
        if (row.utility) {
          bodyItem.utility = row.utility
        }
        if (this.monthlyWidgetType && row.month) {
          bodyItem.month = row.month
        }
        if (this.vendorWidgetType && row.vendor) {
          bodyItem.vendor = row.vendor
        }
        if (this.consumptionWidgetType && row.uom && row.consumption) {
          bodyItem.consumption = filters.unitValueCsv(row.consumption, row.uom)
        } else if (row.utility) {
          bodyItem.tableValue = filters.unitValueCsv(row.tableValue, 'USD')
        } else if (this.costsByChargeTypeWidget) {
          bodyItem.month = row.month
          this.chargesByVendorLabels.forEach((label) => {
            bodyItem[label.name] = filters.unitValueCsv(row[label.name], 'USD')
          })
        } else if (this.widget.type === 'highest_unit_cost_locations') {
          bodyItem.value = `${(+row.unitCosts || 0).toFixed(2)}`
        } else {
          bodyItem.value = filters.unitValueCsv(row.costs, 'USD')
        }
        body.push(bodyItem)
      })
      if (this.widget.type === 'cost_by_utility') {
        body = body.reverse()
      }
      return body
    },
    tableFilterColumns() {
      const data = []
      const headers = document.querySelectorAll('.filters-list .filter-type')
      ;[].slice.call(headers).forEach((el) => {
        if (!_.isEmpty(el.innerText)) {
          data.push(el.innerText)
        }
      })
      return data
    },
    tableFilterBody() {
      const data = []
      const body = document.querySelectorAll(
        '.filters-list .filter-description',
      )
      ;[].slice.call(body).forEach((el) => {
        if (!_.isEmpty(el.innerText)) {
          data.push(el.innerText)
        }
      })
      return [data]
    },
    async getAllWidgetData() {
      let widgetData = _.cloneDeep(this.widgetData)
      const limit = 500
      const options = {
        customerId: this.currentCustomerId,
        widgetId: this.$route.params.widgetId,
      }
      while (widgetData.length < this.totalCount) {
        try {
          options.params = {
            offset: widgetData.length,
            limit,
          }
          const { data } = await dashboard.getWidgetData(
            options,
            this.widget.type,
          )
          widgetData = [...widgetData, ...data]
        } catch (error) {
          console.log('widget get data error', error)
          break
        }
      }
      return widgetData
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../styles/utilities';
.network-error-container {
  .content-inner {
    padding-right: 40px;
    padding-left: 40px;

    @include respond-to($phone) {
      padding: 15px 10px;
    }

    ::v-deep .filters-list {
      margin-left: -7px;
    }

    .mobile-export {
      position: fixed;
      bottom: 45px;
      right: 10px;
      z-index: 1;
      height: 54px;
      width: 54px;
      border-radius: 32px;
      background-color: #ffffff;
      box-shadow: 0 0 5px 0 rgba(124, 124, 124, 0.5);
      overflow: hidden;
      box-sizing: border-box;
      white-space: nowrap;
      transition: all 0.3s;

      &.disabled {
        width: 54px;

        &:after {
          background: url('../../../assets/icons/export.svg') no-repeat center
            center #b1b1b1;
        }
      }

      &-open {
        width: 176px;
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: 50%;
        left: 0;
        background-color: #dbdbdb;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background: url('../../../assets/icons/export.svg') no-repeat center
          center #0080ff;
      }

      &-button {
        padding: 0 60px 0 28px;
        font-size: 12px;
        line-height: 27px;
        color: #909399;

        &:hover {
          background-color: #f4f6fa;
        }
      }
    }

    ::v-deep .filter .filters-list:first-child {
      margin-top: 7px;
    }

    ::v-deep .widget-filter-show-container {
      width: 100%;
      padding: 0 40px;

      .filters-main-title {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .filters-list {
          max-width: 33%;
          padding-right: 40px;
          margin-left: 0;
          box-sizing: border-box;

          li {
            flex-direction: column;

            .filter-type {
              margin-bottom: 5px;
            }

            .filter-description {
              padding-left: 0;
            }
          }

          &:last-child {
            padding-right: 0;
          }
        }

        .filter-edit {
          display: none;
        }
      }
    }

    .page-actions.mobile-filter {
      margin: 0 -40px;
      padding: 0;
      background-color: #fff;
    }

    .mobile-filter ::v-deep .widget-filter-show-container {
      .filters-main-title {
        margin-bottom: 0;
        flex-direction: column;

        .filters-list {
          max-width: 100%;
          margin: 5px 0;
          padding-right: 40px;
          box-sizing: border-box;

          li {
            flex-direction: row;

            .filter-type {
              font-size: 12px;
              line-height: 16px;
              padding-right: 5px;
              margin-bottom: 5px;
            }

            .filter-description {
              font-size: 12px;
              line-height: 16px;
              padding-left: 0;
            }
          }

          &:last-child {
            padding-right: 0;
          }
        }

        .filter-edit {
          display: none;
        }
      }
    }

    .create-filter {
      text-align: right;
    }

    ::v-deep div.widget-filter_actions {
      margin-top: 15px;
    }
  }

  .widget-filter-container {
    margin-top: 0;
  }

  .page-title {
    margin-right: auto;
    color: #5d5d5d;
    font-size: 28px;
    line-height: 38px;
    max-width: calc(100% - 250px);
    word-break: break-word;

    @include respond-to($phone) {
      max-width: 100%;
      margin-right: initial;
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 32px;
      border-bottom: 1px solid #0080ff;
    }
  }

  .page-actions {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
  }

  .table {
    &-container {
      padding: 20px 40px 0;
      margin: 10px -40px 0;
      border-top: 1px solid #e0e0e0;

      @include respond-to($phone) {
        overflow-x: scroll;
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
      }
    }

    &-title {
      margin-bottom: 15px;
      color: #5d5d5d;
      font-size: 22px;
      font-weight: 900;
    }

    ::v-deep th {
      padding: 8px 0;
    }

    ::v-deep td {
      padding: 5px 0;

      &.utility {
        .cell {
          text-transform: capitalize;
        }
      }
    }
  }

  ::v-deep .chart-filter {
    position: relative;
    padding: 0;
    height: 32px;
    width: 32px;
    margin-right: 30px;
    border: 1px solid transparent;
    background-color: #409eff;
    border-radius: 50%;
    box-sizing: border-box;

    .icon {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
    }

    &.is-active {
      border-color: #409eff;
      background-color: #fff;
    }

    @include respond-to($phone) {
      width: 26px;
      height: 26px;

      .icon {
        top: 5px;
        transform: translateX(-50%) scale(0.8);
      }
    }
    @include respond-to($phone-l) {
      width: 26px;
      height: 26px;

      .icon {
        top: 5px;
        transform: translateX(-50%) scale(0.8);
      }
    }
  }

  .no-data {
    margin: 50px 37px;
    color: #7f7f7f;
    font-size: 22px;
    font-weight: 900;
    line-height: 30px;
  }
}
</style>

<style lang="scss">
@import './../../../styles/utilities';

.widget-filter {
  padding: 25px;
  border-top-right-radius: 0;
  box-shadow: 0 0 4px 0 rgba(159, 159, 159, 0.5);

  > .el-icon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 16px;
    cursor: pointer;
  }
}

.el-table.table.el-table--fit.wide {
  @include respond-to($phone) {
    min-width: 900px;
  }
}
</style>
