// todo: fix configs
let application = {}
if (window.location.host === 'app.pear.ai') {
  application = {
    msal: {
      clientId: 'e62cd275-3474-4789-b245-86041ebe2212',
      scopes: null,
      tenant_name: 'exretailb2c',
      user_flow_name: 'B2C_1A_PearAI_signup_signin',
    },
    constellation_energy_manager: {
      host: 'https://energymanager.constellation.com',
    },
  }
} else if (window.location.host === 'app-qa.pear.ai') {
  application = {
    msal: {
      clientId: '391e8754-c3dd-4682-9ada-58564c886638',
      scopes: null,
      tenant_name: 'exretailb2ctest',
      user_flow_name: 'B2C_1A_PearAI_signup_signin',
    },
    constellation_energy_manager: {
      host: 'https://energymanagertest.constellation.com',
    },
  }
} else {
  application = {
    msal: {
      clientId: '63b26000-4bff-47b8-80f6-4e7c74913239',
      scopes: null,
      tenant_name: 'peardevb2c',
      user_flow_name: 'B2C_1_pear_dev',
    },
    constellation_energy_manager: {
      host: 'https://admin-dev.pear.ai',
    },
  }
}

export { application }
