<template>
  <div class="form-reports-components">
    <validation-observer v-slot="{ validate }">
      <el-form class="template-form" label-position="top">
        <div class="delivery-option">
          <base-select
            :rules="'required'"
            :label="report.formBody.deliveryOptions.label"
            v-model="deliveryOptionsValue"
            :isEditMode="isEditMode"
            size="medium"
          >
            <el-option
              v-for="(v, i) in report.formBody.deliveryOptions.selectableValues"
              :key="i"
              :value="v"
            />
          </base-select>
          <base-date-picker
            v-if="isScheduledReport"
            :rules="'required'"
            name="date"
            :picker-options="pickerOptions"
            :label="report.formBody.startDate.label"
            @change="handleRecurringTypes"
            v-model="startDate"
            :isEditMode="isEditMode"
            size="medium"
          />
          <base-select
            v-if="isScheduledReport"
            :rules="'required'"
            :label="report.formBody.recurringType.label"
            v-model="recurringTypeValue"
            :isEditMode="isEditMode"
            size="medium"
          >
            <el-option v-for="(v, i) in selectableValues" :key="i" :value="v" />
          </base-select>
          <base-select
            v-if="isScheduledReport"
            filterable
            :rules="'required'"
            :label="report.formBody.selectTime.label"
            v-model="time"
            :isEditMode="isEditMode"
            size="medium"
          >
            <el-option
              v-for="(v, i) in report.formBody.selectTime.selectableValues"
              :key="i"
              :value="v"
              :disabled="shouldTimeSlotBeDisabled(v)"
            />
          </base-select>
        </div>
        <validation-observer ref="delivery" v-slot="{ validate }">
          <delivery-methods
            ref="deliveryMethods"
            :report="report"
            @update-email-recipients="updateEmailRecipients"
            @delete-email-recipients="deleteEmailRecipients"
            @update-sftp-conn-id="updateSftpConnectionId"
          />
        </validation-observer>
      </el-form>
      <div class="buttons-container">
        <disabled-button
          :isEditMode="report.previewable"
          :buttonName="'Preview Report'"
          @click="openPreview"
        >
          <p>Preview is not available with this type of report</p>
        </disabled-button>
        <el-button
          type="primary"
          class="button"
          @click.prevent="validate().then(() => onSubmit())"
        >
          Create Report
        </el-button>
      </div>
    </validation-observer>
    <preview
      v-if="report.previewable"
      :visible.sync="showPreview"
      :previewList="previewList"
      :locations="filtersData.locations"
      :report="report"
      @close="closePreview"
      :previewLoading="previewLoading"
      :totalAmount="previewTotalAmount"
    />
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { createReports } from '@/api'
import Preview from '../Tabs/Create/Preview'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import BaseDatePicker from '@/components/FormComponents/BaseDatePicker'
import InputWithTags from '@/components/FormComponents/InputWithTags'
import DisabledButton from '@/components/Buttons/DisabledButton'
import { DateTime, LocalZone } from 'luxon'
import * as _ from 'lodash'
import BaseSelectMultiple from '@/components/FormComponents/BaseSelectMultiple'
import DeliveryMethods from './DeliveryMethods'

export default {
  props: {
    report: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DeliveryMethods,
    BaseSelectMultiple,
    BaseSelect,
    BaseDatePicker,
    InputWithTags,
    ValidationObserver,
    DisabledButton,
    Preview,
  },
  data() {
    return {
      isEditMode: true,
      deliveryOptionsValue: 'Run Now',
      recurringTypeValue: 'Does not repeat',
      time: '01:00 PM',
      startDate: DateTime.local().toFormat('yyyy-LL-dd'),
      selectedFormat: '',
      showPreview: false,
      sftpConnectionId: null,
      changedRecurringType: '',
      selectedRange: 'all_time',
      emailRecipientsValue: '',
      deliveryMethodsValue: [],
      pickerOptions: {
        disabledDate(date) {
          const today = new Date()
          const yesterday = new Date(today)
          yesterday.setDate(yesterday.getDate() - 1)
          return date < yesterday
        },
      },
      localTimeZone: new LocalZone(),
      emailRecipients: [],
      selectableValues: [],
    }
  },
  created() {
    this.handleRecurringTypes(this.startDate)
    this.checkFirstAvailableTimeSlot()
    const callback = this.checkInitialTimeSlot
    setInterval(function () {
      callback()
    }, 10000)
  },
  watch: {
    recurringTypeValue: function (newValue) {
      this.checkInitialTimeSlot()
    },
    startDate: function (newValue) {
      this.checkInitialTimeSlot()
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
      filtersData: 'reports/filtersData/bli_filters',
      previewLoading: 'reports/previewLoading',
      previewTotal: 'reports/previewTotal',
      previewList: 'reports/preview',
      currentCustomerId: 'customers/currentCustomerId',
    }),
    previewTotalAmount() {
      return +this.previewTotal || 0
    },
    isScheduledReport() {
      return this.deliveryOptionsValue === 'Scheduled Report'
    },
  },
  methods: {
    ...mapActions({
      fetchPreview: 'reports/fetchPreview',
    }),
    ...mapMutations({
      resetScheduledState: 'scheduledReports/resetState',
    }),
    emitDelete(tag) {
      this.$emit('emit-delete', tag)
    },
    deleteEmailRecipients(e) {
      this.emailRecipients = []
    },
    handleDeleteEmail(tag) {
      this.emailRecipients.splice(this.emailRecipients.indexOf(tag), 1)
    },
    closePreview() {
      this.showPreview = false
    },
    openPreview() {
      const body = {
        reportTemplateId: this.report.id,
        appliedFilters: this.report.appliedFilters,
      }
      this.fetchPreview({
        params: {
          ..._.omitBy(this.report.appliedFilters, _.isEmpty),
        },
        body,
      })
      this.showPreview = true
    },
    updateEmailRecipients(e) {
      this.emailRecipients = e
    },
    updateSftpConnectionId(e) {
      this.sftpConnectionId = e
    },
    transformRecurringType() {
      let recurringTypeArrOfWords = this.recurringTypeValue.split(' ')
      let recurringTypeArrOfWordsFiltered = recurringTypeArrOfWords.filter(
        (word) => word !== 'on' && word !== 'the',
      )
      let [type, valuePrefix, valueSuffix] = recurringTypeArrOfWordsFiltered
      valuePrefix = _.startCase(valuePrefix)
      type = type.toUpperCase()
      let dateValidation = DateTime.fromFormat(valuePrefix, 'LLLL').isValid
      let month = DateTime.fromFormat(valuePrefix, 'LLLL').toFormat('LLL')
      if (this.recurringTypeValue === 'Does not repeat') {
        return 'ONCE'
      }
      if (this.recurringTypeValue === 'Daily') {
        return 'DAILY'
      }
      if (this.recurringTypeValue === 'Every weekday') {
        return 'WEEKDAYS'
      }
      return `${type}: ${dateValidation ? month : valuePrefix}${
        valueSuffix ? '-' : ''
      }${
        valueSuffix
          ? !_.isNaN(parseInt(valueSuffix))
            ? parseInt(valueSuffix)
            : valueSuffix
          : ''
      }`
    },
    async onSubmit() {
      const { emailTagsInput } = this.$refs.deliveryMethods.$refs
      if (emailTagsInput.inputValue && emailTagsInput.inputValue.length) {
        emailTagsInput.addNew()
      }
      if (
        this.$refs.deliveryMethods.hasEmailDelivery &&
        !this.emailRecipients.length
      ) {
        return this.$message({
          showClose: true,
          message: `Please enter at least one email recipient!`,
          type: 'error',
        })
      }
      let { customerId } = this.$route.params
      let body
      this.checkInitialTimeSlot()
      if (this.isScheduledReport) {
        if (this.recurringTypeValue === 'Does not repeat') {
          if (
            ((this.report.appliedFilters &&
                this.report.appliedFilters['due_date'] &&
              this.report.appliedFilters['due_date'].selectVal ===
                'Day Of Report Being Run') ||
              (this.report.appliedFilters &&
                 this.report.appliedFilters['statement_date'] &&
                this.report.appliedFilters['statement_date'].selectVal ===
                  'Day Of Report Being Run')) &&
            this.report.readableId === 'CUSTOMER_BILL_DETAILS'
          ) {
            if (
              this.report.appliedFilters['due_date'] &&
              this.report.appliedFilters['due_date'].selectVal ===
                'Day Of Report Being Run'
            ) {
              const dueDate = this.report.appliedFilters['due_date']
              if (dueDate['min']) {
                dueDate['min'] = DateTime.fromISO(this.startDate).toISO()
                dueDate['max'] = DateTime.fromISO(this.startDate)
                  .plus({
                    months: this.report.appliedFilters['due_date'].noMonths,
                  })
                  .toISO()
              }
              if (dueDate['start_date']) {
                dueDate['start_date'] = DateTime.fromISO(this.startDate).toISO()
                dueDate['end_date'] = DateTime.fromISO(this.startDate)
                  .plus({
                    months: this.report.appliedFilters['due_date'].noMonths,
                  })
                  .toISO()
              }
              this.report.appliedFilters['due_date'].date = this.startDate
            }
            if (
              this.report.appliedFilters['statement_date'] &&
              this.report.appliedFilters['statement_date'].selectVal ===
                'Day Of Report Being Run'
            ) {
              const statementDate = this.report.appliedFilters['statement_date']
              if (statementDate['min']) {
                statementDate['max'] = DateTime.fromISO(this.startDate).toISO()
                statementDate['min'] = DateTime.fromISO(this.startDate)
                  .minus({
                    months:
                      this.report.appliedFilters['statement_date'].noMonths,
                  })
                  .toISO()
              }
              if (statementDate['start_date']) {
                statementDate['end_date'] = DateTime.fromISO(
                  this.startDate,
                ).toISO()
                statementDate['start_date'] = DateTime.fromISO(this.startDate)
                  .minus({
                    months:
                      this.report.appliedFilters['statement_date'].noMonths,
                  })
                  .toISO()
              }
              this.report.appliedFilters['statement_date'].date = this.startDate
            }
          } else if (
            this.report.appliedFilters &&
            this.report.appliedFilters.selectVal ===
              'Day Of Report Being Run' &&
            (this.report.readableId === 'CUSTOMER_BILLING_LINE_ITEMS' ||
              this.report.readableId === 'CUSTOMER_BILL_DETAILS_PLUS_LOCATION')
          ) {
            this.report.appliedFilters['end_date'] = DateTime.fromISO(
              this.startDate,
            ).toISO()
            this.report.appliedFilters['start_date'] = DateTime.fromISO(
              this.startDate,
            )
              .minus({
                months: this.report.appliedFilters.noMonths,
              })
              .toISO()
            this.report.appliedFilters.date = this.startDate
          }
        }

        body = {
          reportTemplateId: this.report.id,
          customerId: +customerId,
          userId: +this.currentUser.id,
          saveFormat: this.report.saveFormat,
          recurringType: this.transformRecurringType(),
          startDate: this.startDate,
          sftpConnectionId: this.sftpConnectionId,
          time: this.time,
          customReportName: this.report.customReportName || this.report.title,
          appliedFilters: this.report.appliedFilters,
          emailRecipients: this.emailRecipients,
          timezone: this.localTimeZone.name,
        }
      } else {
        body = {
          reportTemplateId: this.report.id,
          customerId: +customerId,
          userId: +this.currentUser.id,
          saveFormat: this.report.saveFormat,
          customReportName: this.report.customReportName || this.report.title,
          appliedFilters: this.report.appliedFilters,
          emailRecipients: this.emailRecipients,
          sftpConnectionId: this.sftpConnectionId,
          timezone: this.localTimeZone.name,
        }
      }
      try {
        if (this.isScheduledReport) {
          this.resetScheduledState()
          await createReports.postScheduledReport(customerId, body)
        } else {
          await createReports.postRunNowReport(customerId, body)
        }
        this.$emit('report-created', this.report.title)
        this.$message({
          showClose: true,
          message: `Report created successfully. Please go to ${
            this.isScheduledReport ? 'Scheduled' : 'Completed'
          } Reports page for more information.`,
          type: 'success',
        })
      } catch (e) {
        this.$message({
          showClose: true,
          message: 'Your report could not be created. Please try again later.',
          type: 'error',
        })
      }
    },
    daySuffix(d) {
      if (d > 3 && d < 21) return 'th'
      switch (d % 10) {
        case 1:
          return 'st'
        case 2:
          return 'nd'
        case 3:
          return 'rd'
        default:
          return 'th'
      }
    },
    handleRecurringTypes(date) {
      if (!_.isNil(date)) {
        const day = DateTime.fromISO(date).toFormat('EEEE')
        const dayAsNumber = DateTime.fromISO(date).toFormat('d')
        const month = DateTime.fromISO(date).toFormat('MMMM')
        const weeksOfMonth = ['first', 'second', 'third', 'fourth', 'last']
        let weekInMonth =
          DateTime.fromISO(date).weekNumber -
          DateTime.fromISO(date).startOf('month').weekNumber
        let dayMonth

        dayMonth = `${month} ${dayAsNumber}${this.daySuffix(+dayAsNumber)}`
        const weekNo = weeksOfMonth[weekInMonth] || 'last'
        this.selectableValues = []
        this.selectableValues.push(
          'Does not repeat',
          'Daily',
          'Every weekday',
          `Weekly on ${day}`,
          `Monthly on the ${weekNo} ${day}`,
          `Annually on ${dayMonth}`,
        )
        const recurringTypeArrOfWords = this.recurringTypeValue.split(' ')
        const [prefix] = recurringTypeArrOfWords
        const prefixLowerCase = prefix.toLowerCase()
        switch (prefixLowerCase) {
          case 'weekly':
            this.recurringTypeValue = this.selectableValues[3]
            break
          case 'monthly':
            this.recurringTypeValue = this.selectableValues[4]
            break
          case 'annually':
            this.recurringTypeValue = this.selectableValues[5]
            break
        }
      }
    },
    shouldTimeSlotBeDisabled(time) {
      let crtTime = DateTime.now()
      let runTime = DateTime.fromFormat(
        this.startDate + ' ' + time,
        'yyyy-LL-dd hh:mm a',
      )
      if (this.recurringTypeValue === 'Does not repeat' && runTime < crtTime) {
        return true
      }
      return false
    },
    checkFirstAvailableTimeSlot() {
      let crtTime = DateTime.now()
      for (let time of this.report.formBody.selectTime.selectableValues) {
        let runTime = DateTime.fromFormat(
          crtTime.toFormat('yyyy-LL-dd') + ' ' + time,
          'yyyy-LL-dd hh:mm a',
        )
        if (runTime >= crtTime) {
          this.time = time
          break
        }
      }
    },
    checkInitialTimeSlot() {
      let crtTime = DateTime.now()
      let runTime = DateTime.fromFormat(
        this.startDate + ' ' + this.time,
        'yyyy-LL-dd hh:mm a',
      )
      if (this.recurringTypeValue === 'Does not repeat' && runTime < crtTime) {
        this.checkFirstAvailableTimeSlot()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.form-reports-components {
  .button-container {
    display: flex;
    justify-content: center;
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 2em;

    .el-button {
      //padding: 10px;
    }
  }
  .button {
    font-size: 16px;
    font-weight: 600;
  }
  .template-form {
    display: flex;
    flex-direction: column;
  }

  .delivery-option {
    display: inline-flex;
    flex-wrap: wrap;
    span {
      margin-right: 10px;
    }
  }

  .sftp-name {
    margin: 0;
  }

  .sftp-host-and-folder {
    margin: 0;
    font-size: 10px;
    line-height: 10px;
  }

  .options {
    height: 55px;
  }

  .delivery-option > div {
    flex: 1 1 auto;
    border: 1px red solid;
    text-align: center;
    margin: 5px;
  }
}
</style>
