import Vue from 'vue'
import Router from 'vue-router'
import { stringify, parse } from 'qs'

import { router as Auth } from '../pages/Auth'
import { router as Dashboard } from '../pages/Dashboard'
import ErrorPage from '@/pages/ErrorPage'

import store from '../store'
import HomeNoCustomers from '@/pages/HomeNoCustomers'

const originalPush = Router.prototype.push
Router.prototype.push = function (location) {
  return originalPush.call(this, location).catch((err) => {
    console.warn('NavigationDuplicated')
    return err
  })
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  history: true,
  hashbang: false,
  parseQuery: parse,
  stringifyQuery: function () {
    const res = stringify(...arguments)
    return res ? `?${res}` : ''
  },
  routes: [
    {
      component: {
        template: '<router-view></router-view>',
      },
      path: '/error',
      children: [
        {
          path: '404',
          name: 'not.found',
          component: ErrorPage,
          meta: {
            title: 'Page not found',
            subtitle: 'This page does not exist.',
          },
        },
        {
          path: '403',
          name: 'forbidden',
          component: ErrorPage,
          meta: {
            title: 'Forbidden',
            subtitle:
              "You don't have permission to access requested resource or page.",
          },
        },
      ],
    },
    {
      name: 'home.no.customer',
      path: '/home/no-customers',
      component: HomeNoCustomers,
      meta: {
        auth: true,
        title: 'pear.ai',
      },
    },
    Auth,
    Dashboard,
    {
      path: '*',
      name: 'notFound',
      component: ErrorPage,
      meta: {
        title: 'Page not found',
        subtitle: 'This page does not exist.',
      },
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('router/updateCurrentRoute', { to, from, next })
})

router.afterEach((to, from, next) => {
  if (window.ga) {
    window.ga('set', 'page', to.path)
    window.ga('send', 'pageview')
  }

  if (
    to.name === 'dashboard.sam' ||
    to.name === 'not.found' ||
    to.path.includes('auth')
  ) {
    document.body.classList.add('elevio-hide')
  } else {
    document.body.classList.remove('elevio-hide')
  }

  if (from.name === 'dashboard.sam') {
    Vue.nextTick(() => {
      if (window.scrollY > 0) {
        window.scrollTo(0, 0)
      }
    })
  }
})

export default router
