<template lang="pug">
.payments-details
  .payments-details-container
    .payments-details-items-container(v-for="bill in bills", :key="bill.id")
      .payments-details-item
        .payments-details-item-row
          .payments-details-item-row-col
            span.label Bill ID
            span.value
              router-link(:to="{ path: `/${currentCustomerId}/bills/${bill.billId}` }") {{ bill.billId }}
          .payments-details-item-row-col
            span.label Amount Due
            span.value.sum {{ bill.totalAmountDue | unit('USD') }}
        .payments-details-item-row
          .payments-details-item-row-col
            span.label Due Date
            span.value {{ bill.dueDate | datetime('LL/dd/yyyy') }}
          .payments-details-item-row-col
            span.label Invoice Date
            span.value {{ bill.invoiceDate | datetime('LL/dd/yyyy') }}
        .payments-details-item-row
          .payments-details-item-row-col
            span.label Vendor
            span.value {{ bill.vendorName }}
          .payments-details-item-row-col
            span.label Billing ID
            span.value {{ bill.vendorBillingId }}
        .payments-details-item-row(v-if="isBillPayEntitlement")
          .payments-details-item-row-col
            span.label A/P Request
            span.value {{ bill.totalAmountDue | unit('USD') }}
          .payments-details-item-row-col
            span.label Amount Paid
            span.value {{ amountPaid | unit('USD') }}
        .payments-details-status-row(v-if="isBillPayEntitlement")
            span.label Status
            span(:class="billStatusClass(bill.billId)" class="pill") {{ billStatusText(bill.billId) }}
</template>

<script>
import { STATUSES } from './helpers'
import { mapGetters } from 'vuex'

export default {
  props: {
    bills: {
      type: Array,
      required: true,
      default: () => [],
    },
    currentCustomerId: {
      type: Number,
      required: true,
      default: () => '',
    },
  },
  data() {
    return {
      amountPaid: 0,
    }
  },
  computed: {
    ...mapGetters({
      isBillPayEntitlement: 'customers/isBillPayEntitlement',
    }),
  },
  methods: {
    billStatusText(billId) {
      let statusIndex = billId % STATUSES.length
      return STATUSES[statusIndex].text
    },
    billStatusClass(billId) {
      let statusIndex = billId % STATUSES.length
      return (
        'status-' + STATUSES[statusIndex].code.replace(/_/g, '-').toLowerCase()
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../styles/_utilities';
@import './../../../styles/bill-statuses';

.payments-details {
  z-index: 9999999;
}
a {
  color: #409eff;
}
.payments-details-container {
  margin-bottom: 64px;

  h3 {
    color: #393a3c;
    font-size: 20px;
    font-weight: 900;
    line-height: 27px;
    text-align: center;
    margin-bottom: 3px;
  }

  .total-charges {
    color: #606266;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    text-align: center;
  }

  .payments-details-items-container {
    margin-top: 9px;
    .payments-details-item {
      border: 1px solid #dcdfe6;
      padding: 16px 20px;
      margin-bottom: 9px;
      box-shadow: 2px 0 6px 0 rgba(227, 208, 208, 0.5);
      border-radius: 4px;

      &-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        &-col {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 50%;
        }
      }

      span {
        &.label {
          color: #909399;
          font-size: 12px;
          font-weight: 900;
          line-height: 17px;
        }

        &.value {
          color: #606266;
          font-size: 16px;
          font-weight: 900;
          line-height: 14px;
          margin-top: 5px;

          &.sum {
            color: #606266;
            font-size: 20px;
            font-weight: 900;
            line-height: 14px;
          }
        }
      }

      button {
        color: #0080ff;
        font-size: 16px;
        font-weight: 900;
        line-height: 14px;
        text-decoration: underline;
        margin-left: 0;
        padding: 0;
        margin-top: 5px;
      }

      .payments-details-status-row {
        display: flex;
        flex-direction: column;
        width: fit-content;
      }
    }
  }
}
</style>
