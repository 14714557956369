<template lang="pug">
.bills-list
  .page-header
    .page-title Bills
    .bills-list-total Qty of bills:
      b {{ total || 'N/A' }}
  filter-container
  el-table.bills-table(ref="billsTable",
                       @sort-change='sortTable',
                        @row-click='handleClick',
                       :data='bills',
                       data-element="bills-list",
                      :cell-style='() => "border-bottom: 1px solid #ebeef5 !important;"'
  )
    el-table-column(label='Invoice Date', prop='statementDate', sortable)
      template(slot-scope='scope')
        span(data-type='bill-date', data-testid="invoiceDateColumn") {{ scope.row['statementDate'] | datetime('LL/dd/yyyy') }}
    el-table-column(label='Vendor', prop='vendor', sortable)
      template(slot-scope='scope')
        span(data-testid="vendorColumn") {{ getPrettyVendorByCode(scope.row['vendor']) }}
    el-table-column(label='Billing ID', prop='clientAccount', sortable)
      template(slot-scope='scope')
        span(data-testid="billingIDColumn") {{ scope.row['clientAccount'] }}
    el-table-column(label='Amount Due', prop='amountDue', sortable="true", label-class-name='amount-due', class-name='amount-due' @sort-method='sortBy')
      template(slot-scope='scope')
        span(data-type='bill-amount', data-testid="amountDueColumn") {{ scope.row['amountDue'] | currency }}
    el-table-column(label='Billing Start Date', prop='billingStartDate')
      template(slot-scope='scope')
        span(data-type='billing-start-date', data-testid="billingStartDateColumn") {{ scope.row['billingStartDate'] | datetime('LL/dd/yyyy') }}
    el-table-column(label='Billing End Date', prop='billingEndDate')
      template(slot-scope='scope')
        span(data-type='billing-end-date', data-testid="billingEndDateColumn") {{ scope.row['billingEndDate'] | datetime('LL/dd/yyyy') }}
    el-table-column(label='Current Charges', prop='currentCharges')
      template(slot-scope='scope')
        span(data-type='current-charges', data-testid="currentChargesColumn") {{ scope.row['currentCharges'] | currency }}
    el-table-column(label='Marked For Payment', prop='markedForPayment', v-if="customer.entitlements.payments")
      template(slot-scope='scope')
        span(data-type='marked-for-payment', data-testid="markedForPaymentColumn") {{ scope.row['markedForPayment'] | datetime('LL/dd/yyyy') }}

</template>

<script>
import FilterContainer from './Filters/FilterContainer.vue'
import { isNil } from 'ramda'
import { mapGetters } from 'vuex'
import { hosts } from '@/lib/api.configs'
import { serializeOrderBy } from '@/lib/helpers'

export default {
  components: {
    FilterContainer,
  },
  data() {
    return {
      API_HOST: hosts.api,
    }
  },
  computed: {
    ...mapGetters({
      order: 'bills/filters/order',
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
      total: 'bills/total',
      bills: 'bills/bills',
      jwt: 'user/authToken',
      customer: 'customers/customer',
    }),
  },
  methods: {
    sortTable({ prop, order }) {
      const { path, query } = this.$route

      if (isNil(prop) || isNil(order)) {
        this.$router.push({
          path: path,
          query: {
            ...query,
            orderBy: [],
          },
        })
      } else {
        this.$router.push({
          path: path,
          query: {
            ...query,
            orderBy: [serializeOrderBy(prop, order)],
          },
        })
      }
    },
    sortBy(a, b) {
      if (typeof a !== 'number' && typeof b !== 'number') {
        return 0
      }
      if (typeof a !== 'number' || typeof b !== 'number') {
        return -1
      }
      return a - b
    },
    handleClick(row) {
      this.$router.push({
        name: 'dashboard.bills.info',
        params: { billId: row.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/utilities';

.bills {
  &-table {
    .el-button {
      padding: {
        top: 9px;
        bottom: 9px;
      }
      text-decoration: underline;
    }

    .el-button:hover {
      font-weight: bold;
    }
  }
}
.bills-list-total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #7d89a6;
  font-size: 12px;

  b {
    padding-left: 10px;
    color: #3b3e48;
    font-size: 16px;
    font-weight: 900;
  }
}
</style>

<style lang="scss">
.bills-table {
  th {
    font-weight: 500;
  }
  .amount-due {
    font-weight: 900;
  }
}
</style>
