<template lang="pug">
  .bill-control.control
    .bill-control_list
      .bill-control_item(v-for="bodyItem in messageData.data", @click="selectBill(bodyItem.id)")
        .info-item
          span.label Vendor
          span.value {{ getPrettyVendorByCode(bodyItem.vendor) }}
        .info-item
          span.label Invoice Date
          span.value {{ bodyItem.invoice_date }}
</template>

<script>
import { mapGetters } from 'vuex'
import { sendEventToChatbase } from '../../../../api/stats'
export default {
  props: {
    messageData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
      currentCustomerId: 'customers/currentCustomerId',
    }),
  },
  methods: {
    selectBill(id) {
      sendEventToChatbase(
        this.currentCustomerId,
        'same_tab',
        'select_bill_from_list',
      ).catch((err) => {
        console.error('CHATBASE ERROR: ', err)
      })

      const message = this.messageData.action.bubble_text
      const [match] = message.match(/\{([^}]+)\}/)
      this.$emit('sendMessage', message.replace(match, id))
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../../styles/_utilities';

.bill-control {
  @include respond-to($phone) {
  }

  &_list {
    width: 100%;
    max-width: 340px;
    padding: 10px;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #dcdfe6;
    border-radius: 0 4px 4px 4px;
    /*box-shadow: 0 2px 6px 0 rgba(110,112,113,0.5);*/
    margin-left: 62px;

    @include respond-to($phone) {
      margin-left: 0;
      border-radius: 4px;
    }
    @include respond-to($phone-l) {
      margin-left: 0;
    }
  }
  &_item {
    display: flex;
    align-items: center;
    min-height: 40px;
    padding: 8px 10px;
    border: 1px solid #2893f9;
    border-radius: 5px;
    background-color: #fff;
    box-sizing: border-box;
    cursor: pointer;
    transition: box-shadow 0.25s;

    + .bill-control_item {
      margin-top: 10px;
    }
    &:hover {
      background: #0080ff;
      color: #fff;

      .info-item {
        .label,
        .value {
          color: #fff;
        }
      }
    }
  }
}
.info-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 50%;

  + .info-item {
    margin-left: 20px;
  }
  .label {
    color: #5d6a89;
    font-size: 12px;
  }
  .value {
    margin-left: 8px;
    color: #3b3e48;
    font-size: 14px;
    word-break: break-all;
  }
}
</style>
