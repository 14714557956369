<template lang="pug">
.form-wrapper.settings-page
  .icon-wrapper
    i.icon.icon-sam-bot
    i.icon.icon-lamp
  .form-container
    .form
      h1.form-title Stay up-to-date
      p.form-text Choose how to receive notifications when pear.ai finds insights worth your attention

      .form-fields
        .form-field
          .switch-container
            span.switch-data Send Email notifications
            .switch-control
              input(type="checkbox",
                    name="email_notifications",
                    id="email_notifications",
                    class="ios8-switch",
                    v-model="notifications_email")
              label(for="email_notifications",
                    class="checkbox-label",
                    data-off="off",
                    data-on="on")
          .email-container
            span.email-label Email
            span.email-value {{ email }}
          .switch-container
            span.switch-data Send SMS notifications
            .switch-control
              input(type="checkbox",
                    name="sms_notifications",
                    id="sms_notifications",
                    class="ios8-switch",
                    v-model="notifications_sms")
              label(for="sms_notifications",
                    class="checkbox-label",
                    data-off="off",
                    data-on="on")
        .form-field
          .el-input.input-phone-number(:class='{error: phoneNumberError}')
            masked-input(v-model="phoneNumber",
                        name="inputPhoneNumber",
                        mask="\\+\\ 1 (111) 111-1111"
                        placeholder="+1 (000) 000 - 0000",
                        placeholder-char=" ",
                        :v-validate='phoneNumberError',
                        type="tel")
            label Mobile Phone
            .form-error.error-small(v-if='phoneNumberError') {{ phoneNumberError }}
            .sms-tip(v-if="notifications_sms")
              |By entering your mobile phone number and clicking "Save" below, you consent to receive text messages
              |sharing insights from your Pear.ai account, from or on behalf of Exelon Generation Services, LLC at the telephone number listed.
              |By clicking "Save", you acknowledge that this consent is not a condition of purchasing any good or services from
              |Exelon Generation Services, LLC or its affiliate. Your service provider’s standard data and message rates may apply.
      .form-actions
        el-button.button-save(type='primary',
        :disabled='submitButtonDisabled',
        @click.native='save') Save
        el-button.button-skip(type='primary',
        @click.native='skipSave') Not Now
      .tos-container
        span You can always change this configuration in the settings section.
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { pathOr } from 'ramda'
import MaskedInput from 'vue-masked-input'
import { user } from '@/api'
import { isValidNumber } from 'libphonenumber-js'

export default {
  components: {
    MaskedInput,
  },
  data() {
    return {
      phoneNumberErrorFromServer: false,
      phoneNumber: '',
      notifications_email: true,
      notifications_sms: false,
      password: '',
      validatePhoneNumberField: {
        required: this.notifications_sms,
      },
    }
  },
  computed: {
    ...mapState({ email: pathOr('', ['user', 'user', 'email']) }),
    submitButtonDisabled() {
      if (this.notifications_sms) {
        return !!this.phoneNumberError
      }

      if (!this.notifications_email && !this.notifications_sms) {
        return true
      }
    },
    phoneNumberError() {
      // eslint-disable-next-line no-useless-escape
      if (this.phoneNumber.replace(/[\s\+\(\)\-]/g, '').length === 11) {
        return this.notifications_sms && !isValidNumber(this.phoneNumber)
          ? 'Invalid Phone Number'
          : null
      }

      if (this.phoneNumberErrorFromServer) {
        return this.phoneNumberErrorFromServer
      }
    },
    phone_number() {
      return this.phoneNumber.slice().replace(/(\s+|\(|\)|-)/g, '')
    },
  },
  // watch: {
  //   phoneNumber () {
  //     this.phoneNumberErrorFromServer = false;
  //   }
  // },
  methods: {
    ...mapActions({ $changeProfile: 'user/changeProfile' }),
    async skipSave() {
      try {
        const profile = {
          onboarding_shown: true,
          notifications_settings: {
            notifications_email: true,
          },
        }
        await user.patch({ profile })
        await this.$changeProfile(profile)
        this.$router.push({ path: '/' })
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      if (this.phoneNumberError && this.notifications_sms) {
        return false
      }

      try {
        const { phone_number, notifications_sms, notifications_email } = this
        const profile = {
          ...(this.phone_number && { phone_number }),
          notifications_settings: { notifications_email, notifications_sms },
          onboarding_shown: true,
        }
        await user.patch({ profile })
        this.$router.push({ path: '/' })
      } catch (e) {
        console.log(e)
        const error = JSON.parse(pathOr('{}', ['response', 'data', 'error'], e))
        if (error['profile.phone_number']) {
          this.phoneNumberErrorFromServer =
            'Phone number required and should match phone number pattern.'
        }
      }
    },
    checkSpaces() {
      if (this.password.indexOf(' ') === -1) {
        return false
      }
      return true
    },
    clearPassword() {
      this.password = ''
    },
    clearConfirmPassword() {
      this.confirmPassword = ''
    },
    skip() {
      this.$router.push({ path: '/' })
    },
  },
}
</script>

<style scoped lang="scss">
@import './../../styles/utilities';

.form {
  min-width: 370px;

  @include respond-to($phone) {
    min-width: 240px;
  }
  .form-title {
    margin-bottom: 20px;

    @include respond-to($phone) {
      color: #3b3e48;
      margin-bottom: 15px;
    }
  }
  .sms-tip {
    margin-top: 8px;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
    color: #606266;
  }
  .form-text {
    color: #909399;
    font-size: 18px;
    line-height: 25px;
    font-weight: 900;

    @include respond-to($phone) {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 25px;
    }
  }
  .form-fields {
    .el-input {
      label {
        font-size: 12px;
        line-height: 16px;
        font-weight: 900;

        @include respond-to($phone) {
          color: #606266;
        }
      }
      input {
        padding-top: 17px;
        line-height: 30px;

        @include respond-to($phone) {
          color: #3b3e48;
        }
      }
      @include placeholder(#909399, 16px, 500);
    }
    .switch-container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      &:first-child {
        @include respond-to($phone) {
          margin-top: 0;
        }
      }
      @include respond-to($phone) {
        margin-top: 30px;
      }

      > span {
        color: #3b3e48;
        font-size: 18px;
        line-height: 25px;
        font-weight: 900;
      }
      .switch-control {
        input[type='checkbox'].ios8-switch {
          position: absolute;
          margin: 8px 0 0 16px;
        }
        input[type='checkbox'].ios8-switch + label {
          position: relative;
          height: 31px;
          display: flex;
          width: 51px;
          cursor: pointer;
        }
        input[type='checkbox'].ios8-switch + label:before {
          content: '';
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          height: 31px;
          width: 51px;
          box-sizing: border-box;
          border-radius: 16px; /* x*2 */
          background: #f5f5f5;
          border: 1px solid #d9d9d9;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
        }
        input[type='checkbox'].ios8-switch + label:after {
          content: '';
          position: absolute;
          display: block;
          left: 1px;
          top: 1px;
          border-radius: 16px;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
          box-sizing: border-box;
          height: 29px;
          width: 29px;
          border: 0.5px solid rgba(0, 0, 0, 0.04);
          background-color: #ffffff;
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15),
            0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 3px 1px 0 rgba(0, 0, 0, 0.1);
        }
        input[type='checkbox'].ios8-switch + label:hover:after {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }
        input[type='checkbox'].ios8-switch:checked + label:after {
          margin-left: 16px;
          left: 5px;
        }
        input[type='checkbox'].ios8-switch:checked + label:before {
          background: #007efd;
          border: 0;
        }
      }
    }

    .email-container {
      display: flex;
      flex-flow: column nowrap;
      margin-top: 13px;
      margin-bottom: 10px;

      > span {
        color: #4a4a4a;
        font-size: 12px;
        line-height: 16px;
        font-weight: 900;

        @include respond-to($phone) {
          color: #606266;
        }

        &:first-child {
          font-size: 12px;
          line-height: 16px;
          font-weight: 900;
        }

        &:last-child {
          color: #3b3e48;
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }

  .form-field {
    margin-bottom: 13px;
  }

  .form-actions {
    display: flex;
    flex-flow: row nowrap;

    button {
      height: 36px;
      font-size: 16px;
      /*line-height: 14px;*/
      font-weight: 700;
      flex: 1 1 50%;
      padding: 0;
      border-radius: 100px;
      background-color: #0080ff;
      margin-left: 0;

      &:hover {
        background-color: #026fda;
      }
      &[disabled] {
        background-color: #73acee;

        &:hover {
          background-color: #73acee;
        }
      }

      &:last-child {
        margin-left: 31px;
        border: 2px solid #0080ff;
        border-radius: 18px;
        background: #fff;
        color: #0080ff;

        &:hover {
          background-color: inherit;
        }
      }
    }
  }

  .tos-container {
    color: #7d89a6;
    font-size: 12px;
    line-height: 16px;
  }

  @include respond-to($phone) {
    .form-actions {
      margin-top: 20px;
      margin-bottom: 0;
      justify-content: center;

      button {
        flex: 0 0 120px;

        &:last-child {
          margin-left: 20px;
        }
      }
    }

    .tos-container {
      margin: 20px 32px;
      text-align: center;
    }
  }
}
</style>
