import { hosts } from '@/lib/api.configs'
import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'
import { groupWith, compose } from 'ramda'
import { DateTime } from 'luxon'
import filters from '@/lib/filters'
import qs from 'qs'

const processFeedBlock = (results) =>
  results.map((f) => ({
    title: filters.datetime(f[0].timePeriod, 'MMMM yyyy'),
    total: f.reduce((a, b) => a + b.totalCharges, 0),
    data: groupByBillId(
      (f || []).map((f) => ({
        ...f,
        date: filters.datetime(f.timePeriod, 'LL/dd/yyyy'),
      })),
    ),
    timePeriod: f[0].timePeriod,
  }))

const sortFeeds = (results) =>
  results.sort((a, b) => {
    const prev = DateTime.fromISO(String(a.timePeriod), {
      zone: 'utc',
    }).valueOf()
    const next = DateTime.fromISO(String(b.timePeriod), {
      zone: 'utc',
    }).valueOf()

    return next - prev
  })

const groupByBillId = (list) => groupWith((a, b) => a.billId === b.billId, list)

const groupByMonth = (results) =>
  groupWith((a, b) => {
    const monthA = DateTime.fromISO(String(a.timePeriod), {
      zone: 'utc',
    }).toObject().month
    const monthB = DateTime.fromISO(String(b.timePeriod), {
      zone: 'utc',
    }).toObject().month

    return monthA === monthB
  }, results)

const reduceFeeds = compose(processFeedBlock, groupByMonth, sortFeeds)

const list = ({ customerId, locationId, params = [] }) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/locations/${locationId}/bills-monthly-feed?${qs.stringify(
        params,
        { arrayFormat: 'repeat' },
      )}`,
    )
    .then(dataMiddleware)
    .then((data) => {
      return { results: reduceFeeds(data.results), dates: data.dates }
    })

const details = ({ customerId, locationId, blockId, startDate, endDate }) =>
  http(hosts.v1)
    .get(
      `/customers/${customerId}/locations/${locationId}/bills-monthly-feed/${blockId}/calculations?${qs.stringify(
        {
          startDate,
          endDate,
        },
        { arrayFormat: 'repeat' },
      )}`,
    )
    .then(dataMiddleware)

export { list, details }
