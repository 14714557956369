export const chargesByVendorLabels = [
  {
    name: 'commodityCharges',
    title: 'Commodity Charges',
    color: '#2470E5',
  },
  {
    name: 'consumptionCharges',
    title: 'Consumption Charges',
    color: '#84B2FA',
  },
  {
    name: 'customerCharges',
    title: 'Customer Charges',
    color: '#124BA2',
  },
  {
    name: 'demandCharges',
    title: 'Demand Charges',
    color: '#1E88B7',
  },
  {
    name: 'otherCharges',
    title: 'Other Charges',
    color: '#B8D4FF',
  },
  {
    name: 'taxesCharges',
    title: 'Taxes Charges',
    color: '#4B57D8',
  },
]
