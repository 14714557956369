import { hosts } from '@/lib/api.configs'
import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'
import { setPieChartUtilityColor } from '@/lib/helpers'
import qs from 'qs'
import store from '@/store'

/**
 * @description process widget data
 * @param {array} data
 * @param {string} widgetTyte
 */
const processWidgetData = (data, widgetType) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  if (data && widgetType === 'cost_by_utility') {
    const total = Number(
      (data || [])
        .reduce((s, k) => (s += parseFloat(k.costs) || 0), 0)
        .toFixed(2),
    )

    return data
      .map((e) => ({
        value: Math.round(
          ((parseFloat(e.costs) || 0).toFixed(2) / total) * 100,
        ),
        tableValue: e.costs,
        utility: e.utilityType.toLowerCase(),
        color: setPieChartUtilityColor(e.utilityType),
      }))
      .sort((a, b) => a.tableValue - b.tableValue)
  }
  if (data && widgetType === 'highest_cost_locations') {
    return data
      .map((e) => ({
        location: e.name,
        value: e.totalCharges,
        costs: e.totalCharges,
      }))
      .sort((a, b) => b.value - a.value)
  }
  if (data && widgetType === 'highest_consumption_locations') {
    return data
      .map((e) => ({
        location: e.name,
        value: e.totalConsumption,
        consumption: (+e.totalConsumption || 0).toFixed(2),
        uom: e.totalConsumptionUom,
      }))
      .sort((a, b) => b.value - a.value)
  }

  if (data && widgetType === 'highest_unit_cost_locations') {
    return data
      .map((e) => ({
        location: e.name,
        value: e.unitCosts,
        unitCosts: e.unitCosts,
        uom: e.totalConsumptionUom,
      }))
      .sort((a, b) => b.value - a.value)
  }

  if (data && widgetType === 'monthly_cost_by_location') {
    return data
      .map((e) => ({
        month: months[e.month - 1],
        value: e.charges,
        costs: e.charges,
      }))
      .sort((a, b) => b.month - a.month)
  }

  if (data && widgetType === 'monthly_consumption_by_location') {
    return data
      .map((e) => ({
        month: months[e.month - 1],
        value: e.consumption,
        consumption: (+e.consumption || 0).toFixed(2),
        uom: e.uom,
      }))
      .sort((a, b) => b.month - a.month)
  }

  if (data && widgetType === 'charges_by_vendor') {
    const getPrettyVendorByCode =
      store.getters['resources/getPrettyVendorByCode']
    return data
      .map((e) => ({
        value: e.charges,
        vendor: getPrettyVendorByCode(e.vendorCode),
        costs: e.charges,
      }))
      .sort((a, b) => b.value - a.value)
  }

  if (data && widgetType === 'costs_by_charge_type') {
    return data
      .map((e) => ({
        commodityCharges: e.commodityCharges,
        consumptionCharges: e.consumptionCharges,
        customerCharges: e.customerCharges,
        demandCharges: e.demandCharges,
        month: months[e.month - 1],
        otherCharges: e.otherCharges,
        taxesCharges: e.taxesCharges,
      }))
      .sort((a, b) => b.month - a.month)
  }

  return []
}

/**
 * @description update widget order
 * @param {string} customerId
 * @param {object} order contains id and sort_order fields
 */
const reorderWidgets = ({ customerId, body }) =>
  http(hosts.v1)
    .post(`/customers/${customerId}/widgets/reorder`, body)
    .then(dataMiddleware)
    .then((data) => ({
      meta: data.meta || {},
      data: data.data || [],
    }))

/**
 * @description load widgets
 * @param {string} customerId
 */
const getWidgets = (customerId) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/widgets`)
    .then(dataMiddleware)
    .then((data) => ({
      meta: data.meta || {},
      data: data.data || [],
    }))

/**
 * @description create new widget
 * @param {object} widget
 */
const createWidget = (customerId, widget) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/widgets`, widget)
    .then(dataMiddleware)

/**
 * @description get widget data
 * @param {string} customerId
 * @param {string} widgetId
 */
const getWidget = ({ customerId, widgetId }) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/widgets/${widgetId}`)
    .then(dataMiddleware)

/**
 * @description get widget meta data
 * @param {string} customerId
 * @param {string} widgetType
 * @param {object} filters
 */
const getWidgetMeta = ({ customerId, widgetType, filters = {} }) =>
  http(hosts.v1)
    .get(
      `/customers/${customerId}/widgets-metadata/${widgetType}?${qs.stringify(
        filters,
        { arrayFormat: 'repeat' },
      )}`,
    )
    .then(dataMiddleware)

/**
 * @description get widget data
 * @param {string} customerId
 * @param {string} widgetId
 * @param {object}
 */
const getWidgetData = ({ customerId, widgetId, params }, widgetType) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/widgets/${widgetId}/data?${qs.stringify(
        params,
        { arrayFormat: 'repeat' },
      )}`,
    )
    .then(dataMiddleware)
    .then((data) => ({
      meta: data.meta || {},
      data: processWidgetData(data.data, widgetType),
    }))

/**
 * @description update widget
 * @param {string} customerId
 * @param {object} widget
 */
const updateWidget = ({ customerId, widget }) =>
  http(hosts.v3)
    .put(`/customers/${customerId}/widgets`, widget)
    .then(dataMiddleware)

/**
 * @description delete widget
 * @param {string} customerId
 * @param {string} widgetId
 */
const removeWidget = ({ customerId, widgetId }) =>
  http(hosts.v1)
    .delete(`/customers/${customerId}/widgets/${widgetId}`)
    .then(dataMiddleware)

export {
  createWidget,
  getWidget,
  getWidgetData,
  updateWidget,
  removeWidget,
  getWidgets,
  reorderWidgets,
  getWidgetMeta,
}
