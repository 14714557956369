<template>
  <div class="form-wrapper login-page" v-if="!getTokenFromUrl() || allowShow">
    <div class="login-background hidden-sm-and-down"></div>

    <div class="form-container rebranded-login-logo">
      <div class="logo-container">
        <img
          src="../../assets/rebranded_login_logo.svg"
          class="rebranded-logo-img"
        />
      </div>
      <form
        class="form"
        data-element="form-login"
        @submit.prevent="submitLogin"
      >
        <h1 class="form-title">Welcome to pear.ai</h1>
        <p class="form-text">Enter your email address to login</p>
        <div class="error-container" v-if="errorMsg.length">
          <span>{{ errorMsg }}</span>
        </div>
        <div class="form-fields">
          <div class="form-field">
            <div
              class="el-input input-login"
              :class="{ error: emailError && veefields.email.dirty }"
            >
              <input
                class="el-input__inner"
                id="field-email"
                v-model.lazy="email"
                @input="handleInputChange"
                v-validate="'required|email'"
                :data-vv-delay="700"
                type="text"
                name="email"
                placeholder="Enter a valid email address"
                tabindex="1"
                @keydown.enter="submit"
              />
              <button
                class="button-clear"
                v-if="errors.first('email') && email.length &gt; 0 || wrongCredentials || disabledUser"
                type="button"
                @click="clearEmail"
              ></button>
              <label>Email</label>
            </div>
            <div class="form-error" v-if="emailError && veefields.email.dirty">
              {{ emailError }}
            </div>
            <div
              class="form-error"
              v-else-if="wrongCredentials && veefields.email.dirty"
            >
              The email and password combination is incorrect. Please try again.
            </div>
            <div
              class="form-error"
              v-else-if="disabledUser && veefields.email.dirty"
            >
              This account is disabled.
            </div>
            <div
              class="form-error"
              v-else-if="isAdmin && veefields.email.dirty"
            >
              Unauthorised access. Please verify the entered email address.
            </div>
          </div>
          <div class="form-field">
            <div
              class="el-input input-password"
              :class="{ error: passwordError }"
            >
              <input
                class="el-input__inner"
                id="field-password"
                v-model="password"
                v-validate="'required'"
                :data-vv-delay="700"
                type="password"
                name="password"
                placeholder="Enter 8 or more characters or digits"
                tabindex="2"
                @keydown.enter="submit"
              />
              <button
                class="button-clear"
                v-if="errors.first('password') && password.length &gt; 0 || wrongCredentials || disabledUser"
                type="button"
                @click="clearPassword"
              ></button>
              <label>Password</label>
            </div>
            <div class="form-error" v-if="passwordError">
              {{ passwordError }}
            </div>
            <router-link class="form-link" :to="{ name: 'forgot' }">
              Forgot your password?
            </router-link>
          </div>
        </div>
        <div class="form-actions">
          <el-button
            class="button-login"
            id="button-submit"
            type="primary"
            :disabled="email.length === 0 || submitting"
            @click.prevent="submitLogin"
            tabindex="3"
          >
            Login
          </el-button>
          <div class="divider"><span>OR</span></div>
          <div class="constellation-text">
            <p>
              Constellation customers can use their Constellation Energy Manager
              account to access Pear.ai.&nbsp;
            </p>
            <router-link :to="{ name: 'learn-more' }">Learn more</router-link>
          </div>
          <el-button class="constellation-button" @click="loginClick">
            <div class="button-content">
              <i class="icon icon-constellation"></i>
              <span>Continue with Constellation account</span>
            </div>
          </el-button>
        </div>
      </form>
    </div>
    <img
      src="../../assets/rebranded_login_right.svg"
      class="right-login-background hidden-sm-and-down"
    />
  </div>
</template>

<script>
import { application } from '../../lib/config'
import { MsalClient } from '@/lib'
import { auth } from '@/api'
import { cond, equals } from 'ramda'
import { mapActions, mapGetters } from 'vuex'
import store from '../../store'
import { isSafari, getTokenFromUrl, getSsoAuthErrorMessage } from './helpers'

export default {
  data() {
    return {
      email: '',
      password: '',
      wrongCredentials: false,
      disabledUser: false,
      submitting: false,
      redirectAfterAuth: '',
      allowShow: false,
      errorMsg: '',
      isAdmin: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/currentUser',
    }),
    emailError() {
      return this.errors.has('email')
        ? 'Please enter a valid email address.'
        : null
    },
    passwordError() {
      return this.errors.has('password') ? 'Please enter a password.' : null
    },
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      loadHierarchy: 'locations/loadHierarchy',
      setCustomerInfo: 'customers/setCustomerInfo',
    }),
    async afterLogin() {
      const customerId = store.getters['customers/currentCustomerId']
      this.redirectAfterAuth =
        localStorage.getItem('redirectAfterAuth') || `/${customerId}/home`
      if (localStorage.getItem('redirectAfterAuth')) {
        localStorage.removeItem('redirectAfterAuth')
      }
      return this.$router.push(this.redirectAfterAuth)
    },
    async loginClick() {
      if (isSafari()) {
        const { msal } = application
        window.location.href = `https://${msal.tenant_name}.b2clogin.com/${msal.tenant_name}.onmicrosoft.com/oauth2/v2.0/authorize?p=${msal.user_flow_name}&client_id=${msal.clientId}&nonce=defaultNonce&redirect_uri=${window.location.origin}/auth/login&scope=openid&response_type=id_token&prompt=login`
        return
      }
      try {
        const client = new MsalClient()
        const id_token = await client.login()
        const { token, user } = await auth.loginAzureAd(id_token)
        this.setCustomerInfo({ user })
        await this.login({ jwt: token, user })
        this.errorMsg = ''
        await this.afterLogin()
      } catch (e) {
        this.errorMsg = getSsoAuthErrorMessage(e)
      }
    },
    getTokenFromUrl,
    handleInputChange() {
      this.disabledUser = false
      this.wrongCredentials = false
    },
    submitLogin() {
      this.submitting = true
      auth
        .login(this.email, this.password)
        .then(({ token, user, refreshToken }) => {
          if (user.roles.indexOf('web_admin') === -1) {
            this.wrongCredentials = true
            throw new Error()
          }
          this.setCustomerInfo({ user })
          return this.login({ jwt: token, user, refreshToken })
        })
        .then(
          () => {
            this.isAdmin = this.user.roles.some((role) => role === 'admin')
            if (this.isAdmin) {
              return
            }
            this.afterLogin()
          },
          (err) => {
            cond([
              [
                equals(401),
                () => {
                  this.wrongCredentials = true
                },
              ],
              [
                equals(400),
                () => {
                  this.wrongCredentials = true
                },
              ],
              [
                equals(403),
                () => {
                  this.disabledUser = true
                },
              ],
            ])(err.response.status)
          },
        )
        .finally(() => {
          this.submitting = false
        })
    },
    submit(e) {
      e.preventDefault()
      this.submitLogin()
    },
    clearEmail() {
      this.email = ''
      this.wrongCredentials = false
    },
    clearPassword() {
      this.password = ''
      this.wrongCredentials = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/utilities';
@import 'element-ui/lib/theme-chalk/display.css';

.right-login-background {
  width: 121px;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.logo-container {
  position: absolute;
  top: 65px;
  width: 100%;
  min-width: 300px;
  max-width: 410px;

  @include respond-to($tablet-p) {
    top: 40px;
  }
  @include respond-to($phone-l) {
    top: auto;
    margin-top: 10px;
  }
  @include respond-to($phone) {
    top: auto;
    margin-top: 10px;
  }
  @media (max-height: 600px) {
    top: 0px;
  }
}
.rebranded-logo-img {
  @include respond-to($phone) {
    height: 35px;
  }
  @media (max-width: 490px) {
    padding: 0 30px;
  }
}

.error-container {
  margin-bottom: 36px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #e12018;
  }
}

.form-link {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}

.form-field {
  margin-bottom: 24px;
}

.form-actions {
  margin-top: 0;
  .button-login {
    margin-right: 0;
    max-width: 100%;
  }
}

.constellation-text {
  margin-bottom: 12px;
  p {
    display: inline;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #909399;
  }

  a {
    cursor: pointer;
    color: #0080ff;
  }
}

.constellation-button {
  padding: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  width: 100%;

  .button-content {
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #606266;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include respond-to($tablet-l) {
      font-size: 12px;
    }

    @include respond-to($phone) {
      font-size: 12px;
    }

    i {
      margin-right: 10px;
    }
  }
}

.divider {
  margin: 0 0 24px 0;
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  &::after,
  &::before {
    display: flex;
    content: ' ';
    height: 1px;
    background: #1c1c1c;
    flex-grow: 2;
  }

  > span {
    margin: 0 16px;
  }
}
</style>
