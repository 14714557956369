// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'babel-polyfill'
import Vue from 'vue'
import Vuex from 'vuex'
import { sync } from 'vuex-router-sync'
import VeeValidate from 'vee-validate'
import VueCurrencyFilter from 'vue-currency-filter'
import { VueHammer } from 'vue2-hammer'
import '@/lib/polyfills'
import { customMessages } from '@/lib/helpers'

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'

import VueChatScroll from 'vue-chat-scroll'

import App from './App'
import router from './router'
import store from './store'
import { filters } from '@/lib'
import vClickOutside from 'v-click-outside'

if (window.VERSION !== localStorage.getItem('VERSION')) {
  localStorage.removeItem('vuex')
  localStorage.setItem('VERSION', window.VERSION)
}

VeeValidate.Validator.localize('en', customMessages)
VeeValidate.Validator.extend('decimal', filters.decimal)

sync(store, router)

Vue.use(Vuex)
Vue.use(VeeValidate, {
  fieldsBagName: 'veefields',
})
Vue.use(ElementUI, { locale })
Vue.use(VueHammer)
Vue.use(VueChatScroll)
Vue.use(vClickOutside)

Vue.config.productionTip = false

Vue.filter('datetime', filters.datetime)
Vue.filter('unit', filters.unit)
Vue.filter('prettyVendor', filters.prettyVendor)
Vue.filter('toTitleCase', filters.toTitleCase)
Vue.filter('switchCommoditiesCase', filters.switchCommoditiesCase)
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false,
})
/* eslint-disable no-new */

// Adding instance property to store info about user's device
Vue.prototype.$deviceInfo = {}

new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>',
})
