<template lang="pug">
  .filter-item
    .filter-title(style="color: #606266" class="title") {{ title }}
    .filter-item-date
      el-date-picker(v-model="month",
                    format="MMMM yyyy",
                    type="month",
                    size="mini",
                    class="date-picker"
                    :pickerOptions="pickerOptions",
                    :placeholder="placeholder"
                     @change="changeMonth")
</template>

<script>
import * as R from 'ramda'
import { mapState } from 'vuex'

export default {
  props: ['dates', 'title', 'placeholder', 'datesMinMax'],
  data() {
    return {
      month: '',
      year: '',
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
    }
  },
  computed: {
    ...mapState({
      customer_id: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
  },
  methods: {
    changeMonth() {
      this.$emit('changeDate', this.month)
    },
    disabledDate(date) {
      if (this.datesMinMax) {
        return !(
          date.valueOf() > this.datesMinMax.startDate &&
          date.valueOf() < this.datesMinMax.endDate
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-item {
  &:first-child {
    margin-right: 6px;
  }
  .title {
    @media screen and (max-width: 540px) {
      font-size: 10px;
    }
  }

  .filter-item-date {
    .date-picker {
      max-width: 150px;
      @media screen and (max-width: 540px) {
        max-width: 100px;
        ::v-deep .el-input__inner {
          font-size: 10px;
          padding: 0 5px 0 25px;
        }
      }
    }
  }
}
.el-button {
  width: 72px;
  margin-left: 10px;
}
</style>
