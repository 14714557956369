<template lang="pug">
  .bill-summary-control.control
    .bill-summary_table
      table.sam-table.sam-table-list
        thead(v-if="messageData.options ? !messageData.options.hideHeader : true")
          tr
            th(v-for="(header, index) in messageData.header",
              :key="index",
              :style="columnWidth(header['param'])")
              .cell {{ header.displayString }}
        tbody
          tr(v-for="(item, index) in tableData", :key="index" :class="[rowClassName(item), {'sam-table_action': messageData.action}]", @click="handleRowClick(item)")
            td(v-for='(col, index) in item', :key='index', :class="col.class")
              .cell(v-if="col.key === 'vendor' || (isVendor(item[0]) && index > 0)") {{ getPrettyVendorByCode(col.value) }}
              .cell(v-else) {{ col.value }}
    .bill-table-view-footer(v-if="messageData.footer")
      .data
        span(v-for="item in (Array.isArray(messageData.footer) ? messageData.footer : [messageData.footer])") {{ item }}
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { sendEventToChatbase } from '../../../api/stats'
import { getPdfDownloadLink } from '../../../api/bills'
import TableControl from '@/components/Controls/TableControl.vue'

export default {
  components: {
    TableControl,
  },
  props: {
    messageData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
      currentCustomerId: 'customers/currentCustomerId',
    }),
    tableData() {
      return _.map(this.messageData.data, (colItem) => {
        let arr = []

        _.forEach(this.messageData.header, (headerItem, index) => {
          _.forIn(colItem, (value, key) => {
            if (headerItem.param === key) {
              arr[index] = {
                id: colItem.id ? colItem.id : '',
                customer_id: colItem.customer_id ? colItem.customer_id : '',
                key: key.toLowerCase(),
                value,
                class:
                  colItem.styles && colItem.styles[key]
                    ? colItem.styles[key].join(' ')
                    : '',
              }
            }
          })
        })

        return arr
      })
    },
  },
  methods: {
    isVendor(data) {
      return data.key === 'attribute' && data.value.toLowerCase() === 'vendor'
    },
    rowClassName(row) {
      return _.get(row, 'styles._row.0', '')
    },
    columnWidth(header) {
      if (header === 'state') {
        return 'width: 15%'
      }
      if (header === 'location') {
        return 'width: 55%'
      }
      return ''
    },
    sendMessage(message) {
      this.$emit('sendMessage', message)
    },
    scrapeValue(str, fields) {
      const [match, key] = str.match(/\{([^}]+)\}/)
      return str.replace(match, fields[key])
    },
    handleRowClick(row) {
      if (!this.messageData.action) {
        return false
      }

      if (this.messageData.action.type === 'weblink_newtab') {
        sendEventToChatbase(this.currentCustomerId, 'new_tab', 'weblink').catch(
          (err) => {
            console.error('CHATBASE ERROR: ', err)
          },
        )

        window.open(
          this.scrapeValue(this.messageData.action.url, row[0]),
          '_blank',
        )
      } else if (this.messageData.action.type === 'get_download_link') {
        sendEventToChatbase(this.currentCustomerId, 'new_tab', 'weblink').catch(
          (err) => {
            console.error('CHATBASE ERROR: ', err)
          },
        )

        const { id: billId, customer_id: customerId } = row[0]
        let windowOpen = null

        if (this.$deviceInfo.deviceOS === 'ios') {
          windowOpen = window.open()
        }
        getPdfDownloadLink({ billId, customerId })
          .then(({ url }) => {
            if (url) {
              if (this.$deviceInfo.deviceOS === 'ios') {
                windowOpen.location = url
              } else {
                const a = window.document.createElement('a')
                a.setAttribute('href', url)
                window.document.body.appendChild(a)
                a.click()
                window.document.body.removeChild(a)
              }
            } else {
              console.error(`GET PDF LINK ERROR: got empty download url`)
            }
          })
          .catch((err) => {
            console.error('GET PDF LINK ERROR: ', err)
          })
      } else {
        this.sendMessage(
          this.scrapeValue(this.messageData.action.bubble_text, row[0]),
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../styles/_utilities';

.bill-summary-control {
  max-width: 380px;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #fff;
  /*box-shadow: 0 2px 6px 0 rgba(110,112,113,0.5);*/
  box-sizing: border-box;
  overflow-x: auto;
  margin-left: 62px;
  border: 1px solid #dde5e6;
  border-radius: 0 16px 16px 16px;

  @include respond-to($phone) {
    margin-left: 0;
  }
  @include respond-to($phone-l) {
    margin-left: 0;
  }
}

.bill-summary_meta {
  padding: 10px 10px 0 10px;
  color: #7d89a6;
  font-size: 10px;
}
</style>

<style lang="scss">
@import './../../../styles/_utilities';

.bill-summary-control .sam-table th > .cell {
  color: #7d89a6;
  padding: 0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 900;
}

.bill-table-view-footer {
  /*margin-left: 10px;*/
  .data {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > span {
      margin-top: 2px;
      font-size: 10px;
      line-height: 14px;
      font-weight: 500;
      color: #888888;
    }
  }
}
</style>
