<template lang="pug">
  .filter-item.filter-item-date
    .filter-title(v-if="title") {{ title }}
    .select-box
      select(v-model="type",
        :data-action="`filter-select_timeperiod-mobile`",
        :value="type")
        option(v-for='item in options',
              :key="item.value",
              :value="item.value") {{ item.label }}
      i.el-select__caret.el-input__icon.el-icon-arrow-down

    .filter-item-inner(v-if="type === 'months'")
      .month-input-box
        button(type="button", @click="month > 1 ? month-- : null")
          i.el-icon-minus
        input(v-model="month",
              class=""
              type="number",
              data-action="filter-timeperiod_months-mobile",
              min="1",
              :max="maxMonths")
        button(type="button", @click="month++")
          i.el-icon-plus

    .filter-item-inner(v-if="type === 'period'")
      .date-input-box
        el-date-picker(v-model="start_date",
          format="MMM yyyy",
          value-format="yyyy-MM-dd",
          type="month",
          :editable="false",
          size="mini",
          placeholder="Start date")
        .divider to
        el-date-picker(v-model="end_date",
          format="MMM yyyy",
          value-format="yyyy-MM-dd",
          type="month",
          :editable="false",
          size="mini",
          placeholder="End date")
</template>

<script>
import _ from 'lodash'
import { DateTime } from 'luxon'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    maxMonths: {
      type: Number,
      default: 360,
    },
  },
  data() {
    return {
      start_date: _.get(this.value, 'period.start_date', null),
      end_date: _.get(this.value, 'period.end_date', null),
      type: _.get(this.value, 'type', 'year_to_date'),
      month: _.get(this.value, 'months') || _.get(this.value, 'count', 1),
      options: [
        {
          label: 'Year to-date',
          value: 'year_to_date',
        },
        {
          label: 'Custom',
          value: 'period',
        },
        {
          label: 'Last x months',
          value: 'months',
        },
        {
          label: 'All',
          value: 'all',
        },
      ],
    }
  },
  beforeMount() {
    this.filter.type = this.type
    if (this.type === 'year_to_date') {
      this.filter.year_to_date = true
    }
    if (this.value.months) {
      this.filter.months = this.value.months
    }
    this.type = this.filter.type
  },
  watch: {
    type(val) {
      this.filter.type = val
      if (val === 'all') {
        this.start_date = null
        this.end_date = null
      }
      if (val === 'year_to_date') {
        this.filter.year_to_date = true
      } else {
        delete this.filter.year_to_date
      }
      if (val === 'months') {
        this.filter.count = this.month
      }
      this.$emit('validate', this.twoDatesSelected())
    },
    start_date: {
      immediate: true,
      handler: function (val) {
        if (this.type !== 'period') {
          return
        }
        this.filter.start_date = val
          ? DateTime.fromFormat(val, 'yyyy-LL-dd', { zone: 'utc' }).toFormat(
            'yyyy-LL-dd',
          )
          : null
        this.$emit('validate', this.twoDatesSelected())
      },
    },
    end_date: {
      immediate: true,
      handler: function (val) {
        if (this.type !== 'period') {
          return
        }
        this.filter.end_date = val
          ? DateTime.fromFormat(val, 'yyyy-LL-dd', { zone: 'utc' })
            .endOf('month')
            .toFormat('yyyy-LL-dd')
          : null
        this.$emit('validate', this.twoDatesSelected())
      },
    },
    month(val) {
      const count = parseInt(val)
      if (count > this.maxMonths) {
        this.month = this.maxMonths
        return
      }
      if (count > 0) {
        this.filter.end_date = DateTime.fromMillis(Date.now(), { zone: 'utc' })
          .startOf('month')
          .toFormat('yyyy-LL-dd')
        this.filter.start_date = DateTime.fromMillis(Date.now(), {
          zone: 'utc',
        })
          .minus({ months: count })
          .startOf('month')
          .toFormat('yyyy-LL-dd')
        this.filter.type = 'months'
        this.filter.count = count
      } else {
        this.filter.start_date = null
        this.filter.end_date = null
      }
    },
  },
  methods: {
    twoDatesSelected() {
      if (this.type === 'period') {
        return (
          !_.isEmpty(this.filter.start_date) && !_.isEmpty(this.filter.end_date)
        )
      } else {
        return true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-item.filter-item-date {
  .select-box {
    position: relative;
    width: 100%;

    select {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 36px;
      padding: 0 20px;
      color: #909399;
      font-size: 16px;
      line-height: 36px;
      border-radius: 10px;
      border: 1px solid #dcdfe6;
      background-color: #fff;
      box-sizing: border-box;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      appearance: none;
      outline: none;

      &.show-label {
        opacity: 0;

        &:focus {
          opacity: 1;

          ~ label {
            opacity: 0;
          }
        }
      }
    }
    i {
      position: absolute;
      top: 0;
      right: 5px;
      color: #c0c4cc;
    }
  }
  .month-input-box {
    margin-right: auto;
    display: flex;
    width: 135px;
    height: 36px;
    border: 1px solid #d8dce6;
    border-radius: 10px;

    input {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 36px;
      padding: 0 20px;
      color: #909399;
      font-size: 16px;
      line-height: 36px;
      white-space: nowrap;
      text-align: center;
      appearance: none;
      border: 0;
      background-color: #fff;
      background-image: none;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      box-sizing: border-box;
      overflow: hidden;
      outline: none;
      z-index: 1;

      + button {
        border-radius: 0 10px 10px 0;
      }
    }
    button {
      flex: none;
      height: 36px;
      width: 36px;
      color: #7b7b7b;
      font-size: 18px;
      border: 0;
      border-radius: 10px 0 0 10px;
      background-color: #f2f6fb;
      outline: none;

      &:active {
        color: #409eff;
      }
    }
  }
  .date-input-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    .el-date-editor {
      flex-grow: 1;
      width: auto !important;
      ::v-deep input {
        height: 36px !important;
        line-height: 36px !important;
      }
    }
    .divider {
      width: 16px;
      margin: 0 5px;
      color: #7d89a6;
      font-size: 14px;
    }
  }
  .filter-item-inner {
    margin-top: 20px;
  }
}
</style>
