<template>
  <div class="powerbi-wrapper">
    <div id="reportContainer" class="report-container" />
    <set-power-bi-ids
      :visible="showIDSModal"
      @emit-powerbi-params="getPowerBIReport($event)"
      @close="close"
      :errorMessage="errorMessage"
    />
  </div>
</template>

<script>
import checkMobileDevice from '@/mixins/checkMobileDevice'
import SetPowerBiIds from './Modals/SetPowerBiIds'
import { mapGetters } from 'vuex'
import { analytics } from '@/api'
import { models } from 'powerbi-client'
import store from '../../../store/index'
import { powerbi } from '@/store/modules'

export default {
  components: { SetPowerBiIds },
  mixins: [checkMobileDevice],
  data() {
    return {
      emptyLabel: '—',
      emptyValue: '',
      tokenData: '',
      powerbiParamsForm: {
        reportId: '',
        groupId: '',
        datasetId: '',
      },
      showIDSModal: false,
      showErrors: false,
      errorMessage: '',
    }
  },
  mounted() {
    const { query } = this.$route
    if (query.reportId && query.groupId && query.datasetId) {
      this.powerbiParamsForm.reportId = query.reportId || ''
      this.powerbiParamsForm.groupId = query.groupId || ''
      this.powerbiParamsForm.datasetId = query.datasetId || ''
      this.getPowerBIReport(this.powerbiParamsForm)
    } else {
      this.showIDSModal = true
    }
  },
  watch: {
    currentCustomerId() {
      if (!this.isPowerBIEntitlement) {
        this.$router.push({ name: 'dashboard.home' })
      }
    },
  },
  computed: {
    ...mapGetters({
      currentCustomerId: 'customers/currentCustomerId',
      isPowerBIEntitlement: 'customers/isPowerBIEntitlement',
    }),
  },
  methods: {
    async close() {
      if (this.showIDSModal !== false) {
        await this.getPowerBIReport()
      }
      this.showIDSModal = false
    },
    async getPowerBIReport(formData) {
      this.errorMessage = ''
      await this.getPowerBIToken(formData)
      this.embedPowerBI()
    },
    async getPowerBIToken(powerbiParamsForm = this.powerbiParamsForm) {
      const params = {
        reportId: powerbiParamsForm.reportId,
        groupId: powerbiParamsForm.groupId,
        datasetId: powerbiParamsForm.datasetId,
      }
      try {
        this.tokenData = await analytics.getEmbedToken({
          customerId: this.currentCustomerId,
          params,
        })
        this.showIDSModal = false
      } catch (e) {
        console.log(e.response.data.error)
        this.errorMessage = e.response.data.error
      }
    },
    embedPowerBI() {
      const embedConfiguration = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: this.tokenData.embed_token,
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${this.tokenData.report_id}&groupId=${this.tokenData.group_id}`,
        id: this.tokenData.report_id,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: true,
            },
            pageNavigation: {
              visible: true,
            },
          },
        },
      }
      const report = powerbi.embed(
        document.getElementById('reportContainer'),
        embedConfiguration,
      )

      // Set url query parameters for sharing report
      report.on('loaded', () => {
        this.$router.push({
          name: 'dashboard.powerbi',
          query: {
            datasetId: this.tokenData.dataset_id,
            groupId: this.tokenData.group_id,
            reportId: this.tokenData.report_id,
          },
        })
      })
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!store.getters['customers/isPowerBIEntitlement']) {
      next({ name: 'not.found' })
    } else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
.powerbi-wrapper {
  margin: 24px;
  min-height: calc(100vh - 48px);
  max-height: calc(100vh - 48px);
}
.report-container {
  height: calc(100vh - 48px);
}
@media (max-width: 600px) {
  //header added on mobile devices need to extract more height
  .powerbi-wrapper {
    min-height: calc(100vh - 48px - 74px);
    max-height: calc(100vh - 48px - 74px);
  }
  .report-container {
    height: calc(100vh - 48px - 74px);
  }
}
</style>
