<template lang="pug">
  .feeds-page-container(v-loading="isFetching")
    .feeds-page-mobile(v-if="$deviceInfo.isMobileDevice")
      monthly-list-mobile
    .feeds-page(v-else)
      //- [TEMP] Remove after bill history will be moved to Locations tabs
      .filter
        .filter-container
          .filter-item
            .filter-title Utility Type
            el-select(v-model="utilityType",
              size="mini",
              @change="changeUtilityType"
              filterable)
              el-option(v-for='item in utilityTypes',
                :key="item",
                :label="item",
                :value="item")
          processing-date(class="processing-date",
            title="Go to Month",
            :datesMinMax="datesMinMax"
            :dates="dates",
            placeholder="All months",
            @changeDate="changeDate")
      .feeds-list-container(:class="{'no-scroll': list.length === 0}")
        .feeds-list(v-if="list.length")
          .feeds-list_item(v-for="feed in list", :key="String(feed.timePeriod)")
            .feeds-list_item-header
              h2(:id="feed.title") {{ feed.title }}
              .total-charges(v-if="!feed.type")
                span Total Cost:&nbsp;
                b {{ feed.total | unit('USD') }}
            .feeds-list_item-body(v-if="!feed.type")
              bill-block(:block="feed.data", :key="feed.title")
            .feeds-list_empty-gap(v-else)
              span No bill history for this time period.

        .feeds-list.feeds-list-empty(v-else)
          h2 No bill history for this time period.

</template>

<script>
import _ from 'lodash'
import * as R from 'ramda'
import { DateTime } from 'luxon'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import ProcessingDate from '@/components/ProcessingDate.vue'
import FilterSelect from '@/components/Filters/FilterSelect.vue'
import BillBlock from './BillBlock.vue'
import MonthlyListMobile from './ListMobile.vue'
import FeedDetailsModal from './FeedDetailsModal.vue'
import { commodities } from '@/api'

export default {
  components: {
    ProcessingDate,
    FilterSelect,
    BillBlock,
    FeedDetailsModal,
    MonthlyListMobile,
  },
  data() {
    return {
      paymentsData: [],
      bills: [],
      dateLabel: '',
      showFeedDetailsModal: false,
      loading: true,
      utilityType: 'All Utilities',
      selector: '.feeds-list-container',
      utilityTypes: [],
    }
  },
  props: ['datesMinMax'],
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      list: 'feeds/list',
      isFetching: 'feeds/isFetching',
      dates: 'feeds/dates',
      params: 'feeds/params',
    }),
    utility_types() {
      return [
        'All Utilities',
        ...this.$store.getters['resources/getResourceByName']('commodities')
          .value.filter(
            (type) =>
              type.title === 'All Utilities' ||
              this.utilityTypes.includes(type.title),
          )
          .map((type) => type.title),
      ]
    },
  },
  async mounted() {
    this.utilityTypes = await commodities.getCommoditiesForLocation({
      customerId: this.currentCustomerId,
      locationId: this.$route.params.locationId,
    })
    await this.$fetchFeeds({
      customerId: this.currentCustomerId,
      locationId: this.$route.params.locationId,
    })
    if (this.dates.length) {
      await this.$setParams({
        ...this.params,
        startDate: DateTime.fromFormat(_.last(this.dates), 'yyyy-LL', {
          zone: 'utc',
        })
          .endOf('month')
          .toFormat('yyyy-LL-dd')
          .toString(),
      })
    }
    const el = document.querySelector(this.selector)
    if (el) {
      el.addEventListener('scroll', this.onWindowScroll)
    }
  },
  beforeDestroy() {
    const el = document.querySelector(this.selector)
    if (el) {
      el.removeEventListener('scroll', this.onWindowScroll)
    }
    this.$resetState()
  },
  methods: {
    ...mapActions({
      $fetchFeeds: 'feeds/fetchFeeds',
      $setParams: 'feeds/setParams',
      $appendFeeds: 'feeds/appendFeeds',
    }),
    ...mapMutations({
      $resetState: 'feeds/resetState',
    }),
    async onWindowScroll(e) {
      if (!this.list.length || this.isFetching) return
      const container = e.target
      if (
        container.offsetHeight + container.scrollTop >=
        container.scrollHeight
      ) {
        const lastMonthData = _.get(_.last(this.list), 'data', [])
        const lastDate = _.get(
          _.last(_.last(lastMonthData)),
          'timePeriod',
          null,
        )
        if (
          DateTime.fromISO(lastDate, { zone: 'utc' })
            .toFormat('yyyy-LL')
            .toString() !== _.first(this.dates)
        ) {
          await this.$setParams({
            direction: '-',
            ...(lastDate && {
              startDate: DateTime.fromISO(lastDate, { zone: 'utc' })
                .toFormat('yyyy-LL-dd')
                .toString(),
            }),
            period: 12,
          })
          await this.$appendFeeds({
            customerId: this.currentCustomerId,
            locationId: this.$route.params.locationId,
          })
        }
        return
      }

      if (container.scrollTop <= 0) {
        const initHeight = container.scrollHeight
        const lastMonthData = _.get(_.first(this.list), 'data', [])
        const lastDate = _.get(
          _.last(_.last(lastMonthData)),
          'timePeriod',
          null,
        )
        if (
          DateTime.fromISO(lastDate, { zone: 'utc' })
            .toFormat('yyyy-LL')
            .toString() !== _.last(this.dates)
        ) {
          await this.$setParams({
            direction: '+',
            ...(lastDate && {
              startDate: DateTime.fromISO(lastDate, { zone: 'utc' })
                .toFormat('yyyy-LL-dd')
                .toString(),
            }),
            period: 12,
          })
          await this.$appendFeeds({
            customerId: this.currentCustomerId,
            locationId: this.$route.params.locationId,
          })
        }
        this.$nextTick(() =>
          container.scroll(0, container.scrollHeight - initHeight + 1),
        )
      }
    },
    openFeedDetailsModal() {
      this.showFeedDetailsModal = true
    },
    closeFeedDetailsModal() {
      this.showFeedDetailsModal = false
    },
    async changeDate(time) {
      let params = null
      const id = DateTime.fromMillis(+time)
        .toFormat('MMMM yyyy')
        .toString()
      const element = document.getElementById(id)
      if (element) {
        this.$nextTick(() => element.scrollIntoView())
        return
      }

      if (time) {
        params = {
          direction: '-',
          startDate: DateTime.fromMillis(+time)
            .toFormat('yyyy-LL-dd')
            .toString(),
          period: 12,
        }
      } else {
        params = {
          direction: '-',
          startDate: DateTime.fromFormat(_.last(this.dates), 'yyyy-LL', {
            zone: 'utc',
          })
            .endOf('month')
            .toFormat('yyyy-LL-dd')
            .toString(),
          period: 12,
        }
      }

      await this.$setParams(params)
      await this.$fetchFeeds({
        customerId: this.currentCustomerId,
        locationId: this.$route.params.locationId,
      })
      this.$nextTick(() => document.querySelector(this.selector).scroll(0, 1))
    },
    async changeUtilityType() {
      await this.$setParams({
        utilityType:
          this.utilityType !== 'All Utilities' ? this.utilityType : null,
      })
      await this.$fetchFeeds({
        customerId: this.currentCustomerId,
        locationId: this.$route.params.locationId,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../../../styles/_utilities';
.feeds-page-container {
  /* [TEMP] Remove after bill history will be moved to Locations tabs */
  .feeds-page {
    padding: 0 40px;

    ::v-deep .el-button--primary {
      background: #0080ff;
      border-color: #0080ff;
    }
  }

  .content-inner {
    padding-right: 40px;
    padding-left: 40px;
  }

  .filter {
    &-item {
      &:first-child {
        margin-right: auto;
      }
    }

    ::v-deep .el-input {
      width: 160px !important;
    }
  }

  .feeds-list {
    max-height: calc(100vh - 276px);
    min-height: calc(100vh - 276px);

    .feeds-list_empty-gap {
      color: #646464;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 42px;
    }

    &-container {
      margin: 0 -40px;
      padding: 0 40px;
      max-height: calc(100vh - 277px);
      min-height: calc(100vh - 277px);
      overflow-y: scroll;
      @extend %scrollbar;
      &.no-scroll {
        overflow-y: hidden;
      }
    }

    &_item {
      &:not(:last-child) {
        margin-bottom: 42px;

        &::after {
          display: block;
          margin: 0 -40px;
          content: '';
          border-bottom: 1px solid #dcdcdc;
        }
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        margin-bottom: 15px;

        h2 {
          margin: 0;
          color: #222222;
          font-size: 22px;
          line-height: 32px;
        }
      }
    }

    &-empty {
      h2 {
        display: inline-block;
        padding: 18px 10px 18px 0;
        margin: 0;
        color: #c3c3c3;
        font-size: 22px;
        line-height: 32px;
        border-bottom: 1px solid #dfdfdf;
      }
    }
  }

  .total-charges {
    color: #222222;
    font-size: 18px;
    line-height: 25px;

    b {
      font-weight: 900;
    }
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      min-width: 140px;
    }
  }

  .processing-date {
    margin-right: 0;
  }
}
</style>
