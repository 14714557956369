<template>
  <div class="content-inner">
    <div class="budgeting-page">
      <div class="page-header">
        <div class="page-title">Budgeting and Forecasting</div>
      </div>
      <el-tabs v-model="activeName" @tab-click="changeTab">
        <el-tab-pane label="Budget List" :name="tabsList[0]">
          <budgeting-reports-list />
        </el-tab-pane>
        <el-tab-pane label="Create a Budget" :name="tabsList[1]">
          <create-budget-view v-if="activeName === tabsList[1]" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import CreateBudgetView from './Tabs/CreateBudgetView'
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import BudgetingReportsList from './Tabs/BudgetingReportsList'
import { Events } from '@/lib'

export default {
  components: { BudgetingReportsList, CreateBudgetView },
  data() {
    return {
      activeName: 'budgetList',
      totalLocations: 0,
      tabsList: ['budgetList', 'createABudget'],
    }
  },
  watch: {
    async currentCustomerId(customerId) {
      await this.handleGetData(customerId)
    },
  },
  async beforeMount() {
    const { customerId, bgTab = 'budgetList' } = this.$route.params
    if (this.tabsList.indexOf(bgTab) === -1) {
      Events.$emit('not.found')
      return
    }
    this.activeName = bgTab
    await this.handleGetData(customerId)
  },
  beforeDestroy() {
    this.resetStateCreateBudgeting()
    this.resetStateCompletedBudgetingList()
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      paginationCreateBudgeting: 'createBudgeting/pagination',
      paginationCompletedBudgeting: 'completedBudgets/pagination',
      budgetingReportsList: 'completedBudgets/budgetingReportsList',
      locationHierarchiesData: 'createBudgeting/locationHierarchiesData',
    }),
  },
  methods: {
    ...mapActions({
      getBudgetingTemplates: 'createBudgeting/getBudgetingTemplates',
      getBudgetingReportsList: 'completedBudgets/getBudgetingReportsList',
      checkProcessingReports: 'completedBudgets/checkProcessingReports',
      loadLocations: 'locations/loadLocations',
      changePaginationCompletedBudgeting: 'completedBudgets/changePagination',
      changePaginationCreateBudgeting: 'createBudgeting/changePagination',
      resetStateCompletedBudgetingList: 'completedBudgets/reset',
    }),
    ...mapMutations({
      resetStateCreateBudgeting: 'createBudgeting/resetState',
    }),

    async handleGetData(customerId) {
      const [BUDGET_LIST] = this.tabsList
      this.changePaginationCompletedBudgeting({ limit: 10, offset: 0 })
      this.changePaginationCreateBudgeting({ limit: 10, offset: 0 })
      if (this.activeName === BUDGET_LIST) {
        this.addRouteParams(this.paginationCompletedBudgeting)
      } else {
        this.addRouteParams(this.paginationCreateBudgeting)
      }
      await this.loadLocations()

      await this.getBudgetingReportsList({
        customerId,
        params: {
          limit: this.paginationCompletedBudgeting.limit,
          offset: this.paginationCompletedBudgeting.offset,
        },
      })
      await this.getBudgetingTemplates({
        customerId,
        params: {
          limit: this.paginationCreateBudgeting.limit,
          offset: this.paginationCreateBudgeting.offset,
        },
      })
    },
    addRouteParams({ limit, offset }) {
      this.$router.push({
        params: {
          bgTab: this.activeName,
        },
        query: {
          limit,
          offset,
        },
      })
    },
    async changeTab() {
      const [BUDGET_LIST, CREATE_BUDGET] = this.tabsList

      if (this.activeName === CREATE_BUDGET) {
        this.addRouteParams(this.paginationCreateBudgeting)
        return
      }
      if (this.activeName === BUDGET_LIST) {
        this.changePaginationCompletedBudgeting({ limit: 10, offset: 0 })
        const { limit, offset } = this.paginationCompletedBudgeting
        const { customerId } = this.$route.params
        this.addRouteParams(this.paginationCompletedBudgeting)
        await this.getBudgetingReportsList({
          customerId,
          params: { limit, offset },
        })
        await this.checkProcessingReports({
          customerId,
          params: { limit, offset },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/utilities';
.content-inner {
  .budgeting-page {
    .page-header {
      .page-title {
        font-size: 24px;
        line-height: 45px;
        color: #333333;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
