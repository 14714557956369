import { render, staticRenderFns } from "./MessageItemTableListView.vue?vue&type=template&id=e4cea8be&scoped=true&lang=pug&"
import script from "./MessageItemTableListView.vue?vue&type=script&lang=js&"
export * from "./MessageItemTableListView.vue?vue&type=script&lang=js&"
import style0 from "./MessageItemTableListView.vue?vue&type=style&index=0&id=e4cea8be&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4cea8be",
  null
  
)

export default component.exports