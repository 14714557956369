import * as API from '@/api'
import _ from 'lodash'
import { DateTime } from 'luxon'

const REQUEST_FEEDS = 'REQUEST_FEEDS'
const RECEIVE_FEEDS = 'RECEIVE_FEEDS'
const REQUEST_DATES = 'REQUEST_DATES'
const RECEIVE_DATES = 'RECEIVE_DATES'
const APPEND_FEEDS = 'APPEND_FEEDS'
const SET_PARAMS = 'SET_PARAMS'
const SELECT_DATE = 'SELECT_DATE'

const initialState = () => ({
  isFetching: false,
  list: [],
  dates: [],
  params: {
    direction: '-',
    startDate: DateTime.utc().toISODate(),
    period: 12,
  },
  selectedDate: '',
})

const sortList = (a, b) => {
  const prev = DateTime.fromISO(String(a.timePeriod), { zone: 'utc' }).valueOf()
  const next = DateTime.fromISO(String(b.timePeriod), { zone: 'utc' }).valueOf()

  return next - prev
}

const detectGaps = (data) => {
  const newData = data.sort(sortList).slice()
  const gaps = []
  newData.forEach((item, index) => {
    if (!newData[index + 1]) return
    const currentTime = DateTime.fromFormat(item.title, 'MMMM yyyy')
    const nextTime = DateTime.fromFormat(newData[index + 1].title, 'MMMM yyyy')
    const diff = currentTime.diff(nextTime, 'months')
    const { months } = diff
    if (months > 1) {
      gaps.push({
        index: index + 1,
        type: 'gap',
        timePeriod: currentTime,
        title: `${
          months > 2
            ? `${nextTime.plus({ months: 1 }).toFormat('MMMM yyyy')}`
            : ''
        } - ${currentTime.minus({ months: 1 }).toFormat('MMMM yyyy')}`,
      })
    }
  })

  gaps.forEach((gap) => {
    newData.splice(gap.index, 0, gap)
  })

  newData.sort(sortList)

  return newData
}

export default {
  namespaced: true,
  state: initialState(),

  getters: {
    list: (state) => state.list,
    params: (state) => state.params,
    isFetching: (state) => state.isFetching,
    dates: (state) => state.dates,
    selectedDate: (state) => state.selectedDate,
  },

  mutations: {
    [REQUEST_DATES]: (state) => {
      state.isFetching = true
    },

    [RECEIVE_DATES]: (state, data) => {
      state.dates = data || []
      state.selectedDate = _.last(data)
    },

    [REQUEST_FEEDS]: (state) => {
      state.isFetching = true
    },

    [RECEIVE_FEEDS]: (state, list) => {
      let l = list
      l = detectGaps(l)
      state.list = l
      state.isFetching = false
    },

    [APPEND_FEEDS]: (state, { list }) => {
      let l = _.uniqBy([...state.list, ...list], 'title')
      l = detectGaps(l)
      state.list = l
      state.isFetching = false
    },

    [SET_PARAMS]: (state, params) => {
      if (params) {
        state.params = { ...state.params, ...params }
      }
    },

    [SELECT_DATE]: (state, date) => {
      state.selectedDate = date
    },

    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async fetchFeeds({ commit, state }, { customerId, locationId }) {
      await commit(REQUEST_FEEDS)
      await commit(RECEIVE_DATES)

      try {
        const data = await API.feeds.list({
          customerId,
          locationId,
          params: state.params,
        })
        await commit(RECEIVE_FEEDS, data.results)
        await commit(RECEIVE_DATES, data.dates)
      } catch (e) {
        await commit(RECEIVE_FEEDS, [])
        await commit(RECEIVE_DATES, [])
      }
    },

    async setParams({ commit }, params) {
      await commit(SET_PARAMS, params)
    },

    async appendFeeds({ commit, state }, { customerId, locationId }) {
      try {
        await commit(REQUEST_FEEDS)
        const data = await API.feeds.list({
          customerId,
          locationId,
          params: { ...state.params, excludeMeta: true },
        })
        await commit(APPEND_FEEDS, {
          list: data,
          direction: state.params.direction,
        })
      } catch (e) {
        await commit(APPEND_FEEDS, { list: [] })
      }
    },

    selectDate({ commit }, date) {
      commit(SELECT_DATE, date)
    },
  },
}
