import { createReports } from '@/api'
import { DateTime } from 'luxon'

const initialState = () => {
  return {
    reports: {},
    formBody: {
      deliveryOptions: {
        label: 'Delivery Options',
        selectableValues: ['Run Now', 'Scheduled Report'],
      },
      startDate: {
        label: 'Start Date',
      },
      recurringType: {
        label: 'Recurring Type',
      },
      selectTime: {
        label: 'Select Time',
        selectableValues: [],
      },
      deliveryMethods: {
        label: 'Delivery Methods',
        types: ['Email', 'SFTP'],
      },
      emailRecipients: {
        label: 'Enter Email Recipients',
        emails: [],
      },
      sftpConnection: {
        label: 'Select SFTP Address',
        list: [],
      },
    },
    pagination: {
      limit: 10,
      offset: 0,
      pageSizes: [10, 20, 30, 40, 100],
    },
    loading: true,
    availableFilters: [],
    formats: [
      { value: 'xlsx', label: 'XLSX' },
      { value: 'csv', label: 'CSV' },
    ],
  }
}

export default {
  namespaced: true,
  state: initialState(),

  getters: {
    reports: (state) =>
      state.reports &&
      state.reports.results &&
      state.reports.results.map((report) => ({
        ...report,
        saveFormat: 'xlsx',
        formBody: state.formBody,
      })),
    reportTypes: (state) =>
      state.reports &&
      state.reports.results &&
      state.reports.results.map((report) => {
        return { value: report.readableId, label: report.title }
      }),
    reportFormats: (state) => state.formats,
    availableFilters: (state) => state.availableFilters,
    totalResults: (state) => state.reports && state.reports.total,
    pagination: (state) => state.pagination,
    currentPage: (state) =>
      state.pagination.offset / state.pagination.limit + 1,
    formBody: (state) => state.formBody,
    loading: (state) => state.loading,
  },

  mutations: {
    setReports(state, payload) {
      state.reports = payload
    },
    setHours(state, payload) {
      state.formBody.selectTime.selectableValues = payload
    },
    setLimit(state, payload) {
      state.pagination.limit = +payload
    },
    setOffset(state, payload) {
      state.pagination.offset = +payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setSftpConnection(state, payload) {
      state.formBody.sftpConnection.list = payload
    },
    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    reset({ commit }) {
      commit('resetState')
    },
    async getReports({ dispatch, commit }, { customerId, params = {} }) {
      try {
        const reports = await createReports.getReports(customerId, params)
        commit('setReports', reports)
        commit('setLoading', false)
        commit('setLimit', params.limit || 10)
        commit('setOffset', params.offset || 0)
      } catch (e) {
        console.log(e)
        commit('setLoading', false)
      }
    },
    async getSftpConnection({ commit }, { customerId }) {
      const { results } = await createReports.getSftpConnectionForReports(
        customerId,
      )
      commit('setSftpConnection', results)
    },
    handleSelectableHours({ commit }) {
      const selectTime = []
      for (let i = 0; i <= 23; i++) {
        for (let j = 0; j <= 45; j += 15) {
          let hoursAndMinutes =
            (i === 0 ? '00' : i) + ':' + (j < 10 ? '0' + j : j)
          let americanFormat = DateTime.fromFormat(
            hoursAndMinutes,
            'h:mm',
          ).toFormat('hh:mm a')
          selectTime.push(americanFormat)
        }
      }
      commit('setHours', selectTime)
    },
  },
}
