import { hosts } from '@/lib/api.configs'
import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'
import qs from 'qs'

export const deliveryTypes = [
  {
    label: 'Email Report',
    value: 'EMAIL',
  },
  {
    label: 'Local Report',
    value: 'LOCAL',
  },
  {
    label: 'Scheduled Report',
    value: 'SCHEDULED',
  },
]

/**
 * @description set pretty types
 * @param {array} reports
 */
const setPrettyTypes = (reports) => {
  return {
    ...reports,
    data: reports.data.map((item) => ({
      ...item,
      title: item.reportTemplate.title || '',
    })),
  }
}

/**
 * @description get reports list
 * @param {string} customerId
 * @param {object} params
 */
const getReports = ({ customerId, params }) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/reports/list?${qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
    )
    .then(dataMiddleware)
    .then((data) => ({
      totalResults: data.total,
      data: data.results || [],
      filters: data.filters,
    }))
    .then((res) => setPrettyTypes(res))

/**
 * @description update completed reports
 * @param {string} customerId
 * @param {string} reportId
 * @param {object} body
 */
export const updateReport = ({ customerId, reportId, body }) =>
  http(hosts.v3)
    .put(`/customers/${customerId}/reports/${reportId}`, body)
    .then(dataMiddleware)

/**
 * @description delete completed reports
 * @param {string} customerId
 * @param {object} body
 */
export const removeReportList = ({ customerId, body }) =>
  http(hosts.v3)
    .delete(`/customers/${customerId}/reports/bulk`, { data: body })
    .then(dataMiddleware)

export { getReports }
