export default {
  returningUser: [
    {
      id: 100,
      description: 'Get bill charges',
      type: 'conversation',
      button_text: 'how much was my bill',
      bubble_text: 'how much was my bill',
    },
    {
      id: 120,
      description: 'Get bill summary',
      type: 'conversation',
      button_text: 'get bill summary',
      bubble_text: 'get bill summary',
    },
    {
      id: 140,
      description: 'Get charges',
      type: 'conversation',
      button_text: 'get charges',
      bubble_text: 'get charges',
    },
    {
      id: 150,
      description: 'Get usage',
      type: 'conversation',
      button_text: 'get usage',
      bubble_text: 'get usage',
    },
    {
      id: 180,
      description: 'Get cost history',
      type: 'conversation',
      button_text: 'cost history',
      bubble_text: 'get cost history',
    },
    {
      id: 200,
      description: 'Most expensive locations',
      type: 'conversation',
      button_text: 'most expensive locations',
      bubble_text: 'what are my most expensive locations',
    },
    {
      id: 220,
      description: 'Least efficient',
      type: 'conversation',
      button_text: 'least efficient',
      bubble_text: 'what are my least ',
    },
    {
      id: 5000,
      description: 'Help',
      type: 'weblink_overlay',
      button_text: 'what can you do',
      bubble_text: 'what can you do',
    },
  ],
  firstTimeUser: [
    {
      id: 100,
      description: 'Get bill charges',
      type: 'conversation',
      button_text: 'how much was my bill',
      bubble_text: 'how much was my bill',
    },
    {
      id: 120,
      description: 'Get bill summary',
      type: 'conversation',
      button_text: 'get bill summary',
      bubble_text: 'get bill summary',
    },
    {
      id: 140,
      description: 'Get charges',
      type: 'conversation',
      button_text: 'get charges',
      bubble_text: 'get charges',
    },
    {
      id: 150,
      description: 'Get usage',
      type: 'conversation',
      button_text: 'get usage',
      bubble_text: 'get usage',
    },
    {
      id: 180,
      description: 'Get cost history',
      type: 'conversation',
      button_text: 'cost history',
      bubble_text: 'get cost history',
    },
    {
      id: 200,
      description: 'Most expensive locations',
      type: 'conversation',
      button_text: 'most expensive locations',
      bubble_text: 'what are my most expensive locations',
    },
    {
      id: 220,
      description: 'Least efficient',
      type: 'conversation',
      button_text: 'least efficient',
      bubble_text: 'what are my least ',
    },
    {
      id: 5000,
      description: 'Help',
      type: 'weblink_overlay',
      button_text: 'what can you do',
      bubble_text: 'what can you do',
    },
  ],
}
