import * as API from '@/api'
import * as R from 'ramda'

const SET_LOADING = 'set_loading'
const SET_DATA = 'set_data'

const initialState = () => ({
  loading: false,
  data: {
    vendorCodes: [],
    locations: [],
  },
})

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    [SET_LOADING]: (state, flag) => {
      state.loading = flag
    },

    [SET_DATA]: (state, data) => {
      state.data = data
    },

    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async loadData({
      commit,
      rootState: {
        customers: { currentCustomerId },
      },
    }) {
      await commit(SET_LOADING, true)
      try {
        let { filters } = await API.bills.list(currentCustomerId)
        const { vendorCodes } = filters
        filters.vendorPrettyNames = vendorCodes.prettyNames || ''
        filters.amountDue = R.mapObjIndexed(Number, filters.amountDue)
        await commit(SET_DATA, filters)
      } catch (error) {
        console.warn('LOADING FILTERS ERROR:', error)
      } finally {
        await commit(SET_LOADING, false)
      }
    },
    async clearBillsFiltersData({ commit }) {
      await commit('resetState')
    },
  },

  getters: {
    data: R.prop('data'),
    loading: R.prop('loading'),
  },
}
