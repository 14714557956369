<template lang="pug">
.mobile_feed_bill-block
  .feed_bill-block_header
    div {{ block.total.utility   }}
  .feed_bill-block_body
    el-carousel(:arrow="block.list.length > 1 ? 'always': 'never'",
      :autoplay="false",
      @change="slideChange"
      :class="{ hideLeftArrow: currentSlide === 0, hideRightArrow: currentSlide === block.list.length - 1 }",
      indicator-position="none")
      el-carousel-item(v-for="(utility, index) in block.list", :key="index")
        .block_row(v-for="(item, indexInternal) in utility", :key="indexInternal" v-if="!item.hidden")
          .block_cell(v-if="!item.hidden")
            span {{ item.key }}
          .block_cell(v-if="!item.hidden")
            span(v-if="indexInternal === 0", @click="goToBill(item.value)").bill-link {{ item.value }}
            span(v-else) {{ item.value }}
        .block-details
          el-button(class="view-details", @click="showPopup(utility)", type="text").bill-link View Details
  .feed_bill-block_total
    .total-title Total:
    .total-wrapper
      div
        div Pro rated cost
        .total-cost {{ block.total.totalCharges | unit('USD') }}
      div
        div Pro rated usage
        .total-cost {{ formatUsageValue(block.total.totalConsumption, block.total.uom) }}
  el-dialog(
    custom-class="details-popup",
    :visible="isVisible",
    :append-to-body="true",
    :close-on-click-modal="false",
    top="40px",
    width="640px",
    @close="isVisible=false")
    .details-content(v-loading="loading")
      .details-content-header
        h2 Calculated Values
        .details-content-header__text \#{{ popup.title }} {{ getPrettyVendorByCode(popup.vendor) }}
          span &nbsp;({{ popup.date }})
      el-table(:data="details", :stripe="true")
        el-table-column(label="Name", prop="title", width="300")
        el-table-column(label="Bill values", prop="bill_value", width="150",  :formatter="formatTableValue")
        el-table-column(label="Pro-rated values", prop="pro_rated_value", width="150", :formatter="formatTableValue")
</template>

<script>
import * as R from 'ramda'
import { mapState, mapGetters } from 'vuex'
import { feeds } from '@/api'
import filters from '@/lib/filters'

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
    }),
  },
  data() {
    return {
      loading: true,
      details: [],
      isVisible: false,
      popup: {
        title: '',
        vendor: '',
        date: '',
      },
      currentSlide: 0,
    }
  },
  methods: {
    goToBill(billId) {
      this.$router.push({
        name: 'dashboard.bills.info',
        params: { billId, customerId: this.currentCustomerId },
      })
    },
    showPopup(utility) {
      this.isVisible = true
      this.popup.title = utility[0].value
      this.popup.vendor = utility[8].value
      this.popup.date = this.formatValue(utility[9].value)
      this.viewDetails(utility[7].value, utility[4].value, utility[5].value)
    },
    async viewDetails(blockId, startDate, endDate) {
      this.loading = true
      this.details = []
      let ar = startDate.split('/')
      startDate = `${ar[2]}-${ar[0]}-${ar[1]}`
      ar = endDate.split('/')
      endDate = `${ar[2]}-${ar[0]}-${ar[1]}`

      try {
        const data = await feeds.details({
          customerId: this.currentCustomerId,
          locationId: this.$route.params.locationId,
          blockId,
          startDate: filters.datetime(startDate, 'yyyy-LL-dd'),
          endDate: filters.datetime(endDate, 'yyyy-LL-dd'),
        })

        this.details = Object.keys(data)
          .map((key) => data[key])
          .filter((item) => !/time\s+period/i.test(item.title))
      } catch (e) {
        console.error(e)
      }

      this.loading = false
    },
    formatValue(value, units) {
      if (units) {
        return filters.unit(value, units)
      }

      if (/\d{4}-\d{2}-\d{2}/.test(value)) {
        value = filters.datetime(value, 'LL/dd/yyyy')
      }

      return value
    },
    formatUsageValue(cell, uom) {
      cell = isNaN(parseFloat(cell)) ? '0.000' : cell
      return filters.unit(cell, uom)
    },
    formatTableValue(row, column, cellValue) {
      if (row.units) {
        return filters.unit(cellValue, row.units)
      }

      if (/\d{4}-\d{2}-\d{2}/.test(cellValue)) {
        cellValue = filters.datetime(cellValue, 'LL/dd/yyyy')
      }

      return cellValue
    },
    slideChange(index) {
      this.currentSlide = index
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../../styles/utilities';

.mobile_feed_bill-block {
  margin-bottom: 30px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;

  .feed_bill-block_header {
    padding: 16px;
    font-size: 18px;
    text-align: center;
    line-height: 18px;
    font-weight: 900;
    color: #606266;
    border-bottom: 1px solid #dcdfe6;
  }

  .feed_bill-block_body {
    padding: 16px 5px 0 5px;
    .block {
      &_row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 0 10px;
        margin: 12px 0;
      }
      &_cell {
        overflow: hidden;
        white-space: nowrap;
        &:first-child {
          flex-basis: 55%;
          font-size: 14px;
          font-weight: 900;
          line-height: 17px;
          color: #909399;
          span:after {
            content: '..........................................................................................................';
            padding-left: 2px;
            color: #909399;
            font-size: 14px;
            font-weight: 900;
            line-height: 17px;
          }
        }
        &:nth-child(2) {
          padding-left: 2px;
          font-size: 14px;
          font-weight: 900;
          line-height: 14px;
          color: #606266;
        }
      }
    }

    .bill-link {
      font-size: 14px;
      font-weight: 900;
      line-height: 14px;
      color: #2893f9;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
    .block-details {
      margin-bottom: 0;
      text-align: center;
    }
  }

  .feed_bill-block_total {
    padding: 8px 16px 16px 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    color: #606266;
    white-space: nowrap;
    border-top: 1px solid #dcdfe6;
    .total-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      div {
        flex-basis: 50%;
      }
    }
    .total-title {
      padding-bottom: 10px;
    }
    .total-cost {
      padding-top: 8px;
      font-size: 18px;
      font-weight: 900;
      line-height: 14px;
      color: #323336;
    }
  }
  ::v-deep .el-carousel__item {
    padding-left: 10px;
  }
  ::v-deep .el-carousel__arrow {
    top: 43%;
    background: none;
    color: #000;
    font-size: 22px;
    &--left {
      left: -10px;
    }
    &--right {
      right: -10px;
    }
  }

  ::v-deep .el-carousel {
    &.hideLeftArrow {
      .el-carousel__arrow--left {
        display: none;
      }
    }
    &.hideRightArrow {
      .el-carousel__arrow--right {
        display: none;
      }
    }
  }

  .details-content-header {
    margin-top: -40px;
    padding-left: 15px;
    color: #2a2a2a;
    h2 {
      margin-bottom: 10px;
    }
    &__text {
      font-style: oblique;
      margin-bottom: 15px;
      span {
        font-weight: 900;
      }
    }
  }

  ::v-deep .details-popup {
    width: 100vw !important;
    margin-top: 0 !important;
    .el-table th > .cell {
      color: #1c1c1c;
      font-weight: 900;
    }
  }
}
</style>

<style lang="scss">
.el-popover.el-popper.block-details-popver {
  /*top: 52px !important;*/
  padding: 7px;
  border: 2px solid #2893f9;
  border-radius: 13px;
  background-color: #ffffff;
  box-shadow: -2px 2px 6px 0 rgba(178, 178, 178, 0.5);
  box-sizing: border-box;
}
</style>
