import { isEmpty } from 'ramda'
import * as API from '@/api'
import { initializeAxiosInterceptors } from '@/lib/http'
const TOKEN = 'authToken'
const REFRESH_TOKEN = 'refreshToken'
const initialState = () => ({
  jwt: '',
  user: {},
  refreshToken: localStorage.getItem(REFRESH_TOKEN),
})

// todo fix it
const userModuleState = () => {
  let savedState = localStorage.getItem('vuex')
  return savedState ? JSON.parse(savedState).user : initialState()
}

export default {
  namespaced: true,
  state: userModuleState(),

  getters: {
    isAuth: (state) => !!state.jwt,
    authToken: (state) => state.jwt,
    currentUser: (state) => (isEmpty(state.user) ? null : state.user),
  },

  mutations: {
    setUser(state, user) {
      // delete user.customer_id;
      state.user = { ...user }
    },
    setJWT(state, jwt) {
      state.jwt = jwt
      localStorage.setItem(TOKEN, jwt)
    },
    setRefreshToken(state, token) {
      state.refreshToken = token
      localStorage.setItem(REFRESH_TOKEN, token)
    },
    clear(state) {
      state.jwt = null
      state.user = {}
      localStorage.removeItem(TOKEN)
      localStorage.removeItem(REFRESH_TOKEN)
    },
    resetState(state) {
      const initial = initialState()
      Object.assign(state, initial)
    },
    changeProfile(state, profile) {
      state.user = {
        ...state.user,
        profile: { ...state.user.profile, ...profile },
      }
    },
    updateUser(state, newUser) {
      state.user = { ...state.user, ...newUser }
    },
  },

  actions: {
    async loadResourcesAndHierarchies({ getters, dispatch }) {
      if (!getters['resources/checkResources']) {
        await dispatch('resources/setResources', {}, { root: true })
      }
      if (!getters['locations/checkHierarchy']) {
        await dispatch('locations/loadHierarchy', {}, { root: true })
      }
    },
    async login({ dispatch, commit, rootState }, { jwt, user, refreshToken }) {
      initializeAxiosInterceptors()
      await commit('setUser', user)
      await commit('setJWT', jwt)
      await commit('setRefreshToken', refreshToken)
      await this.dispatch('user/loadResourcesAndHierarchies')
    },
    async logout({ commit }) {
      commit('clear')
      await commit('resetState')
    },
    setUser({ commit }, user) {
      return Promise.resolve(commit('setUser', user))
    },
    loadUserHeapIdentity({ getters }, customerId) {
      const currentUser = getters['currentUser']
      if (currentUser.id) {
        const currentCustomer = currentUser.customers.find(
          (customer) => +customer.id === +customerId,
        )
        const heapDetails = {
          Email: currentUser.email,
          'User Role': currentUser.roles.join(', '),
          'Pear Customer ID': currentCustomer.id,
          'Pear Customer Name': currentCustomer.name,
          'Product Tier': currentCustomer.contractDetails.productTier,
          'Contract Signed': currentCustomer.contractDetails.contractSigned,
          'Job Title': currentUser.profile.jobTitle,
        }
        window.heap.identify(currentUser.id)
        window.heap.addUserProperties(heapDetails)

        // send the same info to Delighted too
        window.delighted.survey({
          email: currentUser.email,
          properties: {
            user_role: currentUser.roles.join(', '),
            pear_customer_id: currentCustomer.id,
            pear_customer_name: currentCustomer.name,
            product_tier: currentCustomer.contractDetails.productTier,
            contract_signed: currentCustomer.contractDetails.contractSigned,
            job_title: currentUser.profile.jobTitle,
          },
        })
      }
    },
    async changeProfile({ commit }, profile) {
      commit('changeProfile', profile)
    },
    async fetchUser({ commit }) {
      const newUSer = await API.user.fetchUser()
      await commit('updateUser', newUSer)
      return newUSer
    },
    async ssoDisconnect() {
      await API.user.ssoDisconnect()
    },
    async ssoConnect({ commit }, token) {
      await API.user.ssoConnect(token)
    },
  },
}
