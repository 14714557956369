import { resources } from '@/api'
import * as R from 'ramda'
import { vendorsPrettyMap } from '@/lib/helpers'

export default {
  namespaced: true,

  state: {
    resources: [],
    prettyNames: {},
  },

  getters: {
    checkResources: (state) => state.resources.length,
    getResourceByName: (state) => (name) => {
      return state.resources.find((resource) => resource.name === name)
    },
    getPrettyVendorByCode: (state) => (code) => {
      return R.propOr(code, code, state.prettyNames)
    },
    getPrettyVendorNames: (state) => {
      return state.prettyNames
    },
  },

  mutations: {
    setResources: (state, payload) => {
      state.resources = payload
    },
    setPrettyVendorNames: (state, data) => {
      const providerVendors = data.find(
        (resource) => resource.name === 'vendors',
      )
      state.prettyNames = vendorsPrettyMap(
        providerVendors.value,
        'providers_codes',
      )
    },
  },

  actions: {
    async setResources({ commit }) {
      const { data } = await resources.getResources()
      await commit('setResources', data)
      await commit('setPrettyVendorNames', data)
    },
  },
}
