<template>
  <div>
    <section-subtitle>
      Choose what filters to apply to the budget forecast:
    </section-subtitle>
    <el-form class="filters-form" label-position="top">
      <div
        class="filters-container"
        v-for="(filters, i) in availableFilters"
        :key="i"
      >
        <div v-for="filter in filters" :key="filter.key">
          <el-form-item
            v-if="filter.key === 'utility_type' && utilityTypeCustom"
            :label="`${filter.label}:`"
          >
            <el-select
              v-model="advancedFilters[filter.key]"
              size="small"
              filterable
            >
              <el-option
                v-for="filterValues in filter.availableValues"
                :key="filterValues.value"
                :value="filterValues.value"
                :label="filterValues.key"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-else-if="filter.element === 'select'"
            :label="`${filter.label}:`"
          >
            <el-select
              :title="advancedFilters[filter.key].join(', ')"
              v-model="advancedFilters[filter.key]"
              size="small"
              @change="removeRecommendedValue($event, filter.key)"
              multiple
              clearable
              filterable
            >
              <el-option value="all" label="All (recommended)" />
              <el-option
                v-for="filterValues in filter.availableValues"
                :key="filterValues.value"
                :value="filterValues.value"
                :label="filterValues.key"
              />
            </el-select>
          </el-form-item>
          <location-hierarchy
            style="width: 650px"
            v-if="filter.element === 'hierarchy'"
            :hierarchies="hierarchies"
            ref="locationHierarchy"
            :show-only-selected-locations="showOnlySelectedLocations"
            :total-locations="totalLocations"
            :filter-text="filterText"
            :default-selected-locations="advancedFilters.location_id"
            @filter-val-change="handleFilterValue"
            @show-selected-locs="handleShowOnlySelectedLocations"
            @get-checked-keys="handleSelectedLocations"
          />
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import LocationHierarchy from '@/components/LocationHierarchyComponents/LocationHierarchy'
import { mapGetters } from 'vuex'
import SectionSubtitle from '../../../../../components/SectionSubtitle'
import { appliedFiltersDefault } from '../../Constants/Defaults'

export default {
  components: { SectionSubtitle, LocationHierarchy },
  props: {
    availableFilters: {
      type: Array,
      default: () => [],
    },
    hierarchies: {
      type: Array,
      default: () => [],
    },
    opened: {
      type: Boolean,
      default: false,
    },
    appliedFilters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showOnlySelectedLocations: false,
      filterText: '',
      advancedFilters: {
        ...appliedFiltersDefault,
      },
    }
  },
  watch: {
    opened: {
      handler() {
        Object.assign(this.advancedFilters, this.appliedFilters)
        this.initialUtValue()
        this.filterText = ''
        this.showOnlySelectedLocations = false
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      totalLocations: 'locations/total',
    }),
    utilityTypeCustom() {
      return [7033, 51024, 8110, 22646, 117, 46068].includes(
        +this.$route.params.customerId,
      )
    },
  },
  methods: {
    handleFilterValue(val) {
      this.filterText = val
    },
    removeRecommendedValue(val, key) {
      this.advancedFilters[key] = val.some((el) => el !== 'all')
        ? this.advancedFilters[key].filter((filter) => filter !== 'all')
        : val
    },
    initialUtValue() {
      if (this.utilityTypeCustom) {
        for (let filters of this.availableFilters) {
          for (let filter of filters) {
            if (filter.key === 'utility_type') {
              this.advancedFilters.utility_type =
                this.appliedFilters.utility_type[0] === 'all'
                  ? filter.availableValues[0].value
                  : this.appliedFilters.utility_type[0]
            }
          }
        }
      }
    },
    handleShowOnlySelectedLocations(val) {
      this.showOnlySelectedLocations = val
    },
    handleSelectedLocations(event) {
      this.advancedFilters.location_id = event.locationsIds
    },
  },
}
</script>

<style lang="scss" scoped>
.filters-container {
  display: inline-flex;
  width: 420px;
  justify-content: space-between;
  flex-wrap: wrap;
}
::v-deep .el-select__tags-text {
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}
</style>
