import { http } from '@/lib'
import { hosts } from '@/lib/api.configs'
import { dataMiddleware } from '@/lib/http'

async function login(email, password) {
  return http(hosts.v3)
    .post('/auth/login', {
      authType: 'basic',
      email: email,
      password: password,
    })
    .then(dataMiddleware)
}

const refreshToken = (refreshToken) =>
  http(hosts.v3)
    .post('/auth/refresh-token', { refreshToken })
    .then(dataMiddleware)

const forgot = (email) =>
  http().post('', {
    email,
  })

const patch = (data) =>
  http(hosts.v1).patch('/users', data).then(dataMiddleware)

const fetchUser = () => http(hosts.v3).get('/auth/me').then(dataMiddleware)

const ssoDisconnect = () =>
  http(hosts.v1)
    .post('/users/constellation-sso-disconnect')
    .then(dataMiddleware)

async function ssoConnect(id_token) {
  return http(hosts.v1)
    .post('/users/constellation-sso-connect', {
      azure_id_token: { id_token },
    })
    .then(dataMiddleware)
}

export {
  login,
  refreshToken,
  forgot,
  patch,
  fetchUser,
  ssoDisconnect,
  ssoConnect,
}
