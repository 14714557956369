import { dataMiddleware } from '@/lib/http'
import { hosts } from '@/lib/api.configs'
import { http } from '@/lib'

/**
 * @description get energystar properties information
 * @param {string} customerId
 */
export const getAccount = ({ customerId }) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/energy-star`)
    .then(dataMiddleware)

/**
 * @description create energystar property
 * @param {string} customerId
 * @param {object} property contains id and sort_order fields
 */
export const createProperty = ({ customerId, body }) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/energy-star/properties`, body)
    .then(dataMiddleware)

/**
 * @description update energystar property
 * @param {string} customerId
 * @param {string} propertyId
 * @param {object} property contains id and sort_order fields
 */
export const updateProperty = ({ customerId, propertyId, body }) =>
  http(hosts.v3)
    .put(`/customers/${customerId}/energy-star/properties/${propertyId}`, body)
    .then(dataMiddleware)

/**
 * @description get energystar property information
 * @param {string} customerId
 * @param {object} propertyId
 */
export const mapProperty = ({ customerId, propertyId, body }) =>
  http(hosts.v3)
    .post(
      `/customers/${customerId}/energy-star/properties/${propertyId}/map`,
      body,
    )
    .then(dataMiddleware)

/**
 * @description get energystar properties information
 * @param {string} customerId
 */
export const getProperties = (customerId) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/energy-star/properties`)
    .then(dataMiddleware)

/**
 * @description get energystar property information
 * @param {string} customerId
 * @param {object} propertyId
 */
export const getProperty = ({ customerId, propertyId }) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/energy-star/properties/${propertyId}`)
    .then(dataMiddleware)

/**
 * @description get energystar property uses information
 * @param {string} customerId
 * @param {object} propertyId
 */
export const getPropertyUses = ({ customerId, propertyId }) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/energy-star/properties/${propertyId}/uses`)
    .then(dataMiddleware)

/**
 * @description get energystar property scores
 * @param {string} customerId
 * @param {object} propertyId
 */
export const getPropertyScores = ({ customerId, propertyId }) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/energy-star/properties/${propertyId}/scores`)
    .then(dataMiddleware)

/**
 * @description get energystar property no scores reasons
 * @param {string} customerId
 * @param {object} propertyId
 */
export const getPropertyNoScoresReasons = ({
  customerId,
  propertyId,
  year,
  month,
}) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/energy-star/properties/${propertyId}/noScoreReasons?year=${year}&month=${month}`,
    )
    .then(dataMiddleware)

/**
 * @description get energystar property no scores reasons
 * @param {string} customerId
 * @param {object} propertyId
 */
export const getPropertyHistoricalScores = ({ customerId, propertyId, year }) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/energy-star/properties/${propertyId}/monthlyScores?year=${year}`,
    )
    .then(dataMiddleware)
/**
 * @description get energystar meters
 * @param {string} customerId
 * @param {string} propertyId
 * @param {string} locationId
 */
export const getMeters = ({ customerId, propertyId, locationId }) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/energy-star/properties/${propertyId}/meters?locationId=${locationId}`,
    )
    .then(dataMiddleware)
/**
 * @description get energystar meter
 * @param {string} customerId
 * @param {string} propertyId
 * @param {string} meterId
 */
export const getMeter = ({ customerId, propertyId, meterId }) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/energy-star/properties/${propertyId}/meters/${meterId}`,
    )
    .then(dataMiddleware)
/**
 * @description update energystar meter
 * @param {string} customerId
 * @param {string} propertyId
 * @param {string} meterId
 */
export const postMeter = ({ customerId, propertyId, body }) =>
  http(hosts.v3)
    .post(
      `/customers/${customerId}/energy-star/properties/${propertyId}/meters/`,
      body,
    )
    .then(dataMiddleware)
/**
 * @description update energystar meter
 * @param {string} customerId
 * @param {string} propertyId
 * @param {string} meterId
 */
export const putMeter = ({ customerId, propertyId, meterId, body }) =>
  http(hosts.v3)
    .put(
      `/customers/${customerId}/energy-star/properties/${propertyId}/meters/${meterId}`,
      body,
    )
    .then(dataMiddleware)
/**
 * @description delete energystar meter
 * @param {string} customerId
 * @param {string} propertyId
 * @param {string} meterId
 */
export const deleteMeter = ({ customerId, propertyId, meterId }) =>
  http(hosts.v3)
    .delete(
      `/customers/${customerId}/energy-star/properties/${propertyId}/meters/${meterId}`,
    )
    .then(dataMiddleware)
/**
 * @description map virtual account to meter
 * @param {string} customerId
 * @param {string} propertyId
 * @param {string} meterId
 */
export const mapMeter = ({ customerId, propertyId, meterId, virtualAccId }) =>
  http(hosts.v3)
    .post(
      `/customers/${customerId}/energy-star/properties/${propertyId}/meters/${meterId}/virtual-accounts/${virtualAccId}`,
    )
    .then(dataMiddleware)
/**
 * @description unmap virtual account from meter
 * @param {string} customerId
 * @param {string} propertyId
 * @param {string} meterId
 */
export const unmapMeter = ({ customerId, propertyId, meterId, virtualAccId }) =>
  http(hosts.v3)
    .delete(
      `/customers/${customerId}/energy-star/properties/${propertyId}/meters/${meterId}/virtual-accounts/${virtualAccId}`,
    )
    .then(dataMiddleware)
/**
 * @description create a new property use
 * @param {string} customerId
 * @param {string} propertyId
 */
export const postPropertyUses = ({ customerId, propertyId, body }) =>
  http(hosts.v3)
    .post(
      `/customers/${customerId}/energy-star/properties/${propertyId}/uses`,
      body,
    )
    .then(dataMiddleware)
/**
 * @description update a property use
 * @param {string} customerId
 * @param {string} propertyId
 */
export const putPropertyUses = ({
  customerId,
  propertyId,
  propertyUseId,
  body,
}) =>
  http(hosts.v3)
    .put(
      `/customers/${customerId}/energy-star/properties/${propertyId}/uses/${propertyUseId}`,
      body,
    )
    .then(dataMiddleware)
/**
 * @description auto-map a property use
 * @param {string} customerId
 * @param {string} propertyId
 * @param {string} meterId
 */
export const autoMeters = ({ customerId, propertyId, locationId }) =>
  http(hosts.v3).post(
    `/customers/${customerId}/energy-star/properties/${propertyId}/auto-meters`,
    { locationId },
  )
/**
 * @description delete a property from location
 * @param {string} customerId
 * @param {string} propertyId
 */
export const removeProperty = ({ customerId, propertyId, locationId }) =>
  http(hosts.v3)
    .delete(
      `/customers/${customerId}/energy-star/properties/${propertyId}/map/${locationId}`,
    )
    .then(dataMiddleware)
/**
 * @description delete a property use from location
 * @param {string} customerId
 * @param {string} propertyId
 */
export const removePropertyUse = ({ customerId, propertyId, propertyUseId }) =>
  http(hosts.v3)
    .delete(
      `/customers/${customerId}/energy-star/properties/${propertyId}/uses/${propertyUseId}`,
    )
    .then(dataMiddleware)
