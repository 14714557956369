<template>
  <div class="form-completed-view-reports-components">
    <completed-desktop
      :completedReports="completedReports"
      @sort-table="sortTable"
      @reload-data="handleRouteAndApiCall($route.query)"
    />
    <el-pagination
      class="pagination-container"
      background
      layout="prev, pager, next, sizes"
      v-if="total > pagination.pageSizes[0]"
      :page-size="pagination.limit"
      :page-sizes="pagination.pageSizes"
      :current-page="currentPage"
      @current-change="changeOffset"
      @size-change="changeLimit"
      :total="total"
      :disabled="isLoading"
    ></el-pagination>
  </div>
</template>

<script>
import { isNil } from 'lodash'
import FilterContainer from '../../Filters/FilterContainer.vue'
import CompletedDesktop from './CompletedDesktop.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { serializeOrderBy } from '@/lib/helpers'
import * as R from 'ramda'

export default {
  data() {
    return {
      processingReports: [],
    }
  },
  components: {
    FilterContainer,
    CompletedDesktop,
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      loading: 'completedReports/loading',
      completedReports: 'completedReports/reports',
      total: 'completedReports/totalResults',
      loading_list: 'completedReports/loading',
      pagination: 'completedReports/pagination',
      currentPage: 'completedReports/currentPage',
    }),
    isLoading() {
      return this.loading_list
    },
    createReportModalWidth() {
      if (!this.$deviceInfo.isMobileDevice) {
        return '610px'
      }
      return ''
    },
  },
  methods: {
    ...mapMutations({
      setLoading: 'completedReports/setLoading',
    }),
    ...mapActions({
      getCompletedReports: 'completedReports/getCompletedReports',
    }),
    changeLimit(limit) {
      if (this.loading) {
        return
      }
      const localCurrentPage = Math.floor(
        this.pagination.offset / this.pagination.limit + 1,
      )
      this.pagination.limit = limit
      if (this.pagination.limit * localCurrentPage > this.total) {
        return this.changeOffset(
          Math.floor(this.total / this.pagination.limit + 1),
        )
      } else {
        return this.changeOffset(localCurrentPage)
      }
    },
    async changeOffset(page) {
      if (this.loading) {
        return
      }
      this.pagination.offset = (page - 1) * this.pagination.limit
      this.handleRouteAndApiCall({
        ...this.$route.query,
        limit: this.pagination.limit,
        offset: this.pagination.offset,
      })
    },
    sortTable(params) {
      const { prop, order } = params
      const { query } = this.$route
      if (isNil(prop) || isNil(order)) {
        this.handleRouteAndApiCall({
          ...query,
          orderBy: [],
        })
        return
      }
      this.handleRouteAndApiCall({
        ...query,
        orderBy: [serializeOrderBy(prop, order)],
      })
    },
    handleRouteAndApiCall(query) {
      const { customerId } = this.$route.params
      this.setLoading(true)
      this.$router.push({
        query,
      })
      this.getCompletedReports({
        customerId,
        params: { ...query },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../../styles/utilities';
.form-completed-view-reports-components {
  .content-inner {
    padding-right: 40px;
    padding-left: 40px;
    overflow: scroll;

    @include respond-to($phone) {
      padding: 15px 10px;
      max-height: calc(100% - 30px);
      -webkit-overflow-scrolling: touch;
    }
    @include respond-to($phone-l) {
      padding: 15px 10px;
      max-height: calc(100% - 30px);
      -webkit-overflow-scrolling: touch;
    }
  }
}
</style>

<style lang="scss">
@import '../../../../../styles/utilities';
.content {
  .content-inner {
    .pagination-container {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 30px;
      padding: 0 100px;

      @include respond-to($phone) {
        flex-wrap: wrap;
        padding: 0;
      }

      .el-pager {
        @include respond-to($phone) {
          margin: 10px 0;
        }

        li {
          height: 30px;
          line-height: 30px;

          @include respond-to($phone) {
            margin: 0 4px !important;

            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .btn-prev,
      .btn-next {
        height: 30px;
        line-height: 30px;

        @include respond-to($phone) {
          min-width: 100% !important;
        }
      }
      .el-pagination__sizes {
        position: absolute;
        right: 0;
        margin-right: 0;

        @include respond-to($phone) {
          display: none !important;
        }
        @include respond-to($phone-l) {
          display: none !important;
        }
      }
      .el-select .el-input {
        margin: 0;
      }
    }
    .pagination-container.is-background .el-pager li {
      background-color: #f0f2f5;
    }
    .pagination-container.is-background .el-pager li:not(.disabled).active {
      background-color: #0080ff;
    }
    .pagination-container .el-select .el-input .el-input__inner {
      height: 30px !important;
      color: #4a4a4a;
      border: 0;
      background-color: #f0f2f5;
    }
    .create-report-modal {
      .el-dialog {
        border-radius: 10px;

        @include respond-to($phone) {
          border-radius: 0;
        }
      }
      .el-dialog__header {
        padding: 40px 40px 0;

        @include respond-to($phone) {
          padding: 30px 15px 0;
        }
      }
      .el-dialog__headerbtn {
        top: 30px;
        right: 40px;

        @include respond-to($phone) {
          top: 20px;
          right: 15px;
          font-size: 20px;
        }
      }
      .el-dialog__title {
        color: #2c2e34;
        font-weight: 900;

        @include respond-to($phone) {
          color: #273a58;
          font-size: 28px;
        }
      }
      .el-dialog__body {
        padding: 20px 40px 30px;

        @include respond-to($phone) {
          padding: 15px 15px 40px;
        }
      }
    }
    .create-report-notify {
      top: 30px !important;
      right: 40px !important;
      width: 360px;
      padding: 30px;

      @include respond-to($phone) {
        right: 20px !important;
        width: calc(100% - 40px);
      }

      .el-notification__group {
        margin-left: 0;
      }
      .el-notification__content {
        margin-top: 10px;
        line-height: 24px;
      }
    }
  }
}
</style>
