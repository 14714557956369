<template lang="pug">
.filter.filter-m
  .filter-container
    .filter-item
      .filter-title Search
      .select-box
        filter-search(:search-query="filters.query",
                    :onChange='setFilters')
    .filter-item
      .filter-title Invoice Date
      filter-datepicker(:filter-name="'statement_date'", :onChange="setFilters")
    .filter-item
      .filter-title Vendor
      filter-select(:filter-name="'vendor_codes'",
                    :options="filtersData.vendor_pretty_names",
                    :onSelectChange="setVendorCodes",
                    :selected="selectedVendors")
    .filter-item
      .filter-title Location
      filter-select(:filter-name="'location_ids'",
                    :options="filtersData.locations",
                    :onSelectChange="setFilters",
                    :itemValue="'id'",
                    :itemLabel="'name'",
                    :selected="filters.location_ids")
    .filter-item
      .filter-title Commodity
      filter-select(:filter-name="'commodities'",
                    :options='commodities',
                    :onSelectChange='$changeFilters',
                    :selected='filters.commodities')
    .filter-item
      .filter-title Cost Range
      filter-range(v-if="filtersData.amount_due",
                  :filter-name="'amount_due'",
                  :onChange="setFilters",
                  :min="filters.amount_due_min",
                  :max="filters.amount_due_max")
    el-button.button-filter-apply(type="primary", size="small", @click="applyFilters") Apply Filters
    el-button.button-filter-reset(type="primary", size="small", @click="resetFilters") Reset Filters
</template>

<script>
import debounce from 'lodash.debounce'
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import FilterSearch from '@/components/Filters/FilterSearch'
import FilterSelect from '@/components/Filters/FilterSelect'
import FilterDatepicker from '@/components/Filters/FilterDatepicker'
import FilterRange from '@/components/Filters/FilterRange'
import * as R from 'ramda'
import { vendorsPrettyMap } from '@/lib/helpers'

export default {
  components: {
    FilterSearch,
    FilterSelect,
    FilterDatepicker,
    FilterRange,
  },
  data() {
    return {
      showFilter: true,
      placeholder: 'Search',
      newFilters: {},
    }
  },
  computed: {
    ...mapGetters({
      isFilterDataLoading: 'bills/filtersData/loading',
      filtersData: 'bills/filtersData/data',
      filters: 'bills/filters/filters',
      getResourceByName: 'resources/getResourceByName',
    }),
    commodities: {
      get() {
        return _.get(this.getResourceByName('commodities'), 'value', []).map(
          (i) => i.title,
        )
      },
    },
    selectedVendors() {
      const { vendor_codes } = this.filtersData
      const prettyMap = vendorsPrettyMap(vendor_codes, 'codes')
      const selectedVendorCodes = R.pathOr(
        [],
        ['filters', 'vendor_codes'],
        this,
      )
      return R.pipe(
        R.map((code) => R.prop(code, prettyMap)),
        R.uniq,
      )(selectedVendorCodes)
    },
  },
  methods: {
    ...mapActions({
      $changeFilters: 'bills/filters/changeFilters',
      $resetFilers: 'bills/filters/resetFilters',
    }),
    applyFilters() {
      this.$changeFilters(this.newFilters)
      this.$emit('close', true)
    },
    resetFilters() {
      this.$resetFilers()
      this.$emit('close', true)
    },
    setFilters(filter) {
      const setNewFilters = debounce(() => {
        this.newFilters = {
          ...this.newFilters,
          ...filter,
        }
      }, 300)

      setNewFilters()
    },
    setVendorCodes(selected) {
      if (this.isFilterDataLoading) {
        return
      }
      const { vendor_codes } = this.filtersData
      selected = R.propOr([], 'vendor_codes')(selected)

      const codes = R.map((pretty) => {
        const vendor = R.find(R.propEq('pretty_name', pretty), vendor_codes)
        return R.propOr([], 'codes', vendor)
      })(selected)
      this.setFilters({
        vendor_codes: R.reduce(R.concat, [], codes),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.filter {
  .filter-container {
    .select-box {
      ::v-deep .el-input {
        width: 100%;
      }
    }
  }
}
</style>
