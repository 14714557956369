<template>
  <div class="btn-container">
    <el-button class="btn" round type="primary" @click="onClose">
      <slot name="cancel" />
    </el-button>
    <el-button class="btn" round type="primary" @click="onConfirm">
      <slot name="confirm" />
    </el-button>
  </div>
</template>

<script>
export default {
  methods: {
    onClose() {
      this.$emit('close')
    },
    onConfirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-container {
  display: inline-flex;
  padding: 32px 0;
  justify-content: center;
  width: 100%;
  .btn {
    width: 185px;
    margin-right: 20px;
    font-weight: 600;
  }
}
</style>
