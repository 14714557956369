import { dashboard } from '@/api'
import _ from 'lodash'

const initialState = () => {
  return {
    list: {
      data: [],
      meta: {},
    },
    widgetTypes: null,
    widgetsMeta: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),

  getters: {
    widgetTypes: (state) => state.widgetTypes,
    listData: (state) => state.list.data,
    listMeta: (state) => state.list.meta,
    widgetsMeta: (state) => state.widgetsMeta,
  },

  mutations: {
    addWidget(state, widget) {
      state.list.data.push(widget)
    },
    setListData(state, data) {
      state.list.data = data
    },
    setWidgetsList(state, { data, meta }) {
      state.list.data = data.filter((item) =>
        _.keys(state.widgetTypes).includes(item.type),
      )
      state.list.meta = meta
    },
    setWidget: (state, widget) => {
      widget = _.mapKeys(widget, (value, key) => _.snakeCase(key))
      const widgetIndex = state.list.data.findIndex((w) => +w.id === +widget.id)

      state.list.data.splice(widgetIndex, 1, {
        ...state.list.data[widgetIndex],
        ...widget,
      })
    },
    deleteWidget(state, id) {
      state.list.data = _.remove(state.list.data.slice(), (i) => i.id !== id)
    },
    setWidgetsTypes: (state, payload = {}) => {
      state.widgetTypes = _.pickBy(payload, (o) => !o.hidden)
    },
    setWidgetMeta: (state, { widgetType, meta }) => {
      state.widgetsMeta[widgetType] = meta
    },
    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async createWidget({ commit }, { customerId, body }) {
      const widget = await dashboard.createWidget(customerId, body)
      commit('addWidget', widget)
    },
    async listWidgets({ commit, rootGetters }, customerId) {
      const response = (await dashboard.getWidgets(customerId)) || { data: [] }
      const { value } = rootGetters['resources/getResourceByName']('widgets')

      const data = response.data.map((e) => ({
        ...e,
        description: value[e.type].description,
      }))

      commit('setWidgetsList', { ...response, data })
    },
    async patchWidget({ commit }, { customerId, widget }) {
      const response = await dashboard.updateWidget({ customerId, widget })
      await commit('setWidget', response[0])
    },
    async deleteWidget({ commit }, { customerId, widgetId }) {
      await dashboard.removeWidget({ customerId, widgetId })
      commit('deleteWidget', widgetId)
    },
    async reorderWidgets(context, { customerId, widgets }) {
      await dashboard.updateWidget({
        customerId,
        widget: widgets,
      })
    },
    async setWidgetsTypes({ commit, rootGetters }) {
      const { value } = rootGetters['resources/getResourceByName']('widgets')
      commit('setWidgetsTypes', value)
    },
    async getWidgetMeta(
      { commit, rootGetters },
      { customerId, widgetType, filters },
    ) {
      const meta = await dashboard.getWidgetMeta({
        customerId,
        widgetType,
        filters,
      })
      commit('setWidgetMeta', { widgetType, meta })
    },
  },
}
