<template>
  <div class="filter">
    <div class="filter-container">
      <div class="filter-item query">
        <div class="filter-title">Search</div>
        <el-input
          class="filter-item-inner query"
          v-model="filters.query"
          prefix-icon="el-icon-search"
          :placeholder="'Search'"
        />
      </div>
      <div class="filter-item">
        <div class="filter-title">Type</div>
        <div class="filter-item-inner">
          <el-select
            :value="filters.buildingTypes"
            @change="changeBuildingTypes"
            multiple
            filterable
            clearable
            placeholder="Select"
          >
            <el-option
              v-for="buildingType in filtersData.buildingType"
              :key="buildingType"
              :value="buildingType"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="filter-item">
        <div class="filter-title">City</div>
        <div class="filter-item-inner">
          <el-select
            :value="filters.cities"
            @change="changeCity"
            multiple
            filterable
            clearable
            placeholder="Select"
          >
            <el-option
              v-for="city in filtersData.city"
              :key="city"
              :value="city"
            />
          </el-select>
        </div>
      </div>
      <div class="filter-item">
        <div class="filter-title">State</div>
        <div class="filter-item-inner">
          <el-select
            :value="filters.states"
            @change="changeState"
            multiple
            filterable
            clearable
            placeholder="Select"
          >
            <el-option
              v-for="state in states"
              :key="state.value"
              :value="state.value"
              :label="state.name"
            />
          </el-select>
        </div>
      </div>
      <div class="filter-item">
        <div class="filter-title">Area (Sq. Ft.)</div>
        <div class="filter-item-inner area">
          <el-input
            v-model="filters.squareFeetMin"
            type="number"
            :placeholder="'Min'"
          />
          <span class="to">to</span>
          <el-input
            v-model="filters.squareFeetMax"
            type="number"
            :placeholder="'Max'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as R from 'ramda'
import { debounce } from 'lodash'

export default {
  created() {
    this.debounceChangeFilters = debounce(this.$changeFilters, 500)
  },
  watch: {
    'filters.query'(value) {
      this.debounceChangeFilters({ searchQuery: value, offset: 0 })
    },
    'filters.squareFeetMin'(value) {
      this.debounceChangeFilters({ squareFeetMin: value, offset: 0 })
    },
    'filters.squareFeetMax'(value) {
      this.debounceChangeFilters({ squareFeetMax: value, offset: 0 })
    },
  },
  computed: {
    ...mapGetters({
      loadingData: 'locations/filtersData/loading',
      filtersData: 'locations/filtersData/data',
      filters: 'locations/filters/filters',
      getResourceByName: 'resources/getResourceByName',
    }),
    states() {
      const filters = R.propOr([], 'state', this.filtersData)
      const states = R.propOr({}, 'value')(this.getResourceByName('states'))
      return R.map((key) => {
        const prettyName = R.pathOr('', [key, 'pretty_name'], states)
        const state = (key || '').replace(/^.*-/g, '')
        return {
          name: `${prettyName} (${state})`,
          value: key,
        }
      })(filters)
    },
  },

  methods: {
    ...mapActions({
      $changeFilters: 'locations/filters/changeFilters',
    }),
    changeBuildingTypes(value) {
      this.$changeFilters({ buildingTypes: [...value], offset: 0 })
    },
    changeState(value) {
      this.$changeFilters({ states: [...value], offset: 0 })
    },
    changeCity(value) {
      this.$changeFilters({ cities: [...value], offset: 0 })
    },
  },
}
</script>
<style lang="scss" scoped>
.filter {
  .filter-container {
    .query {
      ::v-deep .el-input {
        &__inner {
          width: 180px;
          padding-left: 38px;
        }
      }
    }
    .area {
      ::v-deep .el-input {
        &__inner {
          padding-right: 0;
        }
      }
    }
  }
}
</style>
