<template lang="pug">
.bill-block
  bill-block-utility(v-for="(bill, index) in blockGrouped", :key="index", :block="bill")
</template>

<script>
import * as R from 'ramda'
import { mapState } from 'vuex'
import { feeds } from '@/api'
import filters from '@/lib/filters'
import _ from 'lodash'
import BillBlockUtility from './BillBlockUtility'

export default {
  props: {
    block: {
      type: Array,
      required: true,
    },
  },
  components: {
    BillBlockUtility,
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    blockGrouped: function () {
      let mobileGrouped = []
      let grouped = _.flatMap(this.block, (item) => item)
      grouped = _.groupBy(grouped, 'utilityType')
      _.forEach(grouped, (value, utility) => {
        let totalCharges = 0
        let totalConsumption = 0
        let uom = ''
        let bill = { list: [], total: {} }
        value.forEach((item) => {
          uom = item.totalConsumptionUom
          let converted = [
            { key: 'Bill ID', value: item.billId },
            { key: 'Utility', value: item.utilityType },
            { key: 'Cost', value: filters.unit(item.totalCharges, 'USD') },
            {
              key: 'Usage',
              value: this.formatUsageValue(item.totalConsumption, uom),
            },
            {
              key: 'Start Date',
              value: filters.datetime(item.startDate, 'LL/dd/yyyy'),
            },
            {
              key: 'End Date',
              value: filters.datetime(item.endDate, 'LL/dd/yyyy'),
            },
            { key: 'Day of Service', value: item.daysOfService },
            { key: 'block_id', value: item.blockId, hidden: true },
            { key: 'vendorCode', value: item.vendorCode, hidden: true },
            { key: 'statementDate', value: item.statementDate, hidden: true },
          ]
          bill.list.push(converted)
          totalCharges += Number(item.totalCharges) || 0
          totalConsumption += Number(item.totalConsumption) || 0
        })
        bill.total = { utility, totalCharges, totalConsumption, uom }

        mobileGrouped.push(bill)
      })
      return mobileGrouped
    },
  },
  data() {
    return {
      isCollapsed: true,
      loading: true,
      details: [],
    }
  },
  methods: {
    goToBill(billId) {
      this.$router.push({
        name: 'dashboard.bills.info',
        params: { billId, customerId: this.currentCustomerId },
      })
    },
    async viewDetails(blockId, startDate, endDate) {
      this.loading = true
      this.details = []

      try {
        const data = await feeds.details({
          customerId: this.currentCustomerId,
          locationId: this.$route.params.locationId,
          blockId,
          startDate: filters.datetime(startDate, 'yyyy-LL-dd'),
          endDate: filters.datetime(endDate, 'yyyy-LL-dd'),
        })

        this.details = Object.keys(data)
          .map((key) => data[key])
          .filter((item) => !/time\s+period/i.test(item.title))
          .filter((item) => !R.isNil(item.title) && item.title !== '')
      } catch (e) {
        console.error(e)
      }

      this.loading = false
    },
    formatValue(value, units) {
      if (units) {
        return filters.unit(value, units)
      }

      if (/\d{4}-\d{2}-\d{2}/.test(value)) {
        value = filters.datetime(value, 'LL/dd/yyyy')
      }

      return value
    },
    formatUsageValue(cell, uom) {
      cell = isNaN(parseFloat(cell)) ? '0.000' : cell
      return filters.unit(cell, uom)
    },
  },
}
</script>
