<template lang="pug">
  .content-inner
    .reports-page
      .page-header
        .page-title Reports
      el-tabs(v-model='activeTab' @tab-click="onTabClick")
        el-tab-pane(label='Create Reports', name='create', data-action='create-report' )
          create-tab(:resetState="resetState" @delete-search-reset="resetStateReset")
        el-tab-pane(label='Scheduled Reports', name='scheduled', data-action='scheduled-report')
          scheduled-tab
        el-tab-pane(label='Completed Reports', name='completed', data-action='show-completed')
          completed-tab
</template>

<script>
import * as R from 'ramda'
import { mapActions, mapState, mapGetters } from 'vuex'
import CompletedTab from './Tabs/Completed/View.vue'
import CreateTab from './Tabs/Create/View'
import ScheduledTab from './Tabs/Scheduled/ScheduledView'
import { Events } from '@/lib'
import _ from 'lodash'

const CREATE_REPORT_PAGE_TITLE = 'Create Report'

export default {
  components: {
    ScheduledTab,
    CompletedTab,
    CreateTab,
  },
  data() {
    return {
      tabsList: ['completed', 'scheduled', 'create'],
      processingReports: [],
      activeTab: 'create',
      resetState: false,
      checker: null,
      defaultTitle: null,
    }
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      reportTemplates: 'createReports/reports',
      scheduledReports: 'scheduledReports/scheduledReports',
      completedReports: 'completedReports/reports',
      totalResults: 'createReports/totalResults',
    }),
  },
  watch: {
    currentCustomerId(val) {
      const { tab } = this.$route.params
      this.handleRouteAndApiCall(val, tab)
      this.loadLocations(+this.$route.params.customerId)
    },
  },
  mounted() {
    this.handleTabs()
  },
  methods: {
    ...mapActions({
      getCreatedReports: 'createReports/getReports',
      handleSelectableHours: 'createReports/handleSelectableHours',
      getSftpConnection: 'createReports/getSftpConnection',
      getScheduledReports: 'scheduledReports/getScheduledReports',
      getCompletedReports: 'completedReports/getCompletedReports',
      resetFilters: 'reportFilters/resetState',
      resetTabStates: 'reports/resetTabStates',
      loadLocations: 'locations/loadLocations',
    }),
    async handleRouteAndApiCall(customerId, tab) {
      await this.$router.push({
        name: 'dashboard.reports.tab',
        params: { customerId, tab },
        query: { limit: '10', offset: '0' },
      })
      this.handleTabData(customerId, tab)
    },
    handleTabs() {
      const { customerId, tab = 'create' } = this.$route.params
      if (this.tabsList.indexOf(tab) === -1) {
        Events.$emit('not.found')
        return
      }
      this.activeTab = tab
      this.handleRouteAndApiCall(customerId, tab)
    },
    handleTabData(customerId, tab) {
      if (this.defaultTitle === null) {
        this.defaultTitle = this.$route.meta.title
      }
      this.$route.meta.title = this.defaultTitle
      this.handleSelectableHours()
      if (tab === 'create') {
        this.$route.meta.title = CREATE_REPORT_PAGE_TITLE
        this.loadLocations(+this.$route.params.customerId)
        this.getCreatedReports({
          customerId,
          params: { ...this.$route.query },
        })
        this.getSftpConnection({ customerId })
        return
      }
      if (tab === 'scheduled') {
        if (
          _.isNil(this.reportTemplates) ||
          this.reportTemplates.length < this.totalResults
        ) {
          this.getCreatedReports({
            customerId,
          })
          this.getSftpConnection({ customerId })
        }
        this.getScheduledReports({
          customerId,
          params: { ...this.$route.query },
        })
        return
      }
      if (tab === 'completed') {
        this.getCompletedReports({
          customerId: customerId,
          params: { ...this.$route.query },
        })
        this.checkProcessingReports()
      }
    },
    onTabClick() {
      const { customerId } = this.$route.params
      this.handleRouteAndApiCall(customerId, this.activeTab)
      this.resetFilters()
      this.resetTabStates()
      this.resetState = !this.resetState
      this.resetTabStates()
    },
    deleteSearchReset(payload) {
      this.deleteSearch = payload
    },
    resetStateReset(payload) {
      this.resetState = payload
    },
    checkProcessingReports() {
      const interval = setInterval(async () => {
        const processingStatus =
          this.completedReports &&
          this.completedReports.some(
            (completedReport) => completedReport.status === 'Processing',
          )
        if (processingStatus) {
          await this.getCompletedReports({
            customerId: this.$route.params.customerId,
            params: { ...this.$route.query },
          })
        } else {
          clearInterval(interval)
        }
      }, 5000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/utilities';

.content-inner {
  padding-right: 40px;
  padding-left: 40px;
  overflow: auto;

  @include respond-to($phone) {
    -webkit-overflow-scrolling: touch;
  }
  @include respond-to($phone-l) {
    -webkit-overflow-scrolling: touch;
  }
}
</style>

<style lang="scss">
@import '../../../styles/utilities';
.content-inner {
  .reports-page {
    .page-header {
      .page-title {
        font-size: 24px;
        line-height: 45px;
        color: #333333;
        margin-bottom: 16px;
      }

      @include respond-to($phone) {
        display: none;
      }
    }

    ::v-deep .el-tabs__header {
      margin-bottom: 30px;

      @include respond-to($phone) {
        margin: 0 20px 20px;
      }
    }

    ::v-deep .el-tabs__item {
      font-weight: 900;
    }

    ::v-deep .last-bill {
      color: #0080ff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    ::v-deep .virtual-accounts-table td {
      cursor: default;
    }
  }

  .create-report-notify {
    top: 30px !important;
    right: 40px !important;
    width: 360px;
    padding: 30px;

    @include respond-to($phone) {
      right: 20px !important;
      width: calc(100% - 40px);
    }

    .el-notification__group {
      margin-left: 0;
    }

    .el-notification__content {
      margin-top: 10px;
      line-height: 24px;
    }
  }
}
</style>
