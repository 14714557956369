<template>
  <el-form label-position="left">
    <BaseInput
      :isEditMode="true"
      v-model="filters.title"
      placeholder="Report name"
      :required="true"
      maxlength="50"
      name="name"
      label="Report name:"
      :labelWidth="'100px'"
      rules="required"
      size="medium"
    />
    <BaseSelect
      :isEditMode="true"
      label="Format:"
      name="format"
      size="medium"
      :labelWidth="'100px'"
      :required="true"
      rules="required"
      v-model="filters.saveFormat"
    >
      <el-option
        v-for="v in formats"
        :key="v.value"
        :value="v.value"
        :label="v.name"
      />
    </BaseSelect>
  </el-form>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import BaseInput from '@/components/FormComponents/BaseInput'
import BaseSelect from '@/components/FormComponents/BaseSelect'
export default {
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BaseInput,
    BaseSelect,
    ValidationObserver,
  },
  data() {
    return {}
  },
  computed: {
    formats: {
      get() {
        return [
          { name: 'Microsoft Excel .xlsx', value: 'xlsx' },
          { name: 'CSV', value: 'csv' },
        ]
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.el-form {
  ::v-deep .el-input {
    width: 280px;
  }
}
</style>
