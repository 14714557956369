import { render, staticRenderFns } from "./FeedDetailsModal.vue?vue&type=template&id=648d84ce&lang=pug&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports