import { http } from '@/lib'
import { dataMiddleware } from '../lib/http'
import { hosts } from '@/lib/api.configs'

async function login(email, password) {
  return http(hosts.v3)
    .post('/auth/login', {
      authType: 'basic',
      email: email,
      password: password,
    })
    .then(dataMiddleware)
}

async function loginAzureAd(id_token) {
  return http(hosts.v3)
    .post('/auth/login', {
      token: id_token,
      authType: 'azureToken',
    })
    .then(dataMiddleware)
}

async function forgotPassword(email) {
  return http(hosts.v3)
    .post('/users/forgot-password', { email })
    .then(dataMiddleware)
}

async function checkActivateToken(token) {
  return http(hosts.v3).get(`users/activate/${token}`).then(dataMiddleware)
}

async function activate(token, password) {
  return http(hosts.v1)
    .post(`auth/reset/${token}`, { password })
    .then(dataMiddleware)
}

async function checkResetToken(token) {
  return http(hosts.v3)
    .get(`/users/password-reset/${token}`)
    .then(dataMiddleware)
}

async function resetPassword(token, password) {
  return http(hosts.v3)
    .post(`/users/password-reset/${token}`, { password })
    .then(dataMiddleware)
}

async function activateAccount(token, password, id_token) {
  return http(hosts.v3)
    .post(
      `/users/activate`,
      Object.assign(
        { token, password },
        id_token ? { azure_id_token: { id_token } } : {},
      ),
    )
    .then(dataMiddleware)
}

export {
  login,
  forgotPassword,
  checkActivateToken,
  activate,
  checkResetToken,
  resetPassword,
  activateAccount,
  loginAzureAd,
}
