<template>
  <div class="energy-star-list-modal">
    <div v-loading="loading" class="energystar-page" v-if="!this.hasESAccount">
      <div class="energystar-page-empty">
        <h2>
          There is no ENERGY STAR® account found. Please contact Pear.ai
          administrator for further information.
        </h2>
      </div>
    </div>
    <div v-loading="loading" v-else>
      <div class="energystar-page" v-if="!location.energyStarPropertyId">
        <div class="energystar-page-empty">
          <h2>This location is not setup for ENERGY STAR® reporting.</h2>
        </div>
        <div class="energystar-form-actions">
          <el-button
            round
            type="primary"
            @click="openCreateEnergyStarForm"
            data-action="submit-report"
          >
            Create ENERGY STAR® Property
          </el-button>
        </div>
        <p>or</p>
        <div class="energystar-form-actions">
          <el-button type="text" @click="openMapPropertyModal">
            <span>Map to an existing ENERGY STAR® Property</span>
          </el-button>
        </div>
      </div>
      <div v-loading="loading" v-else>
        <div class="location-attributes">
          <div class="location-attributes-body">
            <div class="location-information">
              <section class="location-info_item">
                <h3 class="location-info_title">
                  ENERGY STAR® Property Details
                </h3>
                <template>
                  <el-button
                    class="dock-right"
                    type="text"
                    @click="openRemoveModal"
                  >
                    <span class="btn-red">Remove Property Mapping</span>
                  </el-button>
                  <el-button
                    class="dock-right"
                    type="text"
                    @click="editProperty"
                  >
                    <span>Edit Property</span>
                  </el-button>
                  <el-button
                    class="dock-right"
                    type="text"
                    @click="handleShowCorrectModal"
                  >
                    <span>Meter Associations</span>
                  </el-button>
                </template>
              </section>
              <section class="location-info_item">
                <div class="location-info_field">
                  <label>Property ID:</label>
                  <span>{{ location.energyStarPropertyId || '-' }}</span>
                </div>
                <div class="location-info_field">
                  <label>Name:</label>
                  <span>{{ energyStarProperty.name || '-' }}</span>
                </div>
                <div class="location-info_field">
                  <label>Address:</label>
                  <div class="location-info_address">
                    {{ energyStarPropertyAddress }}
                  </div>
                </div>
                <div class="location-info_field">
                  <label>Primary Use:</label>
                  <span>{{ energyStarProperty.primaryFunction || '-' }}</span>
                </div>
                <div class="location-info_field">
                  <label>Year Built:</label>
                  <span>{{ energyStarProperty.yearBuilt || '-' }}</span>
                </div>
                <div class="location-info_field">
                  <label>Gross Floor Area:</label>
                  <span>{{ grossFloorArea }}</span>
                </div>
                <div class="location-info_field">
                  <label>Irrigated Area:</label>
                  <span>{{ irrigatedArea }}</span>
                </div>
                <div class="location-info_field">
                  <label>Occupancy:</label>
                  <span>
                    {{ (energyStarProperty.occupancyPercentage || '-') + '%' }}
                  </span>
                </div>
              </section>
              <section class="location-info_item">
                <h3 class="location-info_title">Property Use Information</h3>
                <el-button
                  v-if="!energyStarPropertyUses.length"
                  class="dock-right"
                  type="text"
                  @click="showChoosePropUseModal = true"
                >
                  <span>Add New Property Use</span>
                </el-button>
              </section>
              <section
                v-if="!energyStarPropertyUses.length"
                class="location-info_item"
              >
                <div class="no-property-use">
                  <p>
                    You have no property use entries for this property. You must
                    have at least one property use entry to receive ENERGY STAR®
                    ratings.
                  </p>
                </div>
              </section>
              <section v-else class="location-info_item">
                <div class="location-info_field">
                  <el-form ref="form" :inline="true" label-position="left">
                    <BaseSelect
                      :value="crtPropertyUseType"
                      labelWidth="150px"
                      label="Property Use Name:"
                      name="propertyUse"
                      size="small"
                      :required="true"
                      :isEditMode="true"
                      @change="setCrtPropertyUse"
                    >
                      <el-option
                        v-for="v in energyStarPropertyUses"
                        :key="v.id"
                        :value="v.id"
                        :label="v.name"
                      />
                      <el-option :value="crtPropertyUseType">
                        <el-button
                          type="text"
                          class="select"
                          @click="showChoosePropUseModal = true"
                        >
                          Add New Property Use
                        </el-button>
                      </el-option>
                    </BaseSelect>
                    <el-form-item>
                      <el-button type="text" @click="openEditPropertyUses">
                        Edit
                      </el-button>
                      <el-button type="text" @click="openRemovePropertyUses">
                        <span class="btn-red">Remove</span>
                      </el-button>
                    </el-form-item>
                    <br />
                    <BaseInput
                      v-model="propertyUseLabels[propertyUse.type]"
                      label="Property Type:"
                      :labelWidth="'165px'"
                      size="small"
                    />
                  </el-form>
                </div>
                <div class="location-info_item">
                  <!-- PROPERTY USE DATA -->
                  <el-table v-if="propertyUse" :data="propertyUse.useDetails">
                    <el-table-column
                      prop="label"
                      label="Property Attributes"
                      sortable
                    />
                    <el-table-column prop="unitValue" label="Value" sortable />
                    <el-table-column
                      prop="currentAsOf"
                      label="Current As Of"
                      sortable
                    />
                    <el-table-column
                      prop="temporary"
                      label="Temporary value?"
                    ></el-table-column>
                  </el-table>
                </div>
              </section>
            </div>
            <el-card body-style="padding:10px;" class="location-score">
              <h2>ENERGY STAR® Score (1-100)</h2>
              <div class="month-score current-score">
                <div class="label">
                  <label>Current Score</label>
                  <span v-if="energyStarScores.currentScore">
                    {{ formatDate(energyStarScores.currentScore.date) }}
                  </span>
                </div>
                <div class="value">
                  <template v-if="energyStarScores.currentScore">
                    <h2 class="score">
                      <template
                        v-if="energyStarScores.currentScore.score !== false"
                      >
                        {{ energyStarScores.currentScore.score }}
                      </template>
                      <template v-else>
                        <el-button
                          type="text"
                          @click="
                            getNoScoreReasons(
                              energyStarScores.currentScore.date,
                            )
                          "
                        >
                          <span>N/A</span>
                        </el-button>
                      </template>
                    </h2>
                  </template>
                  <template v-else>
                    <span class="el-icon-loading"></span>
                  </template>
                </div>
              </div>
              <div class="month-score">
                <div class="label">
                  <label>Baseline Score</label>
                  <span v-if="energyStarScores.baselineScore">
                    {{ formatDate(energyStarScores.baselineScore.date) }}
                  </span>
                </div>
                <div class="value">
                  <template v-if="energyStarScores.baselineScore">
                    <h2 class="score">
                      <template
                        v-if="energyStarScores.baselineScore.score !== false"
                      >
                        {{ energyStarScores.baselineScore.score }}
                      </template>
                      <template v-else>
                        <el-button
                          type="text"
                          @click="
                            getNoScoreReasons(
                              energyStarScores.baselineScore.date,
                            )
                          "
                        >
                          <span>N/A</span>
                        </el-button>
                      </template>
                    </h2>
                  </template>
                  <template v-else>
                    <span class="el-icon-loading"></span>
                  </template>
                </div>
              </div>
              <div
                class="month-score_right"
                v-if="energyStarScores.baselineScore"
              >
                <el-button
                  class="dock-right"
                  type="text"
                  @click="getHistoricalScores()"
                >
                  View Historical Scores
                </el-button>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
    <create-energy-star-modal
      :visible.sync="showCreateEnergyStarModal"
      :title="modalTitle"
      @close="closeCreateEnergyStarModal"
      :customerId="currentCustomerId"
      :property="energyStarProperty"
      :isUpdateMode="isUpdateMode"
    />
    <map-property-modal
      :visible.sync="showMapPropertyModal"
      @close="closeMapPropertyModal"
      :customerId="currentCustomerId"
      :property="energyStarProperty"
      :isUpdateMode="isUpdateMode"
    />
    <energy-star-no-scores-reasons-modal
      :visible.sync="showEnergyStarNoScoresReasonsModal"
      @close="closeEnergyStarNoScoresReasonsModal"
      :noScoreReasons="noScoreReasons"
    />
    <energy-star-historical-scores-modal
      :visible.sync="showEnergyStarHistoricalScores"
      @close="closeEnergyStarHistorycalScoresModal"
      @getNoScoreReasons="getNoScoreReasons"
      @getHistoricalScores="getHistoricalScores"
      :historicalScores="historicalScores"
      :year="historicalYear"
    />
    <show-energy-star-auto-meters-modal
      :visible.sync="showESAutoMetersSetupModal"
      :isLoading.sync="autoMetersSetupLoading"
      :createError.sync="showErrorMessage"
      @close="closeESAuto"
      @open-meters="openMetersModal"
      @setup-auto-meters="setupAutoMeters"
    />
    <meters-modal
      :visible.sync="showMetersModal"
      :loading.sync="getMetersLoading"
      :propertyId="location.energyStarPropertyId"
      :propertyName="energyStarProperty.name"
      :energyStarMeters.sync="energyStarMeters"
      :createError.sync="getMetersError"
      @close="closeMetersModal"
      @open-edit-meters="openEditMeters"
      @get-meters="getMeters"
      @map-virtual-acc="openVirtualAcc"
      @unmap-virtual-acc="unmap"
    />
    <edit-meter-modal
      :meterId="meterId"
      :meterName="meterName"
      :propertyId="location.energyStarPropertyId"
      :visible.sync="showEditMeters"
      @close="closeEditMeterModal"
      @open-edit-meters="openEditMeters"
      @get-meters="getMeters"
    />
    <map-virtual-acc-modal
      :visible.sync="showMapToMeterModal"
      :energyStarMeters.sync="energyStarMeters"
      :virtualAcc="virtualAcc"
      @close="closeMapVirtualAccModal"
    />
    <choose-property-uses
      :visible.sync="showChoosePropUseModal"
      :propertyUsesArr="propertyUsesArr"
      @close="closePropertyUses"
      :propertyName="energyStarProperty.name"
      @choose-property-use="choosePropertyUse"
      @open-property-uses="openPropertyUsesModal"
    />
    <add-default-property-use
      :visible.sync="showDefaultPropUseModal"
      :propertyUseVal="energyStarProperty.primaryFunction"
      :propertyUsesArr="propertyUsesArr"
      @close="closeDefaultPropertyUse"
      @choose-property-use="choosePropertyUse"
      @open-property-uses="openPropertyUsesModal"
    />
    <create-property-uses
      :visible.sync="showPropertyUse"
      :propertyId="location.energyStarPropertyId"
      :propertyYearBuilt="energyStarProperty.yearBuilt"
      :propertyName="energyStarProperty.name"
      @close="closeCreatePropertyUses"
      @refresh-data="loadEnergyStarDetails"
      :propertyUseSelectedObj="propertyUseSelectedObj"
      :propertyUse="propertyUse"
      :propertyUsesArr="propertyUsesArr"
      :isUpdateMode="isUpdateOfCreateUses"
    />
    <show-remove-modal
      :visible.sync="showRemoveModal"
      :propertyId="location.energyStarPropertyId"
      :propertyName="energyStarProperty.name"
      @close="closeRemoveModal"
      @refresh-page="showLocationNotSet"
    />
    <show-remove-modal
      :visible.sync="showRemovePropertyUsesModal"
      :propertyId="location.energyStarPropertyId"
      :propertyName="propertyUse.name"
      :propertyUseId="propertyUse.id"
      :isRemovePropertyUse="true"
      @close="closeRemovePropertyUses"
      @refresh-page="loadEnergyStarDetails"
    />
  </div>
</template>

<script>
import * as R from 'ramda'
import { energystar } from '@/api'
import { mapState } from 'vuex'
import { isEmpty } from 'lodash'
import propertyUseLabels from '@/assets/propertyUseLabels.json'
import CreateEnergyStarModal from '../../Modals/CreateEnergyStarModal.vue'
import MapPropertyModal from '../../Modals/MapPropertyModal.vue'
import EnergyStarNoScoresReasonsModal from '../../Modals/EnergyStarNoScoresReasonsModal'
import EnergyStarHistoricalScoresModal from '../../Modals/EnergyStarHistoricalScoresModal'
import { DateTime } from 'luxon'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import BaseInput from '@/components/FormComponents/BaseInput'
import MetersModal from '../../Modals/EditMetersModals/MetersModal.vue'
import ShowEnergyStarAutoMetersModal from '../../Modals/EditMetersModals/ShowEnergyStarAutoMetersModal.vue'
import EditMeterModal from '../../Modals/EditMetersModals/EditMeterModal.vue'
import MapVirtualAccModal from '../../Modals/EditMetersModals/MapVirtualAccModal'
import CreatePropertyUses from '../../Modals/EditMetersModals/CreatePropertyUses'
import ChoosePropertyUses from '../../Modals/EditMetersModals/ChoosePropertyUse'
import AddDefaultPropertyUse from '../../Modals/EditMetersModals/AddDefaultPropertyUse'
import PropertyUses from '@/assets/PropertyUseTypes.json'
import ShowRemoveModal from '../../Modals/EditMetersModals/ShowRemoveModal'

export default {
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      crtPropertyUseType: '',
      propertyUseLabels: propertyUseLabels,
      getMetersLoading: false,
      getMetersError: '',
      autoMetersSetupLoading: false,
      showCreateEnergyStarModal: false,
      showMapPropertyModal: false,
      loading: true,
      showESAutoMetersSetupModal: false,
      showMetersModal: false,
      showEditMeters: false,
      meterId: '',
      meterName: '',
      isUpdateMode: false,
      isUpdateOfCreateUses: false,
      showChoosePropUseModal: false,
      showDefaultPropUseModal: false,
      propertyUsesArr: [],
      hasESAccount: false,
      propertyUseSelectedObj: {},
      energyStarMeters: {
        meters: [],
        unassignedVirtualAccounts: [],
      },
      modalTitle: 'Create an ENERGY STAR® Property',
      energyStarProperty: {},
      energyStarPropertyUses: [],
      propertyUse: {},
      energyStarScores: {},
      historicalScores: [],
      showEnergyStarNoScoresReasonsModal: false,
      showEnergyStarHistoricalScores: false,
      showPropertyUse: false,
      showRemovePropertyUsesModal: false,
      noScoreReasons: [],
      loadingScreen: null,
      historicalYear: null,
      showMapToMeterModal: false,
      showRemoveModal: false,
      virtualAcc: {},
      showErrorMessage: '',
    }
  },
  components: {
    CreateEnergyStarModal,
    ShowEnergyStarAutoMetersModal,
    MetersModal,
    EditMeterModal,
    EnergyStarNoScoresReasonsModal,
    EnergyStarHistoricalScoresModal,
    MapPropertyModal,
    BaseSelect,
    BaseInput,
    MapVirtualAccModal,
    CreatePropertyUses,
    ChoosePropertyUses,
    AddDefaultPropertyUse,
    ShowRemoveModal,
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    units: {
      get() {
        return [{ name: 'Square Feet' }, { name: 'Square Meters' }]
      },
    },
    energyStarPropertyAddress() {
      if (this.energyStarProperty.address) {
        return `${this.energyStarProperty.address.address1}
  ${this.energyStarProperty.address.city} ${
  this.energyStarProperty.address.county
    ? `, ${this.energyStarProperty.address.county}`
    : ''
},  ${this.energyStarProperty.address.state},  ${
  this.energyStarProperty.address.postalCode
}
  ${this.energyStarProperty.address.country}`
      }
      return '-'
    },
    grossFloorArea() {
      if (this.energyStarProperty.grossFloorArea) {
        return `${this.energyStarProperty.grossFloorArea.value} ${this.energyStarProperty.grossFloorArea.units}`
      }
      return '-'
    },
    irrigatedArea() {
      if (this.energyStarProperty.irrigatedArea) {
        return `${this.energyStarProperty.irrigatedArea.value} ${this.energyStarProperty.irrigatedArea.units}`
      }
      return '-'
    },
  },
  async mounted() {
    this.propertyUsesArr = JSON.parse(JSON.stringify(PropertyUses))
    this.loading = true
    await this.loadEnergyStarAccount()
    if (this.hasESAccount) {
      await this.loadEnergyStarDetails()
    }
    this.loading = false
  },
  methods: {
    formatDate(date) {
      return DateTime.fromFormat(date, 'yyyy-MM', { zone: 'utc' }).toFormat(
        'MMMM yyyy',
      )
    },
    getErrorReasons(date) {
      alert(date)
    },
    async showLocationNotSet() {
      this.loading = true
      this.isUpdateMode = false
      this.energyStarProperty = {}
      this.$emit('refresh-location')
      this.loading = false
    },
    async loadEnergyStarDetails() {
      if (this.location.energyStarPropertyId) {
        await Promise.all([
          this.loadESProperty(),
          this.loadESPropertyUses(),
          this.loadESScores(),
          this.getMeters(true),
        ])
        if (this.energyStarPropertyUses.length) {
          // turn property uses to array to be able to create table directly from data
          this.energyStarPropertyUses.forEach((propertyU) => {
            let newUseDetails = Object.keys(propertyU.useDetails).map((k) => {
              return {
                ...propertyU.useDetails[k],
                label: propertyUseLabels[k],
                temporary:
                  propertyU.useDetails[k].temporary !== 'false' ? 'Yes' : 'No',
                unitValue:
                  propertyU.useDetails[k].value +
                  (propertyU.useDetails[k].units
                    ? ' ' + propertyU.useDetails[k].units
                    : ''),
                value:
                  propertyU.useDetails[k].value !== ''
                    ? propertyU.useDetails[k].value
                    : '-',
              }
            })
            propertyU.useDetails = newUseDetails
          })
          this.setCrtPropertyUse(this.energyStarPropertyUses[0].id)
        }
      }
    },
    setCrtPropertyUse(id) {
      const propertyUse = this.energyStarPropertyUses.find(
        (propertyUse) => propertyUse.id === id,
      )
      if (propertyUse) {
        this.crtPropertyUseType = propertyUse.name
        this.propertyUse = propertyUse
      }
    },
    async loadEnergyStarAccount() {
      const energyStarAccount = await energystar.getAccount({
        customerId: this.currentCustomerId,
      })
      this.hasESAccount = energyStarAccount.hasOwnProperty('username')
    },
    async loadESProperty() {
      this.energyStarProperty = await energystar.getProperty({
        customerId: this.currentCustomerId,
        propertyId: this.location.energyStarPropertyId,
      })
      this.isUpdateMode = true
      this.modalTitle = 'Update ENERGY STAR® Property'
    },
    async loadESPropertyUses() {
      this.energyStarPropertyUses = await energystar.getPropertyUses({
        customerId: this.currentCustomerId,
        propertyId: this.location.energyStarPropertyId,
      })
    },
    async loadESScores() {
      this.energyStarScores = await energystar.getPropertyScores({
        customerId: this.currentCustomerId,
        propertyId: this.location.energyStarPropertyId,
      })
      if (
        this.energyStarScores.currentScore &&
        this.energyStarScores.currentScore.date
      ) {
        this.historicalYear = this.energyStarScores.currentScore.date.substr(
          0,
          4,
        )
      }
    },
    openEditPropertyUses() {
      this.isUpdateOfCreateUses = true
      this.showPropertyUse = true
    },
    choosePropertyUse(propertyUseSelectedObj) {
      this.propertyUseSelectedObj = propertyUseSelectedObj
    },
    openCreateEnergyStarForm() {
      this.showCreateEnergyStarModal = true
    },
    openRemoveModal() {
      this.showRemoveModal = true
    },
    openMapPropertyModal() {
      this.showMapPropertyModal = true
    },
    openRemovePropertyUses() {
      this.showRemovePropertyUsesModal = true
    },
    openVirtualAcc(id) {
      this.virtualAcc = { id, propertyId: +this.location.energyStarPropertyId }
      this.showMetersModal = false
      this.showMapToMeterModal = true
    },
    openMetersModal() {
      this.showESAutoMetersSetupModal = false
      this.showMetersModal = true
    },
    openAutoMetersModal() {
      this.showMetersModal = false
      this.showESAutoMetersSetupModal = true
    },
    openPropertyUsesModal(propertyUseSelectedObj) {
      this.propertyUseSelectedObj = propertyUseSelectedObj
      this.showDefaultPropUseModal = false
      this.showChoosePropUseModal = false
      this.showPropertyUse = true
    },
    openEditMeters(e) {
      this.meterId = e.meterId
      this.meterName = e.meterName
      this.showMetersModal = false
      this.showEditMeters = true
    },
    editProperty() {
      this.showCreateEnergyStarModal = true
    },
    closeESAuto() {
      this.showErrorMessage = ''
      this.showESAutoMetersSetupModal = false
    },
    closeCreatePropertyUses() {
      this.showPropertyUse = false
      this.isUpdateOfCreateUses = false
    },
    closeMetersModal() {
      this.showMetersModal = false
    },
    closeEditMeterModal(e) {
      this.showEditMeters = false
      this.showMetersModal = true
    },
    closeRemoveModal() {
      this.showRemoveModal = false
    },
    closeRemovePropertyUses() {
      this.showRemovePropertyUsesModal = false
    },
    closeMapVirtualAccModal() {
      this.showMapToMeterModal = false
      this.getMeters(true)
      this.showMetersModal = true
    },
    closeCreateEnergyStarModal(type, result) {
      this['showCreateEnergyStarModal'] = false
      if (type === 'updatedEnergyStar') {
        let newLocation = false
        if (!this.location.energyStarPropertyId) newLocation = true
        this.location.energyStarPropertyId = +result.id
        this.energyStarProperty = result
        this.isUpdateMode = true
        this.modalTitle = 'Update ENERGY STAR® Property'
        this.$forceUpdate()
        this.loadEnergyStarDetails()
        if (newLocation) this.showDefaultPropUseModal = true
        this.loading = false
      }
    },
    closeMapPropertyModal(type, result) {
      this['showMapPropertyModal'] = false
      if (type === 'updatedEnergyStar') {
        this.location.energyStarPropertyId = +result.id
        this.energyStarProperty = result
        this.isUpdateMode = true
        this.modalTitle = 'Update ENERGY STAR® Property'
        this.$forceUpdate()
        this.loadEnergyStarDetails()
        this.loading = false
      }
    },
    closePropertyUses() {
      this.showChoosePropUseModal = false
    },
    closeDefaultPropertyUse() {
      this.showDefaultPropUseModal = false
    },
    async getNoScoreReasons(date) {
      this.loading = true
      this.noScoreReasons = await energystar.getPropertyNoScoresReasons({
        customerId: this.currentCustomerId,
        propertyId: this.location.energyStarPropertyId,
        year: parseInt(date.substr(0, 4)),
        month: parseInt(date.substr(5)),
      })
      this.loading = false
      this.showEnergyStarNoScoresReasonsModal = true
    },
    closeEnergyStarNoScoresReasonsModal() {
      this.showEnergyStarNoScoresReasonsModal = false
      this.noScoreReasons = []
    },
    async getHistoricalScores(year = null) {
      if (year === null) {
        year = this.historicalYear
      }
      this.loading = true
      this.historicalScores = await energystar.getPropertyHistoricalScores({
        customerId: this.currentCustomerId,
        propertyId: this.location.energyStarPropertyId,
        year,
      })
      this.loading = false
      this.showEnergyStarHistoricalScores = true
    },
    closeEnergyStarHistorycalScoresModal() {
      this.showEnergyStarHistoricalScores = false
      this.historicalScores = []
    },
    async unmap(e) {
      const { customerId } = this.$route.params
      try {
        await energystar.unmapMeter({
          customerId,
          propertyId: this.location.energyStarPropertyId,
          meterId: e.meterId,
          virtualAccId: e.id,
        })
        this.getMeters(true)
      } catch (e) {
        this.getMetersError = 'There was an error. Please try again later.'
        this.getMetersLoading = false
        console.log(e)
      }
    },
    async getMeters(forceReload = false) {
      if (this.getMetersLoading) return
      this.getMetersLoading = true
      if (forceReload) {
        const { customerId, locationId } = this.$route.params
        try {
          this.energyStarMeters = await energystar.getMeters({
            customerId,
            propertyId: this.location.energyStarPropertyId,
            locationId,
          })
          this.getMetersError = ''
        } catch (e) {
          this.getMetersError =
            'There are no ENERGY STAR® Meters created. Please create an ENERGY STAR® Meter and associate one or more Pear.ai Virtual Accounts to it.'
          console.log(e)
        }
      }
      this.getMetersLoading = false
    },
    handleShowCorrectModal() {
      if (
        !isEmpty(this.energyStarMeters.unassignedVirtualAccounts) &&
        isEmpty(this.energyStarMeters.meters)
      ) {
        this.openAutoMetersModal()
      } else {
        this.openMetersModal()
      }
    },
    async setupAutoMeters() {
      this.autoMetersSetupLoading = true
      const { customerId, locationId } = this.$route.params
      try {
        await energystar.autoMeters({
          customerId,
          propertyId: this.location.energyStarPropertyId,
          locationId,
        })
        this.getMeters(true)
        this.openMetersModal()
      } catch (e) {
        this.showErrorMessage = e.response.data.error
      }
      this.autoMetersSetupLoading = false
    },
  },
  watch: {
    loading(val) {
      if (val) {
        this.loadingScreen = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)',
        })
      } else {
        if (this.loadingScreen) {
          this.loadingScreen.close()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.energy-star-list-modal {
  ::v-deep .el-form-item {
    margin-bottom: 16px;
  }
  ::v-deep .cell {
    word-break: break-word;
  }
  .energystar-page {
    padding: 0 40px;
    text-align: center;
    el-button.is-round {
      border-radius: 20px;
      padding: 12px 23px;
    }
    &-empty {
      h2 {
        display: inline-block;
        padding: 18px 10px 18px 0;
        margin: 0;
        color: #c3c3c3;
        font-size: 22px;
        line-height: 32px;
      }
    }
    .energystar-form-actions {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      button {
        padding: 8px 32px;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        &.el-button--primary {
          font-weight: 900;
          color: #ffffff;
          background: #0080ff;
          border-color: #0080ff;
        }
      }
    }
  }
  .dock-right {
    float: right;
    margin: 0px 8px;
  }
  .btn-red {
    color: #ff0000;
  }
  .location-attributes {
    padding-right: 20px;
    padding-left: 20px;
    .location-attributes-body {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;

      > div {
        margin-right: 10px;
      }
    }
  }
  .location-information {
    flex: 1 0 0;
    border-right: 2px solid #e4e7ed;
    max-width: calc(100% - 374px);
  }
  h2.score {
    margin: 0;
    color: #222222;
    font-size: 22px;
    line-height: 32px;
  }
  .location-score {
    flex: 0 0 350px;
    height: fit-content;
    text-align: center;
  }
  .month-score {
    justify-content: start;
    width: 100%;
    padding: 0px 10px;
    text-align: left;
    display: inline-flex;
    flex-direction: row;
    align-items: left;
    .label {
      flex: 0 0 130px;
      label {
        font-weight: bold;
      }
    }
    &_right {
      text-align: right;
      padding-right: 20px;
    }
    .value {
      flex: 1 0 0;
      padding: 0px 10px;
      display: inline-block;
    }
  }
  .current-score {
    margin-bottom: 20px;
  }
  ::v-deep .el-table th {
    color: #3c3c3c;
    font-weight: 900;
  }
  .location-info {
    &_item {
      margin-bottom: 40px;
    }
    &_address {
      display: inline-flex;
      white-space: pre-line;
    }
    &_title {
      margin: 0 0 20px;
      display: inline;
      color: #2c2e34;
      font-weight: 900;
    }
    &_field {
      font-weight: 500;
      label {
        display: inline-block;
        width: 240px;
        color: #4a4a4a;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  .select {
    width: 100%;
    padding: 0;
  }
  .no-property-use {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
