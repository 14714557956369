<template lang="pug">
.filter-item.filter-item-utility
  .filter-title
    span(v-if="title") {{ title }}
  .select-box
    select(v-model="utility_type.selected", @change="handleChange")
      option(v-for="comm in commodities",
            :key="comm",
            :value="comm") {{ comm }}
    i.el-select__caret.el-input__icon.el-icon-arrow-down
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: () => {},
      required: true,
    },
    value: {
      type: String,
      default: () => 'ELECTRIC',
      required: true,
    },
    commodities: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      utility_type: {
        selected: this.value,
        type: 'all',
      },
    }
  },
  methods: {
    handleChange({ target: { value } }) {
      this.filter.utility_type = value
    },
  },
}
</script>

<style lang="scss" scoped>
.select-box {
  position: relative;
  width: 100%;

  select {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 36px;
    padding: 0 20px;
    color: #909399;
    font-size: 16px;
    line-height: 36px;
    border-radius: 10px;
    border: 1px solid #dcdfe6;
    background-color: #fff;
    box-sizing: border-box;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    appearance: none;
    outline: none;

    &.show-label {
      opacity: 0;

      &:focus {
        opacity: 1;

        ~ label {
          opacity: 0;
        }
      }
    }
  }
  i {
    position: absolute;
    top: 0;
    right: 5px;
    color: #c0c4cc;
  }
}
</style>
