<template lang="pug">
  .location-control.control
    el-dialog.location-modal(title="Select a locations",
                            custom-class="select-location-dialog",
                            :visible="isVisible",
                            :append-to-body="true",
                            :close-on-click-modal="false",
                            top="40px",
                            width="640px",
                            @close="close")
      el-tabs(v-model='activeTab')
        el-tab-pane(label='Locations', name='locations', data-action='Show locations')
          locations-control(:locations="locations", :stored-locations="selectedLocations", @select-locations="selectLocations")
        el-tab-pane(v-if="hasHierarchy", label='Hierarchies', name='hierarchies', data-action='Show hierarchies')
          hierarchies-control(:hierarchy="hierarchy", :stored-locations="selectedLocations", @select-locations="selectLocations", :locations="locations")
      .location-control_actions
        el-button(type="primary", size="small", round, @click="changeFilter") Submit
</template>

<script>
import { mapGetters } from 'vuex'
import LocationsControl from './LocationsControl.vue'
import HierarchiesControl from './HierarchiesControl.vue'
import { isEmpty } from 'ramda'

export default {
  components: {
    LocationsControl,
    HierarchiesControl,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    selectedLocations: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  updated() {
    this.selected = this.selectedLocations
  },
  data() {
    return {
      activeTab: 'locations',
      selected: this.selectedLocations,
    }
  },
  computed: {
    ...mapGetters({
      locations: 'locations/locations',
      hierarchy: 'locations/hierarchy',
    }),
    hasHierarchy() {
      return !isEmpty(this.hierarchy)
    },
  },
  watch: {
    selectedLocations(val) {
      this.selected = val
    },
  },
  methods: {
    selectLocations(locations) {
      this.selected = locations
      this.$emit('onChange', this.selected)
    },
    changeFilter() {
      this.$emit('onChange', this.selected)
      this.$emit('close')
      this.selected = []
    },
    close() {
      this.$emit('close')
      this.selected = []
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../../styles/_utilities';

.location-control_actions {
  padding-top: 20px;
  text-align: center;

  .el-button {
    width: 160px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 900;
  }
}
</style>

<style lang="scss">
@import './../../../../styles/_utilities';

.location-modal {
  .el-dialog__header {
    padding: 40px 40px 0 40px;

    @include respond-to($phone) {
      padding: 30px 30px 0 30px;
    }
  }
  .el-dialog__title {
    color: #273a58;
    font-size: 24px;
    font-weight: 700;

    @include respond-to($phone) {
      font-size: 28px;
    }
  }
  .el-dialog__headerbtn {
    top: 42px;
    right: 38px;

    @include respond-to($phone) {
      top: 20px;
      right: 25px;
    }
  }
  .el-dialog__body {
    padding: 20px 15px;

    @include respond-to($phone) {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  @include respond-to($phone) {
    .el-dialog {
      width: 100% !important;
      height: 100vh;
      margin: 0 !important;
      border-radius: 0;
      overflow: hidden;
    }
  }

  .el-tabs__header {
    padding: 0 25px;
    margin-bottom: 20px;

    @include respond-to($phone) {
      margin: 0 20px 20px;
    }
  }
  .el-tabs__item {
    font-weight: 900;
  }
}
</style>
