<template lang="pug">
.intro
  .intro-step-container
    el-carousel(:autoplay='false',
                ref='carousel',
                :height='carouselHeight',
                indicator-position='none',
                arrow='never',
                trigger='click',
                @change='getActiveItem')
      el-carousel-item
        section.intro-step(v-hammer:swipe='onSwipe')
          .intro-content
            .intro-icon.first
              i.icon.icon-lamp.desktop
              i.icon.icon-lamp-m.tablet
            h2.intro-title Gain control over your energy profile with Pear.ai technology
            p.intro-text Pear.ai performs deep analysis on your energy data to identify anomalies, generate insights, and guide you to problem areas.
          el-button.button-next(type='text', @click='goToStep(1)')
            i.icon.icon-back
      el-carousel-item
        section.intro-step(v-hammer:swipe='onSwipe')
          .intro-content
            .intro-icon.second
              i.icon.icon-invoice.desktop
              i.icon.icon-invoice-m.tablet
            h2.intro-title Stay informed with proactive alerts that empower your business
            p.intro-text Sam alerts you when it finds issues and corrects them, or gives you the information you need to follow up.
          el-button.button-next(type='text', @click='goToStep(2)')
            i.icon.icon-back
      el-carousel-item
        section.intro-step(v-hammer:swipe='onSwipe')
          .intro-content
            .intro-icon.third
              i.icon.icon-sam-bot.desktop
              i.icon.icon-sam-bot-m.tablet
            h2.intro-title Talk to your data with Sam, your energy assistant
            p.intro-text Ask for the information you need instead of waiting for days to talk to account managers.
          el-button.button-next(type='text', @click='skipIntro')
            i.icon.icon-back
      .intro-navigation
        .intro-bullets
          span.bullet(:class='{ "is-active": activeItem === 0 }', @click='goToStep(0)')
          span.bullet(:class='{ "is-active": activeItem === 1 }', @click='goToStep(1)')
          span.bullet(:class='{ "is-active": activeItem === 2 }', @click='goToStep(2)')

        el-button.button-skip(:class='{ "is-last": activeItem === 2 }', type='text', @click='skipIntro')
          template(v-if='activeItem === 2')
            | Got it! Let’s go
            i.icon.icon-forward
          template(v-else)
            | Skip Intro

</template>

<script>
const DIRECTION_LEFT = 2
const DIRECTION_RIGHT = 4

export default {
  data() {
    return {
      activeItem: 0,
    }
  },
  computed: {
    carouselHeight() {
      return this.$deviceInfo.isMobileDevice
        ? window.innerHeight + 'px'
        : '100vh'
    },
  },
  methods: {
    goToStep(item) {
      this.$refs.carousel.setActiveItem(item)
    },
    getActiveItem(item) {
      this.activeItem = item
    },
    skipIntro() {
      this.$router.push({ path: 'settings' })
    },
    onSwipe(e) {
      if (e.direction === DIRECTION_RIGHT && this.activeItem > 0) {
        this.$refs.carousel.setActiveItem(--this.activeItem)
      }
      if (e.direction === DIRECTION_LEFT && this.activeItem < 2) {
        this.$refs.carousel.setActiveItem(++this.activeItem)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/utilities';

.intro {
  background: url('../../assets/logo.svg') center 10px / 147px 54px no-repeat;

  @include respond-to($phone) {
    background-position: 40px 30px;
    background-size: 87px 34px;
  }

  &-step {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &-content {
    padding-left: 57%;
    padding-right: 170px;

    @include respond-to($tablet-l) {
      padding-left: 50%;
      padding-right: 100px;
    }
    @include respond-to($tablet-p) {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: flex-start;
      height: 100%;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 80px;
    }
  }
  &-icon {
    position: absolute;
    left: -68px;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 709px;
    width: 709px;
    border-radius: 50%;
    box-sizing: border-box;
    z-index: -1;
    transform: translateY(-50%);

    @include respond-to($tablet-l) {
      width: 509px;
      height: 509px;
    }
    @include respond-to($tablet-p) {
      position: static;
      flex: none;
      display: flex;
      align-items: flex-end;
      width: auto;
      height: 200px;
      padding-bottom: 20px;
      margin: 0;
      background-image: none !important;
      transform: none;
      margin-bottom: 30px;
    }
    .icon {
      margin: 0;
    }
    &.first {
      background-image: linear-gradient(279.67deg, #ffe031 0%, #dbb917 100%);
    }
    &.second {
      background-image: linear-gradient(279.67deg, #80c400 0%, #6ca600 100%);
    }
    &.third {
      background-image: linear-gradient(279.67deg, #008040 0%, #026d37 100%);
    }
  }
  &-title {
    margin: 0 0 40px;
    color: #333333;
    font-weight: 900;
    font-size: 36px;
    line-height: 45px;

    @include respond-to($phone) {
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 30px;
    }
  }
  &-text {
    margin: 0;
    color: #909399;
    font-size: 24px;
    line-height: 33px;
    font-weight: 500;

    @include respond-to($phone) {
      font-size: 18px;
      line-height: 25px;
      font-weight: 900;
    }
  }
  &-navigation {
    position: absolute;
    left: 57%;
    /*bottom: 190px;*/
    z-index: 10;
    top: 80%;

    @media screen and (max-height: 700px) and (max-width: 599.98px) {
      top: 88%;
    }

    @include respond-to($tablet-l) {
      left: 50%;
    }
    @include respond-to($tablet-p) {
      transform: translateX(-50%);
    }
    @include respond-to($phone) {
      bottom: 10px;
    }
    @include respond-to('(min-width: 600px) and (max-height: 880px)') {
      bottom: 50px;
    }
    @include respond-to('(min-width: 600px) and (max-height: 600px)') {
      bottom: 20px;
    }
  }
  &-bullets {
    /*margin-top: 110px;*/
    margin-bottom: 20px;

    @include respond-to($tablet-p) {
      margin-top: auto;
      margin-bottom: 5px;
      text-align: center;
    }
  }
}
.bullet {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #dcdfe6;
  transition: background-color 0.3s;
  cursor: pointer;

  + .bullet {
    margin-left: 15px;
  }
  &:hover,
  &.is-active {
    background-color: #0080ff;
  }
}
.button-next {
  position: absolute;
  top: 0;
  right: 0;
  width: 170px;
  height: 100vh;
  padding: 0 40px 0 0;
  text-align: right;

  @include respond-to($tablet-l) {
    width: 100px;
  }
  @include respond-to($tablet-p) {
    display: none;
  }

  .icon {
    margin-right: 0;
    transform: rotate(180deg);

    &::before {
      width: 24px;
      height: 17px;
      background-size: 24px 17px;
    }
  }
}
.button-skip {
  margin: 0;
  color: #626262;
  font-size: 24px;
  font-weight: 500;

  @include respond-to($phone) {
    font-size: 16px;
  }

  &.is-last {
    color: #0080ff;
    font-weight: 800;

    .icon {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}
.desktop {
  @include respond-to($tablet-p) {
    display: none;
  }
}
.tablet {
  display: none;

  @include respond-to($tablet-p) {
    display: block;
  }
}
</style>
