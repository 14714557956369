<template lang="pug">
  .feeds-page
    //- [TEMP] Remove after bill history will be moved to Locations tabs
    .filter
      location-filter-monthly-view-mobile-container(v-if='showMobileFilter',
        :utility_type="utility_type"
        @close='closeMobileFilter',
        @change="changeUtilityType")
      el-button.toggle-search(type='text', @click='toggleMobileFilter')
        i.icon.icon-filters-m(v-if='!showMobileFilter')
        i.el-icon-close(v-else)
      el-button.toggle-calendar(type='text', @click='toggleMobileCalendar')
        el-date-picker(v-model="month",
          ref="datePicker",
          format="MMMM yyyy",
          type="month",
          size="mini",
          :clearable="false",
          :pickerOptions="pickerOptions",
          @change="changeDate",
          align="right",
          placeholder="All months")
      .filter-container
    .feeds-list-container(v-loading="isFetching")
      .feeds-list(v-if="list.length")
        .feeds-list_item(v-for="feed in list", :key="String(feed.timePeriod)")
          .feeds-list_item-header
            h2(:id="feed.title")  {{ feed.title }}
            .total-charges(v-if="!feed.type")
              span Total Cost:&nbsp;
              b {{ feed.total | unit('USD') }}
          .feeds-list_item-body(v-if="!feed.type")
            bill-block-mobile(:block="feed.data", :key="feed.title")
          .feeds-list_empty-gap(v-else)
            span No bill history for this time period.
      .feeds-list-empty(v-else)
        h2 No bill history for this time period.
</template>

<script>
import _ from 'lodash'
import * as R from 'ramda'
import { DateTime } from 'luxon'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import ProcessingDate from '@/components/ProcessingDate.vue'
import FilterSelect from '@/components/Filters/FilterSelect.vue'
import BillBlockMobile from './BillBlockMobile.vue'
import FeedDetailsModal from './FeedDetailsModal.vue'
import LocationFilterMonthlyViewMobileContainer from '../../../Locations/Filters/FilterMonthlyViewMobileContainer'

export default {
  components: {
    ProcessingDate,
    FilterSelect,
    BillBlockMobile,
    FeedDetailsModal,
    LocationFilterMonthlyViewMobileContainer,
  },
  data() {
    const self = this
    return {
      paymentsData: [],
      bills: [],
      dateLabel: '',
      showFeedDetailsModal: false,
      loading: true,
      utility_type: 'All Utilities',
      showMobileFilter: false,
      showMobileCalendar: false,
      month: '',
      scrollHandler: null,
      pickerOptions: {
        disabledDate(time) {
          const dateString = `${time.getFullYear()}-${`0${
            time.getMonth() + 1
          }`.slice(-2)}`
          return !self.dates.find((item) => item === dateString)
        },
      },
    }
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      list: 'feeds/list',
      isFetching: 'feeds/isFetching',
      dates: 'feeds/dates',
      params: 'feeds/params',
    }),
    utility_types() {
      return [
        'All Utilities',
        ...this.$store.getters['resources/getResourceByName'](
          'commodities',
        ).value.map((type) => type.title),
      ]
    },
  },
  async mounted() {
    await this.$getDatesList({
      customerId: this.currentCustomerId,
      locationId: this.$route.params.locationId,
    })
    if (this.dates.length) {
      await this.$setParams({
        ...this.params,
        startDate: DateTime.fromFormat(_.last(this.dates), 'yyyy-LL', {
          zone: 'utc',
        })
          .endOf('month')
          .toFormat('yyyy-LL-dd')
          .toString(),
      })
    }
    this.scrollHandler = _.throttle(this.onWindowScroll, 500)
    window.addEventListener('scroll', this.scrollHandler)
    await this.$fetchFeeds({
      customerId: this.currentCustomerId,
      locationId: this.$route.params.locationId,
    })
  },
  beforeDestroy() {
    this.$resetState()
    window.removeEventListener('scroll', this.scrollHandler)
  },
  methods: {
    ...mapActions({
      $fetchFeeds: 'feeds/fetchFeeds',
      $setParams: 'feeds/setParams',
      $appendFeeds: 'feeds/appendFeeds',
      $getDatesList: 'feeds/getDatesList',
    }),
    ...mapMutations({
      $resetState: 'feeds/resetState',
    }),
    toggleMobileFilter() {
      this.showMobileFilter = !this.showMobileFilter
    },
    toggleMobileCalendar() {
      this.$refs['datePicker'].focus()
      this.showMobileCalendar = !this.showMobileCalendar
    },
    closeMobileFilter() {
      this.showMobileFilter = false
    },
    async onWindowScroll(e) {
      if (!this.list.length || this.isFetching) return
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        const lastMonthData = _.get(_.last(this.list), 'data', [])
        const lastDate = _.get(
          _.last(_.last(lastMonthData)),
          'time_period',
          null,
        )
        if (
          DateTime.fromISO(lastDate, { zone: 'utc' })
            .toFormat('yyyy-LL')
            .toString() !== _.first(this.dates)
        ) {
          await this.$setParams({
            direction: '-',
            ...(lastDate && {
              startDate: DateTime.fromISO(lastDate, { zone: 'utc' })
                .toFormat('yyyy-LL-dd')
                .toString(),
            }),
            period: 12,
          })
          await this.$appendFeeds({
            customerId: this.currentCustomerId,
            locationId: this.$route.params.locationId,
          })
        }
        return
      }

      if (window.scrollY <= 0) {
        const lastMonthData = _.get(_.first(this.list), 'data', [])
        const lastDate = _.get(
          _.last(_.last(lastMonthData)),
          'time_period',
          null,
        )
        const initHeight = document.body.offsetHeight
        if (
          DateTime.fromISO(lastDate, { zone: 'utc' })
            .toFormat('yyyy-LL')
            .toString() !== _.last(this.dates)
        ) {
          await this.$setParams({
            direction: '+',
            ...(lastDate && {
              startDate: DateTime.fromISO(lastDate, { zone: 'utc' })
                .toFormat('yyyy-LL-dd')
                .toString(),
            }),
            period: 12,
          })
          await this.$appendFeeds({
            customerId: this.currentCustomerId,
            locationId: this.$route.params.locationId,
          })
        }
        this.$nextTick(() =>
          window.scroll(0, document.body.offsetHeight - initHeight + 1),
        )
      }
    },
    openFeedDetailsModal() {
      this.showFeedDetailsModal = true
    },
    closeFeedDetailsModal() {
      this.showFeedDetailsModal = false
    },
    async changeDate(time) {
      let params = null
      const id = DateTime.fromMillis(+time)
        .toFormat('MMMM yyyy')
        .toString()
      const element = document.getElementById(id)
      if (element) {
        this.$nextTick(() => element.scrollIntoView())
        this.$nextTick(() => window.scroll(0, window.scrollY - 60))
        return
      }

      if (time) {
        params = {
          direction: '-',
          startDate: DateTime.fromMillis(+time)
            .toFormat('yyyy-LL-dd')
            .toString(),
          period: 12,
        }
      } else {
        params = {
          direction: '-',
          startDate: DateTime.fromFormat(_.last(this.dates), 'yyyy-LL', {
            zone: 'utc',
          })
            .endOf('month')
            .toFormat('yyyy-LL-dd')
            .toString(),
          period: 12,
        }
      }

      await this.$setParams(params)
      await this.$fetchFeeds({
        customerId: this.currentCustomerId,
        locationId: this.$route.params.locationId,
      })
      window.scroll(0, 1)
    },
    async changeUtilityType(utility_type) {
      this.utility_type = utility_type
      await this.$setParams({
        utility_type: utility_type !== 'All Utilities' ? utility_type : null,
      })
      await this.$fetchFeeds({
        customerId: this.currentCustomerId,
        locationId: this.$route.params.locationId,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/* [TEMP] Remove after bill history will be moved to Locations tabs */
.feeds-page {
  padding: 0 10px;

  ::v-deep .el-button--primary {
    background: #0080ff;
    border-color: #0080ff;
  }

  ::v-deep {
    .toggle-search {
      position: fixed;
      top: 5px;
      right: 15px;
      z-index: 99;

      i {
        margin-right: 0;
        color: #7d89a6;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .toggle-calendar {
      position: fixed;
      bottom: 20px;
      right: 15px;
      display: flex;
      justify-content: center;
      align-content: center;
      width: 56px;
      height: 56px;
      background-color: #0080ff;
      border-radius: 50%;
      z-index: 10;

      .el-input.el-date-editor {
        width: 0 !important;
        height: 26px;
        line-height: 26px;

        input {
          display: none;
        }

        .el-input__prefix {
          left: -13px;
        }
      }

      .el-icon-date {
        margin: 0;
        padding: 0;
        font-size: 26px;
        line-height: 26px;
        color: #fff;
        background-color: #0080ff;

        &:before {
          content: '\E608';
          width: 26px;
          height: 26px;
        }
      }
    }
  }

  ::v-deep .filter-m .filter-container {
    padding-top: 44px;
    top: 0;
    z-index: 99;
  }

  .feeds-list_item:not(:last-child)::after {
    display: none !important;
  }

  .content-inner {
    padding-right: 40px;
    padding-left: 40px;
  }

  .filter {
    box-sizing: border-box;
    margin: 15px -15px;

    .filter-container {
      flex-wrap: wrap;
      padding: 15px;
    }

    &-item {
      &:first-child {
        margin-right: auto;
      }
    }

    ::v-deep .el-input {
      width: 160px !important;
    }
  }

  .feeds-list {
    .feeds-list_empty-gap {
      text-align: center;
      color: #646464;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 42px;
    }

    &-container {
      min-height: 50vh;
    }

    &_item {
      &:not(:last-child) {
        margin-bottom: 42px;

        &::after {
          display: block;
          margin: 0 -40px;
          content: '';
          border-bottom: 1px solid #dcdcdc;
        }
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 5px;
        margin-bottom: 15px;

        h2 {
          margin: 0;
          color: #222222;
          font-size: 22px;
          line-height: 32px;
        }
      }
    }

    &-empty {
      h2 {
        display: inline-block;
        padding: 18px 10px 18px 0;
        margin: 0;
        color: #c3c3c3;
        font-size: 22px;
        line-height: 32px;
        border-bottom: 1px solid #dfdfdf;
      }
    }
  }

  .total-charges {
    font-size: 16px;
    font-weight: 700;
    color: #606266;

    b {
      font-size: 18px;
      font-weight: 900;
    }
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      min-width: 140px;
    }
  }

  .processing-date {
    margin-right: 0;
  }
}
</style>
