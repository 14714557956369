import { scheduledReports } from '@/api'
import * as _ from 'lodash'
import { Message } from 'element-ui'

const initialState = () => {
  return {
    scheduledReports: {},
    exclude: [],
    filters: {
      formats: null,
      types: null,
    },
    pagination: {
      limit: 10,
      offset: 0,
      page_sizes: [10, 20, 30, 40, 100],
    },
    loading: true,
  }
}

export default {
  namespaced: true,
  state: initialState(),

  getters: {
    scheduledReports: (state) =>
      state.scheduledReports && state.scheduledReports.results,
    filters: (state) =>
      _.get(state, 'scheduledReports.filters', null) || state.filters,
    totalResults: (state) =>
      state.scheduledReports && state.scheduledReports.total,
    pagination: (state) => state.pagination,
    currentPage: (state) =>
      state.pagination.offset / state.pagination.limit + 1,
    loading: (state) => state.loading,
  },

  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    },
    setReports(state, payload) {
      state.scheduledReports = payload
      if (state.exclude.length === 0) {
        state.filters.formats = _.get(
          state,
          'scheduledReports.filters.formats',
          null,
        )
        state.filters.types = _.get(
          state,
          'scheduledReports.filters.types',
          null,
        )
        state.exclude = ['filters', 'extra']
      }
    },
    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    reset({ commit }) {
      commit('resetState')
    },
    async getScheduledReports({ commit, state }, { customerId, params = {} }) {
      commit('setLoading', true)
      try {
        params.exclude = state.exclude
        const scheduledReportsList = await scheduledReports.getScheduledReports(
          customerId,
          params,
        )
        commit('setReports', scheduledReportsList)
        commit('setLoading', false)
      } catch (e) {
        console.log(e)
        commit('setLoading', false)
      }
    },
    async updateScheduledReports({ dispatch, commit }, { customerId, body }) {
      const putBody = {
        ...body,
        formBody: undefined,
        customReportName: body.title,
        saveBody: body.format,
      }
      try {
        await scheduledReports.updateScheduledReports(
          customerId,
          body.id,
          putBody,
        )
        commit('setLoading', true)
        dispatch('getScheduledReports', { customerId })
        Message({
          message: `${body.customReportName} has been successfully updated.`,
          type: 'success',
        })
      } catch (e) {
        console.log(e)
        commit('setLoading', false)
        Message({
          message: `Error while trying to update ${body.customReportName}. Please try again later.`,
          type: 'error',
        })
      }
    },

    async runNowScheduledReports(
      { dispatch, commit },
      { customerId, scheduledReportId, reportName },
    ) {
      try {
        await scheduledReports.runNowScheduledReports(
          customerId,
          scheduledReportId,
        )
        commit('setLoading', true)
        dispatch('getScheduledReports', { customerId })
        Message({
          message: `${reportName} has been successfully ran.`,
          type: 'success',
        })
      } catch (e) {
        console.log(e)
        commit('setLoading', false)
        Message({
          message: `Error while trying to run ${reportName}. Please try again later.`,
          type: 'error',
        })
      }
    },

    async deleteScheduledReports(
      { dispatch, commit },
      { customerId, scheduledReportId, reportName, limit, offset },
    ) {
      try {
        await scheduledReports.deleteScheduledReports(
          customerId,
          scheduledReportId,
        )
        commit('setLoading', true)
        const params = { limit, offset }
        dispatch('getScheduledReports', { customerId, params })
        Message({
          message: `${reportName} has been successfully deleted.`,
          type: 'success',
        })
      } catch (e) {
        console.log(e)
        commit('setLoading', false)
        Message({
          message: `Error while trying to delete ${reportName}. Please try again later.`,
          type: 'error',
        })
      }
    },
  },
}
