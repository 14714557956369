<template>
  <div class="location-attributes">
    <div class="location-attributes-body">
      <div class="location-information">
        <section class="location-info_item">
          <h3 class="location-info_title">Main information</h3>
          <div class="location-info_field">
            <label>Description</label>
            <span>{{ location.name || '-' }}</span>
          </div>
          <div class="location-info_field">
            <label>Address</label>
            <span>{{ location.address || '-' }}</span>
          </div>
          <div class="location-info_field">
            <label>Postcode</label>
            <span>{{ location.postcode || '-' }}</span>
          </div>
          <div class="location-info_field type">
            <label>Type</label>
            <span>{{ location.buildingType || '-' }}</span>
          </div>
          <div class="location-info_field">
            <label>Area</label>
            <span>
              {{ location.squareFeet ? `${location.squareFeet} Sq. Ft.` : '-' }}
            </span>
          </div>
        </section>
        <section class="location-info_item">
          <h3 class="location-info_title">Additional Information</h3>
          <div class="location-info_field">
            <label>Site number</label>
            <span v-if="location.payload">
              {{ location.payload.siteNumber || '-' }}
            </span>
            <span v-else>-</span>
          </div>
          <div class="location-info_field">
            <label>City</label>
            <span>{{ location.city || '-' }}</span>
          </div>
          <div class="location-info_field">
            <label>State</label>
            <span>{{ removeCountry(location.state) }}</span>
          </div>
          <div class="location-info_field">
            <label>Country</label>
            <span>{{ location.country || '-' }}</span>
          </div>
          <div class="location-info_field">
            <label>Division</label>
            <span v-if="location.payload">
              {{ location.payload.siteDivision || '-' }}
            </span>
            <span v-else>-</span>
          </div>
          <div class="location-info_field">
            <label>Operating status</label>
            <span v-if="location.payload">
              {{ location.payload.siteStatus || '-' }}
            </span>
            <span v-else>-</span>
          </div>
          <div class="location-info_field">
            <label>Phone</label>
            <span v-if="location.payload">
              {{ location.payload.site_phone || '-' }}
            </span>
            <span v-else>-</span>
          </div>
          <div class="location-info_field">
            <label>Fax</label>
            <span v-if="location.payload">
              {{ location.payload.siteFax || '-' }}
            </span>
            <span v-else>-</span>
          </div>
        </section>
      </div>
      <div class="location-information" v-if="customAttributes.length">
        <div class="location-attributes-body">
          <div class="location-information">
            <section class="location-info_item">
              <h3 class="location-info_title">Custom Attributes</h3>
              <el-row
                style="margin-bottom: 10px"
                v-for="{ id, attributeName, attributeUom } in customAttributes"
                :key="id"
              >
                <el-col :span="12">{{ attributeName }}</el-col>
                <el-col :span="12" v-if="getMetadataValue(id)">
                  {{
                    getMetadataValue(id) &&
                    `${getMetadataValue(id)} ${
                      attributeUom ? attributeUom : ''
                    }`
                  }}
                </el-col>
                <el-col :span="12" v-else>-</el-col>
              </el-row>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as R from 'ramda'
import { mapState } from 'vuex'
export default {
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
  },
  data() {
    return {
      customAttributes: [],
    }
  },
  async mounted() {
    await this.loadMetadataList()
  },
  methods: {
    async loadMetadataList() {
      const data = _.get(this.location, 'metadata', [])
      data.sort((a, b) => a.sortOrder - b.sortOrder)
      this.customAttributes = data
    },
    getMetadataValue(id) {
      const metadata = _.get(this.location, 'payload', {})
      const key = Object.keys(metadata).find((key) => +key === id)
      return key ? metadata[key] : ''
    },
    removeCountry(state) {
      return state ? state.replace(/^.*-/g, '') : '-'
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../../styles/_utilities';

.location-attributes {
  padding-right: 20px;
  padding-left: 20px;

  .location-attributes-body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    > div {
      margin-right: 80px;

      @include respond-to($phone) {
        margin-right: 0;
      }
    }
    @include respond-to($desktop-md) {
      flex-wrap: wrap;
    }
  }
}

.location-info {
  width: 50%;
  &_meta {
    display: inline-flex;
  }
  &_item {
    width: 500px;
    margin-bottom: 40px;
  }
  &_title {
    margin: 0 0 20px;
    color: #2c2e34;
    font-weight: 900;

    @include respond-to($phone) {
      font-size: 16px;
    }
  }
  &_field {
    font-weight: 500;

    label {
      display: inline-block;
      width: 240px;
      color: #4a4a4a;

      @include respond-to($phone) {
        width: 160px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
</style>
