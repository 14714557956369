<template>
  <div>
    <div class="select-wrapper">
      <span>Go to:</span>
      <el-select @change="selectChange" v-model="stateModel" filterable>
        <el-option
          v-for="err in billErrors"
          :key="err.workflowState"
          :label="err.workflowState"
          :value="err.workflowState"
        />
      </el-select>
    </div>
    <div
      class="errors-container-wrapper error-bar-content"
      ref="container"
      :key="componentKey"
    >
      <div
        class="errors-container"
        v-bind:key="category.id"
        v-for="category in billErrorsSorted"
        :ref="formatRef(category.workflowState)"
      >
        <div class="errors-item">
          <h3>{{ category.workflowState }}</h3>
          <div v-bind:key="errorItem.id" v-for="errorItem in category.errors">
            <div
              class="error-entity-container"
              data-testid="unresolvableErrorContainer"
            >
              <span class="checkbox-placeholder" />
              <span class="text" data-testid="errorMessage">
                {{ errorItem.message }}
              </span>
              <span class="code" data-testid="errorCode">
                {{ errorItem.validationCheckId }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import * as R from 'ramda'
import BaseSelect from '@/components/FormComponents/BaseSelect'

const workflowStateOrder = [
  'Integrity Check',
  'Data Verification I',
  'Data Verification II',
  'Data Audit I',
  'Data Audit II',
  'Processed',
]
const orderNum = (workflowState) =>
  workflowStateOrder.findIndex(
    (s) => s === R.prop('workflowState', workflowState),
  )

export default {
  name: 'BillSideErrors',
  components: { BaseSelect },
  props: {
    workflowState: {
      type: String,
      default: () => '',
    },
    navigation: {
      type: Object,
      default: () => ({}),
    },
    billId: {
      type: [String, Number],
      default: () => '',
    },
    billValidations: {
      type: Array,
      default: () => [],
    },
    workflows: {
      type: Array,
      default: () => [],
    },
    errorsList: {
      type: Array,
      default: () => [],
    },
    resolvedErrors: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      history: [],
      stateModel: '',
      checkedErrorItems: [],
      resolve: [],
      isParserV1: false,
      checkAll: false,
      isIndeterminate: false,
      bulkActionsQueue: [],
      keepLoading: false,
      releaseLoading: false,
      componentKey: 0,
      inputLimit: 300,
      clonedCheckedErrorItems: [],
      comment: '',
    }
  },
  watch: {
    billId() {
      this.updateWFState()
      this.history = []
    },
    workflowStates() {
      this.updateWFState()
    },
    comment(val) {
      if (val.length > this.inputLimit) {
        this.comment = R.slice(0, 300, this.comment)
      }
    },
  },
  async mounted() {
    if (this.errorsList.length && this.billValidations.length) {
      this.clonedResolvedItems = _.cloneDeep(
        this.errorsList.filter(
          (errorItem) =>
            errorItem && this.isResolvable(errorItem.validationCheckId),
        ),
      )
      this.clonedCheckedErrorItems = _.cloneDeep(this.checkedErrorItems)
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
    billErrors() {
      if (!this.billValidations || !this.errorsList || !this.workflows) {
        return []
      }
      const tmp = {}
      this.errorsList.forEach((e) => {
        const validation =
          this.billValidations.find((i) => +i.id === +e.validationCheckId) || {}
        if (!validation) return
        const state = `${
          (
            this.workflows.find((i) => i.value === validation.workflowState) ||
            {}
          ).name || 'Deprecated'
        }`
        if (!tmp[state]) {
          tmp[state] = []
        }

        tmp[state].push(e)
      })
      const list = Object.keys(tmp).map((workflowState) => ({
        workflowState,
        errors: tmp[workflowState],
      }))
      return R.sortBy(orderNum)(list)
    },
    billErrorsSorted() {
      return R.sortBy(orderNum)(this.billErrors)
    },
    errorStatus() {
      if (!this.billValidations) {
        return { type: '', count: 0 }
      }

      const getCount = (states = []) =>
        _.flatten(
          this.billErrors
            .filter((e) => states.includes(e.workflowState))
            .map(({ errors }) => errors.filter((i) => !i.resolved)),
        ).length
      const state = (
        this.billValidations.find(
          (i) => i.workflowState === this.workflowState,
        ) || {}
      ).name
      const count = (
        (this.billErrors.find((e) => e.workflowState === state) || {}).errors ||
        []
      ).filter((i) => !i.resolved).length
      if (
        [
          'integrity_check',
          'data_verification_1',
          'data_verification_2',
        ].includes(this.workflowState)
      ) {
        return {
          type: getCount([
            'Integrity Check',
            'Data Verification I',
            'Data Verification II',
          ])
            ? 'critical'
            : '',
          count: getCount([
            'Integrity Check',
            'Data Verification I',
            'Data Verification II',
          ]),
        }
      } else if (
        ['data_audit_1', 'data_audit_2'].includes(this.workflowState)
      ) {
        return {
          type: getCount(['Data Audit I', 'Data Audit II']) ? 'audit' : '',
          count: getCount(['Data Audit I', 'Data Audit II']),
        }
      } else if (['processed'].includes(this.workflowState)) {
        return { type: 'processed', count }
      } else {
        return { type: '', count }
      }
    },
  },
  methods: {
    updateWFState() {
      if (!this.billValidations) {
        this.stateModel = ''
        return
      }
      const states = this.billErrors.map(({ workflowState }) => workflowState)
      const state = (
        this.billValidations.find(
          (i) => i.workflowState === this.workflowState,
        ) || {}
      ).name
      if (!states.includes(state)) {
        this.stateModel = ''
        return
      }

      this.stateModel = state
    },
    formatRef(val = '') {
      return val.toLowerCase().replace(/ /g, '_')
    },
    selectChange(val) {
      const [ref] = this.$refs[this.formatRef(val)]

      setTimeout(
        () => this.$refs.container.scrollTo(0, ref.offsetTop - 120),
        300,
      )
    },
    isResolvable(code) {
      return (
        this.billValidations &&
        this.billValidations.find((e) => +e.id === +code && e.resolvable)
      )
    },
  },
}
</script>

<style lang="scss">
@import '../../../../styles/utilities';

.error-bar-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .check-all-container {
    border-top: 1px solid #d8dce5;
    margin: 0 28px 10px 28px;
    .check-all {
      font-weight: 600;
      margin-top: 10px;
      color: #606266;
      font-size: 12px;
    }
  }
  .error-bar-content {
    flex-grow: 2;
    overflow-y: scroll;
    height: 100vh;
    @extend %scrollbar;
  }
  .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    h2 {
      color: #222222;
      font-size: 18px;
      line-height: 24px;
      margin: 20px 20px 20px 57px;
    }

    span {
      font-size: 16px;
      line-height: normal;
      font-weight: 700;

      &.critical {
        color: #f56c6c;
      }

      &.processed {
        color: #67c23a;
        font-size: 20px;
      }

      &.audit {
        color: #f3a745;
      }
    }
  }

  .select-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 16px;

    > span {
      margin-right: 20px;
    }

    .el-select {
      input {
        height: 29px;
        line-height: normal;
      }

      i {
        line-height: normal;
      }
    }
  }

  .errors-container {
    .errors-item {
      margin-top: 20px;
      h3 {
        color: #222222;
        font-size: 16px;
        line-height: 22px;
        margin-left: 16px;
      }
      .error-entity-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        min-height: 20px;
        &.checked {
          cursor: pointer;
          span {
            color: #c1c4cc;
            font-weight: 400 !important;
          }

          .el-icon-check {
            display: flex;
          }

          .el-checkbox {
            display: none;
          }

          &:hover {
            span {
              color: #4a90e2;
            }
            .el-checkbox {
              display: flex;
            }
            .el-icon-check {
              display: none;
            }
          }
        }

        .el-icon-check {
          display: none;
          margin: 0 10px 0 0;
          font-size: 12px;
          font-weight: 700;
          color: #4a90e2;
        }

        .el-checkbox {
          margin: 0 8px 0 0;
          max-height: 20px;
          .el-checkbox__input {
            line-height: normal;
            vertical-align: baseline;
          }
        }

        .checkbox-placeholder {
          flex: 0 0 12px;
          margin: 0 8px 0 0;
          font-weight: 700;
          color: #f86259;
        }

        > span {
          color: #606266;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 14px;
          text-align: left;

          &.text {
            text-overflow: ellipsis;
            overflow: hidden;
            flex-grow: 1;
            white-space: normal;
            &-padding {
              padding-left: 17px;
            }
          }

          &:last-child {
            margin: 0 17px;
          }
        }
      }
    }
  }
}
</style>
