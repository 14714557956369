<template lang="pug">
  .chart-container(v-loading.lock="loading", ref="chart-container")
    .chart-header
      .chart-header_name
        i.icon.icon-drag-and-drop-dark.handle(v-if="!$deviceInfo.isMobileDevice")
        i.icon.icon-drag-and-drop-m.handle(v-else)
        template(v-if="!editName")
          h3.widget-name
            span {{ widget.name }}
        template(v-else)
          .widget-name(:class="{'is-editing': editName}")
            span(:contenteditable="editName", ref="widget-name", data-value="widget-title-name") {{ widget.name }}
            i.el-icon-check(@click="saveNewName", data-action="btn-apply-rename")
            i.el-icon-close(@click="toggleEditName", data-action="btn-cancel-rename")
      el-button.chart-filter(v-if="!$deviceInfo.isMobileDevice", type="text", slot="reference", @click.stop="showFilter", :class="{ 'open': widgetFilterVisible}")
        i.icon.icon-chart-filter-orange
        span.chart-filter-text(data-action="btn-show-filter") Filter
      .chart-header_actions

        //- Desktop Description Popover
        el-popover(v-if="!$deviceInfo.isMobileDevice",
          placement="top",
          :title="widgetTypes[widget.type].title",
          width="400",
          trigger="hover",
          popper-class="popover-tooltip")
          p.popover-tooltip_content {{ widgetTypes[widget.type].description }}

        //- Mobile Description Modal
        filter-mobile-container(v-if="$deviceInfo.isMobileDevice",
          :widget-filters="filterSimple",
          :widget-type="widget.type",
          @submit-filters="submitFilters",
          @reset-filters="resetFilters")
        //- Desktop Filter Popover
        el-dropdown(trigger="click", :class="{'filter-opened': widgetFilterVisible}")
          el-button.button-chart-more(type="text")
            span
          el-dropdown-menu.widget-actions(slot="dropdown")
            el-dropdown-item
              el-button(type="text", @click="getWidgetDetails", data-action="widget-full-view") Full view
            el-dropdown-item
              el-button(type="text", @click="toggleEditName", data-action="widget-rename") Rename
            el-dropdown-item
              el-button.button-remove-widget(type="text", @click="removeWidget", data-action="widget-remove") Remove

    .widget(v-if="!loading", :class="{'no-data': !showWidget, [chartType]: true}")
      .filter-wrapper(:class="{'is-open': widgetFilterVisible}", v-click-outside="vcoConfig")
        el-popover(v-if="!$deviceInfo.isMobileDevice",
          v-model="widgetFilterVisible",
          placement="bottom-end",
          :visible-arrow="false",
          popper-class="widget-filter",
          trigger="manual",
          @hide="onPopperHide")

          .widget-create-filters
            filter-container(v-if="isAddFilterOpened",
              :widget-filters="{}",
              :widget-type="widget.type",
              @add-filters="addFilter")
            filter-show-container(v-if="!isAddFilterOpened",
              :widgetFilters="filterSimple",
              :widget-type="widget.type",
              @remove-filter="removeFilter",
              @open-edit-filter="openEditFilter",
              @update-filter="updateFilters")

          // i.el-icon-close(@click="widgetFilterVisible = false")

      component(v-if="showWidget && !showLegend",
        :class="{'is-open': widgetFilterVisible}",
        :is="chartType",
        :data="widgetData",
        v-bind="widgetOptions")

      img(v-if="!showWidget", :src="WidgetNoDataThumb")

      template(v-if="showWidget && \
            $deviceInfo.isMobileDevice && \
            chartType === 'pie'")
        button(class="toggle-legend-button", type="button", @click="toggleLegend")
          | {{ showLegend ? 'Chart' : 'Legend' }}
        .pie-chart-legend(v-if="showLegend")
          .pie-chart-legend_item(v-for="item in widgetData", :key="item.color")
            span.pie-chart-legend_item-color(:style="`background-color: ${item.color}`")
            span.pie-chart-legend_item-label {{ item.utility }} {{ item.value <= 3 ? '< 3' : `- ${item.value}` }}%

</template>

<script>
import * as R from 'ramda'
import _ from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import { dashboard } from '@/api'
import FilterContainer from './Filters/FilterContainer.vue'
import FilterShowContainer from './Filters/FilterShowContainer'
import FilterMobileContainer from './Filters/FilterMobileContainer.vue'
import WidgetMobileDescription from './WidgetMobileDescriptionModal.vue'
import Bar from '@/components/Charts/BarChart.vue'
import Pie from '@/components/Charts/PieChart.vue'
import Stack from '@/components/Charts/StackChart.vue'
import WidgetNoDataThumb from '@/assets/widget-no-data-thumb.svg'
import { filters } from '@/lib'

const mapFilters = (f) => {
  if (f.filter === 'time_period') {
    const type = f.time_period.type
    return { time_period: { ..._.omit(f.time_period, 'type') }, type }
  } else {
    return { [f.filter]: f[f.filter] }
  }
}

export default {
  components: {
    FilterContainer,
    FilterShowContainer,
    FilterMobileContainer,
    WidgetMobileDescription,
    Bar,
    Pie,
    Stack,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.resizeWidget()
    window.addEventListener('resize', this.resizeWidget)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeWidget)
  },
  data() {
    return {
      widgetFilterVisible: false,
      editName: false,
      widgetName: '',
      loading: false,
      showLegend: false,
      WidgetNoDataThumb,
      widgetData: [],
      isAddFilterOpened: false,
      isEditFilterOpened: false,
      editFilterType: '',
      filters: {},
      widgetWidth: 0,
      vcoConfig: {
        handler: this.clickOutSide,
        middleware: this.middleware,
        events: ['click'],
        isActive: true,
      },
    }
  },
  watch: {
    name() {
      if (this.editName) {
        this.toggleEditName()
      }
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      widgetTypes: 'widgets/widgetTypes',
    }),
    filterSimple: {
      cache: false,
      get: function () {
        const currentFilters = {}
        const widgetFilter = _.get(this.widget, ['payload', 'filters'], [])
        widgetFilter.forEach((value) => {
          const filterType = value.filter
          currentFilters[filterType] = value[filterType]
        })
        return {
          ...filters.defaultFilters(this.widget.type, 'show'),
          ...currentFilters,
        }
      },
    },
    vendorWidgetType() {
      return ['charges_by_vendor'].includes(this.widget.type)
    },
    monthlyWidgetType() {
      return [
        'monthly_cost_by_location',
        'monthly_consumption_by_location',
        'costs_by_charge_type',
      ].includes(this.widget.type)
    },
    chartType() {
      return this.widgetTypes[this.widget.type].chart_types[0]
    },
    showWidget() {
      return this.widgetData && this.widgetData.length
    },
    widgetOptions() {
      const options = {}
      const isMobile = this.$deviceInfo.isMobileDevice

      options.barItemWidth = isMobile ? 10 : 15
      options.barWidth = isMobile ? 40 : 55

      if (this.chartType === 'bar' || this.chartType === 'stack') {
        options.barWidth = this.defineBarChartBarWidth()
        options.width =
          (this.widgetData.length < 3
            ? this.widgetData.length + 1
            : this.widgetData.length) * options.barWidth
      } else {
        options.width = isMobile ? 300 : 500
      }

      if (isMobile && this.chartType === 'pie') {
        options.height = 300
      }
      if (this.chartType === 'pie') {
        options.pieSize = isMobile ? 240 : 300
        options.showLegend = !isMobile
      }

      options.labels = this.defineWidgetLabels()
      if (!isMobile) {
        options.width = this.widgetWidth
      }

      return options
    },
  },
  async created() {
    await this.getWidgetData()
  },
  methods: {
    ...mapActions({
      fetchWidgetData: 'widgets/fetchWidgetData',
      patchWidget: 'widgets/patchWidget',
      deleteWidget: 'widgets/deleteWidget',
    }),
    clickOutSide() {
      if (this.widgetFilterVisible) {
        this.widgetFilterVisible = false
      }
    },
    resizeWidget() {
      this.widgetWidth = this.$refs['chart-container'].clientWidth - 40
      this.widgetWidth = this.widgetWidth > 580 ? 580 : this.widgetWidth
    },
    checkAvailableFilters(filter) {
      return R.contains(
        filter,
        R.pathOr([], [this.widgetType, 'filters'], this.widgetTypes),
      )
    },
    addFilter(filter) {
      this.isAddFilterOpened = false
      this.submitFilters({ ...this.filterSimple, ...filter })
    },
    openEditFilter(filterType) {
      this.isEditFilterOpened = true
      this.editFilterType = filterType
    },
    removeFilter() {
      const filters = _.get(this.widget, 'payload.filters', [])
      _.remove(filters, (item) =>
        _.keys(item).find((i) => i === this.editFilterType),
      )
      this.submitFilters({})
      this.editFilterType = ''
      this.isEditFilterOpened = false
    },
    updateFilters(filter) {
      this.isEditFilterOpened = false
      this.submitFilters(_.extend(this.filterSimple, filter))
    },
    defineBarChartBarWidth() {
      if (this.monthlyWidgetType || this.vendorWidgetType) return 48
      return 55
    },
    defineWidgetLabels() {
      switch (this.widget.type) {
        case 'highest_unit_cost_locations':
          return { x: 'location', y: 'unitCosts' }
        case 'highest_cost_locations':
          return { x: 'location', y: 'costs' }
        case 'highest_consumption_locations':
          return { x: 'location', y: 'consumption' }
        case 'monthly_cost_by_location':
          return { x: 'month', y: 'costs' }
        case 'monthly_consumption_by_location':
          return { x: 'month', y: 'consumption' }
        case 'charges_by_vendor':
          return { x: 'vendor', y: 'costs' }
        default:
          return {}
      }
    },
    getWidgetDetails() {
      this.$router.push({
        path: `dashboard/${this.widget.id}`,
      })
    },
    async getWidgetData() {
      this.loading = true
      let options = {
        customerId: this.currentCustomerId,
        widgetId: this.widget.id,
        params: {},
      }

      const { data } = await dashboard.getWidgetData(options, this.widget.type)
      if (
        ['charges_by_vendor', 'costs_by_charge_type'].includes(this.widget.type)
      ) {
        this.widgetData = data.slice(0, 12)
      } else {
        this.widgetData = data
      }
      this.loading = false
    },
    async removeWidget() {
      await this.deleteWidget({
        customerId: this.currentCustomerId,
        widgetId: this.widget.id,
      })
    },
    async submitFilters(currentFilters) {
      /* eslint-disable */
      const {
        time_period,
        location_id,
        utility_type,
        utility_type_list,
        year,
      } = currentFilters
      const filters = _.get(this.widget, 'payload.filters', [])
        .slice()
        .map((f) => mapFilters(f))

      if (!_.isEmpty(time_period)) {
        const existing = _.get(
          _.find(filters, (item) =>
            _.keys(item).find((i) => i === 'time_period'),
          ),
          'time_period.period',
          {},
        )
        const type = _.get(time_period, 'type', 'all')
        const period = { ...existing, ..._.omit(time_period, 'type') }
        _.remove(filters, (item) =>
          _.keys(item).find((i) => i === 'time_period'),
        )
        if (period.start_date && period.end_date && type === 'period') {
          filters.push({ time_period: { period, type } })
        } else if (type === 'months') {
          const months = time_period.count
            ? time_period.count
            : time_period.months
          filters.push({ time_period: { months, type } })
        } else if (type === 'year_to_date') {
          filters.push({ time_period: { year_to_date: true, type } })
        }
      }

      if (!_.isEmpty(location_id)) {
        _.remove(filters, (item) =>
          _.keys(item).find((i) => i === 'location_id'),
        )
        if (!_.isEmpty(location_id.location_ids)) {
          filters.push({
            location_id: {
              location_ids: location_id.location_ids,
            },
          })
        }
      }

      if (!_.isEmpty(utility_type)) {
        _.remove(filters, (item) =>
          _.keys(item).find((i) => i === 'utility_type'),
        )
        filters.push({ utility_type })
      }

      if (!_.isEmpty(year)) {
        _.remove(filters, (item) => _.keys(item).find((i) => i === 'year'))
        filters.push({ year })
      }

      if (!_.isEmpty(utility_type_list)) {
        _.remove(filters, (item) =>
          _.keys(item).find((i) => i === 'utility_type_list'),
        )
        filters.push({ utility_type_list })
      }

      this.loading = true
      await this.patchWidget({
        customerId: this.currentCustomerId,
        widget: { id: this.widget.id, filters },
      })
      this.widgetFilterVisible = false
      await this.getWidgetData()
    },
    async resetFilters() {
      this.submitFilters(filters.defaultFilters(this.widget.type))
    },
    showFilter({ currentTarget: el }) {
      this.widgetFilterVisible = !this.widgetFilterVisible
    },
    onPopperHide() {
      document
        .querySelectorAll('.button-chart-filter')
        .forEach((e) => e.classList.remove('is-active'))
    },
    toggleEditName() {
      this.editName = !this.editName
    },
    async saveNewName() {
      if (this.$refs['widget-name'].textContent === this.widget.name) {
        this.editName = false
        return
      }

      await this.$emit('update-widget', [
        {
          id: +this.widget.id,
          name: this.$refs['widget-name'].textContent,
          filters: _.get(this.widget, 'payload.filters', [])
            .slice()
            .map((f) => mapFilters(f)),
          sortOrder: +this.widget.sort_order,
        },
      ])

      this.editName = false
    },
    toggleLegend() {
      this.showLegend = !this.showLegend
    },
    middleware(event) {
      const path = event.path || (event.composedPath && event.composedPath())
      if (path) {
        return path.every((el) => {
          const classes = String(_.get(el, ['className'], ''))
          return classes.indexOf('el-dialog__wrapper') === -1
        })
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/_utilities';

.create-filter {
  text-align: right;
}

.widget-container,
.pie-chart {
  &.is-open {
    opacity: 0.25;
  }
}

.chart-container {
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  max-width: 600px;
  flex: none;
  margin-right: 20px;
  margin-bottom: 20px;
  color: #484848;
  font-size: 10px;
  line-height: 14px;
  border-radius: 8px;
  border: solid 2px #d3d3d3;
  background-color: #fff;
  box-sizing: border-box;
  @include respond-to($tablet-l) {
    min-width: 600px;
  }

  @include respond-to($phone) {
    min-width: 300px;
    width: 100%;
    min-height: 320px;
    margin-right: 0;
    box-shadow: 0 1px 6px 0 rgba(155, 155, 155, 0.5);
  }
  @include respond-to($phone-l) {
    min-width: 300px;
    width: 100%;
    min-height: 320px;
    margin-right: 0;
    box-shadow: 0 1px 6px 0 rgba(155, 155, 155, 0.5);
  }
  ::v-deep .filter-item-date > :not(.el-input-number) .el-input {
    width: 145px;
  }

  ::v-deep .button-tooltip {
    width: 32px;
    height: 32px;
    padding: 0;
    margin-right: 16px;
    font-weight: 900;

    @include respond-to($phone) {
      width: 26px;
      height: 26px;
      margin-right: 10px;
      font-size: 12px;
    }
    @include respond-to($phone-l) {
      width: 26px;
      height: 26px;
      margin-right: 10px;
      font-size: 12px;
    }
  }

  ::v-deep .chart-filter {
    &.open {
      color: #475e75;
      background-color: #ffffff;
      border: 1px solid #475e75;
    }

    flex-basis: 98px;
    position: relative;
    top: 8px;
    height: 28px;
    width: 98px;
    padding: 0 16px 0 13px;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: #ffffff;
    background-color: #475e75;
    border-radius: 14px;
    border: 1px solid #475e75;
    vertical-align: top;

    .icon {
      padding-top: 6px;
      margin-right: 8px;
    }

    &-text {
      position: relative;
      top: -3px;
    }

    &.mobile {
      position: absolute;
      top: 200px;
      left: 15px;
      width: 72px;
      height: 24px;
      line-height: 24px;
      background-color: #2893f9;
      border-radius: 10px 10px 0 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0 15px;
      font-size: 12px;
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
      color: #fff;
      border: none;
      z-index: 1;
    }

    @include respond-to($phone) {
      &.mobile {
        display: block;
        position: relative;
        border-radius: 50%;
        transform: none;
        padding: 0;
        left: 0;
        top: 0;
      }

      .icon {
        top: 5px;
        transform: translateX(-50%) scale(0.8);
      }
    }
  }
  .filter-wrapper {
    position: absolute;
    top: -1px;
    right: -405px;
    width: 405px;
    height: calc(100% - 20px);
    transition: 0.1s all;

    &.is-open {
      ::v-deep .el-popover {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #d2d3d4;
        border-radius: 0 0 8px 0;
      }

      right: 0;
    }
  }

  .button-chart-more {
    position: relative;
    width: 27px;
    height: 20px;
    padding: 0;
    margin: 0 10px 0 14px;
    border: 0;
    cursor: pointer;

    @include respond-to($phone) {
      margin-left: 10px;
    }
    @include respond-to($phone-l) {
      margin-left: 10px;
    }

    span {
      position: absolute;
      top: 50%;
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #475e75;
      transform: translateY(-50%);

      @include respond-to($phone) {
        width: 6px;
        height: 6px;
        background-color: #ebebeb;
      }
      @include respond-to($phone-l) {
        width: 6px;
        height: 6px;
      }

      &::before,
      &::after {
        position: absolute;
        top: 0;
        width: 7px;
        height: 7px;
        content: '';
        border-radius: 50%;
        background-color: #475e75;

        @include respond-to($phone) {
          width: 6px;
          height: 6px;
          background-color: #ebebeb;
        }
        @include respond-to($phone-l) {
          width: 6px;
          height: 6px;
        }
      }

      &::before {
        left: -10px;
      }

      &::after {
        right: -10px;
      }
    }
  }

  .widget {
    flex-grow: 1;
    position: relative;
    padding: 0 0 0 15px;
    width: calc(100% + 15px);
    left: -14px;
    box-sizing: border-box;
    overflow: hidden;
    @include respond-to($phone) {
      overflow-x: auto;
      &.pie {
        overflow-x: hidden;
      }
    }

    img {
      display: block;
      max-width: 100%;
    }

    &.no-data {
      position: relative;
      padding: 20px 20px 20px 50px;
      height: 340px;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        content: 'NO DATA FOR VIEW';
        color: #7f7f7f;
        font-size: 22px;
        font-weight: 900;
        line-height: 30px;
        transform: translate(-50%, -50%);
        z-index: 1;

        @include respond-to($phone) {
          font-size: 18px;
        }
      }
    }

    @include respond-to($phone) {
      ::v-deep .pie-chart {
        display: flex;
        justify-content: center;
      }
      ::v-deep .arc > text {
        font-size: 12px;
      }
    }
  }
}

.chart-header {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;
  border-bottom: solid 2px #dedddd;

  &_name {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 8px 10px 5px 35px;
    flex-grow: 1;
    max-width: calc(100% - 120px);

    .icon-drag-and-drop-dark {
      position: absolute;
      top: 12px;
      left: 10px;
      cursor: pointer;
    }

    .icon-drag-and-drop-m {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    .icon-edit {
      margin-top: 6px;
      margin-left: 10px;
      cursor: pointer;
    }

    .el-icon-check,
    .el-icon-close {
      margin-top: 2px;
      font-size: 18px;
      cursor: pointer;
    }

    .el-icon-check {
      margin-left: 10px;
      color: #43b493;
    }

    .el-icon-close {
      color: #f86259;
    }

    .new-name-input {
      display: inline-block;
      color: #5d5d5d;
      font-size: 18px;
      line-height: 25px;
      font-weight: 700;

      &::after {
        display: block;
        content: '';
        width: 116px;
        height: 1px;
        margin-top: 8px;
        margin-left: -14px;
        background-color: #2893f9;
      }
    }
  }

  &_actions {
    padding: 10px 15px 0 0;
    display: flex;
    align-items: center;

    ::v-deep #{'>'} span {
      line-height: 1;
    }
  }
}

.widget-name {
  position: relative;
  min-width: 120px;
  min-height: 34px;
  margin: 0;

  span {
    color: #475e75;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    word-break: break-word;
    outline: 0;

    @include respond-to($phone) {
      font-size: 14px;
      line-height: 19px;
    }
    @include respond-to($phone-l) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &.is-editing {
    &::after {
      background-color: #2893f9;
    }
  }
}

.toggle-legend-button {
  position: absolute;
  top: 50px;
  right: -20px;
  width: 64px;
  height: 24px;
  color: #fff;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  border: 1px solid #168cfe;
  border-radius: 8px 8px 0 0;
  background-color: #2893f9;
  outline: none;
  transform: rotate(-90deg);
}

.pie-chart-legend {
  min-height: 300px;
  padding: 20px 8px;
  box-sizing: border-box;
}

.pie-chart-legend_item {
  margin-bottom: 5px;

  &-color {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }

  &-label {
    color: #333333;
  }
}
</style>

<style lang="scss">
.widget-filter {
  left: 0;
  padding: 25px;
  border-radius: 8px 0 8px 0;
  background-color: #ffffff;
  box-shadow: none;

  > .el-icon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 16px;
    cursor: pointer;
  }
}

.widget-actions {
  padding: 0;
  border-radius: 8px 0 8px 8px;
  box-shadow: 0 1px 2px 0 rgba(194, 194, 194, 0.5);

  .el-button {
    padding: 7px 32px 7px 12px;
    color: #4c4c4c;
  }

  .button-remove-widget {
    color: #ef3939;
  }

  .el-dropdown-menu__item {
    padding-right: 0;
    padding-left: 0;
    line-height: 1;

    &:not(:first-child) {
      border-top: 1px solid #efefef;
    }
  }

  .popper__arrow {
    display: none;
  }
}
</style>
