<template lang="pug">
  .filter-mobile-container
    el-button(v-if="$deviceInfo.isMobileDevice && $route.name !== 'dashboard.widgets.details'"
              class="chart-filter mobile",
              type="text",
              @click="showFilter = true") Filters
    .filter-mobile(v-if="showFilter")
      el-button.close(v-if="!$deviceInfo.isMobileDevice || $route.name !== 'dashboard.widgets.details'", type="text", @click='showFilter = false')
        i.el-icon-close

      filter-year(v-if="checkAvailableFilters('year')", title="Year", :filter="filters.year", :value="defaultYear")
      filter-time-period(v-if="checkAvailableFilters('time_period')",
                        title="Time Period", :filter="filters.time_period", :value="defaultTimePeriod")
      filter-locations(v-if="checkAvailableFilters('location_id')",
                      title="Locations", :filter="filters.location_id", :value="defaultLocations")
      filter-utility-type(v-if="checkAvailableFilters('utility_type')",
                          :commodities="commodities",
                          title="Utility Type", :filter="filters.utility_type", :value="defaultUtilityType")
      filter-utility-type-list(v-if="checkAvailableFilters('utility_type_list')",
        :commodities="commodities",
        title="Utility Types", :filter="filters.utility_type_list", :value="defaultUtilityTypeList")

      .filter-mobile_actions
        el-button.button-filter-apply(type="primary", size="small", @click="applyFilters") Apply Filters
        el-button.button-filter-reset(type="primary", size="small", @click="resetFilters") Reset Filters
</template>

<script>
import * as R from 'ramda'
import _ from 'lodash'
import { mapState, mapGetters } from 'vuex'
import FilterTimePeriod from './Mobile/FilterTimePeriod.vue'
import FilterYear from './Mobile/FilterYear.vue'
import FilterLocations from './Mobile/FilterLocations.vue'
import FilterUtilityType from './Mobile/FilterUtilityType.vue'
import FilterUtilityTypeList from './Mobile/FilterUtilityTypeList.vue'
import { dashboard } from '@/api'

export default {
  props: {
    widgetFilters: {
      type: Object,
      required: true,
    },
    widgetType: {
      type: String,
      required: true,
    },
  },
  components: {
    FilterTimePeriod,
    FilterLocations,
    FilterUtilityType,
    FilterUtilityTypeList,
    FilterYear,
  },
  beforeMount() {
    this.getWidgetMeta()
  },
  data() {
    return {
      filters: {
        year: {},
        time_period: {},
        utility_type: {},
        utility_type_list: {},
        location_id: {},
      },
      showFilter: false,
      widgetMeta: {},
    }
  },
  watch: {
    widgetFilters() {
      this.getWidgetMeta()
    },
  },
  computed: {
    ...mapState({
      customerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      widgetTypes: 'widgets/widgetTypes',
    }),
    defaultYear: {
      cache: false,
      get: function () {
        return _.get(this.widgetFilters, ['year'], {
          year: new Date().getFullYear(),
        })
      },
    },
    defaultLocations: {
      cache: false,
      get: function () {
        return _.get(this.widgetFilters, ['location_id', 'location_ids'], [])
      },
    },
    defaultTimePeriod: {
      cache: false,
      get: function () {
        return _.get(this.widgetFilters, ['time_period'], [])
      },
    },
    defaultUtilityType: {
      cache: false,
      get: function () {
        return _.get(
          this.widgetFilters,
          ['utility_type', 'utility_type'],
          'ELECTRIC',
        )
      },
    },
    defaultUtilityTypeList: {
      cache: false,
      get: function () {
        return _.get(this.widgetFilters, ['utility_type_list', 'list'], [])
      },
    },
    commodities() {
      return _.get(this.widgetMeta, 'utility_type_list', [])
    },
  },
  methods: {
    checkAvailableFilters(filter) {
      return R.contains(
        filter,
        R.pathOr([], [this.widgetType, 'filters'], this.widgetTypes),
      )
    },
    toggleFilter() {
      this.showFilter = !this.showFilter
    },
    applyFilters() {
      this.showFilter = false
      this.$emit('submit-filters', this.filters)
    },
    async resetFilters() {
      this.showFilter = false
      this.$emit('reset-filters')
    },
    getWidgetMeta() {
      const { customerId, widgetType } = this
      if (_.isEmpty(widgetType)) {
        return
      }
      dashboard.getWidgetMeta({ customerId, widgetType }).then((meta) => {
        this.widgetMeta = meta
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../styles/utilities';
.filter-mobile-container {
  .chart-filter.mobile {
    position: absolute;
    top: 200px;
    left: calc(100% - 48px);
    width: 72px;
    height: 24px;
    line-height: 24px;
    background-color: #2893f9;
    border-radius: 10px 10px 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 15px;
    font-size: 12px;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    color: #fff;
  }

  .filter-mobile {
    position: fixed;
    top: 74px;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 40px 30px 25px;
    background-color: #e7ebf0;
    overflow-y: auto;
    z-index: 10;

    &-container {
      height: 26px;

      ::v-deep .filter-item {
        margin-bottom: 15px;
      }
    }
    &_actions {
      text-align: center;

      .el-button {
        &:first-child {
          margin-top: 10px;
        }
      }
    }
  }
  .close {
    position: absolute;
    top: 20px;
    right: 12px;
    width: 26px;
    height: 26px;
    padding: 0;
    margin-right: 0;
    transition: none;

    i {
      margin-right: 0;
      color: #7d89a6;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .toggle-mobile-filter {
    position: fixed;
    top: 9px;
    right: 15px;
    width: 26px;
    height: 26px;
    padding: 0;
    margin-right: 0;
    z-index: 99;

    i {
      @include respond-to($phone) {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}
</style>
