<template lang="pug">
  .widget-mobile-description
    el-button(class="button-tooltip",
              :class="{'is-active': showDescription}",
              type="primary",
              round,
              @click="toggleDescription") {{ showDescription ? '&times;' : '?' }}
    .tooltip-container(v-if="showDescription")
      h4 {{ title }}
      p {{ description }}
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDescription: false,
    }
  },
  methods: {
    toggleDescription() {
      this.showDescription = !this.showDescription
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-mobile-description {
  position: relative;
}
.tooltip-container {
  position: absolute;
  top: 30px;
  right: 10px;
  width: 250px;
  max-height: 250px;
  padding: 12px;
  font-size: 14px;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  border: 1px solid #ebeef5;
  border-radius: 4px 0 4px 4px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  box-sizing: border-box;
  z-index: 10;

  h4 {
    display: inline-block;
    margin: 0;
    padding-bottom: 3px;
    font-size: 14px;
    font-weight: 900;
    border-bottom: 1px solid #0080ff;
  }
  p {
    font-size: 12px;
  }
}
.button-tooltip {
  &.is-active {
    color: #3a8ee6;
    border-color: #3a8ee6;
    background-color: #fff;
  }
}
.close {
  position: absolute;
  top: 20px;
  right: 12px;
  width: 26px;
  height: 26px;
  padding: 0;
  margin-right: 0;
  transition: none;

  i {
    margin-right: 0;
    color: #7d89a6;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
