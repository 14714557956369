<template lang="pug">
.form-wrapper.forgot-password-page
  .icon-wrapper
    i.icon.icon-sam-bot
    i.icon.icon-lamp
  .form-container
    .form.message-success
      template(v-if='showMessage')
        h1.form-title Password reset email sent
        p.form-text If the email address <span>{{ email }}</span> maps to an account in our system, we will send instructions to reset your password. Please check your email for instructions.
        p.form-text If you don't receive an email, please check your Spam folder or contact your pear.ai administrator.
        .form-actions
          router-link.form-link.big-link(:to='{name: "login"}') Back to Login
      template(v-if='!showMessage')
        h1.form-title Forgot your password?
        p.form-text Enter your email address and we will send you a link to reset it
        .form-fields
          .form-field
            .el-input.input-login(:class='{error: emailError}')
              input#field-email.el-input__inner(v-model='email',
                                    @input="handleInput",
                                    v-validate='"required|email"',
                                    :data-vv-delay='700',
                                    type='text',
                                    name='email',
                                    placeholder='Enter a valid email address',
                                    index='1')
              button.button-clear(v-if='errors.first("email") && email.length > 0',
                                  type='button',
                                  @click='clearEmail')
              label Email
            .form-error(v-if='emailError') {{ emailError }}
        .form-actions
          el-button#button-submit.button-login(type='primary',
                                index='2',
                                :disabled='errors.has("email") || !email.length',
                                @click.native='sendResetLink') Send reset link
          router-link.form-link.big-link(:to='{name: "login"}') Back to Login
</template>

<script>
import { auth } from '@/api'

export default {
  data() {
    return {
      email: '',
      showMessage: false,
    }
  },
  computed: {
    emailError() {
      return this.errors.has('email')
        ? 'Please enter a valid email address.'
        : null
    },
  },
  methods: {
    handleInput() {
      this.errors.remove('email')
    },
    async sendResetLink() {
      if (!this.errors.has('email')) {
        try {
          await auth.forgotPassword(this.email)
          this.showMessage = !this.showMessage
        } catch (e) {
          this.errors.add({ field: 'email', msg: 'Email not found' })
        }
      }
    },
    clearEmail() {
      this.email = ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/utilities';

.form-actions {
  margin-top: 40px;

  @include respond-to($phone) {
    justify-content: center;
  }
}
.form-text + .form-actions {
  margin-top: 10px;
}
.message-success {
  .form-title {
    margin-bottom: 30px;
  }
}
</style>
