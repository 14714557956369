var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogVisible,"close-on-click-modal":_vm.closeOnMask,"width":_vm.width,"append-to-body":_vm.appendToBody,"destroy-on-close":_vm.destroyOnClose,"show-close":_vm.showClose,"center":_vm.center},on:{"close":_vm.close,"open":_vm.open}},[_c('div',[_vm._t("content")],2),_c('div',{class:[
      _vm.isLeft
        ? 'dialog-footer-left'
        : _vm.isRight
        ? 'dialog-footer-right'
        : 'dialog-footer-center',
      'dialog-footer',
      _vm.$attrs.classes ],attrs:{"slot":"footer"},slot:"footer"},[_vm._t("footer")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }