<template>
  <BaseModal
    :title="title"
    :dialogVisible="showModal"
    @close="close"
    @open="init"
    :width="'640px'"
    :closeOnMask="false"
    :showClose="true"
    :loading="loading"
    class="customize-report-modal-base-modal"
  >
    <template #content>
      <div v-loading="loading">
        <!-- <ErrorCard :statusMessage="createError" /> -->
        <p class="info">
          <span class="bold">Base Report:</span>
          <span class="paranthesis">{{ baseReportTitle }}</span>
        </p>
        <el-tabs v-model="activeTab">
          <el-tab-pane
            v-if="update"
            label="Delivery Options"
            name="deliveryOptions"
            data-action="Show Edit"
          >
            <form-body :report="filters" :opened="opened" />
          </el-tab-pane>
          <el-tab-pane
            v-if="update"
            label="Delivery Methods"
            name="deliveryMethods"
            data-action="Show Edit"
          >
            <delivery-methods-update
              :report="filters"
              :opened="opened"
              ref="customizeFilters"
            />
          </el-tab-pane>
          <el-tab-pane
            :disabled="
              !reportTemplate ||
              !reportTemplate.availableFilters ||
              reportTemplate.availableFilters.length === 0
            "
            label="Customize Filters"
            name="filters"
            data-action="Customize Filters"
          >
            <customize-filters-tab
              :report="reportTemplate"
              :filters="filtersToChange"
              @updateFilters="filters[$event.key] = $event.value"
              :advanced-filters="filtersToChange"
              :reportType="reportType"
              :open="visible"
            ></customize-filters-tab>
          </el-tab-pane>
          <el-tab-pane
            class="output__tab-pane"
            label="Output"
            name="output"
            data-action="Show Output"
          >
            <output-tab class="output-dimensions" :filters="filters" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
    <template #footer>
      <el-button class="button-size" round type="primary" @click="close">
        Cancel
      </el-button>
      <el-button
        :disabled="disableButton"
        class="button-size"
        round
        type="primary"
        @click="applyFilters"
      >
        Apply
      </el-button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import { DateTime } from 'luxon'
import CustomizeFiltersTab from '../Tabs/Create/CustomizeFiltersTab'
import OutputTab from '../Tabs/Create/OutputTab'
import FormBody from '../ReportsComponents/FormUpdate'
import BaseInput from '@/components/FormComponents/BaseInput'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import { isString, isNil } from 'lodash'
import DeliveryMethodsUpdate from '../ReportsComponents/DeliveryMethodsUpdate'
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    report: {
      type: Object,
      default: () => {
        return {}
      },
    },
    update: {
      type: Boolean,
      default: false,
    },
    createError: {
      type: String,
      default: '',
    },
  },
  components: {
    DeliveryMethodsUpdate,
    BaseModal,
    BaseInput,
    BaseSelect,
    FormBody,
    CustomizeFiltersTab,
    OutputTab,
  },
  data() {
    return {
      showModal: false,
      title: '',
      reportTitle: '',
      filters: {},
      appliedFilters: {},
      sftpConnectionId: null,
      baseReportTitle: '',
      activeTab: 'filters',
      isEditMode: false,
      reportType: '',
      opened: false,
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  computed: {
    reportTemplate() {
      if (this.report && this.report.reportTemplate) {
        return this.report.reportTemplate
      }
      return this.report
    },
    filtersToChange() {
      if (this.update) {
        return this.appliedFilters
      }
      return this.filters
    },
    disableButton() {
      if (this.filtersToChange.selectVal === 'Custom Range') {
        return !this.filters.title || isNil(this.filtersToChange.dateRange)
      }
      return !this.filters.title
    },
  },
  methods: {
    ...mapMutations({
      resetAvailableFilters: 'availableFiltersReportsBudgeting/resetAvailableFilters'
    }),
    ...mapActions({
      loadLocations: 'locations/loadLocations',
    }),
    close() {
      this.opened = false
      if (this.$refs.customizeFilters) {
        this.$refs.customizeFilters.reset(true)
      }
      this.$emit('close')
      this.resetAvailableFilters()
    },
    init() {
      this.opened = true
      const clonedReport = this.report
      this.title = 'Customize the Report'
      this.baseReportTitle = clonedReport.title || 'Report'
      this.reportType = clonedReport.readableId
      this.reportTitle =
        clonedReport.customReportName || clonedReport.title || 'Report'
      if (
        this.reportTemplate.availableFilters &&
        this.reportTemplate.availableFilters.length === 0
      ) {
        this.activeTab = 'output'
      } else {
        this.activeTab = 'filters'
      }
      if (this.update) {
        this.title = `Edit ${this.report.customReportName}`
        this.baseReportTitle =
          clonedReport.customReportName || clonedReport.title || 'Report'
        this.reportType = clonedReport.reportTemplate.readableId
        this.reportTitle =
          clonedReport.customReportName || clonedReport.title || 'Report'
        this.activeTab = 'deliveryOptions'
        this.appliedFilters =
          this.report && isString(this.report.appliedFilters)
            ? JSON.parse(this.report.appliedFilters)
            : this.report.appliedFilters
        const startDate = this.appliedFilters && this.appliedFilters.start_date
        const endDate = this.appliedFilters && this.appliedFilters.end_date
        const dateDiff = this.dateDiff(
          DateTime.fromISO(startDate),
          DateTime.fromISO(endDate),
        )
        this.appliedFilters = {
          ...this.appliedFilters,
          dateRange:
            this.appliedFilters && dateDiff ? [startDate, endDate] : null,
          selectVal:
            this.appliedFilters && this.appliedFilters.selectVal
              ? this.appliedFilters.selectVal
              : 'All Time',
          pickerVisible:
            this.appliedFilters &&
            this.appliedFilters.selectVal === 'Custom Range',
        }
        this.filters = {
          ...this.report,
          title: this.report.customReportName,
          saveFormat: this.report.saveFormat,
        }
      } else {
        this.filters = {
          ...clonedReport.appliedFilters,
          title: this.reportTitle,
          saveFormat: this.report.saveFormat || 'xlsx',
          pickerVisible:
            clonedReport.appliedFilters &&
            clonedReport.appliedFilters.selectVal === 'Custom Range',
          selectVal:
            (clonedReport.appliedFilters &&
              clonedReport.appliedFilters.selectVal) ||
            'All Time',
        }
      }
    },
    dateDiff(start, end) {
      let dateDifference
      if (!isNil(start) && !isNil(end)) {
        dateDifference = end.diff(start, 'days').days
      }
      return dateDifference
    },
    applyFilters() {
      let filtered = {}
      if (this.$refs.customizeFilters) {
        this.$refs.customizeFilters.addNew()
        this.$refs.customizeFilters.reinit(true)
      }

      const filtersLocationAttributes = (
        this.filters.location_attributes || []
      ).reduce((reduced, attribute) => {
        if (attribute.fieldType === 'Date') {
          if (attribute.dataRangeValue[0] === attribute.dataRangeValue[1]) {
            attribute.dataValue = attribute.dataRangeValue[0]
          } else {
            attribute.dataValue = attribute.dataRangeValue.join(';')
          }
        }
        reduced.push({ [attribute.key]: attribute.dataValue })
        return reduced
      }, [])

      Object.assign(this.filters, ...filtersLocationAttributes)

      Object.keys(this.filters).forEach(
        (key) => this.filters[key] === undefined && delete this.filters[key],
      )
      Object.keys(this.filters).forEach(
        (key) =>
          ['emailRecipient', 'sftpConnection'].indexOf(key) === -1 &&
          (filtered[key] = this.filters[key]),
      )

      if (this.update) {
        filtered.sftpConnectionId = this.$refs.customizeFilters.sftpConnectionId
        if (!this.$refs.customizeFilters.sftpConnectionId) {
          filtered.sftpConnection = null
        }
        filtered.emailRecipients = this.$refs.customizeFilters.emailRecipients
        this.$refs.customizeFilters.validation().then((valid) => {
          if (valid) {
            this.$emit('change-filters', {
              ...filtered,
              appliedFilters: this.appliedFilters,
            })
            this.close()
          }
        })
      } else {
        this.$emit('change-filters', filtered)
        this.close()
      }
    },
  },
}
</script>

<style lang="scss">
.el-dialog__wrapper {
  .el-dialog {
    border-radius: 8px;

    .el-dialog__header {
      padding: 32px 32px 0px 32px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
    }

    .el-dialog__body {
      padding: 0px 32px 0px 32px;

      .el-tabs__nav {
        &.is-top {
          width: 100%;
        }
      }

      .select-width {
        width: 160px;
      }

      #tab-output {
        padding-left: 16px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.customize-report-modal-base-modal {
  .button-size {
    width: 30%;
  }
  .plain-button {
    color: #2893f9;
    border-color: #2893f9;
  }
  .info {
    font-weight: 400;
    color: #2c2e34;
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 4px;
  }
  .el-tabs__content {
    overflow: auto;
    min-height: calc(100vh - 375px);
  }
  .el-button[class*='text'] {
    padding: 0;
  }
  .bold {
    font-weight: bold;
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;
  }
  .full-form {
    width: 100%;
  }
  .output-dimensions {
    width: 50%;
  }

  ::v-deep .el-tabs__nav-wrap::after {
    width: 0;
  }
}
</style>
