<template>
  <div v-loading="isLoading">
    <h2>Budget Forecast List</h2>
    <el-table
      :data="budgetingReportsList"
      @sort-change="sortTable"
      style="width: 100%"
    >
      <template #empty>
        <p class="no-budgets">No Budget Forecasts Available</p>
      </template>
      <el-table-column label="Name" prop="customReportName" sortable />
      <el-table-column label="Created on" prop="createdAt" sortable>
        <template v-slot="{ row }">
          <span>{{ createdAt(row.createdAt) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Start Month" prop="startDate" sortable>
        <template v-slot="{ row }">
          <span>{{ startMonth(row.payload.config.budget_startdt) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Utility Type">
        <template v-slot="{ row }">
          <template v-if="getAppliedFilters(row, 'utility_type') !== 'All'">
            <ul
              v-for="utilityType of getAppliedFilters(row, 'utility_type')"
              :key="utilityType"
            >
              <li>{{ utilityType }}</li>
            </ul>
          </template>
          <span v-else>
            {{ getAppliedFilters(row, 'utility_type') }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Vendor">
        <template v-slot="{ row }">
          <template v-if="getAppliedFilters(row, 'vendor') !== 'All'">
            <ul
              v-for="vendor of getAppliedFilters(row, 'vendor')"
              :key="vendor"
            >
              <li>{{ vendor }}</li>
            </ul>
          </template>
          <span v-else>
            {{ getAppliedFilters(row, 'vendor') }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Locations">
        <template v-slot="{ row }">
          <span>{{ getAppliedFilters(row, 'location_id') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Historical Data">
        <template v-slot="{ row }">
          <span>
            {{ getAppliedFilters(row, 'historical_dates', 'config') }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Status">
        <template v-slot="{ row }">
          <report-statuses :report="row" />
        </template>
      </el-table-column>
      <el-table-column>
        <template v-slot="{ row }">
          <el-tooltip
            effect="light"
            placement="top"
            content="Edit Budget Forecast"
          >
            <el-button
              class="action-button edit-btn btn-size"
              type="text"
              icon="el-icon-edit"
              :disabled="isProcessing(row)"
              @click="createBudgetConfig(row, 'edit')"
            />
          </el-tooltip>
          <el-tooltip
            effect="light"
            placement="top"
            content="Duplicate Budget Forecast"
          >
            <el-button
              class="action-button edit-btn btn-size"
              type="text"
              icon="el-icon-document-copy"
              :disabled="isProcessing(row)"
              @click="createBudgetConfig(row, 'duplicate')"
            />
          </el-tooltip>
          <el-tooltip
            effect="light"
            placement="top"
            content="Delete Budget Forecast"
          >
            <el-button
              class="action-button__delete close-btn btn-size"
              type="text"
              icon="el-icon-delete-solid"
              @click="clearReport(row.id)"
              :disabled="isProcessing(row)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination-container"
      background
      layout="prev, pager, next, sizes"
      v-if="
        (total > pagination.pageSizes[0] && pagination.offset === 0) ||
        pagination.offset !== 0
      "
      :page-size="+pagination.limit"
      :page-sizes="pagination.pageSizes"
      :total="total"
      :current-page="currentPage"
      @current-change="changeOffset"
      @size-change="changeLimit"
    />
    <advanced-options
      :is-edit-modal="isEditModal"
      :is-duplicate-modal="isDuplicateModal"
      :is-on-budgeting-list="true"
      :is-visible="isVisible"
      :budget-type="budgetType"
      :available-filters="availableFilters"
      :advanced-options="advancedOptions"
      @close="closeAdvOptModal"
      @confirm="duplicateOrEditBudget"
    />
    <clear-report-modal
      report-type="Budget"
      :visible.sync="showClearModal"
      :reportId="reportId"
      :is-budgeting-list-tab="true"
      @close="closeClearModal"
      @reload-data="loadReports"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { LocalZone, DateTime } from 'luxon'
import _ from 'lodash'

import AccordionItem from '@/components/AccordionItem'
import NewBudgetCreatorForm from '../Modals/Components/NewBudgetCreatorForm'
import ReportStatuses from '../../../../components/ReportStatuses'
import ClearReportModal from '../../Reports/Modals/ClearReportModal'
import AdvancedOptions from '../Modals/AdvancedOptions'

export default {
  components: {
    AdvancedOptions,
    ClearReportModal,
    ReportStatuses,
    NewBudgetCreatorForm,
    AccordionItem,
  },
  data() {
    return {
      reportId: '',
      isVisible: false,
      showClearModal: false,
      isDuplicateModal: false,
      isEditModal: false,
      budgetType: '',
      budgetId: 0,
      localTimeZone: new LocalZone(),
      budgetingTemplate: {},
      advancedOptions: {},
    }
  },
  watch: {
    pagination: {
      async handler(pag) {
        const { limit, offset } = pag
        const { customerId } = this.$route.params
        this.addRouteParams(pag)
        await this.getBudgetingReportsList({
          customerId,
          params: { limit, offset },
        })
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      budgetingList: 'completedBudgets/budgetingReportsList',
      isLoading: 'completedBudgets/isLoading',
      pagination: 'completedBudgets/pagination',
      currentPage: 'completedBudgets/currentPage',
      totalLocations: 'locations/total',
      budgetingTemplates: 'createBudgeting/budgetingTemplates',
      total: 'completedBudgets/total',
      availableFilters: 'availableFiltersReportsBudgeting/availableFilters'
    }),
    budgetingReportsList() {
      return this.budgetingList
    },
  },
  methods: {
    ...mapActions({
      getBudgetingReportsList: 'completedBudgets/getBudgetingReportsList',
      changePagination: 'completedBudgets/changePagination',
      updateBudget: 'completedBudgets/updateBudget',
      checkProcessingReports: 'completedBudgets/checkProcessingReports',
      createNewBudgetingReport: 'createBudgeting/createNewBudgetingReport',
      loadReportAvailableValues: 'availableFiltersReportsBudgeting/getTemplateAvailableValues'
    }),
    ...mapMutations({
      setLoading: 'createBudgeting/setLoading'
    }),
    createdAt(createdAtDate) {
      return DateTime.fromISO(createdAtDate).toFormat('LL.dd.yyyy')
    },
    startMonth(startMonthDate) {
      return DateTime.fromISO(startMonthDate).toFormat('LLL yyyy')
    },
    isProcessing(budget) {
      return budget.status === 'Processing'
    },
    changeLimit(limit) {
      this.changePagination({ limit, offset: 0 })
    },
    changeOffset(page) {
      const offset = (page - 1) * this.pagination.limit
      this.changePagination({ offset })
    },
    addRouteParams(params) {
      const { limit, offset } = params
      this.$router.push({
        query: {
          limit,
          offset,
        },
      })
    },
    closeAdvOptModal() {
      this.isVisible = false
      this.isEditModal = false
      this.isDuplicateModal = false
    },
    getAppliedFilters(row, filter, payloadType = 'filters') {
      if (filter === 'location_id') {
        return _.isNil(row.payload[payloadType][filter]) ||
          row.payload[payloadType][filter].length === 0
          ? this.totalLocations
          : row.payload[payloadType][filter].length
      }
      if (filter === 'historical_dates') {
        if (
          row.payload.config.historical_dates &&
          row.payload.config.historical_dates.length > 0
        ) {
          let [startDate, endDate] = row.payload.config.historical_dates
          if (_.isNil(startDate) || _.isNil(endDate)) {
            return 'All'
          }
          startDate = DateTime.fromISO(startDate).toFormat('LLL yyyy')
          endDate = DateTime.fromISO(endDate).toFormat('LLL yyyy')
          return _.isNil(row.payload[payloadType][filter]) ||
            row.payload[payloadType][filter].length === 0
            ? 'All'
            : `${startDate} - ${endDate}`
        }
      }
      return _.isNil(row.payload[payloadType][filter]) ||
        row.payload[payloadType][filter].length === 0
        ? 'All'
        : row.payload[payloadType][filter]
    },
    sortTable({ prop, order }) {
      const { customerId } = this.$route.params
      const { query } = this.$route
      if (_.isNil(prop) || _.isNil(order)) {
        this.$router.push({
          query: {
            ...query,
            orderBy: undefined,
          },
        })
        return
      }
      const orderBy = order === 'ascending' ? `${prop}.asc` : `${prop}.desc`
      const params = { ...query, orderBy: [orderBy] }
      this.$router.push({
        query: params,
      })
      this.getBudgetingReportsList({ customerId, params })
    },
    async duplicateOrEditBudget(budgetOptions) {
      const { budgetSettings, advancedFilters, usageConfig, unitCostConfig } =
        budgetOptions
      const budgetStartdt = DateTime.fromJSDate(
        budgetSettings.budgetStartdt,
      ).toISO()
      const locationFilters = this.budgetingTemplate.availableFilters.find(
        (filters) =>
          filters.filter((filter) => filter && filter.element !== 'hierarchy'),
      )
      const [utilityTypes, vendors] = locationFilters
      const [startDate, endDate] = usageConfig.historicalDates
      const { customerId } = this.$route.params
      const { limit, offset } = this.pagination
      const body = {
        customerId,
        id: this.isEditModal ? this.budgetId : undefined,
        reportTemplateId: this.budgetingTemplate.id,
        customReportName: budgetSettings.budgetName,
        timezone: this.localTimeZone.name,
        configPayload: {
          ...usageConfig,
          ...unitCostConfig,
          budgetStartdt: _.isNil(budgetStartdt)
            ? budgetSettings.budgetStartdt
            : budgetStartdt,
          historicalDates:
            usageConfig.historicalData === 'all'
              ? []
              : [
                DateTime.fromJSDate(startDate).toISO(),
                DateTime.fromJSDate(endDate).toISO(),
              ],
          adjustmentRate: !_.isNil(unitCostConfig.adjustmentRate)
            ? +(unitCostConfig.adjustmentRate / 100).toFixed(2)
            : undefined,
          historicalData: undefined,
        },
        appliedFilters: {
          ...advancedFilters,
          utility_type:
            advancedFilters.utility_type.includes('all') ||
            advancedFilters.utility_type.length ===
              utilityTypes.availableValues.length
              ? []
              : advancedFilters.utility_type,
          vendor:
            advancedFilters.vendor.includes('all') ||
            advancedFilters.vendor.length === vendors.availableValues.length
              ? []
              : advancedFilters.vendor,
          location_id: advancedFilters.location_id,
        },
      }
      this.isEditModal = false
      this.isDuplicateModal = false
      await this.createNewBudgetingReport(body)
      await this.getBudgetingReportsList({
        customerId,
        params: { limit, offset },
      })
      await this.checkProcessingReports({
        customerId,
        params: { limit, offset },
      })
      this.isVisible = false
    },
    clearReport(reportId) {
      this.reportId = reportId || ''
      this.showClearModal = true
    },
    closeClearModal() {
      this.showClearModal = false
      this.reportId = ''
    },
    loadReports() {
      const { customerId } = this.$route.params
      this.getBudgetingReportsList({
        customerId,
        params: { ...this.$route.query },
      })
    },
    async createBudgetConfig(budget, action = 'duplicate') {
      this.setLoading(true)
      const { customerId } = this.$route.params
      this.budgetingTemplate = this.budgetingTemplates.find(
        (budgetingTemplate) =>
          budgetingTemplate.id === budget.reportTemplate.id,
      )
      await this.loadReportAvailableValues({ customerId, templateId: this.budgetingTemplate.id })
      const { customReportName, payload, reportTemplate, id } = budget
      const { utility_type, vendor } = payload.filters
      const config = _.mapKeys(payload.config, (value, key) => _.camelCase(key))
      const { historicalDates, unitCostType, adjustmentRate, budgetStartdt } =
        config
      if (action === 'edit') {
        this.isEditModal = true
      } else {
        this.isDuplicateModal = true
      }
      this.budgetId = id
      this.budgetType = reportTemplate.title
      this.availableFilters = this.budgetingTemplate.availableFilters
      let [startDate, endDate] = historicalDates
      const areNotNullDates = _.isNil(startDate) && _.isNil(endDate)
      this.advancedOptions = {
        budgetSettings: {
          budgetName: customReportName,
          budgetStartdt,
        },
        advancedFilters: {
          ...payload.filters,
          utility_type: utility_type.length === 0 ? ['all'] : utility_type,
          vendor: vendor.length === 0 ? ['all'] : vendor,
        },
        usageConfig: {
          ...config,
          unitCostType: undefined,
          adjustmentRate: undefined,
          historicalData:
            historicalDates.length === 0 || areNotNullDates
              ? 'all'
              : 'customRange',
          historicalDates:
            historicalDates.length === 0 || areNotNullDates
              ? []
              : [
                DateTime.fromISO(historicalDates[0]).toJSDate(),
                DateTime.fromISO(historicalDates[1]).toJSDate(),
              ],
        },
        unitCostConfig: {
          unitCostType,
          adjustmentRate: adjustmentRate * 100,
        },
      }
      this.setLoading(false)
      this.isVisible = true
    },
  },
}
</script>
<style lang="scss" scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.no-budgets {
  font-weight: 600;
  margin: 0;
  color: #606266;
}
.action-button {
  color: #0080ff;
  font-size: 14px;
  &:disabled {
    color: #0080ff;
    opacity: 0.5;
  }
  &__delete {
    color: #ff0000;
    &:disabled {
      color: #ff0000;
      opacity: 0.5;
    }
    &:hover {
      color: #d00000;
    }
    &:focus {
      color: #d00000;
    }
  }
}
</style>
