import { hosts } from '@/lib/api.configs'
import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'
import qs from 'qs'
import * as R from 'ramda'
import router from '../router'

/**
 * @description get locations list
 * @param {string} customerId
 * @param {object} params
 */
const listLocations = ({ customerId, params }) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/locations?${qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
    )
    .then(dataMiddleware)

const listHierarchy = ({ customerId }) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/hierarchies/default`)
    .then(dataMiddleware)

const location = (customerId, locationId) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/locations/${locationId}`)
    .then(dataMiddleware)

const unmatchedLocations = (customerId, hierarchyId, params = {}) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/hierarchies/${hierarchyId}/unmatched-locations`,
      { params },
    )
    .then(dataMiddleware)

const virtualAccounts = (customerId, locationId, params) =>
  http(hosts.v3)
    .get(
      `customers/${customerId}/locations/${locationId}/virtual-account-groups`,
      { params },
    )
    .then(dataMiddleware)
    .catch((error) => {
      const status = R.path(['response', 'status'], error)
      if (status === 404) {
        router.history.replace(router.match('/error/404'))
      }
    })

const metrics = (customerId, locationId, commodity, virtualAccountId) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/locations/${locationId}/metrics?${
        commodity ? 'commodity=' + commodity : ''
      }${qs.stringify(virtualAccountId, { arrayFormat: 'brackets' })}`,
    )
    .then(dataMiddleware)
    .then((res) => (res.data ? res : { ...res, data: [] }))

const listBillHistory = ({ customerId, locationId, vaGroupId }) =>
  http(hosts.v1)
    .get(
      `/customers/${customerId}/locations/${locationId}/virtual_account_groups/${vaGroupId}/bills`,
    )
    .then(dataMiddleware)

const getVirtualAccountAttributesList = (customerId, virtualAccountId) =>
  http(hosts.v3)
    .get(`customers/${customerId}/virtual-account-metadata-attributes/list`)
    .then(dataMiddleware)

const getVirtualAccountAttributes = (customerId, virtualAccountId) =>
  http(hosts.v3)
    .get(
      `customers/${customerId}/virtual-account-metadata-attributes/${virtualAccountId}`,
    )
    .then(dataMiddleware)

const getVirtualAccountAttributeValues = (customerId, attributeId, options) =>
  http(hosts.v3)
    .get(
      `customers/${customerId}/locations-metadata/${attributeId}?${qs.stringify(
        options,
        { arrayFormat: 'brackets' },
      )}`,
    )
    .then(dataMiddleware)

export {
  listBillHistory,
  listLocations,
  listHierarchy,
  location,
  unmatchedLocations,
  virtualAccounts,
  metrics,
  getVirtualAccountAttributes,
  getVirtualAccountAttributesList,
  getVirtualAccountAttributeValues,
}
