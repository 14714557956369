<template lang="pug">
  .dashboard
    .navbar-container(:class='{"is-hidden": mobileNavbarIsOpened, opened: showMobileNavbar}')
      navbar(@navbarToggle='navbarToggle')
    .content(:class='{"full-width": mobileNavbarIsOpened}')
      router-view
</template>

<script>
import Navbar from './Navbar.vue'

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      showMobileNavbar: false,
    }
  },
  computed: {
    mobileNavbarIsOpened() {
      if (!this.showMobileNavbar) {
        return (
          this.$deviceInfo.isMobileDevice && this.$deviceInfo.isPortraitMode
        )
      }
    },
  },
  methods: {
    navbarToggle(value) {
      this.showMobileNavbar = value
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../styles/utilities';

.dashboard {
  display: flex;
  height: 100%;
}

.navbar-container {
  flex: none;
  width: 240px;
  transition: width 0.25s, left 0.25s;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100%;

  @include respond-to($tablet-l) {
    position: fixed;
    top: 0;
    left: 0;
    //height: 44px;
    z-index: 10;
  }
  @include respond-to($tablet-p) {
    width: 200px;
  }
  @include respond-to($phone) {
    position: fixed;
    width: calc(100% - 45px);
    z-index: 99;
  }

  &.is-hidden {
    width: 0;
    min-width: 0;
    left: -100%;
    // Rethink this
    @include respond-to($phone-l) {
      left: auto;
      width: 200px;
      min-width: initial;
    }
  }
  @at-root .keyboard-opened & {
    @include respond-to($phone) {
      width: 0;
      min-width: 0;
      left: -100%;
    }
  }
}
.content {
  flex: auto;
  box-sizing: border-box;
  margin-left: 240px;

  @extend %scrollbar;

  &.full-width {
    width: 100%;
  }

  @include respond-to($tablet-l) {
    width: calc(100% - 240px);
  }
  @include respond-to($tablet-p) {
    width: calc(100% - 200px);
    margin-left: 200px;
  }
  @include respond-to($phone) {
    width: 100%;
    padding-top: 75px;
    margin-left: 0;
  }
}
</style>
