<template>
  <validation-observer>
    <el-form :inline="true" label-position="top" class="delivery-options">
      <base-date-picker
        :rules="'required'"
        name="date"
        :picker-options="pickerOptions"
        :label="report.formBody.startDate.label"
        @change="handleRecurringTypes"
        v-model="report.startDate"
        :isEditMode="isEditMode"
        size="medium"
      />
      <base-select
        :rules="'required'"
        :label="report.formBody.recurringType.label"
        v-model="report.recurringType"
        :isEditMode="isEditMode"
        size="medium"
      >
        <el-option v-for="(v, i) in selectableValues" :key="i" :value="v" />
      </base-select>
      <base-select
        filterable
        :rules="'required'"
        :label="report.formBody.selectTime.label"
        v-model="report.time"
        :isEditMode="isEditMode"
        size="medium"
      >
        <el-option
          v-for="(v, i) in report.formBody.selectTime.selectableValues"
          :key="i"
          :value="v"
          :disabled="shouldTimeSlotBeDisabled(v)"
        />
      </base-select>
    </el-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import BaseDatePicker from '@/components/FormComponents/BaseDatePicker'
import InputWithTags from '@/components/FormComponents/InputWithTags'
import DisabledButton from '@/components/Buttons/DisabledButton'
import { DateTime } from 'luxon'
import * as _ from 'lodash'

export default {
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseSelect,
    BaseDatePicker,
    InputWithTags,
    ValidationObserver,
    DisabledButton,
  },
  data() {
    return {
      isEditMode: true,
      selectableValues: [],
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
    }),
    startDate: {
      get() {
        return this.report && this.report.startDate
          ? this.report.startDate
          : DateTime.now().toISODate()
      },
      set(newValue) {
        this.report.startDate = newValue
      },
    },
    time: {
      get() {
        return this.report && this.report.time ? this.report.time : '01:00 PM'
      },
      set(newValue) {
        this.report.time = newValue
      },
    },
    recurringTypeValue: {
      get() {
        return this.report && this.report.recurringType
          ? this.report.recurringType
          : 'Does not repeat'
      },
      set(newValue) {
        this.report.recurringType = newValue
      },
    },
    startDateChange: {
      get() {
        return DateTime.fromISO(this.startDate).toISODate()
      },
      set(newValue) {
        this.startDate = newValue
      },
    },
  },
  watch: {
    opened: {
      immediate: true,
      handler(value) {
        this.handleRecurringTypes(this.report.startDate)
        this.prettifiyReturnedRecurringType()
        this.checkInitialTimeSlot()
      },
    },
    recurringTypeValue: function (newValue) {
      this.checkInitialTimeSlot()
    },
    startDate: function (newValue) {
      this.checkInitialTimeSlot()
    },
  },
  methods: {
    shouldTimeSlotBeDisabled(time) {
      let crtTime = DateTime.now()
      let runTime = DateTime.fromFormat(
        this.startDateChange + ' ' + time,
        'yyyy-LL-dd hh:mm a',
      )
      if (this.recurringTypeValue === 'Does not repeat' && runTime < crtTime) {
        return true
      }
      return false
    },
    checkFirstAvailableTimeSlot() {
      let crtTime = DateTime.now()
      for (let time of this.report.formBody.selectTime.selectableValues) {
        let runTime = DateTime.fromFormat(
          crtTime.toFormat('yyyy-LL-dd') + ' ' + time,
          'yyyy-LL-dd hh:mm a',
        )
        if (runTime >= crtTime) {
          this.time = time
          break
        }
      }
    },
    checkInitialTimeSlot() {
      let crtTime = DateTime.now()
      let runTime = DateTime.fromFormat(
        this.startDateChange + ' ' + this.time,
        'yyyy-LL-dd hh:mm a',
      )
      if (this.recurringTypeValue === 'Does not repeat' && runTime < crtTime) {
        this.checkFirstAvailableTimeSlot()
      }
    },
    prettifiyReturnedRecurringType() {
      const [occurenceType, recurringDate] =
        this.report.recurringType.split(': ')
      if (occurenceType.toLowerCase() === 'once') {
        this.report.recurringType = 'Does not repeat'
      }
      if (occurenceType.toLowerCase() === 'daily') {
        this.report.recurringType = 'Daily'
      }
      if (occurenceType.toLowerCase() === 'weekdays') {
        this.report.recurringType = 'Every weekday'
      }
      if (occurenceType.toLowerCase() === 'weekly') {
        this.report.recurringType = `Weekly on ${recurringDate}`
      }
      if (occurenceType.toLowerCase() === 'monthly') {
        const [recurringWeek, recurringDay] = recurringDate.split('-')
        this.report.recurringType = `Monthly on the ${recurringWeek.toLowerCase()} ${recurringDay}`
      }
      if (
        occurenceType.toLowerCase() === 'anually' ||
        occurenceType.toLowerCase() === 'annually'
      ) {
        this.report.recurringType = `Annually on ${DateTime.fromFormat(
          recurringDate,
          'MMM-d',
        ).toFormat('MMMM dd')}`
      }
    },
    daySuffix(d) {
      if (d > 3 && d < 21) return 'th'
      switch (d % 10) {
        case 1:
          return 'st'
        case 2:
          return 'nd'
        case 3:
          return 'rd'
        default:
          return 'th'
      }
    },
    handleRecurringTypes(date) {
      if (!_.isNil(date)) {
        const day = DateTime.fromISO(date).toFormat('EEEE')
        const dayAsNumber = DateTime.fromISO(date).toFormat('d')
        const month = DateTime.fromISO(date).toFormat('MMMM')
        const weeksOfMonth = ['first', 'second', 'third', 'fourth', 'last']
        let weekInMonth =
          DateTime.fromISO(date).weekNumber -
          DateTime.fromISO(date).startOf('month').weekNumber
        let dayMonth

        dayMonth = `${month} ${dayAsNumber}${this.daySuffix(+dayAsNumber)}`
        const weekNo = weeksOfMonth[weekInMonth] || 'last'
        this.selectableValues = []
        this.selectableValues.push(
          'Does not repeat',
          'Daily',
          'Every weekday',
          `Weekly on ${day}`,
          `Monthly on the ${weekNo} ${day}`,
          `Annually on ${dayMonth}`,
        )
        const recurringTypeArrOfWords = this.recurringTypeValue.split(' ')
        const [prefix] = recurringTypeArrOfWords
        const prefixLowerCase = prefix.toLowerCase()
        switch (prefixLowerCase) {
          case 'weekly':
            this.recurringTypeValue = this.selectableValues[3]
            break
          case 'monthly':
            this.recurringTypeValue = this.selectableValues[4]
            break
          case 'annually':
            this.recurringTypeValue = this.selectableValues[5]
            break
        }
      }
    },
    disabledDate(date) {
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      return date < yesterday
    },
  },
}
</script>
<style lang="scss" scoped>
.delivery-options {
  width: 70%;

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 2em;
  }
  .button {
    font-size: 16px;
    font-weight: 600;
  }
  .customize-button {
    padding: 2em 0;
    font-weight: 600;
  }

  ::v-deep .el-form-item__content {
    width: 250px;
  }
  ::v-deep .el-date-editor {
    width: 250px;
  }
  ::v-deep .pl-12 {
    padding: 0;
  }
}
</style>
