<template lang="pug">
  .bill-basic-card-control.control
    .bill-basic-card
      .basic-card-title {{ messageData.title }}
      .basic-card-subtitle {{ messageData.subtitle }}
    .bill-basic-card-content
      span(class="title-value") {{ messageData.text }}
    .bill-basic-card-footer(v-if="messageData.footer")
      .data
        span(v-for="item in (Array.isArray(messageData.footer) ? messageData.footer : [messageData.footer])") {{ item }}
</template>

<script>
import { sendEventToChatbase } from '../../../api/stats'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  props: {
    messageData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentCustomerId: 'customers/currentCustomerId',
    }),
  },
  methods: {
    sendMessage(message) {
      this.$emit('sendMessage', message)
    },
    scrapeValue(str, fields) {
      const [match, key] = str.match(/\{([^}]+)\}/)
      return str.replace(match, fields[key])
    },
    handleRowClick(row) {
      if (!this.messageData.action) {
        return false
      }

      if (this.messageData.action.type === 'weblink_newtab') {
        sendEventToChatbase(this.currentCustomerId, 'new_tab', 'weblink').catch(
          (err) => {
            console.error('CHATBASE ERROR: ', err)
          },
        )

        window.open(
          this.scrapeValue(this.messageData.action.url, row),
          '_blank',
        )
      } else {
        this.sendMessage(
          this.scrapeValue(this.messageData.action.bubble_text, row),
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../styles/_utilities';
.basic-card-title {
  font-size: 14px;
  line-height: 17px;
  font-weight: 900;
  color: #5d6a89;
  margin-bottom: 10px;
}

.bill-basic-card-control {
  max-width: 240px;
  padding: 10px 20px;
  margin-bottom: 10px;
  background-color: #fff;
  /*box-shadow: 0 2px 6px 0 rgba(110,112,113,0.5);*/
  box-sizing: border-box;
  overflow-x: auto;
  margin-left: 63px;
  border: 1px solid #dde5e6;
  border-radius: 0 16px 16px 16px;

  @include respond-to($phone) {
    margin-left: 0;
  }
}
</style>

<style lang="scss">
@import './../../../styles/_utilities';
.bill-basic-card-control {
  .bill-basic-card-content {
    .title-value {
      font-size: 22px;
      line-height: 22px;
      font-weight: 400;
      color: #3b3e48;
    }
  }

  .bill-basic-card-footer {
    .data {
      margin-top: 2px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      > span {
        width: 100%;
        margin-top: 2px;
        font-size: 10px;
        line-height: 14px;
        font-weight: 400;
        color: #7d89a6;
      }
    }
  }
}
</style>
