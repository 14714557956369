<template>
  <div class="card" v-if="data">
    <div class="header" :class="[!isCurrentMonth ? 'grey-header' : '']">
      <span
        class="icon"
        :class="[data.locationId ? `icon-weather-${data.icon}` : 'no_icon']"
      />
      <span class="date">{{ data.date | datetime('EEE - dd') }}</span>
    </div>
    <div class="content" v-if="isEmptyData">
      <div class="content-flex">
        <span class="field">Min Temp (F)</span>
        <span class="field">{{ data.temperatureMin || 'N/A' }}</span>
      </div>
      <div class="content-flex">
        <span class="field">Max Temp (F)</span>
        <span class="field">{{ data.temperatureMax || 'N/A' }}</span>
      </div>
      <div class="content-flex">
        <span class="field">HDD</span>
        <span class="field">{{ data.heating || 'N/A' }}</span>
      </div>
      <div class="content-flex">
        <span class="field">CDD</span>
        <span class="field">{{ data.cooling || 'N/A' }}</span>
      </div>
    </div>
    <div class="no-content" v-else>
      <h1 class="no-content-body">No Data for this Period</h1>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    currentDate: {
      type: [String, Object],
      default: () => DateTime.local().toUTC().toFormat('yyyy-LL-dd'),
    },
  },
  computed: {
    isCurrentMonth() {
      return (
        DateTime.fromFormat(this.currentDate, 'yyyy-LL-dd').toFormat('LL', {
          zone: 'utc',
        }) ===
        DateTime.fromFormat(this.data.date, 'yyyy-LL-dd').toFormat('LL', {
          zone: 'utc',
        })
      )
    },
    isEmptyData() {
      const { locationId, temperatureMin, temperatureMax, heating, cooling } =
        this.data
      return (
        locationId && (temperatureMin || temperatureMax || heating || cooling)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../../styles/_utilities';

.p-nd {
  padding: 19px 10px;
}
.no_icon {
  display: none;
}
.icon {
  margin-right: 8px;
}

.card {
  width: 13%;
  height: 180px;
  margin-right: 1.5%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  .date {
    font-size: 14px;
  }
  .header {
    display: flex;
    justify-content: center;
    background-color: #0080ff;
    color: #ffffff;
    font-size: 12px;
    line-height: 16px;
    padding: 10px;
  }
  .grey-header {
    background-color: #91939a;
  }
  &::nth-child(7n) {
    margin-right: 0;
  }
  @include respond-to($phone) {
    width: 48%;
    height: 48%;
    margin-right: 1%;
  }
  @include respond-to($phone-l) {
    width: 32%;
    height: 32%;
    margin-right: 1%;
  }
}
.no-content {
  margin: 45px 12px;
  text-align: center;
  .no-content-body {
    font-size: 18px;
    color: grey;
  }
}
.content {
  margin: 16px 12px;
  &-flex {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    .field {
      font-size: 12px;
    }
  }
}
</style>
