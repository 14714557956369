var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.body.lock",value:(!_vm.initialized),expression:"!initialized",modifiers:{"fullscreen":true,"body":true,"lock":true}}],staticClass:"navbar-inner"},[_c('div',{staticClass:"navbar-mobile",class:{
    'is-hidden': !_vm.$deviceInfo.isMobileDevice && _vm.$deviceInfo.isPortraitMode,
    'is-opened': _vm.showMobileFilter,
  }},[(!_vm.showMobileFilter &&
      !(
        _vm.$route.name === 'dashboard.bills.info' ||
        _vm.$route.name === 'dashboard.locations.info' ||
        _vm.$route.name === 'dashboard.widgets.details'
      )
      )?_c('el-button',{staticClass:"navbar-toggle",on:{"click":_vm.openOnMobile}},[_c('span'),_c('span'),_c('span')]):_vm._e(),(_vm.$route.name === 'dashboard.bills.info' ||
      _vm.$route.name === 'dashboard.locations.info' ||
      _vm.$route.name === 'dashboard.widgets.details'
      )?_c('button',{staticClass:"button-go-back mobile",on:{"click":_vm.goBack}},[_c('i',{staticClass:"icon icon-back-white"})]):_vm._e(),(!_vm.showMobileFilter)?_c('div',{staticClass:"navbar-mobile_title"},[(_vm.$route.meta.title === 'pear.ai')?[_c('div',{staticClass:"icon icon-logo-text-only"})]:[_vm._v(_vm._s(_vm.$route.meta.title))]],2):_vm._e(),_c('div',{staticClass:"navbar-mobile_customer_info"},[_c('span',[_vm._v(_vm._s(_vm.customerName))])]),(_vm.$route.name === 'dashboard.bill' ||
      _vm.$route.name === 'dashboard.locations' ||
      _vm.$route.name === 'dashboard.payments' ||
      _vm.isReportsList
      )?_c('el-button',{staticClass:"toggle-search",attrs:{"type":"text"},on:{"click":_vm.toggleMobileFilter}},[(!_vm.showMobileFilter)?_c('i',{staticClass:"icon icon-filters-m"}):_c('i',{staticClass:"el-icon-close"})]):_vm._e(),(_vm.showMobileFilter && _vm.$route.name === 'dashboard.bills')?_c('bills-filter-mobile-container',{on:{"close":_vm.closeMobileFilter}}):_vm._e(),(_vm.showMobileFilter && _vm.$route.name === 'dashboard.payments')?_c('payments-filter-mobile-container',{on:{"close":_vm.closeMobileFilter}}):_vm._e(),(_vm.showMobileFilter && _vm.$route.name === 'dashboard.locations')?_c('locations-filter-mobile-container',{on:{"close":_vm.closeMobileFilter}}):_vm._e(),(_vm.showMobileFilter && _vm.isReportsList)?_c('reports-filter-mobile-container',{on:{"close":_vm.closeMobileFilter}}):_vm._e(),(_vm.$deviceInfo.isMobileDevice &&
      _vm.$deviceInfo.isPortraitMode &&
      _vm.$route.name === 'dashboard.bills.info'
      )?_c('a',{staticClass:"donwload-bill-mobile",attrs:{"href":'#',"download":""},on:{"click":function($event){$event.preventDefault();return _vm.handleDownloadBill()}}},[_c('i',{staticClass:"icon icon-download"})]):_vm._e()],1),_c('div',{staticClass:"navbar-wrapper",class:{ closed: !_vm.showMobileNavbar }},[_c('div',{staticClass:"logo",class:{ mobile: _vm.$deviceInfo.isMobileDevice }},[_c('img',{attrs:{"src":require("./../../assets/logo-navbar-large-white.svg")}})]),_c('div',{staticClass:"customers-list"},[_c('el-dropdown',{attrs:{"trigger":"click","placement":"bottom"},on:{"command":_vm.selectCustomer,"visible-change":_vm.updateCustomers}},[_c('div',{staticClass:"customer-name"},[_c('span',[_vm._v(_vm._s(_vm.customer ? _vm.customer.name : ''))]),_c('i',{staticClass:"el-icon-arrow-down"})]),_c('el-dropdown-menu',{staticClass:"customers-dropdown",attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.sortAZ(_vm.customers)),function(c){return _c('el-dropdown-item',{key:c.id,staticClass:"customers-dropdown-item",class:{ selected: c.id === _vm.customerId },attrs:{"command":c.id},nativeOn:{"click":function($event){return _vm.fetchUserData(c.id)}}},[_vm._v(" "+_vm._s(c.name)+" ")])}),1)],1)],1),_c('el-menu',{staticClass:"navbar",attrs:{"router":true,"default-active":this.activeNavbarItem}},[_vm._l((_vm.enabledMenuItems),function(menuItem){return [_c('el-menu-item',{key:menuItem.index,attrs:{"data-action":'go-to-' + menuItem.index.replace('.', '-'),"route":menuItem.route,"index":menuItem.index},nativeOn:{"click":function($event){return _vm.closeOnMobile.apply(null, arguments)}}},[_c('i',{class:'icon ' + menuItem.icon}),_c('span',{domProps:{"innerHTML":_vm._s(menuItem.name)}})]),_vm._l((menuItem.children),function(subMenu){return [(_vm.activeNavbarItem === menuItem.index ||
            _vm.activeNavbarItem === subMenu.index
            )?_c('el-menu-item',{key:subMenu.index,staticClass:"el-menu-item-location-hierarchies",attrs:{"index":subMenu.index,"route":subMenu.route}},[_c('router-link',{staticClass:"menu-item sub-menu-item",attrs:{"to":subMenu.route},nativeOn:{"click":function($event){return _vm.closeOnMobile.apply(null, arguments)}}},[_vm._v(" "+_vm._s(subMenu.name)+" ")])],1):_vm._e()]})]})],2),_c('el-button',{class:['switch-apps-btn', {'margin-bottom': !_vm.constellationCustomer}],on:{"click":_vm.swithApp}},[_vm._v(" Go to New App ")]),(_vm.constellationCustomer)?_c('div',{staticClass:"constellation-logo"},[_c('span',[_vm._v("Brought to you by")]),_vm._m(0)]):_vm._e(),_c('user-menu',{on:{"close":_vm.closeOnMobile}})],1),_c('el-button',{staticClass:"button-close",class:{ 'is-hidden': !_vm.showMobileNavbar },on:{"click":_vm.closeOnMobile}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("./../../assets/constellation_logo.svg")}})])}]

export { render, staticRenderFns }