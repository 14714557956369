<template>
  <div class="customize-date-container" v-if="baseFilters != null">
    <BaseSelect
      class="selector"
      :ordered="true"
      :isEditMode="true"
      size="medium"
      :label="label"
      name="Date Range"
      v-model="baseFilters.selectVal"
      @change="handleChange"
      immediate
    >
      <el-option
        v-for="v in dateRanges"
        :key="v.key"
        :value="v.name"
        :label="v.name"
      />
    </BaseSelect>
    <BaseInput
      v-if="baseFilters.selectVal === 'Day Of Report Being Run'"
      v-model.number="baseFilters.noMonths"
      type="number"
      label="Number Of Months:"
      name="city"
      size="medium"
      :isEditMode="true"
      @change="datePicked(true)"
    />
    <BaseDatePicker
      v-if="baseFilters.pickerVisible && !baseFilters.partialDate"
      v-model="baseFilters.dateRange"
      name="dateRange"
      classes="date-selector"
      :picker-options="pickerOptions"
      size="medium"
      :type="
        reportType === 'CUSTOMER_BILLING_LINE_ITEMS'
          ? 'daterange'
          : 'monthrange'
      "
      :format="
        reportType === 'CUSTOMER_BILLING_LINE_ITEMS' ? 'MM/dd/yyyy' : 'MM/yyyy'
      "
      :onPick="datePicked"
      :default-value="
        reportType === 'CUSTOMER_BILLING_LINE_ITEMS'
          ? new Date()
          : new Date().getFullYear() - 1 + '-01'
      "
      :start-placeholder="
        reportType === 'CUSTOMER_BILLING_LINE_ITEMS'
          ? 'Start day'
          : 'Start month'
      "
      :end-placeholder="
        reportType === 'CUSTOMER_BILLING_LINE_ITEMS' ? 'End day' : 'End month'
      "
      :isEditMode="true"
    />
    <BaseDatePicker
      v-show="baseFilters.partialDate"
      v-model="baseFilters.date"
      classes="date-selector"
      size="medium"
      :onPick="datePicked"
      :isEditMode="true"
    />
  </div>
</template>

<script>
import BaseDatePicker from '@/components/FormComponents/BaseDatePicker'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import { DateTime } from 'luxon'
import BaseInput from '@/components/FormComponents/BaseInput'

export default {
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    filtersKey: {
      type: String,
      default: '',
    },
    filterType: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: 'All Time',
    },
    reportType: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Date Range',
    },
  },
  components: {
    BaseInput,
    BaseDatePicker,
    BaseSelect,
  },
  data() {
    return {
      localFilters: null,
      pickerOptions: {
        disabledDate(date) {
          return date > new Date()
        },
      },
    }
  },
  computed: {
    dateRanges() {
      return [
        { name: 'All Time' },
        { name: 'Custom Range' },
        { name: 'Last Full Calendar Month' },
        ...(this.filterType === 'date_range_partial'
          ? [{ name: 'Day Of Report Being Run' }]
          : []),
      ]
    },
    baseFilters: {
      get() {
        return !this.filtersKey ? this.filters : this.localFilters
      },
      set(value) {
        !this.filtersKey
          ? (this.filters = value)
          : (this.filters[this.filtersKey] = this.localFilters)
      },
    },
    getStartDateKey() {
      return this.filtersKey ? 'min' : 'start_date'
    },
    getEndDateKey() {
      return this.filtersKey ? 'max' : 'end_date'
    },
  },
  watch: {
    filters: {
      handler(value) {
        if (this.filtersKey) {
          if (!value[this.filtersKey]) {
            this.$emit('updateFilters', {
              key: this.filtersKey,
              value: {
                pickerVisible: false,
                partialDate: false,
                selectVal: 'All Time',
                noMonths: 0,
                dateRange: [],
              },
            })
          } else {
            this.localFilters = this.filters[this.filtersKey]
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleChange() {
      delete this.baseFilters.start_date
      delete this.baseFilters.end_date
      delete this.baseFilters.min
      delete this.baseFilters.max
      delete this.baseFilters.dateRange
      delete this.baseFilters.pickerVisible
      delete this.baseFilters.partialDate
      delete this.baseFilters.date
      delete this.baseFilters.noMonths
      if (this.baseFilters.selectVal === 'Custom Range') {
        this.baseFilters.dateRange = []
        this.baseFilters.pickerVisible = true
      } else if (this.baseFilters.selectVal === 'Last Full Calendar Month') {
        let date = new Date()
        this.baseFilters[this.getStartDateKey] = DateTime.fromJSDate(date)
          .minus({ months: 1 })
          .startOf('month')
          .toISO()
        this.baseFilters[this.getEndDateKey] = DateTime.fromJSDate(date)
          .minus({ months: 1 })
          .endOf('month')
          .toISO()
      } else if (this.baseFilters.selectVal === 'Day Of Report Being Run') {
        this.baseFilters.dateRange = []
        this.baseFilters.pickerVisible = true
        this.baseFilters.partialDate = true
        let date = new Date()
        this.baseFilters[this.getStartDateKey] = DateTime.fromJSDate(date)
          .minus({ months: 1 })
          .startOf('month')
          .toISO()
        this.baseFilters[this.getEndDateKey] = DateTime.fromJSDate(date)
          .minus({ months: 1 })
          .endOf('month')
          .toISO()
      }
    },
    datePicked(fromMonths = false) {
      if (this.baseFilters.date || fromMonths) {
        if (!this.baseFilters.date && fromMonths) {
          this.baseFilters.date = DateTime.fromJSDate(new Date()).toISO()
        }
        if (
          this.baseFilters.selectVal === 'Day Of Report Being Run' &&
          ['due_date', 'statement_date'].indexOf(this.filtersKey) !== -1 &&
          this.reportType === 'CUSTOMER_BILL_DETAILS'
        ) {
          if (this.filtersKey === 'due_date') {
            this.baseFilters[this.getStartDateKey] = DateTime.fromISO(
              this.baseFilters.date,
            ).toISO()
            this.baseFilters[this.getEndDateKey] = DateTime.fromISO(
              this.baseFilters.date,
            )
              .plus({ months: this.baseFilters.noMonths })
              .toISO()
          } else if (this.filtersKey === 'statement_date') {
            this.baseFilters[this.getEndDateKey] = DateTime.fromISO(
              this.baseFilters.date,
            ).toISO()
            this.baseFilters[this.getStartDateKey] = DateTime.fromISO(
              this.baseFilters.date,
            )
              .minus({ months: this.baseFilters.noMonths })
              .toISO()
          }
        } else if (
          this.baseFilters.selectVal === 'Day Of Report Being Run' &&
          (this.reportType === 'CUSTOMER_BILLING_LINE_ITEMS' ||
            this.reportType === 'CUSTOMER_BILL_DETAILS_PLUS_LOCATION')
        ) {
          this.baseFilters[this.getEndDateKey] = DateTime.fromISO(
            this.baseFilters.date,
          ).toISO()
          this.baseFilters[this.getStartDateKey] = DateTime.fromISO(
            this.baseFilters.date,
          )
            .minus({ months: this.baseFilters.noMonths })
            .toISO()
        } else {
          this.baseFilters[this.getStartDateKey] = DateTime.fromISO(
            this.baseFilters.date,
          ).toISO()
          this.baseFilters[this.getEndDateKey] = DateTime.fromISO(
            this.baseFilters.date,
          )
            .plus({ months: this.baseFilters.noMonths })
            .toISO()
        }
      }
      if (this.baseFilters.dateRange && this.baseFilters.dateRange[0]) {
        this.baseFilters[this.getStartDateKey] = DateTime.fromISO(
          this.baseFilters.dateRange[0],
        )
          .startOf('day')
          .toISO()
      }
      if (this.baseFilters.dateRange && this.baseFilters.dateRange[1]) {
        if (this.reportType === 'CUSTOMER_BILLING_LINE_ITEMS') {
          this.baseFilters[this.getEndDateKey] = DateTime.fromISO(
            this.baseFilters.dateRange[1],
          )
            .endOf('day')
            .toISO()
        } else {
          this.baseFilters[this.getEndDateKey] = DateTime.fromISO(
            this.baseFilters.dateRange[1],
          )
            .endOf('month')
            .endOf('day')
            .toISO()
        }
      }
    },
  },
}
</script>
<style lang="scss">
.date-selector {
  margin: 10px 0 0 0;
  width: 280px;
}
.selector {
  padding-right: 10px;
}
.customize-filters-tab {
  .customize-date-container {
    .el-form-item__content {
      .form-item {
        .form-item-input {
          .el-input {
            width: auto;
            max-width: 100px;
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
.customize-date-container {
  display: flex;
}
</style>
