<template>
  <BaseModal
    :dialogVisible="showModal"
    :title="title"
    :width="'75%'"
    @close="close"
    @open="open"
    id="createPropertyUseModal"
    class="create-property-uses-base-modal"
  >
    <template #content>
      <ErrorCard :statusMessage="errorMessage" />
      <div class="content">
        {{
          propertyUsesObj.propertyUseDescription || 'No description provided'
        }}
      </div>
      <ValidationObserver ref="observer">
        <el-form label-position="left">
          <div class="name-input">
            <BaseInput
              :isEditMode="true"
              size="large"
              name="Name"
              label="Name:"
              v-model="propertyUseName"
              :rules="'required'"
            />
          </div>
          <el-table :data="propertyUsesObj.propertyUseAttributes">
            <el-table-column prop="attribute_label" label="Property Use Detail">
              <template slot-scope="scope">
                <i
                  :class="[
                    scope.row.required_es_rating === 'true'
                      ? 'el-icon-star-on'
                      : '',
                  ]"
                />
                <label>{{ scope.row.attribute_label }}</label>
              </template>
            </el-table-column>
            <el-table-column
              prop="attribute_allowable_values"
              label="Value"
              width="450"
            >
              <template slot-scope="scope">
                <div class="value-column">
                  <div
                    class="input-width"
                    v-if="scope.row.attribute_value_type === 'enum'"
                  >
                    <BaseSelect
                      :isEditMode="true"
                      size="large"
                      :name="scope.row.attribute_label"
                      immediate
                      :labelWidth="'0'"
                      :rules="scope.row.mandatory === 'true' ? 'required' : ''"
                      v-model="scope.row.model"
                    >
                      <el-option
                        v-for="(v, i) in scope.row.attribute_allowable_values"
                        :key="i"
                        :value="v"
                        :label="v"
                      />
                    </BaseSelect>
                  </div>
                  <div class="input-width" v-else>
                    <BaseInput
                      :isEditMode="true"
                      size="large"
                      immediate
                      :name="scope.row.attribute_label"
                      :labelWidth="'0'"
                      maxlength="13"
                      v-model="scope.row.model"
                      :rules="rules(scope)"
                    />
                  </div>
                  <div class="attrs-value">
                    <div
                      class="attrs-value-uom"
                      v-if="scope.row.attribute_uom_values.length > 0"
                    >
                      <BaseSelect
                        :isEditMode="true"
                        size="large"
                        :labelWidth="'0'"
                        immediate
                        name="units"
                        v-model="scope.row.selected_uom_value"
                      >
                        <el-option
                          v-for="(v, i) in scope.row.attribute_uom_values"
                          :key="i"
                          :value="v"
                          :label="v"
                        />
                      </BaseSelect>
                    </div>
                    <div
                      class="set_default"
                      v-if="scope.row.optional_default.type !== 'none'"
                    >
                      <BaseCheckbox
                        :isEditMode="true"
                        :labelWidth="'0'"
                        immediate
                        v-model="scope.row.attribute_default"
                        :labelCheckbox="'Use a default'"
                        @change="defaultValue($event, scope)"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Current As Of">
              <template slot-scope="scope">
                <div class="date-padding">
                  <BaseDatePicker
                    size="large"
                    v-model="scope.row.attribute_date"
                    immediate
                    name="date"
                    :isEditMode="true"
                    :labelWidth="'0'"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="optional_default.static_value"
              label="Temporary Value?"
            >
              <template slot-scope="scope">
                <BaseCheckbox
                  v-model="scope.row.temporary_value"
                  immediate
                  :isEditMode="true"
                  :labelWidth="'0'"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </ValidationObserver>
      <div class="legend">
        <i class="el-icon-star-on legend-star" />
        <p class="legend-text">
          This Use Detail is used to calculate the 1-100 ENERGY STAR® score
        </p>
      </div>
      <p v-if="isUpdateMode" class="legend-text legend-no-star">
        You must update the "Current As Of" date when making an update to a
        Property Use detail.
      </p>
    </template>
    <template #footer>
      <el-button class="button-size plain-button" @click="close">
        Cancel
      </el-button>
      <el-button
        class="button-size"
        type="primary"
        @click="createOrUpdatePropertyUse"
      >
        {{ isUpdateMode ? 'Apply Changes' : 'Create Property Use' }}
      </el-button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import BaseInput from '@/components/FormComponents/BaseInput'
import BaseCheckbox from '@/components/FormComponents/BaseCheckbox'
import BaseDatePicker from '@/components/FormComponents/BaseDatePicker'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import ErrorCard from '@/components/Cards/ErrorCard'
import { energystar } from '@/api'
import { isNumber, isNaN, cloneDeep } from 'lodash'
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    BaseModal,
    BaseInput,
    BaseCheckbox,
    BaseDatePicker,
    BaseSelect,
    ErrorCard,
    ValidationObserver,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    propertyUseSelectedObj: {
      type: Object,
      default: () => {},
    },

    propertyName: {
      type: String,
      default: '',
    },
    propertyYearBuilt: {
      type: String,
      default: '',
    },
    propertyUse: {
      type: Object,
      default: () => {},
    },
    propertyUsesArr: {
      type: Array,
      default: () => {},
    },
    propertyId: {
      type: Number,
      default: 0,
    },
    isUpdateMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: '',
      showModal: false,
      errorMessage: '',
      propertyUsesObj: {},
      propertyUsesCopy: {},
      body: {},
      meters: [
        { value: 'Sq. Ft.', label: 'Square Feet' },
        { value: 'Sq. M.', label: 'Square Meters' },
        { value: 'Ft.', label: 'Feet' },
        { value: 'M.', label: 'Meters' },
        { value: 'Pounds', label: 'pounds' },
        { value: 'Kilograms', label: 'Kilogram' },
        { value: 'Short Tons', label: 'short tons' },
      ],
      propertyUseName: '',
      squares: ['Sq. Ft.', 'Sq. M.'],
      noSquares: ['Ft.', 'M.'],
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  methods: {
    close() {
      this.$emit('close')
      this.propertyUseName = ''
      this.propertyUsesObj = {}
      this.errorMessage = ''
      this.$refs.observer.reset()
    },
    createOrUpdatePropertyUse() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          if (this.isUpdateMode) {
            this.updatePropertyUse()
          } else {
            this.createPropertyUse()
          }
        }
      })
    },
    rules(scope) {
      if (scope.row.attribute_name === 'weeklyOperatingHours') { return 'decimal|min_value:0|max_value:168' }
      if (
        scope.row.mandatory === 'true' &&
        scope.row.attribute_value_type === 'number'
      ) { return 'required|decimal' }
      if (scope.row.mandatory === 'true') return 'required'
      if (scope.row.attribute_value_type === 'number') return 'decimal'
      return ''
    },
    open() {
      if (this.isUpdateMode) {
        this.propertyUsesObj = this.propertyUsesArr.find(
          (propertyUse) =>
            propertyUse.propertyUseTypeName === this.propertyUse.type,
        )
        this.propertyUseName = this.propertyUse.name
        this.title = `Edit Property Use for ${this.propertyName}`
        this.propertyUsesObj.propertyUseAttributes.map((attribute, i) => {
          const uomValueFinder = this.meters.find((meter) =>
            this.propertyUse.useDetails[i] &&
            meter.label === this.propertyUse.useDetails[i].units
              ? meter
              : '',
          )
          attribute.attribute_date =
            this.propertyUse.useDetails[i] &&
            this.propertyUse.useDetails[i].currentAsOf
          attribute.attribute_default =
            this.propertyUse.useDetails[i] &&
            this.propertyUse.useDetails[i].default === 'Yes'
          attribute.temporary_value =
            this.propertyUse.useDetails[i] &&
            this.propertyUse.useDetails[i].temporary === 'Yes'
          attribute.model =
            this.propertyUse.useDetails[i] &&
            this.propertyUse.useDetails[i].value
          attribute.selected_uom_value = uomValueFinder && uomValueFinder.value
        })
      } else {
        this.title = `Add Property Use for ${this.propertyName}`
        this.propertyUsesObj = this.propertyUseSelectedObj
        this.propertyUsesObj.propertyUseAttributes.map((attribute) => {
          attribute.attribute_date = `${this.propertyYearBuilt || '1970'}-01-01`
          attribute.attribute_default = false
          attribute.temporary_value = false
          attribute.model = ''
          attribute.selected_uom_value = attribute.attribute_uom_values[0]
        })
      }
      this.propertyUsesCopy = cloneDeep(this.propertyUsesObj)
    },
    async createPropertyUse() {
      const { customerId } = this.$route.params
      this.bodyConstructor()
      try {
        await energystar.postPropertyUses({
          customerId,
          propertyId: this.propertyId,
          body: this.body,
        })
        this.errorMessage = ''
        this.$emit('refresh-data')
        this.close()
      } catch (e) {
        this.errorMessage = e.response.data.error
      }
    },
    async updatePropertyUse() {
      const { customerId } = this.$route.params
      this.bodyConstructor()
      try {
        await energystar.putPropertyUses({
          customerId,
          propertyId: this.propertyId,
          propertyUseId: this.propertyUse.id,
          body: this.body,
        })
        this.errorMessage = ''
        this.$emit('refresh-data')
        this.close()
      } catch (e) {
        this.errorMessage = e.response.data.error
      }
    },
    defaultValue(e, scope) {
      if (e && scope.row.optional_default.type === 'multiplier') {
        const multiplier = this.propertyUsesObj.propertyUseAttributes.find(
          (attribute) =>
            attribute.attribute_name ===
            scope.row.optional_default.multipler_attribute,
        )
        if (scope.row.optional_default.multiplier_value && multiplier.model) {
          const multiplierValue =
            parseInt(multiplier.model) *
            parseFloat(scope.row.optional_default.multiplier_value)
          scope.row.model = multiplierValue
          this.$set(
            this.propertyUsesObj.propertyUseAttributes,
            scope.row.attribute_name,
            scope.row,
          )
        } else {
          scope.row.model = 0
          this.$set(
            this.propertyUsesObj.propertyUseAttributes,
            scope.row.attribute_name,
            scope.row,
          )
        }
      }
      if (e && scope.row.optional_default.type === 'static') {
        scope.row.model = scope.row.optional_default.static_value
        this.$set(
          this.propertyUsesObj.propertyUseAttributes,
          scope.row.attribute_name,
          scope.row,
        )
      }
      if (!e) {
        scope.row.model = ''
        this.$delete(
          this.propertyUsesObj.propertyUseAttributes,
          scope.row.attribute_name,
          scope.row,
        )
      }
    },
    bodyConstructor() {
      const useDetails = {}
      this.propertyUsesObj.propertyUseAttributes.forEach((attribute, i) => {
        if (
          Object.entries(attribute).toString() !==
          Object.entries(
            this.propertyUsesCopy.propertyUseAttributes[i],
          ).toString()
        ) {
          const meterFinder = this.meters.find((meter) =>
            meter.value === attribute.selected_uom_value ? meter : '',
          )
          if (attribute.model) {
            useDetails[attribute.attribute_name] = {
              currentAsOf: attribute.attribute_date,
              temporary: attribute.temporary_value,
              default:
                attribute.attribute_default ||
                attribute.attribute_default === 'Yes'
                  ? 'Yes'
                  : 'No',
              value:
                isNumber(+attribute.model) && !isNaN(+attribute.model)
                  ? +attribute.model
                  : attribute.model,
              units:
                meterFinder && meterFinder.label
                  ? meterFinder.label
                  : undefined,
            }
          }
        }
      })
      this.body = {
        type: !this.isUpdateMode
          ? this.propertyUsesObj.propertyUseTypeName
          : undefined,
        name: this.propertyUseName,
        useDetails,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.create-property-uses-base-modal {
  .button-size {
    width: 35%;
  }
  .plain-button {
    color: #2893f9;
    border-color: #2893f9;
  }
  ::v-deep .el-table {
    td {
      cursor: auto;
      border-bottom: 0;
      color: #0080ff;
      font-weight: 600;
    }
    .cell {
      word-break: break-word;
    }
    th {
      border-bottom: 0;
      color: #000000;
    }
    &::before {
      height: 0;
    }
  }
  .value-column {
    display: inline-flex;
    width: 100%;
    ::v-deep .pl-12 {
      padding: 0;
    }
  }
  .content {
    color: #909399;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ebeef5;
  }
  .name-input {
    width: 400px;
    margin-bottom: 2rem;
    padding-left: 15px;
  }
  ::v-deep .pl-12 {
    padding: 0;
  }
  .set_default {
    width: 100px;
    padding-left: 12px;
  }
  .attrs-value {
    display: inline-flex;
    &-uom {
      width: 100px;
      margin-left: 12px;
    }
  }
  .input-width {
    width: 180px;
  }
  .date-padding {
    padding-top: 16px;
  }
  .legend {
    display: inline-flex;
    border-top: 1px solid #ebeef5;
    padding: 15px 0 0 0;
    width: 100%;
    &-star {
      color: #2893f9;
      padding-right: 10px;
    }
    &-text {
      color: #3b3e48;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      padding-bottom: 1rem;
      margin: 0 0;
    }
    &-no-star {
      padding-left: 24px;
    }
  }
}
</style>
