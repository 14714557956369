<template>
  <BaseModal
    :dialogVisible="showModal"
    @close="close"
    :width="width"
    id="energyStarAutoMetersModal"
  >
    <template #content>
      <ErrorCard :statusMessage="createError" />
      <p class="message">
        You haven’t set up any meters for the ENERGY STAR® property at this
        location. Would you like Pear.ai to automatically setup ENERGY STAR®
        meters and map Pear.ai virtual accounts for you? (You will be able to
        edit the configuration after initial setup).
      </p>
    </template>
    <template #footer class="dialog-footer-center">
      <el-button
        class="button-size plain-button"
        id="energyStartAutoMetersNo"
        @click="openMetersModal"
      >
        No, don’t set up ENERGY STAR® Meters
      </el-button>
      <el-button
        class="button-size"
        type="primary"
        id="energyStartAutoMetersYes"
        @click="setupAutoMeters"
        :loading="isLoading"
      >
        Yes, automatically set up ENERGY STAR® Meters
      </el-button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import ErrorCard from '@/components/Cards/ErrorCard'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    customerFDGCode: {
      type: String,
    },
    width: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    createError: {
      type: String,
      default: '',
    },
  },
  components: {
    BaseModal,
    ErrorCard,
  },
  data() {
    return {
      showModal: false,
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    openMetersModal() {
      this.$emit('open-meters')
    },
    setupAutoMeters() {
      this.$emit('setup-auto-meters')
    },
  },
}
</script>

<style lang="scss" scoped>
.button-size {
  width: 45%;
}
.plain-button {
  color: #2893f9;
  border-color: #2893f9;
}
.message {
  margin: 0;
  text-align: center;
  word-break: break-word;
}
</style>
