<template>
  <div class="navbar-inner" v-loading.fullscreen.body.lock="!initialized">
    <div class="navbar-mobile" :class="{
      'is-hidden': !$deviceInfo.isMobileDevice && $deviceInfo.isPortraitMode,
      'is-opened': showMobileFilter,
    }">
      <el-button class="navbar-toggle" v-if="!showMobileFilter &&
        !(
          $route.name === 'dashboard.bills.info' ||
          $route.name === 'dashboard.locations.info' ||
          $route.name === 'dashboard.widgets.details'
        )
        " @click="openOnMobile">
        <span></span>
        <span></span>
        <span></span>
      </el-button>
      <button class="button-go-back mobile" v-if="$route.name === 'dashboard.bills.info' ||
        $route.name === 'dashboard.locations.info' ||
        $route.name === 'dashboard.widgets.details'
        " @click="goBack">
        <i class="icon icon-back-white"></i>
      </button>
      <div class="navbar-mobile_title" v-if="!showMobileFilter">
        <template v-if="$route.meta.title === 'pear.ai'">
          <div class="icon icon-logo-text-only"></div>
        </template>
        <template v-else>{{ $route.meta.title }}</template>
      </div>
      <div class="navbar-mobile_customer_info">
        <span>{{ customerName }}</span>
      </div>
      <el-button class="toggle-search" v-if="$route.name === 'dashboard.bill' ||
        $route.name === 'dashboard.locations' ||
        $route.name === 'dashboard.payments' ||
        isReportsList
        " type="text" @click="toggleMobileFilter">
        <i class="icon icon-filters-m" v-if="!showMobileFilter"></i>
        <i class="el-icon-close" v-else></i>
      </el-button>
      <bills-filter-mobile-container v-if="showMobileFilter && $route.name === 'dashboard.bills'"
        @close="closeMobileFilter"></bills-filter-mobile-container>
      <payments-filter-mobile-container v-if="showMobileFilter && $route.name === 'dashboard.payments'"
        @close="closeMobileFilter"></payments-filter-mobile-container>
      <locations-filter-mobile-container v-if="showMobileFilter && $route.name === 'dashboard.locations'"
        @close="closeMobileFilter"></locations-filter-mobile-container>
      <reports-filter-mobile-container v-if="showMobileFilter && isReportsList"
        @close="closeMobileFilter"></reports-filter-mobile-container>
      <a class="donwload-bill-mobile" v-if="$deviceInfo.isMobileDevice &&
        $deviceInfo.isPortraitMode &&
        $route.name === 'dashboard.bills.info'
        " :href="'#'" @click.prevent="handleDownloadBill()" download>
        <i class="icon icon-download"></i>
      </a>
    </div>
    <div class="navbar-wrapper" :class="{ closed: !showMobileNavbar }">
      <div class="logo" :class="{ mobile: $deviceInfo.isMobileDevice }">
        <img src="./../../assets/logo-navbar-large-white.svg" />
      </div>
      <div class="customers-list">
        <el-dropdown trigger="click" @command="selectCustomer" placement="bottom" @visible-change="updateCustomers">
          <div class="customer-name">
            <span>{{ customer ? customer.name : '' }}</span>
            <i class="el-icon-arrow-down"></i>
          </div>
          <el-dropdown-menu class="customers-dropdown" slot="dropdown">
            <el-dropdown-item class="customers-dropdown-item" v-for="c in sortAZ(customers)" :key="c.id" :command="c.id"
              @click.native="fetchUserData(c.id)" :class="{ selected: c.id === customerId }">
              {{ c.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-menu class="navbar" :router="true" :default-active="this.activeNavbarItem">
        <template v-for="menuItem in enabledMenuItems">
          <el-menu-item :key="menuItem.index" :data-action="'go-to-' + menuItem.index.replace('.', '-')"
            :route="menuItem.route" :index="menuItem.index" @click.native="closeOnMobile">
            <i :class="'icon ' + menuItem.icon"></i>
            <span v-html="menuItem.name"></span>
          </el-menu-item>
          <template v-for="subMenu in menuItem.children">
            <el-menu-item v-if="activeNavbarItem === menuItem.index ||
              activeNavbarItem === subMenu.index
              " :key="subMenu.index" :index="subMenu.index" :route="subMenu.route"
              class="el-menu-item-location-hierarchies">
              <router-link class="menu-item sub-menu-item" :to="subMenu.route" @click.native="closeOnMobile">
                {{ subMenu.name }}
              </router-link>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
      <el-button :class="['switch-apps-btn', {'margin-bottom': !constellationCustomer}]" @click="swithApp">
        Go to New App
      </el-button>
      <div class="constellation-logo" v-if="constellationCustomer">
        <span>Brought to you by</span>
        <a href="/">
          <img src="./../../assets/constellation_logo.svg" />
        </a>
      </div>
      <user-menu @close="closeOnMobile"></user-menu>
    </div>
    <el-button class="button-close" :class="{ 'is-hidden': !showMobileNavbar }" @click="closeOnMobile"></el-button>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { find, isEmpty, path, pathOr, prop, propEq, sortBy } from 'ramda'
import { hosts } from '@/lib/api.configs'
import { bills } from '@/api'
import BillsFilterMobileContainer from './Bills/Filters/FilterMobileContainer.vue'
import PaymentsFilterMobileContainer from './Payments/FilterMobileContainer.vue'
import LocationsFilterMobileContainer from './Locations/Filters/FilterMobileContainer.vue'
import UserMenu from './UserMenu'
import { getCustomerCarbonFootprint } from '@/api/customer'

export default {
  components: {
    BillsFilterMobileContainer,
    PaymentsFilterMobileContainer,
    LocationsFilterMobileContainer,
    UserMenu,
  },
  data() {
    return {
      initialized: false,
      user: JSON.parse(localStorage.getItem('user')) || {},
      showMobileNavbar: false,
      showMobileFilter: false,
      API_HOST: hosts.api,
      menuItems: [
        { name: 'Home', icon: 'icon-home', index: 'dashboard.home' },
        {
          name: 'Portfolio View',
          icon: 'icon-widgets',
          index: 'dashboard.widgets',
        },
        { name: 'Bills', icon: 'icon-bill', index: 'dashboard.bills' },
        {
          name: 'Sam',
          icon: 'icon-sam',
          index: 'dashboard.sam',
          feature: 'sam',
        },
        {
          name: 'Locations',
          icon: 'icon-locations',
          index: 'dashboard.locations',
          children: [
            {
              name: 'Hierarchy',
              index: 'dashboard.locations.hierarchy',
              showCondition: 'hasHierarchy',
            },
          ],
        },
        { name: 'Reports', icon: 'icon-reports', index: 'dashboard.reports' },
        {
          name: 'Analytics',
          icon: 'icon-powerbi',
          index: 'dashboard.analytics',
          feature: 'analytics',
          children: [
            {
              name: 'Change Analytic Report',
              index: 'dashboard.analytics.changeReportModal',
              color: '',
              svgColor: '',
              entitlement: 'powerBi',
              showCondition: 'isPowerBIEntitlement',
            },
          ],
        },
        {
          name: 'Sustainability',
          icon: 'icon-sustainability',
          index: 'dashboard.sustainability',
          entitlement: 'carbonFootprint',
          showCondition: 'hasCarbonFootprintEntitlement',
        },
        {
          name: 'Payments',
          icon: 'icon-payments',
          index: 'dashboard.payments',
          feature: 'payments',
          entitlement: 'payments',
        },
        {
          name: 'Budgeting <sup style="font-size: 7px; vertical-align: super">BETA</sup>',
          icon: 'icon-budgeting',
          index: 'dashboard.budgeting',
          entitlement: 'budgeting',
        },
      ],
      routesObject: {
        'dashboard.home': {
          oldPath: ':customerId/home',
          mergedPath: '/:customerId/dashboard',

        },
        'dashboard.widgets': {
          mergedPath: '/:customerId/portfolio',
          oldPath: ':customerId/dashboard'
        },
        'dashboard.widgets.details': {
          mergedPath: '/:customerId/portfolio/:widgetId',
          oldPath: ':customerId/dashboard/:widgetId'
        },
        'dashboard.sam': {
          mergedPath: '/:customerId/sam',
          oldPath: ':customerId/sam'
        },
        'dashboard.bills': {
          mergedPath: '/:customerId/bills/',
          oldPath: ':customerId/bills'
        },
        'dashboard.bills.info': {
          mergedPath: '/:customerId/bills/:billId',
          oldPath: ':customerId/bills/:billId'
        },
        'dashboard.locations': {
          mergedPath: '/:customerId/locations',
          oldPath: ':customerId/locations'
        },
        'dashboard.locations.hierarchy': {
          mergedPath: '/:customerId/locations/hierarchies',
          oldPath: ':customerId/locations/hierarchy'
        },
        'dashboard.locations.info': {
          oldPath: ':customerId/locations/:locationId',
          mergedPath: '/:customerId/locations/:locationId/metrics',
        },
        'dashboard.locations.info.tab': {
          mergedPath: '/:customerId/locations/:locationId/:tab',
          oldPath: ':customerId/locations/:locationId/:tab'
        },
        'dashboard.reports': {
          mergedPath: '/:customerId/reports',
          oldPath: ':customerId/reports'
        },
        'dashboard.budgeting': {
          mergedPathbudgetList: '/:customerId/budgeting',
          oldPath: ':customerId/budgeting/:bgTab',
          mergedPathcreateABudget: '/:customerId/budgeting/create',
        },
        'dashboard.analytics': {
          mergedPath: '/:customerId/analytics',
          oldPath: ':customerId/analytics'
        },
        'dashboard.reports.tab': {
          oldPath: ':customerId/reports/:tab',
          mergedPathcompleted: '/:customerId/reports/completed-reports',
          mergedPathscheduled: '/:customerId/reports/scheduled-reports',
          mergedPathcreate: '/:customerId/reports/create-reports',
        },
        'dashboard.payments': {
          oldPath: ':customerId/payments',
          mergedPath: '/:customerId/payments',

        },
        'dashboard.sustainability': {
          mergedPath: '/:customerId/sustainability',
          oldPath: ':customerId/sustainability',
        },
        'dashboard.settings': {
          oldPath: 'settings',
          mergedPath: '/settings',

        },
      }
    }
  },
  async created() {
    await this.updateCustomers(true)
    await this.getCarbonFootprint()
  },
  computed: {
    ...mapGetters({
      hasAnyPermissions: 'customers/hasAnyPermissions',
      jwt: 'user/authToken',
      hierarchy: 'locations/hierarchy',
      customerId: 'customers/currentCustomerId',
      customers: 'customers/customersList',
      customer: 'customers/customer',
      customerName: 'customers/customerName',
      currentUser: 'user/currentUser',
      hasEntitlement: 'customers/hasEntitlement',
      hasCarbonFootprintEntitlement: 'customers/hasCarbonFootprintEntitlement',
      isPowerBIEntitlement: 'customers/isPowerBIEntitlement',
    }),
    ...mapActions({}),
    enabledMenuItems() {
      return this.initialized
        ? this.menuItems.map(this.processMenuItem).filter(this.filterMenuItems)
        : []
    },
    getAppHost() {
      switch (window.location.host) {
        case 'localhost:8081':
          return 'http://localhost:8000'

        case 'app-dev.pear.ai':
          return 'https://beta-dev.pear.ai'

        case 'app-qa.pear.ai':
          return 'https://beta-qa.pear.ai'

        case 'app-pp.pear.ai':
          return 'https://beta-pp.pear.ai'

        case 'app.pear.ai':
          return 'https://beta.pear.ai'
        default:
          return 'https://beta.pear.ai'
      }
    },
    activeNavbarItem() {
      if (this.$route.name.includes('bills')) {
        return 'dashboard.bills'
      }
      if (this.$route.name.includes('reports')) {
        return 'dashboard.reports'
      }
      if (this.$route.name.includes('locations.hierarchy')) {
        return 'dashboard.locations.hierarchy'
      }
      if (this.$route.name.includes('locations')) {
        return 'dashboard.locations'
      }
      return this.$route.name
    },
    hasHierarchy() {
      return !isEmpty(this.hierarchy)
    },
    constellationCustomer() {
      return _.get(
        this.customer,
        'contractDetails.constellationContract',
        false,
      )
    },
    permissions() {
      return _.get(this.customer, 'permissions', [])
    },
    isReportsList() {
      const {
        name,
        params: { tab },
      } = this.$route
      return name === 'dashboard.reports.tab' && tab === 'list'
    },
  },
  methods: {
    ...mapActions({
      $logout: 'user/logout',
      loadHierarchy: 'locations/loadHierarchy',
      loadBillsFilterData: 'bills/filtersData/loadData',
      loadReportsFilterData: 'reports/filtersData/loadData',
      fetchUser: 'user/fetchUser',
      loadUserHeapIdentity: 'user/loadUserHeapIdentity'
    }),
    ...mapMutations({
      setCurrentCustomerId: 'customers/setCurrentCustomerId',
      setCustomersList: 'customers/setCustomersList',
      setCurrentCustomerCarbonFootprint:
        'customers/setCurrentCustomerCarbonFootprint',
    }),
    processMenuItem(menuItem) {
      const item = { ...menuItem }
      if (item.children) {
        item.children = item.children
          .map(this.processMenuItem)
          .filter(this.filterMenuItems)
      }

      return {
        ...item,
        route: { name: item.index, params: { customerId: this.customerId } },
      }
    },
    swithApp() {
      const params = this.$router.currentRoute.params
      const baseRoute = this.getAppHost
      const routeName = this.$router.currentRoute.name
      let path

      if (routeName === 'dashboard.budgeting') {
        path = this.routesObject[routeName][`mergedPath${params.bgTab}`]
      } else if (routeName === 'dashboard.reports.tab') {
        path = this.routesObject[routeName][`mergedPath${params.tab}`]
      } else {
        path = this.routesObject[routeName].mergedPath
      }

      if (params && routeName !== 'dashboard.locations.info.tab') {
        Object.keys(params).forEach((param) => {
          path = path.replace(`:${param}`, params[param])
        })
      } else if (params) {
        Object.keys(params).forEach((param) => {
          if (params[param] === 'bill_history' && param === 'tab') {
            path = path.replace(`:${param}`, 'monthly-view')
          }
          path = path.replace(`:${param}`, params[param].replace('_', '-'))
        })
      }
      const route = baseRoute + path
      window.open(route, '_blank') || window.location.replace(route)
    },
    filterMenuItems(menuItem) {
      if (
        menuItem.feature &&
        !this.hasAnyPermissions(this.customer, [
          menuItem.feature,
          'customer_full_access',
        ])
      ) {
        return false
      }
      if (menuItem.entitlement && !this.hasEntitlement(menuItem.entitlement)) {
        return false
      }

      if (menuItem.showCondition && !this[menuItem.showCondition]) {
        return false
      }
      return true
    },
    sortAZ(data = []) {
      if (Array.isArray(data)) {
        return sortBy(prop('name'), data)
      }
      return data
    },
    async getCarbonFootprint() {
      await getCustomerCarbonFootprint(this.customerId).then((response) => {
        this.setCurrentCustomerCarbonFootprint({
          customerId: this.customerId,
          carbonFootprint: response,
        })
        this.initialized = true
      })
    },
    async selectCustomer(customerId) {
      if (+customerId === +this.customerId) {
        return
      }
      this.setCurrentCustomerId(customerId)
      await this.getCarbonFootprint()
      await this.loadHierarchy()

      const customerIdFromUrl = path(
        ['$router', 'currentRoute', 'params', 'customerId'],
        this,
      )
      if (customerIdFromUrl) {
        let { name } = this.$router.currentRoute
        // load filters data
        switch (name) {
          case 'dashboard.reports.tab':
            await this.loadReportsFilterData()
            break
        }
        // redirect to common page
        name =
          this.$route.name === 'dashboard.widgets.details'
            ? 'dashboard.widgets'
            : name
        name =
          this.$route.name === 'dashboard.bills.info' ? 'dashboard.bills' : name
        name = this.$route.name.includes('locations')
          ? 'dashboard.locations'
          : name
        name =
          this.$route.name === 'dashboard.payments' &&
            !this.isPaymentsEntitlement
            ? 'dashboard.home'
            : name
        name =
          this.$route.name === 'dashboard.locations.hierarchy' &&
            !this.hasHierarchy
            ? 'dashboard.locations'
            : name
        await this.$router.push({
          ...this.$router.currentRoute,
          ...{ params: { customerId }, name, query: {} },
        })
      }
    },
    async handleDownloadBill() {
      const { url } = await bills.getPdfDownloadLink({
        customerId: this.customerId,
        billId: this.$route.params.billId,
      })
      if (url) {
        const a = window.document.createElement('a')
        a.setAttribute('href', url)
        window.document.body.appendChild(a)
        a.click()
        window.document.body.removeChild(a)
      } else {
        console.error(`GET PDF LINK ERROR: got empty download url`)
      }
    },
    openOnMobile() {
      this.$emit('navbarToggle', true)
      this.showMobileNavbar = true
    },
    async fetchUserData(id) {
      const user = await this.fetchUser()
      const list = pathOr([], ['customers'], user)
      const customer = list.find((customer) => +customer.id === +id)
      if (customer.entitlements.carbonFootprint) {
        const response = await getCustomerCarbonFootprint(customer.id)
        this.setCurrentCustomerCarbonFootprint({
          customerId: customer.id,
          carbonFootprint: response,
        })
      }
      this.closeOnMobile()
    },
    closeOnMobile() {
      this.$emit('navbarToggle', false)
      this.showMobileNavbar = false
    },
    toggleMobileFilter() {
      this.showMobileFilter = !this.showMobileFilter
    },
    closeMobileFilter() {
      this.showMobileFilter = false
    },
    goBack() {
      this.$router.go(-1)
    },
    async updateCustomers(isVisible) {
      if (isVisible) {
        try {
          const user = await this.fetchUser()
          this.loadUserHeapIdentity(this.$route.params.customerId)
          const list = pathOr([], ['customers'], user)
          await this.setCustomersList(list)
          if (isEmpty(list)) {
            await this.$router.replace({ name: 'home.no.customer' })
          } else if (!find(propEq('id', this.customerId), list)) {
            await this.selectCustomer(path(['0', 'id'], list))
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../styles/utilities';

.el-menu {
  border-right: 0;
}

.constellation-logo {
  padding-top: 20px;

  span {
    position: absolute;
    left: 8.33%;
    right: 57.92%;
    margin-bottom: 4px;
    font-family: Avenir;
    font-weight: normal;
    font-size: 10px;
    color: #ffffff;

    @media (max-width: 899px) {
      right: auto;
    }
  }

  a {
    img {
      margin: 20px calc(100% - 20px - 130px) 20px 20px;
      height: 23px;
    }
  }
}

.navbar {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #081a45;
  z-index: 10;
  flex-grow: 1;

  &-container,
  &-inner,
  &-wrapper {
    height: 100%;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 11;
    background-color: #081a45;

    &.closed {
      @include respond-to($phone) {
        display: none;
      }
    }
  }

  .el-menu-item-location-hierarchies {
    margin: -10px 0 0 0;
  }

  a {
    &.menu-item {
      display: inline-block;
    }

    &.sub-menu-item {
      padding-left: 40px;
      font-size: 12px;
      font-weight: 600;
      color: #ffffff;

      &.active {
        color: #2893f9;
      }
    }
  }
}

.menu-settings:focus,
.menu-settings:active,
.menu-settings:visited,
.menu-settings:focus-within {
  outline: none;
}

.menu-settings div:focus {
  outline-style: none;
}

.logo {
  display: flex;
  align-items: center;
  height: 64px;
  min-height: 64px;
  padding: 0 30px;
  margin-bottom: 32px;
  border-bottom: solid 1px #dde5e6;

  img {
    width: 111px;
    height: 38px;
  }

  &.mobile {
    display: flex;
    margin-bottom: 10px;
    border-bottom: none;
  }
}

.el-menu-item {
  margin-bottom: 10px;

  @include respond-to($phone) {
    margin-bottom: 5px;
  }

  &:hover {
    background-color: #5f5f5f;
  }
}

.el-dropdown {
  display: block;
  min-height: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.navbar-mobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 0 15px;
  background-color: #026fda;
  box-shadow: 0 2px 5px 0 rgba(185, 185, 185, 0.5);
  z-index: 10;
  box-sizing: border-box;

  .el-button:focus,
  .el-button:hover {
    background-color: initial;
  }

  &.is-opened {
    justify-content: flex-end;
    box-shadow: none;
    background-color: #e7ebf0;
    background-image: none;
  }

  &.is-hidden {
    display: none;
  }

  &_customer_info {
    position: absolute;
    left: 0;
    top: 44px;
    width: 100%;
    padding: 7px 15px;
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
    background-color: #e6f1fc;
    color: #ffffff;
    text-align: center;
    box-sizing: border-box;
  }

  @include respond-to($phone) {
    display: flex;
  }
}

.navbar-mobile_title {
  margin: 0 auto;
  color: #fff;
  font-size: 16px;
  font-weight: 900;

  .icon {
    margin-top: 2px;
    margin-right: 0;
    vertical-align: middle;
  }
}

.button-close {
  display: none;
  position: absolute;
  top: 0;
  right: -45px;
  width: 45px;
  height: 100%;
  padding: 0;
  border: 0;
  background-color: rgba(#000, 0.5);
  z-index: 99;

  &::before,
  &::after {
    position: absolute;
    top: 25px;
    right: 15px;
    width: 15px;
    height: 2px;
    content: '';
    background-color: #fff;
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &.is-hidden {
    display: none;
  }

  @include respond-to($phone) {
    display: block;
  }
}

.toggle-search {
  position: absolute;
  top: 9px;
  right: 15px;
  width: 26px;
  height: 26px;
  padding: 0;
  margin-right: 0;
  transition: none;

  i {
    margin-right: 0;
    color: #7d89a6;
    font-size: 20px;
    font-weight: bold;
  }
}

.desktop {
  @include respond-to($phone) {
    display: none;
  }
}

.mobile {
  display: none;

  @include respond-to($phone) {
    display: block;
  }
}

.button-go-back {
  position: absolute;
  padding: 0;
  border: 0;
  line-height: 1;
  background-color: transparent;

  i {
    margin-right: 0;
  }
}

.icon-widgets {
  margin-left: -5px;
  margin-right: 13px;
}

.customers-list {
  margin-bottom: 24px;
  padding-bottom: 23px;

  @include respond-to($phone) {
    margin-bottom: 10px;
  }

  &:focus {
    outline: none;
  }

  .el-dropdown {
    line-height: 25px;
    height: auto;
    min-height: initial;
  }
}

.customer-name {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #ffffff;

  span {
    text-align: left;
    white-space: normal;
    word-break: break-word;
  }

  i {
    align-self: end;
    color: #909399;
    font-size: 14px;
    line-height: 22px;
  }
}

.payments-beta {
  position: relative;
  top: -6px;
  left: 3px;
  color: $primary;
  font-size: 7px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 10px;
}
</style>

<style lang="scss">
@import './../../styles/utilities';

.customers-dropdown {
  max-height: 70vh;
  overflow-y: auto;
  @extend %scrollbar;
}

.customers-dropdown-item {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  color: #ffffff;
  width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.selected {
    font-weight: 900;
  }

  &.el-dropdown-menu__item:hover {
    background-color: rgba(0, 128, 255, 0.05);
    color: #026fda;
  }
}

.navbar {
  padding-bottom: 60px;

  @include respond-to($phone) {
    padding-bottom: 20px;
  }

  overflow-y: auto;

  .el-menu-item {
    height: 40px;
    padding-left: 25px !important;
    color: #ffffff;
    line-height: 40px;
    font-size: 16px;
    font-weight: 500;
    border-left: 5px solid transparent;

    a {
      display: block;
      color: $primary-text;
      text-decoration: none;
    }

    &.is-active {
      color: #081a45;
      background-color: #ffffff;

      &:nth-child(9n + 1):not(.el-menu-item-location-hierarchies) {
        border-color: #0e2a6a;

        i {
          filter: brightness(0) saturate(100%) invert(17%) sepia(16%) saturate(4930%) hue-rotate(194deg) brightness(99%) contrast(103%);
        }
      }

      &:nth-child(9n + 2):not(.el-menu-item-location-hierarchies) {
        border-color: #1846b0;

        i {
          filter: brightness(0) saturate(100%) invert(13%) sepia(63%) saturate(4116%) hue-rotate(224deg) brightness(111%) contrast(94%);
        }
      }

      &:nth-child(9n + 3):not(.el-menu-item-location-hierarchies) {
        border-color: #0080ff;

        i {
          filter: brightness(0) saturate(100%) invert(40%) sepia(78%) saturate(4572%) hue-rotate(197deg) brightness(102%) contrast(106%);
        }
      }

      &:nth-child(9n + 4):not(.el-menu-item-location-hierarchies) {
        border-color: #ffe031;

        i {
          filter: brightness(0) saturate(100%) invert(76%) sepia(40%) saturate(4010%) hue-rotate(12deg) brightness(100%) contrast(101%);
        }
      }

      &:nth-child(9n + 5):not(.el-menu-item-location-hierarchies) {
        border-color: #80c400;

        i {
          filter: brightness(0) saturate(100%) invert(69%) sepia(84%) saturate(2175%) hue-rotate(36deg) brightness(95%) contrast(102%);
        }
      }

      &:nth-child(9n + 6) {
        border-color: #80c400;

        &:not(.el-menu-item-location-hierarchies) {
          border-color: #008040;

          i {
            filter: brightness(0) saturate(100%) invert(28%) sepia(82%) saturate(1231%) hue-rotate(123deg) brightness(95%) contrast(104%);
          }
        }
      }

      &:nth-child(9n + 7):not(.el-menu-item-location-hierarchies) {
        border-color: #0c4f2d;

        i {
          filter: brightness(0) saturate(100%) invert(23%) sepia(12%) saturate(3170%) hue-rotate(100deg) brightness(92%) contrast(91%);
        }
      }

      &:nth-child(9n + 8) {
        border-color: #0c4f2d;

        &:not(.el-menu-item-location-hierarchies) {
          border-color: #0080ff;

          i {
            filter: brightness(0) saturate(100%) invert(31%) sepia(32%) saturate(6133%) hue-rotate(199deg) brightness(107%) contrast(103%);
          }
        }
      }

      &:nth-child(9n + 9):not(.el-menu-item-location-hierarchies) {
        border-color: #ffe031;

        i {
          filter: brightness(0) saturate(100%) invert(88%) sepia(55%) saturate(804%) hue-rotate(327deg) brightness(104%) contrast(103%);
        }
      }

      a {
        color: #081a45;
      }
    }
  }
}

.navbar-toggle {
  position: absolute;
  width: 24px;
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;

  span {
    display: flex;
    flex-direction: column;

    >span {
      width: 24px;
      height: 2px;
      border-radius: 3px;
      background-color: #fff;

      +span {
        margin-top: 4px;
      }
    }
  }
}

.donwload-bill-mobile {
  position: absolute;
  top: 12px;
  right: 15px;
  line-height: 1;

  i {
    margin-right: 0;
  }
}

.switch-apps-btn.el-button {
  padding: 10px;
  line-height: 12px;
  border-radius: 16px;
  background-color: #0080FF99;
  border: 0;
  color: #fff;
  margin: 0 14px;
  transition: none;

  &.margin-bottom {
    margin-bottom: 14px;
  }
}
</style>
