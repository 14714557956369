<template>
  <BaseFormComponent
    :vid="vid"
    :name="$attrs.name"
    :isEditMode="isEditMode"
    :label="label"
    :content="$attrs.content"
    :mode="$attrs.mode"
    :icon="icon"
    :rules="rules"
    v-slot="{ flags, validate }"
    :labelWidth="labelWidth"
  >
    <div class="form-item">
      <div v-if="isEditMode" class="form-item-input">
        <span v-if="required" class="required-star">*</span>
        <el-input
          :placeholder="$attrs.placeholder"
          :type="$attrs.type"
          v-model="innerValue"
          v-on="$listeners"
          :size="size"
          :prefix-icon="$attrs.prefixIcon"
          :class="[!required ? 'pl-12' : '', $attrs.classes]"
          :suffix-icon="$attrs.suffixIcon"
          :maxlength="$attrs.maxlength"
        />
      </div>
      <div v-else>
        {{ innerValue }}
      </div>
      <slot :flags="flags" :validate="validate" />
    </div>
  </BaseFormComponent>
</template>

<script>
import BaseFormComponent from './BaseFormComponent'

export default {
  components: {
    BaseFormComponent,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
    },
    label: {
      type: String,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'mini',
    },
    required: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: String,
      default: '125px',
    },
    width: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>
<style scoped>
@import '../../styles/_formcomponent.scss';
</style>
