<template lang="pug">
.feed_bill-block
  .feed_bill-block_body(v-show="isCollapsed")
    el-table(:data="blockGrouped", :default-sort = "{prop: 'date', order: 'descending'}", :row-class-name="tableRowClassName")
      el-table-column(label="Bill ID", prop="billId", min-width="100")
        template(slot-scope="scope")
          router-link.bill-link(v-if="scope.row.billId"
            :to="{name: 'dashboard.bills.info', params: {billId: scope.row.billId, customerId: currentCustomerId }}")
            | {{ scope.row.billId }}
      el-table-column(label="Utility", prop="utilityType", min-width="150")
      el-table-column(label="Cost", prop="totalCharges", min-width="150")
        template(slot-scope='scope')
          span {{ scope.row.totalCharges | unit('USD') }}
      el-table-column(label="Usage", prop="totalConsumption", :formatter="formatUsageValue", min-width="150")
      el-table-column(label="Start Date", prop="startDate", width="100")
        template(slot-scope="scope")
          span {{ scope.row.startDate | datetime('LL/dd/yyyy') }}
      el-table-column(label="End Date", prop="endDate", width="100")
        template(slot-scope="scope")
          span {{ scope.row.endDate | datetime('LL/dd/yyyy') }}
      el-table-column(label="Day of Service", prop="daysOfService", align="center", width="130")
        template(slot-scope='scope')
          div(style="text-align: center") {{ scope.row.daysOfService }}
      el-table-column(label="", width="132")
        template(slot-scope='scope')
          el-popover(v-if="!scope.row.total" placement="left"
                    width="640"
                    popper-class="block-details-popver"
                    :visible-arrow="false"
                    @show="viewDetails(scope.row.blockId, scope.row.startDate, scope.row.endDate)")
            .details-content(v-loading="loading")
              .details-content-header
                h2 Calculated Values
                .details-content-header__text \#{{ scope.row.billId }} {{ getPrettyVendorByCode(scope.row.vendorCode) }}
                  span ({{ scope.row.statementDate | datetime('LL/dd/yyyy')}})
              el-table(:data="details", :stripe="true")
                el-table-column(label="Name", prop="title", width="300")
                el-table-column(label="Bill values", prop="bill_value", :formatter="formatValue")
                el-table-column(label="Pro-rated values", prop="pro_rated_value", :formatter="formatValue")
            el-button(class="view-details" slot="reference" type="text") View Details
</template>

<script>
import * as R from 'ramda'
import { mapState, mapGetters } from 'vuex'
import { feeds } from '@/api'
import filters from '@/lib/filters'
import _ from 'lodash'

export default {
  props: {
    block: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
    }),
    blockGrouped: function () {
      let grouped = _.flatMap(this.block, (item) => item)
      grouped = _.groupBy(grouped, 'utilityType')
      _.forEach(grouped, (value, key) => {
        let totalCharges = 0
        let totalConsumption = 0
        let uom = ''
        value.forEach((item) => {
          totalCharges += Number(item.totalCharges) || 0
          totalConsumption += Number(item.totalConsumption) || 0
          uom = item.totalConsumptionUom
        })

        const undefinedConsumption =
          _.every(value.slice(), (item) => isNaN(item.totalConsumption)) &&
          uom === 'N/A'

        grouped[key].push({
          ...(!undefinedConsumption && { totalConsumption }),
          utilityType: 'Total:',
          totalCharges,
          totalConsumptionUom: uom,
          total: true,
        })
      })
      return _.flatMap(grouped, (item) => item)
    },
  },
  data() {
    return {
      isCollapsed: true,
      loading: true,
      details: [],
    }
  },
  methods: {
    toggleBillBlock() {
      this.isCollapsed = !this.isCollapsed
    },
    async viewDetails(blockId, startDate, endDate) {
      this.loading = true
      this.details = []

      try {
        const data = await feeds.details({
          customerId: this.currentCustomerId,
          locationId: this.$route.params.locationId,
          blockId,
          startDate: filters.datetime(startDate, 'yyyy-LL-dd'),
          endDate: filters.datetime(endDate, 'yyyy-LL-dd'),
        })

        this.details = Object.keys(data)
          .map((key) => data[key])
          .filter((item) => !/time\s+period/i.test(item.title))
          .filter((item) => !R.isNil(item.title) && item.title !== '')
      } catch (e) {
        console.error(e)
      }

      this.loading = false
    },
    formatValue(row, column, cellValue) {
      if (row.units) {
        return filters.unit(cellValue, row.units)
      }

      if (/\d{4}-\d{2}-\d{2}/.test(cellValue)) {
        cellValue = filters.datetime(cellValue, 'LL/dd/yyyy')
      }

      return cellValue
    },
    formatUsageValue(row, column, cellValue) {
      if (row.totalConsumptionUom === 'N/A' && isNaN(cellValue)) {
        return 'n/a'
      }
      cellValue = isNaN(parseFloat(cellValue)) ? '0.000' : cellValue
      return filters.unit(cellValue, row.totalConsumptionUom)
    },

    tableRowClassName({ row }) {
      return row.total ? 'el-table__row--total' : ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../../styles/utilities';
.feed_bill-block {
  .feed_bill-block_header {
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 40px;
    margin: 0 -40px;
    font-weight: 600;
    box-shadow: 2px 2px 8px 0 #c9c9c9;
    background-color: #ffffff;
    box-sizing: border-box;
    z-index: 1;

    .bill-block-id {
      padding-right: 10px;
      color: #2893f9;
      text-decoration: underline;
      cursor: pointer;
    }
    .vendor-code,
    .invoice-date {
      color: #606266;
    }
  }
  .feed_bill-block_body {
    position: relative;
    padding: 0;
    margin: 0 -40px;
    background-color: #f7f8fa;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 24px;
      content: '';
      background-color: #fff;
    }

    ::v-deep .el-table {
      background-color: transparent;
      tr {
        background-color: transparent;
        td {
          &:nth-child(2),
          &:nth-child(3) {
            color: #747474;
          }
        }
        &.el-table__row--total td {
          color: #222222;
          font-size: 14px;
          font-style: oblique;
          font-weight: 900;
          height: 36px;
          background-color: #fff !important;
        }
      }
      th,
      td {
        cursor: default;
        padding: 0;
      }
      td,
      th.is-leaf {
        border-bottom: 1px solid #ebeef5;
      }
      .cell {
        white-space: nowrap;
      }
    }
    ::v-deep .el-button {
      padding: 5px 20px;
    }
    ::v-deep .cell span {
      display: block;
    }
  }
  .toggle-bill-block {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 8px;
    color: #2893f9;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }

  .view-details {
    text-decoration: underline;
  }

  .bill-link {
    cursor: pointer;
    text-decoration: underline;
    color: #409eff;
  }

  .details-content {
    height: 600px;

    @extend %scrollbar;

    /deep/ .el-table,
    ::v-deep .el-table tr {
      background-color: transparent;
    }
    ::v-deep .el-table th {
      color: #3c3c3c;
      font-weight: 900;
    }
    /deep/ .el-table th,
    ::v-deep .el-table td {
      cursor: default;
      padding: 0;
    }
    ::v-deep .el-table .cell {
      white-space: nowrap;
    }
    &-header {
      padding: 0 15px;
      h2 {
        margin: 0;
        padding: 20px 0 8px 0;
        font-size: 18px;
        line-height: 25px;
        font-weight: 900;
        color: #343536;
      }
      &__text {
        /*height: 22px;*/
        width: auto;
        font-size: 18px;
        font-style: oblique;
        line-height: 20px;
        color: #606266;
        span {
          padding: 0 5px;
          font-weight: 900;
        }
      }
    }
  }

  ::v-deep .feed_bill-block_body .el-table {
    th,
    td {
      &:first-child {
        padding-left: 25px;
      }
      &:last-child {
        font-size: 14px;
        padding-right: 15px;
      }
    }
  }
}
</style>

<style lang="scss">
.el-popover.el-popper.block-details-popver {
  padding: 7px;
  border: 2px solid #2893f9;
  border-radius: 13px;
  background-color: #ffffff;
  box-shadow: -2px 2px 6px 0 rgba(178, 178, 178, 0.5);
  box-sizing: border-box;
}
</style>
