<template>
  <BaseModal
    :dialogVisible="showModal"
    class="map-virtual-account-modal-base-modal"
    @close="close"
    :title="title"
  >
    <template #content>
      <ErrorCard :statusMessage="createError" />
      <el-table :data="energyStarMeters.meters">
        <el-table-column prop="id" label="Meter ID" />
        <el-table-column prop="name" label="Meter Name" />
        <el-table-column prop="type" label="Commodity Type">
          <template slot-scope="scope">
            <span>{{ scope.row.type | switchCommoditiesCase }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Select" align="center" width="200">
          <template slot-scope="scope">
            <el-radio v-model="selectMeterForMapping" :label="scope.row.id" />
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template #footer>
      <el-button
        :disabled="!selectMeterForMapping"
        class="button-size"
        type="primary"
        @click="map"
      >
        Ok
      </el-button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import ErrorCard from '@/components/Cards/ErrorCard'
import { energystar } from '@/api'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    virtualAcc: {
      type: Object,
      default: () => {},
    },
    propertyId: {
      type: Number,
      default: 0,
    },
    energyStarMeters: {
      type: Object,
      default: () => {
        return {
          meters: [],
          unassignedVirtualAccounts: [],
        }
      },
    },
  },
  components: {
    BaseModal,
    ErrorCard,
  },
  data() {
    return {
      showModal: false,
      title: 'Map Virtual Account to Meter',
      selectMeterForMapping: '',
      createError: '',
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  methods: {
    close() {
      this.createError = ''
      this.selectMeterForMapping = ''
      this.$emit('close')
    },
    async map() {
      try {
        await energystar.mapMeter({
          customerId: this.$route.params.customerId,
          propertyId: this.virtualAcc.propertyId,
          meterId: this.selectMeterForMapping,
          virtualAccId: this.virtualAcc.id,
        })
        this.close()
      } catch (e) {
        if (e.response.status < 500) {
          this.createError = e.response.data.message
        } else {
          this.createError = 'There was a problem with the server.'
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-virtual-account-modal-base-modal {
  .button-size {
    width: 45%;
  }
  ::v-deep .el-radio__label {
    display: none;
  }
}
</style>
