<template>
  <sup class="color">*</sup>
</template>

<script>
export default {
  name: 'FormRequiredAsterisk',
}
</script>

<style lang="scss" scoped>
.color {
  color: #ff0000;
  font-size: 14px;
}
</style>
