import { hosts } from '@/lib/api.configs'
import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'

const getMonthlyAttributesList = (customerId, locationId) =>
  http(hosts.v3)
    .get(`customers/${customerId}/locations/${locationId}/get-production-data`)
    .then(dataMiddleware)

const updateMonthlyAttributesValues = (customerId, locationId, body) =>
  http(hosts.v3)
    .put(`customers/${customerId}/locations/update-monthly-attributes-values/${locationId}`, body)
    .then(dataMiddleware)

const addYearMonths = (customerId, locationId, body) =>
  http(hosts.v3)
    .post(`customers/${customerId}/locations/create-monthly-attributes-values/${locationId}`, body)
    .then(dataMiddleware)

export {
  getMonthlyAttributesList,
  updateMonthlyAttributesValues,
  addYearMonths
}
