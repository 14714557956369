<template lang="pug">
  .location-metrics
    .location-metrics_col
      section.metrics-item
        h3.metrics-title Cost History
        .metrics-select(v-if='costHistoryData.length')
          label Select Accounts
          el-select(v-model='selectedCostHistory', size='small', data-action='Select cost history account', @change='change($event, "cost history")', @input='checkCurrentValue', filterable)
            el-option(v-for='option in costHistoryOptions',
              :key='option.value',
              :label='option.label',
              :value='option.value')
          .select-box
            select(v-model='selectedCostHistory',
              :value="selectedCostHistory",
              @change='changeOnMobile($event, "cost history")',
              @blur='checkCurrentValueOnMobile')
              option(v-if="typeof costHistoryOptions[0] === 'object'", v-for='option in costHistoryOptions',
                :key="option.value",
                :value="option.value") {{ option.label }}
            i.el-select__caret.el-input__icon.el-icon-arrow-down
        p.metrics-message(v-else) No data found
        table.metrics-table(style='max-width: 500px', data-content='Cost history data')
          thead
            tr
              td(v-for='item in costHistoryHeaders') {{item.label}}

          tbody
            tr(v-for='item in costHistoryData')
              td(v-if="isCostInHeaders('month')") {{ item.month }}
              td(v-if="isCostInHeaders('cost')") {{ setCost(item.cost)}}
              td(v-if="isCostInHeaders('distCharges')") {{ setValue(item.distCharges)}}
              td(v-if="isCostInHeaders('supplyCharges')") {{ setValue(item.supplyCharges)}}

    .location-metrics_col
      section.metrics-item
        h3.metrics-title Consumption History
        .metrics-select(v-if="consumptionHistoryData.length")
          label Select Accounts
          el-select(v-model='selectedConsumptionHistory', size='small', data-action='Select consumption history account', @change='change($event, "consumption history")', filterable)
            el-option(v-for='option in consumptionHistoryOptions',
              :key='option.value',
              :label='option.label',
              :value='option.value')
          .select-box
            select(v-model='selectedConsumptionHistory',
              :value="selectedConsumptionHistory",
              @change='changeOnMobile($event, "consumption history")')
              option(v-if="typeof consumptionHistoryOptions[0] === 'object'", v-for='option in consumptionHistoryOptions',
                :key="option.value",
                :value="option.value") {{ option.label }}
            i.el-select__caret.el-input__icon.el-icon-arrow-down
        p.metrics-message(v-else) No data found
        table.metrics-table(style='max-width: 520px', data-content='Consumption history data')
          thead
            tr
              td(v-for='item in consumptionHistoryHeaders') {{ item.label }}

          tbody
            tr(v-for='item in consumptionHistoryData')
              td(v-if="isInHeaders('month')") {{ item.month }}
              td(v-if="isInHeaders('consumption')") {{ item.consumption ? item.consumption : '-' }} {{ item.consumption ? item.uom : '' }}
              td(:class='{"no-data": !item.distUnitCost}', v-if="isInHeaders('distUnitCost')") {{ setValue(item.distUnitCost)}}
              td(v-if="isInHeaders('supplyUsages')") {{ item.supplyUsages ? item.supplyUsages : '-' }} {{ item.supplyUsages ? item.uom : '' }}
              td(:class='{"no-data": !item.supplyUnitCost}',v-if="isInHeaders('supplyUnitCost')") {{ setValue(item.supplyUnitCost)}}
              td(:class='{"no-data": !item.unitCost}', v-if="isInHeaders('unitCost')") {{ setValue(item.unitCost)}}

    el-dialog.custom-selection-dialog(:visible.sync='customSelectionModalVisible',
      title='Custom Selection',
      :width='customSelectionModalWidth',
      :fullscreen='customSelectionModalFullscreen',
      :lock-scroll='true',
      @close='clearSelection')
      p.custom-selection-note Please select virtual accounts you want to use to calculate the statistics.
      el-table.virtual-accounts-table(v-if="!$deviceInfo.isMobileDevice",
        ref="virtualAccountsTable",
        :data="virtualAccounts",
        @row-click="selectRow",
        @selection-change="handleSelectionChange")
        el-table-column(type='selection')
        el-table-column(label='Vendor', prop='getPrettyVendorByCode(vendorCode)')
          template(slot-scope='scope')
            span {{ getPrettyVendorByCode(scope.row['vendorCode']) }}
        el-table-column(label='Billing ID', prop='clientAccount')
        el-table-column(label='Service Account ID', width='180', prop='accountCode')
        el-table-column(label='Meter ID', prop='meterSerial')
        el-table-column(label='Utility', prop='commodity')
        el-table-column(label='Type', prop='billType')
        el-table-column(label='Last Bill', prop='lastBillStatementDate', width='170')
          template(slot-scope='scope')
            span {{ scope.row.lastBillStatementDate | dateFormatted }}
      template(v-else)
        .account-item(v-for='(account, index) in virtualAccounts', :key='index', :ref='`account-item-${index}`', @click='selectAccount(account, index)')
          .account-item_content
            .account-item_col
              .account-item_label Vendor
              .account-item_value {{ getPrettyVendorByCode(account.vendorCode) }}
            .account-item_col
              .account-item_label Billing ID
              .account-item_value {{ account.clientAccount }}
            .account-item_col
              .account-item_label Service Account ID
              .account-item_value {{ account.accountCode }}
            .account-item_col
              .account-item_label Meter ID
              .account-item_value {{ account.meterSerial }}
            .account-item_col
              .account-item_label Utility
              .account-item_value {{ account.commodity }}
            .account-item_col
              .account-item_label Type
              .account-item_value {{ account.billType }}
            .account-item_col
              .account-item_label Last Bill
              .account-item_value.last-bill {{ account.lastBillStatementDate | dateFormatted }}
      .dialog-footer(slot='footer')
        el-button.button-select-accounts(type='primary', round, @click='selectVirtualAccounts') Select Virtual Accounts

</template>

<script>
import { DateTime } from 'luxon'
import { locations } from '@/api'
import { mapState, mapGetters } from 'vuex'
import * as R from 'ramda'
import accounting from 'accounting'

const options = [
  {
    label: 'All Gas Accounts',
    value: 'NATURALGAS',
  },
  {
    label: 'All Electricity Accounts',
    value: 'ELECTRIC',
  },
  {
    label: 'All Water Accounts',
    value: 'WATER',
  },
  {
    label: 'All Chilled water Accounts',
    value: 'CHILLEDWATER',
  },
  {
    label: 'All Fire protection Accounts',
    value: 'FIREPROTECTION',
  },
  {
    label: 'All Hot water Accounts',
    value: 'HOTWATER',
  },
  {
    label: 'All Refuse Accounts',
    value: 'REFUSE',
  },
  {
    label: 'All Sewer Accounts',
    value: 'SEWER',
  },
  {
    label: 'All Solar power Accounts',
    value: 'SOLARPV',
  },
  {
    label: 'All Lighting Accounts',
    value: 'LIGHTING',
  },
  {
    label: 'All Storm drain Accounts',
    value: 'STORMDRAIN',
  },
  {
    label: 'All Telephone Accounts',
    value: 'TELEPHONE',
  },
]

const customSelection = {
  label: 'Custom Selection',
  value: 'custom_selection',
}

export default {
  props: {
    virtualAccounts: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      customSelectionModalVisible: false,
      costHistoryData: [],
      selectedCostHistory: 'all_accounts',
      costHistoryOptions: [
        {
          label: 'All Accounts',
          value: 'all_accounts',
        },
      ],
      consumptionHistoryData: [],
      selectedConsumptionHistory: '',
      consumptionHistoryOptions: [],
      multipleSelection: [],
      savedSelection: [],
    }
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
    }),
    customSelectionModalWidth() {
      if (!this.$deviceInfo.isMobileDevice) {
        return '1170px'
      }
      return ''
    },
    customSelectionModalFullscreen() {
      if (!this.$deviceInfo.isMobileDevice) {
        return false
      }
      return true
    },
    costHistoryHeaders() {
      if (
        this.selectedCostHistory === 'WATER' ||
        this.selectedCostHistory === 'SEWER'
      ) {
        return [
          { value: 'month', label: 'Month' },
          { value: 'cost', label: 'Total Cost' },
        ]
      } else {
        return [
          { value: 'month', label: 'Month' },
          { value: 'cost', label: 'Total Cost' },
          { value: 'distCharges', label: 'Distribution Cost' },
          { value: 'supplyCharges', label: 'Supply Cost' },
        ]
      }
    },
    isCostInHeaders() {
      return (value) => {
        return !!this.costHistoryHeaders.find((currentHeader) => {
          return value === currentHeader.value
        })
      }
    },
    consumptionHistoryHeaders() {
      if (
        this.selectedConsumptionHistory === 'WATER' ||
        this.selectedConsumptionHistory === 'SEWER'
      ) {
        return [
          { value: 'month', label: 'Month' },
          { value: 'consumption', label: 'Consumption' },
          { value: 'unitCost', label: 'Unit Cost' },
        ]
      } else {
        return [
          { value: 'month', label: 'Month' },
          { value: 'consumption', label: 'Distribution Consumption' },
          { value: 'distUnitCost', label: 'Distribution Unit Cost' },
          { value: 'supplyUsages', label: 'Supply Consumption' },
          { value: 'supplyUnitCost', label: 'Supply Unit Cost' },
        ]
      }
    },
    isInHeaders() {
      return (value) => {
        return !!this.consumptionHistoryHeaders.find((currentHeader) => {
          return value === currentHeader.value
        })
      }
    },
  },
  filters: {
    dateFormatted(value) {
      if (!value) return ''
      return DateTime.fromISO(value, { zone: 'utc' }).toFormat('LLLL d yyyy')
    },
  },
  async mounted() {
    const opts = R.uniqBy((x) => x.commodity, this.virtualAccounts)
      .map((item) => options.find((o) => o.value === item.commodity))
      .filter((item) => item)

    this.costHistoryOptions = [
      ...this.costHistoryOptions,
      ...opts,
      customSelection,
    ]
    this.consumptionHistoryOptions = [
      ...this.consumptionHistoryOptions,
      ...opts,
    ]

    if (this.consumptionHistoryOptions.length) {
      this.selectedConsumptionHistory = this.consumptionHistoryOptions[0].value
    }

    this.getMetrics('cost history')
    this.getMetrics('consumption history')
  },
  methods: {
    filterConsumption(value) {
      let result = 0.0
      let isNegative = String(value).charAt(0) === '-'

      if (isNegative) {
        value = String(value).slice(1)
      }

      result = accounting.formatMoney(value, {
        format: '%s%v',
        symbol: '',
        precision: 1,
        thousand: ',',
        decimal: '.',
      })

      return isNegative ? `-${result}` : result
    },
    filterCurrency(value) {
      let result = 0.0
      let isNegative = String(value).charAt(0) === '-'

      if (isNegative) {
        value = String(value).slice(1)
      }

      result = accounting.formatMoney(value, {
        format: '%s%v',
        symbol: '$',
        precision: 3,
        thousand: ',',
        decimal: '.',
      })
      return isNegative ? `-${result}` : result
    },
    setValue(value) {
      return value ? this.filterCurrency(value, 'USD') : '-'
    },
    setCost(value) {
      return value ? this.$options.filters.unit(value.toFixed(2), 'USD') : '-'
    },
    change(value, selectType) {
      if (value === 'custom_selection') {
        return
      }

      this.getMetrics(selectType)
    },
    checkCurrentValue(value) {
      if (value === 'custom_selection') {
        this.customSelectionModalVisible = true
        this.savedSelection.forEach((row) =>
          this.$refs.virtualAccountsTable.toggleRowSelection(row),
        )
      }
    },
    changeOnMobile({ target: { value } }, selectType) {
      if (value === 'custom_selection') {
        if (this.$deviceInfo.deviceOS !== 'ios') {
          this.customSelectionModalVisible = true
          this.selectedCostHistory = 'custom_selection'
        }
        return
      }

      this.getMetrics(selectType)
    },
    checkCurrentValueOnMobile() {
      if (this.$deviceInfo.deviceOS === 'ios') {
        if (this.selectedCostHistory === 'custom_selection') {
          this.customSelectionModalVisible = true
        }
      }
    },
    selectVirtualAccounts() {
      this.savedSelection = this.multipleSelection
      this.multipleSelection = R.map((item) => item.id, this.multipleSelection)
      this.customSelectionModalVisible = false
      this.getMetrics('cost history')
      this.multipleSelection = []
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    selectRow(account) {
      if (!this.multipleSelection.includes(account)) {
        this.$refs.virtualAccountsTable.toggleRowSelection(account)
      } else {
        this.$refs.virtualAccountsTable.toggleRowSelection(account)
      }
    },
    clearSelection() {
      if (!this.$deviceInfo.isMobileDevice) {
        this.$refs.virtualAccountsTable.clearSelection()
      } else {
        this.multipleSelection = []
      }
    },
    selectAccount(account, index) {
      if (!this.multipleSelection.includes(account)) {
        this.multipleSelection.push(account)
        this.$refs[`account-item-${index}`][0].classList.add('is-selected')
      } else {
        this.multipleSelection.splice(index, 1)
        this.$refs[`account-item-${index}`][0].classList.remove('is-selected')
      }
    },
    async getMetrics(type) {
      let commodity = ''

      if (type === 'cost history') {
        if (
          this.selectedCostHistory === 'all_accounts' ||
          this.selectedCostHistory === 'custom_selection'
        ) {
          const { results } = await locations.metrics(
            this.currentCustomerId,
            this.$route.params.locationId,
            commodity,
            { virtual_accounts: this.multipleSelection },
          )
          this.costHistoryData = results
        } else {
          commodity = this.selectedCostHistory
          const { results } = await locations.metrics(
            this.currentCustomerId,
            this.$route.params.locationId,
            commodity,
            { virtual_accounts: this.multipleSelection },
          )
          this.costHistoryData = results
        }
      }

      if (type === 'consumption history') {
        let uom = ''

        switch (this.selectedConsumptionHistory) {
          case 'NATURALGAS':
            uom = 'therm'
            break
          case 'ELECTRIC':
            uom = 'kWh'
            break
          case 'WATER':
            uom = 'ccf'
            break
          case 'CHILLEDWATER':
            uom = 'ton'
            break
          case 'FIREPROTECTION':
            uom = 'ccf'
            break
          case 'HOTWATER':
            uom = 'therm'
            break
          case 'REFUSE':
            uom = 'ton'
            break
          case 'SEWER':
            uom = 'ccf'
            break
          case 'SOLARPV':
            uom = 'kwh'
            break
          case 'LIGHTING':
            uom = 'kwh'
            break
          default:
            uom = ''
        }

        commodity = this.selectedConsumptionHistory
        const { results } = await locations.metrics(
          this.currentCustomerId,
          this.$route.params.locationId,
          commodity,
          { virtual_accounts: this.multipleSelection },
        )

        if (results) {
          this.consumptionHistoryData = results.map((item) => {
            return {
              ...item,
              consumption:
                uom === 'kWh'
                  ? this.filterConsumption(item.consumption)
                  : (item.consumption || 0).toFixed(2),
              supplyUsages:
                uom === 'kWh'
                  ? this.filterConsumption(item.supplyUsages)
                  : (item.supplyUsages || 0).toFixed(2),
              uom,
            }
          })
        } else {
          this.consumptionHistoryData = []
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../../styles/utilities';

.location-metrics {
  display: flex;
  flex-wrap: wrap;

  &_col {
    flex: 0 1 50%;

    @include respond-to($phone) {
      flex-basis: 100%;
    }
  }
}

.metrics-title {
  padding-left: 15px;
  color: #333333;
  font-size: 24px;
  font-weight: 900;
}

.metrics-table {
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  border-collapse: collapse;

  @include respond-to($phone) {
    table-layout: fixed;
  }

  th,
  td {
    border-bottom: 1px solid #ebeef5;
  }

  th {
    padding: 12px 15px;
    color: #909399;
    text-align: left;
  }

  td {
    padding: 8px 15px;
    color: #606266;

    &:last-child:not(.no-data),
    &:nth-child(3) {
      color: #2c2e34;
      font-weight: 900;
    }
  }
}

.custom-selection-note {
  margin: 20px 0 20px;
  color: #909399;
  font-weight: 900;

  @include respond-to($phone) {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 18px;
  }
}

.dialog-footer {
  text-align: center;
}

.button-select-accounts {
  min-width: 240px;
  padding-top: 7px;
  padding-bottom: 7px;
  background-color: #0080ff;
}

.metrics-message {
  padding-left: 15px;
}
</style>

<style lang="scss">
@import './../../../../styles/utilities';

.metrics-select {
  margin-bottom: 15px;

  label {
    padding-left: 15px;
    padding-right: 35px;
    color: #222222;

    @include respond-to($phone) {
      padding-right: 20px;
    }
  }

  .el-select {
    @include respond-to($phone) {
      display: none;
    }
  }

  .el-input__inner {
    border-radius: 10px;
  }

  .el-input--small .el-input__inner {
    height: 30px;
    line-height: 30px;
  }

  .select-box {
    display: none;
    position: relative;
    width: 180px;

    @include respond-to($phone) {
      display: inline-block;
    }

    select {
      padding-right: 25px;
      padding-left: 15px;
      font-size: 16px;
      line-height: 30px;
    }

    i {
      position: absolute;
      top: -5px;
      right: 5px;
      color: #c0c4cc;
    }
  }

  @include respond-to($phone) {
    input[type='text'] {
      font-size: 13px;
    }
    select {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 30px;
      padding: 0 15px;
      color: #606266;
      font-size: 13px;
      border-radius: 10px;
      line-height: 30px;
      border: 1px solid #dcdfe6;
      font-size: inherit;
      background-color: #fff;
      background-image: none;
      box-sizing: border-box;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      appearance: none;
      outline: none;
    }
  }
}

.custom-selection-dialog {
  .el-dialog__headerbtn {
    top: 30px;
    right: 30px;

    @include respond-to($phone) {
      top: 15px;
      right: 15px;
    }
  }

  .el-dialog {
    border-radius: 10px;

    @include respond-to($phone) {
      border-radius: 0;
    }
  }

  .el-dialog__header {
    padding: 40px 45px 0;

    @include respond-to($phone) {
      padding: 30px 10px 15px;
    }
  }

  .el-dialog__title {
    color: #2c2e34;
    font-weight: 900;

    @include respond-to($phone) {
      font-size: 28px;
    }
  }

  .el-dialog__body {
    padding: 0 45px;

    @include respond-to($phone) {
      height: calc(100% - 131px);
      padding: 0 10px;
      overflow-y: auto;
      box-sizing: border-box;
    }
  }

  .el-dialog__footer {
    padding: 40px 45px;

    @include respond-to($phone) {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 20px 0;
      background-color: #fff;
    }
  }
}

.virtual-accounts-table {
  .cell {
    word-break: break-word;
  }
}
</style>
