<template>
  <BaseFormComponent
    :vid="vid"
    :name="$attrs.name"
    :isEditMode="isEditMode"
    :label="label"
    :rules="rules"
    :title="title"
    :labelWidth="labelWidth"
  >
    <span v-if="isEditMode" class="form-item">
      <span v-if="required" class="required-star">*</span>
      <el-select
        ref="elSelect"
        class="select-width"
        v-model="innerValue"
        v-on="$listeners"
        v-bind="$attrs"
        :class="[!required && !ordered ? 'pl-12' : '', $attrs.classes]"
        filterable
      >
        <slot />
      </el-select>
    </span>
    <span v-else>{{ innerValue }}</span>
  </BaseFormComponent>
</template>

<script>
import BaseFormComponent from './BaseFormComponent'

export default {
  components: {
    BaseFormComponent,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
    },
    label: {
      type: String,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    ordered: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: String,
      default: '125px',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>
<style lang="scss">
@import '../../styles/_formcomponent.scss';
</style>
