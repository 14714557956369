<template>
  <div class="reports-list" v-loading="loading">
    <filter-container v-if="completedView" :filters="filters" />
    <el-table
      class="reports-table"
      ref="reportsTable"
      @sort-change="sortTable"
      :data="completedReports"
      :empty-text="noReportsMessage"
      data-element="reports-list"
      :cell-style="() => 'border-bottom: 1px solid #ebeef5 !important'"
    >
      <el-table-column label="Report Name" prop="customReportName" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.customReportName || scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Base Report" prop="title" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Format"
        prop="format"
        width="150"
        class-name="report-format"
        sortable
      ></el-table-column>
      <el-table-column label="Creation Date" prop="createdAt" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.createdAt | datetime('LLLL d y t') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Delivery" prop="delivery" width="150" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.delivery || 'Run Now' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sftpConnections" label="SFTP" sortable>
        <template slot-scope="{ row }">{{ hasSftpConnections(row) }}</template>
      </el-table-column>
      <el-table-column prop="emailRecipients" label="Email" sortable>
        <template slot-scope="{ row }">{{ hasEmailRecipients(row) }}</template>
      </el-table-column>
      <el-table-column label="Status" prop="status" width="150" sortable>
        <template slot-scope="{ row }">
          <report-statuses :report="row" />
        </template>
      </el-table-column>
      <el-table-column width="150" prop="id">
        <template slot-scope="scope">
          <a
            class="status-disabled"
            v-if="scope.row.status.toLowerCase() === 'processing'"
          >
            Clear
          </a>
          <a
            :class="`status-${scope.row.status.toLowerCase()}`"
            v-else
            @click="clearReport(scope.row.id)"
          >
            Clear
          </a>
        </template>
      </el-table-column>
    </el-table>
    <el-link class="clear-all" type="primary" @click="clearReport()">
      Clear All
    </el-link>
    <clear-report-modal
      :visible.sync="showClearModal"
      :reportId="reportId"
      @close="closeClearModal"
      @reload-data="loadReports"
    />
  </div>
</template>

<script>
import FilterContainer from '../../Filters/FilterContainer'
import ClearReportModal from '../../Modals/ClearReportModal'
import { isNil, path } from 'ramda'
import { mapGetters, mapActions } from 'vuex'
import { hosts } from '@/lib/api.configs'
import ReportStatuses from '../../../../../components/ReportStatuses'

export default {
  props: {
    completedReports: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ReportStatuses,
    FilterContainer,
    ClearReportModal,
  },
  data() {
    return {
      API_HOST: hosts.api,
      noReportsMessage: '',
      showClearModal: false,
      reportId: '',
    }
  },
  computed: {
    ...mapGetters({
      total: 'completedReports/totalResults',
      loading: 'completedReports/loading',
      filters: 'completedReports/filters',
      jwt: 'user/authToken',
    }),
    completedView() {
      return this.$route.params.tab === 'completed'
    },
    hasEmailRecipients() {
      return (row) =>
        row.emailsRecipients && row.emailsRecipients.length ? 'Yes' : '-'
    },
    hasSftpConnections() {
      return (row) => {
        if (!isNil(row.sftpConnection) && !isNil(row.sftpConnection.deletedAt)) { return '-' }
        if (
          !isNil(row.sftpConnection) &&
          row.sftpConnection.hasOwnProperty('id')
        ) { return 'Yes' }
        return '-'
      }
    },
  },
  watch: {
    reports() {
      if (
        (!isNil(path(['filters', 'start_date'], this)) ||
          !isNil(path(['filters', 'end_date'], this)) ||
          path(['filters', 'type', 'length'], this) ||
          path(['filters', 'format', 'length'], this)) &&
        !this.total
      ) {
        this.noReportsMessage = 'No reports found for these filters.'
      } else {
        this.noReportsMessage =
          'You have no reports. Click "Create Report" to create a new one.'
      }
    },
  },
  methods: {
    ...mapActions({
      getCompletedReports: 'completedReports/getCompletedReports',
    }),
    sortTable({ prop, order }) {
      this.$emit('sort-table', { prop, order })
    },
    clearReport(reportId) {
      if (reportId) {
        this.reportId = reportId
      } else this.reportId = ''
      this.showClearModal = true
    },
    closeClearModal() {
      this.showClearModal = false
      this.reportId = ''
    },
    loadReports() {
      this.$emit('reload-data')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../../styles/utilities';
@import '../../../../../styles/report-statuses';

.reports {
  &-table {
    .el-button {
      padding: {
        top: 9px;
        bottom: 9px;
      }
      text-decoration: underline;
    }

    .el-button:hover {
      font-weight: bold;
    }
  }
}
.clear-all {
  color: #0080ff;
  float: right;
  margin: 1em 6em 0 0;
  font-size: 14px;
  font-weight: bold;
}
.reports-list-total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #7d89a6;
  font-size: 12px;

  b {
    padding-left: 10px;
    color: #3b3e48;
    font-size: 16px;
    font-weight: 900;
  }
}
</style>

<style lang="scss">
.content {
  .reports-table {
    th {
      font-weight: 500;
    }
    .amount-due {
      font-weight: 900;
    }
    td.report-format {
      text-transform: uppercase;
    }
    .cell {
      word-break: break-word;
    }
  }
}
</style>
