<template lang="pug">
  .message-item-text
    .message-item-text_avatar
      template(v-if='checkIfSam')
        img(src='./../../../../static/favicon.png')
      template(v-else)
        | {{ userAvatar() }}
    .message-item-text_content(v-html='convertedText',
                               :class="{ 'text-with-content': controlTypeSelectMonth || pearPayload }",
                               @click="handleAction")
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import * as R from 'ramda'
import { sendEventToChatbase } from '../../../api/stats'

export default {
  props: {
    item: {
      type: [String, Object],
      required: true,
    },
    senderId: {
      type: [String, Number],
    },
    messages: {
      type: Array,
    },
    calendarOpened: {
      type: Boolean,
    },
  },
  updated() {
    if (this.item.action && this.item.action.autoInvoke) {
      setTimeout(this.handleAction, 1000)
      delete this.item.action.autoInvoke
    }
  },
  computed: {
    ...mapState({
      firstName: R.pathOr('', ['user', 'user', 'profile', 'firstName']),
    }),
    ...mapGetters({
      currentCustomerId: 'customers/currentCustomerId',
    }),
    checkIfSam() {
      return +this.senderId === 98
    },
    convertedText() {
      if (this.item.text) {
        return this.convertText(this.item.text.join('\n'))
      }
      return this.convertText(this.item)
    },
    controlTypeSelectMonth() {
      let indicator = false
      ;(this.messages || []).forEach((message) => {
        if (
          message.payload &&
          R.has('pearAi')(message.payload) &&
          message.payload.pearAi.missingParamWidget
        ) {
          indicator =
            message.payload.pearAi.missingParamWidget.name === 'select_month'
        }
      })

      return indicator && this.calendarOpened
    },
    pearPayload() {
      let indicator = false
      ;(this.messages || []).forEach((message) => {
        if (
          message.payload &&
          R.has('pearAi')(message.payload) &&
          message.payload.pearAi
        ) {
          indicator =
            message.payload.pearAi.tableCard ||
            message.payload.pearAi.tableList ||
            message.payload.pearAi.basicCard

          if (
            message.payload.pearAi.missingParamWidget &&
            message.payload.pearAi.missingParamWidget.name === 'select_bill'
          ) {
            indicator = true
          }
        }
      })

      return indicator
    },
  },
  methods: {
    userAvatar() {
      return this.firstName.slice(0, 1)
    },
    convertText(text) {
      return text.replace(
        /(<(https?:\/\/[^|]+)\|([^>]+)>)/gm,
        '<a href="$2">$3</a>',
      )
    },
    scrapeValue(str, fields) {
      const matches = str.match(/\{([^}]+)\}/)
      if (!matches) {
        return str
      }
      const [match, key] = matches
      return str.replace(match, fields[key])
    },
    sendMessage(message) {
      this.$emit('sendMessage', message)
    },
    handleAction() {
      if (!this.item.action) {
        return false
      }

      if (this.item.action.type === 'weblink_newtab') {
        sendEventToChatbase(this.currentCustomerId, 'new_tab', 'weblink').catch(
          (err) => {
            console.error('CHATBASE ERROR: ', err)
          },
        )
        window.open(this.scrapeValue(this.item.action.url, this.item), '_self')
      } else if (this.item.action.type === 'weblink_sametab') {
        if (this.item.action.billId) {
          sendEventToChatbase(
            this.currentCustomerId,
            'same_tab',
            'bill_detail',
          ).catch((err) => {
            console.error('CHATBASE ERROR: ', err)
          })
          this.$router.push({
            name: 'dashboard.bills.info',
            params: { billId: this.item.action.billId },
          })
        } else {
          sendEventToChatbase(
            this.currentCustomerId,
            'same_tab',
            'weblink',
          ).catch((err) => {
            console.error('CHATBASE ERROR: ', err)
          })
          window.open(
            this.scrapeValue(this.item.action.url, this.item),
            '_self',
          )
        }
      } else {
        this.sendMessage(
          this.scrapeValue(this.item.action.bubble_text, this.item),
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../styles/utilities';

.message-item-text {
  flex: none;
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

  &_avatar {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #dcdfe6;
    background-color: #fff;

    /* remove this after real icon is added */
    img {
      width: 30px;
    }

    @include respond-to($phone) {
      display: none;
    }
    @include respond-to($phone-l) {
      display: none;
    }
  }
  &_content {
    position: relative;
    max-width: 50%;
    min-height: 40px;
    margin: 10px 0 0 10px;
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: pre-line;
    border-radius: 20px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 22px;
    padding: 8px 20px;
    font-weight: 500;
    color: #222222;
    background: #fff;
    border: 1px solid #dcdfe6;

    &.text-with-content {
      border-bottom-left-radius: 0;
    }

    @include respond-to($tablet-l) {
      max-width: 80%;
    }
    @include respond-to($phone) {
      max-width: 270px;
      min-height: 36px;
      margin-left: 0;
      margin-right: 0 !important;
    }
    @include respond-to($phone-l) {
      margin-left: 0;
    }
  }

  &.customer {
    justify-content: flex-end;

    .message-item-text_avatar {
      color: #fff;
      font-size: 24px;
      background: linear-gradient(279.67deg, #1846b0 0%, #0080ff 100%);
      order: 1;
    }
    .message-item-text_content {
      margin: 10px 10px 0 0;
      color: #fff;
      border-color: $primary;
      /*border-bottom-right-radius: 0;*/
      background-color: #0080ff;
    }
  }
}
</style>
