<template>
  <div class="production-data" v-loading="loading">
    <div class="filter">
      <div class="filter-container">
        <div class="filter-item">
          <div class="filter-title">Attribute</div>
          <el-select v-model="attributeType" size="mini" filterable="filterable">
            <el-option v-for="item in attributeTypes" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <processing-date
          class="processing-date" title="Go to Month" :datesMinMax="null"
          placeholder="All months" @changeDate="changeDate">
        </processing-date>
      </div>
    </div>
    <div v-if="filteredList.length">
      <el-row type="flex" justify="end">
        <el-button class="btn" type="text" @click="addYearMonths">Add Year/Months</el-button>
      </el-row>
      <div v-if="!hasDataForTimePeriod" class="no-production-data">
        <span>No Production Data for this time period.</span>
      </div>
      <div class="monthly-attributes-container" :class="{'no-scroll': filteredList.length === 0}" v-else>
        <div class="monthly-attributes_item" v-for="monthlyAttributes in filteredList"
             :key="monthlyAttributes.dateMonth" :id="monthlyAttributes.dateMonth">
          <monthly-attributes
            :monthlyAttributes="monthlyAttributes"
            :key="monthlyAttributes.dateMonth"
            :isEditMode="isEditMode(monthlyAttributes.dateMonth)"
            @changeEditMode="changeEditMode"
            @reloadData="getMonthlyAttributes"
          />
        </div>
      </div>
    </div>
    <div class="no-production-data">
      <div v-if="!hasMonthlyAttributes">
        <span>The Location needs to have Attributes in order to allow</span>
        <span>for Production Data values to be entered.</span>
      </div>
      <div v-if="!filteredList.length && hasMonthlyAttributes">
        <span>No Production Data added yet.</span>
        <span>Start by adding a Year and Months.</span>
      </div>
      <el-button class="btn" type="text" @click="addYearMonths" v-if="!filteredList.length"
                 :disabled="!hasMonthlyAttributes">Add Year/Months
      </el-button>
    </div>
    <add-year-months-modal
      :visible="showAddYearMonthsModal"
      :disabledMonths="disabledMonths"
      @close="closeAddYearMonthsModal"
      @addYearMonths="onAddYearMonths"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MonthlyAttributes from './MonthlyAttributes'
import AddYearMonthsModal from './AddYearMonthsModal'
import ProcessingDate from '@/components/ProcessingDate.vue'
import FilterSelect from '@/components/Filters/FilterSelect.vue'
import { DateTime } from 'luxon'

export default {
  components: {
    MonthlyAttributes,
    ProcessingDate,
    FilterSelect,
    AddYearMonthsModal,
  },
  data() {
    return {
      attributeType: 'All Attributes',
      selector: '.monthly-attributes-container',
      hasDataForTimePeriod: true,
      selectedMonthlyViewId: null,
      showAddYearMonthsModal: false,
      disabledMonths: {}
    }
  },
  computed: {
    ...mapGetters({
      list: 'productionData/list',
      loading: 'productionData/loading',
      hasMonthlyAttributes: 'productionData/hasMonthlyAttributes'
    }),
    filteredList() {
      if (!this.attributeType || this.attributeType === 'All Attributes') {
        return this.list
      }
      const filteredList = []
      for (let i = 0, len = this.list.length; i < len; i++) {
        const item = { attributes: [], dateMonth: this.list[i].dateMonth }
        for (let j = 0, jLen = this.list[i].attributes.length; j < jLen; j++) {
          if (this.list[i].attributes[j].attributeName === this.attributeType) {
            item.attributes.push(this.list[i].attributes[j])
          }
        }
        if (item.attributes.length) {
          filteredList.push(item)
        }
      }
      return filteredList
    },
    attributeTypes() {
      const attributeTypes = []
      if (!this.list.length) {
        return []
      }
      this.list[0].attributes.forEach((attribute) => {
        attributeTypes.push(attribute.attributeName)
      })
      return attributeTypes
    }
  },
  async mounted() {
    await this.getMonthlyAttributes()
  },
  methods: {
    ...mapActions({
      fetchMonthlyAttributes: 'productionData/fetchMonthlyAttributes'
    }),
    addYearMonths() {
      this.disabledMonths = {}
      this.list.forEach(item => {
        const year = new Date(item.dateMonth).getFullYear()
        if (!this.disabledMonths[year]) {
          this.disabledMonths[year] = []
        }
        this.disabledMonths[year].push(new Date(item.dateMonth).getMonth())
      })
      this.showAddYearMonthsModal = true
    },
    async getMonthlyAttributes() {
      const { customerId, locationId } = this.$route.params
      await this.fetchMonthlyAttributes({ customerId, locationId })
    },
    async changeDate(time) {
      this.hasDataForTimePeriod = true
      if (!time) {
        await this.getMonthlyAttributes()
        if (this.list.length) {
          this.$nextTick(() => document.querySelector(this.selector).scroll(0, 1))
        }
        return
      }
      this.$nextTick(() => {
        const id = DateTime.fromMillis(+time)
          .toFormat('yyyy-MM')
          .toString()
        const element = document.getElementById(id)
        if (element) {
          this.$nextTick(() => element.scrollIntoView())
        } else {
          this.hasDataForTimePeriod = false
        }
      })
    },
    changeEditMode(edit, id) {
      if (edit) {
        this.selectedMonthlyViewId = id
      } else {
        this.selectedMonthlyViewId = null
      }
    },
    isEditMode(id) {
      return this.selectedMonthlyViewId === id
    },
    closeAddYearMonthsModal() {
      this.showAddYearMonthsModal = false
    },
    async onAddYearMonths(recentMonth) {
      await this.changeDate(recentMonth)
    }
  },
}
</script>

<style lang="scss" scoped>
@import './../../../../../styles/_utilities';

.production-data {
  .filter {
    margin-right: 0;
    margin-left: 0;

    &-item {
      &:first-child {
        margin-right: auto;
      }
    }

    ::v-deep .el-input {
      width: 160px !important;
    }

    .processing-date {
      margin-right: 0;
    }
  }

  .monthly-attributes {
    &-container {
      overflow-y: scroll;
      max-height: calc(100vh - 277px);
      min-height: calc(100vh - 277px);
      @extend %scrollbar;

      &.no-scroll {
        overflow-y: hidden;
      }
    }

    &_item {
      border-bottom: 1px solid #dcdcdc;

      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }

  .no-production-data {
    text-align: center;
    margin-top: 59px;

    span {
      display: block;
      color: #999999;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  .btn {
    font-weight: 600;
    font-size: 14px;
    color: $primary;
    margin: 13px 6px 0 0;
    line-height: 19px;

    &:hover {
      color: $primary;
    }

    &:disabled {
      opacity: 0.4;
    }
  }
}
</style>
