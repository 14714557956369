import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'
import { hosts } from '@/lib/api.configs'
import qs from 'qs'

export const getReports = (customerId, params) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/reports/templates?${qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
    )
    .then(dataMiddleware)

export const postScheduledReport = (customerId, body) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/reports/scheduled`, body)
    .then(dataMiddleware)

export const postRunNowReport = (customerId, body) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/reports/run-now`, body)
    .then(dataMiddleware)

export const getSftpConnectionForReports = (customerId) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/sftp-connections?featureType=reports`)
    .then(dataMiddleware)

export const getTemplatesAvailableValues = (templateId, customerId) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/reports/available-filters/${templateId}`)
