<template lang="pug">
  .related-queries.control(data-element="related-queries")
    .control-buttons(ref='control-buttons', :class="{'control-buttons-ipad': isIpad}")
      template(v-for='query in relatedQueries')
        el-button.button-control(v-if='query.type === "weblink_overlay"',
                                :key='query.id',
                                size='medium',
                                round,
                                @click='openWhatElseModal()') What Else Can You Do?
        el-button.button-control(v-else-if='query.type === "weblink_sametab"',
                                :key='query.id',
                                size='medium',
                                round,
                                @click='() => navigateToBillDetail(query)') {{ query.button_text }}
        el-button.button-control(v-else,
                                :key='query.id',
                                size='medium',
                                round,
                                :data-action="query.button_text",
                                @click='sendMessage(query.bubble_text)') {{ query.button_text }}
</template>

<script>
import * as R from 'ramda'
import { sendEventToChatbase } from '../../../../api/stats'
import { mapGetters } from 'vuex'

export default {
  props: {
    whatElseModalOpened: {
      type: Boolean,
      required: true,
    },
    relatedQueriesData: {
      type: Array,
      required: true,
    },
    relatedQueries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // isWhatElseModalVisible: false
    }
  },
  computed: {
    ...mapGetters({
      currentCustomerId: 'customers/currentCustomerId',
    }),
    isIpad() {
      return this.$deviceInfo.deviceOS === 'ipad'
    },
  },
  mounted() {
    this.setButtonsControlWidth()
    window.addEventListener('resize', this.setButtonsControlWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setButtonsControlWidth)
    // document.body.classList.remove('disable-scroll-related-queries');
    // this.$emit('unblockChat');
  },
  methods: {
    async resetContextsAndSendMessage(message) {
      this.sendMessage(message, false, true)
    },
    sendMessage(message, silent, resetContexts) {
      sendEventToChatbase(
        this.currentCustomerId,
        'related_query',
        message.replace(/\s/gi, '_'),
      ).catch((err) => {
        console.error('CHATBASE ERROR: ', err)
      })

      this.$emit('sendMessage', message, silent, resetContexts)
    },
    setButtonsControlWidth() {
      if (this.$deviceInfo.isMobileDevice && this.$refs['control-buttons']) {
        let controlButtonsWidth = 0
        this.$refs['control-buttons'].childNodes.forEach((button) => {
          controlButtonsWidth += button.offsetWidth + 20
        })
        controlButtonsWidth++
        this.$refs['control-buttons'].style.width = `${controlButtonsWidth}px`
      } else {
        this.$refs['control-buttons'].style.width = 'auto'
      }
      // this.$emit('scrollToBottom');
    },
    navigateToBillDetail(query) {
      sendEventToChatbase(
        this.currentCustomerId,
        'same_tab',
        'bill_detail',
      ).catch((err) => {
        console.error('CHATBASE ERROR: ', err)
      })

      const payload = R.find((item) =>
        R.path(['payload', 'pearAi', 'tableCard'], item),
      )(this.relatedQueriesData)
      const id = R.path(
        ['payload', 'pearAi', 'tableCard', 'properties', 'bill_id'],
        payload,
      )
      this.$router.push({
        name: 'dashboard.bills.info',
        params: { billId: id },
      })
    },
    // toggleBodyScroll (value) {
    //   console.log('bdsd');
    //   if (!value) {
    //     document.querySelector('body').style.overflow = 'hidden';
    //   } else {
    //     document.querySelector('body').style.overflow = 'initial';
    //   }
    // },

    openWhatElseModal() {
      this.$emit('openWhatElseModal')
    },
    toggleWhatElseModal() {
      sendEventToChatbase(
        this.currentCustomerId,
        'overlay',
        'what_else_can_you_do',
      ).catch((err) => {
        console.error('CHATBASE ERROR: ', err)
      })

      this.isWhatElseModalVisible = !this.isWhatElseModalVisible
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../../styles/_utilities';

.control-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .button-control {
    border-radius: 40px;
  }
}

.logo {
  display: none;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #fff;

  @include respond-to($phone) {
    display: flex;
  }
  .icon {
    margin-right: 0;
  }
}
.related-queries_text {
  color: #5d6a89;

  p {
    margin: 0;
    line-height: 22px;

    @include respond-to($phone) {
      padding: 0 10px;
      font-size: 14px;
      text-align: center;
    }
  }
}
.related-queries_list {
  .el-button {
    border-color: #dcdfe6;
  }

  &-title {
    margin: 30px 0 5px;
    color: #3b3e48;
    font-size: 16px;
    font-weight: 600;
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .el-button {
      margin: 10px 10px 0 0;
      color: #0080ff;

      @include respond-to($phone) {
        background-color: transparent;
      }
    }
  }
}
.related-queries_list-item {
  display: block;
  width: 100%;
  min-height: 54px;
  background-color: transparent;

  + .related-queries_list-item {
    margin: 10px 0 0;
  }
}
.location-address {
  color: #2c2e34;
}
.location-state {
  margin-top: 5px;
  color: #7d89a6;
  font-size: 10px;
}
.button-control:first-letter {
  text-transform: uppercase;
}
</style>

<style lang="scss">
@import './../../../../styles/_utilities';
.related-queries {
  overflow-x: auto;
  .control {
    &-buttons {
      .el-button {
        /*min-width: 245px;*/
        min-width: auto;
        max-width: auto;
        color: #0080ff;

        @include respond-to($phone) {
          float: left;
          width: auto;
          margin-right: 10px;
        }

        + .el-button {
          @include respond-to($phone) {
            margin-top: 10px;
            margin-left: 0;
          }
        }
      }
    }
  }
  &.control {
    @include respond-to($phone) {
      padding-bottom: 7px;
      overflow-x: auto;
    }
  }
}

.related-queries-modal {
  overflow: hidden;
  background-color: rgba(#f4f6fa, 0.9);

  @include respond-to($phone) {
    background-color: rgba(#f4f6fa, 0.95);
  }

  .el-dialog {
    max-height: 75%;

    @extend %scrollbar;

    @include respond-to($phone) {
      max-height: 100%;
      background-color: transparent;
      -webkit-overflow-scrolling: touch;
    }
    @include respond-to('(max-height: 800px)') {
    }
  }
  .el-dialog__header {
    padding: 40px 50px 0 50px;

    @include respond-to($phone) {
      padding: 20px 30px 0 30px;
      text-align: center;
    }
  }
  .el-dialog__title {
    color: #273a58;
    font-size: 24px;
    font-weight: 700;

    @include respond-to($phone) {
      color: #000;
      font-size: 14px;
    }
  }
  .el-dialog__headerbtn {
    top: 40px;
    right: 45px;

    @include respond-to($phone) {
      top: 16px;
      right: auto;
      left: 20px;
      font-size: 22px;

      .el-icon-close:before {
        content: '\E600';
      }
    }
  }
  .el-dialog__body {
    padding: 30px 50px 40px;

    @include respond-to($phone) {
      padding-top: 20px;
      padding-right: 22px;
      padding-left: 22px;
    }
  }

  @include respond-to($phone) {
    .el-dialog {
      width: 100% !important;
      height: 100vh;
      margin: 0 !important;
      border-radius: 0;
    }
  }
}
</style>
