<template>
  <div class="pie-chart" ref="pie-chart"></div>
</template>

<script>
import * as d3 from 'd3'

export default {
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    width: {
      type: Number,
      default: 560,
    },
    height: {
      type: Number,
      default: 340,
    },
    pieSize: {
      type: Number,
      default: 300,
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    width() {
      this.redrawChart()
    },
  },
  mounted() {
    this.printChart()
  },
  methods: {
    redrawChart() {
      this.$refs['pie-chart'].innerHTML = ''
      this.printChart()
    },
    printChart() {
      const data = this.data
      const pieChart = this.$refs['pie-chart']
      const svgWidth = this.width > 520 ? 520 : this.width
      const svgHeight = this.height
      const radius = this.pieSize / 2
      const margin = {
        right: 100,
        left: 60,
      }

      const isMobile = this.$deviceInfo.isMobileDevice

      const svg = d3
        .select(pieChart)
        .append('svg')
        .attr('class', 'chart')
        .attr('width', svgWidth)
        .attr('height', svgHeight)

      if (!isMobile) {
        svg
          .attr('viewBox', `0 0 560 ${svgHeight}`)
          .attr('preserveAspectRatio', 'xMaxYMid meet')
      }

      // Print pie
      let piePosition = null

      if (this.showLegend) {
        piePosition = `${svgWidth / 2 - margin.left - 30} ${svgHeight / 2}`
      } else {
        piePosition = `${svgWidth / 2} ${svgHeight / 2}`
      }

      const g = svg.append('g').attr('transform', `translate(${piePosition})`)

      const arc = d3
        .arc()
        .outerRadius(radius - 10)
        .innerRadius(0)

      const pie = d3.pie().value((d) => d)

      let pieData = data.filter((e) => parseInt(e.value) > 3)
      const over = pieData.reduce((s, v) => (s += parseInt(v.value)), 0)

      if (over < 100) {
        pieData.push({
          color: '#795548',
          utility: 'other',
          value: 100 - pieData.reduce((s, v) => (s += v.value), 0),
        })
      }

      const pieColor = d3.scaleOrdinal(pieData)

      const arcs = g
        .selectAll('arc')
        .data(pie(pieData.map((e) => e.value)))
        .enter()
        .append('g')
        .attr('class', 'arc')
        .on('mouseenter', (d, i) => {
          tooltips.classed('show', (e, j) => j === i)
        })
        .on('mouseleave', () => tooltips.classed('show', false))

      arcs
        .append('path')
        .attr('d', arc)
        .style('fill', (d, i) => pieColor(i).color)

      arcs
        .append('text')
        .attr('transform', (d) => {
          let [x, y] = arc.centroid(d)
          x *= 2.4
          y *= 2.4
          return `translate(${x} ${y})`
        })
        .attr('dy', '.50em')
        .style('text-anchor', 'middle')
        .text((d) => `${d.data}%`)

      // Print tooltips
      const tooltips = g
        .selectAll('.tooltip')
        .data(pie(pieData.map((e) => e.value)))
        .enter()
        .append('g')
        .attr('class', 'tooltip')
        .attr('transform', (d) => {
          let [x, y] = arc.centroid(d)
          return `translate(${x} ${y})`
        })

      tooltips
        .append('rect')
        .attr('rx', '7')
        .attr('ry', '7')
        .attr('x', 2)
        .attr('y', 2)
        .attr('fill', 'rgba(0, 0, 0, 0.15)')
        .attr(
          'width',
          (d, i) =>
            `${pieData[i].utility} - ${pieData[i].value}%`.length * 6 + 14,
        )
        .attr('height', '34')

      tooltips
        .append('rect')
        .attr('rx', '7')
        .attr('ry', '7')
        .attr('fill', '#ffffff')
        .attr(
          'width',
          (d, i) =>
            `${pieData[i].utility} - ${pieData[i].value}%`.length * 6 + 14,
        )
        .attr('height', '34')

      tooltips
        .append('text')
        .attr('dx', 8)
        .attr('dy', 22)
        .text((d, i) => `${pieData[i].utility} - ${pieData[i].value}%`)

      // Print legend
      if (this.showLegend) {
        const legend = svg
          .append('g')
          .attr('class', 'pie-legend')
          .attr('transform', `translate(${svgWidth - margin.right - 40} 20)`)

        const legendItems = legend
          .selectAll('svg')
          .data(data.reverse())
          .enter()
          .append('g')
          .attr('class', 'pie-legend-item')
          .attr('transform', (d, i) => `translate(0 ${i * 24})`)

        legendItems
          .append('rect')
          .attr('width', 12)
          .attr('height', 12)
          .attr('fill', (d) => d.color)

        legendItems
          .append('text')
          .attr('dx', 20)
          .attr('dy', 10)
          .text((d) => {
            if (d.value <= 3) {
              return `${d.utility} < 3%`
            }

            return `${d.utility} - ${d.value}%`
          })
      }
    },
  },
}
</script>

<style>
:root {
  --border-color: #d8d8d8;
  --bar-bgcolor: #84b2fa;
}
.pie-chart {
  position: relative;
}
.chart {
  display: block;
}
.arc > text {
  fill: #5d5d5d;
  font-size: 16px;
  font-weight: 900;
}
.arc > path {
  cursor: pointer;
}
.pie-legend-item > text {
  fill: #333333;
  font-size: 12px;
  font-weight: 500;
}
.tooltip {
  fill: #636363;
  font-size: 12px;
  font-weight: 500;
  transition: opacity 0.25s;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
</style>
