import { DateTime } from 'luxon'
import accounting from 'accounting'
import { pathOr } from 'ramda'
import * as _ from 'lodash'

const datetime = (value, format = 'DDD') => {
  if (!value) return ''
  return DateTime.fromISO(String(value), { zone: 'utc' }).toFormat(format)
  /*
    Attach { zone: 'utc' } option to luxon Datetime.fromISO method.
    It should fix all datetime global offsets.
   */
}

const consumption = (value) => {
  let result = 0.0
  let isNegative = String(value).charAt(0) === '-'

  if (isNegative) {
    value = String(value).slice(1)
  }

  result = accounting.formatMoney(value, {
    format: '%s%v',
    symbol: '',
    precision: 1,
    thousand: ',',
    decimal: '.',
  })

  return isNegative ? `-${result}` : result
}

const currency = (value, symbol = '$') => {
  let result = 0.0
  value = +value === 0 ? 0 : value
  let isNegative = String(value).charAt(0) === '-'

  if (isNegative) {
    value = String(value).slice(1)
  }

  result = accounting.formatMoney(value, {
    format: '%s%v',
    symbol: symbol,
    precision: 2,
    thousand: ',',
    decimal: '.',
  })

  return isNegative ? `-${result}` : result
}

const prettyVendor = (value = '', dict = {}) => {
  return pathOr(value, [value], dict)
}

const unit = (value, unit = '', nonUsBill = false) => {
  let currencySymbol = '$'
  if (nonUsBill) {
    currencySymbol = 'USD'
  }
  if (unit === 'USD') {
    return nonUsBill
      ? `${currencySymbol} ${currency((+value).toFixed(3), '')}`
      : `${currencySymbol}${currency((+value).toFixed(3), '')}`
  } else if (unit === 'USDCCF') {
    return `${(+value).toFixed(3)} ${currencySymbol}/ccf`
  } else if (unit === 'USDTHERM') {
    return `${(+value).toFixed(3)} ${currencySymbol}/therm`
  } else if (unit === 'USDTON') {
    return `${(+value).toFixed(3)} ${currencySymbol}/ton`
  } else if (unit === 'USDMIN') {
    return `${(+value).toFixed(3)} ${currencySymbol}/min`
  } else if (unit === 'USDMB') {
    return `${(+value).toFixed(3)} ${currencySymbol}/Mb`
  } else if (unit === 'USDSQFEET') {
    return `${(+value).toFixed(3)} ${currencySymbol}/sqfeet`
  } else if (unit === 'USDGAL') {
    return `${(+value).toFixed(3)} ${currencySymbol}/gal`
  } else if (unit === 'KWHDAY') {
    return `${(+value).toFixed(3)} kWh/day`
  } else if (unit === 'PERCENT') {
    return `${(+value).toFixed(3)}%`
  } else if (unit === 'USDDAY') {
    return `${(+value).toFixed(3)} ${currencySymbol}/day`
  } else if (unit === 'USDKWHDAY') {
    return `${(+value).toFixed(3)} ${currencySymbol}/kWh/day`
  } else if (unit === 'USDKWH') {
    return `${(+value).toFixed(3)} ${currencySymbol}/kWh`
  } else if (unit === 'TONDAY') {
    return `${(+value).toFixed(3)} ton/day`
  } else if (unit === 'CCFDAY') {
    return `${(+value).toFixed(3)} ccf/day`
  } else if (unit === 'THERMDAY') {
    return `${(+value).toFixed(3)} therm/day`
  } else if (unit === 'MINDAY') {
    return `${(+value).toFixed(3)} min/day`
  } else if (unit === 'CALLS') {
    return `${(+value).toFixed(3)} calls`
  } else if (unit === 'MB') {
    return `${(+value).toFixed(3)} Mb`
  } else if (unit === 'MBDAY') {
    return `${(+value).toFixed(3)} Mb/day`
  } else if (unit === 'SQFEETDAY') {
    return `${(+value).toFixed(3)} sqfeet/day`
  } else if (unit === 'KWH') {
    return `${consumption((+value).toFixed(3))} kWh`
  } else if (unit === 'KW') {
    return `${consumption((+value).toFixed(3))} kW`
  } else if (unit === 'KVARH') {
    return `${consumption((+value).toFixed(3))} kvarh`
  } else if (unit === 'USDMMBTU') {
    return `${(+value).toFixed(3)} ${currencySymbol}/mmbtu`
  } else if (unit === 'MMBTUDAY') {
    return `${(+value).toFixed(3)} mmbtu/day`
  } else if (unit === 'KWHDD') {
    return `${(+value).toFixed(3)} kWh/DD`
  } else if (unit === 'CAD') {
    return `CAD ${currency((+value).toFixed(3), '')}`
  } else if (unit) {
    return `${(+value).toFixed(3)} ${(unit && unit.toLowerCase() !== 'null'
      ? unit
      : ''
    ).toLowerCase()}`
  }
  return (+value).toFixed(3)
}

const unitValueCsv = (value, unit = '') => {
  if (unit === 'USD') {
    return (+value).toFixed(2)
  }
  return (+value).toFixed(3)
}

const unitCsv = (unit = '') => {
  if (unit === 'USD') {
    return '($)'
  } else if (unit === 'KWHDAY') {
    return `(kwh/day)`
  } else if (unit === 'PERCENT') {
    return `(%)`
  } else if (unit === 'USDDAY') {
    return `($/day)`
  } else if (unit === 'USDKWHDAY') {
    return `($/kwh/day)`
  } else if (unit === 'USDKWH') {
    return `($/kwh)`
  } else if (unit === 'KWH') {
    return `(kWh)`
  } else if (unit === 'KW') {
    return `(kW)`
  }
  return `(${unit.toLowerCase()})`
}

const defaultFilters = (widgetType = '', action = 'create') => {
  const defaults = {
    year: { year: new Date().getFullYear() },
    time_period: { type: 'year_to_date', year_to_date: true },
    location_id: { location_id: { location_ids: [] } },
    utility_type: { utility_type: 'ELECTRIC' },
    utility_type_list: { list: [] },
  }
  let { year, time_period, location_id, utility_type, utility_type_list } =
    defaults
  let defaultFilters = {}
  if (action === 'show') {
    time_period = { type: 'all' }
  }

  if (
    [
      'highest_cost_locations',
      'highest_consumption_locations',
      'highest_unit_cost_locations',
    ].includes(widgetType)
  ) {
    defaultFilters = { time_period, location_id, utility_type }
  } else if (['cost_by_utility'].includes(widgetType)) {
    defaultFilters = { time_period, location_id }
  } else if (
    ['monthly_cost_by_location', 'monthly_consumption_by_location'].includes(
      widgetType,
    )
  ) {
    defaultFilters = { year, location_id, utility_type }
  } else if (['charges_by_vendor'].includes(widgetType)) {
    defaultFilters = { year, location_id, utility_type_list }
  } else if (['costs_by_charge_type'].includes(widgetType)) {
    defaultFilters = { year, location_id }
  }

  return defaultFilters
}

const toArray = (currentFilters) => {
  const filters = []
  const { time_period, location_id, utility_type, utility_type_list, year } =
    currentFilters
  if (!_.isEmpty(time_period)) {
    const existing = _.get(
      _.find(filters, (item) => _.keys(item).find((i) => i === 'time_period')),
      'time_period.period',
      {},
    )
    const type = _.get(time_period, 'type', 'all')
    const period = { ...existing, ..._.omit(time_period, 'type') }
    _.remove(filters, (item) => _.keys(item).find((i) => i === 'time_period'))
    if (period.start_date && period.end_date && type === 'period') {
      filters.push({ time_period: { period }, type })
    } else if (type === 'months') {
      filters.push({ time_period: { months: time_period.count }, type })
    } else if (type === 'year_to_date') {
      filters.push({ time_period: { year_to_date: true }, type })
    }
  }

  if (!_.isEmpty(location_id)) {
    _.remove(filters, (item) => _.keys(item).find((i) => i === 'location_id'))
    if (!_.isEmpty(location_id.location_ids)) {
      filters.push({
        location_id: {
          location_ids: location_id.location_ids,
        },
      })
    }
  }

  if (!_.isEmpty(utility_type)) {
    _.remove(filters, (item) => _.keys(item).find((i) => i === 'utility_type'))
    filters.push({ utility_type })
  }

  if (!_.isEmpty(year)) {
    _.remove(filters, (item) => _.keys(item).find((i) => i === 'year'))
    filters.push({ year })
  }

  if (!_.isEmpty(utility_type_list)) {
    _.remove(filters, (item) =>
      _.keys(item).find((i) => i === 'utility_type_list'),
    )
    filters.push({ utility_type_list })
  }
  return filters
}

const toTitleCase = (str) => {
  return _.startCase(str)
}

const switchCommoditiesCase = (commodity) => {
  const commodities = {
    ELECTRIC: 'Electric',
    CHILLEDWATER: 'Chilled Water',
    HOTWATER: 'Hot Water',
    NATURALGAS: 'Natural Gas',
    SOLARPV: 'Solar PV',
    LIGHTING: 'Lighting',
    PROPANE: 'Propane',
    STEAM: 'Steam',
    OIL2: 'Oil 2',
    OIL4: 'Oil 4',
    OIL6: 'Oil 6',
  }
  let formattedCommodity = commodities[commodity]
  return formattedCommodity
}
const decimal = {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      }
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      }
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`,
    )

    return {
      valid: regex.test(value),
    }
  },
  message: 'The {_field_} field must contain only decimal values',
}

export default {
  datetime,
  unit,
  prettyVendor,
  defaultFilters,
  toArray,
  unitCsv,
  unitValueCsv,
  toTitleCase,
  switchCommoditiesCase,
  decimal,
}
