import { not, isEmpty, isNil, pickBy } from 'ramda'
import * as API from '@/api'
import filters from './locations.filters'
import filtersData from './locations.filters.data'

const SET_LOADING_LIST = 'set_loading_list'
const SET_LOCATIONS = 'set_locations'
const SET_UNMATCHED_LOCATIONS = 'set_unmatched_locations'
const SET_HIERARCHY = 'set_hierarchy'
const SET_TOTAL = 'set_total'
const SET_BILL_HISTORY = 'set_bill_history'
const SET_BILL_HISTORY_LOADING = 'set_bill_history_loading'

const initialState = () => ({
  loading_list: false,
  list: [],
  unMatchedLocations: [],
  hierarchy: [],
  billHistory: [],
  billHistoryLoading: false,
  total: null,
  params: null,
})

export default {
  namespaced: true,
  modules: {
    filters,
    filtersData,
  },

  state: initialState(),

  mutations: {
    [SET_BILL_HISTORY_LOADING](state, loading = false) {
      state.billHistoryLoading = loading
    },

    [SET_BILL_HISTORY](state, history = []) {
      state.billHistory = history
    },

    [SET_LOADING_LIST]: (state, flag) => {
      state.loading_list = flag
    },

    [SET_LOCATIONS]: (state, locations) => {
      state.list = locations
    },
    [SET_UNMATCHED_LOCATIONS]: (state, locations) => {
      state.unMatchedLocations = locations
    },

    [SET_HIERARCHY]: (state, hierarchy) => {
      state.hierarchy = hierarchy
    },

    [SET_TOTAL]: (state, total) => {
      state.total = total
    },

    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async loadLocations(
      {
        commit,
        dispatch,
        state,
        rootState: {
          customers: { currentCustomerId },
        },
      },
      body,
    ) {
      await commit(SET_LOADING_LIST, true)
      try {
        const params = pickBy(
          (val) => not(isNil(val)) && not(isEmpty(val)),
          state.filters.filters,
        )
        const { filters, results, total } = await API.locations.listLocations({
          customerId: currentCustomerId,
          params,
          body,
        })

        if (total > 0) {
          await dispatch('locations/filtersData/setData', filters, {
            root: true,
          })
        }

        await commit(SET_LOCATIONS, results)
        await commit(SET_TOTAL, Number(total))
      } catch (err) {
        console.log(`error while loading locations: `, err)
      }
      await commit(SET_LOADING_LIST, false)
    },
    async loadHierarchy({
      commit,
      rootState: {
        customers: { currentCustomerId },
      },
    }) {
      try {
        if (currentCustomerId) {
          const { results } = await API.locations.listHierarchy({
            customerId: currentCustomerId,
          })
          await commit(SET_HIERARCHY, results)
        }
      } catch (err) {
        console.log(`error while loading default hierarchy: `, err)
      }
    },
    async loadUnmatchedLocations({ commit }, { customerId, hierarchyId }) {
      try {
        const { results } = await API.locations.unmatchedLocations(
          customerId,
          hierarchyId,
        )
        commit(SET_UNMATCHED_LOCATIONS, results)
      } catch (err) {
        console.log(`error while loading unmatched locations: `, err)
      }
    },
    async loadBillHistory(
      {
        commit,
        state,
        rootState: {
          customers: { currentCustomerId },
        },
      },
      { locationId, vaGroupId },
    ) {
      try {
        commit(SET_BILL_HISTORY_LOADING, true)
        const { data } = await API.locations.listBillHistory({
          customerId: currentCustomerId,
          locationId,
          vaGroupId,
        })
        await commit(SET_BILL_HISTORY, data)
      } catch (err) {
        console.log(`error while loading default hierarchy: `, err)
      } finally {
        commit(SET_BILL_HISTORY_LOADING)
      }
    },
    setBillHistory({ commit }, data) {
      commit(SET_BILL_HISTORY, data)
    },
  },

  getters: {
    locations: (state) => state.list,
    unMatchedLocations: (state) => state.unMatchedLocations,
    hierarchy: (state) => state.hierarchy,
    checkHierarchy: (state) => state.hierarchy.length,
    total: (state) => state.total,
    loading_list: (state) => state.loading_list,
    billHistory: (state) => state.billHistory,
    billHistoryLoading: (state) => state.billHistoryLoading,
  },
}
