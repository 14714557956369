<template lang="pug">
.form-wrapper.reset-password-page(:class='{ "is-changed": showPassChangedMessage, "is-expired": tokenExpired }')
  .icon-wrapper
    i.icon.icon-sam-bot
    i.icon.icon-lamp
  .form-container

    //- Expired token
    template(v-if='tokenExpired')
      .form
        .form-icon
          i.icon.icon-sam-bot-m-red
        h1.form-title The request has expired
        p.form-text Please try again to reset a password or login.
        router-link.form-link(:to='{name: "login"}') Go to Login
          i.icon.icon-forward

    //- Changed passowrd
    template(v-else-if='showPassChangedMessage')
      .form
        .form-icon
          i.icon.icon-sam-bot-m-green
        h1.form-title Your password has been changed successfully
        p.form-text Use your email and a new password to log in.
        router-link.form-link(:to='{name: "login"}') Go to Login
          i.icon.icon-forward

    template(v-else-if='!showPassChangedMessage')
      .form
        h1.form-title Please enter a new password
        .email-info
          span Account email
          span {{ email }}

        .form-fields
          .form-field
            .el-input.input-password
              input.el-input__inner(v-model='password',
                                    v-validate='validatePasswordField',
                                    :data-vv-delay='700',
                                    type='password',
                                    name='inputPassword',
                                    placeholder='Enter 8 or more characters or digits',
                                    tabindex='1',
                                    ref="inputPassword")
              button.button-clear(v-if='password.length && errors.has("inputPassword")',
                                  type='button',
                                  @click='resetField("password")')
              label Create password
              .form-error(v-if='errors.has("inputPassword") || emptySpaceError') The password must be between 8 and 31 characters and contain at least one upper-case character and one digit.
          .form-field
            .el-input.input-password
              input.el-input__inner(v-model='confirmPassword',
                                    v-validate='"confirmed:inputPassword"',
                                    :data-vv-delay='700',
                                    data-vv-as="inputPassword",
                                    type='password',
                                    name='inputConfirmPassword',
                                    placeholder='Passwords must match',
                                    tabindex='2')
              button.button-clear(v-if='confirmPassword.length && errors.has("inputConfirmPassword")',
                                  type='button',
                                  @click='resetField("confirmPassword")')
              label Confirm password
              .form-error(v-if='errors.has("inputConfirmPassword")') Passwords do not match.
        .form-actions
          el-button.button-login(type='primary',
                                tabindex='3',
                                :disabled='password !== confirmPassword || errors.has("inputPassword") || errors.has("inputConfirmPassword")',
                                @click.native='changePassword') Reset password
</template>

<script>
import { auth } from '@/api'

export default {
  data() {
    return {
      email: '',
      password: '',
      emptySpaceError: false,
      tokenExpired: false,
      confirmPassword: '',
      showPassChangedMessage: false,
      validatePasswordField: {
        min: 8,
        max: 31,
        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]\w+/,
      },
    }
  },
  watch: {
    password(val) {
      if (val.includes(' ')) {
        this.emptySpaceError = true
      } else {
        this.emptySpaceError = false
      }
    },
  },
  methods: {
    changePassword() {
      const { token } = this.$route.params
      if (!this.errors.has('inputConfirmPassword')) {
        auth.resetPassword(token, this.password).then(() => {
          this.showPassChangedMessage = true
        })
      }
    },
    resetField(field) {
      this[field] = ''
    },
  },
  async mounted() {
    const { token } = this.$route.params
    auth
      .checkResetToken(token)
      .then(({ email, new_user }) => {
        this.email = email
      })
      .catch((err) => {
        if ([410, 406, 404, 500].includes(err.response.data.statusCode)) {
          this.tokenExpired = true
        }
      })
  },
}
</script>

<style lang="scss" scoped>
.form-title {
  margin-bottom: 30px;
}
.form-actions {
  margin-top: 40px;
}
.form-link {
  font-size: 16px;
}
</style>
