<template lang="pug">
.filter-item-inner.search
  el-input.desktop(:placeholder="placeholder",
                  ref="searchInput",
                  data-action="filter-search",
                  :value="searchQuery"
                  @input="onSearch")
    i.el-input__icon.el-icon-search(slot="prefix")
  el-input.mobile(:placeholder="placeholder",
                  :value="searchQuery",
                  data-action="filter-search-mobile",
                  @input="onSearch")
    i.el-input__icon.el-icon-search(slot="prefix")
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  props: {
    searchQuery: { type: String },
    onChange: { type: Function },
    placeholder: { type: String, default: 'Search' },
  },
  mounted() {
    this.$refs.searchInput.$refs.input.addEventListener(
      'keyup',
      debounce((event) => {
        this.onChange({ query: event.target.value })
      }, 700),
    )
  },
  methods: {
    onSearch(query) {
      this.onChange({ query })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/utilities';

.desktop {
  @include respond-to($phone) {
    display: none;
  }
}
.mobile {
  display: none;
  width: 100%;

  @include respond-to($phone) {
    display: block;
  }
}
</style>
