<template lang="pug">
  .hierarchy-wrapper
    .hierarchy-container
      ul.hierarchy-list(v-if="tree")
        hierarchy-item(:node="tree",
          :selectedLocation="selectedLocation",
          :matched="matched",
          :nodeClick="nodeClick",
          :showInfo="showInfo")
</template>

<script>
import HierarchyItem from './HierarchyItem.vue'
import _ from 'lodash'

export default {
  components: {
    HierarchyItem,
  },
  props: {
    hierarchy: {
      type: Array,
      required: true,
      default: () => [],
    },
    locations: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedLocation: {
      type: Object,
      required: false,
      default: () => {},
    },
    matched: {
      type: Object,
      required: false,
      default: () => ({ query: '', matches: [] }),
    },
    nodeClick: {
      type: Function,
      required: false,
      default: () => {},
    },
    showInfo: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  computed: {
    tree() {
      const roots = []
      const list = this.hierarchy.slice()
      const map = new Map()
      list.forEach((node, i) => {
        map.set(node.id, i)
        node.children = []
        if (node.parentId) {
          list[map.get(node.parentId)].children.push(node)
        } else {
          roots.push(node)
        }
      })

      /**
       * Return first hierarchy,
       * as we show only default hierarchy for now
       */
      return _.head(roots)
    },
  },
}
</script>

<style lang="scss" scoped>
.hierarchy-container {
  -webkit-overflow-scrolling: touch;
  padding: 0;

  ul {
    list-style: none;
    padding: 0;

    ul {
      list-style: none;
    }
  }
}
</style>
