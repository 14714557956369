<template lang="pug">
.filter-item.filter-item-date
  .filter-title(v-if="title") {{ title }}
  .date-picker
    el-date-picker(v-model="year",
                    format="yyyy",
                    value-format="yyyy",
                    type="year",
                    size="mini",
                    data-value="select-year",
                    placeholder="Select Year")
</template>

<script>
import _ from 'lodash'
import FilterDatepicker from '@/components/Filters/FilterDatepicker'
export default {
  components: {
    FilterDatepicker,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    this.filter.year = +`${_.get(this.value, 'year', new Date().getFullYear())}`
  },
  data() {
    return {
      year: `${_.get(this.value, 'year', new Date().getFullYear())}`,
    }
  },
  watch: {
    value() {
      this.year = `${_.get(this.value, 'year', new Date().getFullYear())}`
    },
    year(val) {
      this.filter.year = val || `${new Date().getFullYear()}`
    },
  },
}
</script>

<style scoped lang="scss">
.filter-item.filter-item-date {
  .date-picker {
    > div:first-child {
      margin-right: 16px;
    }
    ::v-deep .el-date-editor {
      input {
        padding: 0 10px 0 30px;
      }
    }
  }
}
</style>
