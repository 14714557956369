<template>
  <el-form label-position="top">
    <el-form-item label="Historical Data:">
      <template #label>
        <div>
          <label>Method to define unit cost</label>
          <button-with-popover
            icon="el-icon-info"
            placement="top"
            width="300"
            trigger="hover"
            content="How unit cost is determined for finding the budgeted cost.
            Regression refers to finding the fixed and variable portion of the unit cost for each vendor and tariff rate (recommended).
            Other methods refer to using the maximum, median, or the most recent unit costs for each account."
          />
        </div>
      </template>
      <el-select
        class="unit-cost"
        v-model="unitCostConfig.unitCostType"
        size="small"
        filterable
        clearable
      >
        <el-option
          v-for="unitCostOption in unitCostOptions"
          :key="unitCostOption.value"
          :value="unitCostOption.value"
          :label="unitCostOption.label"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Adjustment Rate:">
      <el-input
        class="adjustment-rate"
        placeholder="0"
        v-model.number="unitCostConfig.adjustmentRate"
        size="small"
        maxlength="3"
      >
        <i slot="suffix" class="el-input__icon el-icon-percentage"></i>
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import ButtonWithPopover from '../../../../../components/Buttons/ButtonWithPopover'
import { unitCostConfigDefault } from '../../Constants/Defaults'
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    unitCostConfigRefresh: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    opened: {
      handler() {
        Object.assign(this.unitCostConfig, this.unitCostConfigRefresh)
      },
      immediate: true,
    },
  },
  components: { ButtonWithPopover },
  data() {
    return {
      unitCostOptions: [
        { value: 'rateRegression', label: 'Regression (recommended)' },
        { value: 'max', label: 'Max' },
        { value: 'median', label: 'Median' },
        { value: 'last', label: 'Most Recent' },
      ],
      unitCostConfig: {
        ...unitCostConfigDefault,
      },
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.el-icon-percentage {
  &:before {
    content: '%';
    color: #606266;
  }
  &:after {
    content: '';
    height: 100%;
    width: 0;
    display: inline-block;
    vertical-align: middle;
  }
}
.adjustment-rate {
  width: 75px;
}
.unit-cost {
  width: 220px;
}
</style>
