import { hosts } from '@/lib/api.configs'
import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'
import qs from 'qs'

/**
 * @description get embed token for PowerBI
 * @param {string} customerId
 */
export const getEmbedToken = ({ customerId, params }) =>
  http(hosts.v3)
    .get(
      `/power-bi/${customerId}/get-embed-token?${qs.stringify(params, {
        arrayFormat: 'repeat',
      })}`,
    )
    .then(dataMiddleware)

/**
 * @description get list of reports
 * @param {string} customerId
 */
export const getPowerbiReports = ({ customerId }) =>
  http(hosts.v3).get(`/power-bi/${customerId}/reports`).then(dataMiddleware)
