import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'
import { hosts } from '@/lib/api.configs'

const list = (params) =>
  http(hosts.v1).get('/observations', { params }).then(dataMiddleware)

const get = (id) =>
  http(hosts.v1).get(`observations/${id}`).then(dataMiddleware)

export { list, get }
