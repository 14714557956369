import { productionData } from '../../api'

const initialState = () => ({
  loading: false,
  list: [],
  hasMonthlyAttributes: false
})

export default {
  namespaced: true,
  state: initialState(),

  getters: {
    loading: (state) => state.loading,
    list: (state) => state.list,
    hasMonthlyAttributes: (state) => state.hasMonthlyAttributes
  },

  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    },

    setMonthlyAttributes(state, list) {
      state.list = list
    },

    setHasMonthlyAttributes(state, hasMonthlyAttributes) {
      state.hasMonthlyAttributes = hasMonthlyAttributes
    },

    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async fetchMonthlyAttributes({ commit }, { customerId, locationId }) {
      try {
        commit('setLoading', true)
        const data = await productionData.getMonthlyAttributesList(customerId, locationId)
        await commit('setMonthlyAttributes', data.results || [])
        await commit('setHasMonthlyAttributes', data.hasMonthlyAttributes)
        commit('setLoading', false)
      } catch (e) {
        await commit('setMonthlyAttributes', [])
        commit('setLoading', false)
        console.log(`error while loading monthly attributes: `, e)
      }
    },
    async updateMonthlyAttributesValues({ commit }, { customerId, locationId, body }) {
      try {
        commit('setLoading', true)
        await productionData.updateMonthlyAttributesValues(customerId, locationId, body)
      } catch (e) {
        commit('setLoading', false)
        console.log(`error while updating monthly attributes: `, e)
      }
    },
    async addYearMonths({ commit }, { customerId, locationId, body }) {
      try {
        commit('setLoading', true)
        const data = await productionData.addYearMonths(customerId, locationId, body)
        await commit('setMonthlyAttributes', data.results)
        commit('setLoading', false)
      } catch (e) {
        commit('setLoading', false)
        console.log(`error while adding year and months: `, e)
      }
    },
  },
}
