<template>
  <div class="powerbi-wrapper">
    <div class="report-container" id="reportContainer"></div>
    <select-report-modal
      v-if="$route.name === 'dashboard.analytics.changeReportModal'"
      @changedPowerbiReportId="onChangedPowerbiReportId"
    />
  </div>
</template>

<script>
import checkMobileDevice from '@/mixins/checkMobileDevice'
import { mapActions, mapGetters } from 'vuex'
import { analytics } from '@/api'
import { models } from 'powerbi-client'
import BaseModal from '../../../components/BaseModal'
import SelectReportModal from './Modals/SelectReportModal'

export default {
  components: { SelectReportModal, BaseModal },
  mixins: [checkMobileDevice],
  props: {},
  data() {
    return {
      emptyLabel: '—',
      emptyValue: '',
      showErrors: false,
      changeReportModalVisible: false,
      reports: null,
    }
  },
  async mounted() {
    await this.loadPowerbiReports(this.currentCustomerId)
    const defaultPbiReport = this.isPowerBIEntitlement ? this.getPowerbiReports.find(
      (powerBiDefaultReport) => powerBiDefaultReport.isDefault,
    ) : this.getPowerbiReports[0]
    this.setCurrentPowerbiReportId(defaultPbiReport.id)
    await this.loadReport()
  },
  methods: {
    ...mapActions({
      setCurrentPowerbiReportId: 'powerbi/setCurrentPowerbiReportId',
      loadPowerbiReports: 'powerbi/loadPowerbiReports',
    }),
    async loadReport() {
      let tokenData = null
      try {
        if (this.getCurrentPowerbiReport !== null) {
          tokenData = await analytics.getEmbedToken({
            customerId: this.currentCustomerId,
            params: {
              reportId: this.getCurrentPowerbiReport.reportId,
              groupId: this.getCurrentPowerbiReport.groupId,
              datasetId: this.getCurrentPowerbiReport.datasetId,
            },
          })

          if (!tokenData) {
            throw new Error('Report embed token not found')
          }
        } else {
          tokenData = await analytics.getEmbedToken({
            customerId: this.currentCustomerId,
          })
          if (!tokenData) {
            throw new Error('Report embed token not found')
          }
        }
        this.$message({
          showClose: true,
          message: `Selected report changed to: "${this.getCurrentPowerbiReport.name}"`,
          type: 'success',
        })
      } catch (e) {
        return this.$message({
          showClose: true,
          message: `Report "${this.getCurrentPowerbiReport.name}" failed to download!`,
          type: 'error',
        })
      }

      const embedConfiguration = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: tokenData.embed_token,
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${tokenData.report_id}&groupId=${tokenData.group_id}&datasetId=${tokenData.dataset_id}`,
        id: tokenData.report_id,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: true,
            },
            pageNavigation: {
              visible: true,
            },
          },
        },
      }

      const powerBiClient = window.powerbi
      const embedContainer = document.getElementById('reportContainer')

      powerBiClient.embed(embedContainer, embedConfiguration)

      const report = powerBiClient.get(embedContainer)

      if (this.getCurrentPowerbiReportId === null) {
        report.on('error', this.onReportError)
      }
    },
    onReportError(errorObject) {
      const err = errorObject.detail
      console.error(
        `PowerBI Error: ${err.message}. Detailed message: ${err.detailedMessage}`,
      )
    },
    onChangedPowerbiReportId() {
      this.loadReport()
    },
  },
  computed: {
    ...mapGetters({
      currentCustomerId: 'customers/currentCustomerId',
      getCurrentPowerbiReportId: 'powerbi/getCurrentPowerbiReportId',
      getCurrentPowerbiReport: 'powerbi/getCurrentPowerbiReport',
      getPowerbiReports: 'powerbi/getPowerbiReports',
      isPowerBIEntitlement: 'customers/isPowerBIEntitlement',
    }),
  },
}
</script>

<style lang="scss" scoped>
.powerbi-wrapper {
  margin: 24px;
  min-height: calc(100vh - 48px);
  max-height: calc(100vh - 48px);
}

.report-container {
  height: calc(100vh - 48px);
}

@media (max-width: 600px) {
  //header added on mobile devices need to extract more height
  .powerbi-wrapper {
    min-height: calc(100vh - 48px - 74px);
    max-height: calc(100vh - 48px - 74px);
  }
  .report-container {
    height: calc(100vh - 48px - 74px);
  }
}
</style>
