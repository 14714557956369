<template>
  <div>
    <a
      class="status-done"
      v-if="showDownloadBtn"
      :href="report.url"
      data-element="report-link"
    >
      Download
    </a>
    <span
      v-else
      :title="title"
      :class="`status-${report.status.toLowerCase()}`"
    >
      {{ report.status }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    showDownloadBtn() {
      return this.report.status === 'Done'
    },
    title() {
      return this.report.status === 'Error' ? this.report.reason : ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/report-statuses';
</style>
