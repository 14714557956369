<template>
  <BaseModal
    v-if="showModal"
    :dialogVisible="showModal"
    :property="property"
    :title="title"
    @close="close"
    id="createEnergyStarModal"
    class="create-energy-star-modal"
  >
    <template #content>
      <ErrorCard :statusMessage="createError" />
      <p>
        Properties come in all shapes and sizes, from leased space in a large
        office building, to a K-12 school with a pool, to a large medical
        complex with lots of buildings. Since there are so many choices,
        Portfolio Manager can walk you through getting your property up and
        running. When you’re done, you’ll be ready to start monitoring your
        energy usage and pursue recognition.
      </p>
      <validation-observer ref="observer">
        <el-form ref="form" label-width="120px">
          <h2 class="subtitle">Your property use</h2>
          <h3>What is the primary function of your property?</h3>
          <BaseSelect
            label="Property Type:"
            name="propertyType"
            size="small"
            :required="true"
            :isEditMode="true"
            rules="required"
            v-model="form.primaryFunction"
          >
            <el-option
              v-for="item in primaryFunction"
              :key="item"
              :label="item"
              :value="item"
            />
          </BaseSelect>
          <h2 class="subtitle">About your property</h2>
          <BaseInput
            v-model="form.name"
            placeholder="Enter location name"
            :required="true"
            maxlength="80"
            rules="required"
            name="propertyName"
            label="Name:"
            size="medium"
            :isEditMode="isEditMode"
          />

          <BaseSelect
            v-model="form.address.country"
            label="Country:"
            name="country"
            size="small"
            :required="!isUpdateMode"
            @change="handleChange"
            :isEditMode="!isUpdateMode"
          >
            <el-option
              v-for="(v, k) in countries"
              :key="k"
              :label="v.pretty_name"
              :value="k"
            />
          </BaseSelect>

          <BaseInput
            v-model="form.address.address1"
            placeholder="Enter location address"
            rules="required"
            :required="true"
            name="address1"
            maxlength="100"
            label="Street Address:"
            size="medium"
            :isEditMode="isEditMode"
          />

          <BaseInput
            v-model="form.address.city"
            placeholder="Enter location city"
            rules="required"
            :required="true"
            label="City:"
            name="city"
            size="medium"
            maxlength="100"
            :isEditMode="isEditMode"
          />

          <BaseInput
            v-model="form.address.county"
            placeholder="Enter location county"
            label="County:"
            name="county"
            maxlength="40"
            size="medium"
            :isEditMode="isEditMode"
          />

          <BaseSelect
            v-model="form.address.state"
            label="State:"
            name="state"
            size="small"
            :required="true"
            :isEditMode="true"
            rules="required"
          >
            <el-option
              v-for="v in states"
              :key="v.abbreviation"
              :label="v.pretty_name"
              :value="v.abbreviation"
            />
          </BaseSelect>

          <BaseInput
            v-model="form.address.postalCode"
            placeholder="Enter postal code"
            :required="true"
            rules="required"
            name="postalCode"
            maxlength="20"
            label="Postcode:"
            size="medium"
            :isEditMode="isEditMode"
          />

          <BaseInput
            v-model="form.yearBuilt"
            :required="true"
            placeholder="Enter Year Built"
            rules="required|numeric|digits:4|min_value:1000"
            name="yearBuilt"
            label="Year Built:"
            size="medium"
            :isEditMode="isEditMode"
          />

          <div class="row">
            <div style="flex-grow: 2">
              <BaseInput
                v-model="form.grossFloorArea.value"
                classes="mediumInput"
                placeholder="Enter gross floor area"
                :required="true"
                rules="required|numeric"
                name="gross"
                maxlength="9"
                label="Gross Floor Area:"
                size="medium"
                :isEditMode="isEditMode"
              />
            </div>

            <div style="flex-grow: 1">
              <BaseSelect
                v-model="form.grossFloorArea.units"
                label="Unit:"
                labelWidth="60px"
                classes="mediumInput"
                name="grossUnit"
                size="small"
                :required="true"
                :isEditMode="true"
                rules="required"
              >
                <el-option
                  v-for="(v, k) in units"
                  :key="k"
                  :value="v.name"
                  :label="v.name"
                />
              </BaseSelect>
            </div>
            <div style="flex-grow: 1">
              <BaseCheckbox
                label="Temporary value:"
                :isEditMode="true"
                :labelWidth="'123px'"
                v-model="form.grossFloorArea.temporary"
                :value="form.grossFloorArea.temporary"
                :disabled="!isEditMode"
              />
            </div>
          </div>
          <p>
            Gross Floor Area (GFA) is the total property floor area, measured
            from the principal exterior surface of the building(s). Do not
            include parking.
          </p>

          <div class="row wide" v-if="form.irrigatedArea">
            <BaseInput
              v-model="form.irrigatedArea.value"
              classes="mediumInput"
              placeholder="Enter irrigated area"
              maxlength="9"
              rules="|numeric"
              name="irrigated"
              label="Irrigated Area:"
              size="medium"
              :isEditMode="isEditMode"
            />
            <BaseSelect
              v-model="form.irrigatedArea.units"
              label="Unit:"
              labelWidth="60px"
              classes="mediumInput"
              name="irrUnit"
              size="small"
              :isEditMode="true"
            >
              <el-option
                v-for="(v, k) in units"
                :key="k"
                :value="v.name"
                :label="v.name"
              />
            </BaseSelect>
          </div>
          <BaseSelect
            v-model="form.occupancyPercentage"
            label="Enter Occupancy (%):"
            classes="shortInput"
            name="occupancyPercentage"
            size="small"
            :required="true"
            :isEditMode="true"
            rules="required"
          >
            <el-option
              v-for="(v, k) in occupancyPrc"
              :key="k"
              :value="v"
              :label="v"
            />
          </BaseSelect>
          <h2>Your Property's Buildings</h2>
          <p>
            How many physical buildings do you consider part of your property?
          </p>
          <div class="row wide">
            <input
              type="radio"
              id="buildings"
              v-model="form.buildings"
              name="buildings"
              value="1"
            />
            <label for="buildings">
              <span class="bold">One:</span>
              My property is a single building
            </label>
          </div>
        </el-form>
      </validation-observer>
    </template>
    <template #footer>
      <div>
        <div class="row">
          <div class="controls">
            <el-button type="primary" @click="handleSubmit">
              {{ isUpdateMode ? 'Update' : 'Create' }}
            </el-button>
            <el-button id="resetBtn" @click="reset">Reset</el-button>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>
<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import * as R from 'ramda'
import BaseModal from '@/components/BaseModal'
import ErrorCard from '@/components/Cards/ErrorCard'
import BaseInput from '@/components/FormComponents/BaseInput'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import BaseCheckbox from '@/components/FormComponents/BaseCheckbox'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { energystar } from '@/api'
const initialFormData = () => ({
  name: '',
  primaryFunction: '',
  address: {
    city: '',
    country: 'US',
    state: '',
    county: '',
    address1: '',
    postalCode: '',
  },
  year: '',
  grossFloorArea: {
    temporary: false,
    units: 'Square Feet',
    value: '',
  },
  irrigatedArea: {
    units: 'Square Feet',
    value: '',
  },
  occupancyPercentage: '',
  buildings: '1',
})
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BaseModal,
    ErrorCard,
    BaseInput,
    BaseSelect,
    BaseCheckbox,
  },
  props: {
    property: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    isUpdateMode: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  data() {
    return {
      createError: '',
      isEditMode: true,
      form: initialFormData(),
      showModal: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      getResourceByName: 'resources/getResourceByName',
    }),
    primaryFunction: {
      get() {
        return [
          'College/University',
          'Data Center',
          'Hotel',
          'K-12 School',
          'Multifamily Housing',
          'Non-Refrigerated Warehouse',
          'Office',
          'Other',
          'Residence Hall/Dormitory',
          'Retail Store',
          'Supermarket/Grocery Store',
          'Worship Facility',
          'Hospital (General Medical & Surgical)',
          'Parking',
          'Convenience Store with Gas Station',
          'Convenience Store without Gas Station'
        ]
      },
    },
    units: {
      get() {
        return [{ name: 'Square Feet' }, { name: 'Square Meters' }]
      },
    },
    occupancyPrc: {
      get() {
        return [
          0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85,
          90, 95, 100,
        ]
      },
    },
    standardIds: {
      get() {
        return []
      },
    },
    countries: {
      get() {
        let countries = R.propOr(
          {},
          'value',
          this.getResourceByName('countries'),
        )
        // energy star only available in US and CA
        return Object.assign({}, { US: countries['US'], CA: countries['CA'] })
      },
    },
    states: {
      get() {
        return this.loadStates()
      },
    },
  },
  async mounted() {
    this.loadStates()
    this.loading = false
  },
  async beforeUpdate() {
    if (this.property && this.property.hasOwnProperty('id')) {
      let propertyAtts = _.cloneDeep(this.property)
      this.form = { ...this.form, ...propertyAtts }
      this.form.grossFloorArea.temporary =
        this.form.grossFloorArea.temporary.toString() === 'true'
      this.isUpdateMode = true
    }
    this.loading = false
  },
  methods: {
    handleChange() {
      this.form.address.state = ''
    },
    loadStates() {
      const { country } = this.form.address || 'US'
      if (!country) return {}
      const states = R.pipe(
        R.propOr({}, 'value'),
        R.pickBy((value, key) => R.startsWith(country, key)),
      )(this.getResourceByName('states'))
      return states
    },
    handleInputChange(value) {
      this.form.grossFloorArea.temporary = value
    },
    async handleSubmit() {
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.createError = 'Please complete all required fields'
        return
      }
      this.isLoading = true
      const { customerId, locationId } = this.$route.params
      let body = {
        locationId: locationId,
        name: this.form.name,
        address: {
          address1: this.form.address.address1,
          city: this.form.address.city,
          state: this.form.address.state,
          county: this.form.address.county,
          postalCode: this.form.address.postalCode,
          country: this.form.address.country,
        },
        yearBuilt: Number(this.form.yearBuilt),
        grossFloorArea: {
          value: Number(this.form.grossFloorArea.value),
          units: this.form.grossFloorArea.units,
          temporary: this.form.grossFloorArea.temporary,
        },
        occupancyPercentage: Number(this.form.occupancyPercentage),
        primaryFunction: this.form.primaryFunction,
        constructionStatus: 'Existing',
      }
      if (this.form.irrigatedArea && this.form.irrigatedArea.value) {
        body.irrigatedArea = {
          value: Number(this.form.irrigatedArea.value),
          units: this.form.irrigatedArea.units,
        }
      }
      try {
        let property
        if (this.property.id) {
          property = await energystar.updateProperty({
            customerId,
            propertyId: this.property.id,
            body,
          })
        } else {
          property = await energystar.createProperty({ customerId, body })
        }
        if (property && property.id) {
          this.showModal = false
          this.createError = ''
          this.$emit('close', 'updatedEnergyStar', property)
        } else {
          // TODO: update after API errors are defined clearly to show correct error
          this.createError =
            'Cannot ' +
            (this.isUpdateMode ? 'Update' : 'Create') +
            ' Energystar property'
        }
      } catch (e) {
        this.createError =
          'Cannot ' +
          (this.isUpdateMode ? 'Update' : 'Create') +
          ' Energystar property'
      } finally {
        this.$refs.observer.reset()
        this.isLoading = false
      }
    },
    initForm() {
      this.createError = ''
      if (this.isUpdateMode) {
        let propertyAtts = _.cloneDeep(this.property)
        this.form = { ...initialFormData(), ...propertyAtts }
        this.form.grossFloorArea.temporary =
          this.form.grossFloorArea.temporary.toString() === 'true'
      } else {
        this.form = initialFormData()
      }
    },
    reset() {
      if (this.isUpdateMode) {
        let propertyAtts = _.cloneDeep(this.property)
        this.form = { ...initialFormData(), ...propertyAtts }
        this.form.grossFloorArea.temporary =
          this.form.grossFloorArea.temporary.toString() === 'true'
      } else {
        this.form = initialFormData()
      }
      this.createError = ''
      this.$refs.observer.reset()
    },
    close() {
      this.$emit('close', 'showCreateEnergyStarModal')
      this.createError = ''
      this.initForm()
    },
  },
}
</script>
<style lang="scss" scoped>
.create-energy-star-modal {
  ::v-deep {
    .el-dialog {
      &__body {
        max-height: calc(75vh - 75px);
        overflow-y: auto;
      }
    }
    .el-select-dropdown.el-popper {
      position: absolute !important;
      top: 30px !important;
      left: 0 !important;
    }
  }
  form {
    .alignMiddle {
      margin-top: 10px;
    }
    .row {
      display: inline-flex;
      align-items: center;
      width: 100%;
      .wide {
        justify-content: start;
      }
      .form-group {
        label {
          word-break: break-word;
        }
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        &:first-child {
          margin-left: 20px;
        }
        .bold {
          font-weight: bold;
        }
      }
    }
  }
  .form-item {
    padding: 0 15px 0 0;
  }
  .controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    button {
      height: 30px;
      width: 170px;
      padding: 0;
      margin: 0 20px;
    }
  }
}
</style>
