<template>
  <div>
    <el-form
      :model="newBudgetCreatorForm"
      :rules="rules"
      :hide-required-asterisk="true"
      ref="createBudgetRef"
      label-position="top"
      size="small"
    >
      <h3 v-if="isOnCreateBudgetTab">
        Choose a name for your budget
        <form-required-asterisk />
      </h3>
      <el-form-item
        class="form-item-width"
        label="Budget Name:"
        prop="budgetName"
      >
        <el-input
          v-model="newBudgetCreatorForm.budgetName"
          placeholder="e.g. Budget Forecast"
          maxlength="50"
        />
      </el-form-item>
      <h3 v-if="isOnCreateBudgetTab">
        Choose the starting month of your fiscal year
        <form-required-asterisk />
      </h3>
      <el-form-item label="Start Month:" prop="budgetStartdt">
        <el-date-picker
          v-model="newBudgetCreatorForm.budgetStartdt"
          format="MM/yyyy"
          type="month"
          placeholder="Pick a Start Month"
        />
      </el-form-item>
      <el-button class="btn-text__bold" type="text" @click="openAdvancedOptionsModal">
        Advanced Options
      </el-button>
      <div class="create-budget">
        <el-button
          class="btn-text__bold btn__width"
          type="primary"
          :disabled="isValid"
          @click="onSubmit(budgetingTemplate)"
        >
          Create Budget Forecast
        </el-button>
      </div>
      <advanced-options
        :is-visible="isVisible"
        :budget-type="budgetingTemplate.title"
        :available-filters="availableFilters"
        :advanced-options="advancedOptions"
        @close="isVisible = false"
        @confirm="onConfirm"
      />
    </el-form>
  </div>
</template>

<script>
import FormRequiredAsterisk from '../../../../../components/FormComponents/FormRequiredAsterisk'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import { DateTime, LocalZone } from 'luxon'
import AdvancedOptions from '../AdvancedOptions'
import {
  appliedFiltersDefault,
  usageConfigDefault,
  unitCostConfigDefault,
} from '../../Constants/Defaults'

export default {
  components: { AdvancedOptions, FormRequiredAsterisk },
  props: ['isOnCreateBudgetTab', 'budgetingTemplate'],
  computed: {
    ...mapGetters({
      templates: 'createBudgeting/budgetingTemplates',
      availableFilters: 'availableFiltersReportsBudgeting/availableFilters'
    }),
    isValid() {
      return (
        this.newBudgetCreatorForm.budgetStartdt === '' ||
        this.newBudgetCreatorForm.budgetName === ''
      )
    },
  },
  data() {
    return {
      isVisible: false,
      currentReport: {},
      rules: {
        budgetName: {
          required: true,
          message: 'Budget Name required.',
          trigger: ['blur'],
        },
        budgetStartdt: {
          required: true,
          message: 'Start Month required.',
          trigger: ['change'],
        },
      },
      localTimeZone: new LocalZone(),
      advancedOptions: {
        advancedFilters: {
          ...appliedFiltersDefault,
        },
        unitCostConfig: {
          ...unitCostConfigDefault,
        },
        usageConfig: {
          ...usageConfigDefault,
        },
      },
      newBudgetCreatorForm: {
        budgetName: '',
        budgetStartdt: '',
      },
    }
  },
  methods: {
    ...mapActions({
      createNewBudgetingReport: 'createBudgeting/createNewBudgetingReport',
      loadReportAvailableValues: 'availableFiltersReportsBudgeting/getTemplateAvailableValues'
    }),
    ...mapMutations({
      setLoading: 'createBudgeting/setLoading'
    }),
    onConfirm(advOptions) {
      this.isVisible = false
      this.advancedOptions = advOptions
    },
    utilityTypePicker(utilityType) {
      if (
        [8110, 7033, 51024, 46068, 117].includes(
          +this.$route.params.customerId,
        ) &&
        utilityType.includes('all')
      ) {
        const accLevelBudget = this.templates.find(
          (template) => template.readableId === 'CUSTOMER_ACCOUNT_LEVEL_BUDGET',
        )
        for (let filters of accLevelBudget.availableFilters) {
          for (let filter of filters) {
            if (filter.key === 'utility_type') {
              return ['ELECTRIC']
            }
          }
        }
      }
      if (!utilityType.includes('all')) {
        return utilityType
      }
      return []
    },
    async openAdvancedOptionsModal () {
      this.setLoading(true)
      const { customerId } = this.$route.params
      await this.loadReportAvailableValues({ customerId, templateId: this.budgetingTemplate.id })
      this.isVisible = true
      this.setLoading(false)
    },
    historicalDatesPicker() {
      let { historicalDates } = this.advancedOptions.usageConfig
      const [startDate, endDate] = historicalDates
      if (
        this.advancedOptions.usageConfig.historicalData === 'all' &&
        [7033, 8110, 117].includes(+this.$route.params.customerId)
      ) {
        return [
          DateTime.fromJSDate(new Date()).minus({ year: 5 }).toISO(),
          DateTime.fromJSDate(new Date()).toISO(),
        ]
      }
      if (this.advancedOptions.usageConfig.historicalData !== 'all') {
        return [
          DateTime.fromJSDate(startDate).toISO(),
          DateTime.fromJSDate(endDate).toISO(),
        ]
      }
      return []
    },
    onSubmit(budgetingTemplate) {
      this.$refs.createBudgetRef.validate((valid) => {
        if (!valid) {
          return false
        }
        const budgetStartdt = DateTime.fromJSDate(
          this.newBudgetCreatorForm.budgetStartdt,
        ).toISO()
        const body = {
          customerId: this.$route.params.customerId,
          reportTemplateId: this.budgetingTemplate.id,
          customReportName: this.newBudgetCreatorForm.budgetName,
          timezone: this.localTimeZone.name,
          configPayload: {
            budgetStartdt,
          },
          appliedFilters: {
            ...appliedFiltersDefault,
          },
        }
        const advancedOptionsCheck =
          !_.isNil(this.advancedOptions) &&
          !_.isNil(this.advancedOptions.advancedFilters) &&
          !_.isNil(this.advancedOptions.unitCostConfig) &&
          !_.isNil(this.advancedOptions.usageConfig)
        if (advancedOptionsCheck) {
          const { utility_type, vendor, location_id } =
            this.advancedOptions.advancedFilters
          const { adjustmentRate } = this.advancedOptions.unitCostConfig
          body.appliedFilters = {
            ...this.advancedOptions.advancedFilters,
            utility_type: this.utilityTypePicker(utility_type),
            vendor: vendor.includes('all') ? [] : vendor,
            location_id,
          }
          body.configPayload = {
            ...this.advancedOptions.usageConfig,
            ...this.advancedOptions.unitCostConfig,
            budgetStartdt,
            historicalDates: this.historicalDatesPicker(),
            adjustmentRate: !_.isNil(adjustmentRate)
              ? +(
                this.advancedOptions.unitCostConfig.adjustmentRate / 100
              ).toFixed(2)
              : undefined,
            historicalData: undefined,
            aggregationLevel: this.budgetingTemplate.readableId,
          }
        }
        this.createNewBudgetingReport(body)
        this.$refs.createBudgetRef.resetFields()
        this.newBudgetCreatorForm = { budgetName: '', budgetStartdt: '' }
        this.advancedOptions.advancedFilters = { ...appliedFiltersDefault }
        this.advancedOptions.usageConfig = { ...usageConfigDefault }
        this.advancedOptions.unitCostConfig = { ...unitCostConfigDefault }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.form-item-width {
  width: 220px;
}
.create-budget {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
}
.btn {
  &-text {
    &__bold {
      font-weight: 600;
    }
  }
  &__width {
    width: 200px;
  }
}
</style>
