<template>
  <div class="widget-container" :class="{ draggable }" ref="widget-container">
    <div class="bar-chart" ref="bar-chart"></div>
  </div>
</template>

<script>
import * as d3 from 'd3'

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      default: 580,
    },
    height: {
      type: Number,
      default: 320,
    },
    barWidth: {
      type: Number,
      default: 55,
    },
    barItemWidth: {
      type: Number,
      default: 15,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Object,
      default: () => ({ x: 'location', y: 'costs' }),
    },
    detailView: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    data() {
      this.redrawChart()
    },
    width() {
      this.redrawChart()
    },
  },
  mounted() {
    this.printChart()
  },
  methods: {
    redrawChart() {
      this.$refs['bar-chart'].innerHTML = ''
      this.printChart()
    },
    getBarLabelText(d) {
      switch (this.labels.y) {
        case 'costs':
          return d3.format('$,.2f')(d.costs)
        case 'consumption':
          return `${d3.format(',.2f')(d.consumption)} ${d.uom}`
        case 'unitCosts':
          return `${d3.format(',.2f')((+d.value || 0).toFixed(2))} $/${d.uom}`
        default:
          return d[this.labels.y]
      }
    },
    getBarLabelTextUom(d) {
      switch (this.labels.y) {
        case 'costs':
          return ''
        case 'consumption':
          return `${d.uom}`
        case 'unitCosts':
          return `$/${d.uom}`
        default:
          return d[this.labels.y]
      }
    },
    calcTitleWidth(d) {
      switch (this.labels.y) {
        case 'costs':
          return d3.format('$,.2f')(d.costs).length * 6.8
        case 'consumption':
          return `${d3.format(',.2f')(d.consumption)} ${d.uom}`.length * 6.4
        case 'unitCosts':
          return `${(+d.value || 0).toFixed(2)} $/${d.uom}`.length * 6.4
        default:
          return `${d[this.labels.y]}`.length * 6.4
      }
    },
    defineXAxisTransform() {
      switch (this.labels.x) {
        case 'month':
          return 'translate(10 15)'
        default:
          return 'translate(-10 15) rotate(-90)'
      }
    },
    formatData(d) {
      if (d < 1) {
        return this.labels.y === 'costs'
          ? d3.format('$,.2r')(d)
          : d3.format(',.2r')(d)
      }
      return this.labels.y === 'costs'
        ? d3.format('$,~s')(d)
        : d3.format('~s')(d)
    },
    printChart() {
      const instance = this
      const barChart = this.$refs['bar-chart']
      const svgHeight = this.height
      const barWidth = this.barWidth
      const barItemWidth = this.barItemWidth
      const margin = { left: 60, bottom: 100 }

      let data = this.data
      let svgWidth = this.width

      if (this.draggable) {
        svgWidth = (data.length + 1) * barWidth
      }

      let scaleFlag = false
      let barQty = parseInt(svgWidth / barWidth)
      barQty = data.length < 12 ? data.length : 12
      const realWidth = barWidth * barQty
      if (realWidth > svgWidth) {
        scaleFlag = true
      }

      if (!this.draggable) {
        data = data.slice(0, barQty)
      }

      const yScale = d3
        .scaleLinear()
        .domain([0, d3.max(data, (e) => Number(e.value)) || 1])
        .nice()
        .range([svgHeight - margin.bottom - 24, 0])

      let xWidth = realWidth > svgWidth - 80 ? svgWidth - 80 : realWidth
      if (this.detailView) {
        xWidth = svgWidth - 80
      }
      const xScale = d3
        .scaleBand()
        .range([0, xWidth])
        .domain(data.map((e) => e[this.labels.x]))

      const svg = d3
        .select(barChart)
        .append('svg')
        .attr('class', 'chart')
        .attr('width', svgWidth)
        .attr('height', svgHeight)

      if (scaleFlag) {
        svg
          .attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`)
          .attr('preserveAspectRatio', 'xMinYMin meet')
      } else {
        svg
          .attr('viewBox', `0 0 ${realWidth} ${svgHeight}`)
          .attr('preserveAspectRatio', 'xMinYMin meet')
      }

      const axisX = svg
        .append('g')
        .attr(
          'transform',
          `translate(${margin.left} ${svgHeight - margin.bottom})`,
        )
        .attr('class', 'axisX')
        .call(d3.axisBottom(xScale).tickSizeInner(5).tickSizeOuter(10))
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('transform', this.defineXAxisTransform())

      const { width: axisX_width } = barChart
        .querySelector('.axisX')
        .getBoundingClientRect()
      const axisX_ticks = axisX.size()
      const lineDecorPosition = parseInt(axisX_width / axisX_ticks / 2)

      svg
        .selectAll('.axisX .tick:not(:last-child)')
        .append('line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 1)
        .attr('y2', 80)
        .attr('stroke', '#d8d8d8')
        .attr('transform', `translate(${lineDecorPosition} 0)`)

      svg.selectAll('.axisX .tick text').call(wrap, margin.bottom - 20)

      let tooltip

      const barContainer = svg
        .selectAll('.bar-container')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'bar-container')
        .attr(
          'transform',
          (d) => `translate(${xScale(d[this.labels.x]) + margin.left}, 0)`,
        )

      barContainer
        .data(data)
        .append('rect')
        .attr('class', 'bar')
        .attr('x', lineDecorPosition - barItemWidth / 2)
        .attr('y', (d) => yScale(d.value) + 24)
        .attr('width', barItemWidth)
        .attr('height', (d) =>
          Math.abs(
            Math.round(svgHeight - margin.bottom - yScale(d.value)) - 24,
          ),
        )
        .on('mouseover', function () {
          tooltip.style('display', null)
        })
        .on('mouseout', function () {
          tooltip.style('display', 'none')
        })
        .on('mousemove', function (d) {
          const textWidth = instance.calcTitleWidth(d)
          const params = barChart
            .querySelector('.axisX')
            .getBoundingClientRect()
          let xPosition =
            xScale(d[instance.labels.x]) + margin.left + d3.mouse(this)[0] - 5
          if (xPosition + textWidth > params.width + margin.left) {
            xPosition -= textWidth - 10
          }
          xPosition = xPosition < 0 ? 0 : xPosition
          const yPosition = d3.mouse(this)[1] - 35
          tooltip.attr('transform', `translate(${xPosition}, ${yPosition})`)
          tooltip.select('rect').attr('width', textWidth)
          tooltip.select('text').text(instance.getBarLabelText(d))
        })

      svg
        .append('g')
        .attr('transform', `translate(${margin.left} 24)`)
        .attr('class', 'axisY')
        .call(
          d3
            .axisLeft(yScale)
            .ticks(8)
            .tickSize(0)
            .tickFormat((d) => {
              return this.formatData(d)
            }),
        )
        .selectAll('text')
        .attr('transform', 'translate(-5 0)')

      const axisY_details = document
        .querySelector('.axisY')
        .getBoundingClientRect()

      d3.select(barChart)
        .select('.axisY')
        .insert('rect', '.axisY > .domain')
        .attr('width', Math.round(axisY_details.width) + margin.left)
        .attr('height', svgHeight)
        .attr('x', -(Math.round(axisY_details.width) + margin.left))
        .attr('y', -5)
        .attr('fill', '#ffffff')

      tooltip = svg
        .append('g')
        .attr('class', 'bar-label')
        .style('display', 'none')

      tooltip
        .append('rect')
        .attr('height', 20)
        .attr('fill', '#515151')
        .style('opacity', 1)

      tooltip.append('text').attr('x', 6).attr('y', 13).attr('fill', 'white')

      if (this.draggable) {
        this.handleChartDrag()
      }

      svg
        .append('text')
        .attr('class', 'axisY-label')
        .attr('transform', `translate(${margin.left - 8} 8)`)
        .attr('dx', 0)
        .attr('dy', 0)
        .style('text-anchor', 'end')
        .style('font-size', '10px')
        .style('fill', '#484848')
        .text(this.getBarLabelTextUom(data[0]))

      function wrap(text, width) {
        text.each(function () {
          let text = d3.select(this)
          let words = text.text().split(/\s+/).reverse()
          let word
          let line = []
          let lineNumber = 0
          let lineHeight = 1.4
          let y = text.attr('y')
          let dy = parseFloat(text.attr('dy'))
          let tspan = text
            .text(null)
            .append('tspan')
            .attr('fill', '#484848')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', dy + 'em')
          while ((word = words.pop())) {
            line.push(word)
            tspan.text(line.join(' '))
            if (tspan.node().getComputedTextLength() > width) {
              line.pop()
              tspan.text(line.join(' '))
              line = [word]
              lineNumber++
              tspan = text
                .append('tspan')
                .attr('x', 0)
                .attr('y', y)
                .attr('dy', `${lineNumber * lineHeight + dy}em`)
                .text(word)
            }
          }
          let flag = false
          for (let i = text.node().children.length; i > 2; i--) {
            text.node().removeChild(text.node().children[i - 1])
            flag = true
          }

          if (flag && text.node().children.length === 2) {
            const tspan = text.node().children[1]
            tspan.textContent.length > 12
              ? (tspan.textContent = `${tspan.textContent.slice(0, -3)} ...`)
              : (tspan.textContent = `${tspan.textContent} ...`)
            text.attr('y', 0).selectAll('tspan').attr('y', 0)
          }
        })
      }
    },
    handleChartDrag() {
      const container = this.$refs['widget-container']
      const chart = container.querySelector('.chart')
      const marginLeft = 60

      container.addEventListener('scroll', ({ currentTarget: el }) => {
        container
          .querySelector('.chart > .axisY')
          .setAttributeNS(
            null,
            'transform',
            `translate(${marginLeft + el.scrollLeft} 24)`,
          )
        container
          .querySelector('.chart > .axisY-label')
          .setAttributeNS(
            null,
            'transform',
            `translate(${marginLeft - 8 + el.scrollLeft} 8)`,
          )
      })

      const scrollSpeed = 2
      let dragging = false
      let startX = 0
      let scrollLeft = 0

      container.addEventListener('mousedown', ({ pageX }) => {
        dragging = true
        startX = pageX - container.offsetLeft
        scrollLeft = container.scrollLeft
        container.classList.add('active')
      })
      container.addEventListener('mousemove', ({ pageX }) => {
        if (dragging) {
          const posX = pageX - container.offsetLeft
          const shift = (posX - startX) * scrollSpeed
          container.scrollLeft = scrollLeft - shift
        }
      })
      container.addEventListener('mouseup', () => {
        dragging = false
        container.classList.remove('active')
      })
      container.addEventListener('mouseleave', () => {
        dragging = false
        container.classList.remove('active')
      })

      if (container.offsetWidth < chart.getAttribute('width')) {
        container.classList.add('is-draggable')
      }
    },
  },
}
</script>

<style>
.widget-container {
  color: #484848;
  font-size: 10px;
  line-height: 14px;
}
.widget-container.draggable {
  overflow-x: auto;
}
.widget-container.is-draggable {
  cursor: pointer;
}
.widget-container.is-draggable.active {
  cursor: grabbing;
}
.widget-container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.widget-container::-webkit-scrollbar-track {
  background-color: rgba(216, 216, 216, 0.3);
}
.widget-container::-webkit-scrollbar-thumb {
  width: 4px;
  height: 4px;
  border-radius: 3px;
  background-color: #d8d8d8;
}
.bar-chart {
  position: relative;
  padding: 0 20px 20px 0;
}
.chart {
  display: block;
  user-select: none;
}
.chart [id^='label'] {
  font-family: sans-serif;
  font-size: 10px;
  fill: #474746;
  transform: rotate(-90deg);
  transition: opacity 0.25s;
  opacity: 0;
}
.chart g:hover > [id^='label'] {
  opacity: 1;
}
.axisY path {
  stroke: #d8d8d8;
}
.axisY line {
  stroke: #d8d8d8;
}
.axisY > .tick:nth-of-type(1) {
  display: none;
}
.axisX > path {
  stroke: #d8d8d8;
}
.axisX > g > line {
  stroke: #d8d8d8;
}
.bar-container:hover > .bar-label {
  opacity: 1;
}
.bar {
  fill: #84b2fa;
  cursor: pointer;
}
</style>
