<template lang="pug">
  .filter(:class="{mobile: $deviceInfo.isMobileDevice}")
    .filter-container
      .filter-item
        .filter-title Search
        .filter-item-inner.search
          .el-input.el-input--prefix
            input.el-input__inner(@input="search = $event.target.value",
              :value="search",
              autocomplete="off",
              type="text",
              placeholder="Search")
            span.el-input__prefix
              i.el-input__icon.el-icon-search

</template>

<script>
export default {
  data() {
    return {
      search: '',
    }
  },
  watch: {
    search(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-item-inner.search {
  .el-input {
    width: 300px;
  }
}

.mobile {
  &.filter {
    margin-top: 0;
  }

  .filter {
    &-container {
      padding: 0 15px;
    }

    &-title {
      display: none;
    }

    &-item {
      width: 100%;
      .el-input__inner {
        height: 36px;
      }
    }

    &-item-inner.search {
      .el-input {
        width: 100%;
      }
    }
  }
}
</style>
