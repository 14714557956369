<template>
  <div class="monthly-attributes" v-if="monthlyAttributes.dateMonth">
    <el-row>
      <el-col :span="isEditMode ? 20 : 22">
        <h2> {{ dateMonth }} </h2>
      </el-col>
      <el-col v-if="!isEditMode" :span="2" align="right">
        <el-button type="text" class="btn" @click="changeEditMode">
          <img
            src="../../../../../assets/icons/edit.svg"
            alt="edit"/>
          Edit
        </el-button>
      </el-col>
      <el-col v-else :span="4" align="right">
        <div class="action-cnt">
          <el-button class="action-btn" @click="cancelChanges">
            Cancel
          </el-button>
          <el-button class="action-btn" type="primary" @click="updateMonthlyAttributes">
            Save
          </el-button>
        </div>
      </el-col>
    </el-row>
    <div class="monthly-attributes_body bg_light_gray" :class="{'edit-mode': isEditMode}">
      <el-table :data="monthlyAttributes.attributes">
        <el-table-column label="Attribute" prop="attributeName" width="345"/>
        <el-table-column label="Value" prop="attributeValue" width="195">
          <template v-slot="{ row }">
            <div v-if="isEditMode">
              <el-input
                :type="row.attributeType === 'Numeric' ? 'number' : 'text'"
                :class="{ 'numeric': row.attributeType === 'Numeric' }"
                class="value-input"
                placeholder="Add Value"
                v-model="attributeValues[row.id]"
                @input="handleInput($event, row.id)"
              />
            </div>
            <span v-else :class="{empty: !row.attributeValue}">
              {{ row.attributeValue || 'N/A' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="UoM" prop="attributeUom">
          <template v-slot="{ row }">
            <span>{{ row.attributeUom || '-' }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import filters from '@/lib/filters'
import { mapActions } from 'vuex'

export default {
  props: {
    monthlyAttributes: {
      type: Object,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      attributeValues: {}
    }
  },
  async beforeMount() {
    this.setAttributeValues()
  },
  computed: {
    dateMonth() {
      return filters.datetime(this.monthlyAttributes.dateMonth, 'MMMM yyyy')
    }
  },
  methods: {
    ...mapActions({
      updateMonthlyAttributesValues: 'productionData/updateMonthlyAttributesValues'
    }),
    changeEditMode() {
      this.$emit('changeEditMode', !this.isEditMode, this.monthlyAttributes.dateMonth)
      this.setAttributeValues()
    },
    setAttributeValues() {
      this.monthlyAttributes.attributes.forEach((attribute) => {
        this.attributeValues[attribute.id] = attribute.attributeValue
      })
      this.attributeValues = JSON.parse(JSON.stringify(this.attributeValues))
    },
    cancelChanges() {
      this.changeEditMode()
    },
    updateMonthlyAttributes() {
      const { customerId, locationId } = this.$route.params
      const body = {
        attributeValues: [],
        dateMonth: this.monthlyAttributes.dateMonth
      }
      Object.keys(this.attributeValues).forEach((key) => {
        const attIsNumber = this.monthlyAttributes.attributes.find(attribute => attribute.id === Number(key)).attributeType === 'Numeric'
        body.attributeValues.push(
          {
            locationMonthlyAttributesMetadataId: key,
            value: attIsNumber ? Number(this.attributeValues[key]) : this.attributeValues[key]
          })
      })
      this.updateMonthlyAttributesValues({ customerId, locationId, body }).then(() => {
        this.changeEditMode()
        this.$emit('reloadData')
      })
    },
    handleInput(event, id) {
      this.attributeValues[id] = event
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../../styles/_brandbook';

.monthly-attributes {
  h2 {
    color: #222222;
    font-size: 22px;
    font-weight: 800;
    line-height: 30px;
    margin: 20px 0 16px 52px;
  }

  .btn {
    font-weight: 600;
    margin: 18px 2px 16px 0;
  }

  .action-cnt {
    padding: 18px 2px 16px 0;

    .action-btn {
      padding: 12px 19px;
      font-weight: 400;
    }
  }

  .monthly-attributes_body {
    ::v-deep .el-table {
      background-color: transparent;

      tr {
        background-color: transparent;
        height: 35px;

        td {
          color: #747474;

          &:nth-child(3) {
            color: #606266;
          }
        }
      }

      th,
      td {
        cursor: default;
        padding: 0 40px;
        border-bottom: 1px solid #d0d5de;
      }

      .cell {
        white-space: nowrap;
        padding-left: 10px;
      }

      .empty {
        opacity: 0.4;
      }
    }

    .value-input {
      ::v-deep.el-input__inner {
        height: 30px;
        width: 148px;
        margin-top: 2px;
        margin-bottom: 2px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .edit-mode {
    ::v-deep .el-table {
      td:nth-child(2) {
        padding: 0 0 0 30px;
      }
    }
  }

  .numeric {
    ::v-deep .el-input {
      &__inner {
        padding-right: 0 !important;
      }
    }
  }
}
</style>
