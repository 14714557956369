<template lang="pug">
  .message-item-qr
    .message-item-qr_content
      .message-item-qr_title(v-html="title")
      .message-item-qr_options(v-for="option in quickReplies")
        .message-item-qr_option(@click="$emit('sendMessage', option)")
          a(href="#" v-text="option")
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.convertText(this.item.title)
    },
    quickReplies() {
      return this.item.quickReplies
    },
  },
  methods: {
    convertText(text) {
      return text.replace(
        /(<(https?:\/\/[^|]+)\|([^>]+)>)/gm,
        '<a href="$2">$3</a>',
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../styles/utilities';

.message-item-qr {
  flex: none;
  display: flex;
  align-items: flex-end;
  position: relative;
  max-width: 50%;
  min-height: 40px;
  padding: 8px 15px;
  margin: 10px 0 0 10px;
  color: #222222;
  font-size: 16px;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-line;
  border: solid 1px #dcdfe6;
  box-shadow: 0 2px 6px 0 rgba(177, 179, 182, 0.5);
  background-color: #f4f6fa;
  box-sizing: border-box;
  border-radius: 20px 20px 20px 0;

  @include respond-to($tablet-l) {
    min-width: 270px;
    max-width: 80%;
  }
  @include respond-to($phone) {
    max-width: 270px;
    min-height: 36px;
    padding: 7px 10px;
    margin-left: 0;
    font-size: 14px;
  }

  &::before {
    position: absolute;
    bottom: -1px;
    left: -12px;
    width: 25px;
    height: 14px;
    content: '';
    background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg width='25px' height='14px' viewBox='0 0 25 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3Cdefs%3E %3Cpath d='M0.842036821,14 L23.9099945,14 C17.0553263,1.0094283 13.0789076,-2.75447302 11.9807384,2.70829604 C10.8825692,8.17106509 7.16966871,11.9349664 0.842036821,14 Z' id='path-1'%3E%3C/path%3E %3C/defs%3E %3Cg id='pc_dropdown' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-321.000000, -815.000000)'%3E %3Cg id='message' transform='translate(270.000000, 779.000000)'%3E %3Cg transform='translate(50.000000, 10.000000)' id='Group-2'%3E %3Cg transform='translate(0.000000, 26.000000)'%3E %3Cg id='Path'%3E %3Cuse fill='%23F4F6FA' fill-rule='evenodd' xlink:href='%23path-1'%3E%3C/use%3E %3Cpath stroke='%23DCDFE6' stroke-width='1' d='M3.52571024,13.5 L23.0786379,13.5 C19.8596814,7.46869919 17.3048972,3.55969349 15.4429626,1.79726891 C13.743407,0.1885451 12.9434442,0.456356203 12.4709316,2.80683856 C11.4913616,7.67964416 8.49505242,11.2508645 3.52571024,13.5 Z'%3E%3C/path%3E %3C/g%3E %3Crect id='Path' fill='%23F4F6FA' x='13.25' y='0' width='12' height='13'%3E%3C/rect%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  }

  &_title {
  }
  &_options {
  }
  &_option {
  }
}
</style>
