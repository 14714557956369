import { prop } from 'ramda'
const SET_LOADING = 'set_loading'
const SET_DATA = 'set_data'

const initialState = () => ({
  loading: false,
  data: {
    buildingTypes: [],
    city: [],
    state: [],
    squareFeet: [],
  },
})

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    [SET_LOADING]: (state, flag) => {
      state.loading = flag
    },

    [SET_DATA]: (state, data) => {
      state.data = data
    },

    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async setData({ commit }, filters) {
      await commit(SET_LOADING, true)
      try {
        await commit(SET_DATA, filters || [])
      } catch (error) {
        console.error('LOADING FILTERS ERROR:', error)
      } finally {
        await commit(SET_LOADING, false)
      }
    },
    async clearLocationsFiltersData({ commit }) {
      await commit('resetState')
    },
  },

  getters: {
    data: prop('data'),
    loading: prop('loading'),
  },
}
