import Vue from 'vue'
import Vuex from 'vuex'
import { isEmpty, isNil, not, propOr, pickBy, contains } from 'ramda'
import createPersistedState from 'vuex-persistedstate'
import router from '@/router'
import * as modules from './modules'

Vue.use(Vuex)

const clearCustomerRelatedData = (store) => {
  store.commit('chat/clearMessages')
  store.commit('bills/resetState')
  store.commit('bills/filtersData/resetState')
  store.commit('bills/filters/resetState')
  store.commit('locations/resetState')
  store.commit('locations/filtersData/resetState')
  store.commit('locations/filters/resetState')
  store.commit('reports/resetState')
  store.commit('reports/filtersData/resetState')
  store.commit('widgets/resetState')
  store.commit('createReports/resetState')
  store.commit('completedReports/resetState')
  store.commit('reportFilters/resetFilters')
  store.commit('createBudgeting/resetState')
}

const clearMessages = (store) => {
  store.subscribe((mutation) => {
    if (mutation.type === 'user/clear') {
      clearCustomerRelatedData(store)
      store.commit('customers/clear')
    }
    if (mutation.type === 'customers/setCurrentCustomerId') {
      clearCustomerRelatedData(store)
    }
  })
}

const billsFiltersPlugin = (store) => {
  store.subscribeAction((action) => {
    if (contains('bills/filters/changeFilters', action.type)) {
      router.push({
        path: router.currentRoute.path,
        query: pickBy((val) => not(isNil(val)) && not(isEmpty(val)), {
          ...router.currentRoute.query,
          ...action.payload,
        }),
      })
    }
  })
  store.subscribe(async (mutation) => {
    if (
      mutation.type === 'route/ROUTE_CHANGED' &&
      mutation.payload.to.name === 'dashboard.bills'
    ) {
      const { query } = mutation.payload.to
      store.dispatch('bills/filters/setFilters', {
        ...query,
        vendorCodes: query.vendorCodes || [],
        locationIds: query.locationIds || [],
        searchQuery: query.searchQuery || '',
        statementDateMin: query.statementDateMin || null,
        statementDateMax: query.statementDateMax || null,
        amountDueMin: query.amountDueMin || null,
        amountDueMax: query.amountDueMax || null,
      })
    }
  })
}

const locationsFiltersPlugin = (store) => {
  store.subscribeAction((action) => {
    if (contains('locations/filters/changeFilters', action.type)) {
      router.push({
        path: router.currentRoute.path,
        query: pickBy((val) => not(isNil(val)) && not(isEmpty(val)), {
          ...router.currentRoute.query,
          ...action.payload,
        }),
      })
    }
  })
  store.subscribe(async (mutation) => {
    if (
      mutation.type === 'route/ROUTE_CHANGED' &&
      mutation.payload.to.name === 'dashboard.locations'
    ) {
      const { query } = mutation.payload.to
      store.dispatch('locations/filters/setFilters', {
        ...query,
        buildingTypes: propOr([], 'buildingTypes', query),
        cities: propOr([], 'cities', query),
        states: propOr([], 'states', query),
        searchQuery: propOr('', 'searchQuery', query),
        squareFeetMin: propOr(null, 'squareFeetMin', query),
        squareFeetMax: propOr(null, 'squareFeetMax', query),
      })
    }
  })
}

const resetFiltersPlugin = (store) => {
  store.subscribeAction((action) => {
    if (
      contains('resetFilters', action.type) &&
      !isEmpty(router.currentRoute.query)
    ) {
      router.push({
        query: '',
      })
    }
  })
}

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['user', 'customers'],
    }),
    clearMessages,
    billsFiltersPlugin,
    locationsFiltersPlugin,
    resetFiltersPlugin,
  ],
  modules,
})

if (module.hot) {
  module.hot.accept(['./modules'], () => {
    store.hotUpdate({
      modules: require('./modules'),
    })
  })
}

export default store
