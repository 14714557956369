<template lang="pug">
  .widget-filter-container
    .filter-main-title Add new filter
    .filter-title Filter type:
    el-select.filter-type-field(v-model="filterType", no-data-text="No filters available")
      el-option(v-for="option in availableFilters",
        :key="option.value",
        :value="option.value"
        :label="option.title",
        filterable)
    filter-year(v-if="isFilter('year')",
                        :title="filterTitle('year')",
                        :filter="filters.year",
                        :value="defaultYear")
    filter-time-period.filter-column(v-if="isFilter('time_period')",
                        :title="filterTitle('time_period')",
                        :filter="filters.time_period",
                        :value="defaultTimePeriod")
    filter-locations.filter-column(v-if="isFilter('location_id')",
                        :title="filterTitle('location_id')",
                        :filter="filters.location_id",
                        :value="defaultLocations")
    filter-utility-type(v-if="isFilter('utility_type')",
                        :title="filterTitle('utility_type')",
                        :filter="filters.utility_type",
                        :value="defaultUtilityType")
    filter-utility-type-list(v-if="isFilter('utility_type_list')",
                        :title="filterTitle('utility_type_list')",
                        :filter="filters.utility_type_list",
                        :value="defaultUtilityTypeList")
    .widget-filter_actions
      el-button(class="button-filter-action",
                type="primary",
                :disabled="!filterType",
                size="small",
                round,
                @click="addFilter(filterType)") Add filter
</template>

<script>
import * as R from 'ramda'
import _ from 'lodash'
import { mapState, mapGetters } from 'vuex'
import FilterTimePeriod from './Desktop/FilterTimePeriod.vue'
import FilterYear from './Desktop/FilterYear.vue'
import FilterLocations from './Desktop/FilterLocations.vue'
import FilterUtilityType from './Desktop/FilterUtilityType.vue'
import FilterUtilityTypeList from './Desktop/FilterUtilityTypeList.vue'

export default {
  props: {
    widgetFilters: {
      type: Object,
      required: true,
    },
    widgetType: {
      type: String,
      required: true,
    },
  },
  components: {
    FilterTimePeriod,
    FilterLocations,
    FilterUtilityType,
    FilterUtilityTypeList,
    FilterYear,
  },
  data() {
    return {
      filterMap: {
        year: {
          title: 'Year',
        },
        time_period: {
          title: 'Time Period',
        },
        location_id: {
          title: 'Locations',
        },
        utility_type: {
          title: 'Utility Type',
        },
        utility_type_list: {
          title: 'Utility Types',
        },
      },
      filters: {
        year: {},
        time_period: {},
        utility_type: {},
        utility_type_list: {},
        location_id: {},
      },
      filterType: '',
    }
  },
  computed: {
    ...mapState({
      customer_id: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      widgetTypes: 'widgets/widgetTypes',
    }),
    availableFilters() {
      const filters = R.pathOr(
        [],
        [this.widgetType, 'filters'],
        this.widgetTypes,
      )
      return filters.map((filter) => {
        return {
          title: this.filterTitle(filter),
          value: filter,
        }
      })
    },
    defaultYear() {
      return _.get(
        _.get(this.widgetFilters, 'filters', []).find(
          (item) => item.filter === 'year',
        ),
        'year',
        { year: new Date().getFullYear() },
      )
    },
    defaultLocations() {
      return _.get(
        _.get(this.widgetFilters, 'filters', []).find(
          (item) => item.filter === 'location_id',
        ),
        'location_id.location_ids',
        [],
      )
    },
    defaultTimePeriod() {
      return _.get(
        _.get(this.widgetFilters, 'filters', []).find(
          (item) => item.filter === 'time_period',
        ),
        'time_period',
        {},
      )
    },
    defaultUtilityType() {
      return _.get(
        _.get(this.widgetFilters, 'filters', []).find((item) =>
          _.findKey(item, 'utility_type'),
        ),
        'utility_type.utility_type',
        'ELECTRIC',
      )
    },
    defaultUtilityTypeList() {
      return _.get(
        _.get(this.widgetFilters, 'filters', []).find(
          (item) => item.filter === 'utility_type_list',
        ),
        'utility_type_list.list',
        [],
      )
    },
  },
  methods: {
    isFilter(filterType) {
      return this.filterType === filterType
    },
    filterTitle(filterType) {
      return R.pathOr(filterType, [filterType, 'title'], this.filterMap)
    },
    addFilter(filterType) {
      this.$emit('add-filters', {
        [filterType]: R.pathOr({}, [filterType], this.filters),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-filter-container {
  margin-top: -35px;
  .filter-main-title {
    margin-bottom: 22px;
    color: #2c2e34;
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
  }
  ::v-deep .filter-column {
    flex-direction: column;
  }

  ::v-deep .filter-title {
    position: relative;
    width: 100%;
    margin-bottom: 12px;
    font-weight: 900;

    .icon {
      position: absolute;
      top: 4px;
      left: -14px;
      cursor: pointer;
    }
  }
  ::v-deep .filter-item {
    display: flex;
    flex-wrap: wrap;
    padding: 25px 25px 7px;
    margin: 0 -25px;

    &:first-child {
      margin-top: -25px;
    }
    &-date {
      > :not(.el-input-number) {
        .el-input {
          width: 134px;
        }
      }
    }
    &-utility {
      .el-select.is-multiple {
        width: 100%;
      }
      ::v-deep .el-input {
        width: inherit;
      }
    }
    .el-button {
      margin-bottom: 18px;
    }
    .el-radio-group {
      margin: 18px 0;
    }
  }

  ::v-deep .button-filter-action {
    width: 240px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
    font-weight: 900;
    background: #0080ff;
  }
}
.widget-filter_actions {
  margin: 0 -12px;
  padding-top: 10px;
  text-align: center;
}
</style>
