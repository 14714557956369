import { render, staticRenderFns } from "./MessageInput.vue?vue&type=template&id=7c96c9cc&scoped=true&lang=pug&"
import script from "./MessageInput.vue?vue&type=script&lang=js&"
export * from "./MessageInput.vue?vue&type=script&lang=js&"
import style0 from "./MessageInput.vue?vue&type=style&index=0&id=7c96c9cc&prod&lang=scss&scoped=true&"
import style1 from "./MessageInput.vue?vue&type=style&index=1&id=7c96c9cc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c96c9cc",
  null
  
)

export default component.exports