<template>
  <div class="pdf__wrapper" v-loading="isLoading">
    <div class="pdf__container" v-if="total">
      <div class="pdf__header">
        <div class="page-scale">
          <span class="page-scale__value">{{ scale }}%</span>
          <span
            class="page-scale__plus el-icon-plus"
            @click="changeScale('+')"
          ></span>
          <span
            class="page-scale__minus el-icon-minus"
            @click="changeScale('-')"
          ></span>
        </div>
        <div class="pdf-navigation">
          <i
            class="el-icon-arrow-left"
            v-if="selectedPage > 1"
            @click="previous"
          ></i>
          <el-input
            class="pdf-navigation__page"
            v-model="_selectedPage"
            @change="manualChange"
            size="mini"
            :min="1"
            :max="total"
          ></el-input>
          <span>of</span>
          <span class="pdf-navigation__total">{{ total }}</span>
          <i
            class="el-icon-arrow-right"
            v-if="selectedPage < total"
            @click="next"
          ></i>
        </div>
        <div class="pdf-action">
          <i class="fw-700 el-icon-download" @click="downloadPdf"></i>
          <div
            class="btn-thumbnail"
            @click="isThumbnailVisible = !isThumbnailVisible"
            :class="{ active: isThumbnailVisible }"
          >
            <img :src="require('../../../../assets/thumbnail.svg')" />
          </div>
        </div>
      </div>
      <div class="pdf__content">
        <div class="pdf__content-scale" ref="pdf">
          <pdf
            class="pdf__page"
            v-for="i in total"
            :src="src"
            :key="i"
            :page="i"
            style="display: inline-block; width: 100%"
            :id="`pdf-page-${i}`"
            @rendered="pdfRendered"
          ></pdf>
        </div>
      </div>
      <div
        class="pdf__preview-container"
        :class="{ active: isThumbnailVisible }"
      >
        <div class="pdf__preview">
          <div
            class="pdf__page-small"
            v-for="i in total"
            @click="selectPage(i)"
            :key="i"
            :class="{ active: i === +selectedPage }"
          >
            <pdf
              :src="src"
              :page="i"
              style="display: inline-block; width: 100%"
            ></pdf>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import * as R from 'ramda'
import { bills } from '@/api'
import _ from 'lodash'

export default {
  components: {
    pdf,
  },
  props: {
    sidebar: {
      type: String,
      default: 'isPdfBillVisible',
    },
    bill: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      total: undefined,
      src: null,
      selectedPage: 1,
      scale: 100,
      scaleStep: 5,
      isThumbnailVisible: false,
      isScrolling: false,
      isLoading: false,
      renderHandler: null,
    }
  },
  beforeDestroy() {
    this.removeScrollWatcher()
    window.removeEventListener('keydown', this.keyPress)
    clearInterval(this.renderHandler)
  },
  mounted() {
    window.addEventListener('keydown', this.keyPress)
  },
  computed: {
    hasPdfLink() {
      const files = R.propOr({}, 'files', this.bill)
      const currentPdfBill = files.find(
        (file) => file.contentType === 'application/pdf',
      )
      return currentPdfBill.id
    },
    _selectedPage: {
      set: function (val) {
        this.manualChange()
        let newVal = isNaN(Number(val)) ? 1 : Number(val)
        if (newVal > this.total) {
          newVal = newVal % 10
        }
        this.selectedPage =
          newVal > this.total ? this.total : newVal < 1 ? 1 : newVal
      },
      get: function () {
        return this.selectedPage
      },
    },
  },
  watch: {
    hasPdfLink: {
      immediate: true,
      handler: async function (value) {
        if (value) {
          await this.loadPdf()
        }
      },
    },
    selectedPage(page) {
      if (this.isScrolling) {
        this.isScrolling = false
        return
      }
      const top = document.getElementById(`pdf-page-${page}`).offsetTop / 2 - 30
      document.querySelector('.pdf__content').scrollTop = top
    },
  },
  methods: {
    keyPress(e) {
      const { keyCode } = e
      switch (keyCode) {
        case 37:
          this._selectedPage--
          break
        case 39:
          this._selectedPage++
          break
      }
    },
    async loadBill() {
      await this.$emit('loadBill')
    },
    next() {
      this.selectedPage++
      this.isScrolling = false
    },
    previous() {
      this.selectedPage--
      this.isScrolling = false
    },
    manualChange() {
      this.isScrolling = false
    },
    async loadPdf() {
      const { billId = 0, customerId } = this.$route.params
      const { url } = await bills.getPdfDownloadLink({ customerId, billId })
      if (url) {
        this.isLoading = true
        this.src = pdf.createLoadingTask(url)
        this.src.promise
          .then((data) => {
            this.total = data.numPages
            this.$nextTick(() => {
              this.addScrollWatcher()
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        console.error(`GET PDF LINK ERROR: got empty download url`)
      }
    },
    selectPage(page) {
      this.selectedPage = page
      this.isScrolling = false
    },
    changeScale(flag) {
      const element = this.$refs.pdf
      const minScale = 10
      const maxScale = 300
      this.scale =
        flag === '+' ? this.scale + this.scaleStep : this.scale - this.scaleStep
      this.scale = this.scale < minScale ? (this.scale = minScale) : this.scale
      this.scale = this.scale > maxScale ? (this.scale = maxScale) : this.scale
      if (this.scale > 100) {
        element.style.minWidth = this.scale * 2 + '%'
        element.style.maxWidth = 'initial'
      } else {
        element.style.minWidth = 'initial'
        element.style.maxWidth = this.scale * 2 + '%'
      }
    },
    addScrollWatcher() {
      const el = document.querySelector('.pdf__content')
      if (el) {
        el.addEventListener('scroll', this.scrollWatcher)
      }
    },
    removeScrollWatcher() {
      const el = document.querySelector('.pdf__content')
      if (el) {
        el.removeEventListener('scroll', this.scrollWatcher)
      }
    },
    scrollWatcher: _.debounce(function (e) {
      const rect = document.querySelector('.pdf__page').getClientRects()
      const height = R.path([0, 'height'], rect)
      const currentPage = Math.round(e.target.scrollTop / height) + 1
      this.selectedPage = currentPage > this.total ? this.total : currentPage
      this.isScrolling = true
    }, 50),
    async downloadPdf() {
      const url = await this.getPdfLink()
      if (url) {
        const a = window.document.createElement('a')
        a.setAttribute('href', url)
        window.document.body.appendChild(a)
        a.click()
        window.document.body.removeChild(a)
      } else {
        console.error(`GET PDF LINK ERROR: got empty download url`)
      }
    },
    async getPdfLink() {
      const { billId = 0, customerId } = this.$route.params
      const { url } = await bills.getPdfDownloadLink({ customerId, billId })
      return url
    },
    pdfRendered() {
      clearInterval(this.renderHandler)
      this.renderHandler = setTimeout(() => this.$emit('pdfRendered'), 500)
    },
  },
}
</script>

<style lang="scss">
@import '../../../../styles/utilities.scss';

.pdf {
  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 8px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
    //background-color: #83c2c9;
    .annotationLayer {
      display: none !important;
    }
  }

  &__header {
    //background-color: #d2ddff;
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;

    .page-scale {
      color: #273a58;
      font-weight: 600;
      text-align: center;

      &__value {
        display: inline-block;
        text-align: left;
        min-width: 50px;
      }

      &__plus,
      &__minus {
        cursor: pointer;
        font-size: 14px;
        margin-right: 8px;
        font-weight: 700;
        color: #4a90e2;
      }
    }

    .pdf-navigation {
      color: #606266;
      white-space: nowrap;
      position: relative;

      &__page {
        display: inline-block;
        margin-right: 5px;
        max-width: 35px;
        height: 19px;

        .el-input__inner {
          height: 19px;
          padding: 0 5px;
          line-height: 17px;
          text-align: center;
          background-color: #efefef;
        }
      }

      &__total {
        display: inline-block;
        margin-left: 5px;
      }

      i {
        position: absolute;
        top: 3px;

        &.el-icon-arrow-left {
          left: -23px;
        }

        &.el-icon-arrow-right {
          right: -20px;
        }

        &:hover {
          cursor: pointer;
          color: #000000;
        }
      }
    }

    .pdf-action {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      .el-icon-download {
        cursor: pointer;
        padding-right: 7px;

        &:hover {
          color: #000000;
        }
      }
    }

    .btn-thumbnail {
      width: 24px;
      height: 22px;
      cursor: pointer;

      &:hover {
        .svg-stroke-color {
          stroke: #000000;
        }
      }

      &.active {
        .svg-stroke-color {
          stroke: #ffffff;
        }

        background-color: #4a90e2;
        border-radius: 3px;
      }
    }
  }

  &__content {
    background-color: #f6f6f6;
    flex-grow: 1;
    flex-basis: 1px;
    height: 100%;
    max-height: calc(100% - 30px);
    overflow: auto;
    &-scale {
      width: calc(200% + 0px);
      height: 50%;
      transform-origin: top left;
      transform: scale(0.5);
    }
    @extend %scrollbar;
  }

  &__preview-container {
    background-color: #878787;
    flex-basis: 0;
    max-width: 0;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    transition: all 0.3s;

    &.active {
      flex-basis: 100px;
      max-width: 100px;
    }
  }

  &__preview {
    background-color: #e9e9eb;
    //background-color: #f6f6f6;
    min-width: 94px;
    height: 100%;
    overflow: auto;
    @extend %scrollbar;
  }

  &__page {
    margin-bottom: 10px;
    box-shadow: 0 0 5px 0 rgba(195, 195, 195, 0.5);

    + & {
      margin-top: 10px;
    }
  }

  &__page-small {
    box-shadow: 0 0 20px 0 rgba(195, 195, 195, 0.5);
    margin: 9px 7px;

    &:first-child {
      margin-top: 10px;
    }

    &.active {
      box-shadow: 0px 0px 0px 2px #4a90e2;
    }
  }
}
</style>
