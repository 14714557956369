<template lang="pug">
  .chat-wrapper(ref='chat-wrapper')
    .chat-container(ref='chat-container', v-chat-scroll="{always: true, smooth: true}")
      .chat-messages
        //-message-chart
        select-location(v-if='controlType === "select_location"',
                        @scrollToBottom='scrollToBottom',
                        @selectLocation='selectLocation',
                        @blockChat="blockChat",
                        @unblockChat="unblockChat")
        select-month(v-if='controlType === "select_month"',
                    :monthMessage.sync="monthMessage",
                    @scrollToBottom='scrollToBottom',
                    @selectTimePeriod='selectTimePeriod',
                    @openCalendar="openCalendar")
        related-queries(v-if='controlType === "related_queries"',
                        :related-queries='relatedQueries',
                        :relatedQueriesData='relatedQueriesData',
                        @scrollToBottom='scrollToBottom',
                        @blockChat="blockChat",
                        @unblockChat="unblockChat",
                        @sendMessage='sendMessageAndScroll',
                        @openWhatElseModal='handlerOpenModal',
                        :whatElseModalOpened="isWhatElseModalVisible")
        message-item(v-for='(message, index) in messages',
                    :key='index',
                    :message='message',
                    :calendarOpened='calendarOpened',
                    :data-index="message.id",
                    :showDate="messages[index - 1] && message.day !== messages[index -1].day",
                    :previousMessage="messages[index - 1]",
                    :getDayString="getDayString",
                    @scrollToBottom='scrollToBottom',
                    @sendMessage='sendMessageAndScroll')
        .loading
          span(v-loading='loading')
        .chat-bottom(ref='bottom')
    .chat-input-container(ref="input-wrapper", :class="{ focused: keyboardIsOpened }")
      message-input(@scrollToBottom='scrollToBottom',
                    @sendMessage='sendMessageAndScroll',
                    @keepKeyboardOneped='keepKeyboardOneped',
                    @handlerOpenModal="handlerOpenModal")
    el-dialog.related-queries-modal(title='Introducing Sam',
                                    :visible.sync='isWhatElseModalVisible',
                                    :append-to-body='true',
                                    :modal='false',
                                    width='620px')
      .related-queries_text
        .logo
          i.icon.icon-logo-no-text
        p Hello! I am Sam, your virtual energy assistant.
          | I’m here to answer questions about your energy usage, help you with your energy bills,
          | and help you find ways to lower your energy costs.
          | I’m available 24 hours a day, every day. Here are some questions I can help you with.
      .related-queries_list
        .related-queries_list-title Bill Information
        .related-queries_list-content
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("how much was my bill")'
          ) How much was my bill
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("what was my bill usage?")'
          ) What was my bill usage?
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("give me a bill summary")'
          ) Give me a bill summary
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("download my bill")'
          ) Download my bill
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("show meter detail on my bill")'
          ) Show meter detail on my bill
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("compare with previous bill")'
          ) Compare with previous bill
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("do I have a late fee")'
          ) Do I have a late fee
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("when is my bill due")'
          ) When is my bill due
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("what was my last invoice date")'
          ) What was my last invoice date
        .related-queries_list-title Account Information
        .related-queries_list-content
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("what is my account number")'
          ) What is my account number
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("what is my meter number")'
          ) What is my meter number
        .related-queries_list-title Energy Analysis
        .related-queries_list-content
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("what were my monthly charges")'
          ) What were my monthly charges
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("what was my monthly usage")'
          ) What was my monthly usage
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("get cost history")'
          ) Get cost history
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("get consumption history")'
          ) Get consumption history
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("what are my most expensive locations")'
          ) What are my most expensive locations
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("what are my highest consumption locations")'
          ) What are my highest consumption locations
          el-button.button-control(size='medium',
          round,
          @click='resetContextsAndSendMessage("What are my highest unit cost locations")'
          ) What are my highest unit cost locations
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import SelectLocation from './Controls/SelectLocation.vue'
import SelectMonth from './Controls/SelectMonth.vue'
import BillControl from './Controls/SelectBill.vue'
import RelatedQueries from './Controls/RelatedQueries.vue'
import MessageChart from './MessageChart.vue'
import MessageItem from './MessageItem.vue'
import MessageInput from './MessageInput.vue'
import { DateTime } from 'luxon'
import * as R from 'ramda'
import welcomeRelatedQueries from './welcomeIntentsRelatedQueries'
import { sendEventToChatbase } from '../../../api/stats'
import checkIe from '@/mixins/checkIe'

const initialState = () => ({
  monthMessage: null,
  controlType: '',
  blockLoading: false,
  relatedQueries: [],
  relatedQueriesData: null,
  keyboardIsOpened: false,
  calendarOpened: false,
  isWhatElseModalVisible: false,
})

export default {
  mixins: [checkIe],
  components: {
    SelectLocation,
    SelectMonth,
    BillControl,
    RelatedQueries,
    MessageChart,
    MessageItem,
    MessageInput,
  },
  data() {
    return initialState()
  },
  async mounted() {
    Object.assign(this, initialState())
    await this.initChart()
  },
  beforeDestroy() {
    this.destroyChart()
  },
  computed: {
    ...mapGetters({
      loading: 'chat/loading',
      allLoaded: 'chat/allLoaded',
      messages: 'chat/getMessages',
      currentCustomerId: 'customers/currentCustomerId',
    }),
    ...mapState({
      first_name: R.pathOr('', ['user', 'user', 'profile', 'first_name']),
    }),
  },
  watch: {
    async currentCustomerId(id) {
      this.resetChatState()
      this.destroyChart()
      if (id) {
        await this.initChart()
        setTimeout(() => {
          this.scrollToBottom()
        }, 300)
      }
    },
    isWhatElseModalVisible(state) {
      if (state) {
        this.blockChat()
        document.body.classList.add('disable-scroll')
      } else {
        document.body.classList.remove('disable-scroll')
        this.unblockChat()
      }
    },
    messages() {
      // todo: refactor this
      // if (R.pathOr(false, ['0', 'payload', 'messages'], this.messages[0].payload.messages)) {
      if (R.pathOr(false, ['payload', 'messages'], this.messages[0])) {
        let isControl = R.has('pearAi')
        R.forEach((item) => {
          if (
            item.payload &&
            isControl(item.payload) &&
            item.payload.pearAi.missingParamWidget
          ) {
            this.monthMessage = item.payload.pearAi.missingParamWidget
            this.controlType = item.payload.pearAi.missingParamWidget.name
          }
          if (item.message && item.message === 'relatedQueries') {
            this.controlType = 'related_queries'
            this.relatedQueries = item.relatedQueries
            this.relatedQueriesData = this.messages[0].payload.messages
            this.scrollToBottom()
          }
          const action = R.path(['payload', 'pearAi', 'action'], item)
          if (action && action.type === 'weblink_overlay') {
            this.handlerOpenModal()
          }
        }, this.messages[0].payload.messages)
      }
    },
  },
  methods: {
    ...mapActions({
      loadMessages: 'chat/loadMessages',
      sendMessage: 'chat/sendMessage',
      addMessage: 'chat/addMessage',
    }),
    ...mapMutations({
      resetChatState: 'chat/resetState',
    }),
    // todo: debounce this
    async handleScroll(event) {
      event.preventDefault()
      event.stopPropagation()
      if (
        (window.scrollY || window.pageYOffset) <= 0 &&
        !this.allLoaded &&
        !this.blockLoading
      ) {
        this.blockLoading = true
        const id = R.prop('id', R.last(this.messages))
        const previousElement = this.$refs['chat-container'].querySelector(
          `.chat-messages [data-index="${id}"]`,
        )
        await this.loadMessages()
        if (previousElement) {
          if (this.$deviceInfo.isIe) {
            window.scrollTo(0, 100)
          } else {
            window.scrollTo(0, Math.abs(previousElement.offsetTop) + 100)
          }
        }

        if (this.$deviceInfo.isIe) {
          setTimeout(() => {
            this.blockLoading = false
          }, 500)
        } else {
          this.blockLoading = false
        }
      }

      return false
    },
    openCalendar() {
      sendEventToChatbase(
        this.currentCustomerId,
        'calendar',
        'select_time_period',
      ).catch((err) => {
        console.error('CHATBASE ERROR: ', err)
      })

      this.calendarOpened = true
    },
    sendMessageAndScroll(message, silent, resetContexts) {
      if (R.isEmpty(message)) {
        return
      }
      this.sendMessage({ message, silent, resetContexts })
      this.controlType = ''
      /**
       * Temporary remove bluring input
       * Issues:
       * FKUN-1795,
       * FKUN-1772
       * */
      this.focusOnInput()
    },
    scrollToBottom() {
      if (!this.blockLoading) {
        window.scrollTo(0, document.body.scrollHeight)
      }
    },
    onContentInserted(e) {
      this.scrollToBottom()
    },
    getDayString(message) {
      if (message) {
        const now = DateTime.utc()
        if (message.date.hasSame(now, 'day')) {
          return 'Today'
        }
        if (message.date.hasSame(now.minus({ days: 1 }), 'day')) {
          return 'Yesterday'
        }
        if (message.date.hasSame(now, 'year')) {
          return message.date.toLocaleString({
            weekday: 'long',
            month: 'long',
            day: '2-digit',
          })
        }
        return DateTime.fromISO(message.date, { zone: 'utc' }).toFormat('DDDD')
        // return message.date.toFormat('DDDD');
      } else {
        return ''
      }
    },
    selectLocation(location) {
      this.sendMessageAndScroll(location)
      this.controlType = ''
    },
    selectTimePeriod(timePeriod) {
      this.sendMessageAndScroll(timePeriod)
      this.controlType = ''
      this.calendarOpened = false
    },
    focusOnInput() {
      if (this.keyboardIsOpened) {
        document.querySelector('#chat-input').focus()
      }
    },
    keepKeyboardOneped(status) {
      this.keyboardIsOpened = status
    },
    handleTouch(e) {
      if (
        (e.target.classList.contains('button-control') ||
          e.target.parentElement.classList.contains('button-control')) &&
        this.keyboardIsOpened
      ) {
        this.focusOnInput()
      } else {
        this.keyboardIsOpened = false
      }
    },
    blockChat() {
      this.blockLoading = true
    },
    unblockChat() {
      this.blockLoading = false
      this.scrollToBottom()
    },
    handlerOpenModal() {
      this.isWhatElseModalVisible = true
    },
    resetContextsAndSendMessage(message) {
      this.sendMessageAndScroll(message, false, true)
      this.isWhatElseModalVisible = false
    },
    setButtonsControlWidth() {
      if (this.$deviceInfo.isMobileDevice && this.$refs['control-buttons']) {
        let controlButtonsWidth = 0
        this.$refs['control-buttons'].childNodes.forEach((button) => {
          controlButtonsWidth += button.offsetWidth + 20
        })
        this.$refs['control-buttons'].style.width = `${controlButtonsWidth}px`
      } else {
        this.$refs['control-buttons'].style.width = 'auto'
      }
      // this.$emit('scrollToBottom');
    },
    navigateToBillDetail(query) {
      const payload = R.find((item) =>
        R.path(['payload', 'pearAi', 'tableCard'], item),
      )(this.relatedQueriesData)
      const id = R.path(
        ['payload', 'pearAi', 'tableCard', 'properties', 'bill_id'],
        payload,
      )
      this.$router.push({
        name: 'dashboard.bills.info',
        params: { billId: id },
      })
    },
    toggleBodyScroll(value) {
      if (!value) {
        document.querySelector('body').style.overflow = 'hidden'
      } else {
        document.querySelector('body').style.overflow = 'initial'
      }
    },
    async initChart() {
      let launchMessage = false
      await this.loadMessages({ initLoad: true })

      document.body.classList.add('chat-page')
      this.$refs['chat-wrapper'].addEventListener(
        'DOMNodeInserted',
        this.onContentInserted,
      )
      window.addEventListener('scroll', this.handleScroll)
      this.$refs['chat-wrapper'].addEventListener(
        'touchstart',
        this.handleTouch,
      )

      if (this.$route.query.message) {
        const { message, silent } = this.$route.query
        this.sendMessageAndScroll(message, silent)
        launchMessage = true
        this.$router.replace({ query: { message: undefined } })
      }

      const baseMessage = { type: 'sam_chat', sender_id: '98' }
      let payload = { messages: [] }
      if (launchMessage) {
        this.scrollToBottom({ behavior: 'instant' })
        return
      }

      if (this.messages.length === 0) {
        payload = {
          messages: [
            {
              message: 'text',
              text: `Hi ${this.first_name}. I am your virtual energy assistant and can help you look up bills, analyze bills, and perform analysis on energy consumption across all your locations.`,
            },
            {
              message: 'text',
              text: `Click on a button below or type “help” to get started.`,
            },
            {
              message: 'relatedQueries',
              relatedQueries: welcomeRelatedQueries.firstTimeUser,
            },
          ],
        }
        await this.addMessage({ ...baseMessage, payload })
      } else {
        if (this.messages[0].created_at) {
          const dt1 = DateTime.fromISO(this.messages[0].created_at).plus({
            minutes: 10,
          })
          const dt2 = DateTime.local()
          if (dt2 > dt1) {
            payload = {
              messages: [
                { message: 'text', text: `Welcome back, ${this.first_name}!` },
                { message: 'text', text: `How can I help you today?` },
                {
                  message: 'relatedQueries',
                  relatedQueries: welcomeRelatedQueries.returningUser,
                },
              ],
            }
            await this.addMessage({ ...baseMessage, payload })
          }
        }
      }
      this.scrollToBottom()
    },
    destroyChart() {
      document.body.classList.remove('chat-page')
      this.$refs['chat-wrapper'].removeEventListener(
        'touchstart',
        this.handleTouch,
      )
      this.$refs['chat-wrapper'].removeEventListener(
        'DOMNodeInserted',
        this.onContentInserted,
      )
      window.removeEventListener('scroll', this.handleScroll)
      document.body.classList.remove('disable-scroll')
      this.unblockChat()
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../styles/utilities';
.chat-wrapper {
  flex: auto;
  /*height: 100vh;*/
  box-sizing: border-box;
  background: #f7f8fa;

  @extend %scrollbar;
  width: 100%;

  @include respond-to($tablet-l) {
    /*position: fixed;*/
    /*top: 0;*/
    /*right: 0;*/
    /*width: calc(100% - 240px);*/
    /*height: 100%;*/
  }
  @include respond-to($tablet-p) {
    /*width: calc(100% - 200px);*/
  }
  @include respond-to($phone) {
    width: 100%;
    /*padding-top: 44px;*/
  }
}

.chat {
  &-container {
    position: relative;
    /*height: calc(100vh - 61px);*/
    /*height: 100%;*/
    /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='121' height='332' viewBox='0 0 121 332'%3E %3Cg fill='none' fill-rule='evenodd' stroke='%23E7EBF0'%3E %3Cpath stroke-width='30' d='M137.588 2L33 170.677 85.294 258h107.524L237 170.677z'/%3E %3Cpath stroke-width='10' d='M34.198 258L6 303.464 20.099 327h28.99L61 303.464z'/%3E %3C/g%3E %3C/svg%3E");*/
    /*background-repeat: no-repeat;*/
    /*background-position: right center;*/
    /*-webkit-overflow-scrolling: touch;*/
    padding-bottom: 70px;
    overflow: hidden;
    z-index: 1;

    &::after {
      display: block;
      content: ' ';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='121' height='332' viewBox='0 0 121 332'%3E %3Cg fill='none' fill-rule='evenodd' stroke='%23E7EBF0'%3E %3Cpath stroke-width='30' d='M137.588 2L33 170.677 85.294 258h107.524L237 170.677z'/%3E %3Cpath stroke-width='10' d='M34.198 258L6 303.464 20.099 327h28.99L61 303.464z'/%3E %3C/g%3E %3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right center;
      z-index: -1;
    }

    @extend %scrollbar;

    @include respond-to($tablet-l) {
      /*height: calc(100% - 61px);*/
    }
    @include respond-to($phone) {
      /*height: calc(100% - 52px);*/
      /*padding-bottom: 52px;*/
    }
  }
  &-messages {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    min-height: 100vh;
    padding: 30px 30px 20px 30px;
    box-sizing: border-box;

    @include respond-to($phone) {
      padding: 10px 10px 20px 10px;
    }
  }
  &-input-container {
    width: calc(100% - 240px);
    padding: 10px 30px 20px 30px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #f7f8fa;
    z-index: 2;
    box-shadow: 4px 0px 8px 0 rgba(188, 188, 188, 0.8);

    @include respond-to($tablet-p) {
      width: calc(100% - 200px);
    }
    @include respond-to($phone) {
      width: 100%;
      padding: 10px 10px 10px 10px;
    }
  }
  &-bottom {
    position: absolute;
    bottom: 0;
  }
}
.loading {
  position: absolute;
  top: 20px;
  left: 50%;
  width: 42px;
  transform: translateX(-50%);
}

.related-queries_list {
  .el-button {
    border-color: #dcdfe6;
  }

  &-title {
    margin: 30px 0 5px;
    color: #3b3e48;
    font-size: 16px;
    font-weight: 600;
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .el-button {
      margin: 10px 10px 0 0;
      color: #0080ff;

      @include respond-to($phone) {
        background-color: transparent;
      }
    }
  }
}

.related-queries_list-item {
  display: block;
  width: 100%;
  min-height: 54px;
  background-color: transparent;

  + .related-queries_list-item {
    margin: 10px 0 0;
  }
}

.logo {
  display: none;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #fff;

  @include respond-to($phone) {
    display: flex;
  }
  .icon {
    margin-right: 0;
  }
}
.related-queries_text {
  color: #5d6a89;

  p {
    margin: 0;
    line-height: 22px;

    @include respond-to($phone) {
      padding: 0 10px;
      font-size: 14px;
      text-align: center;
    }
  }
}
</style>

<style lang="scss">
@import './../../../styles/_utilities';
.related-queries {
  .control {
    &-buttons {
      .el-button {
        /*min-width: 245px;*/
        min-width: auto;
        max-width: auto;
        color: #0080ff;

        @include respond-to($phone) {
          float: left;
          width: auto;
          margin-right: 10px;
        }

        + .el-button {
          @include respond-to($phone) {
            margin-top: 10px;
            margin-left: 0;
          }
        }
      }
    }
  }
  &.control {
    @include respond-to($phone) {
      padding-bottom: 7px;
      overflow-x: auto;
    }
  }
}

.related-queries-modal {
  overflow: hidden;
  background-color: rgba(#f4f6fa, 0.9);

  @include respond-to($phone) {
    background-color: rgba(#f4f6fa, 0.95);
  }

  .el-dialog {
    max-height: 75%;

    @extend %scrollbar;

    @include respond-to($phone) {
      max-height: 100%;
      background-color: transparent;
    }
    @include respond-to('(max-height: 800px)') {
    }
  }
  .el-dialog__header {
    padding: 40px 50px 0 50px;

    @include respond-to($phone) {
      padding: 20px 30px 0 30px;
      text-align: center;
    }
  }
  .el-dialog__title {
    color: #273a58;
    font-size: 24px;
    font-weight: 700;

    @include respond-to($phone) {
      color: #000;
      font-size: 14px;
    }
  }
  .el-dialog__headerbtn {
    top: 40px;
    right: 45px;

    @include respond-to($phone) {
      top: 16px;
      right: auto;
      left: 20px;
      font-size: 22px;

      .el-icon-close:before {
        content: '\E600';
      }
    }
  }
  .el-dialog__body {
    padding: 30px 50px 40px;

    @include respond-to($phone) {
      padding-top: 20px;
      padding-right: 22px;
      padding-left: 22px;
    }
  }

  @include respond-to($phone) {
    .el-dialog {
      width: 100% !important;
      height: 100vh;
      margin: 0 !important;
      border-radius: 0;
    }
  }
}
</style>
