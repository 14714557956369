import { customer } from '../../api'
import { Message } from 'element-ui'

const initialState = () => ({
  loading: false,
  sustainabilityForm: [
    {
      label: 'Record Type:',
      prop: 'recordType',
      options: ['Building', 'General', 'Vehicle'],
      placeholder: 'Building',
      elementType: 'select',
    },
    {
      label: 'Asset Type:',
      prop: 'assetType',
      rule: {
        required: true,
        message: 'Record Type required',
        trigger: 'change',
      },
      options: [
        { name: 'Commercial Building', type: 'Building' },
        { name: 'Data Center Facility', type: 'Building' },
        { name: 'Air Travel', type: 'General' },
        { name: 'Ground Travel', type: 'General' },
        { name: 'Hotel Stay', type: 'General' },
        { name: 'Fleet Vehicle', type: 'Vehicle' },
        { name: 'Freight Hauling', type: 'Vehicle' },
        { name: 'Private Jet', type: 'Vehicle' },
        { name: 'Rental Car', type: 'Vehicle' },
      ],
      placeholder: 'Commercial Building',
      elementType: 'select',
    },
    {
      label: 'Business Region:',
      prop: 'businessRegion',
      rule: {
        required: true,
        message: 'Business Region required',
        trigger: 'change',
      },
      options: [
        'AMER',
        'Africa',
        'LATAM',
        'Central America',
        'North America',
        'Middle East',
        'ANZ',
        'Europe',
        'APAC',
        'Central Asia',
        'EMEA',
        'South America',
        'SouthEast Asia',
      ],
      placeholder: 'AMER',
      elementType: 'select',
    },
    {
      label: 'Company-Owned Asset:',
      prop: 'companyOwnedAsset',
      info: 'This is required for Scope 1 or Scope 2 emissions calculations.',
      rule: {
        required: true,
        message: 'Company-Owned Asset required',
        trigger: 'change',
      },
      options: ['Yes', 'No'],
      placeholder: 'Select',
      elementType: 'select',
    },
    {
      label: 'Lease ID:',
      prop: 'leaseId',
      elementType: 'input',
    },
    {
      label: 'Lease Expiration Date:',
      prop: 'leaseExpirationDate',
      placeholder: 'Select Date',
      elementType: 'date',
    },
    {
      label: 'Facility ID:',
      prop: 'facilityId',
      elementType: 'input',
    },
    {
      label: 'Total Building Area:',
      prop: 'totalBuildingArea',
      elementType: 'input',
    },
    {
      label: 'Occupied Building Floor Area:',
      prop: 'occupiedFloorArea',
      elementType: 'input',
    },
    {
      label: 'Occupied Building Floor Area Unit:',
      prop: 'occupiedAreaUnit',
      options: ['sq ft', 'm2'],
      placeholder: 'Select',
      elementType: 'select',
    },
    {
      label: 'Description:',
      prop: 'description',
      elementType: 'input',
    },
  ],
  sustModel: {
    recordType: '',
    businessRegion: '',
    companyOwnedAsset: null,
    description: '',
    facilityId: '',
    leaseExpirationDate: null,
    leaseId: null,
    occupiedFloorArea: 0,
    occupiedAreaUnit: '',
    assetType: '',
    totalBuildingArea: 0,
  },
})

export default {
  namespaced: true,
  state: initialState(),

  getters: {
    sustainabilityForm: (state) => state.sustainabilityForm,
    sustModel: (state) => state.sustModel,
  },
  mutations: {
    setSustainabilityForm(state, sustainabilityForm) {
      state.sustModel = (({
        recordType,
        businessRegion,
        companyOwnedAsset,
        description,
        facilityId,
        leaseExpirationDate,
        leaseId,
        occupiedFloorArea,
        occupiedAreaUnit,
        assetType,
        totalBuildingArea,
      }) => ({
        recordType,
        businessRegion,
        companyOwnedAsset,
        description,
        facilityId,
        leaseExpirationDate,
        leaseId,
        occupiedFloorArea,
        occupiedAreaUnit,
        assetType,
        totalBuildingArea,
      }))(sustainabilityForm)
      state.sustModel.companyOwnedAsset = sustainabilityForm.companyOwnedAsset
        ? 'Yes'
        : 'No'
    },
    setChangedFieldVal(state, fieldData) {
      state.sustModel[fieldData.prop] = fieldData.value
    },
    setFieldValsOnEdit(state) {
      if (
        !state.sustModel.recordType ||
        !state.sustModel.businessRegion ||
        !state.sustModel.assetType
      ) {
        state.sustModel = {
          ...state.sustModel,
          recordType: 'Building',
          businessRegion: 'AMER',
          assetType: 'Commercial Building',
        }
      }
    },
    resetState(state) {
      state = Object.assign(state, initialState())
    },
  },
  actions: {
    async getSustainabilityData({ state, commit }, { customerId, locationId }) {
      try {
        const response = await customer.getCustomerSustainabilityAttrs(
          customerId,
          locationId,
        )
        commit('setSustainabilityForm', response)
      } catch (e) {
        console.log(e)
        commit('resetState')
      }
    },
    async createSustainabilityAttrs(
      { state, commit, dispatch },
      { customerId, locationId },
    ) {
      try {
        state.sustModel.companyOwnedAsset =
          state.sustModel.companyOwnedAsset === 'Yes'
        const response = await customer.createCustomerSustainabilityAttrs(
          customerId,
          locationId,
          state.sustModel,
        )
        commit('setSustainabilityForm', response)
        Message({
          type: 'success',
          message: 'Sustainability Attributes saved successfully.',
        })
      } catch (e) {
        console.log(e)
        Message({
          type: 'error',
          message:
            'Sustainability Attributes not saved. Please try again later.',
        })
        dispatch('getSustainabilityData', { customerId, locationId })
      }
    },
    async clearLocationsFiltersData({ commit }) {
      await commit('resetState')
    },
  },
}
