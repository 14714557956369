import * as Msal from 'msal'
import _ from 'lodash'
import { application as config } from './config'

const TETANT_NAME = config.msal.tenant_name
const USER_FLOW_NAME = config.msal.user_flow_name

const msalConfig = {
  auth: {
    clientId: config.msal.clientId,
    authority: `https://${TETANT_NAME}.b2clogin.com/tfp/${TETANT_NAME}.onmicrosoft.com/${USER_FLOW_NAME}`,
    validateAuthority: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
}

export default class MsalClient {
  constructor() {
    this.instance = new Msal.UserAgentApplication(msalConfig)
  }

  async login() {
    try {
      const idToken = _.get(
        await this.instance.loginPopup(),
        'idToken.rawIdToken',
      )
      return idToken || _.get(await this.getToken(), 'idToken.rawIdToken')
    } catch (e) {
      console.error('LOGIN ERROR": ', e)
    }
  }

  getToken() {
    return this.instance.acquireTokenSilent().catch(() => {
      this.instance.acquireTokenPopup().catch((e) => {
        console.error('ACQUIRE TOKEN ERROR', e)
      })
    })
  }
}
