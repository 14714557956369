<template>
  <BaseModal
    v-if="showModal"
    :dialogVisible="showModal"
    :title="title"
    @close="close"
    id="energyStarNoScoresReasonsModal"
    class="energy-star-no-scores-reasons-modal"
  >
    <template #content>
      <div class="row reasonsBox">
        <div v-for="(reason, key) in noScoreReasons" :key="key">
          <b v-html="reason.name"></b>
          <div v-html="reason.description"></div>
        </div>
      </div>
      <div class="row">
        Note: these error messages are provided by ENERGY STAR® directly. For
        more help with these errors, contact your Pear.ai support representative
      </div>
    </template>
    <template #footer>
      <div class="controls">
        <el-button type="primary" @click="close">OK</el-button>
      </div>
    </template>
  </BaseModal>
</template>
<script>
import BaseModal from '@/components/BaseModal'
export default {
  components: {
    BaseModal,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default:
        'ENERGY STAR® has not calculated a score for this calendar month for the reason(s) below:',
    },
    noScoreReasons: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
    }
  },
  async mounted() {
    this.loading = false
  },
  async beforeUpdate() {
    this.loading = false
  },
  methods: {
    close() {
      this.$emit('close', 'showEnergyStarNoScoresReasonsModal')
    },
  },
}
</script>
<style lang="scss" scoped>
.energy-star-no-scores-reasons-modal {
  ::v-deep {
    .el-dialog {
      color: #000000;
      min-width: 400px;
      &__body {
        color: #000000;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 0.8em;
      }
      &__title {
        color: #000000;
        font-size: 1.4em;
        font-weight: bold;
      }
    }

    .reasonsBox {
      max-height: 50vh;
      overflow-y: auto;
      background: #ffffff;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      margin: 20px;
      padding: 16px;
    }
  }

  .form-item {
    padding: 0 15px 0 0;
  }
  .controls {
    button {
      width: 170px;
    }
  }
}
</style>
