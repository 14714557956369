<template>
  <div v-loading="loading">
    <h2>Select a Report</h2>
    <div class="filter-item">
      <el-input
        v-model="search"
        classes="mediumInput"
        suffix-icon="el-icon-search"
        placeholder="Search Report Name and Description"
        size="medium"
      ></el-input>
    </div>
    <el-collapse accordion>
      <accordion-item
        v-for="(report, i) in reports"
        :key="report.id"
        :id="`accordion_item_${report.readableId}`"
        :accordionData="report"
        class="reports-accordion-item"
      >
        <template #content>
          <el-button
            class="customize-button"
            type="text"
            @click="openCustomizeReportModal(reports[i])"
          >
            Customize
          </el-button>
          <form-body :report="report" @report-created="setReportOutput" />
        </template>
      </accordion-item>
    </el-collapse>
    <customize-report-modal
      :visible="showCustomizeReportModal"
      @close="closeCustomizeReportModal"
      :report="currentReport"
      @change-filters="setFilters"
    />
    <el-pagination
      class="pagination-container"
      background
      layout="prev, pager, next, sizes"
      v-if="
        (total > pagination.pageSizes[0] && pagination.offset === 0) ||
        pagination.offset !== 0
      "
      :page-size="+pagination.limit"
      :page-sizes="pagination.pageSizes"
      :total="total"
      :current-page="currentPage"
      @current-change="changeOffset"
      @size-change="changeLimit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AccordionItem from '@/components/AccordionItem'
import FormBody from '../../ReportsComponents/Form'
import { isNil } from 'lodash'
import CustomizeReportModal from '../../Modals/CustomizeReportModal'

const getDefaultBliFilters = () => ({
  query: '',
  invoice_date_min: null,
  invoice_date_max: null,
  commodity: [],
  vendor: [],
  pear_category: [],
  location: [],
  usage_uom: [],
})

export default {
  props: {
    resetState: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    search(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.setLoading(true)
          this.getCreateReports({
            customerId: this.customerId,
            params: { search: this.search },
          })
          this.awaitingSearch = false
        }, 1000)
      }
      this.awaitingSearch = true
    },
    resetState(val) {
      if (this.resetState) {
        this.resetReportsState()
        this.search = ''
        this.$emit('delete-search-reset', false)
      }
    },
  },
  components: { AccordionItem, FormBody, CustomizeReportModal },
  data() {
    return {
      search: '',
      successOpened: false,
      filters: getDefaultBliFilters(),
      awaitingSearch: false,
      showCustomizeReportModal: false,
      currentReport: null,
      customerId: this.$route.params.customerId,
    }
  },
  computed: {
    ...mapGetters({
      reports: 'createReports/reports',
      currentPage: 'createReports/currentPage',
      pagination: 'createReports/pagination',
      loading: 'createReports/loading',
      total: 'createReports/totalResults',
      scheduledReports: 'scheduledReports/scheduledReports',
      availableFilters: 'availableFiltersReportsBudgeting/availableFilters'
    }),
    currentPage() {
      return this.pagination.offset / this.pagination.limit + 1
    },
  },
  methods: {
    ...mapMutations({
      resetReportsState: 'createReports/resetState',
      setLoading: 'createReports/setLoading',
      setReports: 'createReports/setReports',
    }),
    ...mapActions({
      getCreateReports: 'createReports/getReports',
      loadReportAvailableValues: 'availableFiltersReportsBudgeting/getTemplateAvailableValues'

    }),
    async openCustomizeReportModal(report) {
      this.setLoading(true)
      const { customerId } = this.$route.params
      await this.loadReportAvailableValues({ customerId, templateId: report.id })
      this.currentReport = report
      this.currentReport.availableFilters = this.availableFilters
      this.showCustomizeReportModal = true
      this.setLoading(false)
    },
    closeCustomizeReportModal() {
      this.showCustomizeReportModal = false
    },
    setFilters(filters) {
      if (filters.title) {
        this.currentReport.customReportName = filters.title
        delete filters.title
      }
      if (filters.saveFormat) {
        this.currentReport.saveFormat = filters.saveFormat || 'xlsx'
        delete filters.saveFormat
      }
      if (filters.start_date && filters.end_date) {
        this.currentReport.start_date = filters.start_date
        this.currentReport.end_date = filters.end_date
      }
      this.currentReport.appliedFilters = {
        ...filters,
        dateRange: filters.dateRange,
        pickerVisible: undefined,
      }
      delete filters.start_date
      delete filters.end_date
    },
    setReportOutput(reportTitle) {
      if (
        !isNil(this.currentReport) &&
        !isNil(this.currentReport.customReportName) &&
        !isNil(this.currentReport.saveFormat)
      ) {
        this.currentReport.customReportName = reportTitle
        this.currentReport.saveFormat = 'xlsx'
        delete this.currentReport.start_date
        delete this.currentReport.end_date
        delete this.currentReport.dateRange
        delete this.currentReport.appliedFilters
        delete this.currentReport.selectVal
      }
    },
    changeLimit(limit) {
      if (this.loading) {
        return
      }
      const localCurrentPage = Math.floor(
        this.pagination.offset / this.pagination.limit + 1,
      )
      this.pagination.limit = limit
      if (this.pagination.limit * localCurrentPage > this.total) {
        return this.changeOffset(
          Math.floor(this.total / this.pagination.limit + 1),
        )
      } else {
        return this.changeOffset(localCurrentPage)
      }
    },
    async changeOffset(page) {
      if (this.loading) {
        return
      }
      this.pagination.offset = (page - 1) * this.pagination.limit
      this.handleRouteAndApiCall({
        ...this.$route.query,
        limit: this.pagination.limit,
        offset: this.pagination.offset,
      })
    },
    handleRouteAndApiCall(query) {
      const { customerId } = this.$route.params
      this.setLoading(true)
      this.$router.push({
        query,
      })
      this.getCreateReports({
        customerId,
        params: { ...query },
      })
    },
  },
}
</script>
<style lang="scss">
.reports-accordion-item.accordion-item {
  .el-collapse-item__header {
    height: fit-content;
  }
}
</style>
<style lang="scss" scoped>
@import '../../../../../styles/components/_pagination';

.customize-button {
  padding: 2em 0;
  font-weight: 600;
}
</style>
