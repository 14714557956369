import { hosts } from '@/lib/api.configs'
import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'
import qs from 'qs'

export const types = [
  {
    label: 'Bill Details Report',
    value: 'CUSTOMER_BILL_DETAILS',
  },
  {
    label: 'Monthly Usage Detail Report',
    value: 'CUSTOMER_MONTHLY_USAGE',
  },
  {
    label: 'Charges by Location Report',
    value: 'CUSTOMER_CHARGES_BY_LOCATION',
  },
  {
    label: 'Consumption by Location Report',
    value: 'CUSTOMER_CONSUMPTION_BY_LOCATION',
  },
  {
    label: 'Unit Cost Report',
    value: 'CUSTOMER_UNIT_COST',
  },
  {
    label: 'Account IDs Report',
    value: 'CUSTOMER_ACCOUNTS',
  },
  {
    label: 'Tableau Extract Report',
    value: 'CUSTOMER_TABLEAU_REPORT',
  },
  {
    label: 'Billing Line Items Report',
    value: 'CUSTOMER_BILLING_LINE_ITEMS',
  },
  {
    label: 'Location Report',
    value: 'CUSTOMER_LOCATIONS',
  },
  {
    label: 'ENERGY STAR® Meter Reads',
    value: 'CUSTOMER_ENERGY_STAR_METER_REPORT',
  },
]

const getFiltersData = (customerId) => {
  return {
    type: types,
    format: [
      {
        label: 'Microsoft Excel (.xlsx)',
        value: 'xlsx',
      },
      {
        label: 'CSV',
        value: 'csv',
      },
    ],
  }
}

/**
 * @description set pretty types
 * @param {array} reports
 */
const setPrettyTypes = (reports) => {
  return {
    ...reports,
    data: reports.data.map((item) => ({
      ...item,
      title: (types.find((type) => type.value === item.type) || {}).label,
    })),
  }
}

/**
 * @description get reports list
 * @param {string} customerId
 * @param {object} params
 */
const list = ({ customerId, params }) =>
  http(hosts.v1)
    .get(
      `/customers/${customerId}/reports?${qs.stringify(params, {
        arrayFormat: 'repeat',
      })}`,
    )
    .then(dataMiddleware)
    .then((data) => ({
      meta: data.meta || {},
      data: data.data || [],
    }))
    .then((res) => setPrettyTypes(res))

/**
 * @description get report by id
 * @param {string} customerId
 * @param {string} reportId
 */
const report = (customerId, reportId) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/reports/${reportId}`)
    .then(dataMiddleware)
    .then((report) => ({
      ...report,
      title: types.find((e) => e.value === report.type).label,
    }))

/**
 * @description create report
 * @param {string} customerId
 * @param {object} reportOptions
 */
const createReport = (customerId, reportOptions) =>
  http(hosts.v1)
    .post(`/customers/${customerId}/reports`, reportOptions)
    .then(dataMiddleware)

/**
 * @description fetch filters
 * @param {string} customerId
 */
const fetchFilters = (customerId) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/generate-billing-line-items-export/filters`)
    .then(dataMiddleware)

/**
 * @description fetch preview
 * @param {string} customerId
 * @param {object} params
 */
const fetchPreview = (customerId, params, body) =>
  http(hosts.v3)
    .post(`/customers/${customerId}/reports/preview`, body)
    .then(dataMiddleware)

/**
 * @description get embed token for PowerBI
 * @param {string} customerId
 */
const getEmbedToken = (customerId) =>
  http(hosts.v1)
    .get(`/customers/${customerId}/get-embed-token`)
    .then(dataMiddleware)

export {
  list,
  report,
  getFiltersData,
  createReport,
  fetchFilters,
  fetchPreview,
  getEmbedToken,
}
