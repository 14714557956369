import { analytics } from '../../api'

const initialState = () => ({
  reports: [],
  currentPowerbiReportId: 0,
  currentPowerbiReport: null,
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    getPowerbiReports: (state) => state.reports,
    getCurrentPowerbiReportId: (state) => state.currentPowerbiReportId,
    getCurrentPowerbiReport: (state) => state.currentPowerbiReport,
  },
  actions: {
    setCurrentPowerbiReportId({ state }, payload) {
      state.currentPowerbiReportId = payload
      state.currentPowerbiReport = state.reports.find(
        (report) => report.id === state.currentPowerbiReportId,
      )
    },
    async loadPowerbiReports({ state, dispatch }, customerId) {
      state.reports = await analytics
        .getPowerbiReports({ customerId })
        .then((response) => response.results)
    },
  },
}
