<template>
  <div v-loading="loading">
    <filter-container v-if="scheduledView" :filters="filtersValues" />
    <el-table
      :data="scheduledReports"
      @sort-change="sortTable"
      style="width: 100%"
    >
      <el-table-column prop="customReportName" label="Report Name" sortable>
        <template slot-scope="{ row }">
          {{ row.customReportName }}
        </template>
      </el-table-column>
      <el-table-column prop="title" label="Report Type" sortable>
        <template slot-scope="{ row }">
          {{ row.reportTemplate.title }}
        </template>
      </el-table-column>
      <el-table-column
        prop="saveFormat"
        label="Format"
        sortable
        :min-width="50"
      >
        <template slot-scope="{ row }">
          {{ row.saveFormat.toUpperCase() }}
        </template>
      </el-table-column>
      <el-table-column
        prop="emailRecipientsFlat"
        label="Email"
        sortable
        :min-width="50"
      >
        <template slot-scope="{ row }">
          <div>{{ hasEmailRecipients(row) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="sftpConnectionName"
        label="SFTP"
        sortable
        :min-width="50"
      >
        <template slot-scope="{ row }">
          <p v-if="getSftpStatus(row) === sftpConnectionStatus.ACTIVE">Yes</p>
          <p v-else-if="getSftpStatus(row) === sftpConnectionStatus.NONE">-</p>
          <el-popover
            v-else-if="getSftpStatus(row) === sftpConnectionStatus.DELETED"
            placement="top"
            width="260"
            trigger="hover"
            content="The SFTP Location for this Report was deleted. Please update the Delivery Method with a new SFTP Location."
          >
            <el-button
              class="sftp-warn"
              type="text"
              slot="reference"
              icon="el-icon-warning"
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="recurringType"
        label="Schedule"
        sortable
        :min-width="100"
      >
        <template slot-scope="{ row }">
          {{ transformToSchedule(row.recurringType, row.time) }}
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="{ row }">
          <el-button
            class="play-btn btn-size"
            type="text"
            icon="el-icon-video-play"
            @click="
              runNowScheduledReports({
                customerId: row.customerId,
                scheduledReportId: row.id,
                reportName: row.customReportName,
              })
            "
          />
          <el-button
            class="edit-btn btn-size"
            type="text"
            icon="el-icon-edit"
            @click="openCustomizeReportModal(row)"
          />
          <el-button
            class="close-btn btn-size"
            type="text"
            icon="el-icon-close"
            @click="
              deleteScheduledReports({
                customerId: row.customerId,
                scheduledReportId: row.id,
                reportName: row.customReportName,
                limit: $route.query.limit,
                offset: $route.query.offset,
              })
            "
          />
        </template>
      </el-table-column>
    </el-table>
    <customize-report-modal
      :visible.sync="showCustomizeReportModal"
      :report="currentReport"
      :update="true"
      @close="closeCustomizeReportModal()"
      @change-filters="updateScheduledRep($event)"
    />
    <el-pagination
      class="pagination-container"
      background
      layout="prev, pager, next, sizes"
      v-if="total > pagination.page_sizes[0]"
      :page-size="pagination.limit"
      :page-sizes="pagination.page_sizes"
      :total="total"
      :current-page="currentPage"
      @current-change="changeOffset"
      @size-change="changeLimit"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import CustomizeReportModal from '../../Modals/CustomizeReportModal'
import { DateTime } from 'luxon'
import { isNil, startCase, isUndefined } from 'lodash'
import FilterContainer from '../../Filters/FilterContainer'
import { serializeOrderBy } from '@/lib/helpers'

export default {
  components: {
    CustomizeReportModal,
    FilterContainer,
  },
  computed: {
    ...mapGetters({
      reports: 'createReports/reports',
      scheduledReports: 'scheduledReports/scheduledReports',
      filtersValues: 'scheduledReports/filters',
      availableFilters: 'availableFiltersReportsBudgeting/availableFilters',
      pagination: 'scheduledReports/pagination',
      loading: 'scheduledReports/loading',
      formBody: 'createReports/formBody',
      currentPage: 'scheduledReports/currentPage',
      total: 'scheduledReports/totalResults',
    }),
    scheduledView() {
      return this.$route.params.tab === 'scheduled'
    },
    hasEmailRecipients() {
      return (row) => (row.emailRecipients.length > 0 ? 'Yes' : '-')
    },
    getSftpStatus() {
      return (row) => {
        const { NONE, ACTIVE, DELETED } = this.sftpConnectionStatus
        if (
          !isNil(row.sftpConnection) &&
          !isNil(row.sftpConnection.deletedAt)
        ) {
          return DELETED
        }
        if (
          !isNil(row.sftpConnection) &&
          row.sftpConnection.hasOwnProperty('id')
        ) {
          return ACTIVE
        }
        return NONE
      }
    },
  },
  data() {
    return {
      showCustomizeReportModal: false,
      currentReport: {},
      sftpConnectionStatus: {
        NONE: 'none',
        ACTIVE: 'active',
        DELETED: 'deleted',
      },
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'scheduledReports/setLoading',
    }),
    ...mapActions({
      getScheduledReports: 'scheduledReports/getScheduledReports',
      runNowScheduledReports: 'scheduledReports/runNowScheduledReports',
      deleteScheduledReports: 'scheduledReports/deleteScheduledReports',
      updateScheduledReports: 'scheduledReports/updateScheduledReports',
      loadReportAvailableValues: 'availableFiltersReportsBudgeting/getTemplateAvailableValues'
    }),
    transformToSchedule(recurringType, time) {
      const [occurenceTypeUpperCase] = recurringType.split(':')
      const occurenceType = occurenceTypeUpperCase.toLowerCase()
      if (isNil(occurenceType) || isNil(time)) {
        return 'N/A'
      }
      if (occurenceType.toLowerCase() === 'weekdays') {
        return `Every weekday at ${time.toUpperCase()}`
      }
      return `${startCase(occurenceType)} at ${time.toUpperCase()}`
    },
    async openCustomizeReportModal(scheduledReport) {
      const { customerId } = this.$route.params
      if (
        !isNil(this.reports) &&
        !isUndefined(scheduledReport.appliedFilters)
      ) {
        const rep = this.reports.find(
          (report) => +report.id === +scheduledReport.reportTemplateId,
        )
        await this.loadReportAvailableValues({ customerId, templateId: rep.id })
        scheduledReport = { ...scheduledReport, reportTemplate: { ...rep, availableFilters: this.availableFilters } }
        this.currentReport = { ...scheduledReport, formBody: this.formBody }
        this.showCustomizeReportModal = true
      }
    },
    closeCustomizeReportModal() {
      this.showCustomizeReportModal = false
    },
    transformRecurringType(recurringType) {
      let recurringTypeArrOfWords = recurringType.split(' ')
      let recurringTypeArrOfWordsFiltered = recurringTypeArrOfWords.filter(
        (word) => word !== 'on' && word !== 'the',
      )
      let [type, valuePrefix, valueSuffix] = recurringTypeArrOfWordsFiltered
      valuePrefix = startCase(valuePrefix)
      type = type.toUpperCase()

      let dateValidation = DateTime.fromFormat(valuePrefix, 'LLLL').isValid
      let month = DateTime.fromFormat(valuePrefix, 'LLLL').toFormat('LLL')
      if (recurringType === 'Does not repeat') {
        return 'ONCE'
      }
      if (recurringType === 'Daily') {
        return 'DAILY'
      }
      if (recurringType === 'Every weekday') {
        return 'WEEKDAYS'
      }
      return `${type}: ${dateValidation ? month : valuePrefix}${
        valueSuffix ? '-' : ''
      }${
        valueSuffix
          ? !isNaN(parseInt(valueSuffix))
            ? parseInt(valueSuffix)
            : valueSuffix
          : ''
      }`
    },
    updateScheduledRep(updatedReport) {
      this.closeCustomizeReportModal()
      const recurringType = this.transformRecurringType(
        updatedReport.recurringType,
      )
      let requestBody = {
        ...updatedReport,
      }
      delete requestBody.reportTemplate
      this.updateScheduledReports({
        customerId: this.$route.params.customerId,
        body: { ...requestBody, recurringType },
      })
    },
    changeLimit(limit) {
      if (this.loading) {
        return
      }
      const localCurrentPage = Math.floor(
        this.pagination.offset / this.pagination.limit + 1,
      )
      this.pagination.limit = limit
      if (this.pagination.limit * localCurrentPage > this.total) {
        return this.changeOffset(
          Math.floor(this.total / this.pagination.limit + 1),
        )
      }
      return this.changeOffset(localCurrentPage)
    },
    async changeOffset(page) {
      if (this.loading) {
        return
      }
      this.pagination.offset = (page - 1) * this.pagination.limit
      this.handleRouteAndApiCall({
        ...this.$route.query,
        limit: this.pagination.limit,
        offset: this.pagination.offset,
      })
    },
    handleRouteAndApiCall(query) {
      const { customerId } = this.$route.params
      this.setLoading(true)
      this.$router.push({
        query,
      })
      this.getScheduledReports({
        customerId,
        params: { ...query },
      })
    },
    sortTable({ prop, order }) {
      const { query } = this.$route
      if (isNil(prop) || isNil(order)) {
        this.handleRouteAndApiCall({
          ...query,
          orderBy: [],
        })
        return
      }
      this.handleRouteAndApiCall({
        ...query,
        orderBy: [serializeOrderBy(prop, order)],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.play-btn {
  color: #0080ff;
}

.edit-btn {
  color: #3b3e48;
}

.close-btn {
  color: #dc2c25;
}
.close-btn:focus {
  color: #dc2c25;
}
.close-btn:hover {
  color: #0080ff;
}
.sftp-warn {
  color: #ff0000;
  font-size: 16px;
}
.btn-size {
  font-size: 16px;
}
</style>
