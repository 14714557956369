<template>
  <el-form
    ref="usageConfigRef"
    :model="usageConfig"
    class="usage-config-container"
    label-position="top"
  >
    <section-subtitle>
      What historical data should be used in the budget forecast?
    </section-subtitle>
    <div class="historical-data-container">
      <el-form-item label="Historical Data:" v-if="historicalDataCustom">
        <template #label>
          <div class="hist-label">
            <div>Historical Data:</div>
            <div class="info-msg">
              <i class="el-icon-warning-outline" />
              Custom Range selection limit is 5 years
            </div>
          </div>
        </template>
        <el-select
          class="historical-data"
          v-model="usageConfig.historicalData"
          size="small"
          filterable
        >
          <el-option :value="'customRange'" :label="'Custom Range'" />
        </el-select>
      </el-form-item>
      <el-form-item label="Historical Data:" v-else>
        <el-select
          class="historical-data"
          v-model="usageConfig.historicalData"
          size="small"
          filterable
          clearable
        >
          <el-option
            v-for="historicalItem in historicalDataOptions"
            :key="historicalItem.value"
            :value="historicalItem.value"
            :label="historicalItem.label"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="usageConfig.historicalData === 'customRange'"
        class="historical-date"
        prop="historicalDates"
        :rules="rules"
      >
        <el-date-picker
          v-model="usageConfig.historicalDates"
          :picker-options="pickerOptions"
          format="MM/yyyy"
          type="monthrange"
          range-separator="-"
          start-placeholder="Start date"
          end-placeholder="End date"
          size="small"
          @change="handleDateChange"
        />
      </el-form-item>
    </div>
    <el-form-item
      class="pt-10"
      label="What usage value should be used for analytics?"
    >
      <el-select
        class="loadProj"
        v-model="usageConfig.loadProjBasedOnHistoricalData"
        size="small"
        filterable
        clearable
      >
        <el-option
          v-for="loadProjItem in loadProjBasedOnHistoricalOptions"
          :key="loadProjItem.value"
          :value="loadProjItem.value"
          :label="loadProjItem.label"
        />
      </el-select>
    </el-form-item>
    <div class="section-with-radio">
      <section-subtitle>
        When usage data is missing for a month should the system fill in gaps?
      </section-subtitle>
      <el-radio-group class="radio-buttons" v-model="usageConfig.fillInGaps">
        <el-radio :label="true">Yes</el-radio>
        <el-radio :label="false">No</el-radio>
      </el-radio-group>
    </div>
    <el-form-item label="Gap Filling Method">
      <template #label>
        <div>
          <label :class="{ 'is-disabled': !usageConfig.fillInGaps }">
            Gap Filling Method
          </label>
          <button-with-popover
            icon="el-icon-info"
            placement="top"
            width="240"
            trigger="hover"
            content="How missing bills will be treated in budget forecasting."
            :disabled="!usageConfig.fillInGaps"
          />
        </div>
      </template>
      <el-select
        class="gapFillingMethod"
        v-model="usageConfig.gapFillingMethod"
        :disabled="!usageConfig.fillInGaps"
        size="small"
        filterable
        clearable
      >
        <el-option
          v-for="gapItem in gapFillingMethodOptions"
          :key="gapItem.value"
          :value="gapItem.value"
          :label="gapItem.label"
        />
      </el-select>
    </el-form-item>
    <div class="section-with-radio">
      <section-subtitle>
        Should the system smooth potential outliers in usage data?
      </section-subtitle>
      <button-with-popover
        icon="el-icon-info"
        placement="top"
        class="outlier-q"
        width="480"
        trigger="hover"
        content="Vendors sometimes make mistakes in billing.
        This step is to ensure that vendors' errors are not corrupting your budget forecasting.
        We compare the consumption value of each month with the median of consumption around it
        to detect if each data point is an outlier, and most likely a mistake.
        We recommend a factor of 10, when comparing each data point to its neighbors.
        You can make this step less (or more) sensitive by increasing (or decreasing) the multiplier."
      />
      <el-radio-group class="radio-buttons" v-model="usageConfig.usageData">
        <el-radio :label="true">Yes</el-radio>
        <el-radio :label="false">No</el-radio>
      </el-radio-group>
    </div>
    <div class="outliers">
      <el-select
        class="outlierThreshold"
        v-model="usageConfig.outlierThreshold"
        :disabled="!usageConfig.usageData"
        size="small"
        filterable
        clearable
      >
        <el-option
          v-for="outlierThresholdOption in outlierThresholdOptions"
          :key="outlierThresholdOption.value"
          :value="outlierThresholdOption.value"
          :label="outlierThresholdOption.label"
        />
      </el-select>
      <p class="outlier-p" :class="{ 'is-disabled': !usageConfig.usageData }">
        higher than surrounding
      </p>
      <el-select
        v-model="usageConfig.outlierWindow"
        class="outlierWindow"
        :disabled="!usageConfig.usageData"
        size="small"
        filterable
        clearable
      >
        <el-option
          v-for="outlierWindowOption in outlierWindowOptions"
          :key="outlierWindowOption.value"
          :value="outlierWindowOption.value"
          :label="outlierWindowOption.label"
        />
      </el-select>
    </div>
  </el-form>
</template>

<script>
import _, { isNil } from 'lodash'
import SectionSubtitle from '@/components/SectionSubtitle'
import ButtonWithPopover from '../../../../../components/Buttons/ButtonWithPopover'
import { usageConfigDefault } from '../../Constants/Defaults'
import { DateTime } from 'luxon'

const getOutlineThresholdOptions = () => {
  let outlierThresholdOptions = []
  for (let i = 2; i <= 20; i++) {
    if (i <= 10 || i === 20) {
      outlierThresholdOptions.push({ value: i, label: `${i}x` })
    }
  }
  return outlierThresholdOptions
}

const getOutlierWindowOptions = () => {
  let outlierWindowOptions = []
  for (let i = 2; i <= 12; i++) {
    outlierWindowOptions.push({ value: i, label: `${i} months` })
  }
  return outlierWindowOptions
}

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    usageConfigRefresh: {
      type: Object,
      default: () => {},
    },
  },
  components: { ButtonWithPopover, SectionSubtitle },
  computed: {
    historicalDataCustom() {
      return [7033, 8110, 117].includes(+this.$route.params.customerId)
    },
  },
  data() {
    var checkPeriod = (rule, value, callback) => {
      if (this.historicalDataCustom) {
        const [startDate, endDate] = value
        const dateDiff = this.dateDiff(
          DateTime.fromJSDate(startDate),
          DateTime.fromJSDate(endDate),
        )
        if (dateDiff / 12 > 5) {
          return callback(new Error('Only 5 years can be selected'))
        }
      }
    }
    return {
      historicalDataOptions: [
        { value: 'all', label: 'All (recommended)' },
        { value: 'customRange', label: 'Custom Range' },
      ],
      loadProjBasedOnHistoricalOptions: [
        { value: 'max', label: 'Max Value' },
        { value: 'median', label: 'Median (recommended)' },
      ],
      gapFillingMethodOptions: [
        {
          value: 'averageOfAllData',
          label: 'Fill with average value of all data',
        },
        {
          value: 'averageOfClosest',
          label: 'Fill with average of the closest values (default)',
        },
      ],
      usageConfig: {
        ...usageConfigDefault,
      },
      minDate: '',
      pickerOptions: {
        disabledDate: this.disabledDates,
        onPick: this.onPick,
      },
      rules: [
        { required: true, message: 'Historical Data required.' },
        { validator: checkPeriod, trigger: 'change' },
      ],
      outlierThresholdOptions: getOutlineThresholdOptions(),
      outlierWindowOptions: getOutlierWindowOptions(),
    }
  },
  watch: {
    opened: {
      handler() {
        Object.assign(this.usageConfig, this.usageConfigRefresh)
        this.usageConfig.historicalData = this.historicalDataCustom
          ? 'customRange'
          : this.usageConfigRefresh.historicalData
        const date1 = new Date()
        date1.setFullYear(date1.getFullYear() - 5)
        const date2 = new Date()
        this.usageConfig.historicalDates =
          this.historicalDataCustom &&
          this.usageConfig.historicalDates.length === 0
            ? [date1, date2]
            : this.usageConfig.historicalDates
      },
      immediate: true,
    },
    'usageConfig.historicalData'(val) {
      if (val === 'all') {
        this.usageConfig.historicalDates = []
      }
      this.clearValidation()
    },
  },
  methods: {
    disabledDates(date) {
      const isFutureDate = date.getTime() > Date.now()
      if (this.minDate) {
        let firstFutureAvailableDate = new Date(
          new Date(this.minDate).setFullYear(
            new Date(this.minDate).getFullYear() + 1,
          ),
        )
        let isDateGreaterThanSelection =
          date.getTime() > new Date(this.minDate).getTime()
        let isDateLesserThanSelection =
          date.getTime() < firstFutureAvailableDate.getTime()
        let isDateSelected =
          isDateGreaterThanSelection && isDateLesserThanSelection
        return isFutureDate || isDateSelected
      }
      return isFutureDate
    },
    dateDiff(start, end) {
      let dateDifference
      if (!isNil(start) && !isNil(end)) {
        dateDifference = end.diff(start, 'months').months
      }
      return dateDifference
    },
    validateForm() {
      let formValidity = false
      this.$refs.usageConfigRef.validate((valid) => {
        if (!valid) {
          formValidity = true
        }
      })
      return formValidity
    },
    onPick({ minDate }) {
      this.minDate = minDate
    },
    clearValidation() {
      this.$refs.usageConfigRef.clearValidate('historicalDates')
    },
    handleDateChange() {
      if (_.isNil(this.usageConfig.historicalDates)) {
        this.minDate = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.is-disabled {
  opacity: 0.5;
}
.pt-10 {
  padding-top: 10px;
}
.info-msg {
  margin: 0;
  font-size: 12px;
}
.hist-label {
  display: flex;
  flex-direction: column;
  line-height: 30px;
}
.loadProj {
  width: 200px;
}
.gapFillingMethod {
  width: 320px;
}
.outlier-q {
  flex-grow: 1;
  padding-left: 10px;
}
.outliers {
  display: inline-flex;
  justify-content: space-between;
  width: 60%;
  .outlierThreshold {
    width: 90px;
  }
  .outlier-p {
    margin: 0;
    align-self: center;
  }
  .outlierWindow {
    width: 120px;
  }
}
.historical-data-container {
  display: inline-flex;
  .historical-data {
    width: 160px;
    padding-right: 8px;
  }
  .historical-date {
    align-self: flex-end;
    height: 38px;
  }
}
.usage-config-container {
  display: flex;
  flex-direction: column;
  .section-with-radio {
    display: inline-flex;
    justify-content: space-between;
    height: 20px;
    margin: 10px 0;
    .radio-buttons {
      display: inline-flex;
      align-items: end;
    }
  }
}
</style>
