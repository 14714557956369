<template lang="pug">
  .message-item-chart
    .card-title
      | Highest Cost Locations
      el-button(type="text", icon="el-icon-menu", @click="showDetails")
    bar-chart(v-if="widgetData.length", :data="widgetData", :width="348", :bar-width="45")
    el-dialog(title="Highest Cost Locations",
              class="details-modal",
              width="90%",
              top="5vh",
              :visible.sync="isDetailsModalVisible",
              :append-to-body="true")
      i.icon.icon-rotate
      .card-widget
        bar-chart(v-if="widgetData.length", :data="widgetData", :bar-width="50", :draggable="true")
      .card-table
        //- h3.card-table_title Table Data
        //- el-table(:data="widgetData")
        //-   el-table-column(label="Description", prop="location")
        //-   el-table-column(label="State", prop="state")
        //-   el-table-column(label="Cost", prop="costs")
        table-control(title="Amount Due")

</template>

<script>
import { dashboard } from '@/api'
import BarChart from '@/components/Charts/BarChart.vue'
import TableControl from '@/components/Controls/TableControl.vue'

export default {
  components: {
    BarChart,
    TableControl,
  },
  data() {
    return {
      widgetData: [],
      count: 0,
      isDetailsModalVisible: false,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.widgetData = await dashboard.getData('most_consumption_location')
    },
    showDetails() {
      this.isDetailsModalVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../styles/utilities';

.message-item-chart {
  max-width: 380px;
  padding: 15px;
  margin-bottom: 10px;
  margin-left: 62px;
  color: #484848;
  font-size: 10px;
  line-height: 14px;
  border: 1px solid #dde5e6;
  border-radius: 0 16px 16px 16px;
  background-color: #fff;
  box-sizing: border-box;

  @include respond-to($phone-l) {
    margin-left: 0;
  }
  @include respond-to($phone) {
    margin-left: 0;
  }
  .bar-chart {
    padding-right: 0;
    padding-left: 0;
  }
}
.card-title {
  display: flex;
  font-size: 14px;
  line-height: 17px;
  font-weight: 900;
  color: #5d6a89;
  margin-bottom: 5px;

  .el-button {
    padding: 0;
    margin-left: auto;
  }
}
.card-table {
  position: relative;
  padding-top: 15px;

  &_title {
    padding-left: 15px;
    color: #273a58;
    font-size: 17px;
    font-weight: 900;
  }
}
.icon-rotate {
  display: none;
  position: absolute;
  top: 15px;
  right: 50px;

  @include respond-to($phone) {
    display: block;
  }
  @include respond-to($phone-l) {
    display: block;
  }
}
</style>

<style lang="scss">
.card-widget {
  .widget-container::-webkit-scrollbar-thumb {
    background-color: #84b2fa;
  }
}
.details-modal {
  .el-dialog__headerbtn {
    top: 10px;
    font-size: 34px;
  }
}
</style>
