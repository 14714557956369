import { not, isEmpty, isNil, pickBy } from 'ramda'
import * as API from '@/api'
import filtersData from './reports.filters.data'

const SET_LOADING_LIST = 'set_loading_list'
const SET_REPORTS = 'set_reports'
const SET_TOTAL = 'set_total'
const ADD_REPORT = 'add_report'
const UPDATE_REPORT = 'update_report'
const SET_PREVIEW = 'set_preview'
const SET_PREVIEW_LOADING = 'set_preview_loading'
const SET_PREVIEW_METADATA = 'set_preview_metadata'

const initialState = () => ({
  loading_list: false,
  list: [],
  total: null,
  params: null,
  preview: [],
  previewLoading: false,
  previewTotal: 0,
})

export default {
  namespaced: true,
  modules: {
    filtersData,
  },

  state: initialState(),

  mutations: {
    [SET_LOADING_LIST]: (state, flag) => {
      state.loading_list = flag
    },

    [SET_REPORTS]: (state, reports) => {
      state.list = reports
    },

    [SET_TOTAL]: (state, total) => {
      state.total = total
    },

    [ADD_REPORT]: (state, report) => {
      state.list.unshift(report)
    },

    [UPDATE_REPORT]: (state, report) => {
      const existingReport = state.list.findIndex((e) => e.id === report.id)

      if (existingReport !== -1) {
        state.list.splice(existingReport, 1, report)
      }
    },

    [SET_PREVIEW]: (state, preview) => {
      state.preview = preview
    },

    [SET_PREVIEW_METADATA]: (state, previewTotal) => {
      state.previewTotal = previewTotal
    },

    [SET_PREVIEW_LOADING]: (state, loading) => {
      state.previewLoading = loading
    },

    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async resetTabStates({ dispatch }) {
      await dispatch('createReports/reset', {}, { root: true })
      await dispatch('scheduledReports/reset', {}, { root: true })
      await dispatch('completedReports/reset', {}, { root: true })
    },
    async loadReports({
      commit,
      state,
      rootState: {
        customers: { currentCustomerId },
      },
    }) {
      await commit(SET_LOADING_LIST, true)

      try {
        const params = pickBy(
          (val) => not(isNil(val)) && not(isEmpty(val)),
          state.filters.filters,
        )
        const { meta, data } = await API.reports.list({
          customerId: currentCustomerId,
          params,
        })
        await commit(SET_REPORTS, data)
        await commit(SET_TOTAL, Number(meta.content_range.total))
      } catch (err) {
        console.log(`error while loading reports: `, err)
      }
      await commit(SET_LOADING_LIST, false)
    },
    addReport({ commit }, report) {
      commit(ADD_REPORT, report)
    },
    updateReport({ commit }, report) {
      commit(UPDATE_REPORT, report)
    },
    async fetchPreview(
      {
        commit,
        rootState: {
          customers: { currentCustomerId },
        },
      },
      { params, body },
    ) {
      try {
        commit(SET_PREVIEW_LOADING, true)
        const { results, total } = await API.reports.fetchPreview(
          currentCustomerId,
          params,
          body,
        )
        commit(SET_PREVIEW, results)
        commit(SET_PREVIEW_METADATA, total)
        commit(SET_PREVIEW_LOADING, false)
      } catch (e) {
        console.log('error while loading preview: ', e)
      }
    },
  },

  getters: {
    reports: (state) => state.list,
    total: (state) => state.total,
    loading_list: (state) => state.loading_list,
    preview: (state) => state.preview,
    previewLoading: (state) => state.previewLoading,
    previewTotal: (state) => state.previewTotal,
  },
}
