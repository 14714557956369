<template>
  <base-modal :dialogVisible="showModal" width="500px" @close="onClose">
    <template #content>
      <p class="paragraph-style">
        One or more mandatory fields are not completed. Please assign the proper
        values and click the save button.
      </p>
    </template>
    <template #footer>
      <el-button type="primary" class="btn" @click="onClose">OK</el-button>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '../../../../../components/BaseModal'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: { BaseModal },
  data() {
    return {
      showModal: false,
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  font-weight: 600;
  width: 170px;
}
.paragraph-style {
  color: #222222;
  text-align: center;
  word-break: break-word;
}
</style>
