<template lang="pug">
.filter-item.filter-item-utility
  .filter-title(v-if="title") {{ title }}
  .select-wrapper
    el-select(v-model="utility_type.selected",
              :class="{'is-multiple': multiple}",
              size="small",
              data-value="select-utility-type",
              :multiple="multiple", @change="handleChange", filterable)
      el-option(v-for="comm in commodities",
                :key="comm",
                :label="comm",
                :value="comm")
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: () => {},
      required: true,
    },
    value: {
      type: String,
      default: () => 'ELECTRIC',
      required: true,
    },
    commodities: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      utility_type: {
        selected: this.value,
        type: 'all',
      },
    }
  },
  methods: {
    handleChange(val) {
      this.filter.utility_type = val
    },
  },
}
</script>

<style scoped lang="scss">
.select-wrapper {
  margin-top: 16px;
}
</style>
