<template lang="pug">
  .chat-input
    i.icon.icon-help(data-action="open what else modal", @click="helpClick")
    el-input#chat-input(v-model='message',
      ref="input",
      placeholder='Write a message…',
      @focus='handleFocus',
      @blur='handleBlur',
      @input.native='handleMessageInput',
      @keydown.enter.native='submit',
      maxlength="256")
    el-button.button-send(type='primary', @click='sendMessage')
</template>

<script>
const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

export default {
  data() {
    return {
      message: null,
      xDown: null,
      yDown: null,
    }
  },
  mounted() {
    global.window &&
      document.body.addEventListener('touchstart', this.handleTouchStart)
    global.window &&
      document.body.addEventListener('touchmove', this.handleTouchMove)
  },
  beforeDestroy() {
    global.window &&
      document.body.removeEventListener('touchstart', this.handleTouchStart)
    global.window &&
      document.body.removeEventListener('touchmove', this.handleTouchMove)
  },
  methods: {
    helpClick() {
      this.$emit('handlerOpenModal')
    },
    handleTouchMove(e) {
      if (e.touches[0].clientY > window.innerHeight / (IS_IOS ? 1.1 : 1.2)) {
        this.$refs.input.blur()
        this.$emit('keepKeyboardOneped', false)
      }
    },
    handleMessageInput({ target: { value } }) {
      this.message = value
    },
    sendMessage() {
      if (this.message !== null) {
        this.$emit('sendMessage', this.message)
        this.message = null
        this.$refs.input.focus()
      }
    },
    handleFocus() {
      this.$emit('keepKeyboardOneped', true)
      document.body.classList.add('keyboard-opened')
      // setTimeout(() => {
      //   this.$emit('scrollToBottom', { behavior: 'instant' })
      // }, 200);
    },
    handleBlur() {
      document.body.classList.remove('keyboard-opened')
    },
    submit(e) {
      e.preventDefault()
      this.sendMessage()
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../styles/utilities';

.chat-input {
  position: relative;
  min-height: 40px;
  border-radius: 20px;
  border: 1px solid #dcdfe6;
  overflow: hidden;
  box-sizing: border-box;
  background: #fff;

  @include respond-to($phone) {
    min-height: 40px;
  }
}

.icon-help {
  position: absolute;
  top: 9px;
  left: 15px;
  cursor: pointer;
  z-index: 9;
}

.el-textarea,
.el-input {
  padding-right: 85px;
  box-sizing: border-box;
  min-height: auto;
  height: 100%;
  background: transparent;
  font-size: 16px;
  line-height: 22px;

  @include respond-to($phone) {
    padding-right: 40px;
  }
}

.el-button {
  position: absolute;
  right: 20px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  /*background-color: #4a90e2;*/
  background-color: transparent;
  border: 0;

  @include respond-to($phone) {
    right: 15px;
    bottom: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
  }
}

.button-send {
  /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='16' viewBox='0 0 19 16'%3E %3Cpath fill='%23FFF' fill-rule='nonzero' d='M18.318 8.004V8v-.004a.97.97 0 0 0-.589-.896L1.961.196A.97.97 0 0 0 .794.489a.97.97 0 0 0-.01 1.203L5.579 7.98a.033.033 0 0 1 0 .04L.785 14.308a.97.97 0 0 0 .09 1.295.969.969 0 0 0 1.086.201L17.73 8.9a.97.97 0 0 0 .588-.896zM1.54 1.116c-.01-.014-.015-.02 0-.04.016-.02.024-.017.04-.01l14.753 6.46H6.412c-.023-.043-4.872-6.41-4.872-6.41zm.04 13.818c-.016.007-.024.01-.04-.01s-.01-.026 0-.04c0 0 4.849-6.367 4.872-6.41h9.921L1.58 14.935z'/%3E %3C/svg%3E");*/
  /*background-image: url("data:image/svg+xml,%3Csvg width='19px' height='16px' viewBox='0 0 19 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='Text_input/mobile' transform='translate(-343.000000, -11.000000)' fill='%232893F9' fill-rule='nonzero'%3E %3Cg id='input' transform='translate(0.000000, -1.000000)'%3E %3Cpath d='M357.71319,11.2928992 C357.712136,11.2918445 357.711292,11.2907195 357.710237,11.2896648 C357.709183,11.2886101 357.708058,11.2877664 357.707003,11.286782 C357.426878,11.0089759 357.025042,10.9255145 356.657238,11.0693395 L340.6254,17.3379778 C340.201557,17.5036701 339.953248,17.9186215 340.007354,18.3704168 C340.0616,18.8222473 340.400963,19.1666749 340.851912,19.2274604 L348.687426,20.2841925 C348.702087,20.2861964 348.713829,20.2978684 348.715833,20.3126341 L349.772559,28.1481244 C349.833344,28.599076 350.177769,28.9385114 350.629597,28.9926875 C350.670871,28.9976445 350.711757,29 350.752257,29 C351.155077,29 351.511421,28.7597409 351.66203,28.3746725 L357.930702,12.3425649 C358.074456,11.9748286 357.99103,11.5730609 357.71319,11.2928992 Z' id='Shape' transform='translate(349.000000, 20.000000) rotate(45.000000) translate(-349.000000, -20.000000) '%3E%3C/path%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");*/
  background-image: url("data:image/svg+xml,%3Csvg width='20px' height='18px' viewBox='0 0 20 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3Cg id='Sam' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='pc_sam' transform='translate(-1375.000000, -871.000000)' fill='%230080FF' fill-rule='nonzero'%3E %3Cg id='WriteInput' transform='translate(270.000000, 860.000000)'%3E %3Cg id='send-button' transform='translate(1105.000000, 11.000000)'%3E %3Cpolygon id='Shape' points='0 17.8901866 19.9813433 8.94996269 0 0.00973880597 1.37931034 6.96324627 14.2723881 8.94996269 1.37931034 10.9366791'%3E%3C/polygon%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;

  @include respond-to($phone) {
    background-image: url("data:image/svg+xml,%3Csvg width='20px' height='18px' viewBox='0 0 20 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3Cg id='Sam' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='pc_sam' transform='translate(-1375.000000, -871.000000)' fill='%230080FF' fill-rule='nonzero'%3E %3Cg id='WriteInput' transform='translate(270.000000, 860.000000)'%3E %3Cg id='send-button' transform='translate(1105.000000, 11.000000)'%3E %3Cpolygon id='Shape' points='0 17.8901866 19.9813433 8.94996269 0 0.00973880597 1.37931034 6.96324627 14.2723881 8.94996269 1.37931034 10.9366791'%3E%3C/polygon%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  }
}
</style>

<style lang="scss">
@import './../../../styles/utilities';

.chat-input {
  .el-textarea__inner,
  .el-input__inner {
    color: $primary-text;
    font-size: 16px;
    line-height: 24px;
    border: 0;
    border-radius: 0;

    @include respond-to($phone) {
      line-height: 16px;
    }

    @include placeholder(#909399, 16px, 400);
  }
  .el-textarea__inner {
    /*min-height: 60px !important;*/
    /*padding: 20px 10px 20px 30px;*/
    padding-left: 50px;
    padding-right: 20px;
    padding-top: 7px;

    @extend %scrollbar;
  }
  .el-input__inner {
    min-height: 40px !important;
    padding: 10px 0 10px 10px;
    padding-left: 50px;
    border: 0;
    line-height: 24px;
  }
  .el-input__inner::-webkit-input-placeholder {
    line-height: 1.5em;
  }
}
</style>
