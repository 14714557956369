<template lang="pug">
  .powerbi-wrapper
    div.report-container#reportContainer
</template>

<script>
import checkMobileDevice from '@/mixins/checkMobileDevice'
import { mapGetters } from 'vuex'
import { analytics } from '@/api'
import { models } from 'powerbi-client'
import store from '../../../store/index'

export default {
  mixins: [checkMobileDevice],
  props: {},
  data() {
    return {
      emptyLabel: '—',
      emptyValue: '',
      showErrors: false,
    }
  },
  computed: {
    ...mapGetters({
      currentCustomerId: 'customers/currentCustomerId',
      customer: 'customers/customer',
      hasCarbonFootprintEntitlement: 'customers/hasCarbonFootprintEntitlement',
      hasCarbonFootprintReport: 'customers/hasCarbonFootprintReport',
      carbonFootprintReport: 'customers/carbonFootprintReport',
    }),
  },
  methods: {
    async onCustomerLoaded() {
      if (
        !this.hasCarbonFootprintEntitlement ||
        !this.hasCarbonFootprintReport
      ) {
        return this.$router.push({ name: 'dashboard.home' })
      }
      let tokenData = await analytics.getEmbedToken({
        customerId: this.currentCustomerId,
        params: {
          groupId: this.carbonFootprintReport.groupId,
          datasetId: this.carbonFootprintReport.datasetId,
          reportId: this.carbonFootprintReport.reportId,
          rls: !this.carbonFootprintReport.customReportEnabled,
        },
      })
      const embedConfiguration = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: tokenData.embed_token,
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${tokenData.report_id}&groupId=${tokenData.group_id}`,
        id: tokenData.report_id,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: true,
            },
            pageNavigation: {
              visible: true,
            },
          },
        },
      }

      const powerBiClient = window.powerbi
      const embedContainer = document.getElementById('reportContainer')

      powerBiClient.embed(embedContainer, embedConfiguration)

      const report = powerBiClient.get(embedContainer)

      report.on('error', (errorObject) => {
        const err = errorObject.detail
        console.error(
          `PowerBI Error: ${err.message}. Detailed message: ${err.detailedMessage}`,
        )
      })
    },
  },
  watch: {
    customer: {
      handler() {
        if (this.customer) {
          this.onCustomerLoaded()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    if (
      !store.getters['customers/hasCarbonFootprintEntitlement'] ||
      !store.getters['customers/hasCarbonFootprintReport']
    ) {
      next({ name: 'dashboard.home' })
    } else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
.powerbi-wrapper {
  margin: 24px;
  min-height: calc(100vh - 48px);
  max-height: calc(100vh - 48px);
}

.report-container {
  height: calc(100vh - 48px);
}

@media (max-width: 600px) {
  //header added on mobile devices need to extract more height
  .powerbi-wrapper {
    min-height: calc(100vh - 48px - 74px);
    max-height: calc(100vh - 48px - 74px);
  }
  .report-container {
    height: calc(100vh - 48px - 74px);
  }
}
</style>
