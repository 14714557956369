<template>
  <div class="inner-tabs">
    <el-tabs v-model="activeName" style="height: 290px">
      <el-tab-pane label="Weather Data" name="weatherData" class="inner-tabs">
        <div class="date-navigation">
          <processing-date
            class="processing-date"
            title="Go to Month"
            :datesMinMax="datesMinMax"
            placeholder="All months"
            @changeDate="changeDate"
          />
          <div class="date-container">
            <el-button
              type="text"
              class="left-button"
              icon="el-icon-arrow-left"
              @click="onBack"
            />
            <div class="date">
              <h3 class="month">{{ month }}</h3>
              <p class="year">{{ year }}</p>
            </div>
            <el-button
              type="text"
              icon="el-icon-arrow-right"
              @click="onForward"
            />
          </div>
          <div class="date-averages">
            <p class="date-averages-hdd">
              Monthly HDD:
              <span class="date-averages-hdd-value">
                {{ monthlyHDD | total }}
              </span>
            </p>
            <p>
              Monthly CDD:
              <span class="date-averages-cdd-value">
                {{ monthlyCDD | total }}
              </span>
            </p>
          </div>
        </div>
        <div class="cards-container">
          <DayCard
            v-for="i in list"
            :key="i.id"
            class="cards"
            :data="i"
            :currentDate="currentMonth"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import * as R from 'ramda'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import FilterSelect from '@/components/Filters/FilterSelect.vue'
import WeatherBlock from './Block.vue'
import { DateTime } from 'luxon'
import ProcessingDate from '@/components/ProcessingDate.vue'
import DayCard from './DayCard'

export default {
  components: {
    FilterSelect,
    WeatherBlock,
    ProcessingDate,
    DayCard,
  },
  props: ['datesMinMax'],
  data() {
    return {
      loading: true,
      period: 'Month',
      date: null,
      availableDates: [],
      selector: '.weather-list-container',
      activeName: 'weatherData',
      currentMonth: DateTime.fromJSDate(new Date()).toFormat('yyyy-LL-dd'),
      startDate: '',
      endDate: '',
    }
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      list: 'weather/list',
      isFetching: 'weather/isFetching',
      dates: 'weather/dates',
      params: 'weather/params',
    }),
    month() {
      return (
        this.list.length &&
        DateTime.fromFormat(this.currentMonth, 'yyyy-LL-dd', { zone: 'utc' })
          .startOf('month')
          .plus({ days: 14 })
          .toFormat('LLLL')
      )
    },
    year() {
      return (
        this.list.length &&
        DateTime.fromFormat(this.currentMonth, 'yyyy-LL-dd', { zone: 'utc' })
          .startOf('month')
          .plus({ days: 14 })
          .toFormat('yyyy')
      )
    },
    monthlyHDD() {
      const dateListOnlyCurrMonth =
        this.list &&
        this.list.filter(
          (item) =>
            DateTime.fromFormat(this.currentMonth, 'yyyy-LL-dd').toFormat(
              'LL',
              { zone: 'utc' },
            ) ===
            DateTime.fromFormat(item.date, 'yyyy-LL-dd').toFormat('LL', {
              zone: 'utc',
            }),
        )
      return (
        dateListOnlyCurrMonth.length &&
        dateListOnlyCurrMonth.reduce(
          (acc, v) => acc + Number(v.heating || 0),
          0,
        )
      )
    },
    monthlyCDD() {
      const dateListOnlyCurrMonth =
        this.list &&
        this.list.filter(
          (item) =>
            DateTime.fromFormat(this.currentMonth, 'yyyy-LL-dd').toFormat(
              'LL',
              { zone: 'utc' },
            ) ===
            DateTime.fromFormat(item.date, 'yyyy-LL-dd').toFormat('LL', {
              zone: 'utc',
            }),
        )
      return (
        dateListOnlyCurrMonth.length &&
        dateListOnlyCurrMonth.reduce(
          (acc, v) => acc + Number(v.cooling || 0),
          0,
        )
      )
    },
  },
  filters: {
    total: function (value) {
      if (value !== 0) {
        return Number(value).toFixed(2)
      }
      return 'N/A'
    },
  },
  async mounted() {
    this.loading = false
    if (typeof this.$route.query.startDate !== 'undefined') {
      this.currentMonth = DateTime.fromFormat(
        this.$route.query.startDate,
        'yyyy-LL-dd',
        { zone: 'utc' },
      )
        .plus({ days: 14 })
        .toFormat('yyyy-LL-dd')
    }
  },

  beforeDestroy() {
    this.$resetState()
  },
  methods: {
    ...mapActions({
      $fetchFeeds: 'weather/fetchFeeds',
      $appendFeeds: 'weather/appendFeeds',
    }),
    ...mapMutations({
      setFetching: 'weather/setFetching',
      $resetState: 'weather/resetState',
    }),
    async onBack() {
      let weatherParams = null
      const prevMonth = DateTime.fromFormat(this.currentMonth, 'yyyy-LL-dd', {
        zone: 'utc',
      }).minus({ month: 1 })
      weatherParams = {
        startDate: this.pickStartDate(prevMonth),
        endDate: this.pickEndDate(prevMonth),
      }
      this.currentMonth = prevMonth.toFormat('yyyy-LL-dd')
      this.$emit('prev-month', weatherParams)
    },
    async onForward() {
      let weatherParams = null
      const nextMonth = DateTime.fromFormat(this.currentMonth, 'yyyy-LL-dd', {
        zone: 'utc',
      }).plus({ month: 1 })
      weatherParams = {
        startDate: this.pickStartDate(nextMonth),
        endDate: this.pickEndDate(nextMonth),
      }
      this.currentMonth = nextMonth.toFormat('yyyy-LL-dd')
      this.$emit('next-month', weatherParams)
    },
    async changeDate(time) {
      let weatherParams = null
      const month =
        +time !== 0 ? DateTime.fromMillis(+time) : DateTime.local().toUTC()
      weatherParams = {
        startDate: this.pickStartDate(month),
        endDate: this.pickEndDate(month),
      }
      this.currentMonth = month.toFormat('yyyy-LL-dd')
      this.$emit('picker-date-change', weatherParams)
    },
    pickStartDate(month) {
      if (month.startOf('month').weekday === 7) {
        return month.startOf('month').toFormat('yyyy-LL-dd')
      }
      return month.startOf('month').set({ weekday: 0 }).toFormat('yyyy-LL-dd')
    },
    pickEndDate(month) {
      if (month.endOf('month').weekday === 6) {
        return month.endOf('month').toFormat('yyyy-LL-dd')
      }
      if (+month.endOf('month').weekday === 7) {
        return month
          .endOf('month')
          .plus({ day: 5 })
          .set({ weekday: 6 })
          .toFormat('yyyy-LL-dd')
      }
      return month.endOf('month').set({ weekday: 6 }).toFormat('yyyy-LL-dd')
    },
    blockId(item) {
      const date = R.pathOr('', ['data', '0', 'date'], item)
      const month = DateTime.fromFormat(date, 'yyyy-LL-dd')
        .toFormat('MMMM yyyy')
        .toString()
      return `weather ${month}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../../styles/_utilities';
.inner-tabs {
  .processing-date {
    flex-grow: 1;
    @include respond-to($phone) {
      flex-grow: 0;
    }
  }
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @include respond-to($phone) {
    }

    .cards {
      margin-top: 16px;
      flex-wrap: wrap;
      &:nth-child(7n) {
        margin-right: 0;
      }
      @include respond-to($desktop-lg) {
        padding-right: 0;
        font-size: 12px;
      }
      @include respond-to($desktop-md) {
        padding-right: 0;
        font-size: 12px;
      }
    }
  }
  ::v-deep .el-tabs {
    .is-top::after {
      height: 0;
    }
    &__item {
      font-size: 18px;
      cursor: initial;
    }
    &__header {
      margin-bottom: 16px;
    }
    &__content {
      @include respond-to($phone) {
        margin: 0 20px 20px;
      }
    }
  }
  ::v-deep .el-button {
    border: none;
    color: #000000;
    &:hover {
      color: none;
      border-color: none;
      background-color: none;
    }
  }
  .date-navigation {
    display: flex;
    flex-wrap: nowrap;
    @include respond-to($phone-l) {
      align-self: center;
    }
    .left-button {
      margin-left: 13em;
      @include respond-to($desktop-md) {
        margin-left: 1em;
      }
    }
  }
  ::v-deep .el-icon-arrow {
    &-left::before {
      font-weight: bolder;
    }
    &-right::before {
      font-weight: bolder;
    }
  }
  .date-container {
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    @include respond-to($phone) {
      flex-grow: 0;
    }
    .date {
      display: flex;
      flex-wrap: wrap;
      max-width: 100px;
      justify-content: center;
      .month {
        min-width: 90px;
        text-align: center;
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 0;
        font-size: 1.125rem;
        @include respond-to($phone) {
          flex-wrap: wrap;
          font-size: 14px;
        }
      }
      .year {
        margin: 0;
        text-align: center;
        color: #616266;
        font-weight: bold;
        font-size: 12px;
        @include respond-to($phone) {
          flex-wrap: wrap;
          font-size: 10px;
        }
      }
    }
  }
  .date-averages {
    display: flex;
    justify-content: flex-end;
    @include respond-to($phone) {
      flex-wrap: wrap;
      font-size: 12px;
    }
    @include respond-to($phone-l) {
      flex-wrap: wrap;
      font-size: 14px;
      max-width: 30%;
    }
    &-hdd {
      padding-right: 16px;
      @include respond-to($phone) {
        padding-right: 0;
        margin-bottom: 0;
        margin-top: 0;
      }
      @include respond-to($phone-l) {
        padding-right: 0;
        margin-bottom: 0;
        margin-top: 0;
      }
      &-value {
        font-weight: bold;
        @include respond-to($phone) {
          flex-wrap: wrap;
          font-size: 12px;
        }
      }
    }
    &-cdd {
      padding-right: 16px;
      @include respond-to($phone) {
        padding-right: 0;
      }
      &-value {
        font-weight: bold;
        @include respond-to($phone) {
          flex-wrap: wrap;
          font-size: 12px;
        }
      }
    }
  }
  .filter-item-date {
    ::v-deep .el-input__inner {
      padding-left: 30px;
    }
  }
  .filter {
    margin: 0 0 10px 0;
    padding: 0;

    &-container {
      justify-content: flex-end;
    }

    ::v-deep {
      .el-button--primary {
        background: #0080ff;
        border-color: #0080ff;
      }

      .filter-item {
        &:first-child {
          margin-right: 0;
        }
      }

      .el-input {
        width: 160px !important;
      }
    }
  }

  .feeds-list {
    min-height: calc(100vh - 280px);
  }

  .feeds-list_item-header {
    padding: 0 40px;
  }

  .weather-list-container {
    max-height: calc(100vh - 277px);
    min-height: calc(100vh - 277px);
    overflow-y: scroll;
    @extend %scrollbar;

    &.no-scroll {
      overflow-y: hidden;
    }
  }
}
</style>
