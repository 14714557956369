<template>
  <div class="customize-filters-tab">
    <el-form label-position="top" v-if="!loadingData">
      <template v-for="(filter, i) in availableFilters">
        <div class="form-item" v-bind:key="i">
          <div
            :class="'form-item-input ' + item.element + ' ' + item.key"
            v-for="item in filter"
            v-bind:key="item.key"
          >
            <div
              v-if="
                item.element === 'select' || item.element === 'single-select'
              "
            >
              <base-select
                :isEditMode="true"
                size="medium"
                :ordered="true"
                :label="item.label + ':'"
                :name="item.label"
                :title="title(item.availableValues, filters[item.key])"
                immediate
                :multiple="item.element === 'select'"
                v-model="filters[item.key]"
              >
                <el-option
                  class="vendor-options"
                  v-for="v in item.availableValues"
                  :key="v.key"
                  :title="item.key === 'location_attributes' ? v : v.key"
                  :value="item.key === 'location_attributes' ? v : v.key"
                  :label="v.value"
                />
              </base-select>
              <div
                v-if="item.key === 'location_attributes'"
                class="location-attributes"
              >
                <div
                  v-for="attribute in filters[item.key]"
                  :key="attribute.key"
                  class="attribute"
                >
                  <base-select
                    :ref="'select_String_' + attribute.key"
                    :popper-append-to-body="false"
                    v-if="'String' === attribute.fieldType"
                    :isEditMode="true"
                    size="medium"
                    allow-create
                    :ordered="true"
                    clearable
                    immediate
                    :multiple="attribute.isMultiselect"
                    @visible-change="
                      onAttributeSuggestionVisibleChange($event, attribute)
                    "
                    :remote-method="onAttributeSuggestionSearch(attribute)"
                    :placeholder="'Select'"
                    v-model="attribute.dataValue"
                    :label="attribute.value + ':'"
                    :name="item.name"
                    :loading="loadingAttributeValueData"
                  >
                    <div
                      v-if="!loadingAttributeValueData"
                      class="infinite-list"
                      v-infinite-scroll="onScrollAttributeSuggestion(attribute)"
                    >
                      <el-option
                        v-for="v in attribute.fieldSuggestions"
                        :key="v"
                        :value="v"
                        :label="v"
                      />
                    </div>
                    <el-option
                      style="text-align: center"
                      v-if="!attribute.fieldSuggestions.length"
                      :disabled="true"
                      :value="'No data'"
                      :label="'No data'"
                    />
                  </base-select>
                  <base-select
                    :ref="'select_Numeric_' + attribute.key"
                    class="addNumericTypeToSelectInput"
                    :popper-append-to-body="false"
                    v-if="'Numeric' === attribute.fieldType"
                    :isEditMode="true"
                    size="medium"
                    allow-create
                    :ordered="true"
                    clearable
                    immediate
                    :multiple="attribute.isMultiselect"
                    @visible-change="
                      onAttributeSuggestionVisibleChange($event, attribute)
                    "
                    :remote-method="onAttributeSuggestionSearch(attribute)"
                    :placeholder="'Select'"
                    v-model.number="attribute.dataValue"
                    :label="attribute.value + ':'"
                    :name="item.name"
                    :loading="loadingAttributeValueData"
                  >
                    <div
                      v-if="!loadingAttributeValueData"
                      class="infinite-list"
                      v-infinite-scroll="onScrollAttributeSuggestion(attribute)"
                    >
                      <el-option
                        v-for="v in attribute.fieldSuggestions"
                        :key="v"
                        :value="v"
                        :label="v"
                      />
                    </div>
                    <el-option
                      style="text-align: center"
                      v-if="!attribute.fieldSuggestions.length"
                      :disabled="true"
                      :value="'No data'"
                      :label="'No data'"
                    />
                  </base-select>
                  <base-select
                    v-if="attribute.fieldType === 'List'"
                    :isEditMode="true"
                    size="medium"
                    :ordered="true"
                    clearable
                    :label="attribute.value + ':'"
                    :name="item.label"
                    immediate
                    :multiple="attribute.isMultiselect"
                    v-model="attribute.dataValue"
                  >
                    <el-option
                      v-for="v in attribute.fieldValues"
                      :key="v.key"
                      :value="v.value"
                      :label="v.value"
                    />
                  </base-select>
                  <BaseDatePicker
                    v-if="attribute.fieldType === 'Date'"
                    v-model="attribute.dataRangeValue"
                    @change="onAttributeSuggestionDateChange($event, attribute)"
                    size="medium"
                    type="daterange"
                    range-separator="-"
                    :format="'MM/dd/yyyy'"
                    :label="attribute.value + ':'"
                    :start-placeholder="'Start day'"
                    :end-placeholder="'Last day'"
                    :isEditMode="true"
                  />
                </div>
              </div>
            </div>
            <div v-if="item.element === 'date'">
              <date-range-filter
                v-if="!hasMultipleDateFilters"
                :label="item.label"
                :filters="filters"
                :reportType="reportType"
                :filterType="item.type"
              />
              <date-range-filter
                v-else
                :label="item.label"
                :filters="filters"
                :filtersKey="item.key"
                :filterType="item.type"
                :reportType="reportType"
                @updateFilters="$emit('updateFilters', $event)"
              />
            </div>
            <div v-if="item.element === 'text'">
              <BaseInput
                :isEditMode="true"
                v-model="filters[item.key]"
                :placeholder="item.placeholder ? item.placeholder : 'Search'"
                :name="item.name"
                :label="item.label"
                size="medium"
              />
            </div>
            <location-hierarchy
              class="location-hierarchy"
              v-if="item.element === 'hierarchy'"
              :hierarchies="hierarchy"
              ref="locationHierarchy"
              :show-only-selected-locations="showOnlySelectedLocations"
              :total-locations="totalLocations"
              :filter-text="filterText"
              :default-selected-locations="filters.location_id"
              @filter-val-change="handleFilterValue"
              @show-selected-locs="handleShowOnlySelectedLocations"
              @get-checked-keys="handleSelectedLocations"
            />
          </div>
        </div>
      </template>
    </el-form>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import BaseDatePicker from '@/components/FormComponents/BaseDatePicker'
import BaseInput from '@/components/FormComponents/BaseInput'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import DateRangeFilter from '@/components/FormComponents/DateRangeFilter'
import LocationHierarchy from '@/components/LocationHierarchyComponents/LocationHierarchy'
import { mapGetters } from 'vuex'
import { buildHierarchy } from '../../../../../lib/helpers'
import { getVirtualAccountAttributeValues } from '@/api/locations'

export default {
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Object,
      default: () => {},
    },
    open: {
      type: Boolean,
      default: () => false,
    },
    hierarchies: {
      type: Array,
      default: () => [],
    },
    reportType: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      locations: 'locations/locations',
      totalLocations: 'locations/total',
      customerId: 'customers/currentCustomerId',
    }),
    availableFilters() {
      return this.report.availableFilters
    },
    hasMultipleDateFilters() {
      return (
        this.report.availableFilters.reduce((reduced, filter) => {
          if (filter[0].element === 'date') {
            reduced++
          }
          return reduced
        }, 0) > 1
      )
    },
    title() {
      return (availableValues, itemKeys) => {
        if (itemKeys) {
          return availableValues
            .filter((av) => (itemKeys.includes(av.key) ? av : undefined))
            .map((av) => av.value)
            .join(', ')
        }
      }
    },
  },
  components: {
    BaseInput,
    BaseSelect,
    DateRangeFilter,
    ValidationObserver,
    LocationHierarchy,
    BaseDatePicker,
  },
  watch: {
    open: {
      handler(value) {
        this.loadingData = true
        if (value) {
          this.createHierarchy()
          const locationAttributesIndex = this.report.availableFilters.reduce(
            (reduced, currentFilter, index) => {
              if (currentFilter[0].type === 'custom_attribute') {
                reduced = index
              }
              return reduced
            },
            -1,
          )
          if (
            locationAttributesIndex !== -1 &&
            this.report.availableFilters[locationAttributesIndex][0]
              .availableValues !== null
          ) {
            this.report.availableFilters[
              locationAttributesIndex
            ][0].availableValues = this.report.availableFilters[
              locationAttributesIndex
            ][0].availableValues.map((attribute) => {
              return Object.assign(attribute, {
                fieldSuggestions: [],
              })
            })
          }
          if (
            this.filters.location_attributes &&
            !this.filters.location_attributes.length
          ) {
            const locationAttributeKeys = Object.keys(this.filters).filter(
              (key) => {
                return key.match(/^location_attribute_/)
              },
            )
            if (locationAttributeKeys.length) {
              const locationAttributesFilter =
                this.report.availableFilters[locationAttributesIndex][0]
              const locationAttributes =
                locationAttributesFilter.availableValues

              locationAttributeKeys.forEach((attributeKey) => {
                const locationAttribute = locationAttributes.find(
                  (locationAttribute) => {
                    return locationAttribute.key === attributeKey
                  },
                )
                const adaptedLocationAttribute = Object.assign(
                  locationAttribute,
                  {
                    valueData: this.filters[attributeKey],
                  },
                )

                this.filters.location_attributes.push(adaptedLocationAttribute)
                delete this.filters[attributeKey]
              })
            }
          }
          this.modifiedData = false
          this.loadingData = false
        }
      },
      immediate: true,
    },
    report: {
      handler() {
        this.report.availableFilters.forEach((filter) => {
          filter.forEach((item) => {
            if (item.defaultValue) {
              this.filters[item.key] = item.defaultValue
            }
          })
        })
      },
      immediate: true,
    },
  },
  methods: {
    async loadAttributeSuggestions(attribute) {
      const data = await getVirtualAccountAttributeValues(
        this.customerId,
        attribute.key.replace('location_attribute_', ''),
        {
          offset: attribute.attributeValueFilters.offset,
          limit: attribute.attributeValueFilters.limit,
          searchQuery: attribute.attributeValueFilters.searchQuery,
        },
      )
      this.loadingAttributeValueData = true
      attribute.fieldSuggestions.push(...data.results)
      attribute.attributeValueFilters.total = data.total
      this.loadingAttributeValueData = false
    },
    onScrollAttributeSuggestion(attribute) {
      return async () => {
        if (
          this.attributeValueData ||
          !attribute.fieldSuggestions.length ||
          !attribute.attributeValueFilters ||
          attribute.fieldSuggestions.length ===
            attribute.attributeValueFilters.total
        ) {
          return
        }
        attribute.attributeValueFilters = {
          offset:
            attribute.attributeValueFilters.offset +
            attribute.attributeValueFilters.limit,
          limit: attribute.attributeValueFilters.limit,
          searchQuery: attribute.attributeValueFilters.searchQuery,
        }
        await this.loadAttributeSuggestions(attribute)
      }
    },
    onAttributeSuggestionSearch(attribute) {
      return async (query) => {
        if (query) {
          attribute.fieldSuggestions.splice(0)
          this.loadingAttributeValueData = true
          attribute.attributeValueFilters = {
            offset: 0,
            limit: 10,
            searchQuery: query,
          }
          await this.loadAttributeSuggestions(attribute)
        } else {
          attribute.fieldSuggestions.splice(0)
          attribute.attributeValueFilters = {
            offset: 0,
            limit: 10,
          }
          await this.loadAttributeSuggestions(attribute)
        }
      }
    },
    async onAttributeSuggestionVisibleChange(visible, attribute) {
      if (visible) {
        attribute.fieldSuggestions.splice(0)
        this.loadingAttributeValueData = true
        attribute.attributeValueFilters = {
          offset: 0,
          limit: 10,
        }
        await this.loadAttributeSuggestions(attribute)
        if (
          this.$refs['select_Numeric_' + attribute.key] &&
          this.$refs['select_Numeric_' + attribute.key][0]
        ) {
          this.$refs[
            'select_Numeric_' + attribute.key
          ][0].$refs.elSelect.$refs.reference.$refs.input.type = 'number'
          this.$refs[
            'select_Numeric_' + attribute.key
          ][0].$refs.elSelect.$refs.reference.$refs.input.classList +=
            ' removeCarrets'
        }
      } else {
        attribute.fieldSuggestions.splice(0)
        delete attribute.attributeValueFilters
      }
    },
    async onAttributeSuggestionDateChange(value, attribute) {
      this.$nextTick(() => {
        if (typeof value === 'string' && value.length) {
          if (attribute.dataRangeValue) {
            attribute.dataRangeValue.splice(0)
          }
        } else {
          attribute.dataValue = ''
        }
      })
    },
    createHierarchy() {
      const hierarchiesArr = this.availableFilters.find((filters) =>
        filters.find((filter) => filter && filter.element === 'hierarchy'),
      )
      if (!hierarchiesArr) {
        return
      }
      const [hierarchiesProps] = hierarchiesArr
      this.hierarchy = buildHierarchy(
        hierarchiesProps.availableValues.hierarchies,
        hierarchiesProps.availableValues.unmatchedLocations,
        this.locations,
      )
    },
    handleFilterValue(val) {
      this.filterText = val
    },
    handleShowOnlySelectedLocations(val) {
      this.showOnlySelectedLocations = val
    },
    handleSelectedLocations(event) {
      this.filters.location_id = event.locationsIds
    },
  },
  data() {
    return {
      loadingData: true,
      loadingAttributeValueData: true,
      attributeValueData: null,
      modifiedData: false,
      showOnlySelectedLocations: false,
      filterText: '',
      showTab: false,
      hierarchy: [],
      isEditMode: false,
    }
  },
}
</script>
<style lang="scss">
.removeCarrets {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.el-select__tags-text {
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}
</style>
<style lang="scss" scoped>
::v-deep.customize-filters-tab {
  .location-attributes {
    .el-select-dropdown.el-popper {
      .el-scrollbar__bar {
        display: none;
      }

      .el-select-dropdown__wrap {
        margin: 0px !important;
        overflow: hidden;

        .el-scrollbar__view.el-select-dropdown__list {
          max-height: inherit;
          padding: 0px;

          /* width */
          ::-webkit-scrollbar {
            width: 6px;
            margin: 2px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            opacity: 0.1;
            margin-top: 2px;
            margin-bottom: 2px;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: rgba(144, 147, 153, 0);
            border-radius: 4px;
          }

          &:hover {
            /* Handle */
            ::-webkit-scrollbar-thumb {
              background: rgba(144, 147, 153, 0.3);
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
              background: rgba(144, 147, 153, 0.5);
            }
          }

          .infinite-list {
            max-height: inherit;
            overflow: overlay;
          }
        }
      }
    }
  }
  .el-form {
    .form-item-input {
      flex-grow: initial;
      padding-right: 16px;
    }

    .el-date-editor {
      width: 235px;
    }

    .form-item {
      display: flex;
      flex-wrap: wrap;

      .form-item-input {
        &.hierarchy {
          width: 100%;

          .location-hierarchy {
            display: flex;
            flex-direction: column;
            width: 100%;

            .location-search {
              width: 100%;
            }
          }
        }

        &.location_attributes {
          width: 100%;

          .location-attributes {
            display: flex;
            flex-direction: column;

            .attribute {
              .el-form-item {
                align-items: center;
                display: flex;
                margin-bottom: 8px;

                .el-form-item__label {
                  width: 120px;
                }

                .el-form-item__content {
                  left: 20px;
                }
              }
            }
          }
        }
      }

      .pl-12 {
        padding: 0;
      }
      .el-input {
        width: 160px;
      }
    }
  }
}
.vendor-options {
  max-width: 200px;
}
</style>
