<template lang="pug">
.locations-container(v-loading.fullscreen.body.lock="loading", data-element="locations-list-mobile")
  .page-header
    el-input.page-mobile-search(placeholder="Search",
            ref="searchInput",
            size="medium",
            :value="filters.query")
      i.el-input__icon.el-icon-search(slot="prefix")
    .total-locations
      .total-locations-title Qty of locations:
      .total-locations-count {{ total || 'N/A' }}
  .location-header.el-table(@click="sortList")
    div(:class='locationHeaderClass') Service Address
      span.caret-wrapper
        i.sort-caret.ascending
        i.sort-caret.descending
  .location-item(v-for="location in locations", :key="location.id", @click="handleClick(location.id)")
    span {{ location.name }}

</template>

<script>
import debounce from 'lodash.debounce'
import { isNil } from 'ramda'
import { mapGetters, mapActions } from 'vuex'
import { serializeOrderBy } from '@/lib/helpers'

export default {
  data() {
    return {
      order: 'ascending',
    }
  },
  computed: {
    ...mapGetters({
      total: 'locations/total',
      loading: 'locations/loading_list',
      locations: 'locations/locations',
      filters: 'locations/filters/filters',
    }),
    locationHeaderClass() {
      if (this.order === 'ascending') {
        return 'ascending'
      }
      if (this.order === 'descending') {
        return 'descending'
      }
    },
  },
  mounted() {
    this.$refs.searchInput.$refs.input.addEventListener(
      'keyup',
      debounce((event) => {
        this.$changeFilters({ searchQuery: event.target.value })
      }, 700),
    )
    this.$setOrder({ orders: [serializeOrderBy('name', this.order)] })
  },
  methods: {
    ...mapActions({
      $changeFilters: 'locations/filters/changeFilters',
      $setOrder: 'locations/filters/setFilters',
    }),
    handleClick(locationId) {
      this.$router.push({
        name: 'dashboard.locations.info',
        params: { locationId },
      })
    },
    sortList() {
      switch (this.order) {
        case null:
          this.order = 'descending'
          break
        case 'descending':
          this.order = 'ascending'
          break
        case 'ascending':
          this.order = null
          break
      }

      if (isNil(this.order)) {
        this.$setOrder({ orders: [] })
      } else {
        this.$setOrder({ orders: [serializeOrderBy('name', this.order)] })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/utilities';

.page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.total-locations {
  display: flex;
  align-items: center;
  font-size: 12px;

  &-title {
    margin-right: 10px;
    color: #7d89a6;
  }
  &-count {
    font-size: 16px;
    font-weight: 600;
  }
}
.location {
  &-header {
    padding: 10px 15px;
    color: #909399;
    font-size: 16px;
  }
  &-item {
    padding: 10px 15px;
    color: #606266;
    font-weight: 500;
    border-radius: 2px;
    border-bottom: 1px solid #ebeef5;
    background-color: #fff;
  }
}
</style>
