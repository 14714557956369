var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.isLoading),expression:"isLoading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"content-inner"},[_c('div',{staticClass:"location-header"},[(!_vm.$deviceInfo.isMobileDevice)?_c('span',{staticClass:"location-header_back",on:{"click":_vm.navigateToHierarchyPage}},[_c('i',{staticClass:"icon icon-back"})]):_vm._e(),(_vm.location)?_c('span',{staticClass:"location-header_title"},[_vm._v(" Location Detail for "+_vm._s(_vm.location.name)+" ")]):_vm._e()]),(_vm.location)?_c('div',{staticClass:"hierarchy-breadcrumbs"},_vm._l((_vm.hierarchyBreadcrumbs),function(ref){
var name = ref.name;
var id = ref.id;
return _c('div',{key:id,staticClass:"hierarchy-breadcrumbs-item"},[_c('router-link',{attrs:{"to":{
          name: 'dashboard.locations.hierarchy',
          params: { customerId: _vm.currentCustomerId },
          query: { id: id },
        }}},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(name))])]),_c('span',{staticClass:"nav"},[_vm._v("-")])],1)}),0):_vm._e(),(_vm.location)?_c('div',{staticClass:"location-content"},[_c('el-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-tab-pane',{attrs:{"label":"Metrics","name":"metrics","data-action":"Show metrics"}},[_c('metrics-tab',{attrs:{"virtual-accounts":_vm.virtualAccounts}})],1),_c('el-tab-pane',{attrs:{"label":"Virtual Accounts","name":"virtual_accounts","data-action":"Show virtual accounts"}},[_c('virtual-accounts-tab',{attrs:{"virtual-accounts":_vm.virtualAccountsGrouped,"pagination":_vm.pagination,"loading":_vm.isLoading},on:{"update-limit":_vm.changeVirtualAccounts,"update-offset":_vm.changeVirtualAccounts,"update-order":function($event){return _vm.getVirtualAccounts(_vm.$route.query)}}})],1),_c('el-tab-pane',{attrs:{"label":"Attributes","name":"attributes","data-action":"Show attributes"}},[_c('attributes-tab',{attrs:{"location":_vm.location}})],1),_c('el-tab-pane',{attrs:{"label":"Production Data","name":"production_data","data-action":"Show attributes"}},[_c('production-data-tab',{attrs:{"location":_vm.location}})],1),_c('el-tab-pane',{attrs:{"label":"Monthly View","name":"bill_history","data-action":"Show bill history"}},[_c('monthly-feed-tab',{attrs:{"datesMinMax":_vm.datesMinMax}})],1),(_vm.isWeatherTabActive)?_c('el-tab-pane',{attrs:{"label":"Weather","name":"weather","data-action":"Show weather"}},[_c('weather-tab',{attrs:{"datesMinMax":_vm.datesMinMax},on:{"prev-month":_vm.onDataChanged,"next-month":_vm.onDataChanged,"picker-date-change":_vm.onDataChanged}})],1):_vm._e(),(_vm.isEnergyStarTabActive)?_c('el-tab-pane',{attrs:{"label":"ENERGY STAR®","name":"energy_star","data-action":"Show ENERGY STAR® information"}},[_c('energy-star-tab',{attrs:{"location":_vm.location},on:{"refresh-location":_vm.getLocation}})],1):_vm._e()],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }