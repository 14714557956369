<template lang="pug">
  .bill-history-wrapper
    .desktop-table-wrapper(v-if='!$deviceInfo.isMobileDevice')
      el-table(:data="billHistory", v-loading="loading")
        el-table-column(label='Bill ID', prop="bill_meta_id", align="center")
          template(slot-scope='scope')
            a.bill-link(:href='`/${customerId}/bills/${scope.row.bill_meta_id}`') {{ scope.row.bill_meta_id }}
        el-table-column(label='Invoice Date')
          template(slot-scope='scope')
            span {{ scope.row.statement_date | dateFormatted }}
        el-table-column(label='Charges')
          template(slot-scope='scope')
            span(v-if="scope.row.total_charges") {{ (scope.row.total_charges.value || 0) | unit(scope.row.total_charges.units) }}
            span(v-else) N/A
        el-table-column(label='Consumption')
          template(slot-scope='scope')
            span(v-if="scope.row.total_consumption") {{ (scope.row.total_consumption.value || 0) | unit(scope.row.total_consumption.units) }}
            span(v-else) N/A
        el-table-column(label='Max Demand')
          template(slot-scope='scope')
            span(v-if="scope.row.max_demand") {{ (scope.row.max_demand.value || 0) | unit(scope.row.max_demand.units) }}
            span(v-else) N/A
        el-table-column(label='Gen Consumption')
          template(slot-scope='scope')
            span(v-if="scope.row.utility_gen_consumption") {{ (scope.row.utility_gen_consumption.value || 0) | unit(scope.row.utility_gen_consumption.units) }}
            span(v-else-if="scope.row.gen_consumption") {{ (scope.row.gen_consumption.value || 0) | unit(scope.row.gen_consumption.units) }}
            span(v-else) N/A
    template(v-else, v-loading="loading")
      .bill-history-item(v-for='(item, index) in billHistory', :key='index')
        .bill-history-item_content
          .bill-history-item_col
            .bill-history-item_label Bill ID
            a.bill-link(:href='`/${customerId}/bills/${item.bill_meta_id}`') {{ item.bill_meta_id }}
          .bill-history-item_col
            .bill-history-item_label Invoice Date
            .bill-history-item_value {{ item.statement_date | dateFormatted }}
          .bill-history-item_col
            .bill-history-item_label Charges
            .bill-history-item_value(v-if="item.total_charges") {{ (item.total_charges.value || 0) | unit(item.total_charges.units) }}
            .bill-history-item_value(v-else) N/A
          .bill-history-item_col
            .bill-history-item_label Consumption
            .bill-history-item_value(v-if="item.total_consumption") {{ (item.total_consumption.value || 0) | unit(item.total_consumption.units) }}
            .bill-history-item_value(v-else) N/A
          .bill-history-item_col
            .bill-history-item_label Max Demand
            .bill-history-item_value(v-if="item.max_demand") {{ (item.max_demand.value || 0) | unit(item.max_demand.units) }}
            .bill-history-item_value(v-else) N/A
          .bill-history-item_col
            .bill-history-item_label Gen Consumption
            .bill-history-item_value(v-if="item.utility_gen_consumption") {{ (item.utility_gen_consumption.value || 0) | unit(item.utility_gen_consumption.units) }}
            .bill-history-item_value(v-else-if="item.gen_consumption") {{ (item.gen_consumption.value || 0) | unit(item.gen_consumption.units) }}
            .bill-history-item_value(v-else) N/A
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'bills-history',
  filters: {
    dateFormatted(value) {
      if (!value) return ''
      return DateTime.fromISO(value, { zone: 'utc' }).toFormat('LL/dd/yyyy')
    },
  },
  props: {
    billHistory: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    customerId: {
      type: Number,
      required: true,
      default: () => '',
    },
  },
}
</script>

<style scoped lang="scss">
@import './../../../../../styles/_utilities';
.bill-history-wrapper {
  .bill-link {
    color: #0080ff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .desktop-table-wrapper {
    max-height: 500px;

    @extend %scrollbar;
  }

  .bill-history {
    &-item {
      margin-bottom: 10px;
      font-weight: 600;
      border: 1px solid #dcdfe6;
      border-radius: 2px;
      background-color: #fff;

      &.is-selected {
        border-color: #0080ff;
      }
      &_content,
      &_actions {
        display: flex;
        flex-wrap: wrap;
      }
      &_content {
        padding: 15px 20px 5px;
        box-sizing: border-box;

        .bill-history-item_col {
          margin-bottom: 10px;
        }
      }
      &_col {
        flex: none;
        width: 50%;
        box-sizing: border-box;
      }
      &_label {
        margin-bottom: 2px;
        color: #909399;
        font-size: 12px;
      }
      &_value {
        color: #606266;

        @include respond-to($phone) {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
