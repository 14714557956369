<template lang="pug">
.filter.filter-m
  .filter-container
    .filter-item
      .filter-title Utility Type
      el-select(v-model="type", size="mini" filterable)
        el-option(v-for='item in utility_types',
          :key="item",
          :label="item",
          :value="item")
    el-button.button-filter-apply(type='primary', size='small', @click='applyFilters') Apply Filters
    el-button.button-filter-reset(type='primary', size='small', @click='resetFilters') Reset Filters
</template>

<script>
export default {
  props: {
    utility_type: {
      type: String,
      required: true,
      default: () => 'All Utilities',
    },
  },
  beforeMount() {
    this.type = this.utility_type
  },
  data() {
    return {
      type: null,
    }
  },
  computed: {
    utility_types() {
      return [
        'All Utilities',
        ...this.$store.getters['resources/getResourceByName'](
          'commodities',
        ).value.map((type) => type.title),
      ]
    },
  },
  methods: {
    applyFilters() {
      this.$emit('change', this.type)
      this.$emit('close', true)
    },
    resetFilters() {
      this.$emit('change', 'All Utilities')
      this.$emit('close', true)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter.filter-m .button-filter-reset {
  color: #0080ff;
  border-color: #0080ff;
  background-color: #fff;
}
.filter {
  .filter-container {
    .select-box {
      ::v-deep .el-input {
        width: 100%;
      }
    }
  }
}
</style>
