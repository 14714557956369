<template>
  <BaseModal
    v-if="showModal"
    :dialogVisible="showModal"
    :title="title"
    @close="close"
    id="mapPropertyModal"
  >
    <template #content>
      <div>
        <ErrorCard :statusMessage="mapError" />
        <p>Property List</p>
        <div class="locations-container">
          <el-table :data="propertiesList">
            <el-table-column
              prop="energyStarPropertyId"
              label="ENERGY STAR® ID"
              sortable
            />
            <el-table-column prop="name" label="Property Name" sortable />
            <el-table-column prop="address" label="Address" sortable />
            <el-table-column width="65" label="Map">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.row.energyStarPropertyId"
                  v-model="propertyId"
                >
                  &nbsp;
                </el-radio>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="buttons-container">
        <el-button
          @click="close()"
          class="plain-button button-size"
          id="cancelMapToProperty"
          plain
        >
          Cancel
        </el-button>
        <el-button
          class="button-large"
          id="mapToProperty"
          type="primary"
          @click="confirm()"
        >
          Map to Property
        </el-button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { energystar } from '@/api'
import BaseModal from '@/components/BaseModal'
import ErrorCard from '@/components/Cards/ErrorCard'

export default {
  components: {
    BaseModal,
    ErrorCard,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  async mounted() {
    await this.fetchProperties()
    this.loading = false
  },
  async beforeUpdate() {
    await this.fetchProperties()
    this.loading = false
  },
  data() {
    return {
      propertyId: '',
      mapError: '',
      showModal: false,
      title: 'Map Energy star Property',
      propertiesList: [],
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  methods: {
    close() {
      this.$emit('close')
      this.propertyId = ''
      this.mapError = ''
      this.propertiesList = []
    },
    async confirm() {
      const { customerId, locationId } = this.$route.params
      if (!this.propertyId) {
        this.mapError = 'Please choose a property to map to location'
        return
      }
      let body = {
        locationId: locationId,
      }
      try {
        const property = await energystar.mapProperty({
          customerId,
          propertyId: this.propertyId,
          body,
        })
        if (property && property.id) {
          this.showModal = false
          this.$emit('close', 'updatedEnergyStar', property)
        } else {
          this.mapError = 'Cannot map Energystar property'
        }
      } catch (e) {
        this.createError = 'Cannot map Energystar property.'
      }
    },
    async fetchProperties() {
      const { customerId } = this.$route.params
      const data = await energystar.getProperties(customerId)
      this.propertiesList = data
    },
  },
}
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
}
.locations-container {
  max-height: 25vw;
  overflow-y: scroll;
}
.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
  .button-size {
    width: 15vw;
  }
  .button-large {
    width: 20vw;
  }
}
</style>
