export default {
  data() {
    return {
      scrollOnBottom: false,
    }
  },
  updated() {
    this.scrollToBottom()
  },
  methods: {
    scrollToBottom() {
      if (this.scrollOnBottom) {
        this.$emit('scrollToBottom')
        this.scrollOnBottom = false
      }
    },
  },
}
