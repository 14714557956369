import * as R from 'ramda'

const SET_FILTERS = 'set_filter'

const initialState = () => ({
  filters: {
    vendorCodes: [],
    locationsIds: [],
    searchQuery: '',
    statementDateMin: null,
    statementDateMax: null,
    amountDueMin: null,
    amountDueMax: null,
    orderBy: ['statementDate.desc'],
    billingLineItemDescription: null,
    limit: 20,
    offset: 0,
    pageSizes: [10, 20, 30, 40, 50, 100],
  },
})

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    [SET_FILTERS]: (state, filters) => {
      if (typeof filters.limit === 'string') {
        filters.limit = Number(filters.limit)
      }
      if (typeof filters.offset === 'string') {
        filters.offset = Number(filters.offset)
      }
      if (typeof filters.amountDueMin === 'string') {
        filters.amountDueMin = Number(filters.amountDueMin)
      }
      if (typeof filters.amountDueMax === 'string') {
        filters.amountDueMax = Number(filters.amountDueMax)
      }
      Object.assign(state.filters, filters)
    },
    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async setFilters({ commit, dispatch }, filters) {
      await commit(SET_FILTERS, filters)
      await dispatch('bills/loadBills', null, { root: true })
    },
    async changeFilters({ commit }, filters) {
      await commit(SET_FILTERS, filters)
    },
    resetFilters({ commit }) {
      commit('resetState')
    },
  },

  getters: {
    filters: R.prop('filters'),
    order: (state) => R.head(R.path(['filters', 'orderBy'], state)),
    pagination: R.prop('filters'),
    currentPage: (state) => state.filters.offset / state.filters.limit + 1,
  },
}
