import * as API from '@/api'
import { prop, map, propOr } from 'ramda'
const SET_LOADING = 'set_loading'
const SET_DATA = 'set_data'
const SET_BLI_FILTERS = 'set_bli_filters'

const initialState = () => ({
  loading: false,
  data: {
    type: [],
    format: [],
    start_date: null,
    end_date: null,
  },
  bli_filters: {},
})

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    [SET_LOADING]: (state, flag) => {
      state.loading = flag
    },

    [SET_DATA]: (state, data) => {
      state.data = data
    },

    [SET_BLI_FILTERS]: (state, filters) => {
      state.bli_filters = filters
    },

    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async loadData({
      commit,
      state,
      rootState: {
        customers: { currentCustomerId },
      },
    }) {
      await commit(SET_LOADING, true)
      try {
        let data = await API.reports.getFiltersData(currentCustomerId)
        await commit(SET_DATA, data)
      } catch (error) {
        console.error('LOADING FILTERS ERROR:', error)
      } finally {
        await commit(SET_LOADING, false)
      }
    },
    async clearReportsFiltersData({ commit }) {
      await commit('resetState')
    },
    async fetchFilters({
      commit,
      state,
      rootState: {
        customers: { currentCustomerId },
      },
    }) {
      try {
        const filters = await API.reports.fetchFilters(currentCustomerId)
        const { vendor_codes } = filters
        filters.vendor_pretty_names = map(propOr('', 'pretty_name'))(
          vendor_codes,
        )
        commit(SET_BLI_FILTERS, filters)
      } catch (e) {
        console.log(`error while loading billing line items filters: `, e)
      }
    },
  },

  getters: {
    data: prop('data'),
    loading: prop('loading'),
    bli_filters: prop('bli_filters'),
  },
}
