import { isEmpty, pathOr, find, propEq, isNil, prop } from 'ramda'
import * as _ from 'lodash'
const initialState = () => ({
  currentCustomerId: null,
  list: [],
  carbonFootprintObject: {},
})

const customersModuleState = () => {
  const state = initialState()
  try {
    const savedState = JSON.parse(localStorage.getItem('vuex'))
    state.list = pathOr([], ['user', 'user', 'customers'], savedState)
    state.currentCustomerId = pathOr(null, ['0', 'id'], state.list)
  } catch (e) {
    console.warn('vuex localStorage JSON parse error')
  }
  return state
}

export default {
  namespaced: true,
  state: customersModuleState(),

  getters: {
    hasAnyPermissions: (state) => (customer, permissions) => {
      if (!customer) {
        return false
      }
      return (
        customer.permissions &&
        customer.permissions.filter((permission) => {
          return (
            permissions.includes(permission.feature) &&
            permission.permission === 'enabled'
          )
        }).length > 0
      )
    },
    currentCustomerId: (state) =>
      isEmpty(state.currentCustomerId) ? null : state.currentCustomerId,
    customersList: (state) => state.list,
    isPaymentsEntitlement: (state) => {
      const customer = find(propEq('id', state.currentCustomerId))(
        state.list || [],
      )
      return pathOr(false, ['entitlements', 'payments'], customer)
    },
    isPowerBIEntitlement: (state) => {
      const customer = find(propEq('id', state.currentCustomerId))(
        state.list || [],
      )
      return pathOr(false, ['entitlements', 'powerBi'], customer)
    },
    isActivityHistoryEntitlement: (state) => {
      const customer = find(propEq('id', state.currentCustomerId))(
        state.list || [],
      )
      return pathOr(false, ['entitlements', 'activityHistoryChat'], customer)
    },
    isBillPayEntitlement: (state) => {
      const customer = find(propEq('id', state.currentCustomerId))(
        state.list || [],
      )
      return pathOr(false, ['entitlements', 'billPay'], customer)
    },
    hasCarbonFootprintEntitlement: (state) => {
      const customer = find(propEq('id', state.currentCustomerId))(
        state.list || [],
      )
      return pathOr(false, ['entitlements', 'carbonFootprint'], customer)
    },
    hasCarbonFootprintReport: (state) => {
      if (state.carbonFootprintObject) {
        return true
      } else {
        return false
      }
    },
    carbonFootprintReport: (state) => {
      return state.carbonFootprintObject
    },
    isBillEditorEntitlement: (state) => {
      const customer = find(propEq('id', state.currentCustomerId))(
        state.list || [],
      )
      return pathOr(false, ['entitlements', 'billEditor'], customer)
    },
    isNoCustomers: (state) => isEmpty(state.list) || isNil(state.list),
    customerName: (state) => {
      const customer = find(propEq('id', state.currentCustomerId))(
        state.list || [],
      )
      return prop('name', customer)
    },
    customer: (state) => {
      return find(propEq('id', state.currentCustomerId))(state.list || [])
    },
    getCustomerById: (state) => (id) => {
      return find(propEq('id', id))(state.list || [])
    },
    hasEntitlement: (state) => (entitlement) => {
      const customer =
        state.list &&
        state.list.find((customer) => customer.id === state.currentCustomerId)
      return _.get(customer, 'entitlements.' + entitlement, false)
    },
  },

  mutations: {
    setCurrentCustomerId(state, customerId) {
      state.currentCustomerId = customerId
    },
    setCurrentCustomerCarbonFootprint(state, { customerId, carbonFootprint }) {
      state.carbonFootprintObject = carbonFootprint
    },
    setCustomersList(state, list) {
      state.list = list
    },
    clear(state) {
      Object.assign(state, initialState())
    },
  },
  actions: {
    setCustomerInfo({ commit }, { user }) {
      const list = pathOr([], ['customers'], user)
      const id = pathOr(null, ['0', 'id'], list)
      commit('setCustomersList', list)
      commit('setCurrentCustomerId', id)
    },
  },
}
