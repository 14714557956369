<template>
  <el-form
    class="mtb-10"
    ref="budgetSettingsRef"
    :model="budgetSettings"
    :rules="rules"
    :hide-required-asterisk="true"
    label-position="top"
    size="small"
  >
    <el-form-item
      class="form-item-width"
      label="Budget Name:"
      prop="budgetName"
    >
      <el-input
        class="budget-name"
        v-model="budgetSettings.budgetName"
        placeholder="e.g. Budget Forecast"
        maxlength="50"
      />
    </el-form-item>
    <el-form-item class="mt-16" label="Start Month:" prop="budgetStartdt">
      <el-date-picker
        style="width: 200px"
        v-model="budgetSettings.budgetStartdt"
        format="MM/yyyy"
        type="month"
        placeholder="Pick a Start Month"
      />
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    isEditModal: {
      type: Boolean,
      default: false,
    },
    budgetSettingsRefresh: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      budgetSettings: {
        budgetName: '',
        budgetStartdt: '',
      },
      rules: {
        budgetName: {
          required: true,
          message: 'Budget Name required.',
          trigger: ['blur'],
        },
        budgetStartdt: {
          required: true,
          message: 'Start Month required.',
          trigger: ['change'],
        },
      },
    }
  },
  watch: {
    opened: {
      handler() {
        Object.assign(this.budgetSettings, this.budgetSettingsRefresh)
        this.budgetSettings.budgetName = this.isEditModal
          ? this.budgetSettings.budgetName
          : `${this.budgetSettings.budgetName} copy`
      },
      immediate: true,
    },
  },
  methods: {
    validateForm() {
      let formValidity = false
      this.$refs.budgetSettingsRef.validate((valid) => {
        if (!valid) {
          formValidity = true
        }
      })
      return formValidity
    },
    clearValidation() {
      this.$refs.budgetSettingsRef.clearValidate()
    },
  },
}
</script>

<style lang="scss" scoped>
.mt-16 {
  margin-top: 16px;
}
.mtb-10 {
  margin: 10px 0;
}
.budget-name {
  width: 200px;
}
.budget-start-date {
}
</style>
