<template>
  <base-modal
    :dialogVisible="showModal"
    :closeOnMask="false"
    @close="close"
    :width="'40%'"
    :title="'PowerBI Production Test'"
  >
    <template #content>
      <error-card :statusMessage="errorMessage" />
      <el-form label-position="left">
        <base-input
          v-model="powerbiParamsForm.groupId"
          label="Group ID:"
          :labelWidth="'200px'"
          size="medium"
          :isEditMode="true"
        />
        <base-input
          v-model="powerbiParamsForm.reportId"
          label="Report ID:"
          :labelWidth="'200px'"
          size="medium"
          :isEditMode="true"
        />
        <base-input
          v-model="powerbiParamsForm.datasetId"
          label="Dataset ID:"
          :labelWidth="'200px'"
          size="medium"
          :isEditMode="true"
        />
      </el-form>
    </template>
    <template #footer>
      <el-button type="primary" @click="emitPowerBIParams">
        Run Report
      </el-button>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import ErrorCard from '@/components/Cards/ErrorCard'
import BaseInput from '@/components/FormComponents/BaseInput'

export default {
  components: { BaseModal, BaseInput, ErrorCard },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showModal: false,
      powerbiParamsForm: {
        datasetId: '',
        reportId: '',
        groupId: '',
      },
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    emitPowerBIParams() {
      this.$emit('emit-powerbi-params', this.powerbiParamsForm)
    },
  },
}
</script>
