import router from '../../router'

const initialState = () => ({
  filters: {
    type: [],
    format: [],
    startDate: '',
    endDate: '',
  },
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    filters: (state) => state.filters,
  },
  mutations: {
    setFilters(state, filters) {
      Object.assign(state.filters, filters)
    },
    resetFilters(state) {
      Object.assign(state, initialState())
    },
  },
  actions: {
    async changeFilters({ commit, dispatch }, { customerId, params, tab }) {
      await commit('setFilters', params)
      const currentParams = { ...router.currentRoute.query, ...params }
      await router.push({
        query: currentParams,
      })
      if (tab === 'completed') {
        await dispatch(
          'completedReports/getCompletedReports',
          { customerId, params: currentParams },
          { root: true },
        )
        return
      }
      await dispatch(
        'scheduledReports/getScheduledReports',
        { customerId, params: currentParams },
        { root: true },
      )
    },
    async resetState({ commit }) {
      commit('resetFilters')
    },
  },
}
