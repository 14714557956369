<template lang="pug">
  .hierarchy-wrapper(:class="{'mobile': $deviceInfo.isMobileDevice, 'desktop': !$deviceInfo.isMobileDevice}")
    .hierarchy-header
      .page-title(v-if="!$deviceInfo.isMobileDevice") Locations Hierarchy
      filters(@change="change")
    template(v-if="!$deviceInfo.isMobileDevice")
      .hierarchy-body.hierarchy-body_desktop
        .col
          hierarchies-control(
            :hierarchy="hierarchy",
            :nodeClick="onClick",
            :selectedLocation="selected",
            :matched="matched",
            :locations="locations")
        .col
          node-info(v-if="selected", :node="selected", :unMatchedLocations="unMatchedLocationsQty")
    template(v-else)
      .hierarchy-body.hierarchy-body_mobile
        hierarchies-control(
          :hierarchy="hierarchy",
          :nodeClick="onClick",
          :selectedLocation="selected",
          :matched="matched",
          :showInfo="showInfo",
          :locations="locations")
        el-dialog(:visible.sync="dialogVisible")
          node-info(v-if="selected", :node="selected", :unMatchedLocations="unMatchedLocationsQty")
          span.dialog-footer(slot="footer")
          el-button.btn-ok.is-round(type="primary", @click="dialogVisible = false") OK

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HierarchiesControl from './../Controls/HierarchiesControl'
import Filters from '@/pages/Dashboard/Locations/Hierarchy/Filters'
import NodeInfo from '@/pages/Dashboard/Locations/Hierarchy/NodeInfo'
import _ from 'lodash'

export default {
  components: {
    Filters,
    HierarchiesControl,
    NodeInfo,
  },
  data() {
    return {
      selected: {},
      query: '',
      dialogVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      locations: 'locations/locations',
      hierarchy: 'locations/hierarchy',
      unMatchedLocations: 'locations/unMatchedLocations',
    }),
    unMatchedLocationsQty() {
      return _.get(this.unMatchedLocations, ['length'], 0)
    },
    matched() {
      let { query } = this
      if (_.isEmpty(query)) {
        return { query: '', matches: [] }
      }
      query = query.toLowerCase()
      const matches = this.hierarchy.filter((node) => {
        const hierarchyName = _.get(node, ['name'], '')
        const locationName = _.get(node, ['location', 'name'], '')
        return (
          (hierarchyName || locationName).toLowerCase().indexOf(query) !== -1
        )
      })
      return { query, matches }
    },
  },
  methods: {
    ...mapActions({
      loadUnmatchedLocations: 'locations/loadUnmatchedLocations',
    }),
    onClick(node) {
      this.selected = node
    },
    change(query) {
      this.query = query
    },
    showInfo() {
      this.dialogVisible = true
    },
  },
  async beforeMount() {
    const root = _.find(this.hierarchy, (h) => !_.isNull(h.parentId))
    if (!_.isEmpty(root)) {
      await this.loadUnmatchedLocations({
        customerId: root.customerId,
        hierarchyId: root.id,
      })
    }
  },
  mounted() {
    const openId = _.get(this.$route.query, ['id'], null)
    if (openId) {
      this.selected = _.find(this.hierarchy, (h) => h.id === openId)
    }
  },
}
</script>

<style lang="scss" scoped>
@import './../../../../styles/_utilities';

.hierarchy-wrapper {
  &.desktop {
    .hierarchy-body {
      display: flex;
      flex-direction: row;
      height: calc(100vh - 210px);
      max-height: calc(100vh - 210px);

      .col {
        flex-basis: 50%;
        padding: 10px 15px 10px 0;
        box-sizing: border-box;

        &:nth-child(2) {
          padding-left: 15px;
        }

        @extend %scrollbar;
      }
    }
  }

  &.mobile {
    .hierarchy-body {
      margin: 0 -25px;
    }

    .filter {
      background-color: transparent;
    }

    ::v-deep .el-dialog {
      margin: 0 !important;
      width: 100%;
      height: 100%;

      &__body {
        padding: 0 15px;
      }

      .el-icon-close {
        font-size: 20px;
        font-weight: bold;
        color: #7d89a6;
      }
    }

    .btn-ok {
      display: block;
      width: 240px;
      height: 30px;
      padding: 0 32px;
      margin: 140px auto 15px auto;
      line-height: 30px;
      background-color: #0080ff;
    }
  }
}
</style>
