<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { Events } from '@/lib'
import checkMobileDevice from '@/mixins/checkMobileDevice'
import { initializeAxiosInterceptors } from '@/lib/http'

export default {
  mixins: [checkMobileDevice],
  name: 'App',

  async beforeCreate() {
    if (localStorage.getItem('authToken')) {
      await initializeAxiosInterceptors()
      await this.$store.dispatch('user/loadResourcesAndHierarchies')
    }
    Events.$on('not.found', () => {
      this.$router.history.push(this.$router.match('/error/404'))
    })
  },
}
</script>

<style lang="scss">
@import './styles/main.scss';

#app {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #273a58;
  height: auto;
}

#_elev_io ._bzf50 {
  font-weight: 600;
}
#_elev_io ._ahxts {
  font-weight: 600 !important;
}
#_elev_io ._xy6qs {
  top: 80% !important;
}
</style>
