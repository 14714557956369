<template>
  <div class="sidebar-container">
    <div
      class="sidebar-item sidebar-item__errors"
      :class="{ active: sidebar === 'isErrorsVisible' }"
    >
      <div class="error-bar-wrapper">
        <div class="header-wrapper">
          <h2>Bill Validation Errors</h2>
        </div>
        <bill-side-errors
          v-if="hasBillErrors"
          :errorsList="bill.errors"
          :resolvedErrors="bill.resolvedErrors"
          :billId="bill.id"
          :navigation="bill.navigation"
          :billValidations="billValidations"
          @loadBill="loadBill"
          :userId="bill.userId"
          :workflows="workflowStates"
          :workflowState="bill.workflowState"
        />
        <h3 class="no-errors" v-else>This bill has zero errors.</h3>
      </div>
    </div>
    <div
      v-if="sidebar === 'isLogsVisible'"
      class="sidebar-item sidebar-item__logs"
      :class="{ active: sidebar === 'isLogsVisible' }"
    >
      <logs-bar />
    </div>
    <div
      class="sidebar-item sidebar-item__pdf-bill"
      :class="{ active: sidebar === 'isPdfBillVisible' }"
    >
      <bill-pdf-side-bar
        v-if="sidebar === 'isPdfBillVisible' || isPdfWasOpened"
        @pdfRendered="pdfWasRendered"
        :bill="bill"
      />
    </div>
  </div>
</template>

<script>
import BillPdfSideBar from './BillPdfSideBar'
import BillSideErrors from './BillSideErrors'
import LogsBar from './LogsBar'

export default {
  components: {
    LogsBar,
    BillSideErrors,
    BillPdfSideBar,
  },
  props: {
    sidebar: {
      type: String,
      default: 'isErrorsVisible',
    },
    billValidations: {
      type: Array,
      default: () => [],
    },
    workflowStates: {
      type: Array,
      default: () => [],
    },
    bill: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isPdfWasOpened: false,
    }
  },
  computed: {
    hasBillErrors() {
      return (
        this.bill &&
        this.bill.errors &&
        this.bill.errors.length &&
        this.billValidations &&
        this.billValidations.length
      )
    },
  },
  methods: {
    async loadBill() {
      await this.$emit('loadBill')
    },
    pdfWasRendered() {
      this.isPdfWasOpened = true
    },
  },
}
</script>

<style lang="scss">
.sidebar-container {
  padding: 0;
  min-width: 540px;
  height: 100%;
  box-sizing: border-box;
  border: solid 1px #dcdfe6;

  .sidebar-item {
    display: none;

    &.active {
      display: flex;
    }

    &__pdf-bill {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
    }

    &__errors {
      max-height: 100%;
      height: 100%;
    }
    &__logs {
      max-height: 100%;
      height: 100%;
    }
  }
}
.error-bar-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    h2 {
      color: #222222;
      font-size: 18px;
      line-height: 24px;
      margin: 20px 20px 20px 16px;
    }

    span {
      font-size: 16px;
      line-height: normal;
      font-weight: 700;

      &.critical {
        color: #f56c6c;
      }

      &.processed {
        color: #67c23a;
        font-size: 20px;
      }

      &.audit {
        color: #f3a745;
      }
    }
  }
}
.no-errors {
  margin-left: 16px;
  color: #222222;
  font-size: 16px;
  line-height: 22px;
}
</style>
