<template lang="pug">
.filter.filter-m
  .filter-container
    .filter-item
      .filter-title Search
      .select-box
        filter-search(:search-query="filters.query",
                      :onChange='setFilters')
    .filter-item
      .filter-title Type
      filter-select(filter-name="building_type",
                    :options='filtersData.building_type',
                    :onSelectChange='setFilters',
                    :selected='filters.building_type')
    .filter-item
      .filter-title City
      filter-select(filter-name="city",
                    :options='filtersData.city',
                    :onSelectChange='setFilters',
                    :selected='filters.city')
    .filter-item
      .filter-title State
      filter-select(filter-name="state",
                    :options='states',
                    :onSelectChange='setFilters',
                    :selected='filters.state')
    .filter-item
      .filter-title Area (Sq. Ft.)
      filter-range(filterName='square_feet',
                  :onChange='setFilters',
                  :min='filters.square_feet_min',
                  :max='filters.square_feet_max')
    el-button.button-filter-apply(type='primary', size='small', @click='applyFilters') Apply Filters
    el-button.button-filter-reset(type='primary', size='small', @click='resetFilters') Reset Filters
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters, mapActions } from 'vuex'
import FilterSearch from '@/components/Filters/FilterSearch'
import FilterSelect from '@/components/Filters/FilterSelect'
import FilterRange from '@/components/Filters/FilterRange'
import * as R from 'ramda'

export default {
  components: {
    FilterSearch,
    FilterSelect,
    FilterRange,
  },
  data() {
    return {
      showFilter: true,
      placeholder: 'Search',
      newFilters: {},
    }
  },
  mounted() {
    this.changeFilters = debounce((args) => this.$changeFilters(args), 300)
  },
  computed: {
    ...mapGetters({
      loadingData: 'locations/filtersData/loading',
      filtersData: 'locations/filtersData/data',
      filters: 'locations/filters/filters',
      getResourceByName: 'resources/getResourceByName',
    }),
    states() {
      const filters = R.propOr([], 'state', this.filtersData)
      const states = R.propOr({}, 'value')(this.getResourceByName('states'))

      return R.map((key) => {
        const prettyName = R.pathOr('', [key, 'pretty_name'], states)
        const state = (key || '').replace(/^.*-/g, '')
        return {
          name: `${prettyName} (${state})`,
          value: key,
        }
      })(filters)
    },
  },
  methods: {
    ...mapActions({
      $changeFilters: 'bills/filters/changeFilters',
      $resetFilers: 'bills/filters/resetFilters',
    }),
    applyFilters() {
      this.$changeFilters(this.newFilters)
      this.$emit('close', true)
    },
    resetFilters() {
      this.$resetFilers()
      this.$emit('close', true)
    },
    setFilters(filter) {
      const setNewFilters = debounce(() => {
        this.newFilters = {
          ...this.newFilters,
          ...filter,
        }
      }, 300)

      setNewFilters()
    },
  },
}
</script>

<style lang="scss" scoped>
.filter {
  .filter-container {
    .select-box {
      ::v-deep .el-input {
        width: 100%;
      }
    }
  }
}
</style>
