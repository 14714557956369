<template lang="pug">
.filter.filter-m
  .filter-container
    .filter-item
      .filter-title Processing Date
      .filter-row
        .select-container
          i.el-input__icon.el-icon-date
          input.date-input(type="month", placeholder="Date", :value="selectedDate", @change="selectDate")
          i.el-input__icon.el-icon-caret-bottom
    el-button.button-filter-apply(type="primary", size="small", @click="applyFilters") Apply Filters
    el-button.button-filter-reset(type="primary", size="small", @click="cancelFilters") Reset Filters
</template>

<script>
import _ from 'lodash'
import FilterDatepicker from '@/components/Filters/FilterDatepicker'
import { DateTime } from 'luxon'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    FilterDatepicker,
  },
  data() {
    return {
      date: '',
    }
  },
  computed: {
    ...mapGetters({
      selectedDate: 'payments/selectedDate',
      dates: 'payments/dates',
      params: 'payments/params',
    }),
  },
  methods: {
    ...mapActions({
      $fetchPayments: 'payments/fetchPayments',
      $setParams: 'payments/setParams',
      $selectDate: 'payments/selectDate',
    }),
    selectDate(e) {
      this.$selectDate(e.target.value)
    },
    async changeDate(time) {
      let dateString = null
      if (!time) {
        dateString = DateTime.fromFormat(_.last(this.dates), 'yyyy-LL', {
          zone: 'utc',
        })
          .endOf('month')
          .toFormat('yyyy-LL-dd')
          .toString()
      } else {
        dateString = DateTime.fromFormat(time, 'yyyy-LL')
          .plus({ days: 1, months: 1 })
          .toFormat('yyyy-LL-dd')
          .toString()
      }
      await this.$setParams({
        direction: '-',
        start_date: dateString,
        period: 12,
      })
      await this.$fetchPayments()
      window.scroll(0, 1)
    },
    applyFilters() {
      this.changeDate(this.selectedDate)
      this.$emit('close')
    },
    async cancelFilters() {
      await this.$setParams({
        ...this.params,
        start_date: DateTime.fromFormat(_.last(this.dates), 'yyyy-LL', {
          zone: 'utc',
        })
          .endOf('month')
          .toFormat('yyyy-LL-dd')
          .toString(),
      })
      await this.$fetchPayments()
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-item {
  max-width: 264px;
}

.filter-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.select-container {
  box-sizing: border-box;
  height: 36px;
  /*width: 150px;*/
  border: 1px solid #dcdfe6;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 11px 0 18px;

  &:first-child {
    margin-right: 8px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #909399;
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    background: none;
    border: none;
    outline: none;
    padding-left: 8px;
  }

  .el-input__icon {
    line-height: normal;
    width: auto;
    color: #909399;
  }

  .date-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    font-size: 16px;
    width: 150px;
    padding: 0 8px;
  }
}
</style>
