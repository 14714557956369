import * as _ from 'lodash'
import store from '../../store'
import router from '../../router'

const protectedRoutes = [
  {
    path: 'dashboard.sam',
  },
  {
    path: 'dashboard.analytics',
  },
  {
    path: 'dashboard.payments',
  },
]

const initialState = () => ({
  previousRoute: null,
  currentRoute: null,
  customerIdPath: null,
  currentCustomerId: null,
  nextCallback: null,
})

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setCurrentRoute(state, currentRoute) {
      state.currentRoute = currentRoute
    },
    setPreviousRoute(state, previousRoute) {
      state.previousRoute = previousRoute
    },
    setCustomerIdPath(state, customerIdPath) {
      state.customerIdPath = customerIdPath
    },
    setCurrentCustomerId(state, currentCustomerId) {
      state.currentCustomerId = currentCustomerId
    },
    setNextCallback(state, nextCallback) {
      state.nextCallback = nextCallback
    },
  },
  actions: {
    async updateCurrentRoute({ commit, state, dispatch }, { to, from, next }) {
      const rawCustomerIdPath = _.get(to, 'params.customerId', null)
      const customerIdPath = rawCustomerIdPath
        ? parseInt(rawCustomerIdPath)
        : null

      commit('setCurrentRoute', to)
      commit('setPreviousRoute', from)
      commit('setNextCallback', next)
      commit('setCustomerIdPath', customerIdPath)
      commit(
        'setCurrentCustomerId',
        store.getters['customers/currentCustomerId']
          ? parseInt(store.getters['customers/currentCustomerId'])
          : null,
      )

      await dispatch('checkNoCustomerRedirect')
      await dispatch('setTitle')
    },
    async setTitle({ state }) {
      document.title = state.currentRoute.meta.title
        ? state.currentRoute.meta.title + ' - Pear AI'
        : 'Pear AI'
    },
    async checkNoCustomerRedirect({ state, dispatch }) {
      if (
        store.getters['user/isAuth'] &&
        store.getters['customers/isNoCustomers'] &&
        state.currentRoute.name !== 'home.no.customer' &&
        state.previousRoute.name === 'login'
      ) {
        return state.nextCallback({ name: 'home.no.customer' })
      }

      await dispatch('checkForCurrentCustomerId')
    },
    async checkForCurrentCustomerId({ state, dispatch }) {
      if (store.getters['user/isAuth'] && !state.currentCustomerId) {
        const user = await dispatch('user/fetchUser')
        const rawCustomerId = _.get(user, 'customers.0.id', null)
        const customerId = rawCustomerId ? parseInt(rawCustomerId) : null
        store.commit(
          'customers/setCurrentCustomerId',
          state.customerIdPath || customerId,
        )
      }

      await dispatch('checkForResetTokenPath')
    },
    async checkForResetTokenPath({ state, dispatch }) {
      if (
        state.currentRoute.params.token &&
        state.currentRoute.path.indexOf('/auth/reset') < 0
      ) {
        await store.dispatch('user/logout')
        await router.replace(
          `/auth/activate/${state.currentRoute.params.token}`,
        )
      }

      await dispatch('checkIfPathIsAuth')
    },
    async checkIfPathIsAuth({ state, dispatch }) {
      if (_.isNil(state.currentRoute.meta.auth)) {
        return state.nextCallback()
      }

      await dispatch('checkProtectedRoutes')
    },
    async checkProtectedRoutes({ state, dispatch }) {
      let list = store.getters['customers/customersList']
      let customer = (list || []).find((c) => c.id === state.customerIdPath)

      const protectedRoute = protectedRoutes.find((protectedRoute) => {
        return protectedRoute.path === state.currentRoute.name
      })

      if (
        protectedRoute &&
        (!customer ||
          !store.getters['customers/hasAnyPermissions'](customer, [
            'customer_full_access',
            protectedRoute.name,
          ]))
      ) {
        if (state.previousRoute.name === state.currentRoute.name || !store.getters['user/isAuth']) {
          return state.nextCallback({
            name: 'dashboard.home',
            replace: true,
          })
        }
        return state.nextCallback({ name: 'forbidden' })
      }

      await dispatch('checkForLogin')
    },
    async checkForLogin({ state, dispatch }) {
      if (state.currentRoute.meta.auth === store.getters['user/isAuth']) {
        state.nextCallback()
      } else {
        let redirectAfterAuth =
          window.location.pathname + window.location.search
        if (redirectAfterAuth === '/' || redirectAfterAuth === '/auth/login') {
          redirectAfterAuth = '/home'
        }
        localStorage.setItem('redirectAfterAuth', redirectAfterAuth)
        // TODO: Uncomment this after issue related to session loosing will be fixed
        // try {
        //   const ref = new URL(document.referrer);
        //   const { constellation_energy_manager, msal } = application;
        //   if (ref.origin === constellation_energy_manager.host) {
        //     window.location.href = `https://${msal.tenant_name}.b2clogin.com/${msal.tenant_name}.onmicrosoft.com/oauth2/v2.0/authorize?p=${msal.user_flow_name}&client_id=${msal.clientId}&nonce=defaultNonce&redirect_uri=${window.location.origin}/auth/login&scope=openid&response_type=id_token&prompt=login`;
        //   }
        // } catch (e) {}
        state.nextCallback({
          path: store.getters['user/isAuth']
            ? `/${state.currentCustomerId}/home`
            : '/auth/login',
        })
      }

      await dispatch('checkForCustomerChange')
    },
    async checkForCustomerChange({ state }) {
      if (
        store.getters['user/isAuth'] &&
        state.customerIdPath &&
        state.currentCustomerId !== state.customerIdPath
      ) {
        let customer = store.getters['customers/getCustomerById'](
          state.customerIdPath,
        )
        if (customer) {
          store.commit('customers/setCurrentCustomerId', state.customerIdPath)
          return state.nextCallback()
        }
        const user = await store.dispatch('user/fetchUser')
        const list = _.get(user, 'customers', [])
        await store.commit('customers/setCustomersList', list)
        await store.dispatch('customers/addCustomerCarbonFootprint')
        customer = store.getters['customers/getCustomerById'](
          state.customerIdPath,
        )

        if (customer) {
          await store.commit(
            'customers/setCurrentCustomerId',
            state.customerIdPath,
          )
          return state.nextCallback()
        }

        return state.nextCallback({
          name: 'notFound',
          params: [state.currentRoute.path],
          replace: true,
        })
      }
    },
  },
}
