<template lang="pug">
  .content-inner
    list(v-if='!$deviceInfo.isMobileDevice')
    list-mobile(v-else)
    el-pagination.pagination-container(
      background,
      v-if='false || total > 1',
      layout='prev, pager, next, sizes',
      :page-size='+pagination.limit',
      :page-sizes='pagination.page_sizes',
      :current-page='currentPage',
      @current-change='changeOffset',
      @size-change='changeLimit',
      :total='total',
      :disabled='isLoading')
</template>

<script>
import FilterContainer from './Filters/FilterContainer'
import ListMobile from './ListMobile.vue'
import List from './List.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    FilterContainer,
    List,
    ListMobile,
  },
  computed: {
    ...mapGetters({
      total: 'locations/total',
      loading_list: 'locations/loading_list',
      pagination: 'locations/filters/pagination',
      currentPage: 'locations/filters/currentPage',
    }),
    isLoading() {
      return this.loading_list
    },
  },
  mounted() {
    if (Object.keys(this.$route.query).length === 0) {
      this.$resetFilters()
    }
  },
  destroyed() {
    this.$resetFilters()
  },
  methods: {
    ...mapActions({
      $changeFilters: 'locations/filters/changeFilters',
      $resetFilters: 'locations/filters/resetFilters',
    }),
    changeLimit(limit) {
      this.$changeFilters({ limit: +limit, offset: 0 })
    },
    changeOffset(page) {
      const offset = (page - 1) * this.pagination.limit
      this.$changeFilters({ offset })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/utilities';

.content-inner {
  padding-right: 40px;
  padding-left: 40px;

  @include respond-to($phone) {
    padding: 15px 10px;
    max-height: calc(100% - 30px);
    -webkit-overflow-scrolling: touch;
  }
  @include respond-to($phone-l) {
    padding: 15px 10px;
    max-height: calc(100% - 30px);
    -webkit-overflow-scrolling: touch;
  }
}
</style>

<style lang="scss">
@import '../../../styles/components/_pagination';
</style>
