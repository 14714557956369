<template lang="pug">
  .create-widget-container
    el-button(type="text",
      data-action="create-widget-wizard",
      @click="addWidgetModalVisible = true") + Create Widget
    el-dialog(:visible.sync="addWidgetModalVisible",
      class="widget-create-modal",
      title="Create Widget",
      width="610")
      .field-control
        label Widget Type
        el-select.widget-type-field(v-model="newWidgetType",
          no-data-text="No widgets available",
          data-value="widget-type",
          @change="clearFilters",
          filterable)
          el-option(v-for="option in sortedWidgetTypes",
            :key="option.type",
            :value="option.type",
            :label="option.title")
      .field-control
        label Widget Name
        el-input.widget-name-field(v-model="newWidgetName",
          data-value="widget-create-input-name",
          :placeholder="widgetNamePlaceholder")
      .widget-create-filters
        filter-container(v-if="newWidgetType.length && isAddFilterOpened",
          :widget-filters="defaultFilters",
          :widget-type="newWidgetType",
          @add-filters="addFilter")
        filter-show-container(v-if="!isAddFilterOpened",
          :widgetFilters="defaultFilters",
          :widget-type="newWidgetType",
          @remove-filter="removeFilter",
          @open-edit-filter="openEditFilter",
          @close-filter="closeFilter",
          @update-filter="updateFilter")
      .widget-create-modal_footer(slot="footer")
        el-button.button-add-widget(v-if="!isEditFilterOpened",
          type="primary",
          size="small",
          round,
          :disabled="!newWidgetType.length",
          data-action="create-widget",
          @click="addWidget") Create Widget
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import FilterContainer from '../Filters/FilterContainer'
import FilterShowContainer from '../Filters/FilterShowContainer'
import { filters } from '@/lib'

export default {
  components: {
    FilterContainer,
    FilterShowContainer,
  },
  data() {
    return {
      addWidgetModalVisible: false,
      newWidgetType: '',
      newWidgetName: '',
      widgetNamePlaceholder: '',
      isAddFilterOpened: false,
      isEditFilterOpened: false,
      filters: {},
      editFilterType: '',
    }
  },
  computed: {
    ...mapGetters({
      widgetTypes: 'widgets/widgetTypes',
    }),
    widgetTooltip() {
      if (this.newWidgetType) {
        const { title, description } = this.widgetTypes[this.newWidgetType]
        return {
          title,
          description,
        }
      }

      return false
    },
    defaultFilters() {
      return { ...filters.defaultFilters(this.newWidgetType), ...this.filters }
    },
    sortedWidgetTypes() {
      return _.sortBy(this.widgetTypes, ['order'])
    },
  },
  watch: {
    widgetTooltip() {
      if (this.newWidgetType) {
        const { title } = this.widgetTypes[this.newWidgetType]
        this.widgetNamePlaceholder = title
      }
    },
    newWidgetType() {
      this.clearFilters()
    },
    addWidgetModalVisible(value) {
      if (value) {
        this.newWidgetType = ''
        this.newWidgetName = ''
        this.widgetNamePlaceholder = ''
      }
    },
  },
  methods: {
    clearFilters() {
      this.isAddFilterOpened = false
      this.isEditFilterOpened = false
      this.filters = {}
    },
    addFilter(filter) {
      this.isAddFilterOpened = false
      this.filters = { ...this.filters, ...filter }
    },
    openEditFilter(filterType) {
      this.isEditFilterOpened = true
      this.editFilterType = filterType
    },
    removeFilter() {
      this.isAddFilterOpened = false
      this.isEditFilterOpened = false
      const temp = _.cloneDeep(this.filters)
      delete temp[this.editFilterType]
      this.filters = temp
      this.editFilterType = ''
    },
    updateFilter(filter) {
      filter = _.cloneDeep(filter)
      this.isEditFilterOpened = false
      this.filters = { ...this.filters, ...filter }
    },
    closeFilter() {
      this.isAddFilterOpened = false
      this.isEditFilterOpened = false
    },
    addWidget() {
      const filtersArray = Object.keys(this.defaultFilters).map((filter) => ({
        filter,
        [filter]: this.defaultFilters[filter],
      }))
      this.$emit('add-widget', {
        name: this.newWidgetName || this.widgetNamePlaceholder,
        type: this.newWidgetType,
        chartType: this.widgetTypes[this.newWidgetType].chart_types[0],
        filters: filtersArray,
      })

      this.addWidgetModalVisible = false
      this.newWidgetType = ''
      this.newWidgetName = ''
      this.widgetNamePlaceholder = ''
      this.isAddFilterOpened = false
      this.isEditFilterOpened = false
      this.filters = {}
    },
  },
}
</script>

<style lang="scss" scoped>
.create-widget-container {
  ::v-deep .filter-item-date > :not(.el-input-number) .el-input {
    width: 145px;
  }
  .widget-title {
    margin-bottom: 40px;
  }
  .widget-create-filters {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -40px;
      width: calc(100% + 80px);
      height: 1px;
      background-color: #f0f0f0;
    }

    .create-filter {
      text-align: right;
    }
  }
  ::v-deep .filters-main-title {
    margin-top: 30px !important;
  }

  ::v-deep .widget-create-filters .widget-filter-container {
    margin-top: 0;
  }

  .widget-create-modal {
    .button-tooltip {
      width: 27px;
      height: 27px;
      padding: 0;
      margin-left: 12px;
    }

    &_message {
      margin: 10px 0 20px;
      color: #909399;
      font-size: 16px;
      line-height: 22px;
      font-weight: 900;
    }

    &_footer {
      text-align: center;
    }

    ::v-deep .el-dialog {
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    }

    ::v-deep .el-dialog__header {
      padding: 40px 40px 20px 40px;
      color: #2c2e34;
      font-size: 18px;
      line-height: 25px;
      font-weight: 900;
    }

    ::v-deep .el-dialog__body {
      padding: 0 40px;
    }

    ::v-deep .el-dialog__footer {
      padding: 20px 40px 40px;
    }
  }

  .widget-name-field {
    ::v-deep .el-input__inner {
      color: #2893f9;
      border-top-width: 0;
      border-right-width: 0;
      border-left-width: 0;
      border-radius: 0;
      border-bottom-color: #2893f9;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #2893f9;
        opacity: 1; /* Firefox */
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #2893f9;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #2893f9;
      }
    }
  }

  .button-add-widget,
  .button-add-filter {
    width: 240px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 900;
    background: #0080ff;
  }

  .widget-type-field {
    flex-grow: 1;
  }

  ::v-deep .widget-filter-show-container .filters-list {
    .filter-type,
    .filter-description {
      margin-bottom: 7px;
      font-size: 16px;
    }
  }
}
</style>
