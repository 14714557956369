<template lang="pug">
  .widget-filter-show-container(v-if="!isEmpty")
    .filters-main-title(v-if="!isEditFilterOpen") {{ title }}
      ul.filters-list(v-for="(filter, filterType) in widgetFilters", @click="editFilter(filterType)")
        li
          span.filter-edit.icon.icon-edit
          span.filter-type {{ filterTitle(filterType) }}:
          span.filter-description {{ filterDescription(filterType) }}
    .filter-mobile(v-if="isEditFilterOpen")
      el-button.close(v-if="!$deviceInfo.isMobileDevice || $route.name !== 'dashboard.widgets.details'", type="text", @click='closeFilter')
        i.el-icon-close
      filter-year(v-if="openEditFilter.year", title="Year", :filter="filters.year", :value="defaultYear")
      filter-time-period(v-if="openEditFilter.time_period",
        @validate="validateTimePeriod",
        :title="filterTitle(currentFilter)",
        :filter="filters.time_period",
        :value="defaultTimePeriod")
      filter-locations(v-if="openEditFilter.location_id",
        :commodities="commodities",
        :title="filterTitle(currentFilter)",
        :filter="filters.location_id",
        :value="defaultLocations")
      filter-utility-type(v-if="openEditFilter.utility_type",
        :commodities="commodities",
        :title="filterTitle(currentFilter)",
        :filter="filters.utility_type",
        :value="defaultUtilityType")
      filter-utility-type-list(v-if="openEditFilter.utility_type_list",
        :commodities="commodities",
        :title="filterTitle(currentFilter)", :filter="filters.utility_type_list", :value="defaultUtilityTypeList")
      .filter-mobile_actions
        el-button.button-filter-apply(type="primary",
          :disabled="openEditFilter.time_period && twoDatesNotSelected",
          size="small",
          @click="updateFilter") Apply Filter
        el-button.button-filter-reset(type="primary", size="small", @click="resetFilters") Reset Filter
</template>

<script>
import * as R from 'ramda'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import FilterTimePeriod from './Mobile/FilterTimePeriod.vue'
import FilterYear from './Mobile/FilterYear.vue'
import FilterLocations from './Mobile/FilterLocations.vue'
import FilterUtilityType from './Mobile/FilterUtilityType.vue'
import FilterUtilityTypeList from './Mobile/FilterUtilityTypeList.vue'
import { dashboard } from '@/api'
import { widgets } from '@/lib'

export default {
  props: {
    widgetFilters: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: 'Selected filters',
    },
    widgetType: {
      type: String,
      required: true,
    },
  },
  beforeMount() {
    this.getWidgetMeta()
  },
  data() {
    return {
      filterMap: {
        year: {
          title: 'Year',
        },
        time_period: {
          title: 'Time Period',
        },
        location_id: {
          title: 'Locations',
        },
        utility_type: {
          title: 'Utility Type',
        },
        utility_type_list: {
          title: 'Utility Types',
        },
      },
      openEditFilter: {
        year: false,
        time_period: false,
        location_id: false,
        utility_type: false,
        utility_type_list: false,
      },
      currentFilter: '',
      filters: {
        year: {},
        time_period: {},
        utility_type: {},
        utility_type_list: {},
        location_id: {},
      },
      twoDatesNotSelected: false,
      widgetMeta: {},
    }
  },
  watch: {
    widgetFilters() {
      this.getWidgetMeta()
      this.closeFilter()
    },
  },
  components: {
    FilterTimePeriod,
    FilterLocations,
    FilterUtilityType,
    FilterUtilityTypeList,
    FilterYear,
  },
  computed: {
    ...mapGetters({
      locations: 'locations/locations',
      customerId: 'customers/currentCustomerId',
    }),
    isEmpty() {
      return _.isEmpty(this.widgetFilters)
    },
    isEditFilterOpen() {
      return _.some(this.openEditFilter, (el) => el)
    },
    defaultYear: {
      cache: false,
      get: function () {
        return _.get(this.widgetFilters, ['year'], {
          year: new Date().getFullYear(),
        })
      },
    },
    defaultLocations: {
      cache: false,
      get: function () {
        return _.get(this.widgetFilters, ['location_id', 'location_ids'], [])
      },
    },
    defaultTimePeriod: {
      cache: false,
      get: function () {
        return _.get(this.widgetFilters, ['time_period'], [])
      },
    },
    defaultUtilityType: {
      cache: false,
      get: function () {
        return _.get(
          this.widgetFilters,
          ['utility_type', 'utility_type'],
          'ELECTRIC',
        )
      },
    },
    defaultUtilityTypeList: {
      cache: false,
      get: function () {
        return _.get(this.widgetFilters, ['utility_type_list', 'list'], [])
      },
    },
    commodities() {
      return _.get(this.widgetMeta, 'utility_type_list', [])
    },
  },
  methods: {
    validateTimePeriod(valid) {
      this.twoDatesNotSelected = !valid
    },
    editFilter(filterType) {
      this.openEditFilter[filterType] = true
      this.currentFilter = filterType
      this.$emit('open-edit-filter', filterType)
    },
    removeFilter() {
      this.$emit('remove-filter')
      _.forEach(this.openEditFilter, (value, key) => {
        this.openEditFilter[key] = false
      })
    },
    closeFilter() {
      _.forEach(this.openEditFilter, (value, key) => {
        this.openEditFilter[key] = false
      })
      this.$emit('close-filter')
    },
    async resetFilters() {
      this.$emit('reset-filters')
      this.closeFilter()
    },
    updateFilter() {
      _.forEach(this.openEditFilter, (value, key) => {
        this.openEditFilter[key] = false
      })
      this.$emit('update-filter', {
        [this.currentFilter]: this.filters[this.currentFilter],
      })
    },
    filterTitle(filterType) {
      return R.pathOr(filterType, [filterType, 'title'], this.filterMap)
    },
    filterDescription(filterType) {
      const { widgetFilters, widgetMeta, locations } = this
      return widgets.filterDescription({
        filterType,
        widgetFilters,
        widgetMeta,
        locations,
      })
    },
    getWidgetMeta() {
      const { customerId, widgetType } = this
      if (_.isEmpty(widgetType)) {
        return
      }
      dashboard.getWidgetMeta({ customerId, widgetType }).then((meta) => {
        this.widgetMeta = meta
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-mobile_actions {
  .el-button {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.widget-filter-show-container {
  padding-top: 5px;

  .filters-main-title {
    margin-bottom: 22px;
    color: #2c2e34;
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
  }

  .edit-filter-title {
    padding-top: 20px;
  }

  .button-back {
    text-decoration: underline;
  }

  ::v-deep .filter-row {
    flex-direction: row !important;
    flex-wrap: wrap;

    .selected-locations {
      flex-basis: 100%;
      line-height: 20px;
    }
  }

  ::v-deep .filter-column {
    flex-direction: column;
  }

  .remove-filter-container {
    text-align: right;

    .remove-filter {
      padding-top: 0;
      padding-bottom: 0;
      line-height: 27px;
      cursor: pointer;
      text-decoration: underline;
      /*color: #FE2800;*/
    }
  }

  ::v-deep .el-button {
    max-width: 160px;
  }

  .filter-item {
    display: flex;
    flex-direction: column;
  }

  ::v-deep .filter-item-location {
    flex-direction: row;
    flex-wrap: wrap;

    .el-radio-group {
      flex-basis: calc(100% - 32px);
    }

    .el-button {
      width: 160px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px;
      font-weight: 900;
      background: #0080ff;
    }
  }

  .button-update-filter,
  .button-close-filter {
    width: 160px;
    margin: 25px 15px 25px 0;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 900;
    background: #0080ff;
  }

  .filters-list {
    list-style: none;
    margin-left: -5px;
    padding-left: 0;

    li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: baseline;
    }

    .icon-edit {
      cursor: pointer;
    }

    .filter-type {
      color: #4a4a4a;
      font-size: 16px;
      font-weight: 900;
      line-height: 22px;
      white-space: nowrap;
    }

    .filter-description {
      padding-left: 5px;
      color: #484748;
      font-size: 16px;
      line-height: 25px;
      font-weight: 500;
    }

    .filter-edit {
      margin-right: 9px;

      ::v-deep .el-radio-group {
        margin-bottom: 25px;
      }
    }
  }

  .filter-edit {
    ::v-deep .el-radio-group {
      margin-bottom: 30px;
    }
  }

  /************************* */
  .filter-mobile {
    position: fixed;
    top: 74px;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 40px 30px 25px;
    background-color: #e7ebf0;
    overflow-y: auto;
    z-index: 10;

    &-container {
      height: 26px;

      ::v-deep .filter-item {
        margin-bottom: 15px;
      }
    }
    &_actions {
      text-align: center;

      .el-button {
        &:first-child {
          margin-top: 10px;
        }
      }
    }
  }
  .close {
    position: absolute;
    top: 20px;
    right: 12px;
    width: 26px;
    height: 26px;
    padding: 0;
    margin-right: 0;
    transition: none;

    i {
      margin-right: 0;
      color: #7d89a6;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .toggle-mobile-filter {
    position: fixed;
    top: 9px;
    right: 15px;
    width: 26px;
    height: 26px;
    padding: 0;
    margin-right: 0;
    z-index: 99;
  }
}
</style>
