import { prop, last, isEmpty } from 'ramda'
import * as API from '@/api'
import { DateTime } from 'luxon'
const dot = require('dot-object')

const SET_LOADING_MESSAGES = 'load_messages'
const SET_ALL_LOADED = 'set_all_loaded'
const ADD_OLDER_MESSAGES = 'add_older_messages'
const ADD_NEW_MESSAGE = 'add_new_message'
const SET_SENDING_MESSAGE = 'set_sending_message'
const CLEAR_QUICK_REPLIES = 'clear_quick_replies'
const SET_LOCATION = 'set_location'

const clearQuickReplies = (message) => {
  if (message.payload.messages instanceof Array) {
    message.payload.messages = message.payload.messages.filter(
      (m) => m.message !== 'quickReplies',
    )
  }
  return message
}

const setMessageDate = (message) => {
  if (!message.created_at) {
    const now = DateTime.utc()
    message.day = now.toISODate()
    message.date = now
    return
  }
  const date = DateTime.fromISO(message.created_at)
  message.day = date.toISODate()
  message.date = date
}
const initState = () => ({
  is_loading: false,
  all_loaded: false,
  messages: [],
  location: {},
  sending_message: false,
})

export default {
  namespaced: true,
  state: initState(),

  getters: {
    getMessages: (state) => state.messages,
    loading: (state) => state.is_loading,
    allLoaded: (state) => state.all_loaded,
    getLocation: (state) => state.location,
  },

  mutations: {
    [SET_LOADING_MESSAGES]: (state, flag) => {
      state.is_loading = flag
    },

    [SET_SENDING_MESSAGE]: (state, flag) => {
      state.sending_message = flag
    },

    [ADD_OLDER_MESSAGES]: (state, messages) => {
      state.messages.push(
        ...messages.map((message) => {
          message.payload = dot.object(message.payload)
          setMessageDate(message)
          return clearQuickReplies(message)
        }),
      )
    },

    [CLEAR_QUICK_REPLIES]: (state) => {
      state.messages.length > 0 && clearQuickReplies(state.messages[0])
    },

    [ADD_NEW_MESSAGE]: (state, message) => {
      setMessageDate(message)
      state.messages = [message, ...state.messages]
    },

    [SET_ALL_LOADED]: (state, flag) => {
      state.all_loaded = flag
    },

    [SET_LOCATION]: (state, location) => {
      state.location = location
    },

    clearMessages(state) {
      state.messages = []
    },
    resetState(state) {
      Object.assign(state, initState())
    },
  },

  actions: {
    async addMessage({ commit }, message) {
      await commit(ADD_NEW_MESSAGE, message)
    },
    async setLocation({ commit }, location) {
      await commit(SET_LOCATION, location)
    },
    async loadMessages(
      {
        commit,
        state,
        rootState: {
          customers: { currentCustomerId },
        },
      },
      payload,
    ) {
      if (state.all_loaded) return
      await commit(SET_LOADING_MESSAGES, true)
      const id = prop('id', last(state.messages)) - 1 || undefined
      try {
        const limit = 15
        const { data } = await API.chat.getMessages({
          customerId: currentCustomerId,
          id,
          limit,
        })
        if (!data || isEmpty(data)) {
          await commit(SET_ALL_LOADED, true)
          return
        }
        if (prop('initLoad', payload)) {
          commit('clearMessages')
        }
        await commit(ADD_OLDER_MESSAGES, data)
        if (data.length < limit) {
          await commit(SET_ALL_LOADED, true)
        }
      } catch (err) {
        console.error(`error while loading messages starting with ${id}`)
      } finally {
        await commit(SET_LOADING_MESSAGES, false)
      }
    },

    async sendMessage(
      {
        commit,
        rootState: {
          user: { user },
          customers: { currentCustomerId },
        },
      },
      { message, silent, resetContexts },
    ) {
      await commit(SET_SENDING_MESSAGE, true)
      try {
        await commit(CLEAR_QUICK_REPLIES)
        if (!silent) {
          await commit(ADD_NEW_MESSAGE, { payload: { message } })
        }
        if (resetContexts) {
          await API.chat.resetContexts(currentCustomerId)
        }
        const reply = await API.chat.sendMessage({
          customerId: currentCustomerId,
          message,
          silent,
        })
        await commit(ADD_NEW_MESSAGE, reply)
        return reply
      } catch (error) {
        console.error(`error while sending message : ${message}`)
        console.error(error)
      } finally {
        await commit(SET_SENDING_MESSAGE, false)
      }
    },
  },
}
