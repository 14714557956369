<template lang="pug">
  .location-control.control(v-if='showControl')
    .control-buttons(:class="{'control-buttons-ipad': isIpad}")
      el-button.button-control(v-if='locations.data && locations.data[0]', size='medium', round, @click='selectLocation(0)') {{ locations.data[0].address }}
      el-button.button-control(v-if='locations.data && locations.data[1]', size='medium', round, @click='selectLocation(1)') {{ locations.data[1].address }}
      el-button.button-control(size='medium', round, @click='openLocationModal()') Show More
    el-dialog.location-modal(title='Select a location',
                            custom-class="select-location-dialog",
                            :visible.sync='isLocationModalVisible',
                            :append-to-body='true',
                            top='40px',
                            width='540px')
      .location-control_search
        el-input(v-model='query', prefix-icon='el-icon-search', placeholder='Search')
      .location-control_list
        el-button.location-control_list-item(v-for='(location, index) in filteredLocations',
                                            :key='index',
                                            type='primary',
                                            plain,
                                            @click='selectLocation(index)')
          span.location-address {{ location.address }}
          span.location-state {{ location.city }}, {{ removeCountry(location.state) }}
          span.location-name {{ location.name }}
</template>

<script>
import { chat } from '@/api'
import { filter, contains, values, join, pathOr } from 'ramda'
import { mapActions, mapState } from 'vuex'
import scrollToBottom from '@/mixins/scrollToBottom'
import { sendEventToChatbase } from '../../../../api/stats'

export default {
  mixins: [scrollToBottom],
  data() {
    return {
      isLocationModalVisible: false,
      showControl: false,
      query: '',
      locations: [],
    }
  },
  watch: {
    isLocationModalVisible(value) {
      if (value) {
        this.$emit('blockChat')
        document.body.classList.add('disable-scroll')
      } else {
        document.body.classList.remove('disable-scroll')
        this.$emit('unblockChat')
      }
    },
  },
  beforeDestroy() {
    document.body.classList.remove('disable-scroll')
    this.$emit('unblockChat')
  },
  computed: {
    filteredLocations() {
      return filter((location) => {
        return contains(
          this.query.toLowerCase(),
          join(
            ' ',
            values({ ...location, ...(location.payload || {}) }),
          ).toLowerCase(),
        )
      }, this.locations.data)
    },
    ...mapState({
      currentCustomerId: pathOr('', ['customers', 'currentCustomerId']),
    }),
    isIpad() {
      return this.$deviceInfo.deviceOS === 'ipad'
    },
  },
  mounted() {
    this.getLocations()
  },
  methods: {
    ...mapActions({ setLocation: 'chat/setLocation' }),
    selectLocation(index) {
      this.setLocation(this.filteredLocations[index])
      this.$emit('selectLocation', this.filteredLocations[index].address)
      this.scrollOnBottom = true
    },
    async getLocations() {
      this.locations = await chat.getLocations({
        customerId: this.currentCustomerId,
      })
      this.showControl = true
      this.scrollOnBottom = true
    },
    openLocationModal() {
      sendEventToChatbase(
        this.currentCustomerId,
        'overlay',
        'select_location',
      ).catch((err) => {
        console.error('CHATBASE ERROR: ', err)
      })

      this.isLocationModalVisible = !this.isLocationModalVisible
    },
    removeCountry(state) {
      return state ? state.replace(/^.*-/g, '') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../../styles/_utilities';

.location-control {
  overflow-x: auto;
}

.control-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .button-control {
    border-radius: 40px;
  }
}

.control-buttons .el-button {
  min-width: auto;
  max-width: 100%;
  @include respond-to($phone) {
    margin-right: 0;
  }
}

.location-control_search {
  padding: 0 20px 20px;

  @include respond-to($phone) {
    padding-right: 20px;
  }
}
.location-control_list {
  padding: 0 16px 20px 20px;
  max-height: 58vh;
  -webkit-overflow-scrolling: touch;

  @include respond-to($phone) {
    max-height: calc(100vh - 153px);
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;
    padding-bottom: 200px;
  }

  .el-button {
    border-color: #2893f9;
  }

  @extend %scrollbar;
}
.location-control_list-item {
  display: block;
  width: 100%;
  min-height: 72px;
  background-color: transparent;

  + .location-control_list-item {
    margin: 10px 0 0;
  }
}
.location-address {
  color: #2c2e34;
}
.location-state {
  margin-top: 8px;
  color: #2c2e34;
  font-size: 12px;
}

.location-name {
  margin-top: 8px;
  color: #7d89a6;
  font-size: 10px;
}
</style>

<style lang="scss">
@import './../../../../styles/_utilities';

.location-control_list {
  .el-button {
    display: flex;
    padding: 8px 0;
    align-items: center;

    > span {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      text-align: left;
      width: 100%;
      height: 54px;
      padding: 0 28px;
    }
    &:hover {
      > span > span {
        color: #fff;
      }
    }
  }
}
.location-control_search {
  .el-input {
    @include placeholder(#909399, 14px, 400);
  }
  .el-input__inner {
    @include respond-to($phone) {
      height: 35px;
      min-height: 35px;
    }
  }
  .el-input__icon {
    color: #909399;

    @include respond-to($phone) {
      line-height: 35px;
    }
  }
}
.location-modal {
  .el-dialog__header {
    padding: 40px 40px 0 40px;

    @include respond-to($phone) {
      padding: 30px 30px 0 30px;
    }
  }
  .el-dialog__title {
    color: #273a58;
    font-size: 24px;
    font-weight: 700;

    @include respond-to($phone) {
      font-size: 28px;
    }
  }
  .el-dialog__headerbtn {
    top: 42px;
    right: 38px;

    @include respond-to($phone) {
      top: 20px;
      right: 25px;
    }
  }
  .el-dialog__body {
    padding: 20px 15px;

    @include respond-to($phone) {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  @include respond-to($phone) {
    .el-dialog {
      width: 100% !important;
      height: 100vh;
      margin: 0 !important;
      border-radius: 0;
      overflow: hidden;
    }
  }
}
</style>
