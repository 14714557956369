import * as API from '@/api'
import _ from 'lodash'
import { DateTime } from 'luxon'
import * as R from 'ramda'

const REQUEST_PAYMENTS = 'REQUEST_PAYMENTS'
const RECEIVE_PAYMENTS = 'RECEIVE_PAYMENTS'
const REQUEST_DATES = 'REQUEST_DATES'
const RECEIVE_DATES = 'RECEIVE_DATES'
const APPEND_PAYMENTS = 'APPEND_PAYMENTS'
const SET_PARAMS = 'SET_PARAMS'
const SELECT_DATE = 'SELECT_DATE'

const initialState = () => ({
  isFetching: false,
  list: [],
  dates: [],
  params: {
    direction: '-',
    // batchStatus: 'COMPLETE',
    startDate: DateTime.utc().toISODate(),
    period: 12,
  },
  selectedDate: '',
})

const detectGaps = (data) => {
  const newData = data.slice()
  const gaps = []
  newData.forEach((item, index) => {
    if (!newData[index + 1]) return
    const currentTime = DateTime.fromFormat(item.title, 'MMMM yyyy')
    const nextTime = DateTime.fromFormat(newData[index + 1].title, 'MMMM yyyy')
    const diff = currentTime.diff(nextTime, 'months')

    const { months } = diff

    if (months > 1) {
      gaps.push({
        index: index + 1,
        type: 'gap',
        paymentFileDate: currentTime,
        title: `${currentTime.minus({ months: 1 }).toFormat('MMMM yyyy')}${
          months > 2
            ? ` - ${nextTime.plus({ months: 1 }).toFormat('MMMM yyyy')}`
            : ''
        }`,
      })
    }
  })

  gaps.forEach((gap) => {
    newData.splice(gap.index, 0, gap)
  })

  return newData
}

export default {
  namespaced: true,
  state: initialState(),

  getters: {
    list: (state) => state.list,
    params: (state) => state.params,
    isFetching: (state) => state.isFetching,
    dates: (state) => state.dates,
    selectedDate: (state) => state.selectedDate,
  },

  mutations: {
    [REQUEST_DATES]: (state) => {
      state.isFetching = true
    },

    [RECEIVE_DATES]: (state, data) => {
      const dates = _.get(data, 'dates', [])
      const asc = (a, b) => (a < b ? -1 : 1)
      state.dates = R.sort(asc, dates)
      state.selectedDate = _.last(dates)
    },

    [REQUEST_PAYMENTS]: (state) => {
      state.isFetching = true
    },

    [RECEIVE_PAYMENTS]: (state, list) => {
      let l = list.sort((a, b) => {
        const monthA = DateTime.fromISO(String(a.paymentFileDate), {
          zone: 'utc',
        })
        const monthB = DateTime.fromISO(String(b.paymentFileDate), {
          zone: 'utc',
        })

        return monthA > monthB ? -1 : 1
      })

      l = detectGaps(l)
      state.list = l
      state.isFetching = false
    },

    [APPEND_PAYMENTS]: (state, { list }) => {
      let l = _.uniqBy([...state.list, ...list], 'title').sort((a, b) => {
        const monthA = DateTime.fromISO(String(a.paymentFileDate), {
          zone: 'utc',
        })
        const monthB = DateTime.fromISO(String(b.paymentFileDate), {
          zone: 'utc',
        })

        return monthA > monthB ? -1 : 1
      })

      l = detectGaps(l)
      state.list = l
      state.isFetching = false
    },

    [SET_PARAMS]: (state, params) => {
      state.params = params
    },

    [SELECT_DATE]: (state, date) => {
      state.selectedDate = date
    },

    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async fetchPayments({
      commit,
      state,
      dispatch,
      rootState: {
        user: { user },
        customers: { currentCustomerId },
      },
    }) {
      await commit(REQUEST_PAYMENTS)
      try {
        const data = await API.payments.list({
          customerId: currentCustomerId,
          params: state.params,
        })
        await commit(RECEIVE_PAYMENTS, data)
      } catch (e) {
        await commit(RECEIVE_PAYMENTS, [])
        // TODO: Remove this once https codes will be correct
        const code = _.get(e, 'response.data.code', 500)
        if ([401, 403].includes(code)) {
          await dispatch('user/logout', null, { root: true })
        }
        return code
      }
    },

    async setParams({ commit, state }, params) {
      await commit(SET_PARAMS, params)
    },

    async appendPayments({
      commit,
      dispatch,
      state,
      rootState: {
        customers: { currentCustomerId },
      },
    }) {
      try {
        await commit(REQUEST_PAYMENTS)
        const data = await API.payments.list({
          customerId: currentCustomerId,
          params: state.params,
        })
        await commit(APPEND_PAYMENTS, {
          list: data,
          direction: state.params.direction,
        })
      } catch (e) {
        await commit(APPEND_PAYMENTS, { list: [] })
        // TODO: Remove this once https codes will be correct
        const code = _.get(e, 'response.data.code', 500)
        if ([401, 403].includes(code)) {
          await dispatch('user/logout', null, { root: true })
        }
      }
    },

    async getDatesList({
      commit,
      dispatch,
      rootState: {
        customers: { currentCustomerId },
      },
    }) {
      try {
        await commit(REQUEST_DATES)
        const data = await API.payments.datesList({
          customerId: currentCustomerId,
        })
        await commit(RECEIVE_DATES, data)
      } catch (e) {
        await commit(RECEIVE_DATES, {})
        // TODO: Remove this once https codes will be correct
        const code = _.get(e, 'response.data.code', 500)
        if ([401, 403].includes(code)) {
          await dispatch('user/logout', null, { root: true })
        }
      }
    },

    selectDate({ commit }, date) {
      commit(SELECT_DATE, date)
    },
  },
}
