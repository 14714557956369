<template lang="pug">
  .table-control_container
    .table-control_title {{ title }}
    table.table-control_table
      thead(v-if="showHeader")
        tr
          th(v-for="(header, index) in header",
            :key="index",
            :style="columnWidth(header['param'])")
            .cell {{ header.displayString }}
      tbody
        tr(v-for="(item, index) in tableData", :key="index" @click="handleRowClick(item)")
          td(v-for='(col, index) in item', :key='index', :class="col.class")
            .cell {{ getPrettyVendorByCode(col.value) }}
    .table-control_footer
      .data
        span(v-for="item in (Array.isArray(footer) ? footer : [footer])") {{ item }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [
        [
          { id: '9674', customer_id: '', value: 'CALPINEENERY', class: 'bold' },
          { id: '9674', customer_id: '', value: 'May 2 2018', class: 'bold' },
          {
            id: '9674',
            customer_id: '',
            value: '$7,341.47',
            class: 'bold blue',
          },
        ],
        [
          { id: '9644', customer_id: '', value: 'CALPINEENERY', class: 'bold' },
          { id: '9644', customer_id: '', value: 'May 31 2018', class: 'bold' },
          {
            id: '9644',
            customer_id: '',
            value: '$6,401.58',
            class: 'bold blue',
          },
        ],
      ],
      header: [
        { displayString: 'Vendor', param: 'vendor' },
        { displayString: 'Invoice Date', param: 'invoice_date' },
        { displayString: 'Amount', param: 'amount' },
      ],
      footer: ['Bill in May 2018, 1025 Gilman Street'],
    }
  },
  computed: {
    ...mapGetters({
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
    }),
    showHeader() {
      return true
    },
  },
  methods: {
    columnWidth(header) {
      if (header === 'state') {
        return 'width: 15%'
      }
      if (header === 'location') {
        return 'width: 55%'
      }
      return ''
    },
    handleRowClick(row) {
      this.$emit('row-click', row)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/utilities';

.table-control {
  &_title {
    font-size: 14px;
    line-height: 17px;
    font-weight: 900;
    color: #5d6a89;
    margin-bottom: 5px;
  }
  &_table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    @include respond-to($phone) {
      font-size: 12px;
    }

    tr {
      &.sam-table_action {
        cursor: pointer;

        &:hover {
          td {
            background-color: #f4f6fa;
          }
        }
      }
    }
    th {
      text-align: left;

      > .cell {
        color: #5d6a89;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
      }
    }
    td {
      min-width: 0;
      padding: 2.5px 0;
      vertical-align: middle;
      box-sizing: border-box;
      transition: background-color 0.25s ease;
    }
    .cell {
      color: #3b3e48;
      font-size: 13px;
      line-height: 20px;
      word-break: break-word;
    }
    .attribute {
      .cell {
        color: #9b9b9b;
      }
    }
    .meter-title {
      .cell {
        font-weight: 600;
        color: #5d6a89;
      }
    }
  }
  &_footer {
    .data {
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      > span {
        margin-top: 2px;
        font-size: 10px;
        line-height: 14px;
        font-weight: 500;
        color: #888888;
      }
    }
  }
}
</style>
