<template lang="pug">
  .content-inner
    .page-header
      .page-title(v-if="!$deviceInfo.isMobileDevice") Portfolio View
      .page-title-action
        create-widget-desktop(v-if="!$deviceInfo.isMobileDevice", @add-widget="addWidget")
        create-widget-mobile(v-else, @add-widget="addWidget")

    draggable(v-loading="loading",
              v-model="widgetsList",
              animation="200",
              class="widgets-container",
              ghost-class="ghost",
              group="widgets",
              handle=".handle",
              @change="handleDraggable")
      chart(v-for="widget in widgetsList",
          :key="widget.id",
          :widget="widget",
          @update-widget="updateWidget",
          @remove-widget="removeWidget(widget.id)")

    p.widgets-message(v-if="showNoWidgetsMsg") Your Portfolio View has no widgets. Click "Create widget" to create a new one.

</template>

<script>
import * as R from 'ramda'
import Draggable from 'vuedraggable'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import CreateWidgetDesktop from './CreateWidget/CreateWidgetDesktop.vue'
import CreateWidgetMobile from './CreateWidget/CreateWidgetMobile.vue'
import Chart from './Chart.vue'
import _ from 'lodash'

export default {
  name: 'app',
  components: {
    CreateWidgetDesktop,
    CreateWidgetMobile,
    Chart,
    Draggable,
  },
  data() {
    return {
      addWidgetModalVisible: false,
      loading: false,
    }
  },
  watch: {
    checkResources: {
      immediate: true,
      async handler(len) {
        if (len > 0) {
          await this.init()
        }
      },
    },
    async currentCustomerId(id) {
      await this.init()
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    ...mapGetters({
      listData: 'widgets/listData',
      checkResources: 'resources/checkResources',
    }),
    widgetsList: {
      get() {
        return this.listData
      },
      set(value) {
        this.setListData(value)
      },
    },
    showNoWidgetsMsg() {
      return !this.listData.length && !this.loading
    },
  },
  methods: {
    ...mapActions({
      setWidgetsTypes: 'widgets/setWidgetsTypes',
      setLocationFilters: 'locations/filters/setFilters',
      loadLocations: 'locations/loadLocations',
      loadHierarchy: 'locations/loadHierarchy',
      listWidgets: 'widgets/listWidgets',
      reorderWidgets: 'widgets/reorderWidgets',
      resourcesByName: 'resources/getResourceByName',
      patchWidget: 'widgets/patchWidget',
      createWidget: 'widgets/createWidget',
    }),
    ...mapMutations({
      setListData: 'widgets/setListData',
      resetState: 'widgets/resetState',
    }),
    async addWidget(newWidget) {
      const sortOrderMax = Math.max(...this.listData.map((e) => e.sort_order))

      try {
        const body = {
          ...newWidget,
          customerId: this.currentCustomerId,
          sortOrder: sortOrderMax >= 0 ? sortOrderMax + 1 : 1,
        }

        await this.createWidget({ customerId: this.currentCustomerId, body })
        this.scrollToBottom()
      } catch (e) {
        const err = _.get(e, 'response.data', {})
        if (!_.isEmpty(err) && (err || {}).code === 409) {
          this.showPopupErrorMessage(
            _.get(
              err,
              ['message'],
              'Sorry, something went wrong. Please try again.',
            ),
          )
        } else {
          this.showErrorMessage()
        }
      }
    },
    async updateWidget(widget) {
      try {
        await this.patchWidget({ customerId: this.currentCustomerId, widget })
      } catch (e) {
        console.error(e)
        this.showErrorMessage()
      }
    },
    async handleDraggable({ moved: { oldIndex, newIndex } }) {
      const start = newIndex > oldIndex ? oldIndex : newIndex
      const end = newIndex > oldIndex ? newIndex : oldIndex

      let pos = start + 1
      let originalOrder = []
      const order = this.widgetsList.slice(start, end + 1).map((e) => {
        originalOrder.push({
          id: e.id,
          sortOrder: pos++,
        })

        return {
          id: +e.id,
          sortOrder: pos++,
        }
      })

      try {
        await this.reorderWidgets({
          customerId: this.currentCustomerId,
          widgets: order,
        })
      } catch (e) {
        // TODO: Rollback
        console.error(e)
        this.showErrorMessage()
      }
    },
    showErrorMessage() {
      this.$message({
        type: 'error',
        message: 'Sorry, something went wrong. Please try again.',
      })
    },
    showPopupErrorMessage(message) {
      const h = this.$createElement
      this.$msgbox({
        title: 'Error',
        message: h('p', null, [h('span', null, message)]),
        confirmButtonText: 'OK',
        center: true,
      })
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight)
    },
    async init() {
      window.scroll(0, 0)
      this.resetState()
      await this.setWidgetsTypes(this.currentCustomerId)
      await this.listWidgets(this.currentCustomerId)
      this.setLocationFilters({ limit: 1000 })
      await this.loadLocations()
      await this.loadHierarchy()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/_utilities';

.content-inner {
  padding-right: 25px;
  padding-left: 25px;

  @include respond-to($phone) {
    padding: 12px 10px 0;
  }
  @include respond-to($phone-l) {
    padding: 12px 10px 0;
  }
}

.widgets-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 25px;

  @include respond-to($phone) {
    margin-top: 0;
  }
  @include respond-to($phone-l) {
    margin-top: 0;
  }
}

.widgets-message {
  color: #1c1c1c;
  font-size: 18px;
  line-height: 36px;
  font-weight: 500;

  @include respond-to($phone) {
    margin-top: 30vh;
    padding-right: 40px;
    padding-left: 40px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }
}

.ghost {
  opacity: 0.5;
}
</style>
