import { hosts } from '@/lib/api.configs'
import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'

const getCommoditiesForLocation = ({ customerId, locationId }) =>
  http(hosts.v3)
    .get(`/commodities/${customerId}/location/${locationId}`)
    .then(dataMiddleware)

export { getCommoditiesForLocation }
