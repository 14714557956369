import { render, staticRenderFns } from "./BudgetSettingsTab.vue?vue&type=template&id=4531f383&scoped=true&"
import script from "./BudgetSettingsTab.vue?vue&type=script&lang=js&"
export * from "./BudgetSettingsTab.vue?vue&type=script&lang=js&"
import style0 from "./BudgetSettingsTab.vue?vue&type=style&index=0&id=4531f383&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4531f383",
  null
  
)

export default component.exports