var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[(_vm.scheduledView)?_c('filter-container',{attrs:{"filters":_vm.filtersValues}}):_vm._e(),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.scheduledReports},on:{"sort-change":_vm.sortTable}},[_c('el-table-column',{attrs:{"prop":"customReportName","label":"Report Name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.customReportName)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"title","label":"Report Type","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.reportTemplate.title)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"saveFormat","label":"Format","sortable":"","min-width":50},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.saveFormat.toUpperCase())+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"emailRecipientsFlat","label":"Email","sortable":"","min-width":50},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.hasEmailRecipients(row)))])]}}])}),_c('el-table-column',{attrs:{"prop":"sftpConnectionName","label":"SFTP","sortable":"","min-width":50},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.getSftpStatus(row) === _vm.sftpConnectionStatus.ACTIVE)?_c('p',[_vm._v("Yes")]):(_vm.getSftpStatus(row) === _vm.sftpConnectionStatus.NONE)?_c('p',[_vm._v("-")]):(_vm.getSftpStatus(row) === _vm.sftpConnectionStatus.DELETED)?_c('el-popover',{attrs:{"placement":"top","width":"260","trigger":"hover","content":"The SFTP Location for this Report was deleted. Please update the Delivery Method with a new SFTP Location."}},[_c('el-button',{staticClass:"sftp-warn",attrs:{"slot":"reference","type":"text","icon":"el-icon-warning"},slot:"reference"})],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"recurringType","label":"Schedule","sortable":"","min-width":100},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.transformToSchedule(row.recurringType, row.time))+" ")]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"play-btn btn-size",attrs:{"type":"text","icon":"el-icon-video-play"},on:{"click":function($event){return _vm.runNowScheduledReports({
              customerId: row.customerId,
              scheduledReportId: row.id,
              reportName: row.customReportName,
            })}}}),_c('el-button',{staticClass:"edit-btn btn-size",attrs:{"type":"text","icon":"el-icon-edit"},on:{"click":function($event){return _vm.openCustomizeReportModal(row)}}}),_c('el-button',{staticClass:"close-btn btn-size",attrs:{"type":"text","icon":"el-icon-close"},on:{"click":function($event){return _vm.deleteScheduledReports({
              customerId: row.customerId,
              scheduledReportId: row.id,
              reportName: row.customReportName,
              limit: _vm.$route.query.limit,
              offset: _vm.$route.query.offset,
            })}}})]}}])})],1),_c('customize-report-modal',{attrs:{"visible":_vm.showCustomizeReportModal,"report":_vm.currentReport,"update":true},on:{"update:visible":function($event){_vm.showCustomizeReportModal=$event},"close":function($event){return _vm.closeCustomizeReportModal()},"change-filters":function($event){return _vm.updateScheduledRep($event)}}}),(_vm.total > _vm.pagination.page_sizes[0])?_c('el-pagination',{staticClass:"pagination-container",attrs:{"background":"","layout":"prev, pager, next, sizes","page-size":_vm.pagination.limit,"page-sizes":_vm.pagination.page_sizes,"total":_vm.total,"current-page":_vm.currentPage},on:{"current-change":_vm.changeOffset,"size-change":_vm.changeLimit}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }