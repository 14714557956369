<template lang="pug">
  .content-inner
    hierarchy
</template>

<script>
import Hierarchy from './Hierarchy/Hierarchy'
import { mapActions } from 'vuex'

export default {
  components: {
    Hierarchy,
  },
  data() {
    return {}
  },
  async beforeMount() {
    this.setLocationFilters({ limit: 1000 })
    await this.loadLocations()
    await this.loadHierarchy()
  },
  methods: {
    ...mapActions({
      setLocationFilters: 'locations/filters/setFilters',
      loadLocations: 'locations/loadLocations',
      loadHierarchy: 'locations/loadHierarchy',
    }),
  },
}
</script>

<style lang="scss" scoped>
.content-inner {
  padding-right: 40px;
  padding-left: 40px;
}
</style>
