<template>
  <el-row>
    <el-col :span="spanDimensions">
      <h3>Sustainability Attributes</h3>
    </el-col>
    <el-col v-if="!isEditMode" :span="2">
      <el-button
        class="btn"
        type="text"
        icon="el-icon-edit"
        @click="changeToEditMode"
      >
        Edit
      </el-button>
    </el-col>
    <el-col v-else :span="4">
      <el-button class="btn" type="text" @click="cancelChanges">
        Cancel
      </el-button>
      <el-button class="btn" type="primary" @click="updateSustainabilityFields">
        Save
      </el-button>
    </el-col>
    <el-form
      :model="sustModel"
      ref="sustFormRef"
      label-position="left"
      hide-required-asterisk
    >
      <el-col
        :span="24"
        v-for="sustField in sustainabilityForm"
        :key="sustField.prop"
      >
        <el-form-item
          v-if="isSelect(sustField)"
          :prop="sustField.prop"
          :label-width="getLabelWidth(sustField)"
          :rules="sustField.rule"
          :label="sustField.label"
        >
          <template #label>
            <label class="label-style">{{ sustField.label }}</label>
          </template>
          <div v-if="isEditMode">
            <form-required-asterisk class="rq-star" v-if="sustField.rule" />
            <el-select
              :value="sustModel[sustField.prop]"
              :placeholder="sustField.placeholder"
              size="small"
              @change="handleChangeFieldVal($event, sustField.prop)"
              filterable
              clearable
            >
              <el-option
                v-for="option in sustField.options"
                :key="option.name || option"
                :value="option.value || option.name || option"
                :label="
                  option.name
                    ? getLabel(sustField, option.name)
                    : getLabel(sustField, option)
                "
                :disabled="getDisabledStateOptions(sustField, option.type)"
              />
            </el-select>
            <el-popover
              v-show="sustField.info"
              placement="top-start"
              width="200"
              trigger="hover"
              :content="sustField.info"
            >
              <el-button
                type="text"
                slot="reference"
                class="info-style"
                icon="el-icon-info"
              />
            </el-popover>
          </div>
          <p class="non-edit" v-else>{{ readonlyVal(sustField.prop) }}</p>
        </el-form-item>
        <el-form-item
          v-if="isInput(sustField)"
          :prop="sustField.prop"
          label-width="250px"
          :label="sustField.label"
        >
          <template #label>
            <label class="label-style">{{ sustField.label }}</label>
          </template>
          <div v-if="isEditMode">
            <el-input
              style="width: 200px"
              :value="sustModel[sustField.prop]"
              @input="handleChangeFieldVal($event, sustField.prop)"
              size="small"
              :disabled="getDisabledState(sustField.prop)"
            />
          </div>
          <p class="non-edit" v-else>
            {{ sustModel[sustField.prop] || 'N/A' }}
          </p>
        </el-form-item>
        <el-form-item
          v-if="isDateTime(sustField)"
          :prop="sustField.prop"
          label-width="250px"
          :label="sustField.label"
        >
          <template #label>
            <label class="label-style">{{ sustField.label }}</label>
          </template>
          <div v-if="isEditMode">
            <el-date-picker
              style="width: 200px"
              v-model="sustModel[sustField.prop]"
              format="MM/dd/yyyy"
              value-format="yyyy-MM-dd"
              size="small"
              :placeholder="sustField.placeholder"
              :disabled="getDisabledState(sustField.prop)"
            />
          </div>
          <p class="non-edit" v-else>
            {{ sustModel[sustField.prop] | datetime('MM/dd/yyyy') || 'N/A' }}
          </p>
        </el-form-item>
      </el-col>
    </el-form>
    <sustainability-modal :visible="showModal" @close="showModal = false" />
  </el-row>
</template>

<script>
import BaseSelect from '../../../../../components/FormComponents/BaseSelect'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'
import FormRequiredAsterisk from '../../../../../components/FormComponents/FormRequiredAsterisk'
import SustainabilityModal from '../../Modals/SustainabilityModal/SustainabilityModal'
export default {
  components: { SustainabilityModal, FormRequiredAsterisk, BaseSelect },
  data() {
    return {
      isEditMode: false,
      showModal: false,
    }
  },
  mounted() {
    const { customerId, locationId } = this.$route.params
    this.getSustainabilityData({ customerId, locationId })
  },
  computed: {
    ...mapGetters({
      sustainabilityForm: 'locationSustainability/sustainabilityForm',
      sustModel: 'locationSustainability/sustModel',
    }),
    spanDimensions() {
      return this.isEditMode ? 20 : 22
    },
    isSelect() {
      return (sustField) => sustField.elementType === 'select'
    },
    isInput() {
      return (sustField) => sustField.elementType === 'input'
    },
    isDateTime() {
      return (sustField) => sustField.elementType === 'date'
    },
    getLabel() {
      return (sustField, option) =>
        sustField.prop === 'companyOwnedAsset'
          ? this.capitalize(option)
          : option
    },
    getLabelWidth() {
      return (sustField) =>
        sustField.rule && this.isEditMode ? '240px' : '250px'
    },
    getDisabledState() {
      return (prop) => {
        return (
          (prop === 'leaseId' || prop === 'leaseExpirationDate') &&
          (_.isNil(this.sustModel.companyOwnedAsset) ||
            this.sustModel.companyOwnedAsset === 'No')
        )
      }
    },
    getDisabledStateOptions() {
      return (sustField, type) => {
        if (sustField.prop === 'assetType') {
          return this.sustModel.recordType !== type
        }
      }
    },
    readonlyVal() {
      return (prop) => {
        return _.isNil(this.sustModel[prop]) || this.sustModel[prop] === ''
          ? 'N/A'
          : this.sustModel[prop]
      }
    },
  },
  methods: {
    ...mapMutations({
      setChangedFieldVal: 'locationSustainability/setChangedFieldVal',
      setFieldValsOnEdit: 'locationSustainability/setFieldValsOnEdit',
      resetState: 'locationSustainability/resetState',
    }),
    ...mapActions({
      createSustainabilityAttrs:
        'locationSustainability/createSustainabilityAttrs',
      getSustainabilityData: 'locationSustainability/getSustainabilityData',
    }),
    changeToEditMode() {
      this.setFieldValsOnEdit()
      this.isEditMode = !this.isEditMode
    },
    updateSustainabilityFields() {
      const { customerId, locationId } = this.$route.params
      this.$refs.sustFormRef.validate((valid) => {
        if (!valid) {
          this.showModal = true
          return
        }
        this.createSustainabilityAttrs({ customerId, locationId })
        this.changeToEditMode()
      })
    },
    handleChangeFieldVal(value, prop) {
      this.$refs.sustFormRef.validateField(prop)
      this.setChangedFieldVal({ prop, value: value })
      if (prop === 'recordType') {
        this.setChangedFieldVal({
          prop: 'assetType',
          value:
            value === 'Building'
              ? 'Commercial Building'
              : value === 'General'
                ? 'Air Travel'
                : 'Fleet Vehicle',
        })
      }
    },
    cancelChanges() {
      const { customerId, locationId } = this.$route.params
      this.$refs.sustFormRef.clearValidate()
      this.getSustainabilityData({ customerId, locationId })
      this.changeToEditMode()
    },
    capitalize(str) {
      return _.startCase(str)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  font-weight: 600;
  width: 106px;
}
.label-style {
  font-weight: 600;
  color: #4a4a4a;
}
.non-edit {
  margin: 0;
  color: #273a58;
  font-weight: 600;
}
.info-style {
  color: #0080ff;
  margin-left: 10px;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-form-item__error {
  padding-left: 10px;
  padding-top: 2px;
}
.rq-star {
  margin-right: 5px;
}
::v-deep .el-icon-edit {
  &:before {
    font-weight: 600;
  }
}
</style>
