<template>
  <BaseModal
    :title="title"
    :dialogVisible="showModal"
    @close="close"
    @open="init"
    :isLeft="true"
    classes="footer-width"
    :loading="loading"
    :width="'80%'"
    id="energyStarMetersModal"
  >
    <template #content>
      <div v-loading="loading">
        <ErrorCard :statusMessage="createError" />
        <CreateMeters
          :innerVisible="innerVisible"
          @close="innerVisible = false"
          :propertyId="propertyId"
          :appendToBody="true"
          @close-and-create="createMetersAndRefresh"
        />
        <p class="info">
          This screen shows your ENERGY STAR® meters and the Pear.ai Virtual
          Accounts at this location mapped to each one.
        </p>
        <div class="subtitle-container">
          <h2 class="subtitle">ENERGY STAR® Meters</h2>
          <el-button
            icon="el-icon-plus"
            type="text"
            @click="innerVisible = true"
            id="createMeterBtn"
          >
            Create
          </el-button>
        </div>
        <el-collapse
          v-model="activeName"
          accordion
          v-if="energyStarMeters.meters.length > 0"
        >
          <el-collapse-item
            v-for="(v, i) of energyStarMeters.meters"
            :title="v.name"
            :name="i"
            :key="v.id"
          >
            <template slot="title">
              <span class="bold meter-name">{{ v.name.toUpperCase() }}</span>
              <span class="virtualAccounts">
                <span class="paranthesis">(</span>
                {{ virtualAccTitle(v) }}
                <span class="paranthesis">)</span>
              </span>
              <el-button
                class="text edit-meters-details-btn"
                type="text"
                @click="openEditMetersModal(v.id, v.name)"
              >
                Edit Details
              </el-button>
            </template>
            <el-table
              v-if="v.virtualAccounts.length > 0"
              :data="v.virtualAccounts"
              class="full-form mapped-accounts-table"
            >
              <el-table-column
                prop="meterSerial"
                label="Meter ID"
                width="180"
              />
              <el-table-column prop="vendorCode" label="Vendor" width="180" />
              <el-table-column prop="lastBillId" label="Billing ID" />
              <el-table-column prop="accountCode" label="Service Account ID" />
              <el-table-column prop="commodity" label="Utility" />
              <el-table-column prop="billType" label="Bill Type" />
              <el-table-column prop="lastBill" label="Last Bill">
                <template slot-scope="scope">
                  <span>{{ scope.row.lastBill | datetime('LL/dd/yyyy') }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="isEditMode">
                <template slot-scope="scope">
                  <el-button
                    class="unmap-meter-btn"
                    type="text"
                    @click="unmap(scope.row.id, v.id)"
                  >
                    Unmap
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-collapse-item>
        </el-collapse>
        <div v-else>
          There are no ENERGY STAR® Meters created. Please create an ENERGY
          STAR® Meter and associate one or more Pear.ai Virtual Accounts to it.
        </div>
        <h2 class="subtitle">Unassigned Accounts</h2>
        <el-table
          :data="energyStarMeters.unassignedVirtualAccounts"
          class="full-form"
          id="unassignedAccountsTable"
        >
          <el-table-column prop="meterSerial" label="Meter ID" width="180" />
          <el-table-column prop="vendorCode" label="Vendor" width="180" />
          <el-table-column prop="lastBillId" label="Billing ID" />
          <el-table-column prop="accountCode" label="Service Account ID" />
          <el-table-column prop="commodity" label="Utility" />
          <el-table-column prop="billType" label="Bill Type" />
          <el-table-column prop="lastBill" label="Last Bill">
            <template slot-scope="scope">
              <span>{{ scope.row.lastBill | datetime('LL/dd/yyyy') }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="isEditMode">
            <template slot-scope="scope">
              <el-button
                class="text map-meter-btn"
                type="text"
                @click="map(scope.row.id, scope.row.commodity)"
              >
                Map
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <template #footer>
      <el-button
        class="button-size"
        id="editMeterAssocBtn"
        :disabled="disableEditMeterAsoc"
        type="primary"
        @click="isEditMode = !isEditMode"
      >
        Edit Meter Associations
      </el-button>
      <el-button
        class="button-size"
        id="closeMeterAssocBtn"
        type="primary"
        @click="close"
      >
        Close
      </el-button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import { ValidationObserver } from 'vee-validate'
import BaseInput from '@/components/FormComponents/BaseInput'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import BaseDatePicker from '@/components/FormComponents/BaseDatePicker'
import CreateMeters from './CreateMeters'
import ErrorCard from '@/components/Cards/ErrorCard'
import { isEmpty } from 'lodash'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    customerFDGCode: {
      type: String,
    },
    width: {
      type: String,
      default: '',
    },
    propertyId: {
      type: Number,
      default: 0,
    },
    propertyName: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    createError: {
      type: String,
      default: '',
    },
    energyStarMeters: {
      type: Object,
      default: () => {
        return {
          meters: [],
          unassignedVirtualAccounts: [],
        }
      },
    },
  },
  components: {
    BaseModal,
    BaseInput,
    BaseSelect,
    CreateMeters,
    BaseDatePicker,
    ErrorCard,
    ValidationObserver,
  },
  data() {
    return {
      showModal: false,
      title: '',
      activeName: '0',
      innerVisible: false,
      isEditMode: false,
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  computed: {
    disableEditMeterAsoc() {
      const lengthCheckArr = this.energyStarMeters.meters.map(
        (meter) => meter.virtualAccounts.length > 0,
      )
      const falseCheck = lengthCheckArr.includes(true)
      if (falseCheck) {
        return false
      }
      if (
        this.energyStarMeters.meters.length !== 0 &&
        this.energyStarMeters.unassignedVirtualAccounts.length !== 0
      ) {
        return false
      }
      return true
    },
  },
  methods: {
    close() {
      this.isEditMode = false
      this.$emit('close', false)
    },
    openMetersModal() {
      this.$emit('open-meters')
    },
    init() {
      this.title = `ENERGY STAR® Meter Associations for ${this.propertyName}`
      if (isEmpty(this.energyStarMeters)) {
        this.getMeters(true)
      }
    },
    async getMeters(forceReload = false) {
      this.$emit('get-meters', forceReload)
    },
    openEditMetersModal(id, name) {
      const meterId = id
      const meterName = name
      this.$emit('open-edit-meters', { meterId, meterName })
    },
    createMetersAndRefresh() {
      this.innerVisible = false
      this.getMeters(true)
    },
    async map(id) {
      this.$emit('map-virtual-acc', id)
    },
    virtualAccTitle(meter) {
      const { switchCommoditiesCase } = this.$options.filters
      if (meter.virtualAccounts.length === 0) {
        return `${meter.virtualAccounts.length} accounts`
      }
      if (meter.virtualAccounts.length === 1) {
        return `${meter.virtualAccounts.length} ${switchCommoditiesCase(
          meter.type,
        )} account`
      }
      return `${meter.virtualAccounts.length} ${switchCommoditiesCase(
        meter.type,
      )} accounts`
    },
    async unmap(id, meterId) {
      this.$emit('unmap-virtual-acc', { id, meterId })
    },
  },
}
</script>

<style lang="scss" scoped>
.button-size {
  width: 40%;
}
.plain-button {
  color: #2893f9;
  border-color: #2893f9;
}
.message {
  margin: 0;
  text-align: center;
  word-break: break-word;
}
.info {
  position: absolute;
  top: 40px;
  font-weight: 400;
  color: #2c2e34;
  line-height: 16px;
}
.subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 1em 0 1em 0;
  display: flex;
  align-items: center;
  color: #2c2e34;
}
.el-button[class*='text'] {
  padding: 0;
}
.bold {
  font-weight: bold;
}
.subtitle-container {
  display: flex;
  justify-content: space-between;
}
.paranthesis {
  font-weight: 900;
  color: black;
}
.virtualAccounts {
  font-weight: bold;
  color: #409eff;
  padding-left: 10px;
  padding-right: 10px;
}
.full-form {
  width: 100%;
}
</style>
