<template>
  <el-form class="filters-container" :inline="true" label-position="top">
    <div class="filters">
      <div class="select">
        <base-select
          :value="filtersVal.type"
          @change="changeType"
          :isEditMode="true"
          placeholder="All Reports"
          multiple
          clearable
          label="Report Type"
          size="mini"
        >
          <el-option
            v-for="(reportType, i) in filters.types"
            :key="`${reportType}${i}`"
            :value="reportType.type"
            :label="reportType.name"
          />
        </base-select>
        <base-select
          :value="filtersVal.format"
          @change="changeFormat"
          :isEditMode="true"
          placeholder="All Formats"
          multiple
          clearable
          label="Format"
          size="mini"
          class="format-base-select"
        >
          <el-option
            v-for="(reportFormat, i) in filters.formats"
            :key="`${reportFormat}${i}`"
            :value="reportFormat"
            :label="reportFormat.toUpperCase()"
          />
        </base-select>
      </div>
      <div v-if="completedPage" class="date-pickers-container">
        <base-date-picker
          :value="filtersVal.createdAtMin"
          :isEditMode="true"
          :onPick="changeStartDate"
          :format="'yyyy-MM-dd'"
          placeholder="Start Month"
          multiple
          label="Creation Date"
          size="mini"
        />
        <div class="separator">to</div>
        <base-date-picker
          v-model="filtersVal.createdAtMax"
          :isEditMode="true"
          style="padding: 8px 0"
          placeholder="End Month"
          multiple
          :onPick="changeEndDate"
          :format="'yyyy-MM-dd'"
          size="mini"
        />
      </div>
    </div>
    <div class="search">
      <base-input
        suffixIcon="el-icon-search"
        :value="name"
        @input="changeSearchName"
        :isEditMode="true"
        size="small"
        placeholder="Search by Report Name"
      />
    </div>
  </el-form>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import BaseInput from '@/components/FormComponents/BaseInput'
import BaseDatePicker from '@/components/FormComponents/BaseDatePicker'

export default {
  components: { BaseSelect, BaseInput, BaseDatePicker },
  props: ['filters'],
  data() {
    return {
      type: '',
      format: '',
      name: '',
      startDate: '',
      endDate: '',
    }
  },
  mounted() {
    this.debounceChangeFilters = debounce(this.changeFilters, 500)
  },
  computed: {
    ...mapGetters({
      filtersVal: 'reportFilters/filters',
    }),
    completedPage() {
      return this.$route.params.tab === 'completed'
    },
    tab() {
      if (this.$route.params.tab === 'completed') {
        return 'completed'
      }
      return 'scheduled'
    },
  },
  methods: {
    ...mapActions({
      changeFilters: 'reportFilters/changeFilters',
    }),
    changeType(val) {
      this.changeFilters({
        customerId: this.$route.params.customerId,
        params: { type: val },
        tab: this.tab,
      })
    },
    changeFormat(val) {
      this.changeFilters({
        customerId: this.$route.params.customerId,
        params: { format: val },
        tab: this.tab,
      })
    },
    changeSearchName(val) {
      this.debounceChangeFilters({
        customerId: this.$route.params.customerId,
        params: { name: val },
        tab: this.tab,
      })
    },
    changeStartDate(val) {
      this.changeFilters({
        customerId: this.$route.params.customerId,
        params: { startDate: val },
        tab: this.tab,
      })
    },
    changeEndDate(val) {
      this.changeFilters({
        customerId: this.$route.params.customerId,
        params: { endDate: val },
        tab: this.tab,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.filters-container.el-form {
  background: #f7f8fa;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  margin-bottom: 3em;

  ::v-deep .pl-12 {
    padding: 0;
  }

  ::v-deep .form-item-input {
    .pl-12 {
      padding: 0;
    }
  }

  ::v-deep .el-input__inner {
    border-radius: 10px;
  }

  .filters {
    display: inline-flex;
  }
  ::v-deep .el-date-editor {
    width: 130px;
  }

  ::v-deep .el-select__tags-text {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
  }

  .filters {
    .select {
      ::v-deep .el-form-item {
        width: 190px;
      }
      ::v-deep .el-input__inner {
        width: 190px;
      }
    }
  }

  .search {
    ::v-deep .el-form-item {
      width: 240px;
    }
    ::v-deep .el-input__inner {
      border-radius: 4px;
    }
  }
  .date-pickers-container {
    display: inline-flex;
    max-height: 80px;
  }
  .separator {
    align-self: center;
    padding-right: 10px;
    color: #606266;
  }
}
</style>
