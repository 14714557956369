<template lang="pug">
.form-wrapper.activate-page(v-if="!getTokenFromUrl() || allowShow", :class='{ "is-activated": alreadyActivated, "not-exist": userNotFound || tokenExpired }')
  .icon-wrapper
    i.icon.icon-sam-bot
    i.icon.icon-lamp
  .form-container(v-if="!loading")
    .form
      //- Success activation message
      template(v-if='alreadyActivated')
        .form-icon
          i.icon.icon-sam-bot-m-gold
        h1.form-title Your account has already been activated
        p.form-text.big-text Please login. If you have forgotten your password, click the “Forgot your password” link on the login page.
        .form-actions
          router-link.form-link.big-link(:to='{name: "login"}') Go to Login
            .icon.icon-forward

      //- User does not exist message
      template(v-else-if='userNotFound')
        .form-icon
          i.icon.icon-sam-bot-m-red
        h1.form-title The user account specified does not exist
        p.form-text.big-text Please contact your pear.ai representative for more information.

      //- Expired token
      template(v-else-if='tokenExpired')
        .form-icon
          i.icon.icon-sam-bot-m-red
        h1.form-title The request has expired
        p.form-text Please try again to reset a password or login
        .form-actions
          router-link.form-link.big-link(:to='{name: "login"}') Go to Login
            .icon.icon-forward

      template(v-else)
        h1.form-title Activate your account
        p.form-text Your Pear.ai account at <b>{{ email }}</b> has been created. Activate your account below.
        div.error-container(v-if="errorMsg.length")
          span {{ errorMsg }}
        div.constellation-text
          p Constellation customers can use their Constellation Energy Manager account to access Pear.ai.&nbsp;
          router-link(:to='{name: "learn-more"}') Learn more
        el-button.constellation-button(@click="handleSSO")
          .button-content
            i.icon.icon-constellation
            span Continue with Constellation account
        div.divider
          span OR
        div.email-info
          span Account email
          span {{ email }}

        .form-fields
          .form-field
            .el-input.input-password(:class='{error: passwordError}')
              input.el-input__inner(v-model='password',
                                    v-validate='validatePasswordField',
                                    :data-vv-delay='1000',
                                    type='password',
                                    name='inputPassword',
                                    ref='inputPassword',
                                    placeholder='Enter 8 or more characters or digits',
                                    tabindex='1',
                                    @keydown='checkSpaces')
              button.button-clear(v-if='errors.first("inputPassword") && password.length > 0',
                                  type='button',
                                  @click='clearPassword')
              label Create password
              .form-error(v-if='passwordError') {{ passwordError }}
          .form-field
            .el-input.input-password(:class='{error: passwordConfirmationError}')
              input.el-input__inner(v-model='confirmPassword',
                                    v-validate='"confirmed:inputPassword"',
                                    :data-vv-delay='1000',
                                    type='password',
                                    name='inputConfirmPassword',
                                    placeholder='Passwords must match',
                                    tabindex='2')
              button.button-clear(v-if='errors.first("inputConfirmPassword") && confirmPassword.length > 0',
                                  type='button',
                                  @click='clearConfirmPassword')
              label Confirm password
              .form-error(v-if='passwordConfirmationError') {{ passwordConfirmationError }}
        .tos-container
          span By clicking the button below, you agree to the
          a(href="https://www.constellationenergy.com/terms-of-use.html", target="_blank") &nbsp;Terms of Service
          span &nbsp;and
          a(href="https://www.constellationenergy.com/privacy-policy.html", target="_blank") &nbsp;Privacy Policy.
        .form-actions
          el-button.button-login(type='primary',
                                :disabled='!(password === confirmPassword) || password.length < 8 || errors.has("inputPassword")',
                                @click.native='changePassword') Activate account
</template>

<script>
import { application } from '../../lib/config'
import { auth } from '@/api'
import { mapActions } from 'vuex'
import { cond, equals } from 'ramda'
import { MsalClient } from '@/lib'
import { getSsoAuthErrorMessage, getTokenFromUrl, isSafari } from './helpers'

export default {
  data() {
    return {
      loading: true,
      alreadyActivated: false,
      userNotFound: false,
      email: '',
      password: '',
      tokenExpired: false,
      confirmPassword: '',
      errorMsg: '',
      allowShow: false,
      validatePasswordField: {
        min: 8,
        max: 31,
        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]\w+/,
      },
    }
  },
  computed: {
    passwordError() {
      return (this.password.length && this.errors.has('inputPassword')) ||
        (this.checkSpaces() && !this.errors.has('inputPassword'))
        ? 'Must contain from 8-31 characters including one uppercase letter and one number'
        : null
    },
    passwordConfirmationError() {
      return this.password.length && this.errors.has('inputConfirmPassword')
        ? 'Passwords do not match'
        : null
    },
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      setCustomerInfo: 'customers/setCustomerInfo',
    }),
    getTokenFromUrl,
    async activateSSO(activationToken = '', id_token = '') {
      try {
        this.errorMsg = ''
        await auth.activateAccount(activationToken, this.password, id_token)
        const { token, user } = await auth.loginAzureAd(id_token)
        this.setCustomerInfo({ user })
        await this.login({ jwt: token, user })
        this.$router.push({ name: 'onboarding' })
      } catch (e) {
        this.allowShow = true
        this.errorMsg = getSsoAuthErrorMessage(e)
      }
    },
    async handleSSO() {
      if (isSafari()) {
        const { token } = this.$route.query
        const { msal } = application
        localStorage.setItem('tmpActivateToken', token)
        window.location.href = `https://${msal.tenant_name}.b2clogin.com/${msal.tenant_name}.onmicrosoft.com/oauth2/v2.0/authorize?p=${msal.user_flow_name}&client_id=${msal.clientId}&nonce=defaultNonce&redirect_uri=${window.location.origin}/auth/activate&scope=openid&response_type=id_token&prompt=login`
        return
      }
      const client = new MsalClient()
      const id_token = await client.login()
      const { token } = this.$route.query

      await this.activateSSO(token, id_token)
    },
    changePassword() {
      const { token } = this.$route.query
      if (!this.errors.has('inputConfirmPassword')) {
        auth.activateAccount(token, this.password).then(() => {
          auth
            .login(this.email, this.password)
            .then(({ token, user }) => {
              this.setCustomerInfo({ user })
              return this.login({ jwt: token, user })
            })
            .then(() => {
              this.$router.push({ name: 'onboarding' })
            })
        })
      }
    },
    checkSpaces() {
      if (this.password.indexOf(' ') === -1) {
        return false
      }
      return true
    },
    clearPassword() {
      this.password = ''
    },
    clearConfirmPassword() {
      this.confirmPassword = ''
    },
  },
  async mounted() {
    const token =
      this.$route.query.token || localStorage.getItem('tmpActivateToken')
    if (!token) {
      this.tokenExpired = true
      this.allowShow = true
      this.loading = false
      return
    }
    auth
      .checkActivateToken(token)
      .then(({ email }) => {
        this.email = email
      })
      .catch((err) => {
        cond([
          [
            equals(404),
            () => {
              this.tokenExpired = true
            },
          ],
          [
            equals(406),
            () => {
              this.tokenExpired = true
            },
          ],
          [
            equals(409),
            () => {
              this.alreadyActivated = true
            },
          ],
          [
            equals(410),
            () => {
              this.tokenExpired = true
            },
          ],
          [
            equals(400),
            () => {
              this.tokenExpired = true
            },
          ],
        ])(err.response.data.statusCode)
      })
      .finally(() => {
        this.loading = false
      })

    const id_token = getTokenFromUrl()
    if (id_token) {
      this.allowShow = false
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      })
      try {
        await this.activateSSO(token, id_token)
        localStorage.removeItem('tmpActivateToken')
      } finally {
        loading.close()
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/utilities';

.error-container {
  margin-bottom: 36px;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #e12018;
  }
}

.form-actions {
  .button-login {
    margin-right: 0;
    max-width: 100%;
  }
}

.form-text {
  font-weight: normal;
}

.constellation-text {
  margin-bottom: 12px;
  p {
    display: inline;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #909399;
  }

  a {
    cursor: pointer;
    color: #0080ff;
  }
}

.constellation-button {
  padding: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  width: 100%;

  .button-content {
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #606266;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include respond-to($tablet-l) {
      font-size: 12px;
    }

    @include respond-to($phone) {
      font-size: 12px;
    }

    i {
      margin-right: 10px;
    }
  }
}

.divider {
  margin: 16px 0 24px 0;
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  &::after,
  &::before {
    display: flex;
    content: ' ';
    height: 1px;
    background: #1c1c1c;
    flex-grow: 2;
  }

  > span {
    margin: 0 16px;
  }
}
</style>
