import { updateReport, getReports } from '@/api/reports/completed-reports'
import { Message } from 'element-ui'

const initialState = {
  budgetingReportsList: [],
  total: null,
  pagination: {
    limit: 10,
    offset: 0,
    pageSizes: [10, 20, 30, 40, 100],
  },
  isLoading: true,
}

export default {
  namespaced: true,
  state: Object.assign({}, { ...initialState }),

  getters: {
    total: (state) => state.total && state.total,
    pagination: (state) => state.pagination,
    currentPage: (state) =>
      state.pagination.offset / state.pagination.limit + 1,
    budgetingReportsList: (state) => state.budgetingReportsList,
    isLoading: (state) => state.isLoading,
  },

  mutations: {
    setBudgetingReportsList(state, budgetingReportsList) {
      state.budgetingReportsList = budgetingReportsList
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setTotal(state, total) {
      state.total = total
    },
    setPagination(state, pagination) {
      Object.assign(state.pagination, pagination)
    },
    resetState(state) {
      state = Object.assign({}, { ...initialState })
    },
  },

  actions: {
    reset({ commit }) {
      commit('resetState')
    },
    async getBudgetingReportsList(
      { commit, state },
      { customerId, params = {} },
    ) {
      params.exclude = ['filters', 'extra']
      params.featureType = 'budgeting'
      try {
        const reports = await getReports({ customerId, params })
        await commit('setTotal', reports.totalResults)
        commit('setBudgetingReportsList', reports.data)
      } catch (e) {
        console.log(e)
      } finally {
        commit('setLoading', false)
      }
    },
    async checkProcessingReports({ dispatch, commit, state }, params) {
      const interval = setInterval(async () => {
        const processingStatus =
          state.budgetingReportsList &&
          state.budgetingReportsList.some(
            (budgetingReport) => budgetingReport.status === 'Processing',
          )
        if (processingStatus) {
          await dispatch('getBudgetingReportsList', params)
        } else {
          clearInterval(interval)
        }
      }, 5000)
    },
    async updateBudget({ commit }, { customerId, budgetId, body }) {
      try {
        await updateReport({ customerId, reportId: budgetId, body })
        Message({
          showClose: true,
          type: 'success',
          message: `${body.customReportName} was successfully updated. You can download the budget after it's generated.`,
        })
      } catch (e) {
        console.log(e)
        Message({
          showClose: true,
          type: 'error',
          message: 'Your report could not be updated. Please try again later.',
        })
      }
    },
    changePagination({ commit }, pagination) {
      commit('setPagination', pagination)
    },
  },
}
