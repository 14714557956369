import { prop, path, head } from 'ramda'

const SET_FILTERS = 'set_filter'

const initialState = () => ({
  filters: {
    buildingTypes: [],
    cities: [],
    states: [],
    searchQuery: '',
    squareFeetMin: 0,
    squareFeetMax: 0,
    orderBy: ['name.desc'],
    limit: 20,
    offset: 0,
  },
})

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    [SET_FILTERS]: (state, filters) => {
      if (typeof filters.limit === 'string') {
        filters.limit = Number(filters.limit)
      }
      if (typeof filters.offset === 'string') {
        filters.offset = Number(filters.offset)
      }
      if (typeof filters.squareFeetMin === 'string') {
        filters.squareFeetMin = Number(filters.squareFeetMin)
      }
      if (typeof filters.squareFeetMax === 'string') {
        filters.squareFeetMax = Number(filters.squareFeetMax)
      }
      Object.assign(state.filters, filters)
    },
    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async setFilters({ commit, dispatch }, filters) {
      await commit(SET_FILTERS, filters)
      await dispatch('locations/loadLocations', null, { root: true })
    },
    async changeFilters({ commit }, filters) {
      await commit(SET_FILTERS, filters)
    },
    resetFilters({ commit }) {
      commit('resetState')
    },
  },

  getters: {
    filters: prop('filters'),
    order: (state) => head(path(['filters', 'orderBy'], state)),
    pagination: prop('filters'),
    currentPage: (state) => state.filters.offset / state.filters.limit + 1,
  },
}
