<template>
  <div class="sidebar-buttons">
    <div
      class="sidebar-button sidebar-button--errors"
      @click="click('isErrorsVisible')"
      :class="{ active: isErrorsVisible }"
    >
      Errors
    </div>
    <div
      class="sidebar-button sidebar-button--logs"
      @click="click('isLogsVisible')"
      :class="{ active: isLogsVisible }"
    >
      Activity History
    </div>
    <div
      class="sidebar-button sidebar-button--pdf"
      @click="click('isPdfBillVisible')"
      :class="{ active: isPdfBillVisible }"
    >
      PDF Bill
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isErrorsVisible: true,
      isLogsVisible: false,
      isPdfBillVisible: false,
      pdfFileName: '',
    }
  },
  methods: {
    click(sidebar) {
      const state = this[sidebar]
      this.hideAllSideBar()
      this[sidebar] = !state
      if (this[sidebar]) {
        this.$emit('open', sidebar)
      } else {
        this.$emit('close')
      }
    },
    hideAllSideBar() {
      this.isErrorsVisible = false
      this.isLogsVisible = false
      this.isPdfBillVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .el-dropdown {
    margin-top: 10px;

    /deep/ *:focus {
      outline: none;
    }
  }

  .sidebar-button {
    height: 94px;
    width: 20px;
    margin-left: 8px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    text-align: center;
    border-radius: 4px 0 0 4px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    cursor: pointer;
    transition: all 0.2s;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -o-user-select: none;
    -moz-user-select: none;

    + .sidebar-button {
      margin-top: 10px;
    }

    &.active {
      width: 100%;
      height: 100px;
      margin-left: 0;
      line-height: 28px;
    }

    &--errors {
      background-color: #ff9494;

      &:hover {
        background-color: #f56c6c;
      }

      &.active {
        background-color: #f56c6c;
      }
    }

    &--logs {
      background-color: #79bbff;

      &:hover {
        background-color: #53a8ff;
      }

      &.active {
        background-color: #53a8ff;
      }
    }

    &--pdf {
      background-color: #4f78b9;

      &:hover {
        background-color: #395787;
      }

      &.active {
        background-color: #395787;
      }
    }
  }
}
</style>

<style lang="scss">
.el-dropdown-menu {
  &.sidebar-pdf-dropdown {
    margin-top: -101px !important;
    margin-right: 27px;

    .popper__arrow {
      display: none;
    }
  }
}
</style>
