<template lang="pug">
.create-widget-container
  el-button(type="text",
            class="create-widget-button",
            icon="el-icon-plus",
            data-action="create-widget-wizard",
            @click="addWidgetModalVisible = true")
  .widget-create-modal(v-if="addWidgetModalVisible")
    el-button.close(type="text", @click='addWidgetModalVisible = false')
      i.el-icon-close

    h2 Create widget

    .field-control
      label Widget Type
      div.widget-type-container
        .select-box
          select(v-model="newWidgetType",
                ref="widget-type-select",
                data-action="widget-type-select")
            option(value="", selected) Select
            option(v-for='item in sortedWidgetTypes',
                  :key="item.type",
                  :value="item.type") {{ item.title }}
          i.el-select__caret.el-input__icon.el-icon-arrow-down
    .field-control
      label Widget Name
      input.widget-name-field(v-model="newWidgetName",
                              :placeholder="widgetNamePlaceholder")

    .filters-wrapper(:class="{'show-scroll': !isEditFilterOpened}")
      h4 Selected Filters
      p Tap on row to edit filter.
      filter-show-mobile-container(:widgetFilters="defaultFilters",
                            :widget-type="newWidgetType",
                            :title="''",
                            @open-edit-filter="openEditFilter",
                            @reset-filters="resetFilter",
                            @close-filter="closeFilter",
                            @update-filter="updateFilter")
    .create-widget_actions
      el-button.button-add-widget(type="primary",
                                size="small",
                                round,
                                :disabled="!newWidgetType.length",
                                @click="addWidget") Create Widget
</template>

<script>
import { mapGetters } from 'vuex'
import FilterShowMobileContainer from '../Filters/FilterShowMobileContainer'
import { filters } from '@/lib'
import _ from 'lodash'

export default {
  components: {
    FilterShowMobileContainer,
  },
  data() {
    return {
      addWidgetModalVisible: false,
      widgetTooltipVisible: false,
      newWidgetType: '',
      newWidgetName: '',
      widgetNamePlaceholder: '',
      filters: {},
      isEditFilterOpened: false,
    }
  },
  computed: {
    ...mapGetters({
      widgetTypes: 'widgets/widgetTypes',
      customerId: 'customers/currentCustomerId',
    }),
    defaultFilters() {
      return { ...filters.defaultFilters(this.newWidgetType), ...this.filters }
    },
    widgetTooltip() {
      if (this.newWidgetType) {
        const { title, description } = this.widgetTypes[this.newWidgetType]
        return {
          title,
          description,
        }
      }
      return false
    },
    sortedWidgetTypes() {
      return _.sortBy(this.widgetTypes, ['order'])
    },
  },
  watch: {
    widgetTooltip() {
      if (this.newWidgetType) {
        const { title } = this.widgetTypes[this.newWidgetType]
        this.widgetNamePlaceholder = title
      }
    },
    newWidgetType() {
      this.clearFilters()
    },
    addWidgetModalVisible(value) {
      if (value) {
        this.newWidgetType = ''
        this.newWidgetName = ''
        this.widgetNamePlaceholder = ''
      }
    },
    customerId() {
      this.addWidgetModalVisible = false
    },
  },
  methods: {
    clearFilters() {
      this.filters = {}
    },
    addFilter(filter) {
      this.filters = { ...this.filters, ...filter }
    },
    openEditFilter(filterType) {
      this.isEditFilterOpened = true
      this.editFilterType = filterType
    },
    removeFilter() {
      this.isEditFilterOpened = false
      const temp = _.cloneDeep(this.filters)
      delete temp[this.editFilterType]
      this.filters = temp
      this.editFilterType = ''
    },
    updateFilter(filter) {
      this.isEditFilterOpened = false
      this.filters = { ...this.filters, ...filter }
    },
    resetFilter() {
      const defaultFilters = filters.defaultFilters(this.newWidgetType)
      this.filters[this.editFilterType] = _.get(
        defaultFilters,
        this.editFilterType,
        {},
      )
    },
    closeFilter() {
      this.isEditFilterOpened = false
    },
    addWidget() {
      const filtersArray = filters.toArray(this.defaultFilters)
      this.$emit('add-widget', {
        name: this.newWidgetName || this.widgetNamePlaceholder,
        type: this.newWidgetType,
        chartType: this.widgetTypes[this.newWidgetType].chart_types[0],
        filters: filtersArray,
      })

      this.addWidgetModalVisible = false
      this.newWidgetType = ''
      this.newWidgetName = ''
      this.widgetNamePlaceholder = ''
    },
    toggleTooltip() {
      this.widgetTooltipVisible = !this.widgetTooltipVisible
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-create-modal {
  position: fixed;
  top: 74px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 40px 30px 25px;
  background-color: #e7ebf0;
  z-index: 10;

  h2 {
    margin: -20px 0 20px;
    color: #2c2e34;
    font-size: 18px;
    line-height: 25px;
    font-weight: 900;
  }

  .widget-type-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
  }

  .filters-wrapper {
    position: relative;
    height: 200px;
    padding: 14px;
    margin-top: 10px;
    color: #606266;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid #d0d0d0;
    border-radius: 6px;
    background-color: #fff;
    &.show-scroll {
      overflow-y: auto;
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 21px;

      span {
        display: block;
        color: #2893f9;
      }
    }
    h4 {
      margin: 5px 0 10px 0;
      font-size: 16px;
      font-weight: 900;
      color: #4a4a4a;
    }
    .icon {
      position: absolute;
      top: 5px;
      left: 5px;
      margin-right: 0;
    }
  }
}
.create-widget_actions {
  text-align: center;
}
.create-widget-button {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 56px;
  height: 56px;
  padding: 0;
  color: #fff;
  font-size: 24px;
  border-color: transparent;
  border-radius: 50%;
  background-color: #0080ff;
  z-index: 10;

  &:hover {
    border-color: #0080ff;
  }
}
.button-add-widget {
  width: 264px;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 900;
  border-radius: 18px;
  background-color: #0080ff;
}
.field-control {
  flex-wrap: wrap;

  label {
    width: 100%;
    height: 19px;
    margin-bottom: 10px;
    color: #606266;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
  }
  input {
    display: inline-block;
    width: 100%;
    height: 36px;
    padding: 0 20px;
    color: #909399;
    font-size: 16px;
    line-height: 36px;
    border-radius: 10px;
    border: 1px solid #dcdfe6;
    background-color: #fff;
    box-sizing: border-box;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    appearance: none;
    outline: none;
  }
}
.select-box {
  position: relative;
  flex-grow: 1;

  select {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 36px;
    padding: 0 20px;
    color: #909399;
    font-size: 16px;
    line-height: 36px;
    border-radius: 10px;
    border: 1px solid #dcdfe6;
    background-color: #fff;
    box-sizing: border-box;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    appearance: none;
    outline: none;

    &.show-label {
      opacity: 0;

      &:focus {
        opacity: 1;

        ~ label {
          opacity: 0;
        }
      }
    }
  }
  i {
    position: absolute;
    top: 0;
    right: 5px;
    color: #c0c4cc;
  }
}
.button-tooltip {
  flex: none;
  width: 27px;
  height: 27px;
  padding: 0;
  line-height: 26px;
  margin-left: 7px;

  &.is-active {
    color: #3a8ee6;
    border-color: #3a8ee6;
    background-color: #fff;
  }
}
.widget-tooltip-container {
  position: relative;
}
.widget-tooltip {
  position: absolute;
  top: -4px;
  right: 34px;
  min-width: 250px;
  max-height: 200px;
  padding: 12px;
  font-size: 14px;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  border: 1px solid #ebeef5;
  border-radius: 4px 0 4px 4px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  box-sizing: border-box;
  z-index: 10;

  h4 {
    display: inline-block;
    margin: 0;
    padding-bottom: 3px;
    font-size: 14px;
    font-weight: 900;
    border-bottom: 1px solid #0080ff;
  }
  p {
    font-size: 12px;
  }
}
.close {
  position: absolute;
  top: 20px;
  right: 12px;
  width: 26px;
  height: 26px;
  padding: 0;
  margin-right: 0;
  transition: none;

  i {
    margin-right: 0;
    color: #7d89a6;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
