<template lang="pug">
  .bill-summary-control.control
    .bill-summary_card
    .bill-table-list-container
      .bill-summary_table(v-for="(dataItem, index) in messageData.data")
        .card-title(v-if="index === 0") {{ messageData.title }}
        .card-subtitle(v-if="index === 0 && messageData.subtitle") {{ processHeader(messageData.subtitle) }}
        table.sam-table.sam-table-list
          thead(v-if="messageData.options ? !messageData.options.hideHeader : true")
            tr
              th(v-for="(header, index) in messageData.header", :key="index")
                .cell {{ header.displayString }}
          tbody
            tr(v-for="(item, index) in dataItem", :key="index" :class="[rowClassName(item), {'sam-table_action': messageData.action}]", @click="handleRowClick(item)")
              td(:class="[cellClassName(item, index, 'attribute'), { 'meter-title': index === 0 }]")
                .cell {{ item.attribute }}
              td(:class="cellClassName(item, index, 'value')")
                .cell {{ item.value }}
        .bill-table-view-footer(v-if="messageData.footer && index === messageData.data.length - 1")
          .data
            span(v-for="item in (Array.isArray(messageData.footer) ? messageData.footer : [messageData.footer])") {{ item }}
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { sendEventToChatbase } from '../../../api/stats'

export default {
  data() {
    return {}
  },
  props: {
    messageData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      prettyVendors: 'resources/getPrettyVendorNames',
      currentCustomerId: 'customers/currentCustomerId',
    }),
  },
  methods: {
    processHeader(header) {
      _.mapKeys(this.prettyVendors, (value, key) => {
        if (header.indexOf(key) >= 0) {
          header = header.replace(key, value)
        }
      })

      return header
    },
    rowClassName(row) {
      return _.get(row, 'styles._row.0', '')
    },
    cellClassName(row, rowIndex, property) {
      return ((row.styles ? row.styles[property] : []) || [])
        .map((className) => className)
        .join(' ')
    },
    sendMessage(message) {
      this.$emit('sendMessage', message)
    },
    scrapeValue(str, fields) {
      const [match, key] = str.match(/\{([^}]+)\}/)
      return str.replace(match, fields[key])
    },
    handleRowClick(row) {
      if (!this.messageData.action) {
        return false
      }

      if (this.messageData.action.type === 'weblink_newtab') {
        sendEventToChatbase(this.currentCustomerId, 'new_tab', 'weblink').catch(
          (err) => {
            console.error('CHATBASE ERROR: ', err)
          },
        )

        window.open(
          this.scrapeValue(this.messageData.action.url, row),
          '_blank',
        )
      } else {
        this.sendMessage(
          this.scrapeValue(this.messageData.action.bubble_text, row),
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../styles/_utilities';

.card-title {
  font-size: 14px;
  line-height: 17px;
  font-weight: 900;
  color: #5d6a89;
  margin-bottom: 5px;
}

.card-subtitle {
  color: #4a4a4a;
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
  margin-top: 2px;
  margin-bottom: 7px;
}

.bill-summary_card {
  padding: 0 10px;
  margin-bottom: -5px;
  color: #3b3e48;
  font-size: 16px;
}
.bill-summary_meta {
  padding: 10px 10px 0 10px;
  color: #7d89a6;
  font-size: 10px;
}

.bill-summary_table {
  max-width: 380px;
  padding: 15px;
  /*padding-bottom: 10px;*/
  margin-bottom: 10px;
  background-color: #fff;
  box-sizing: border-box;
  overflow-x: auto;
  margin-left: 62px;
  border: 1px solid #dde5e6;
  border-radius: 0 16px 16px 0;

  &:last-child {
    border-radius: 0 16px 16px 16px;
  }

  @include respond-to($phone) {
    margin-left: 0;
  }
  @include respond-to($phone-l) {
    margin-left: 0;
  }

  .sam-table {
    @include respond-to($phone) {
      font-size: 12px;
    }
  }
}

.bill-table-list-container {
  .bill-summary_table {
    thead {
      opacity: 0;
    }

    &:first-child {
      thead {
        opacity: 1;
      }
    }
  }
}

.bill-table-view-footer {
  /*margin-left: 10px;*/
  .data {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > span {
      margin-top: 2px;
      font-size: 10px;
      line-height: 14px;
      font-weight: 500;
      color: #888888;
    }
  }
}
</style>
