<template>
  <BaseModal
    :dialogVisible="showModal"
    :title="'Add Year/Months'"
    :showClose="true"
    @close="close"
  >
    <template #content>
      <div class="info">Years and Months that are already part of your list will appear as disabled in the below
        dropdowns.
      </div>
      <div>
        <el-row>
          <el-col :span="8">
            <label>Year</label>
            <el-select
              v-model="selectedYear"
              placeholder="Choose year"
              size="small"
              @change="onYearChange"
            >
              <el-option
                v-for="item in years"
                :key="item"
                :label="item"
                :value="item"
                :disabled="isYearDisabled(item)"
              />
            </el-select>
          </el-col>
          <el-col :span="12" class="months-select">
            <label>Months</label>
            <el-select
              v-model="selectedMonths"
              :disabled="!selectedYear"
              multiple
              placeholder="Choose months"
              size="small"
            >
              <el-option
                v-for="(item, index) in allMonths"
                :key="index"
                :label="item"
                :value="item"
                :disabled="isMonthDisabled(index)"
              />
            </el-select>
          </el-col>
        </el-row>
      </div>
    </template>
    <template #footer>
      <div class="controls">
        <el-button class="cancel-btn" @click="close">Cancel</el-button>
        <el-button type="primary" @click="onAddYearMonths" :disabled="!selectedMonths.length">Add</el-button>
      </div>
    </template>
  </BaseModal>
</template>
<script>
import BaseModal from '@/components/BaseModal'
import { mapActions } from 'vuex'

export default {
  components: {
    BaseModal,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    disabledMonths: {
      type: Object,
      default: null
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      years: [],
      selectedYear: null,
      allMonths: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      selectedMonths: []
    }
  },
  async mounted() {
    const currentYear = new Date().getFullYear()
    this.years = Array.from({ length: 5 }, (_, i) => currentYear - i)
  },
  methods: {
    ...mapActions({
      addYearMonths: 'productionData/addYearMonths'
    }),
    close() {
      this.$emit('close')
      this.selectedYear = null
      this.selectedMonths = []
    },
    onYearChange() {
      this.selectedMonths = []
    },
    isYearDisabled(year) {
      let isDisabled = false
      const noOfMonths = 12
      if (this.disabledMonths[year] && this.disabledMonths[year].length === noOfMonths) {
        isDisabled = true
      }
      return isDisabled
    },
    isMonthDisabled(indexOfMonth) {
      return this.selectedYear && this.disabledMonths[this.selectedYear]
        ? this.disabledMonths[this.selectedYear].includes(indexOfMonth)
        : false
    },
    onAddYearMonths() {
      const { customerId, locationId } = this.$route.params
      const body = {
        dateMonths: []
      }
      this.selectedMonths.forEach(month => {
        body.dateMonths.push(new Date(Date.UTC(this.selectedYear, this.allMonths.indexOf(month))))
      })
      const recentMonth = body.dateMonths.reduce((a, b) => (a.MeasureDate > b.MeasureDate ? a : b))
      this.addYearMonths({ customerId, locationId, body }).then(() => {
        this.close()
        this.$emit('addYearMonths', recentMonth)
      })
    }
  },
}
</script>
<style lang="scss" scoped>
@import 'src/styles/_utilities.scss';

::v-deep {
  .el-dialog {
    width: 480px;
    height: 418px;
    max-height: 418px;
    display: flex;
    flex-direction: column;

    &__title {
      color: $secondary-text;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
    }

    .el-dialog__header .el-dialog__headerbtn {
      margin-top: 17px;
    }

    .el-dialog__footer {
      margin-top: auto;
      padding: 0 32px 32px;

      .dialog-footer {
        margin: 0;
      }
    }
  }

}

.info {
  margin: 32px 0 24px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #909399;
}

::v-deep .el-input__inner {
  border-radius: 10px;
}

.months-select {
  margin-left: 28px;
  margin-right: 26px;

  ::v-deep.el-input.el-input--small {
    border-radius: 10px;
    width: 236px;
  }
}

.controls {
  .el-button {
    width: 170px;
    font-weight: 600;
    font-size: 14px;
  }

  .cancel-btn {
    color: $primary;
    border-color: $primary;
    margin-right: 10px;
  }
}
</style>
