<template>
  <div class="select-analytics-report-modal">
    <base-modal
      :title="'Analytics Reports List'"
      :show-close="true"
      :closeOnMask="true"
      :dialog-visible="true"
      @close="onChangeReportModalClose"
      :width="'466px'"
    >
      <template #content>
        <div class="reports-table-container">
          <el-table :data="getPowerbiReports" style="width: 100%">
            <el-table-column label="Name" prop="name"></el-table-column>
            <el-table-column align="right" label="Action:">
              <template #default="scope">
                <el-radio
                  v-model="currentReportId"
                  :label="scope.row.id"
                  size="mini"
                  type="danger"
                ></el-radio>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <template #footer>
        <el-button
          class="close-btn"
          :disabled="!canChangeReport"
          type="primary"
          @click="onChangeReportModalClose(true)"
        >
          Select Report
        </el-button>
      </template>
    </base-modal>
  </div>
</template>

<script>
import BaseModal from '../../../../components/BaseModal'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { BaseModal },
  computed: {
    ...mapGetters({
      currentCustomerId: 'customers/currentCustomerId',
      getPowerbiReports: 'powerbi/getPowerbiReports',
      getCurrentPowerbiReportId: 'powerbi/getCurrentPowerbiReportId',
      getCurrentPowerbiReport: 'powerbi/getCurrentPowerbiReport',
    }),
    canChangeReport() {
      return this.currentReportId !== this.getCurrentPowerbiReportId
    },
    currentReportId: {
      get() {
        return this.selectedReportId || this.getCurrentPowerbiReportId
      },
      set(value) {
        this.selectedReportId = value
      },
    },
  },
  data() {
    return {
      selectedReportId: null,
    }
  },
  mounted() {
    this.loadPowerbiReports(this.currentCustomerId)
  },
  methods: {
    ...mapActions({
      loadPowerbiReports: 'powerbi/loadPowerbiReports',
      setCurrentPowerbiReportId: 'powerbi/setCurrentPowerbiReportId',
    }),
    onChangeReportModalClose(saveValue = false) {
      if (saveValue) {
        this.setCurrentPowerbiReportId(this.currentReportId)
        this.$emit('changedPowerbiReportId')
      }
      this.$router.replace({
        name: 'dashboard.analytics',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.select-analytics-report-modal {
  ::v-deep.el-dialog {
    padding: 12px;
    border-radius: 8px;

    .el-radio {
      margin-right: 12px;
      .el-radio__label {
        display: none;
      }
    }

    th {
      background-color: #f6f6f6;
    }

    .dialog-footer {
      margin: 0px;
    }
  }
}
</style>
