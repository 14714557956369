<template lang="pug">
  .content-inner
    list(v-if='!$deviceInfo.isMobileDevice')
    list-mobile(v-else)
</template>

<script>
import ListMobile from './ListMobile.vue'
import List from './List.vue'

export default {
  components: {
    List,
    ListMobile,
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/utilities';

.content-inner {
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 15px;

  @include respond-to($phone) {
    padding: 15px 10px;
    max-height: calc(100% - 30px);
    -webkit-overflow-scrolling: touch;
  }
  @include respond-to($phone-l) {
    padding: 15px 10px;
    max-height: calc(100% - 30px);
    -webkit-overflow-scrolling: touch;
  }
}
</style>

<style lang="scss">
@import '../../../styles/components/_pagination';
</style>
