<template lang="pug">
.filter-item.filter-item-utility.filter-item-utilities
  .filter-title(v-if="title") {{ title }}
  .select-wrapper
    el-select(v-model="utility_type.selected",
              class="is-multiple",
              size="small",
              data-value="select-utility-types",
              :multiple="true", @change="handleChange", filterable)
      el-option(v-for="comm in commodities",
                :key="comm",
                :label="comm",
                :value="comm")
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: () => {},
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
    commodities: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      utility_type: {
        selected: this.value,
      },
    }
  },
  methods: {
    handleChange(val) {
      this.filter.list = val
    },
  },
}
</script>

<style>
.filter-item-utilities > :not(.el-input-number) .el-input {
  width: 250px;
}
</style>

<style scoped lang="scss">
.select-wrapper {
  margin-top: 16px;
}
</style>
