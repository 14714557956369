<template>
  <BaseModal
    :dialogVisible="showModal"
    @close="close"
    @open="init"
    :width="'65%'"
    :title="title"
    id="editMeterModal"
    class="edit-meter-modal-base-modal"
  >
    <template #content>
      <div v-loading="loading">
        <ErrorCard :statusMessage="createError" />
        <el-tabs v-model="activeName">
          <el-tab-pane label="Properties" name="Properties">
            <ValidationObserver ref="observer">
              <el-form label-position="left">
                <div class="flex-item">
                  <BaseInput
                    v-model="meter.name"
                    placeholder="Enter ES Meter Name"
                    :required="true"
                    rules="required"
                    name="esName"
                    :immediate="true"
                    label="Name:"
                    :labelWidth="'200px'"
                    size="medium"
                    :isEditMode="isEditMode"
                  />
                  <div>
                    <el-button
                      class="text"
                      type="text"
                      @click="validateAndUpdate"
                    >
                      {{ isEditMode ? 'Save Changes' : 'Edit Properties' }}
                    </el-button>
                    <el-button
                      class="button-color text"
                      id="deleteMeter"
                      icon="el-icon-delete"
                      type="text"
                      @click="deleteMeter"
                    >
                      Delete Meter
                    </el-button>
                  </div>
                </div>
                <BaseInput
                  v-model="meter.id"
                  :required="true"
                  name="date"
                  label="ENERGY STAR® Meter ID:"
                  :labelWidth="'200px'"
                  size="medium"
                />
                <BaseInput
                  v-model="meter.type"
                  name="propertyName"
                  label="Type:"
                  :labelWidth="'200px'"
                  size="medium"
                />
                <BaseInput
                  v-model="meter.unitOfMeasure"
                  name="propertyName"
                  label="Units:"
                  :labelWidth="'200px'"
                  size="medium"
                />
                <BaseDatePicker
                  v-model="meter.firstBillDate"
                  name="esDate"
                  label="Date Meter became Active:"
                  :labelWidth="'200px'"
                  :immediate="true"
                  rules="required"
                  ref="firstBillDate"
                  @change="dateChange"
                  size="medium"
                  :required="true"
                  :isEditMode="isEditMode"
                />
                <BaseCheckbox
                  :isEditMode="true"
                  :labelWidth="'200px'"
                  v-model="inUse"
                  @input="dateChange"
                  :disabled="!isEditMode"
                  :labelCheckbox="'Still in use'"
                />
                <BaseDatePicker
                  v-model="meter.inactiveDate"
                  name="inactiveDate"
                  label="Date Meter became Inactive:"
                  :labelWidth="'200px'"
                  :immediate="true"
                  size="medium"
                  @change="dateChange"
                  :disabled="inUse"
                  :rules="inUse ? '' : 'required'"
                  :message="'The meter is still in use'"
                  :required="inUse ? false : true"
                  :isEditMode="isEditMode"
                />
                <hr class="border" />
                <h2>ENERGY STAR® Metrics</h2>
                <BaseCheckbox
                  v-model="meter.associated"
                  :isEditMode="true"
                  :labelWidth="'400px'"
                  :disabled="!isEditMode"
                  label="Include this meter when computing ENERGY STAR® metrics"
                />
              </el-form>
            </ValidationObserver>
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
    <template #footer class="dialog-footer-center">
      <div
        v-if="isEditMode"
        @mouseenter="onHover"
        @mousemove="onHover"
        @mouseleave="onLeave"
        class="button-container"
      >
        <el-button
          :disabled="isEditMode"
          round
          type="primary"
          class="button"
          @click="close"
        >
          Ok
        </el-button>
        <div ref="tooltip" class="btn-tooltip" v-show="tooltip">
          <p class="btn-tooltip-text">
            Please save the changes before you continue
          </p>
        </div>
      </div>
      <div v-else class="button-container">
        <el-button class="button" type="primary" @click="close" round>
          Ok
        </el-button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import BaseCheckbox from '@/components/FormComponents/BaseCheckbox'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import BaseDatePicker from '@/components/FormComponents/BaseDatePicker'
import BaseInput from '@/components/FormComponents/BaseInput'
import { energystar } from '@/api'
import { DateTime } from 'luxon'
import { ValidationObserver } from 'vee-validate'
import ErrorCard from '@/components/Cards/ErrorCard'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    customerFDGCode: {
      type: String,
    },
    meterId: {
      type: String,
    },
    meterName: {
      type: String,
    },
    width: {
      type: String,
      default: '',
    },
    propertyId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BaseModal,
    BaseSelect,
    BaseInput,
    BaseCheckbox,
    BaseDatePicker,
    ValidationObserver,
    ErrorCard,
  },
  data() {
    return {
      showModal: false,
      activeName: 'Properties',
      title: '',
      meter: {},
      isEditMode: false,
      valid: false,
      loading: true,
      billDate: '',
      createError: '',
      showEnergyStarDiag: true,
      energyStarEntitlement: false,
      tooltip: false,
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  computed: {
    inUse: {
      get() {
        const stillInUse = this.meter.inUse
        if (stillInUse && stillInUse.toString() === 'true') {
          return true
        } else {
          return false
        }
      },
      set(newValue) {
        this.meter.inUse = newValue
        return this.meter.inUse
      },
    },
  },
  methods: {
    close() {
      this.meter = {}
      this.loading = true
      this.$emit('close')
    },
    openMetersModal() {
      this.$emit('open-meters')
    },
    async init() {
      this.createError = ''
      this.title = `Edit details for ${this.meterName}`
      this.getMeter()
    },
    dateChange() {
      this.billDate = this.$refs.firstBillDate.$children[0].$children[0].flags
      if (this.meter.firstBillDate) {
        if (
          DateTime.fromFormat(this.meter.firstBillDate, 'LL/dd/yyyy').isValid
        ) {
          this.meter.firstBillDate = DateTime.fromFormat(
            this.meter.firstBillDate,
            'LL/dd/yyyy',
          ).toFormat('yyyy-LL-dd')
        }
        if (
          this.meter.inUse.toString() === 'false' &&
          this.meter.inactiveDate
        ) {
          if (
            DateTime.fromFormat(this.meter.inactiveDate, 'LL/dd/yyyy').isValid
          ) {
            this.meter.inactiveDate = DateTime.fromFormat(
              this.meter.inactiveDate,
              'LL/dd/yyyy',
            ).toFormat('yyyy-LL-dd')
          }
          if (
            DateTime.fromFormat(this.meter.inactiveDate, 'yyyy-LL-dd') <
            DateTime.fromFormat(this.meter.firstBillDate, 'yyyy-LL-dd')
          ) {
            this.createError =
              'Date Meter became Inactive must be after Date Meter became Active '
            return
          }
        }
      }
      this.createError = ''
    },
    async getMeter() {
      const { customerId } = this.$route.params
      try {
        const data = await energystar.getMeter({
          customerId,
          propertyId: this.propertyId,
          meterId: +this.meterId,
        })
        data.firstBillDate = DateTime.fromFormat(
          data.firstBillDate,
          'yyyy-LL-dd',
          { zone: 'utc' },
        ).toFormat('LL/dd/yyyy')
        data.inactiveDate =
          data.inactiveDate === ''
            ? ''
            : DateTime.fromFormat(data.inactiveDate, 'yyyy-LL-dd', {
              zone: 'utc',
            }).toFormat('LL/dd/yyyy')
        this.meter = data
        this.loading = false
      } catch (e) {
        this.createError =
          e.response.status < 500
            ? 'There was a problem with the request'
            : e.response.data.message
      }
    },
    async deleteMeter() {
      const { customerId } = this.$route.params
      try {
        this.meter = await energystar.deleteMeter({
          customerId,
          propertyId: this.propertyId,
          meterId: +this.meterId,
        })
        this.$emit('get-meters', true)
        this.close()
      } catch (e) {
        this.createError =
          e.status < 500
            ? 'There was a problem with the request'
            : e.response.data.message
      }
    },
    async validateAndUpdate() {
      if (!this.isEditMode) {
        this.isEditMode = true
      } else {
        this.$refs.observer.validate().then((success) => {
          if (success) {
            this.valid = true
            this.updateMeter()
            this.$refs.observer.reset()
          }
        })
      }
    },
    async updateMeter() {
      const { customerId } = this.$route.params
      const body = {
        name: this.meter.name,
        firstBillDate: this.meter.firstBillDate,
        inUse: this.inUse.toString(),
        associated: this.meter.associated,
        inactiveDate: this.meter.inactiveDate,
      }
      try {
        if (body.firstBillDate) {
          if (DateTime.fromFormat(body.firstBillDate, 'LL/dd/yyyy').isValid) {
            body.firstBillDate = DateTime.fromFormat(
              body.firstBillDate,
              'LL/dd/yyyy',
            ).toFormat('yyyy-LL-dd')
          }
          if (body.inUse.toString() === 'true') {
            body.inactiveDate = ''
          } else if (body.inactiveDate) {
            if (DateTime.fromFormat(body.inactiveDate, 'LL/dd/yyyy').isValid) {
              body.inactiveDate = DateTime.fromFormat(
                body.inactiveDate,
                'LL/dd/yyyy',
              ).toFormat('yyyy-LL-dd')
            }
            if (
              DateTime.fromFormat(body.inactiveDate, 'yyyy-LL-dd') <
              DateTime.fromFormat(body.firstBillDate, 'yyyy-LL-dd')
            ) {
              this.createError =
                'Date Meter became Inactive must be after Date Meter became Active '
              return
            }
          }
        }
        this.loading = true
        await energystar.putMeter({
          customerId,
          propertyId: this.propertyId,
          meterId: +this.meterId,
          body,
        })
        this.title = `Edit details for ${this.meter.name}`
        this.getMeter()
        this.$emit('get-meters', true)
        this.isEditMode = false
        this.createError = ''
      } catch (e) {
        this.createError =
          e.response.status < 500
            ? 'There was a problem with the request'
            : e.response.data.message
        this.loading = false
      }
    },
    handleShowEnergyStarDialog() {
      this.showEnergyStarDiag = true
    },
    onHover(position) {
      this.tooltip = true
      let x = position.clientX
      let y = position.clientY
      this.$refs.tooltip.style.top = y + 20 + 'px'
      this.$refs.tooltip.style.left = x + 20 + 'px'
    },
    onLeave() {
      this.tooltip = false
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-meter-modal-base-modal {
  .button-size {
    width: 45%;
  }
  .plain-button {
    color: #2893f9;
    border-color: #2893f9;
  }
  .message {
    margin: 0;
    text-align: center;
    word-break: break-word;
  }
  ::v-deep .el-tabs {
    .is-top::after {
      height: 0;
    }
  }
  .flex-item {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .el-button[class*='text'] {
    padding: 0;
  }

  .border {
    border-top: medium;
  }
  .button-color {
    color: red;
  }
  .button-container {
    width: 45%;
    margin-top: 0;
    .button {
      width: 100%;
    }
  }
  .btn-tooltip {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 8px;
    max-width: 250px;
    height: 30px;
    &-text {
      color: #4a4a4a;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
    }
  }
  .wrapper.el-button {
    display: inline-block;
    padding: 0;
    margin: 0;
    border: none;
  }
  .payment-settings-button {
    font-weight: bold;
    &:disabled {
      border-color: #9ec0e9;
      background-color: #9ec0e9;
      opacity: 0.8;
      color: #ffffff;
    }
  }
}
</style>
