<template>
  <div class="content-inner" v-loading.fullscreen.lock="isLoading">
    <div class="location-header">
      <span
        class="location-header_back"
        v-if="!$deviceInfo.isMobileDevice"
        @click="navigateToHierarchyPage"
      >
        <i class="icon icon-back"></i>
      </span>
      <span class="location-header_title" v-if="location">
        Location Detail for {{ location.name }}
      </span>
    </div>
    <div class="hierarchy-breadcrumbs" v-if="location">
      <div
        class="hierarchy-breadcrumbs-item"
        v-for="{ name, id } in hierarchyBreadcrumbs"
        :key="id"
      >
        <router-link
          :to="{
            name: 'dashboard.locations.hierarchy',
            params: { customerId: currentCustomerId },
            query: { id: id },
          }"
        >
          <span class="name">{{ name }}</span>
        </router-link>
        <span class="nav">-</span>
      </div>
    </div>
    <div class="location-content" v-if="location">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="Metrics" name="metrics" data-action="Show metrics">
          <metrics-tab :virtual-accounts="virtualAccounts"></metrics-tab>
        </el-tab-pane>
        <el-tab-pane
          label="Virtual Accounts"
          name="virtual_accounts"
          data-action="Show virtual accounts"
        >
          <virtual-accounts-tab
            :virtual-accounts="virtualAccountsGrouped"
            :pagination="pagination"
            :loading="isLoading"
            @update-limit="changeVirtualAccounts"
            @update-offset="changeVirtualAccounts"
            @update-order="getVirtualAccounts($route.query)"
          ></virtual-accounts-tab>
        </el-tab-pane>
        <el-tab-pane
          label="Attributes"
          name="attributes"
          data-action="Show attributes"
        >
          <attributes-tab :location="location"></attributes-tab>
        </el-tab-pane>
        <el-tab-pane
          label="Production Data"
          name="production_data"
          data-action="Show attributes"
        >
          <production-data-tab :location="location"></production-data-tab>
        </el-tab-pane>
        <el-tab-pane
          label="Monthly View"
          name="bill_history"
          data-action="Show bill history"
        >
          <monthly-feed-tab :datesMinMax="datesMinMax"></monthly-feed-tab>
        </el-tab-pane>
        <el-tab-pane
          v-if="isWeatherTabActive"
          label="Weather"
          name="weather"
          data-action="Show weather"
        >
          <weather-tab
            :datesMinMax="datesMinMax"
            @prev-month="onDataChanged"
            @next-month="onDataChanged"
            @picker-date-change="onDataChanged"
          ></weather-tab>
        </el-tab-pane>
        <el-tab-pane
          v-if="isEnergyStarTabActive"
          label="ENERGY STAR®"
          name="energy_star"
          data-action="Show ENERGY STAR® information"
        >
          <energy-star-tab
            :location="location"
            @refresh-location="getLocation"
          ></energy-star-tab>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as dot from 'dot-object'
import { locations } from '@/api'
import { mapGetters, mapState, mapActions } from 'vuex'
import { DateTime } from 'luxon'
import * as R from 'ramda'
import MetricsTab from './Tabs/Metrics.vue'
import VirtualAccountsTab from './Tabs/VirtualAccounts/View'
import AttributesTab from './Tabs/Attributes.vue'
import MonthlyFeedTab from './Tabs/MonthlyFeed/List.vue'
import WeatherTab from './Tabs/Weather/List.vue'
import EnergyStarTab from './Tabs/EnergyStar/List.vue'
import { Events } from '@/lib'
import SustainabilityTab from './Tabs/Sustainability/SustainabilityTab'
import ProductionDataTab from './Tabs/ProductionData/List.vue'

const ENERGY_STAR_PAGE_TITLE = 'Energy Star'
const BILL_HISTORY_PAGE_TITLE = 'Bill History'

export default {
  components: {
    ProductionDataTab,
    SustainabilityTab,
    MetricsTab,
    VirtualAccountsTab,
    AttributesTab,
    MonthlyFeedTab,
    WeatherTab,
    EnergyStarTab,
  },
  data() {
    return {
      virtualAccountsGrouped: [],
      isLoading: true,
      datesMinMax: null,
      weatherParams: {
        startDate: DateTime.local()
          .toUTC()
          .startOf('month')
          .set({ weekday: 0 })
          .toFormat('yyyy-LL-dd'),
        endDate: DateTime.local()
          .toUTC()
          .endOf('month')
          .set({ weekday: 6 })
          .toFormat('yyyy-LL-dd'),
      },
      pagination: {
        total: 0,
        limit: 10,
        offset: 0,
        pageSizes: [10, 20, 30, 40, 100],
      },
      activeTab: 'metrics',
      location: null,
      virtualAccounts: [],
      defaultTitle: null,
    }
  },
  async mounted() {
    if (this.defaultTitle === null) {
      this.defaultTitle = this.$route.meta['title'] || 'Locations'
    }
    if (DateTime.local().toUTC().startOf('month').weekday === 7) {
      this.weatherParams.startDate = DateTime.local()
        .toUTC()
        .startOf('month')
        .toFormat('yyyy-LL-dd')
    }
    if (DateTime.local().toUTC().endOf('month').weekday === 6) {
      this.weatherParams.endDate = DateTime.local()
        .toUTC()
        .endOf('month')
        .toFormat('yyyy-LL-dd')
    }
    const weatherQueryParams =
      typeof this.$route.query.startDate !== 'undefined'
        ? this.$route.query
        : undefined
    await this.getData()
    this.onDataChanged(weatherQueryParams || this.weatherParams)
  },
  computed: {
    ...mapGetters({
      hierarchy: 'locations/hierarchy',
      user: 'user/currentUser',
      dates: 'weather/dates',
    }),
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    hierarchyBreadcrumbs() {
      /**
       * Search for `location_id` through `hierarchy`,
       * if found then search for parents by reducing path
       */
      const hierarchy = _.get(this, 'hierarchy', []).slice()
      const found =
        hierarchy.find(
          (h) => h.locationId === _.get(this.location, 'id', null),
        ) || {}
      return [
        ..._.get(found, 'path', []).reduce(
          (acc, item) => [...acc, hierarchy.find((h) => h.id === item)],
          [],
        ),
        { id: found.id, name: _.get(found, 'location.name', '') },
      ]
    },
    isWeatherTabActive() {
      const currentCustomer =
        this.user &&
        this.user.customers.find(
          (customer) => customer.id === this.currentCustomerId,
        )
      return !!(
        currentCustomer &&
        currentCustomer.entitlements.weather &&
        this.location &&
        this.location.weatherActive
      )
    },
    isEnergyStarTabActive() {
      const currentCustomer =
        this.user &&
        this.user.customers.find(
          (customer) => customer.id === this.currentCustomerId,
        )
      return !!(currentCustomer && currentCustomer.entitlements.energyStar)
    },
    isCarbonFootprintTabActive() {
      const currentCustomer =
        this.user &&
        this.user.customers.find(
          (customer) => customer.id === this.currentCustomerId,
        )
      return !!(
        currentCustomer &&
        currentCustomer.entitlements &&
        currentCustomer.entitlements.carbonFootprint
      )
    },
  },
  watch: {
    activeTab: function (newTab) {
      const { customerId, locationId, tab = 'metrics' } = this.$route.params
      if (newTab === 'energy_star') {
        this.$route.meta.title = ENERGY_STAR_PAGE_TITLE
      } else if (newTab === 'bill_history') {
        this.$route.meta.title = BILL_HISTORY_PAGE_TITLE
      } else {
        this.$route.meta.title = this.defaultTitle
      }
      if (newTab !== tab) {
        if (newTab === 'weather') {
          return this.$router.push({
            name: 'dashboard.locations.info.tab',
            params: { customerId, locationId, tab: newTab },
            query: this.weatherParams,
          })
        }
        if (newTab === 'virtual_accounts') {
          return this.changeVirtualAccounts({ offset: 0, limit: 10, newTab })
        }
        return this.$router.push({
          name: 'dashboard.locations.info.tab',
          params: { customerId, locationId, tab: newTab },
        })
      }
    },
    $route(to) {
      this.activeTab = R.pathOr('metrics', ['params', 'tab'], to)
    },
  },
  beforeMount() {
    this.activeTab = R.pathOr('metrics', ['tab'], this.$route.params)
  },
  methods: {
    ...mapActions({
      $fetchFeeds: 'weather/fetchFeeds',
      $appendFeeds: 'weather/appendFeeds',
    }),
    async changeVirtualAccounts(query) {
      const { offset, limit, newTab } = query
      this.pagination.offset = offset
      this.pagination.limit = limit
      const { customerId, locationId } = this.$route.params
      await this.$router.push({
        name: 'dashboard.locations.info.tab',
        params: { customerId, locationId, tab: newTab },
        query: { offset, limit },
      })
      this.getVirtualAccounts(query)
    },
    async getData() {
      if (this.activeTab === 'virtual_accounts') {
        await this.changeVirtualAccounts({
          offset: 0,
          limit: 10,
          newTab: 'virtual_accounts',
        })
      } else {
        await this.getVirtualAccounts()
      }
      await this.getLocation()
    },
    async getVirtualAccounts(query) {
      const { results, total } = await locations.virtualAccounts(
        this.currentCustomerId,
        this.$route.params.locationId,
        query,
      )
      this.virtualAccountsGrouped = results.map((current) => {
        const mainVirtualAccount = current.virtualAccounts.find(
          (currentAccount) => {
            return currentAccount.id === current.virtualAccountGroupId
          },
        )
        mainVirtualAccount.children = [
          ...current.virtualAccounts.filter((currentAccount) => {
            return currentAccount.id !== mainVirtualAccount.id
          }),
        ]
        return mainVirtualAccount
      })
      this.virtualAccounts = _.flatten(
        (results || []).map(({ virtualAccounts }) => virtualAccounts),
      )
      this.pagination.total = total
      this.isLoading = false
    },
    async getLocation() {
      try {
        const response = await locations.location(
          this.currentCustomerId,
          this.$route.params.locationId,
        )
        this.location = {
          ...response,
          ...(response.payload && { payload: dot.object(response.payload) }),
        }

        this.isLoading = false
      } catch (error) {
        const code = R.pathOr('', ['response', 'data', 'code'], error)
        if (code === 404) {
          Events.$emit('not.found')
        }
      }
    },
    async onDataChanged(weatherParams) {
      if (!this.isWeatherTabActive) {
        return
      }
      const { tab } = this.$route.params
      if (tab === 'weather') {
        await this.$router
          .replace({ query: weatherParams })
          .catch((err) => console.log(err.name))
      }
      await this.$fetchFeeds({
        customerId: this.currentCustomerId,
        locationId: this.$route.params.locationId,
        startDate: weatherParams.startDate,
        endDate: weatherParams.endDate,
      })
      this.loading = false
      const { minDate, maxDate } = this.dates
      const startDate = DateTime.fromFormat(minDate || '', 'yyyy-MM-dd')
      const endDate = DateTime.fromFormat(maxDate || '', 'yyyy-MM-dd')
      this.datesMinMax = { startDate, endDate }
      this.isLoading = false
    },
    navigateToHierarchyPage() {
      if (this.currentCustomerId) {
        this.$router.push({
          name: 'dashboard.locations.hierarchy',
          params: { customerId: this.currentCustomerId },
        })
      } else {
        this.$router.go(-1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../styles/_utilities';

.content-inner {
  padding-right: 40px;
  padding-left: 40px;
  -webkit-overflow-scrolling: touch;

  @include respond-to($phone) {
    padding: 10px;
  }

  .location-header {
    margin-bottom: 0;
    @include respond-to($phone) {
      margin-left: 20px;
    }
  }

  .hierarchy-breadcrumbs {
    margin-top: 7px;
    margin-left: 34px;
    min-height: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    color: #7d89a6;
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;

    @include respond-to($phone) {
      margin-left: 24px;
    }

    &-item {
      .name,
      .nav {
        margin-right: 6px;
      }

      &:last-child {
        .nav {
          display: none;
        }
      }

      a {
        color: #7d89a6;
        text-decoration: none;

        &:visited,
        &:focus,
        &:active {
          color: #7d89a6;
        }
      }
    }
  }

  .location-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &_title {
      color: #333333;
      font-size: 24px;
      font-weight: 900;
    }

    &_back {
      cursor: pointer;
    }
  }

  .location-content {
    ::v-deep .el-tabs__header {
      margin-bottom: 30px;

      @include respond-to($phone) {
        margin: 0 20px 20px;
      }
    }

    ::v-deep .el-tabs__content {
      overflow: initial;
      /*min-height: calc(100vh - 180px);*/
    }

    ::v-deep .el-tabs__item {
      font-weight: 900;
    }

    ::v-deep .last-bill {
      color: #0080ff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    ::v-deep .virtual-accounts-table td {
      cursor: default;
    }
  }
}
</style>
