export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const csiStatusesForBatches = {
  TEMPORARY: 'in-progress',
  SUBMITTED: 'in-progress',
  AWAITING_APPROVALS: 'in-progress',
  EXCEEDS_THRESHOLD: 'in-progress',
  HOLD_FOR_FUNDING: 'in-progress',
  FUNDING_FAILED: 'failed',
  INSUFFICIENT_FUNDS: 'failed',
  IN_PROCESS: 'in-progress',
  VALIDATING: 'in-progress',
  PUBLISHED: 'in-progress',
  COMPLETE: 'completed',
  ERRORED: 'failed',
  FAILED_FILE_PROCESSING: 'failed',
  CANCELED: 'canceled',
  PENDING: 'in-progress',
}

export const csiStatusesForIndividualBills = {
  CREATED: 'in-progress',
  CANCELED: 'canceled',
  ERRORED: 'failed',
  PUBLISH_IN_PROCESS: 'in-progress',
  PUBLISH_COMPLETE: 'completed',
  PUBLISH_FAILED: 'failed',
  PUBLISH_CANCELED: 'canceled',
  RECONCILE_IN_PROGRESS: 'in-progress',
  RECONCILE_COMPLETE: 'completed',
  RECONCILE_FAILED: 'failed',
  RECONCILE_CANCELED: 'canceled',
  RECONCILE_DECLINED: 'failed',
}
