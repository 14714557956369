<template>
  <BaseInput
    class="base-input-form-component"
    v-model="inputValue"
    :vid="vid"
    :name="$attrs.name"
    :isEditMode="isEditMode"
    :label="label"
    mode="passive"
    :rules="rules"
    v-on="$listeners"
    :size="size"
    v-slot="{ validate }"
    :placeholder="$attrs.placeholder"
    :type="$attrs.type"
    :labelWidth="labelWidth"
  >
    <el-button
      type="text"
      class="add-button"
      @click.prevent="validate().then(addNew)"
    >
      Add+
    </el-button>
    <div style="display: flex; flex-direction: column">
      <el-tag
        v-for="(tag, index) in arrayOfTags"
        :key="index"
        closable
        @close="emitDelete(tag)"
      >
        {{ tag }}
      </el-tag>
    </div>
  </BaseInput>
</template>

<script>
import BaseInput from './BaseInput'
export default {
  props: {
    model: {
      type: String | Number,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
    arrayOfTags: {
      type: Array,
      default: () => [],
    },
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
    },
    label: {
      type: String,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: String,
      default: '125px',
    },
    width: {
      type: String,
      default: '',
    },
  },
  components: {
    BaseInput,
  },
  data() {
    return {
      initialMailTags: [...this.arrayOfTags],
      inputValue: '',
    }
  },
  methods: {
    reset() {
      this.inputValue = ''
      this.arrayOfTags.splice(0)
      this.arrayOfTags.push(...this.initialMailTags)
      this.$validator.reset()
    },
    reinit() {
      this.initialMailTags.splice(0)
      this.initialMailTags.push(...this.arrayOfTags)
      this.reset()
    },
    addNew(field = null) {
      if ((!field || field.valid) && this.inputValue !== '') {
        if (this.arrayOfTags.indexOf(this.inputValue) >= 0) {
          this.$message({
            showClose: true,
            message: `This email already exists. Please add a new email address.`,
            type: 'error',
          })
          return
        }
        if (this.arrayOfTags.length >= 49) {
          this.$message({
            showClose: true,
            message: `You have reached the 50 emails limit.`,
            type: 'error',
          })
          return
        }
        this.arrayOfTags.push(this.inputValue)
        this.inputValue = ''
        this.$emit('update-email-recipients', this.arrayOfTags)
      }
      this.$validator.reset()
    },
    emitDelete(tag) {
      this.$emit('emit-delete', tag)
    },
  },
}
</script>

<style lang="scss" scoped>
.base-input-form-component {
  ::v-deep .el-tag {
    background-color: #f4f4f5;
    margin: 10px 0 0 0;
    border-color: #e9e9eb;
    color: #91939a;
    .tags-margin {
      margin-left: 10px;
    }
    &__close {
      color: #91939a;
      font-weight: bold;
      background: #c0c4cc;
    }
  }
  .add-button {
    color: #91939a;
    margin-left: 10px;
  }
  ::v-deep .form-item {
    flex-wrap: wrap;
    width: 320px;
  }

  ::v-deep .form-item {
    .form-item-input {
      .pl-12 {
        padding: 0;
      }
    }
  }
}
</style>
