import * as API from '@/api'
import * as R from 'ramda'
import { DateTime } from 'luxon'

const REQUEST_FEEDS = 'REQUEST_FEEDS'
const RECEIVE_FEEDS = 'RECEIVE_FEEDS'
const RECEIVE_DATES = 'RECEIVE_DATES'
const APPEND_FEEDS = 'APPEND_FEEDS'
const SELECT_DATE = 'SELECT_DATE'

const initialState = () => ({
  isFetching: false,
  list: [],
  dates: {},
  meta: {},
  selectedDate: '',
})

export default {
  namespaced: true,
  state: initialState(),

  getters: {
    list: (state) => state.list,
    meta: (state) => state.meta,
    isFetching: (state) => state.isFetching,
    dates: (state) => state.dates,
    selectedDate: (state) => state.selectedDate,
  },

  mutations: {
    [RECEIVE_DATES]: (state, data) => {
      state.dates = data
    },

    [REQUEST_FEEDS]: (state) => {
      state.isFetching = true
    },

    [RECEIVE_FEEDS]: (state, response) => {
      const { data, meta } = response
      state.list = data || []
      state.meta = meta || {}

      state.isFetching = false
    },

    [APPEND_FEEDS]: (state, { list }) => {
      let l = [...state.list, ...list]
      state.list =
        R.pipe(R.uniqBy(R.prop('month')), R.sort(R.prop('month')))(l) || []
      state.isFetching = false
    },

    [SELECT_DATE]: (state, date) => {
      state.selectedDate = date
    },

    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async fetchFeeds(
      { commit, dispatch },
      { customerId, locationId, startDate, endDate },
    ) {
      await commit(REQUEST_FEEDS)
      try {
        const response = await API.weather.list(
          customerId,
          locationId,
          startDate,
          endDate,
        )
        const data = response.results ? [...response.results] : []
        for (
          let d = new Date(startDate);
          d <= new Date(endDate);
          d.setUTCDate(d.getUTCDate() + 1)
        ) {
          data.push({
            date: DateTime.fromJSDate(d, { zone: 'utc' }).toFormat(
              'yyyy-MM-dd',
              { zone: 'utc' },
            ),
          })
        }
        const weatherMonthly = {}
        data.filter((item) =>
          weatherMonthly.hasOwnProperty(item.date)
            ? false
            : (weatherMonthly[item.date] = { ...item }),
        )
        const weatherArr = {
          data: Object.values(weatherMonthly).sort((a, b) =>
            a.date < b.date ? -1 : 1,
          ),
        }
        await commit(RECEIVE_FEEDS, weatherArr)
        await dispatch('weather/setDatesInterval', response.dates, {
          root: true,
        })
      } catch (e) {
        await commit(RECEIVE_FEEDS, [])
      }
    },

    async appendFeeds({ commit, state }, { customerId, locationId }) {
      try {
        await commit(REQUEST_FEEDS)
        const { data } = await API.weather.list({
          customerId,
          locationId,
          params: state.params,
        })
        await commit(APPEND_FEEDS, {
          list: data,
          direction: state.params.direction,
        })
      } catch (e) {
        await commit(APPEND_FEEDS, { list: [] })
      }
    },

    async setDatesInterval({ commit }, dates) {
      try {
        await commit(RECEIVE_DATES, dates)
      } catch (e) {
        await commit(RECEIVE_DATES, {})
      }
    },

    selectDate({ commit }, date) {
      commit(SELECT_DATE, date)
    },
  },
}
