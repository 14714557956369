import { not, head, tail, isNil, propOr, reduce, merge } from 'ramda'

function serializeOrderBy(prop, order) {
  const orders = {
    ascending: 'asc',
    descending: 'desc',
  }

  if (not(order in orders)) {
    throw new Error('unknown order : ' + order)
  }

  return `${prop}.${orders[order]}`
}

function serializeOrderBySigns(prop, order) {
  const orders = {
    ascending: '-',
    descending: '+',
  }

  if (not(order in orders)) {
    throw new Error('unknown order : ' + order)
  }

  return `${orders[order]}${prop}`
}

function deserializeOrderBy(orderBy) {
  const orders = {
    '+': 'ascending',
    '-': 'descending',
  }

  if (isNil(orderBy)) {
    orderBy = ''
  }

  return {
    prop: tail(orderBy),
    order: orders[head(orderBy)],
  }
}

function setPieChartUtilityColor(utility) {
  switch (utility) {
    case 'SEWER':
      return '#43B493'
    case 'WATER':
      return '#7AC0E7'
    case 'REFUSE':
      return '#F3BC6A'
    case 'SOLARPV':
      return '#6D8ED1'
    case 'ELECTRIC':
      return '#C28226'
    case 'HOTWATER':
      return '#DB3B3B'
    case 'INTERNET':
      return '#D1AEAE'
    case 'LIGHTING':
      return '#93B443'
    case 'TELEPHONE':
      return '#B283D3'
    case 'IRRIGATION':
      return '#CC1B84'
    case 'NATURALGAS':
      return '#34598B'
    case 'STORMDRAIN':
      return '#888888'
    case 'STORMWATER':
      return '#E0EDA8'
    case 'CHILLEDWATER':
      return '#F7A6A6'
    case 'FIREPROTECTION':
      return '#842121'
  }
}

function vendorsPrettyMap(vendors = [], propWithCodes) {
  const providers = (acc, value) => {
    const { pretty_name } = value
    const codes = propOr([], propWithCodes, value)
    const prettyMap = reduce(
      (acc, code) => merge(acc, { [code]: pretty_name || code }),
      {},
      codes,
    )
    return merge(acc, prettyMap)
  }
  return reduce(providers, {}, vendors)
}

function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
  try {
    return JSON.parse(jsonPayload)
  } catch (e) {
    return {}
  }
}

const requiredString = (name, isCustomerData = true) => {
  return `Please enter ${isCustomerData ? 'your' : ''} ${name}.`
}
const requiredCustom = (name, rule) => {
  return `${name} must be a ${rule}.`
}
const customMessages = {
  custom: {
    username: {
      required: requiredString('username'),
    },
    password: {
      required: requiredString('password'),
    },
    confirmPass: {
      required: requiredString('password confirmation'),
      confirmed: () => 'Passwords do not match.',
    },
    firstName: {
      required: requiredString('firstname'),
    },
    lastName: {
      required: requiredString('lastname'),
    },
    propertyType: {
      required: requiredString('property type'),
    },
    email: {
      required: requiredString('email'),
    },
    yearBuilt: {
      required: requiredString('year built'),
    },
    phone: {
      required: requiredString('phone number'),
    },
    country: {
      required: requiredString('country'),
    },
    county: {
      required: requiredString('county'),
    },
    address1: {
      required: requiredString('address'),
    },
    city: {
      required: requiredString('city'),
    },
    state: {
      required: requiredString('state'),
    },
    postalCode: {
      required: requiredString('postal code'),
    },
    propertyName: {
      required: requiredString('property name'),
    },
    gross: {
      required: requiredString('gross floor area'),
      numeric: requiredCustom('Gross floor area', 'number'),
    },
    irrigated: {
      required: requiredString('irrigated area'),
      numeric: requiredCustom('Irrigated area', 'number'),
    },
    occupancy: {
      required: requiredString('occupancy'),
      included: requiredCustom('Occupancy', 'multiple of 5%'),
    },
    esName: {
      required: requiredString('ES Meter Name'),
    },
    esDate: {
      required: requiredString('Activation Date'),
    },
    inactiveDate: {
      required: requiredString('Inactivation Date'),
    },
  },
}
const buildHierarchy = (hierarchy, unmatchedLocations, locationsList) => {
  const hierarchies = []
  const listHierarchy = hierarchy.slice()
  const map = new Map()
  listHierarchy.forEach((hierarchy, i) => {
    map.set(hierarchy.id, i)
    hierarchy.children = []
    if (hierarchy.parentId) {
      if (hierarchy.location) {
        listHierarchy[map.get(hierarchy.parentId)].children.push(
          hierarchy.location,
        )
      } else if (hierarchy.name && !hierarchy.location) {
        listHierarchy[map.get(hierarchy.parentId)].children.push(hierarchy)
      }
    } else {
      hierarchies.push(hierarchy)
    }
  })
  hierarchies.push(...unmatchedLocations)
  return [
    {
      name: 'All Locations',
      children: hierarchies ? [...hierarchies] : [...locationsList],
    },
  ]
}

export {
  customMessages,
  serializeOrderBy,
  serializeOrderBySigns,
  deserializeOrderBy,
  setPieChartUtilityColor,
  vendorsPrettyMap,
  buildHierarchy,
  parseJwt,
}
