import { not, isEmpty, pickBy } from 'ramda'
import _ from 'lodash'
import * as API from '@/api'
import filters from './bills.filters'
import filtersData from './bills.filters.data.js'

const SET_LOADING_LIST = 'set_loading_list'
const SET_BILLS = 'set_bills'
const SET_TOTAL = 'set_total'
const SET_LOADING = 'set_loading'
const SET_FILTERS = 'set_filters'
const SET_EXCLUDE = 'set_exclude'
const SET_TOTAL_COMMENTS = 'setTotalComments'
const LOAD_MORE_COMMENTS = 'loadMoreComments'
const ADD_COMMENT = 'addComment'
const CLEAR_BILL = 'clearBill'

const defaultBillState = () => ({
  errors: [],
  files: [],
  comments: {},
})

const initialState = () => ({
  loading: false,
  list: [],
  total: null,
  bill: defaultBillState(),
  filtersData: [],
  exclude: ['extra'],
  data: {
    vendorCodes: [],
    locations: [],
  },
  currentBillExchangeRateObject: 'N/A',
})

export default {
  namespaced: true,
  modules: {
    filters,
    filtersData,
  },
  state: initialState(),

  mutations: {
    [SET_LOADING_LIST]: (state, flag) => {
      state.loading = flag
    },

    [SET_BILLS]: (state, bills) => {
      state.list = bills
    },

    [SET_TOTAL]: (state, total) => {
      state.total = total
    },

    [SET_LOADING]: (state, flag) => {
      state.loading = flag
    },

    [SET_FILTERS]: (state, filtersData) => {
      state.filtersData = filtersData
    },
    [SET_EXCLUDE]: (state, exclude) => {
      state.exclude = exclude
    },
    [SET_TOTAL_COMMENTS]: (state, total_comments) => {
      state.bill.comments = { ...state.bill.comments, total: total_comments }
    },
    [LOAD_MORE_COMMENTS]: (state, comments) => {
      state.bill.comments.data = [
        ...(state.bill.comments.data || []),
        ...(comments || []),
      ]
    },
    [ADD_COMMENT]: (state, comment) => {
      state.bill.comments.data = [comment, ...state.bill.comments.data]
      state.bill.comments.total++
    },
    [CLEAR_BILL]: (state) => {
      state.bill = defaultBillState()
    },
    resetState: (state) => {
      const initState = initialState()
      Object.assign(state, initState)
    },
  },

  actions: {
    async getExchangeRates(
      {
        commit,
        state,
        rootState: {
          customers: { currentCustomerId },
        },
      },
      billId,
    ) {
      try {
        state.currentBillExchangeRateObject = await API.bills.getExchangeRates(
          currentCustomerId,
          billId,
        )
      } catch (e) {
        console.log('error while loading preview: ', e)
      }
    },
    async loadBills({
      commit,
      state,
      rootState: {
        customers: { currentCustomerId },
      },
    }) {
      await commit(SET_LOADING_LIST, true)
      try {
        const params = pickBy(
          (val) => not(_.isNil(val)) && not(isEmpty(val)),
          state.filters.filters,
        )
        delete params.pageSizes
        params.exclude = state.exclude
        const { results, total, filters } = await API.bills.list({
          customerId: currentCustomerId,
          params,
          vendorsNames: {},
        })
        await commit(SET_BILLS, results)
        if (!_.isNil(total)) {
          await commit(SET_TOTAL, Number(total))
        }
        if (!_.isNil(filters)) {
          await commit(SET_FILTERS, filters)
        }
      } catch (err) {
        console.log(`error while loading bills: `, err)
      }
      await commit(SET_LOADING_LIST, false)
    },
  },

  getters: {
    currentBillExchangeRateObject: (state) =>
      state.currentBillExchangeRateObject,
    bills: (state) => state.list,
    total: (state) => state.total,
    loading: (state) => state.loading,
    filtersData: (state) => state.filtersData,
    comments: (state) => state.bill.comments,
    totalComments: (state) => state.bill.comments.total,
  },
}
