<template>
  <div class="widget-container" :class="{ draggable }" ref="widget-container">
    <div class="stack-chart" ref="stack-chart"></div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { chargesByVendorLabels } from './data'

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      default: 580,
    },
    height: {
      type: Number,
      default: 320,
    },
    barWidth: {
      type: Number,
      default: 55,
    },
    barItemWidth: {
      type: Number,
      default: 15,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Object,
      default: () => ({ x: 'location', y: 'costs' }),
    },
  },
  watch: {
    data() {
      this.redrawChart()
    },
    width() {
      this.redrawChart()
    },
  },
  mounted() {
    this.printChart()
  },
  methods: {
    redrawChart() {
      this.$refs['stack-chart'].innerHTML = ''
      this.printChart()
    },
    getBarLabelText(d) {
      return d3.format('$,.2f')(d)
    },
    calcTitleWidth(d) {
      return d3.format('$,.2f')(d).length * 6.8
    },
    printChart() {
      const instance = this
      const barChart = this.$refs['stack-chart']
      const svgHeight = this.height
      const barWidth = this.barWidth
      const barItemWidth = this.barItemWidth
      const margin = { left: 70, bottom: 100, top: 0, right: 0 }

      let data = this.data
      let svgWidth = this.width

      if (this.draggable) {
        svgWidth = (data.length + 1) * barWidth
      }

      let scaleFlag = false
      let barQty = parseInt(svgWidth / barWidth)
      barQty = data.length < 12 ? data.length : 12
      const realWidth = barWidth * barQty
      if (realWidth > svgWidth) {
        scaleFlag = true
      }

      if (!this.draggable) {
        data = data.slice(0, barQty)
      }

      const svg = d3
        .select(barChart)
        .append('svg')
        .attr('class', 'chart')
        .attr('width', svgWidth)
        .attr('height', svgHeight)

      if (scaleFlag) {
        svg
          .attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`)
          .attr('preserveAspectRatio', 'xMinYMin meet')
      } else {
        svg
          .attr('viewBox', `0 0 ${realWidth} ${svgHeight}`)
          .attr('preserveAspectRatio', 'xMinYMin meet')
      }

      const keys = [
        'commodityCharges',
        'consumptionCharges',
        'customerCharges',
        'demandCharges',
        'otherCharges',
        'taxesCharges',
      ]

      const x = d3
        .scaleBand()
        .range([margin.left, svgWidth - margin.right])
        .padding(0.1)

      const y = d3
        .scaleLinear()
        .rangeRound([svgHeight - margin.bottom, margin.top])

      svg
        .append('g')
        .attr('transform', `translate(-10, ${svgHeight - 50})`)
        .attr('class', 'axisX')
        .selectAll('text')
        .style('text-anchor', 'end')

      svg
        .append('g')
        .attr('transform', `translate(60, 50)`)
        .attr('class', 'axisY')

      const legend = svg
        .append('g')
        .attr('transform', `translate(0, 4)`)
        .attr('font-family', 'sans-serif')
        .attr('font-size', 10)
        .selectAll('g')
        .data(chargesByVendorLabels.slice())
        .enter()
        .append('g')
        .attr('class', 'legend')
        .attr('transform', function (d, i) {
          const xOff = (i % 3) * 120
          const yOff = Math.floor(i / 3) * 20
          return `translate(${xOff}, ${yOff})`
        })

      legend
        .append('rect')
        .attr('transform', 'translate(10,0)')
        .attr('width', 10)
        .attr('height', 10)
        .attr('fill', (d) => d.color)

      legend
        .append('text')
        .attr('dx', +25)
        .attr('dy', '0.8em')
        .style('text-anchor', 'front')
        .style('fill', '#666')
        .text((d) => d.title)

      const z = d3
        .scaleOrdinal()
        .range([
          '#2470E5',
          '#84B2FA',
          '#124BA2',
          '#1E88B7',
          '#B8D4FF',
          '#4B57D8',
        ])
        .domain(keys)

      data.forEach(function (d) {
        d.negTotal = d3.sum(keys, (k) => {
          return d[k] < 0 ? d[k] : 0
        })
        d.posTotal = d3.sum(keys, (k) => {
          return d[k] > 0 ? d[k] : 0
        })
        d.total = d3.sum(keys, (k) => +d[k])
        return d
      })

      y.domain([
        d3.min(data, (d) => d.negTotal),
        d3.max(data, (d) => d.posTotal),
      ])

      svg.selectAll('.axisY').call(
        d3
          .axisLeft(y)
          .ticks(null, 's')
          .tickFormat((d) => {
            return this.labels.y === 'costs'
              ? d3.format('$,~s')(d)
              : d3.format('$,~s')(d)
          }),
      )

      // add the Y gridlines
      svg
        .append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(56, 50)`)
        .call(make_y_gridlines().tickSize(-svgWidth).tickFormat(''))

      x.domain(data.map((d) => d.month))

      svg
        .selectAll('.axisX')
        .call(d3.axisBottom(x).tickSizeInner(5).tickSizeOuter(10))

      const group = svg
        .selectAll('g.bar-container')
        .data(d3.stack().keys(keys)(data), (d) => d.key)

      group.exit().remove()

      group
        .enter()
        .append('g')
        .classed('bar-container', true)
        .attr('transform', `translate(6, 50)`)
        .attr('fill', (d) => z(d.key))

      const { width: axisX_width } = barChart
        .querySelector('.axisX')
        .getBoundingClientRect()
      // const axisX_ticks = axisX.size();
      const lineDecorPosition = parseInt(axisX_width / data.length / 2)

      svg
        .selectAll('.axisX .tick:not(:last-child)')
        .append('line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 1)
        .attr('y2', 40)
        .attr('stroke', '#d8d8d8')
        .attr('transform', `translate(${lineDecorPosition} 0)`)

      svg.selectAll('.axisX .tick text').call(wrap, margin.bottom - 20)

      const tooltip = svg
        .append('g')
        .attr('class', 'bar-label')
        .style('display', 'none')

      tooltip
        .append('rect')
        .attr('height', 20)
        .attr('fill', 'white')
        .style('opacity', 1)

      tooltip.append('text').attr('x', 6).attr('y', 13)

      const bars = svg
        .selectAll('g.bar-container')
        .selectAll('rect')
        .data(
          (d) => d,
          (e) => e.data.month,
        )

      bars.exit().remove()

      bars
        .enter()
        .append('rect')
        .attr('width', barItemWidth)
        .on('mouseover', function () {
          tooltip.style('display', null)
        })
        .on('mouseout', function () {
          tooltip.style('display', 'none')
        })
        .on('mousemove', function (d) {
          const value = d[1] - d[0]
          const textWidth = instance.calcTitleWidth(value)
          const params = barChart
            .querySelector('.axisX')
            .getBoundingClientRect()
          let xPosition = d3.mouse(this)[0] - 5
          const yPosition = d3.mouse(this)[1] - 5
          if (xPosition + textWidth > params.width + margin.left) {
            xPosition -= textWidth - 15
          }
          xPosition = xPosition < 0 ? 0 : xPosition
          tooltip.attr('transform', `translate(${xPosition}, ${yPosition})`)
          tooltip.select('rect').attr('width', textWidth)
          tooltip.select('text').text(instance.getBarLabelText(value))
          // tooltip.select('text').text(value);
        })
        .merge(bars)
        .attr('x', (d) => x(d.data.month))
        .attr('y', (d) => {
          return y(d[1] > 0 ? d[1] : d[0])
        })
        .attr('height', (d) => Math.abs(y(d[0]) - y(d[1])))

      // gridlines in y axis function
      function make_y_gridlines() {
        return d3.axisLeft(y).ticks(6)
      }

      function wrap(text, width) {
        text.each(function () {
          let text = d3.select(this)
          let words = text.text().split(/\s+/).reverse()
          let word
          let line = []
          let lineNumber = 0
          let lineHeight = 1.4
          let y = text.attr('y')
          let dy = parseFloat(text.attr('dy'))
          let tspan = text
            .text(null)
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', dy + 'em')
          while ((word = words.pop())) {
            line.push(word)
            tspan.text(line.join(' '))
            if (tspan.node().getComputedTextLength() > width) {
              line.pop()
              tspan.text(line.join(' '))
              line = [word]
              tspan = text
                .append('tspan')
                .attr('x', 0)
                .attr('y', y)
                .attr('dy', `${++lineNumber * lineHeight + dy}em`)
                .text(word)
            }
          }
          if (text.node().children.length === 2) {
            text.attr('y', 0).selectAll('tspan').attr('y', 0)
          }
          if (text.node().children.length === 3) {
            text.attr('y', -7).selectAll('tspan').attr('y', -7)
          }
        })
      }
    },
    handleChartDrag() {
      const container = this.$refs['widget-container']
      const chart = container.querySelector('.chart')
      const marginLeft = 60

      container.addEventListener('scroll', ({ currentTarget: el }) => {
        container
          .querySelector('.chart > .axisY')
          .setAttributeNS(
            null,
            'transform',
            `translate(${marginLeft + el.scrollLeft} 24)`,
          )
      })

      const scrollSpeed = 2
      let dragging = false
      let startX = 0
      let scrollLeft = 0

      container.addEventListener('mousedown', ({ pageX }) => {
        dragging = true
        startX = pageX - container.offsetLeft
        scrollLeft = container.scrollLeft
        container.classList.add('active')
      })
      container.addEventListener('mousemove', ({ pageX }) => {
        if (dragging) {
          const posX = pageX - container.offsetLeft
          const shift = (posX - startX) * scrollSpeed
          container.scrollLeft = scrollLeft - shift
        }
      })
      container.addEventListener('mouseup', () => {
        dragging = false
        container.classList.remove('active')
      })
      container.addEventListener('mouseleave', () => {
        dragging = false
        container.classList.remove('active')
      })

      if (container.offsetWidth < chart.getAttribute('width')) {
        container.classList.add('is-draggable')
      }
    },
  },
}
</script>

<style lang="scss">
.widget-container {
  color: #484848;
  font-size: 10px;
  line-height: 14px;
}
.widget-container.draggable {
  overflow-x: auto;
}
.widget-container.is-draggable {
  cursor: pointer;
}
.widget-container.is-draggable.active {
  cursor: grabbing;
}
.widget-container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.widget-container::-webkit-scrollbar-track {
  background-color: rgba(216, 216, 216, 0.3);
}
.widget-container::-webkit-scrollbar-thumb {
  width: 4px;
  height: 4px;
  border-radius: 3px;
  background-color: #d8d8d8;
}
.stack-chart {
  position: relative;
  padding: 0 20px 20px 0;

  .bar-label {
    transition: none;
    opacity: 1;
  }
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.5;
  shape-rendering: crispEdges;
}

.grid path {
  stroke-width: 0;
}
.chart {
  display: block;
  user-select: none;
}
.chart [id^='label'] {
  font-family: sans-serif;
  font-size: 10px;
  fill: #474746;
  transform: rotate(-90deg);
  transition: opacity 0.25s;
  opacity: 0;
}
.chart g:hover > [id^='label'] {
  opacity: 1;
}
.axisY path {
  stroke: #d8d8d8;
}
.axisY line {
  stroke: #d8d8d8;
}
.axisY > .tick:nth-of-type(1) {
  display: none;
}
.axisX > path {
  stroke: #d8d8d8;
}
.axisX > g > line {
  stroke: #d8d8d8;
}
/*.bar-container:hover > .bar-label {*/
/*opacity: 1;*/
/*}*/
.bar {
  fill: #84b2fa;
  cursor: pointer;
}
.bar-label {
  /*transition: 0.25s;*/
  /*opacity: 0;*/
}
.bar-label > rect {
  fill: #515151;
}
.bar-label > text {
  fill: #ffffff;
}
</style>
