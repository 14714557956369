<template>
  <div class="payments-page">
    <div class="page-header">
      <div class="page-title">Payment Files</div>
    </div>
    <div class="filter">
      <div class="filter-container">
        <processing-date
          :dates="dates"
          title="Processing Date"
          placeholder="All months"
          @changeDate="changeDate"
        />
<!--        <el-row v-if="isBillPayEntitlement" class="completed-only-filter">-->
<!--          <el-col :span="7">-->
<!--            <label class="completed-only-filter__label">Completed Only</label>-->
<!--          </el-col>-->
<!--          <el-col :span="17">-->
<!--            <el-switch v-model="completedOnly" @change="changeCompletedOnly" />-->
<!--          </el-col>-->
<!--        </el-row>-->
      </div>
    </div>
    <div class="payments-list-container" v-loading="isFetching">
      <div class="payments-list" v-if="list.length">
        <div
          class="payments-list_item"
          v-for="payment in list"
          :key="String(payment.paymentFileDate)"
        >
          <div class="payments-list_item-header">
            <h2 :id="payment.title">{{ payment.title }}</h2>
            <div class="total-charges" v-if="!payment.type">
              <span>Total Amount Due:&nbsp;</span>
              <b>{{ payment.total | unit('USD') }}</b>
            </div>
          </div>
          <div class="payments-list_item-body" v-if="!payment.type">
            <el-table
              :data="formatStr2Number(payment.data)"
              :default-sort="{ prop: 'date', order: 'descending' }"
            >
              <el-table-column
                label="Date"
                prop="date"
                sortable=""
              ></el-table-column>
              <el-table-column label="Qty of Bills" prop="count" sortable="">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="
                      showBillsList(scope.row.paymentFileDate, scope.row.date)
                    "
                  >
                    {{ scope.row.count }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column label="Amount Due" prop="sum" sortable="">
                <template slot-scope="scope">
                  <b>{{ scope.row.sum | unit('USD') }}</b>
                </template>
              </el-table-column>
<!--              <el-table-column-->
<!--                v-if="isBillPayEntitlement"-->
<!--                label="Status"-->
<!--                width="220"-->
<!--                prop="status"-->
<!--                sortable=""-->
<!--              >-->
<!--                <template slot-scope="scope">-->
<!--                  <div-->
<!--                    class="pill"-->
<!--                    :class="`status__${billStatusClass(scope.row.batchStatus)}`"-->
<!--                  >-->
<!--                    {{ getReadableBatchStatus(scope.row.batchStatus) }}-->
<!--                  </div>-->
<!--                </template>-->
<!--              </el-table-column>-->
              <el-table-column label="Download Payment File" width="500">
                <template slot-scope="scope">
                  <download-dropdown-desktop
                    :data="scope.row"
                    :paymentFileTypes="propOr([], 'paymentFileTypes', customer)"
                    :customerId="currentCustomerId || ''"
                  ></download-dropdown-desktop>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="payments-list-empty-gap" v-else>
            <span>No payment files for this time period.</span>
          </div>
        </div>
      </div>
      <div class="payments-list-empty" v-else>
        <h2>No payment files processed.</h2>
      </div>
      <network-error v-if="error" @refresh="loadPage"></network-error>
    </div>
    <el-dialog
      :visible.sync="showPaymentDetailsModal"
      :title="`Bill Payment Details for ${dateLabel}`"
      width="1350px"
    >
      <payment-details-modal
        :bills="bills"
        :currentCustomerId="currentCustomerId"
      ></payment-details-modal>
      <div class="dialog-footer" slot="footer">
        <el-button
          type="primary"
          size="small"
          round
          @click="closePaymentDetailsModal"
        >
          OK
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { DateTime } from 'luxon'
import { payments } from '@/api'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ProcessingDate from '@/components/ProcessingDate.vue'
import PaymentDetailsModal from './PaymentDetailsModal.vue'
import DownloadDropdownDesktop from './DownloadDropdownDesktop.vue'
import NetworkError from '@/components/Controls/NetworkError'
import * as R from 'ramda'
import { Events } from '@/lib'
// import { csiStatusesForBatches } from './helpers'

export default {
  components: {
    ProcessingDate,
    PaymentDetailsModal,
    DownloadDropdownDesktop,
    NetworkError,
  },
  data() {
    return {
      payment_file_type: '',
      paymentsData: [],
      bills: [],
      list: [],
      dateLabel: '',
      showPaymentDetailsModal: false,
      loading: true,
      selector: '.payments-list-container',
      error: false,
    }
  },
  computed: {
    ...mapGetters({
      customer: 'customers/customer',
      $list: 'payments/list',
      isFetching: 'payments/isFetching',
      batchStatus: 'payments/batchStatus',
      dates: 'payments/dates',
      params: 'payments/params',
      isPaymentsEntitlement: 'customers/isPaymentsEntitlement',
      isBillPayEntitlement: 'customers/isBillPayEntitlement',
      currentCustomerId: 'customers/currentCustomerId',
    }),
    // completedOnly: {
    //   get() {
    //     return !!this.params.batchStatus
    //   },
    //   async set(val) {
    //     await this.$setParams({
    //       ...this.params,
    //       batchStatus: val ? 'COMPLETE' : '',
    //     })
    //     await this.loadPayments()
    //   },
    // },
  },
  watch: {
    $list: {
      handler() {
        this.list = this.$list
      },
      immediate: true,
    },
    async currentCustomerId() {
      await this.init()
    },
  },
  async mounted() {
    // await this.getCompletedOnlyVal()
    await this.loadPage()
  },
  beforeDestroy() {
    const el = document.querySelector(this.selector)
    this.resetState()
    if (el) {
      el.removeEventListener('scroll', this.onWindowScroll)
    }
  },
  methods: {
    ...mapActions({
      $fetchPayments: 'payments/fetchPayments',
      $setParams: 'payments/setParams',
      $appendPayments: 'payments/appendPayments',
      $getDatesList: 'payments/getDatesList',
    }),
    ...mapMutations({
      resetState: 'payments/resetState',
    }),
    propOr: R.propOr,
    // getReadableBatchStatus(batchStatus) {
    //   return batchStatus ? batchStatus.replaceAll('_', ' ') : '-'
    // },
    // getCompletedOnlyVal() {
    //   const billPayCurrentStatuses = this.getCompletedOnly()
    //   if (!this.isBillPayEntitlement) {
    //     this.completedOnly = false
    //     return
    //   }
    //   if (this.isBillPayEntitlement && billPayCurrentStatuses) {
    //     const currentCustomerBatchStatus = this.findCurrentCustomerBatchStatus(
    //       billPayCurrentStatuses,
    //     )
    //     this.completedOnly = currentCustomerBatchStatus
    //       ? currentCustomerBatchStatus.completedOnly
    //       : true
    //     return
    //   }
    //   this.completedOnly = true
    // },
    // billStatusClass(status) {
    //   return csiStatusesForBatches[status]
    // },
    // updateCurrentCustomerBatchStatus(
    //   billPayCurrentStatuses,
    //   currentCustomerBatchStatus,
    // ) {
    //   return billPayCurrentStatuses.map((currCustomerBatchStatus) => {
    //     if (+currCustomerBatchStatus.id === +currentCustomerBatchStatus.id) {
    //       currCustomerBatchStatus = currentCustomerBatchStatus
    //     }
    //     return currCustomerBatchStatus
    //   })
    // },
    // findCurrentCustomerBatchStatus(billPayCurrentStatuses) {
    //   return billPayCurrentStatuses.find(
    //     (status) => +status.customerId === +this.$route.params.customerId,
    //   )
    // },
    // addFilterStatus(val) {
    //   const billPayCurrentStatuses = this.getCompletedOnly()
    //   const currentCustomerBatchStatus = this.findCurrentCustomerBatchStatus(
    //     billPayCurrentStatuses,
    //   )
    //   const completedOnlyObj = {
    //     customerId: this.$route.params.customerId,
    //     completedOnly: val,
    //   }
    //   let batchStatusArr = []
    //   if (currentCustomerBatchStatus) {
    //     currentCustomerBatchStatus.completedOnly = val
    //     batchStatusArr = this.updateCurrentCustomerBatchStatus(
    //       billPayCurrentStatuses,
    //       currentCustomerBatchStatus,
    //     )
    //   } else {
    //     billPayCurrentStatuses.push(completedOnlyObj)
    //     batchStatusArr = billPayCurrentStatuses
    //   }
    //   return JSON.stringify(batchStatusArr)
    // },
    // changeCompletedOnly(val) {
    //   localStorage.setItem(
    //     'completedOnly',
    //     JSON.stringify(this.addFilterStatus(val)),
    //   )
    //   this.list = this.$list
    // },
    // getCompletedOnly() {
    //   const res = JSON.parse(localStorage.getItem('completedOnly'))
    //   return res ? JSON.parse(res) : []
    // },
    async loadPage() {
      if (!this.isPaymentsEntitlement) {
        Events.$emit('not.found')
        return
      }
      await this.init()
      const el = document.querySelector(this.selector)
      if (el) {
        el.addEventListener('scroll', this.onWindowScroll)
      }
    },
    async init() {
      if (!this.isPaymentsEntitlement) {
        return
      }
      await this.$getDatesList()
      if (this.dates.length) {
        await this.$setParams({
          ...this.params,
          startDate: DateTime.fromJSDate(new Date())
            .toFormat('yyyy-LL-dd')
            .toString(),
        })
      }
      await this.loadPayments()
    },
    async loadPayments() {
      const error = await this.$fetchPayments()
      this.error = error === 500
    },
    formatStr2Number(data) {
      const copy = data.slice()
      copy.forEach((el, index, arr) => {
        arr[index].count = +arr[index].count
      })
      return copy
    },
    async onWindowScroll(e) {
      if (!this.list.length || this.isFetching) return
      const container = e.target
      if (
        container.offsetHeight + container.scrollTop >=
        container.scrollHeight
      ) {
        const lastMonthData = _.get(_.last(this.$list), 'data', [])
        const lastDate = _.get(_.last(lastMonthData), 'paymentFileDate', null)
        if (
          DateTime.fromISO(lastDate, { zone: 'utc' })
            .toFormat('yyyy-LL')
            .toString() !== _.first(this.dates)
        ) {
          await this.$setParams({
            direction: '-',
            ...(lastDate && {
              startDate: DateTime.fromISO(lastDate, { zone: 'utc' })
                .toFormat('yyyy-LL-dd')
                .toString(),
            }),
            period: 12,
          })
          await this.$appendPayments()
        }
        return
      }

      if (container.scrollTop <= 0) {
        const lastMonthData = _.get(_.first(this.$list), 'data', [])
        const lastDate = _.get(_.first(lastMonthData), 'paymentFileDate', null)
        if (
          DateTime.fromISO(lastDate, { zone: 'utc' })
            .toFormat('yyyy-LL')
            .toString() !== _.last(this.dates)
        ) {
          const initHeight = container.scrollHeight
          await this.$setParams({
            direction: '+',
            ...(lastDate && {
              startDate: DateTime.fromISO(lastDate, { zone: 'utc' })
                .toFormat('yyyy-LL-dd')
                .toString(),
            }),
            period: 12,
          })
          await this.$appendPayments()
          this.$nextTick(() =>
            container.scroll(0, container.scrollHeight - initHeight + 1),
          )
        }
      }
    },
    openPaymentDetailsModal() {
      this.showPaymentDetailsModal = true
    },
    closePaymentDetailsModal() {
      this.showPaymentDetailsModal = false
    },
    async changeDate(time) {
      let dateString = null
      const id = DateTime.fromMillis(+time)
        .toFormat('MMMM yyyy')
        .toString()
      const element = document.getElementById(id)
      if (element) {
        this.$nextTick(() => element.scrollIntoView())
        return
      }
      if (!time) {
        if (R.isEmpty(this.dates)) {
          return
        }
        dateString = DateTime.fromFormat(_.last(this.dates), 'yyyy-LL', {
          zone: 'utc',
        })
          .endOf('month')
          .toFormat('yyyy-LL-dd')
          .toString()
      } else {
        dateString = DateTime.fromMillis(+time)
          .endOf('month')
          .toFormat('yyyy-LL-dd')
          .toString()
      }

      await this.$setParams({
        direction: '-',
        startDate: dateString,
        period: 12,
      })
      await this.loadPayments()
      this.$nextTick(() => document.querySelector(this.selector).scroll(0, 1))
    },
    async showBillsList(date, dateLabel) {
      const data = await payments.getPaymentBills({
        customerId: this.currentCustomerId,
        date: DateTime.fromISO(String(date), { zone: 'utc' }).toISODate(),
      })
      this.bills = data
      this.dateLabel = dateLabel
      this.openPaymentDetailsModal()
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../styles/_utilities';
@import './../../../styles/bill-statuses';

.content-inner {
  padding-right: 40px;
  padding-left: 40px;
  .payments-list-container {
    min-height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    @extend %scrollbar;
  }
}
.completed-only-filter {
  width: 450px;
  color: #606266;
  margin-top: 16px;
  margin-left: 8px;
  &__label {
    font-size: 14px;
    margin-right: 8px;
  }
}
.payments-list {
  max-height: calc(100vh - 199px);
  min-height: calc(100vh - 199px);
  .payments-list-empty-gap {
    color: #646464;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 42px;
  }

  &-container {
    min-height: 50vh;
  }
  &_item {
    margin-right: 10px;
    &:not(:last-child) {
      margin-bottom: 42px;

      &::after {
        display: block;
        margin: 0;
        content: '';
        border-bottom: 1px solid #dcdcdc;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;
      margin-bottom: 15px;

      h2 {
        margin: 0;
        color: #222222;
        font-size: 22px;
        line-height: 32px;
      }
    }
  }
  &-empty {
    h2 {
      display: inline-block;
      padding: 18px 10px 18px 0;
      margin: 0;
      color: #c3c3c3;
      font-size: 22px;
      line-height: 32px;
      border-bottom: 1px solid #dfdfdf;
    }
  }
}
.total-charges {
  color: #222222;
  font-size: 18px;
  line-height: 25px;

  b {
    font-weight: 900;
  }
}
.dialog-footer {
  text-align: center;

  .el-button {
    min-width: 140px;
  }
}
</style>

<style lang="scss">
.payments-page .filter .filter-item-date .el-input {
  width: 160px;
}
</style>
