import { render, staticRenderFns } from "./ShowRemoveModal.vue?vue&type=template&id=30514204&scoped=true&"
import script from "./ShowRemoveModal.vue?vue&type=script&lang=js&"
export * from "./ShowRemoveModal.vue?vue&type=script&lang=js&"
import style0 from "./ShowRemoveModal.vue?vue&type=style&index=0&id=30514204&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30514204",
  null
  
)

export default component.exports