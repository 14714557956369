<template lang="pug">
.filter-item.filter-item-date
  .filter-title(v-if="title") {{ title }}
  el-radio-group(v-model="type")
    el-radio(label="year_to_date", data-value="radio-year-to-date") Year to-date
    el-radio(label="period", data-value="radio-custom") Custom
    el-radio(label="months", data-value="radio-last-month") Last x months
    el-radio(label="all", data-value="radio-all-time") All time
  el-input-number(v-if="type === 'months'",
                  data-value="input-month-value",
                  v-model="month",
                  :min="1",
                  :max="maxMonths",
                  size="small")
  .date-picker(v-if="type === 'period'")
    el-date-picker(v-model="start_date",
                    format="MMMM yyyy",
                    value-format="yyyy-MM-dd",
                    :editable="false"
                    type="month",
                    size="mini",
                    data-value="select-start-date",
                    placeholder="Select First Month")
    el-date-picker(v-model="end_date",
                  format="MMMM yyyy",
                  value-format="yyyy-MM-dd",
                  :editable="false"
                  type="month",
                  size="mini",
                  data-value="select-end-date",
                  placeholder="Select Last Month")
</template>

<script>
import _ from 'lodash'
import FilterDatepicker from '@/components/Filters/FilterDatepicker'
import { DateTime } from 'luxon'
export default {
  components: {
    FilterDatepicker,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    maxMonths: {
      type: Number,
      default: 360,
    },
  },
  beforeMount() {
    this.filter.type = _.get(this.value, 'type', 'year_to_date')
    this.type = this.filter.type
    if (this.value.months) {
      this.filter.months = this.value.months
    }
  },
  data() {
    return {
      start_date:
        this.filter.start_date || _.get(this.value, 'period.start_date', null),
      end_date:
        this.filter.end_date || _.get(this.value, 'period.end_date', null),
      type: this.filter.type || _.get(this.value, 'type', 'all'),
      month: this.filter.count || _.get(this.value, 'months', 0),
    }
  },
  watch: {
    value() {
      this.start_date = _.get(this.value, 'period.start_date', null)
      this.end_date = _.get(this.value, 'period.end_date', null)
      this.type = _.get(this.value, 'type', 'all')
      this.month = _.get(this.value, 'months', 0)
    },
    type(val) {
      this.filter.type = val
      if (val === 'all') {
        this.start_date = null
        this.end_date = null
      }
      if (val === 'year_to_date') {
        this.filter.year_to_date = true
      } else {
        delete this.filter.year_to_date
      }
      if (val === 'months') {
        this.filter.count = this.month
      }
      this.$emit('validate', this.twoDatesSelected())
    },
    start_date: {
      immediate: true,
      handler: function (val) {
        if (this.type !== 'period') {
          return
        }
        this.filter.start_date = val
          ? DateTime.fromFormat(val, 'yyyy-LL-dd', { zone: 'utc' }).toFormat(
            'yyyy-LL-dd',
          )
          : null
        this.filter.type = 'period'
        this.$emit('validate', this.twoDatesSelected())
      },
    },
    end_date: {
      immediate: true,
      handler: function (val) {
        if (this.type !== 'period') {
          return
        }
        this.filter.end_date = val
          ? DateTime.fromFormat(val, 'yyyy-LL-dd', { zone: 'utc' })
            .endOf('month')
            .toFormat('yyyy-LL-dd')
          : null
        this.filter.type = 'period'
        this.$emit('validate', this.twoDatesSelected())
      },
    },
    month(val) {
      const count = parseInt(val)
      if (count > this.maxMonths) {
        this.month = this.maxMonths
        return
      }
      if (count > 0) {
        this.filter.end_date = DateTime.fromMillis(Date.now(), { zone: 'utc' })
          .startOf('month')
          .toFormat('yyyy-LL-dd')
        this.filter.start_date = DateTime.fromMillis(Date.now(), {
          zone: 'utc',
        })
          .minus({ months: count })
          .startOf('month')
          .toFormat('yyyy-LL-dd')
        this.filter.type = 'months'
        this.filter.count = count
      } else {
        this.filter.start_date = null
        this.filter.end_date = null
      }
    },
  },
  methods: {
    twoDatesSelected() {
      if (this.type === 'period') {
        return (
          !_.isEmpty(this.filter.start_date) && !_.isEmpty(this.filter.end_date)
        )
      } else {
        return true
      }
    },
  },
}
</script>

<style scoped lang="scss">
.filter-item {
  ::v-deep .el-radio-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -7px;
    .el-radio {
      padding: 5px 7px;
    }
    .el-radio + .el-radio {
      margin: 0;
    }
  }
  .date-picker {
    > div:first-child {
      margin-right: 16px;
    }
    ::v-deep .el-date-editor {
      input {
        padding: 0 10px 0 30px;
      }
    }
  }
}
</style>
