<template lang="pug">
  .service-account
    collapse-block(title='General Information', mode='inner')
      .bill-field_col
        .bill-field
          .bill-field_label Service Account
          .bill-field_value {{ item.accountCode }}
        .bill-field
          .bill-field_label Service Address
          .bill-field_value {{ item.serviceAddress || '-' }}
        .bill-field
          .bill-field_label Service Zip
          .bill-field_value {{ item.serviceZip || '-' }}
        .bill-field
          .bill-field_label Meter ID
          .bill-field_value {{ item.meterSerial }}
        .bill-field
          .bill-field_label Service Location
          .bill-field_value {{ item.serviceLocation || 'N/A' }}
        .bill-field
          .bill-field_label Vendor Type
          .bill-field_value {{ item.billType || '-'}}
        .bill-field(v-if='item.location && item.location.id')
          .bill-field_label Location Address
          .bill-field_value
            router-link.internal-link(:to='`/${currentCustomerId}/locations/${item.location.id}`', data-testid="locationAddress") {{ item.location.address }}
        .bill-field(v-if='item.location && item.location.id')
          .bill-field_label Location Description
          .bill-field_value
            router-link.internal-link(:to='`/${currentCustomerId}/locations/${item.location.id}`', data-testid="locationDescription") {{ item.location.name }}
      .bill-field_col
        .bill-field
          .bill-field_label Service Start Date
          .bill-field_value {{ item.startDate | dateFormatted }}
        .bill-field
          .bill-field_label Service End Date
          .bill-field_value {{ item.endDate | dateFormatted }}
        .bill-field
          .bill-field_label Days of Service
          .bill-field_value {{ item.daysOfService }} ({{ item.serviceDateMethod || 'N/A' }})
        .bill-field
          .bill-field_label Adjusted Service End Date
          .bill-field_value {{ item.adjustedEndDate | dateFormatted }} ({{ adjustedDaysOfService }})
        .bill-field
          .bill-field_label Bill Subtotal
          .bill-field_value {{ subTotal.value | unit(subTotal.units, nonUsBill) }}
        .bill-field
          .bill-field_label Rate Plan
          .bill-field_value {{ item.rateCode }}
        .bill-field(v-if='item.location && item.location.id')
          .bill-field_label Prior Meter Reading
          router-link.internal-link(v-if='item.priorBillId', :to='`/${currentCustomerId}/bills/${item.priorBillId}`') {{ item.priorBillId }}
          .bill-field_value(v-else, data-type='prior-first-bill', data-value) First Bill
    template(v-for="category in categories")
      collapse-block.service-account-item(v-if='getCategoryBLI(category).length !== 0', :title='category', mode='inner')
        .bill-field(v-for='bli in getCategoryBLI(category)')
          template(v-if="bli.type === 'C'")
            .bill-field_label {{ (bli.value ||  '') }}
            .bill-field_value(v-if='bli.usage') {{ (+(bli.usage || 0)).toFixed(3) | unit(bli.uom ? bli.uom : 'KWH', nonUsBill) }}
            .bill-field_value(v-else) &nbsp;
            .bill-field_cost(v-if='!nonUsBill') {{ (bli.charge || 0) | currency }}
            .bill-field_cost(v-if='nonUsBill') {{ bli.nativeCurrency }} {{ (bli.nativeCharge || 0) | currency('') }}
          template(v-else-if="bli.type === 'U'")
            .bill-field_label {{ (bli.value ||  '') }}
            .bill-field_value {{ (+(bli.usage || 0)).toFixed(3) | unit(bli.uom ? bli.uom : 'KWH', nonUsBill) }}
            .bill-field_cost(v-if='bli.charge && !nonUsBill') {{ (bli.charge || 0) | currency }}
            .bill-field_cost(v-if='bli.charge && nonUsBill') {{ bli.nativeCurrency }} {{ (bli.nativeCharge || 0) | currency('') }}
          template(v-else-if="bli.type === 'UC'")
            .bill-field_label {{ (bli.value ||  '') }}
            .bill-field_value {{ (+(bli.usage || 0)).toFixed(3) | unit(bli.uom ? bli.uom : 'KWH', nonUsBill) }}
            .bill-field_cost(v-if='!nonUsBill') {{ (bli.charge || 0) | currency }}
            .bill-field_cost(v-if='nonUsBill') {{ bli.nativeCurrency }} {{ (bli.nativeCharge || 0) | currency('') }}
    .divider
    collapse-block.calculated-values(title='Calculated Values',
      mode='inner',
      :collapsed="collapseCalculatedValues",
      @click.native="collapseCalculatedValues = !collapseCalculatedValues")
      collapse-block(v-if="calculationsRegularFields && calculationsRegularFields.length > 0", title='Regular Values', mode='inner')
        .bill-field_col(v-for="c in chunkBy2(calculationsRegularFields)")
          .bill-field(v-for="val of c", :key="val.name")
            .bill-field_label {{ (val.label || val.name) }}
            .bill-field_value {{ (+(val.value || 0)).toFixed(3) | unit(val ? val.units : '', nonUsBill) }}
      collapse-block(v-if="calculationsWeatherFields && calculationsWeatherFields.length > 0", title='Weather-calculated values', mode='inner')
        .bill-field_col(v-for="c in chunkBy2Weather(calculationsWeatherFields)")
          .bill-field(v-for="val of c", :key="val.name")
            .bill-field_label {{ (val.label || val.name) }}
            .bill-field_value {{ (+(val.value || 0)).toFixed(3) | unit(val && val.units === 'KWHDD' ? val.units : '', nonUsBill) }}
      collapse-block(v-if="calculationsDavFields && calculationsDavFields.length > 0", title='Daily-average Values', mode='inner')
        .bill-field_col(v-for="c in chunkBy2(calculationsDavFields)")
          .bill-field(v-for="val of c", :key="val.name")
            .bill-field_label {{ (val.label || val.name) }}
            .bill-field_value {{ (+(val.value || 0)).toFixed(3) | unit(val ? val.units : '', nonUsBill) }}
</template>

<script>
import CollapseBlock from '@/components/CollapseBlock.vue'
import { get } from 'lodash'
import { mapState } from 'vuex'
import { DateTime, Interval } from 'luxon'
import * as R from 'ramda'

export default {
  props: {
    nonUsBill: {
      type: Boolean,
      default: () => false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    hasObservations: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CollapseBlock,
  },
  filters: {
    dateFormatted(value) {
      if (!value) return ''
      return DateTime.fromISO(value, { zone: 'utc' }).toFormat('MM/dd/yyyy')
    },
  },
  data() {
    return {
      collapseCalculatedValues: true,
    }
  },
  computed: {
    subTotal() {
      return R.pathOr([], ['calculations', 'regular'], this.item).find(
        (e) => e.name === 'total_charges_billblock',
      )
    },
    calculationsRegularFields() {
      const calculationsRegular = get(this.item, 'calculations.regular', [])

      const sortedRegularCalc = calculationsRegular.sort((a, b) =>
        a.label < b.label ? -1 : 1,
      )
      return sortedRegularCalc
    },
    calculationsWeatherFields() {
      const calculationsWeather = get(
        this.item,
        'weatherCalculations.weather',
        [],
      )
      const sortedWeatherCalculation = calculationsWeather.sort((a, b) =>
        a.name < b.name ? 1 : -1,
      )
      return sortedWeatherCalculation
    },
    calculationsDavFields() {
      const calculationsDav = get(this.item, 'calculations.dav', [])
      const sortedDavCalculation = calculationsDav.sort((a, b) =>
        a.label < b.label ? -1 : 1,
      )
      return sortedDavCalculation
    },
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),

    adjustedDaysOfService() {
      const start = DateTime.fromISO(this.item.startDate)
      const end = DateTime.fromISO(this.item.adjustedEndDate)
      const i = Interval.fromDateTimes(start, end)
      const days = Math.round(i.length('days')) + 1
      return isNaN(days) ? '' : days
    },
  },
  methods: {
    chunkBy2(arr = []) {
      return [arr.filter((el, i) => !(i % 2)), arr.filter((el, i) => i % 2)]
    },
    chunkBy2Weather(arr = []) {
      const chunk1 = arr.filter((el, i) => i < 4)
      const chunk2 = arr.filter((el, i) => i >= 4)
      chunk2.push(chunk2.shift())
      this.moveIndex(chunk1, 2, 1)
      this.moveIndex(chunk2, 2, 0)
      return [chunk1, chunk2]
    },
    getCategoryBLI(category) {
      const billingLineItems = R.pathOr([], ['billingLineItems'], this.item)
      return R.filter(R.propEq('category', category))(billingLineItems) || []
    },
    moveIndex(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1
        while (k--) {
          arr.push(undefined)
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
      return arr
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/utilities';
.service-account {
  width: 100%;

  .divider {
    width: 100%;
    height: 1px;
    margin: 20px auto 0 auto;
    border-top: 1px solid #dcdfe6;
  }

  &-item {
    .bill-field {
      &_label {
        flex: 2 2 0%;

        @include respond-to($phone) {
          flex: 0 0 180px;
          padding-right: 15px;
        }
      }

      &_value {
        flex: 1 1 0;
      }

      &_cost {
        flex: 2 2 0;

        @include respond-to($tablet-l) {
          flex-grow: 1;
          flex-shrink: 1;
        }
      }
    }
  }
  ::v-deep .calculated-values.collapse.inner > .collapse_header b {
    font-size: 16px;
  }
}

.calculated-values {
  .bill-field_label {
    flex-basis: 50%;
    padding-right: 15px;
  }
}

.internal-link {
  font-weight: 500;
}
</style>
