import * as R from 'ramda'
import * as dot from 'dot-object'
import { hosts } from '@/lib/api.configs'
import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'
import store from '../store'
import qs from 'qs'
import router from '../router'

const list = ({ customerId, params }) =>
  http(hosts.v3)
    .get(
      `/customers/${customerId}/bills?${qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
    )
    .then(dataMiddleware)

const getExchangeRates = (customerId, billId) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/bills/${billId}/exchange-rate`)
    .then(dataMiddleware)

/**
 * @private
 * @param {*} bill
 */
const setBillCommodity = (bill) => ({
  ...bill,
  commodity: R.uniq((bill.items || []).map((e) => e.commodity || ''))
    .filter((e) => e.length > 0)
    .join(', '),
})

const parseBillCharges = (bill) => {
  const billingLineItem = (description) =>
    bill.billingLineItems.filter((lineItem) => {
      return lineItem.description === description
    })[0]

  const currentChargesLineItem = billingLineItem('Current Charges') || {}
  const totalChargesLineItem = billingLineItem('Total Amount Due') || {}
  const pastDueAmountLineItem = billingLineItem('Past Due Amount') || {}

  const getLineItemValue = (key, lineItem, defaultTo = 0) =>
    lineItem[key] || defaultTo

  const currency = getLineItemValue('currency', currentChargesLineItem, null)
  const nativeCurrency = getLineItemValue(
    'nativeCurrency',
    currentChargesLineItem,
    null,
  )

  return {
    ...bill,
    nativeCurrency,
    currency,
    currentCharges: getLineItemValue('charge', currentChargesLineItem),
    currentChargesNativeCharge: getLineItemValue(
      'nativeCharge',
      currentChargesLineItem,
    ),
    totalCharges: getLineItemValue('charge', totalChargesLineItem),
    totalChargesNativeCharge: getLineItemValue(
      'nativeCharge',
      totalChargesLineItem,
    ),
    pastDueAmount: getLineItemValue('charge', pastDueAmountLineItem),
    pastDueAmountNativeCharge: getLineItemValue(
      'nativeCharge',
      pastDueAmountLineItem,
    ),
  }
}

const compareCalculateField = (field) => {
  const calculations = R.pathOr(
    [],
    ['value'],
    store.getters['resources/getResourceByName']('calculations'),
  )
    .map((item) => ({
      ...item,
      ...(item.name.indexOf('daily') === 0 && { type: 'dav' }),
    }))
    .map((f) => ({ ...f, label: f.description }))
  return { ...field, ...calculations.find((e) => e.name === field.name) }
}

const compareWeatherCalculateField = (field) => {
  const weatherCalculations = R.pathOr(
    [],
    ['value'],
    store.getters['resources/getResourceByName']('weather_calculations'),
  ).map((f) => ({ ...f, label: f.description }))
  return { ...field, ...weatherCalculations.find((e) => e.name === field.name) }
}

const billValidationDefinitions = () =>
  http(hosts.v3)
    .get('resources/bill-validation-definitions?limit=100')
    .then(dataMiddleware)

const prepareCalculationsFields = (bill) => ({
  ...bill,
  items: [
    ...(bill.items || []).map((e) => ({
      ...e,
      calculations: R.groupBy((e) => e.type || 'regular')([
        ...(e.calculations || [])
          .filter((i) => i.name.indexOf('total_consumption') !== 0)
          .filter((i) => i.name.indexOf('daily_consumption') !== 0)
          .map(compareCalculateField),
      ]),
      weatherCalculations: R.groupBy((e) => e.type || 'weather')([
        ...(e.weatherCalculations || []).map(compareWeatherCalculateField),
      ]),
    })),
  ],
})
const preparePayload = (bill) => ({
  ...bill,
  payload: { ...dot.object(bill.payload) },
})

/**
 * @private
 */
const reduceBill = R.compose(
  parseBillCharges,
  setBillCommodity,
  prepareCalculationsFields,
  preparePayload,
)

/**
 * @public
 * @description method allows to get bill by it's id
 * @param {number} customerId id identity key of the customer
 * @param {number} billId id identity key of bill
 */
const get = ({ customerId, billId }) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/bills/${billId}`)
    .then(dataMiddleware)
    .then(reduceBill)
    .catch((error) => {
      const status = R.path(['response', 'status'], error)
      if (status === 404) {
        router.history.replace(router.match('/error/404'))
      }
    })

const exportBill = ({ type, startDate, endDate }) => {
  return http()
    .get(`/bills/export`, {
      params: { type, start_date: startDate, end_date: endDate },
    })
    .then(dataMiddleware)
}

/**
 * @public
 * @description method allows to get bill pdf download link by it's id
 * @param {number} customerId id identity key of the customer
 * @param {number} billId id identity key of bill
 */
const getPdfDownloadLink = ({ customerId, billId }) =>
  http(hosts.v3)
    .get(`/customers/${customerId}/bills/${billId}/pdf`)
    .then(dataMiddleware)

const getFiltersData = () =>
  http(hosts.v1).get(`/bills/filters`).then(dataMiddleware)

const getComments = (customerId, billId) => {
  return http(hosts.v3)
    .get(`/customers/${customerId}/bills/${billId}/activity-history`)
    .then(dataMiddleware)
}

const createComment = (customerId, billId, message, chatTags) => {
  return http(hosts.v3)
    .post(`/customers/${customerId}/bills/${billId}/comments`, {
      message,
      chatTags,
    })
    .then(dataMiddleware)
}

export {
  list,
  get,
  getComments,
  exportBill,
  getPdfDownloadLink,
  getFiltersData,
  getExchangeRates,
  createComment,
  billValidationDefinitions,
}
