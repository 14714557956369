<template lang="pug">
.filter-item.filter-item-location
  .filter-title
    span(v-if="title") {{ title }}
  el-radio-group(v-model="type")
    el-radio(label="all") All locations
    el-radio(label="custom") Select custom locations
  select-location(:is-visible="isLocationModalVisible",
                  :selectedLocations="selectedLocations",
                  data-value="select-location",
                  @onChange="selectLocations",
                  @close="isLocationModalVisible = false")
  .selected-locations(v-if="type === 'custom'")
    span(v-for="(location, index) in selectedLocations")
      template(v-if="index < (maxLocationsShow - 1)") {{ `${location.address}, ` }}
      template(v-if="index === maxLocationsShow") {{ location.address }}...
  el-button.button-filter-action(v-if="type === 'custom'",
                                type="text",
                                @click="isLocationModalVisible = true") Select
</template>

<script>
import SelectLocation from '@/pages/Dashboard/Dashboard/Controls/SelectLocation.vue'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  components: {
    SelectLocation,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  beforeMount() {
    const { locations, value } = this
    if (!_.isEmpty(locations) && !_.isEmpty(value)) {
      this.selectedLocations = value.map(
        (id) => locations.find((l) => parseInt(l.id) === id) || {},
      )
    }
  },
  data() {
    return {
      isLocationModalVisible: false,
      selectedLocations: [],
      type: this.value.length ? 'custom' : 'all',
      maxLocationsShow: 10,
    }
  },
  watch: {
    locations(val) {
      if (val && val.length) {
        this.selectedLocations = this.value.map(
          (id) => val.find((l) => parseInt(l.id) === id) || {},
        )
      }
    },
    type(val) {
      if (val === 'all') {
        this.selectLocations([])
      }
    },
  },
  computed: {
    ...mapGetters({
      locations: 'locations/locations',
    }),
  },
  methods: {
    selectLocations(val) {
      this.filter.location_ids = val.map((e) => Number(e.id))
      this.selectedLocations = val
    },
  },
}
</script>

<style lang="scss" scoped>
.selected-locations {
  width: 100%;
  margin-bottom: 18px;
  color: #606266;
  font-size: 12px;
  line-height: 20px;
}
.filter-item.filter-item-location .button-filter-action {
  width: auto;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  background: none;
  text-decoration: underline;
}
</style>
