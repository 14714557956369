import { postRunNowReport, getReports } from '../../api/reports/create-reports'
import { Message } from 'element-ui'

const initialState = () => {
  return {
    isLoading: true,
    budgetingTemplates: [],
    total: 0,
    pagination: {
      limit: 10,
      offset: 0,
      pageSizes: [10, 20, 30, 40, 100],
    },
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    budgetingTemplates: (state) => state.budgetingTemplates,
    isLoading: (state) => state.isLoading,
    pagination: (state) => state.pagination,
    currentPage: (state) =>
      state.pagination.offset / state.pagination.limit + 1,
    total: (state) => state.total,
  },
  mutations: {
    setBudgetingTemplates(state, budgetingTemplates) {
      state.budgetingTemplates = budgetingTemplates
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setTotal(state, total) {
      state.total = total
    },
    setPagination(state, pagination) {
      Object.assign(state.pagination, pagination)
    },
    resetState(state) {
      Object.assign(state, initialState())
    },
  },
  actions: {
    async getBudgetingTemplates(
      { dispatch, commit },
      { customerId, params = {} },
    ) {
      params.featureType = 'budgeting'
      try {
        const { results } = await getReports(customerId, params)
        commit('setTotal', results.length)
        commit('setBudgetingTemplates', results)
      } catch (e) {
        console.log(e)
      } finally {
        commit('setLoading', false)
      }
    },
    async createNewBudgetingReport({ dispatch, commit, state }, body) {
      try {
        await postRunNowReport(body.customerId, body)
        Message({
          showClose: true,
          type: 'success',
          message: `${body.customReportName} was successfully created. Please check the “Budget List” to download the .xlsl file.`,
        })
      } catch (e) {
        console.log(e)
        Message({
          showClose: true,
          type: 'error',
          message: 'Your report could not be created. Please try again later.',
        })
      }
    },
    changePagination({ commit }, pagination) {
      commit('setPagination', pagination)
    },
  },
}
