<template>
  <el-dialog
    class="advanced-options-modal"
    :visible="visible"
    width="720px"
    :title="title"
    @close="onClose"
    @open="onOpen"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <template #title>
      <h2 class="title">{{ title }}</h2>
      <h4 class="subtitle">
        Budget Type:
        <span class="subtitle-bdgName">
          {{ budgetType }}
        </span>
      </h4>
    </template>
    <el-tabs v-model="activeTab">
      <el-tab-pane
        v-if="isOnBudgetingList"
        class="tab"
        label="Budget Settings"
        :name="tabsList[0]"
      >
        <budget-settings-tab
          ref="budgetSettingsTab"
          :opened="opened"
          :is-edit-modal="isEditModal"
          :budget-settings-refresh="advancedOptions.budgetSettings"
        />
      </el-tab-pane>
      <el-tab-pane class="tab" label="Filters" :name="tabsList[1]">
        <filters-tab
          ref="filtersTab"
          :opened="opened"
          :applied-filters="advancedOptions.advancedFilters"
          :available-filters="availableFilters"
          :hierarchies="hierarchy"
        />
      </el-tab-pane>
      <el-tab-pane class="tab" label="Usage Config" :name="tabsList[2]">
        <usage-config-tab
          ref="usageConfigTab"
          :opened="opened"
          :usage-config-refresh="advancedOptions.usageConfig"
        />
      </el-tab-pane>
      <el-tab-pane
        :opened="opened"
        class="tab"
        label="Unit Cost Config"
        :name="tabsList[3]"
      >
        <unit-cost-config
          ref="unitCostConfigTab"
          :opened="opened"
          :unit-cost-config-refresh="advancedOptions.unitCostConfig"
        />
      </el-tab-pane>
    </el-tabs>
    <modal-btns @close="onClose" @confirm="onConfirm">
      <template #cancel>Cancel</template>
      <template #confirm>
        {{ getButtonLabel }}
      </template>
    </modal-btns>
  </el-dialog>
</template>

<script>
import FiltersTab from './Components/FiltersTab'
import ModalBtns from '../../../../components/ModalBtns'
import { mapGetters } from 'vuex'
import { buildHierarchy } from '../../../../lib/helpers'
import UsageConfigTab from './Components/UsageConfigTab'
import UnitCostConfig from './Components/UnitCostConfig'
import BudgetSettingsTab from './Components/BudgetSettingsTab'

export default {
  components: {
    BudgetSettingsTab,
    UnitCostConfig,
    UsageConfigTab,
    ModalBtns,
    FiltersTab,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    budgetType: {
      type: String,
      default: '',
    },
    isDuplicateModal: {
      type: Boolean,
      default: false,
    },
    isEditModal: {
      type: Boolean,
      default: false,
    },
    isOnBudgetingList: {
      type: Boolean,
      default: false,
    },
    advancedOptions: {
      type: Object,
      default: () => {},
    },
    availableFilters: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      locations: 'locations/locations',
    }),
    getButtonLabel() {
      return this.isEditModal
        ? 'Update Budget'
        : this.isDuplicateModal
          ? 'Duplicate Budget'
          : 'Confirm'
    },
  },
  watch: {
    isVisible(val) {
      this.visible = val
    },
  },
  data() {
    return {
      visible: false,
      opened: false,
      activeTab: '',
      title: 'Advanced Options',
      hierarchy: [],
      tabsList: ['budgetSettings', 'filters', 'usageConfig', 'unitCostConfig'],
    }
  },
  methods: {
    onOpen() {
      if (this.isOnBudgetingList) {
        this.activeTab = this.tabsList[0]
        this.title = this.isEditModal
          ? `Edit ${this.advancedOptions.budgetSettings.budgetName}`
          : this.isDuplicateModal
            ? `Duplicate ${this.advancedOptions.budgetSettings.budgetName}`
            : 'Confirm'
      } else {
        this.activeTab = this.tabsList[1]
      }
      this.opened = true
      this.createHierarchy()
    },
    createHierarchy() {
      const hierarchiesArr = this.availableFilters.find((filters) =>
        filters.find((filter) => filter && filter.element === 'hierarchy'),
      )
      const [hierarchiesProps] = hierarchiesArr
      this.hierarchy = buildHierarchy(
        hierarchiesProps.availableValues.hierarchies,
        hierarchiesProps.availableValues.unmatchedLocations,
        this.locations,
      )
    },
    onClose() {
      this.opened = false
      this.activeTab = ''
      this.hierarchy = []
      if (this.isOnBudgetingList) {
        this.$refs.budgetSettingsTab.clearValidation()
      }
      this.$refs.usageConfigTab.clearValidation()
      this.$emit('close')
    },
    onConfirm() {
      if (
        this.$refs.usageConfigTab.validateForm() ||
        (this.isOnBudgetingList && this.$refs.budgetSettingsTab.validateForm())
      ) {
        return
      }
      const advancedOptionsObj = {
        budgetSettings: this.isOnBudgetingList
          ? this.$refs.budgetSettingsTab.budgetSettings
          : undefined,
        advancedFilters: {
          ...this.$refs.filtersTab.advancedFilters,
          utility_type: !Array.isArray(
            this.$refs.filtersTab.advancedFilters.utility_type,
          )
            ? [this.$refs.filtersTab.advancedFilters.utility_type]
            : this.$refs.filtersTab.advancedFilters.utility_type,
        },
        usageConfig: {
          ...this.$refs.usageConfigTab.usageConfig,
        },
        unitCostConfig: {
          ...this.$refs.unitCostConfigTab.unitCostConfig,
        },
      }
      this.$emit('confirm', advancedOptionsObj)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.advanced-options-modal {
  .el-range-editor {
    padding: 0 10px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .el-form-item__label {
    padding: 0;
  }
  .el-dialog {
    border-radius: 8px;
  }
  .el-dialog__body {
    padding: 0 32px;
  }
  .el-dialog__header {
    padding: 20px 32px 10px;
  }
  .el-tabs__nav-scroll {
    width: 100%;
  }
  .el-form-item-label {
    padding: 0;
  }
  .el-tabs__item {
    padding: 0 50px;
  }

  .el-tabs__nav-wrap::after {
    background-color: #ffffff;
  }
}
.title {
  margin: 0;
  font-size: 18px;
}
.subtitle {
  font-size: 14px;
  margin-bottom: 0;
  &-bdgName {
    font-weight: normal;
  }
}

.tab {
  padding: 10px 0;
}
</style>
