export const appliedFiltersDefault = {
  utility_type: ['all'],
  vendor: ['all'],
  location_id: [],
}

export const unitCostConfigDefault = {
  unitCostType: 'rateRegression',
  adjustmentRate: 0,
}

export const usageConfigDefault = {
  outlierThreshold: 10,
  outlierWindow: 5,
  historicalData: 'all',
  historicalDates: [],
  loadProjBasedOnHistoricalData: 'median',
  gapFillingMethod: 'averageOfClosest',
  fillInGaps: true,
  usageData: true,
}
