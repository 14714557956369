<template>
  <base-modal
    title="Virtual Account Details"
    width="500px"
    :dialog-visible="showMoreDetails"
    @open="open"
    @close="close"
  >
    <template #content>
      <div class="more-details" v-if="hasVirtualAccountAttributes">
        <p class="title">Custom Attributes</p>
        <el-row
          class="va-container"
          v-for="virtualAccountAttribute in virtualAccountAttributes"
          :key="virtualAccountAttribute.id"
        >
          <el-col :span="10">
            {{ virtualAccountAttribute.attributeName }}:
          </el-col>
          <el-col :span="14">
            {{ virtualAccountAttribute.attributeValue || 'N/A' }}
          </el-col>
        </el-row>
      </div>
      <div v-else>N/A</div>
    </template>
    <template #footer>
      <el-button class="close-btn" type="primary" @click="close">
        Close
      </el-button>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import { locations } from '@/api'

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    virtualAccount: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BaseModal,
  },
  watch: {
    dialogVisible(val) {
      this.showMoreDetails = val
    },
  },
  computed: {
    hasVirtualAccountAttributes() {
      return this.virtualAccountAttributes.length > 0
    },
  },
  data() {
    return {
      showMoreDetails: false,
      virtualAccountAttributes: [],
    }
  },
  methods: {
    async open() {
      const selectedVaAttributes = await locations.getVirtualAccountAttributes(
        this.$route.params.customerId,
        this.virtualAccount.virtualAccountId || this.virtualAccount.id,
      )
      const vaAttributes = await locations.getVirtualAccountAttributesList(
        this.$route.params.customerId,
      )
      this.virtualAccountAttributes = vaAttributes.results.map(
        (virtualAccountAttr) => {
          for (const selectedVirtualAccountAttr of selectedVaAttributes) {
            if (
              +virtualAccountAttr.id ===
                +selectedVirtualAccountAttr.virtualAccountsAttributesMetadataId &&
              selectedVirtualAccountAttr.attributeValue
            ) {
              virtualAccountAttr.attributeValue = `${
                selectedVirtualAccountAttr.attributeValue
              }
            ${
  selectedVirtualAccountAttr.virtualAccountsAttributesMetadata
    .attributeType === 'Numeric'
    ? selectedVirtualAccountAttr.virtualAccountsAttributesMetadata
      .attributeUom
    : ''
}`
            }
          }
          return virtualAccountAttr
        },
      )
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 20px;
}
.more-details {
  max-height: 90vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #d8d8d8;
  }
}
.va-container {
  margin-bottom: 20px;
}
.title {
  color: #273a58;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.close-btn {
  width: 140px;
  padding: 10px;
}
</style>
