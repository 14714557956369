<template lang="pug">
  .filter-item-inner.select-filter-field
    el-select(@change="onChange",
      :value="selected",
      :size="size",
      :placeholder="placeholder",
      :data-action="`filter-select-${filterName}`",
       multiple=true,
       clearable=true,
      filterable
      :popper-append-to-body="false",
      :popper-class="`filter-select-${filterName}`")
      el-option(v-if="typeof options[0] === 'object'", v-for='item in options',
        :key="item[itemValue]",
        :label="item[itemLabel]",
        :value="item[itemValue]")
      el-option(v-else, :key="item", :value="item", :label="stateLabel(item)")
    .select-box
      select(v-model="selectedOptions",
        :class="{'show-label': !selectedOptions.length}",
        multiple="",
        :data-action="`filter-select-${filterName}-mobile`",
        :value="selected.length > 0 ? selected : null",
        @change="onChangeMobile")
        option(v-if="typeof options[0] === 'object'", v-for='item in options',
          :key="item[itemValue]",
          :value="item[itemValue]") {{ item[itemLabel] }}
        option(v-else, :key="item", :value="item") {{ stateLabel(item) }}
      i.el-select__caret.el-input__icon.el-icon-arrow-down
      label(v-if="!selectedOptions.length") {{ placeholder }}
</template>

<script>
import { states } from '@/lib/lists'
import * as R from 'ramda'

export default {
  props: {
    filterName: { type: String },
    onSelectChange: { type: Function },
    options: { type: Array },
    itemValue: { type: String, default: () => 'value' },
    itemLabel: { type: String, default: () => 'name' },
    placeholder: { type: String, default: () => 'Select' },
    size: { type: String, default: () => 'mini' },
    selected: { type: Array, default: () => [] },
  },
  data() {
    return {
      value: null,
      selectedOptions: [],
    }
  },
  created() {
    this.selectedOptions = this.selected
  },
  methods: {
    onChange(value) {
      this.onSelectChange({ [this.filterName]: value })
    },
    onChangeMobile({ target: { options } }) {
      this.onSelectChange({ [this.filterName]: this.selectedOptions })
    },
    stateLabel(state) {
      if (this.filterName !== 'state') {
        return state
      }
      const usa = R.pathOr([], ['usa'], states)
      const canada = R.pathOr([], ['canada'], states)
      const stateObj =
        R.find(R.propEq('value', state))(usa) ||
        R.find(R.propEq('value', state))(canada)
      if (stateObj && stateObj.label) {
        return `${stateObj.label} (${state})`
      }
      return state
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/utilities';

.select-filter-field {
  ::v-deep .filter-select-type {
    .el-select-dropdown__wrap {
      max-height: initial !important;
    }
  }

  .el-select {
    @include respond-to($phone) {
      display: none;
    }
  }

  .select-box {
    display: none;
    position: relative;
    width: 100%;

    @include respond-to($phone) {
      display: block;
    }

    select {
      position: relative;
      height: 36px;
      padding: 0 20px;
      color: #909399;
      font-size: 16px;
      line-height: 36px;

      &.show-label {
        opacity: 0;

        &:focus {
          opacity: 1;

          ~ label {
            opacity: 0;
          }
        }
      }
    }

    i {
      position: absolute;
      top: 0;
      right: 5px;
      color: #c0c4cc;
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 7px 20px;
      color: #909399;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      border: 1px solid #dcdfe6;
      border-radius: 10px;
      background-color: #fff;
      box-sizing: border-box;
      z-index: -1;
    }
  }

  @include respond-to($phone) {
    input[type='text'] {
      font-size: 16px;
    }
    select {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 30px;
      padding: 0 15px;
      color: #606266;
      border-radius: 10px;
      line-height: 30px;
      border: 1px solid #dcdfe6;
      font-size: inherit;
      background-color: #fff;
      background-image: none;
      box-sizing: border-box;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      appearance: none;
      outline: none;
    }
  }
}
</style>
