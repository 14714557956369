<template>
  <BaseModal
    :title="title"
    width="80%"
    :dialogVisible="showModal"
    @close="close"
    class="preview-base-modal"
  >
    <template #content>
      <div class="preview-wrapper">
        <div class="preview-header">
          <span>Qty of rows:</span>
          <span class="value">{{ (previewList || []).length }} /</span>
          <span class="bold">{{ totalAmount || 0 }}</span>
        </div>
        <div class="desktop-wrapper" v-if="!$deviceInfo.isMobileDevice">
          <div class="desktop-table-wrapper">
            <el-table
              height="430"
              class="preview-table"
              :data="previewListCasted"
              v-loading="previewLoading"
            >
              <el-table-column
                label="Bill ID"
                prop="AnalyticsBillingLineItems.bill_id"
                width="100px"
                sortable=""
              >
                <template slot-scope="scope">
                  <router-link
                    class="bill-id"
                    :to="`/${$route.params.customerId}/bills/${scope.row['AnalyticsBillingLineItems.bill_id']}`"
                  >
                    {{ scope.row['AnalyticsBillingLineItems.bill_id'] }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column label="Vendor" prop="vendor_code" sortable="">
                <template slot-scope="scope">
                  <span
                    v-if="scope.row['AnalyticsBillingLineItems.vendor_name']"
                  >
                    {{ scope.row['AnalyticsBillingLineItems.vendor_name'] }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Invoice Date"
                prop="statement_date"
                sortable=""
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row['AnalyticsBillingLineItems.statement_date']"
                  >
                    {{
                      scope.row['AnalyticsBillingLineItems.statement_date']
                        | dateFormatted
                    }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="Description" prop="value" sortable="">
                <template slot-scope="scope">
                  <span v-if="scope.row['AnalyticsBillingLineItems.value']">
                    {{ scope.row['AnalyticsBillingLineItems.value'] }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Usage"
                prop="usage"
                sortable=""
                :sort-method="sortBy('usage')"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row['AnalyticsBillingLineItems.usage']">
                    {{
                      (scope.row['AnalyticsBillingLineItems.usage'] || 0)
                        | unit(scope.row['AnalyticsBillingLineItems.uom'])
                    }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Charge"
                prop="charge"
                sortable=""
                :sort-method="sortBy('charge')"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row['AnalyticsBillingLineItems.charge']">
                    {{
                      (scope.row['AnalyticsBillingLineItems.charge'] || 0)
                        | unit(scope.row['AnalyticsBillingLineItems.currency'])
                    }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Pear.ai category"
                prop="category"
                sortable=""
                width="200"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row['AnalyticsBillingLineItems.category']">
                    {{ scope.row['AnalyticsBillingLineItems.category'] }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column label="Location" prop="location_id" sortable="">
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row[
                        'AnalyticsBillingLineItems.location_description'
                      ]
                    "
                  >
                    {{
                      scope.row[
                        'AnalyticsBillingLineItems.location_description'
                      ]
                    }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <template v-else v-loading="previewLoading">
          <div class="no-data" v-if="!previewList.length">
            <p>No Data</p>
          </div>
          <div
            class="preview-item"
            v-else
            v-for="(item, index) in previewList"
            :key="index"
          >
            <div class="preview-item_content">
              <div class="preview-item_col">
                <div class="preview-item_label">Bill ID</div>
                <div class="preview-item_value">{{ item.bill_id }}</div>
              </div>
              <div class="preview-item_col">
                <div class="preview-item_label">Vendor</div>
                <div class="preview-item_value">{{ item.vendor_code }}</div>
              </div>
              <div class="preview-item_col">
                <div class="preview-item_label">Invoice Date</div>
                <div class="preview-item_value">
                  {{ item.statement_date | dateFormatted }}
                </div>
              </div>
              <div class="preview-item_col">
                <div class="preview-item_label">Description</div>
                <div class="preview-item_value" v-if="item.value">
                  {{ item.value }}
                </div>
                <div class="preview-item_value" v-else>-</div>
              </div>
              <div class="preview-item_col">
                <div class="preview-item_label">Usage</div>
                <div class="preview-item_value" v-if="item.usage">
                  {{ (item.usage || 0) | unit(item.uom) }}
                </div>
                <div class="preview-item_value" v-else>-</div>
              </div>
              <div class="preview-item_col">
                <div class="preview-item_label">Charges</div>
                <div class="preview-item_value" v-if="item.charge">
                  {{ (item.charge || 0) | unit(item.currency) }}
                </div>
                <div class="preview-item_value" v-else>-</div>
              </div>
              <div class="preview-item_col">
                <div class="preview-item_label">Pear.ai category</div>
                <div class="preview-item_value" v-if="item.category">
                  {{ item.category }}
                </div>
                <div class="preview-item_value" v-else>-</div>
              </div>
              <div class="preview-item_col">
                <div class="preview-item_label">Location</div>
                <div class="preview-item_value" v-if="item.location_id">
                  {{ findLocation(item.location_id) }}
                </div>
                <div class="preview-item_value" v-else>-</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template #footer>
      <el-button class="button-size" type="primary" round @click="close">
        Ok
      </el-button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import { DateTime } from 'luxon'
import _ from 'lodash'

export default {
  props: {
    previewLoading: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    report: {
      type: Object,
      default: () => {},
    },
    previewList: {
      type: Array,
      required: true,
      default: () => [],
    },
    totalAmount: {
      type: Number,
      required: true,
      default: () => 0,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    locations: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  name: 'preview',
  filters: {
    dateFormatted(value) {
      if (!value) return ''
      return DateTime.fromISO(value, { zone: 'utc' }).toFormat('LL/dd/yyyy')
    },
  },
  data() {
    return {
      showModal: false,
      title: 'Preview',
    }
  },
  components: {
    BaseModal,
  },
  computed: {
    previewListCasted() {
      const data = _.cloneDeep(this.previewList)
      data.forEach((el) => {
        el.bill_id = +el.bill_id
        if (el.usage_value) {
          el.usage_value = +(el.usage_value || 0)
        }
        if (el.charge_value) {
          el.charge_value = +(el.charge_value || 0)
        }
      })
      return data || []
    },
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  methods: {
    findLocation(id) {
      return (_.find(this.locations, (l) => l.id === id) || {}).name
    },
    close() {
      this.$emit('close', false)
    },
    sortBy(key) {
      return (a, b) => {
        if (!_.isNumber(a[key]) && !_.isNumber(b[key])) {
          return 0
        }
        if (!_.isNumber(a[key]) || !_.isNumber(b[key])) {
          return -1
        }
        return a[key] - b[key]
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import './../../../../../styles/_utilities';

.preview-base-modal {
  .button-size {
    margin-top: 2em;
    width: 200px;
    font-weight: bold;
  }
  .preview-wrapper {
    ::v-deep .el-table {
      .cell {
        word-break: break-word;
      }
    }

    .preview-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 10px;

      span {
        margin-left: 4px;
        color: #7d89a6;
        font-size: 12px;
        font-weight: 700;
        line-height: normal;

        &.value {
          color: #818181;
          font-size: 16px;
          font-weight: 400;
        }

        &.bold {
          color: #3b3e48;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .no-data {
      margin-top: 40px;

      p {
        text-align: center;
        margin: 0;
        color: #909399;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 0.09px;
        line-height: 22px;
      }
    }

    .button-wrapper {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .el-button {
        border-radius: 15px;
        background-color: #0080ff;
        color: #ffffff;
        font-size: 14px;
        font-weight: 900;
        line-height: 14px;
        text-align: center;
        padding: 8px 80px;
      }
    }
    .preview-table {
      width: 100%;
      ::v-deep .el-table__body-wrapper {
        @extend %scrollbar;
      }
      .bill-id {
        &:visited {
          color: #0000fe;
        }
      }
    }
    .desktop-table-wrapper {
      max-height: 400px;
    }

    .preview {
      &-item {
        margin-bottom: 10px;
        font-weight: 600;
        border: 1px solid #dcdfe6;
        border-radius: 2px;
        background-color: #fff;

        &.is-selected {
          border-color: #0080ff;
        }

        &_content {
          display: flex;
          flex-wrap: wrap;
          padding: 15px 20px 5px;
          box-sizing: border-box;

          .preview-item_col {
            margin-bottom: 10px;
          }
        }

        &_col {
          flex: none;
          width: 50%;
          box-sizing: border-box;
        }

        &_label {
          margin-bottom: 2px;
          color: #909399;
          font-size: 12px;
        }

        &_value {
          color: #606266;

          @include respond-to($phone) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
