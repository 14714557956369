<template lang="pug">
  .form-wrapper.login-page(v-if="!getTokenFromUrl()")
    .icon-wrapper
      i.icon.icon-sam-bot
      i.icon.icon-lamp
    .form-container
      form.form(data-element='form-login', @submit.native.prevent="submitLogin")
        h1.form-title Welcome to pear.ai
        p.form-text Enter your email address to login
        .form-fields
          .form-field
            .el-input.input-login(:class='{error: emailError && fields.email.dirty}')
              input#field-email.el-input__inner(v-model.lazy='email',
                @input="handleInputChange",
                v-validate='"required|email"',
                :data-vv-delay='700',
                type='text',
                name='email',
                placeholder='Enter a valid email address',
                tabindex='1',
                @keydown.enter='submit')
              button.button-clear(v-if='errors.first("email") && email.length > 0 || wrongCredentials || disabledUser',
                type='button',
                @click='clearEmail')
              label Email
            .form-error(v-if='emailError && fields.email.dirty') {{ emailError }}
            .form-error(v-else-if='wrongCredentials && fields.email.dirty') The email and password combination is incorrect. Please try again.
            .form-error(v-else-if='disabledUser && fields.email.dirty') This account is disabled.
          .form-field
            .el-input.input-password(:class='{error: passwordError}')
              input#field-password.el-input__inner(v-model='password',
                v-validate='"required"',
                :data-vv-delay='700',
                type='password',
                name='password',
                placeholder='Enter 8 or more characters or digits',
                tabindex='2',
                @keydown.enter='submit')
              button.button-clear(v-if='errors.first("password") && password.length > 0 || wrongCredentials || disabledUser',
                type='button',
                @click='clearPassword')
              label Password
            .form-error(v-if='passwordError') {{ passwordError }}
            router-link.form-link(:to='{name: "forgot"}') Forgot your password?
        .form-actions
          el-button#button-submit.button-login(type='primary',
            :disabled="email.length === 0 || submitting",
            @click.native='submitLogin',
            tabindex='3') Login
          div.divider(v-if="isSsoVisible")
            span OR
          div.constellation-text(v-if="isSsoVisible")
            p Constellation customers can use their Constellation Energy Manager account to access Pear.ai.&nbsp;
            router-link(:to='{name: "learn-more"}') Learn more
          el-button.constellation-button(@click="loginClick" v-if="isSsoVisible")
            .button-content
              i.icon.icon-constellation
              span Continue with Constellation account
</template>

<script>
import _ from 'lodash'
import { MsalClient } from '@/lib'
import { auth } from '@/api'
import { cond, equals } from 'ramda'
import { mapActions } from 'vuex'
import store from '../../store'
export default {
  data() {
    return {
      email: '',
      password: '',
      wrongCredentials: false,
      disabledUser: false,
      submitting: false,
      redirectAfterAuth: '',
    }
  },
  computed: {
    emailError() {
      return this.errors.has('email')
        ? 'Please enter a valid email address.'
        : null
    },
    passwordError() {
      return this.errors.has('password') ? 'Please enter a password.' : null
    },
    isSsoVisible() {
      if (window.location.host === 'app.pear.ai') {
        return this.$route.query.sso === '1'
      }
      return true
    },
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      loadHierarchy: 'locations/loadHierarchy',
      setCustomerInfo: 'customers/setCustomerInfo',
    }),
    async afterLogin() {
      const customerId = store.getters['customers/currentCustomerId']
      this.redirectAfterAuth =
        localStorage.getItem('redirectAfterAuth') || `/${customerId}/home`
      if (localStorage.getItem('redirectAfterAuth')) {
        localStorage.removeItem('redirectAfterAuth')
      }
      return this.$router.push(this.redirectAfterAuth)
    },
    async loginClick() {
      const client = new MsalClient()
      const id_token = await client.login()
      const { token, user } = await auth.loginAzureAd(id_token)
      this.setCustomerInfo({ user })
      await this.login({ jwt: token, user })
      await this.afterLogin()
    },
    getTokenFromUrl() {
      const result = window.location.hash
        .substr(1)
        .split('&')
        .reduce(function (result, item) {
          const [first, last] = item.split('=')
          result[first] = last
          return result
        }, {})
      return _.get(result, 'id_token', null)
    },
    handleInputChange() {
      this.disabledUser = false
      this.wrongCredentials = false
    },
    submitLogin() {
      this.submitting = true
      auth
        .login(this.email, this.password)
        .then(({ token, user }) => {
          this.setCustomerInfo({ user })
          return this.login({ jwt: token, user })
        })
        .then(
          () => {
            this.afterLogin()
          },
          (err) => {
            cond([
              [
                equals(401),
                () => {
                  this.wrongCredentials = true
                },
              ],
              [
                equals(403),
                () => {
                  this.disabledUser = true
                },
              ],
            ])(err.response.data.code)
          },
        )
        .finally(() => {
          this.submitting = false
        })
    },
    submit(e) {
      e.preventDefault()
      this.submitLogin()
    },
    clearEmail() {
      this.email = ''
      this.wrongCredentials = false
    },
    clearPassword() {
      this.password = ''
      this.wrongCredentials = false
    },
  },
  async mounted() {
    const id_token = this.getTokenFromUrl()

    if (id_token) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      })
      try {
        const { token, user } = await auth.loginAzureAd(id_token)
        await this.login({ jwt: token, user })
        this.afterLogin()
      } finally {
        loading.close()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/utilities';

.form-link {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}

.form-field {
  margin-bottom: 24px;
}

.form-actions {
  margin-top: 0;
  .button-login {
    margin-right: 0;
    max-width: 100%;
  }
}

.constellation-text {
  margin-bottom: 12px;
  p {
    display: inline;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #909399;
  }

  a {
    cursor: pointer;
    color: #0080ff;
  }
}

.constellation-button {
  padding: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  width: 100%;

  .button-content {
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #606266;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include respond-to($tablet-l) {
      font-size: 12px;
    }

    @include respond-to($phone) {
      font-size: 12px;
    }

    i {
      margin-right: 10px;
    }
  }
}

.divider {
  margin: 0 0 24px 0;
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  &::after,
  &::before {
    display: flex;
    content: ' ';
    height: 1px;
    background: #1c1c1c;
    flex-grow: 2;
  }

  > span {
    margin: 0 16px;
  }
}
</style>
