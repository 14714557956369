<template lang="pug">
.bills-container(v-loading.fullscreen.body.lock="loading", data-element="bills-list-mobile")
  .page-header
    el-input.page-mobile-search(placeholder="Search by Billing ID",
                                ref="searchInput",
                                size="medium",
                                v-model="filters.query")
      i.el-input__icon.el-icon-search(slot="prefix")
    .total-bills
      .total-bills-title Qty of bills:
      .total-bills-count {{ total || 'N/A' }}
  .bill-item(v-for="bill in bills", :key="bill.id", @click="handleClick(bill.id)")
    .bill-item_content
      .bill-item_col
        .bill-item_label Invoice Date
        .bill-item_value {{ bill.statementDate | datetime('LL/dd/yyyy') }}
      .bill-item_col
        .bill-item_label Vendor
        .bill-item_value {{ getPrettyVendorByCode(bill.vendor) }}
      .bill-item_col
        .bill-item_label Billing ID
        .bill-item_value {{ bill.clientAccount }}
      .bill-item_col
        .bill-item_label Amount Due
        .bill-item_value {{ bill.amountDue | currency }}
      .bill-item_col
        .bill-item_label Billing Start Date
        .bill-item_value {{ bill.billingStartDate | datetime('LL/dd/yyyy') }}
      .bill-item_col
        .bill-item_label Billing End Date
        .bill-item_value {{ bill.billingEndDate | datetime('LL/dd/yyyy') }}
      .bill-item_col
        .bill-item_label Current Charges
        .bill-item_value {{ bill.currentCharges | currency }}
      .bill-item_col(v-if="customer.entitlements.payments")
        .bill-item_label Marked For Payment
        .bill-item_value {{ bill.markedForPayment | datetime('LL/dd/yyyy') }}
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      total: 'bills/total',
      loading: 'bills/loading',
      bills: 'bills/bills',
      filters: 'bills/filters/filters',
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
      customer: 'customers/customer',
    }),
  },
  mounted() {
    this.$refs.searchInput.$refs.input.addEventListener(
      'keyup',
      debounce((event) => {
        this.$changeFilters({ searchQuery: event.target.value })
      }, 700),
    )
  },
  methods: {
    ...mapActions({
      $changeFilters: 'bills/filters/changeFilters',
    }),
    handleClick(billId) {
      this.$router.push({ name: 'dashboard.bills.info', params: { billId } })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/utilities';

.page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.total-bills {
  display: flex;
  align-items: center;
  font-size: 12px;

  &-title {
    margin-right: 10px;
    margin-left: 10px;
    color: #7d89a6;
  }
  &-count {
    font-size: 16px;
    font-weight: 600;
  }
}
.bill {
  &-item {
    margin-bottom: 10px;
    font-weight: 600;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    background-color: #fff;

    &_content,
    &_actions {
      display: flex;
      flex-wrap: wrap;
    }
    &_content {
      padding: 15px 20px 5px;
      box-sizing: border-box;

      .bill-item_col {
        margin-bottom: 10px;
      }
    }
    &_actions {
      border-top: 1px solid #dcdfe6;

      .el-button {
        width: 100%;
        text-decoration: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 0;

        &.download-pdf {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 2px;
        }
        &.download-json {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 2px;
        }
      }
      .bill-item_col {
        + .bill-item_col {
          border-left: 1px solid #dcdfe6;
        }
      }
    }
    &_col {
      flex: none;
      width: 50%;
      box-sizing: border-box;
    }
    &_label {
      margin-bottom: 2px;
      color: #909399;
      font-size: 12px;
    }
    &_value {
      word-break: break-word;
      color: #606266;
    }
  }
}
</style>
