<template>
  <BaseModal
    :dialogVisible="showModal"
    :width="'35%'"
    @close="close"
    @open="open"
    class="add-default-property-use-base-modal"
  >
    <template #content>
      Your property has been created. Would you like to configure property use
      fields for "{{ propertyUseVal }}" now?
    </template>
    <template #footer>
      <el-button class="button-size plain-button" @click="close">
        Cancel
      </el-button>
      <el-button
        class="button-size"
        type="primary"
        @click="openPropertyUsesModal"
      >
        Continue
      </el-button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    propertyUseVal: {
      type: String,
      default: '',
    },
    propertyUsesArr: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
  components: {
    BaseModal,
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    open() {
      this.giveMappableObject = this.propertyUsesArr.find(
        (propertyUse) =>
          propertyUse.propertyUseTypeLabel === this.propertyUseVal,
      )
    },
    openPropertyUsesModal() {
      this.$emit('open-property-uses', this.giveMappableObject)
    },
  },
}
</script>

<style lang="scss" scoped>
.add-default-property-use-base-modal {
  .button-size {
    width: 50%;
  }
  .plain-button {
    color: #2893f9;
    border-color: #2893f9;
  }
  ::v-deep .pl-12 {
    padding: 0;
  }
}
</style>
