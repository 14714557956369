const API_HOST = process.env.VUE_APP_API_HOST || 'app-dev.pear.ai'
const API_V1_HOST = process.env.VUE_APP_API_V1_HOST || 'app-dev.pear.ai'
const API_V3_HOST = process.env.VUE_APP_API_V3_HOST || API_V1_HOST
const SCHEME = process.env.VUE_APP_SCHEME || 'https'

const production = {
  NODE_ENV: 'production',
  api: {
    host: '"/api"',
    static: '""',
  },
  v1: {
    host: '"/api/v1"',
    static: '""',
  },
  v2: {
    host: '"/api/v2"',
    static: '""',
  },
  v3: {
    host: '"/api/v3"',
    static: '""',
  },
}
const development = {
  NODE_ENV: 'development',
  api: {
    host: `"${SCHEME}://${API_HOST}/api"`,
    static: '"http://localhost:3000"',
  },
  v1: {
    host: `"${SCHEME}://${API_V1_HOST}/api/v1"`,
    static: '"http://localhost:3000"',
  },
  v2: {
    host: `"${SCHEME}://${API_V1_HOST}/api/v2"`,
    static: '"http://localhost:3000"',
  },
  v3: {
    host: `"${SCHEME}://${API_V3_HOST}/api/v3"`,
    static: '"http://localhost:3000"',
  },
}

export default process.env.VUE_APP_NODE_ENV === 'production'
  ? production
  : development
