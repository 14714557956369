<template lang="pug">
.collapse(:class='mode')
  .collapse_header(:class='{ "is-collapsed": isCollapsed }', @click='collapse()')
    slot(name='title')
      span
        b {{ title }}
    i(:class='{"el-icon-arrow-down": !isCollapsed, "el-icon-arrow-right": isCollapsed}')
  .collapse_content(v-if='!isCollapsed')
    slot
</template>

<script>
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => '',
    },
    mode: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      isCollapsed: this.collapsed,
    }
  },
  methods: {
    collapse(isCollapsed = !this.isCollapsed) {
      this.isCollapsed = isCollapsed
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../styles/_utilities';

.collapse {
  width: 100%;
  margin-bottom: 30px;

  &_header {
    position: relative;
    display: flex;
    height: 30px;
    flex-wrap: wrap;
    padding: 0 35px;
    height: 30px;
    line-height: 16px;
    color: #fff;
    border-radius: 6px 6px 0 0;
    background-color: #026fda;
    cursor: pointer;

    &.is-collapsed {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    > span {
      flex: 0 1 auto;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-right: 50px;

      @include respond-to($phone) {
        margin-right: 0;
      }

      &:nth-last-child(2) {
        margin-right: 0;
      }
      > span {
        margin-right: 15px;
      }
    }
    i {
      position: absolute;
      top: 8px;
      left: 10px;
    }

    @include respond-to($phone) {
      padding-left: 15px;
      padding-right: 15px;

      i {
        left: auto;
        right: 10px;
      }
    }
  }
  &_content {
    display: flex;
    flex-wrap: wrap;
  }

  &.service:not(.inner) {
    > .collapse_header {
      @include respond-to($phone) {
        flex-direction: column;
        font-size: 14px;

        > span {
          &:first-child {
            padding-bottom: 4px;
          }
          + span {
            padding-top: 0;
          }
        }

        i {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &.inner {
    margin-bottom: 10px;

    .collapse_header {
      justify-content: flex-start;
      padding: 9px 35px;
      color: #3b3e48;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;

      b {
        font-size: 14px;
        margin-left: 0;
      }
      i {
        position: static;
        margin-top: 10px;
        margin-left: 15px;
        color: #000;
        font-size: 10px;
      }

      @include respond-to($phone) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}
</style>
