<template>
  <div class="content-inner" v-loading="loading">
    <list v-if="!$deviceInfo.isMobileDevice" />
    <list-mobile v-else />
    <el-pagination
      class="pagination-container"
      background
      v-if="total > pagination.pageSizes[0]"
      layout="prev, pager, next, sizes"
      :page-size="+pagination.limit"
      :page-sizes="pagination.pageSizes"
      :current-page="currentPage"
      @current-change="changeOffset"
      @size-change="changeLimit"
      :total="total"
      :disabled="loading"
    />
  </div>
</template>

<script>
import FilterContainer from './Filters/FilterContainer'
import ListMobile from './ListMobile.vue'
import List from './List.vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
export default {
  components: {
    FilterContainer,
    List,
    ListMobile,
  },
  computed: {
    ...mapGetters({
      total: 'bills/total',
      loading: 'bills/loading',
      pagination: 'bills/filters/pagination',
      currentPage: 'bills/filters/currentPage',
    }),
  },
  destroyed() {
    if (this.$route.name !== 'dashboard.bills.info') {
      this.$resetFilters()
    }
  },
  data() {
    return {
      excludeArr: ['extra', 'filters', 'total'],
    }
  },
  methods: {
    ...mapActions({
      $resetFilters: 'bills/filters/resetFilters',
    }),
    ...mapMutations({
      setExclude: 'bills/set_exclude',
    }),
    changeLimit(limit) {
      this.setExclude(this.excludeArr)
      this.$router.push({
        name: 'dashboard.bills',
        query: { ...this.$route.query, offset: 0, limit: +limit },
      })
    },
    changeOffset(page) {
      this.setExclude(['extra', 'filters', 'total'])
      const offset = (page - 1) * this.pagination.limit
      this.$router.push({
        name: 'dashboard.bills',
        query: { ...this.$route.query, offset },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/utilities';
.content-inner {
  padding-right: 40px;
  padding-left: 40px;
  @include respond-to($phone) {
    padding: 15px 10px;
    max-height: calc(100% - 30px);
    -webkit-overflow-scrolling: touch;
  }
  @include respond-to($phone-l) {
    padding: 15px 10px;
    max-height: calc(100% - 30px);
    -webkit-overflow-scrolling: touch;
  }
}
</style>

<style lang="scss">
@import '../../../styles/components/_pagination';
</style>
