import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'
import { hosts } from '@/lib/api.configs'

/**
 * @public
 * @param {number} customerId
 * @param {string} message
 * @param {boolean} silent
 */
async function sendMessage({ customerId, message, silent }) {
  return http(hosts.v3)
    .post(`/customers/${customerId}/sam/message`, { message, silent: !!silent })
    .then(dataMiddleware)
}

/**
 * @public
 * @description Resets conversation context
 * @param {number} customerId
 */
async function resetContexts(customerId) {
  return http(hosts.v3)
    .post(`/customers/${customerId}/sam/resetContexts`)
    .then(dataMiddleware)
}

/**
 * @public
 * @param {number} customerId
 * @param {number} id Offset message id
 * @param {number} limit
 */
async function getMessages({ customerId, id, limit }) {
  return http(hosts.v3)
    .get(`/customers/${customerId}/sam/messages`, {
      params: { customer_id: customerId, id, limit },
    })
    .then(dataMiddleware)
}

/**
 * @public
 * @description Lists customer's locations
 * @param {number} customerId
 * @param {string} query
 * @param {Object} after
 * @param {number} limit
 */
async function getLocations({
  customerId,
  query = '',
  after = null,
  limit = 1000,
}) {
  return http(hosts.v1)
    .get(`/customers/${customerId}/locations`, {
      params: {
        customer_id: customerId,
        query,
        after,
        limit,
        orders: ['+address', '+id'],
      },
    })
    .then(dataMiddleware)
}

export { sendMessage, resetContexts, getMessages, getLocations }
