import { DateTime } from 'luxon'
import _ from 'lodash'

const timePeriodMap = {
  all: 'All time',
  period: 'Custom',
  year_to_date: 'Year to-date',
  months: (count) => `Last ${count} month${count > 1 ? 's' : ''}`,
}

function filterDescription(params) {
  let { filterType, widgetFilters, widgetMeta, locations } = params
  let description = ''
  let startMonth
  let endMonth
  const filter = widgetFilters[filterType]
  switch (filterType) {
    case 'year':
      description = _.get(widgetFilters, [filterType, filterType], '')
      break
    case 'time_period':
      let type = _.get(filter, ['type'], '')
      if (_.isEmpty(filter) || type === 'all') {
        return timePeriodDescription(type, widgetMeta, [
          ['date_range', 'min'],
          ['date_range', 'max'],
        ])
      } else if (type === 'period') {
        return (
          timePeriodDescription(type, filter, [
            ['period', 'start_date'],
            ['period', 'end_date'],
          ]) ||
          timePeriodDescription(type, filter, [['start_date'], ['end_date']])
        )
      } else if (type === 'months') {
        const count =
          _.get(filter, ['months'], null) || _.get(filter, ['count'], null)
        if (count) {
          if (count === 1) {
            startMonth = DateTime.fromMillis(Date.now(), { zone: 'utc' })
              .minus({ months: count })
              .startOf('month')
              .toFormat('MMM yyyy')
            return `${timePeriodMap.months(count)} (${startMonth})`
          }
          startMonth = DateTime.fromMillis(Date.now(), { zone: 'utc' })
            .minus({ months: 1 })
            .startOf('month')
            .toFormat('MMM yyyy')
          endMonth = DateTime.fromMillis(Date.now(), { zone: 'utc' })
            .minus({ months: count })
            .toFormat('MMM yyyy')
            .toString()
          return `${timePeriodMap.months(count)} (${endMonth} - ${startMonth})`
        }
      } else if (type === 'year_to_date') {
        const startMonth = DateTime.fromMillis(Date.now(), { zone: 'utc' })
          .startOf('year')
          .toFormat('MMM yyyy')
        const endMonth = DateTime.fromMillis(Date.now(), { zone: 'utc' })
          .toFormat('MMM yyyy')
          .toString()
        return `${timePeriodMap.year_to_date} (${startMonth} - ${endMonth})`
      }
      break
    case 'utility_type':
      description = _.get(filter, [filterType], '')
      break
    case 'utility_type_list':
      const list = _.get(filter, ['list'], [])
      if (_.isEmpty(list)) {
        description = 'All utilities'
      } else {
        description = list.join(', ')
      }
      break
    case 'location_id':
      const locationIds = _.get(filter, ['location_ids'], [])
      if (_.isEmpty(locationIds)) {
        description = 'All locations'
      } else {
        const address = locationIds.map(function (id) {
          const loc = _.find(locations, (l) => +l.id === +id)
          return _.get(loc, 'address', '')
        })
        description = address.join(', ').slice(0, 40) + '...'
      }
      break
  }
  return description
}

function timePeriodDescription(type, src, path) {
  const dates = []
  const startDate = _.get(src, path[0], null)
  if (startDate) {
    dates.push(
      DateTime.fromISO(String(startDate), { zone: 'utc' }).toFormat('MMM yyyy'),
    )
  }
  const endDate = _.get(src, path[1], null)
  if (endDate) {
    dates.push(
      DateTime.fromISO(String(endDate), { zone: 'utc' }).toFormat('MMM yyyy'),
    )
  }
  if (_.isEmpty(dates)) return _.get(timePeriodMap, type, '')
  return `${timePeriodMap[type]} (${dates.join(' - ')})`
}

export default {
  filterDescription,
}
