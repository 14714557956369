import { createReports } from '@/api'

export default {
  namespaced: true,
  state: {
    availableFilters: []
  },
  getters: {
    availableFilters: (state) => state.availableFilters
  },
  mutations: {
    setTemplateAvailableValues(state, payload) {
      state.availableFilters = payload
    },
    resetAvailableFilters: (state) => {
      state.availableFilters = []
    }
  },
  actions: {
    async getTemplateAvailableValues({ commit, state }, { customerId, templateId }) {
      try {
        const templateAvailableValues = await createReports.getTemplatesAvailableValues(templateId, customerId)
        commit('setTemplateAvailableValues', templateAvailableValues.data.availableFilters)
      } catch (e) {
        console.log(e)
      }
    },
  }
}
