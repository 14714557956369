<template>
  <BaseModal
    :dialogVisible="showModal"
    :title="title"
    :width="'35%'"
    @open="open"
    @close="close"
    class="choose-property-use-base-modal"
  >
    <template #content>
      <el-form label-position="top">
        <BaseSelect
          :isEditMode="true"
          name="property"
          label="Select a property function bellow:"
          size="medium"
          v-model="propertyUseVal"
          @change="changePropertyUse"
        >
          <el-option
            v-for="(k, v, i) in propertyArr"
            :key="i"
            :value="v"
            :label="k"
          />
        </BaseSelect>
      </el-form>
    </template>
    <template #footer>
      <el-button class="button-size plain-button" @click="close">
        Cancel
      </el-button>
      <el-button
        :disabled="!propertyUseVal"
        class="button-size"
        type="primary"
        @click="openPropertyUsesModal"
      >
        Continue
      </el-button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import BaseSelect from '@/components/FormComponents/BaseSelect'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    propertyName: {
      type: String,
      default: '',
    },
    propertyUsesArr: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      propertyArr: {
        collegeUniversity: 'College/University',
        dataCenter: 'Data Center',
        k12School: 'K-12 School',
        hotel: 'Hotel',
        multifamilyHousing: 'Multi Family Housing',
        nonRefrigeratedWarehouse: 'Non-Refrigerated Warehouse',
        office: 'Office',
        other: 'Other',
        residenceHallDormitory: 'Residence Hall / Dormitory',
        retail: 'Retail Store',
        supermarket: 'Supermarket / Grocery Store',
        worshipFacility: 'Worship Facility',
        hospital: 'Hospital (General Medical & Surgical)',
        parking: 'Parking',
        convenienceStoreWithGasStation: 'Convenience Store with Gas Station',
        convenienceStoreWithoutGasStation:
          'Convenience Store without Gas Station',
      },
      propertyUseVal: '',
      showModal: false,
      title: '',
    }
  },
  components: {
    BaseModal,
    BaseSelect,
  },

  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    open() {
      this.title = `Add Property Use for ${this.propertyName}`
    },
    openPropertyUsesModal() {
      this.$emit('open-property-uses', this.giveMappableObject)
    },
    changePropertyUse() {
      this.giveMappableObject = this.propertyUsesArr.find(
        (propertyUse) =>
          propertyUse.propertyUseTypeName === this.propertyUseVal,
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.choose-property-use-base-modal {
  .button-size {
    width: 50%;
  }
  .plain-button {
    color: #2893f9;
    border-color: #2893f9;
  }
  ::v-deep .pl-12 {
    padding: 0;
  }
}
</style>
