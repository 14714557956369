<template>
  <div class="comment-item" :class="{ 'customer-comment': userRole }">
    <div
      class="comment-item_name"
      :title="`${name.firstName} ${name.lastName}`"
    >
      {{ name.firstName }} {{ name.lastName }}
    </div>
    <div class="comment-item_date">{{ createdAt }}</div>
    <div
      class="comment-item_content"
      v-for="(row, index) in textRows"
      :key="index"
      v-html="row"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import * as R from 'ramda'
import { DateTime } from 'luxon'
export default {
  computed: {
    createdAt: function () {
      return DateTime.fromISO(_.get(this.comment, 'createdAt', {})).toFormat(
        'L/dd/yy hh:mma',
      )
    },
    textRows: function () {
      const itemsFound = this.items.filter((item) =>
        this.comment.text.includes(item.key),
      )
      return R.path(['text'], this.comment)
        .split('[#newline#]')
        .map((textRow) => {
          textRow = textRow.replace(
            /\[#reasoning#\](.*?)\[#reasoning#\]/,
            '<span class="reason">$1</span>',
          )
          if (itemsFound.length === 1 && itemsFound && itemsFound[0].key) {
            textRow = textRow.replace(
              itemsFound[0].key,
              `<span class="reason">${itemsFound[0].key}</span>`,
            )
          } else if (itemsFound.length > 1) {
            itemsFound.forEach((item) => {
              if (item && item.key) {
                textRow = textRow.replace(
                  item.key,
                  `<span class="reason">${item.key}</span>`,
                )
              }
            })
          }
          return textRow
        })
    },
    name: function () {
      return R.pathOr('N/A', ['user', 'profile'], this.comment)
    },
    userRole() {
      return (
        this.comment &&
        this.comment.user &&
        this.comment.user.roles.includes('web_admin')
      )
    },
  },
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../styles/utilities';

.customer-comment {
  text-align: end;
}

.comment-item {
  margin-bottom: 20px;
  font-size: 14px;

  &:nth-child(2) {
    margin-bottom: 29px;
  }
  &.is-system {
    .comment-item_name {
      color: $primary;
    }
  }

  &_name {
    font-weight: bold;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &_date {
    margin: 5px 0;
    color: #9b9b9b;
    font-size: 10px;
  }
  &_content {
    color: #222222;
    word-break: break-word;
    white-space: pre-wrap;
    ::v-deep .reason {
      color: #0080ff;
    }
  }
}
</style>
