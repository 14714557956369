<template lang="pug">
  .widget-filter-show-container(v-if="!isEmpty", @click.stop="()=>{}")
    .filters-main-title(v-if="!isEditFilterOpen") {{ title }}
      ul.filters-list(v-for="(filter, filterType) in widgetFilters")
        li
          span.filter-edit.icon.icon-edit(@click="editFilter(filterType)", :data-action="`edit-${filterType}`")
          span.filter-type {{ filterTitle(filterType) }}:
          span.filter-description {{ filterDescription(filterType) }}
    .filter-edit(v-if="isEditFilterOpen")
      .filters-main-title.edit-filter-title {{ filterTitle(currentFilter) }}
      filter-year(v-if="openEditFilter.year" ,
        :filter="filters.year",
        :value="defaultYear")
      filter-time-period.filter-column(v-if="openEditFilter.time_period",
        @validate="validateTimePeriod"
        :filter="filters.time_period",
        :value="defaultTimePeriod")
      filter-locations(v-if="openEditFilter.location_id",
        :filter="filters.location_id",
        :value="defaultLocations")
      filter-utility-type(v-if="openEditFilter.utility_type",
        :commodities="commodities",
        :filter="filters.utility_type",
        :value="defaultUtilityType")
      filter-utility-type-list(v-if="openEditFilter.utility_type_list",
        :filter="filters.utility_type_list",
        :commodities="commodities",
        :value="defaultUtilityTypeList")
      el-button.button-update-filter(@click="updateFilter"
        :disabled="openEditFilter.time_period && twoDatesNotSelected",
        type="primary",
        size="small",
        round) Update
      el-button.button-back(@click="closeFilter", type="text") Back
</template>

<script>
import * as R from 'ramda'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import FilterTimePeriod from './Desktop/FilterTimePeriod.vue'
import FilterYear from './Desktop/FilterYear.vue'
import FilterLocations from './Desktop/FilterLocations.vue'
import FilterUtilityType from './Desktop/FilterUtilityType.vue'
import FilterUtilityTypeList from './Desktop/FilterUtilityTypeList.vue'
import { dashboard } from '@/api'
import { widgets } from '@/lib'

export default {
  props: {
    widgetFilters: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: 'Selected Filters',
    },
    widgetType: {
      type: String,
      required: true,
    },
  },
  beforeMount() {
    this.getWidgetMeta()
  },
  data() {
    return {
      filterMap: {
        year: {
          title: 'Year',
        },
        time_period: {
          title: 'Time Period',
        },
        location_id: {
          title: 'Locations',
        },
        utility_type: {
          title: 'Utility Type',
        },
        utility_type_list: {
          title: 'Utility Types',
        },
      },
      openEditFilter: {
        year: false,
        time_period: false,
        location_id: false,
        utility_type: false,
        utility_type_list: false,
      },
      currentFilter: '',
      filters: {
        year: {},
        time_period: {},
        utility_type: {
          utility_type: 'ELECTRIC',
        },
        utility_type_list: {},
        location_id: {},
      },
      twoDatesNotSelected: false,
      widgetMeta: {},
    }
  },
  components: {
    FilterTimePeriod,
    FilterLocations,
    FilterUtilityType,
    FilterUtilityTypeList,
    FilterYear,
  },
  watch: {
    widgetFilters() {
      this.getWidgetMeta()
      this.closeFilter()
    },
  },
  computed: {
    ...mapGetters({
      locations: 'locations/locations',
      customerId: 'customers/currentCustomerId',
    }),
    isEmpty() {
      return _.isEmpty(this.widgetFilters)
    },
    isEditFilterOpen() {
      return _.some(this.openEditFilter, (el) => el)
    },
    defaultYear: {
      cache: false,
      get: function () {
        return _.get(this.widgetFilters, ['year'], {
          year: new Date().getFullYear(),
        })
      },
    },
    defaultLocations: {
      cache: false,
      get: function () {
        return _.get(this.widgetFilters, ['location_id', 'location_ids'], [])
      },
    },
    defaultTimePeriod: {
      cache: false,
      get: function () {
        return _.get(this.widgetFilters, ['time_period'], [])
      },
    },
    defaultUtilityType: {
      cache: false,
      get: function () {
        return _.get(
          this.widgetFilters,
          ['utility_type', 'utility_type'],
          'ELECTRIC',
        )
      },
    },
    defaultUtilityTypeList: {
      cache: false,
      get: function () {
        return _.get(this.widgetFilters, ['utility_type_list', 'list'], [])
      },
    },
    commodities() {
      return _.get(this.widgetMeta, 'utility_type_list', [])
    },
  },
  methods: {
    validateTimePeriod(valid) {
      this.twoDatesNotSelected = !valid
    },
    editFilter(filterType) {
      this.openEditFilter[filterType] = true
      this.currentFilter = filterType
      this.$emit('open-edit-filter', filterType)
    },
    removeFilter() {
      this.$emit('remove-filter')
      this.closeAllEditFilter()
    },
    closeFilter() {
      this.closeAllEditFilter()
      this.$emit('close-filter')
    },
    updateFilter() {
      this.closeAllEditFilter()
      this.getWidgetMeta()
      this.$emit('update-filter', {
        [this.currentFilter]: this.filters[this.currentFilter],
      })
    },
    closeAllEditFilter() {
      _.forEach(this.openEditFilter, (value, key) => {
        this.openEditFilter[key] = false
      })
    },
    filterTitle(filterType) {
      return R.pathOr(filterType, [filterType, 'title'], this.filterMap)
    },
    filterDescription(filterType) {
      const { widgetFilters, widgetMeta, locations } = this
      return widgets.filterDescription({
        filterType,
        widgetFilters,
        widgetMeta,
        locations,
      })
    },
    getWidgetMeta() {
      const { customerId, widgetType } = this
      if (_.isEmpty(widgetType)) {
        return
      }
      dashboard.getWidgetMeta({ customerId, widgetType }).then((meta) => {
        this.widgetMeta = meta
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-filter-show-container {
  padding-top: 5px;

  .filters-main-title {
    margin-bottom: 22px;
    color: #2c2e34;
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
  }

  .button-back {
    text-decoration: underline;
  }

  ::v-deep .filter-row {
    flex-direction: row !important;
    flex-wrap: wrap;

    .selected-locations {
      flex-basis: 100%;
      line-height: 20px;
    }
  }

  ::v-deep .filter-column {
    flex-direction: column;
  }

  .remove-filter-container {
    text-align: right;

    .remove-filter {
      padding-top: 0;
      padding-bottom: 0;
      line-height: 27px;
      cursor: pointer;
      text-decoration: underline;
      /*color: #FE2800;*/
    }
  }

  ::v-deep .el-button {
    max-width: 160px;
  }

  .filter-item {
    display: flex;
    flex-direction: column;
  }

  ::v-deep .filter-item-location {
    flex-direction: row;
    flex-wrap: wrap;

    .el-button {
      width: 160px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px;
      font-weight: 900;
      background: #0080ff;
    }
  }

  .button-update-filter,
  .button-close-filter {
    width: 160px;
    margin: 25px 15px 25px 0;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 900;
    background: #0080ff;
  }

  .filters-list {
    list-style: none;
    margin: 7px 0 7px -5px;
    padding-left: 0;
    &:first-child {
      margin-top: 20px;
    }

    li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: baseline;
    }

    .icon-edit {
      cursor: pointer;
    }

    .filter-type {
      color: #4a4a4a;
      font-size: 14px;
      font-weight: 900;
      line-height: 22px;
      white-space: nowrap;
    }

    .filter-description {
      padding-left: 5px;
      color: #484748;
      font-size: 14px;
      line-height: 25px;
      font-weight: 500;
    }

    .filter-edit {
      margin-right: 9px;

      ::v-deep .el-radio-group {
        margin-bottom: 25px;
      }
    }
  }

  .filter-edit {
    ::v-deep .el-radio-group {
      margin-bottom: 30px;
    }
  }
}
</style>
