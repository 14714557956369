<template>
  <BaseModal
    class="energy-star-historical-scores-modal"
    v-if="showModal"
    :dialogVisible="showModal"
    :title="title"
    @close="close"
  >
    <template #content>
      <div class="row">
        <el-form ref="form" label-width="120px">
          <label>Calendar Year</label>
          <BaseSelect
            label=""
            labelWidth="0"
            v-model="crtYear"
            name="propertyType"
            size="small"
            rules="required"
            :isEditMode="true"
            @change="handleChange"
          >
            <el-option
              v-for="item in historicalYears"
              :key="item"
              :label="item"
              :value="item"
            />
          </BaseSelect>
        </el-form>
      </div>
      <div
        class="el-table reports-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
      >
        <div class="el-table__header-wrapper">
          <table
            cellspacing="0"
            cellpadding="0"
            border="0"
            class="el-table__header"
          >
            <colgroup>
              <col name="el-table_1_column_1" />
              <col name="el-table_1_column_2" />
            </colgroup>
            <thead class="">
              <tr class="">
                <th class="el-table_1_column_1 is-leaf">
                  <div class="cell">Month</div>
                </th>
                <th class="el-table_1_column_2 is-leaf">
                  <div class="cell">ENERGY STAR® Score (1-100)</div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="el-table__body-wrapper is-scrolling-none">
          <table
            cellspacing="0"
            cellpadding="0"
            border="0"
            class="el-table__body"
          >
            <colgroup>
              <col name="el-table_1_column_1" />
              <col name="el-table_1_column_2" />
            </colgroup>
            <tbody>
              <tr
                class="el-table__row"
                v-for="(score, key) in historicalScores"
                :key="key"
              >
                <td class="el-table_1_column_1">
                  <div class="cell">
                    <span>{{ formatDate(score.date) }}</span>
                  </div>
                </td>
                <td class="el-table_1_column_2">
                  <div class="cell text-right">
                    <template v-if="score.score !== false">
                      {{ score.score }}
                    </template>
                    <template v-else>
                      <el-button
                        type="text"
                        @click="getNoScoreReasons(score.date)"
                      >
                        <span>N/A</span>
                      </el-button>
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="controls">
        <el-button type="primary" @click="close">OK</el-button>
      </div>
    </template>
  </BaseModal>
</template>
<script>
import BaseModal from '@/components/BaseModal'
import BaseSelect from '@/components/FormComponents/BaseSelect'
import { DateTime } from 'luxon'
export default {
  components: {
    BaseModal,
    BaseSelect,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: 'ENERGY STAR® Historical Scores',
    },
    historicalScores: {
      type: Array,
      default: () => [],
    },
    year: {
      type: String,
      default: '',
    },
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
    year(val) {
      if (!this.yearsChanged) {
        this.historicalYears = []
        for (let i = 0; i < 5; i++) {
          this.historicalYears.push(val - i)
        }
        this.yearsChanged = true
        this.crtYear = val
      }
    },
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      yearsChanged: false,
      historicalYears: [],
      crtYear: '',
    }
  },
  async mounted() {
    this.loading = false
  },
  async beforeUpdate() {
    this.loading = false
  },
  methods: {
    close() {
      this.crtYear = this.historicalYears[0]
      this.$emit('close', 'showEnergyStarHistoricalScoresReasons')
    },
    formatDate(date) {
      return DateTime.fromFormat(date, 'yyyy-MM', { zone: 'utc' }).toFormat(
        'MMM yyyy',
      )
    },
    getNoScoreReasons(date) {
      this.$emit('getNoScoreReasons', date)
    },
    handleChange() {
      this.$emit('getHistoricalScores', this.crtYear)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../../../styles/utilities';
.energy-star-historical-scores-modal {
  ::v-deep {
    .el-dialog {
      width: 25vw;
      min-width: 400px;
      &__body {
        color: #000000;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 0.8em;
      }
      &__title {
        color: #000000;
        font-size: 1.4em;
        font-weight: bold;
      }
    }

    .el-table {
      &__header,
      &__body {
        width: 100%;
      }
    }

    .el-button--text {
      padding: 0;
    }

    .pl-12 {
      padding: 0;
    }
  }

  .controls {
    button {
      width: 170px;
    }
  }

  .text-right {
    text-align: right;
  }
}
</style>
