import { hosts } from '@/lib/api.configs'
import { http } from '@/lib'
import { dataMiddleware } from '@/lib/http'
import * as R from 'ramda'
import { DateTime } from 'luxon'
import qs from 'qs'

const yearMonth = (v) => R.propOr('', 'paymentFileDate', v).substr(0, 7)
const groupMonth = R.groupBy(yearMonth)

/**
 * @description group data by month
 * @param {array} payments
 */

const groupByMonth = (payments) =>
  R.pipe(
    groupMonth,
    R.values,
  )(payments)
    .sort((a, b) => {
      const monthA = DateTime.fromISO(String(a[0].paymentFileDate), {
        zone: 'utc',
      }).toObject().month
      const monthB = DateTime.fromISO(String(b[0].paymentFileDate), {
        zone: 'utc',
      }).toObject().month

      return monthB - monthA
    })
    .map((e) => {
      return {
        title: DateTime.fromISO(String(e[0].paymentFileDate), {
          zone: 'utc',
        }).toFormat('MMMM yyyy'),
        total: e.reduce((a, b) => (b.sum ? a + b.sum : a + 0), 0),
        data: (e || []).map((e) => ({
          ...e,
          date: DateTime.fromISO(String(e.paymentFileDate), {
            zone: 'utc',
          }).toFormat('LL/dd/yyyy'),
        })),
        paymentFileDate: e[0].paymentFileDate,
      }
    })

/**
 * @description get payments list
 * @param {string} customerId
 * @param {string} year
 */
const list = ({ customerId, params }) =>
  http(hosts.v3)
    .get(
      `/payments/payments-list/${customerId}?${qs.stringify(params, {
        arrayFormat: 'repeat',
      })}`,
    )
    .then(dataMiddleware)
    .then((data) => groupByMonth(data))
/**
 * @description get report by id
 * @param {string} customerId
 * @param {string} date
 */
const getPaymentBills = ({ customerId, date }) =>
  http(hosts.v3)
    .get(`/payments/payments-details/${customerId}/${date}`)
    .then(dataMiddleware)
/**
 * @description get report by id
 * @param {string} customerId
 * @param {string} date
 * @param {string} type
 */
const exportFile = ({ customerId, date, params }) =>
  http(hosts.v3)
    .get(
      `/payments/download-payment-file/${customerId}/${date}?${qs.stringify(
        params,
        { arrayFormat: 'repeat' },
      )}`,
    )
    .then(dataMiddleware)

/**
 * @description get payments list
 * @param {string} customerId
 * @param {string} year
 */

const datesList = ({ customerId }) =>
  http(hosts.v3)
    .get(`/payments/payment-dates/${customerId}`)
    .then(dataMiddleware)

export { list, exportFile, getPaymentBills, datesList }
