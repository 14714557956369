<template>
  <div class="map-locations-container" data-type="location-hierarchy">
    <el-input
      class="location-search"
      placeholder="Search locations"
      prefix-icon="el-icon-search"
      :value="filterText"
      @input="filterValueChange"
    />
    <el-checkbox
      class="checkbox"
      :value="showOnlySelectedLocations"
      @change="showOnlySelectedLocChange"
    >
      Show only Selected Locations
    </el-checkbox>
    <div class="tree">
      <el-tree
        ref="tree"
        node-key="id"
        :data="hierarchies"
        :props="defaultProps"
        :filter-node-method="filterNode"
        show-checkbox
        :default-checked-keys="defaultSelectedLocations"
        :expand-on-click-node="false"
        @change.native="getCheckedKeys"
      >
        <app-el-tree-item
          ref="treeItem"
          v-if="hierarchies.length >= 0"
          :node="node"
          :total-locations="totalLocations"
          :data="data"
          slot-scope="{ node, data }"
        />
      </el-tree>
    </div>
  </div>
</template>

<script>
import AppElTreeItem from './AppElTreeItem'
import { isNil } from 'lodash'

export default {
  components: { AppElTreeItem },
  props: {
    hierarchies: {
      type: Array,
      default: () => [],
    },
    defaultSelectedLocations: {
      type: Array,
      default: () => [],
    },
    filterText: {
      type: String,
      default: '',
    },
    showOnlySelectedLocations: {
      type: Boolean,
      default: false,
    },
    totalLocations: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    filterText(val) {
      this.showOnlySelectedLocChange(false)
      this.$refs.tree.filter(val)
    },
    showOnlySelectedLocations(val) {
      this.$refs.tree.filter(val)
    },
  },
  data() {
    return {
      defaultCheckedKeys: null,
      defaultProps: {
        children: 'children',
        label: 'name',
      },
    }
  },
  methods: {
    filterNode(value, data, node) {
      if (this.showOnlySelectedLocations) {
        return node.checked
      } else {
        node.expanded = false
      }
      if (!value) return true
      let { name, address, city, state, postcode } = data
      if (name || address || city || state || postcode) {
        return (
          (name && name.toLowerCase().match(value.toLowerCase())) ||
          (address && address.toLowerCase().match(value.toLowerCase())) ||
          (city && city.toLowerCase().match(value.toLowerCase())) ||
          (state && state.toLowerCase().match(value.toLowerCase())) ||
          (postcode && postcode.toLowerCase().match(value.toLowerCase()))
        )
      }
      if (data.address) {
        return data.name.toLowerCase().match(value.toLowerCase())
      }
    },
    getCheckedKeys(event) {
      const locationsNodes = this.$refs.tree.getCheckedNodes()
      const locationsIds = locationsNodes
        .map(({ children, id }) => (!children ? +id : undefined))
        .filter((locationId) => !isNil(locationId))
      this.$emit('get-checked-keys', { locationsIds, event })
    },
    filterValueChange(val) {
      this.$emit('filter-val-change', val)
    },
    showOnlySelectedLocChange(val) {
      this.$refs.treeItem.showDropdown = val
      this.$emit('show-selected-locs', val)
    },
  },
}
</script>

<style lang="scss">
.map-locations-container {
  padding-top: 20px;
  .tree {
    max-height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      height: 24px;
      opacity: 0.5;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
  }
  .el-input {
    &__inner {
      height: 30px;
      border-radius: 10px;
      font-size: 12px;
    }
    &__icon {
      line-height: 30px;
    }
  }
  .checkbox {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}
</style>
