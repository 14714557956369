<template lang="pug">
.location-control_container
  .location-control_search
    el-input(v-model="query", prefix-icon="el-icon-search", placeholder="Search")
  .location-control_list
    el-button.location-control_list-item(v-for="(location, index) in filteredLocations",
                                        :class='{[`location-${location.id}`]: true, "is-active": checkSelection(location.id)}',
                                        :key="index",
                                        type="primary",
                                        plain,
                                        @click="handleSelection($event, location)")
      span.location-address {{ location.address }}
      span.location-state {{ location.city }}, {{ removeCountry(location.state) }}
</template>

<script>
import { filter, contains, join, values } from 'ramda'
export default {
  props: ['locations', 'storedLocations'],
  data() {
    return {
      query: '',
      selectedLocations: [],
    }
  },
  mounted() {
    this.storedLocations.forEach(({ id }) => {
      document
        .querySelector(`.location-control_list-item.location-${id}`)
        .classList.add('is-active')
    })
    this.selectedLocations = this.storedLocations
  },
  watch: {
    storedLocations(val = []) {
      this.selectedLocations = this.storedLocations
    },
  },
  computed: {
    filteredLocations() {
      return filter((location) => {
        return contains(
          this.query.toLowerCase(),
          join(' ', values(location)).toLowerCase(),
        )
      }, this.locations)
    },
  },
  methods: {
    checkSelection(locationId) {
      return this.selectedLocations.some((e) => e.id === locationId)
    },
    handleSelection(e, location) {
      const check = (e) => e.id === location.id
      e.currentTarget.classList.toggle('is-active')
      if (this.selectedLocations.some(check)) {
        this.selectedLocations.splice(
          this.selectedLocations.findIndex(check),
          1,
        )
      } else {
        this.selectedLocations.push({
          id: location.id,
          address: location.address,
        })
      }

      this.$emit('select-locations', this.selectedLocations)
    },
    removeCountry(state) {
      return state ? state.replace(/^.*-/g, '') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../../styles/_utilities';
.location-control_container {
  .location-control_search {
    padding: 0 20px 20px;

    @include respond-to($phone) {
      padding-right: 20px;
    }
    ::v-deep .el-input {
      @include placeholder(#909399, 14px, 400);
    }
    ::v-deep .el-input__inner {
      @include respond-to($phone) {
        height: 35px;
        min-height: 35px;
      }
    }
    ::v-deep .el-input__icon {
      color: #909399;

      @include respond-to($phone) {
        line-height: 35px;
      }
    }
  }
  .location-control_list {
    padding: 0 16px 20px 20px;
    max-height: 58vh;
    -webkit-overflow-scrolling: touch;

    @include respond-to($phone) {
      max-height: calc(100vh - 153px);
      padding-right: 20px;
      padding-left: 20px;
      box-sizing: border-box;
      padding-bottom: 200px;
    }

    .el-button {
      display: flex;
      padding: 0;
      border-radius: 5px;
      border-color: #2893f9;

      ::v-deep #{'>'} span {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        text-align: left;
        width: 100%;
        height: 54px;
        padding: 0 28px;
      }
      &:hover {
        > span > span {
          color: #fff;
        }
      }
      &.is-active {
        color: #ffffff;
        background-color: #0080ff;

        .location-address,
        .location-state {
          color: inherit;
        }
      }
    }

    @extend %scrollbar;
  }
  .location-control_list-item {
    display: block;
    width: 100%;
    min-height: 54px;
    background-color: transparent;

    + .location-control_list-item {
      margin: 10px 0 0;
    }
  }
  .location-address {
    color: #2c2e34;
  }
  .location-state {
    margin-top: 5px;
    color: #7d89a6;
    font-size: 10px;
  }
}
</style>
