<template lang="pug">
  .form-wrapper.no-customer-page
    .icon-wrapper
      i.icon.icon-sam-bot
      i.icon.icon-lamp
    .form-container
      form.form(data-element='form-login', @submit.native.prevent="submitLogin")
        .mobile
          .logo
            img(src="./../assets/logo-text.svg")
          .icon.icon-no-customers
        h1.form-title Welcome to pear.ai
        p.form-text You do not have any customers added to your account.
          br
          | For assistance, please contact your administrator or&nbsp;
          a(href="https://success.pear.ai/hc/en-us/requests/new") Pear.ai
          | .
        el-button(type="text", class="btn-logout", @click="logout") Log Out
          .icon.icon-forward
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store
        .dispatch('user/logout')
        .then(() => this.$router.replace('/auth/login'))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/utilities';

.no-customer-page {
  .mobile {
    display: none;
  }

  .form-text {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 33px;
    color: #626262;

    a {
      color: #0080ff;

      &:focus {
        outline: none;
      }
    }
  }

  .btn-logout {
    display: inline-block;
    margin: 100px 0 0 0;
    padding: 0;
    text-align: left;
    color: #0080ff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.09px;
    line-height: 22px;
    .icon {
      display: none;
    }
  }

  @include respond-to($tablet-p) {
    .mobile {
      display: block;
      text-align: center;

      .logo {
        margin-bottom: 30px;
        text-align: left;

        img {
          width: 87px;
          height: 24px;
        }
      }
    }
    .form-container {
      background: none;
    }
    .form {
      margin: 30px 0 50px;
      max-width: 295px;

      &-title {
        margin: 30px 0 85px 0;
        color: #3b3e48;
        font-size: 28px;
        font-weight: 900;
        letter-spacing: 0.16px;
        line-height: 38px;
      }

      &-text {
        color: #909399;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.11px;
        line-height: 25px;
        a {
          color: #909399;
        }
      }
      .btn-logout {
        margin: 0;
        .icon {
          display: inline-block;
          padding-left: 137px;
        }
      }
    }
  }
}
</style>
