<template>
  <div class="location-virtual-accounts">
    <el-table
      class="virtual-accounts-table"
      v-if="!$deviceInfo.isMobileDevice"
      :data="virtualAccounts"
      @sort-change="sortTable"
      :row-class-name="tableRowClassName"
      row-key="id"
    >
      <el-table-column label="Vendor" prop="vendorCode" sortable>
        <template slot-scope="scope">
          <el-tooltip
            :content="getPrettyVendorByCode(scope.row['vendorCode'])"
            placement="top"
            effect="light"
          >
            <span>{{ getPrettyVendorByCode(scope.row['vendorCode']) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Billing ID" prop="clientAccount" sortable>
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.clientAccount"
            placement="top"
            effect="light"
          >
            <span>{{ scope.row.clientAccount }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Service Account ID" prop="accountCode" sortable>
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.accountCode"
            placement="top"
            effect="light"
          >
            <span>{{ scope.row.accountCode }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Meter ID" prop="meterSerial" sortable>
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.meterSerial"
            placement="top"
            effect="light"
          >
            <span>{{ scope.row.meterSerial }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Utility" prop="commodity" sortable>
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.commodity"
            placement="top"
            effect="light"
          >
            <span>{{ scope.row.commodity }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Type" prop="billType">
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.billType"
            placement="top"
            effect="light"
          >
            <span>{{ scope.row.billType }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Last Bill" prop="billType">
        <template slot-scope="scope">
          <a
            class="date-last-bill"
            :href="`/${$route.params.customerId}/bills/${scope.row.lastBillId}`"
          >
            {{ scope.row.lastBillStatementDate | datetime('LL/dd/yyyy') }}
          </a>
        </template>
      </el-table-column>
      <el-table-column label="Open/Closed" width="140">
        <template slot-scope="scope">
          <el-tooltip placement="top" effect="light">
            <template slot="content">
              <span class="account-date-tooltip-label">Account Opened:</span>
              <br />
              <span
                v-if="scope.row.accountOpened"
                class="account-date-tooltip-value"
              >
                {{ scope.row.accountOpened | datetime('MM/dd/yyyy') }}
              </span>
              <span
                v-if="!scope.row.accountOpened"
                class="account-date-tooltip-value"
              >
                {{ 'Unknown' }}
              </span>
              <br />
              <span class="account-date-tooltip-label">Account Closed:</span>
              <br />
              <span class="account-date-tooltip-value">
                {{ scope.row.accountClosed | datetime('MM/dd/yyyy') }}
              </span>
              <span
                v-if="!scope.row.accountClosed"
                class="account-date-tooltip-value"
              >
                {{ 'Unknown' }}
              </span>
            </template>
            <el-row class="account-date-row">
              <el-col class="account-date-info" :span="16">
                <span
                  v-if="
                    (scope.row.accountOpened &&
                      scope.row.accountClosed &&
                      new Date(scope.row.accountClosed).getTime() >
                        new Date().getTime() &&
                      new Date(scope.row.accountOpened).getTime() <
                        new Date().getTime()) ||
                    (scope.row.accountOpened &&
                      scope.row.accountClosed == null &&
                      new Date(scope.row.accountOpened).getTime() <
                        new Date().getTime()) ||
                    (scope.row.accountClosed &&
                      new Date(scope.row.accountClosed).getTime() >
                        new Date().getTime() &&
                      scope.row.accountOpened == null)
                  "
                >
                  Opened
                </span>
                <span
                  v-else-if="
                    (scope.row.accountClosed &&
                      new Date(scope.row.accountClosed).getTime() <
                        new Date().getTime()) ||
                    (new Date(scope.row.accountClosed).getTime() >
                      new Date().getTime() &&
                      scope.row.accountOpened == null)
                  "
                >
                  Closed
                </span>
                <span v-else>Unknown</span>
              </el-col>
              <el-col :span="8">
                <i :class="isMoreDetailsModalActiveIcon(scope.$index)"></i>
              </el-col>
            </el-row>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="scope">
          <el-button
            type="text"
            :class="isMoreDetailsModalActive(scope.$index)"
            @click="
              openBillHistory(scope.row.virtualAccountGroupId, scope.$index)
            "
          >
            View Bill History
          </el-button>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="scope">
          <el-button
            id="more-details"
            type="text"
            :class="isMoreDetailsModalActive(scope.$index)"
            @click="openVirtualAccountDetails(scope)"
          >
            More details
          </el-button>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click.prevent="handleDownload(scope.row.lastBillId)"
          >
            Download
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <template v-else>
      <div
        class="account-item"
        v-for="(account, index) in virtualAccounts"
        :key="index"
      >
        <div class="account-item_content">
          <div class="account-item_col">
            <div class="account-item_label">Vendor</div>
            <div class="account-item_value">
              {{ getPrettyVendorByCode(account.vendorCode) }}
            </div>
          </div>
          <div class="account-item_col">
            <div class="account-item_label">Billing ID</div>
            <div class="account-item_value">{{ account.clientAccount }}</div>
          </div>
          <div class="account-item_col">
            <div class="account-item_label">Service Account ID</div>
            <div class="account-item_value">{{ account.accountCode }}</div>
          </div>
          <div class="account-item_col">
            <div class="account-item_label">Meter ID</div>
            <div class="account-item_value">{{ account.meterSerial }}</div>
          </div>
          <div class="account-item_col">
            <div class="account-item_label">Utility</div>
            <div class="account-item_value">{{ account.commodity }}</div>
          </div>
          <div class="account-item_col">
            <div class="account-item_label">Type</div>
            <div class="account-item_value">{{ account.billType }}</div>
          </div>
          <div class="account-item_col">
            <div class="account-item_label">Last Bill</div>
            <el-row class="account-last-bill-row">
              <el-col class="account-last-bill-info" :span="16">
                <a
                  class="date-last-bill"
                  :href="`/${$route.params.customerId}/bills/${account.lastBillId}`"
                >
                  {{ account.lastBillStatementDate | datetime('LL/dd/yyyy') }}
                </a>
              </el-col>
            </el-row>
          </div>
          <div class="account-item_col">
            <div class="account-item_label">Open/Closed</div>
            <el-row class="account-date-row">
              <el-col class="account-date-info" :span="16">
                <span
                  v-if="
                    (account.accountOpened &&
                      account.accountClosed &&
                      new Date(account.accountClosed).getTime() >
                        new Date().getTime() &&
                      new Date(account.accountOpened).getTime() <
                        new Date().getTime()) ||
                    (account.accountOpened &&
                      account.accountClosed == null &&
                      new Date(account.accountOpened).getTime() <
                        new Date().getTime()) ||
                    (account.accountClosed &&
                      new Date(account.accountClosed).getTime() >
                        new Date().getTime() &&
                      account.accountOpened == null)
                  "
                >
                  Opened
                </span>
                <span
                  v-else-if="
                    (account.accountClosed &&
                      new Date(account.accountClosed).getTime() <
                        new Date().getTime()) ||
                    (new Date(account.accountClosed).getTime() >
                      new Date().getTime() &&
                      account.accountOpened == null)
                  "
                >
                  Closed
                </span>
                <span v-else>Unknown</span>
              </el-col>
              <el-col :span="8"><i class="icon icon-info-circle"></i></el-col>
            </el-row>
          </div>
          <div class="account-item_col">
            <div class="account-item_label"></div>
            <div class="account-item_value">
              <el-button
                type="text"
                @click="openBillHistory(account.virtualAccountGroupId)"
              >
                View Bill History
              </el-button>
            </div>
          </div>
          <div class="account-item_col">
            <div class="account-item_label"></div>
            <el-row class="account-download-row">
              <el-col class="account-download-info" :span="16">
                <el-button
                  type="text"
                  @click.prevent="handleDownload(account.lastBillId)"
                >
                  Download
                </el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </template>
    <el-pagination
      class="pagination-container"
      background
      v-if="pagination.total > pagination.pageSizes[0]"
      layout="prev, pager, next, sizes"
      :page-size="+pagination.limit"
      :page-sizes="pagination.pageSizes"
      :current-page="currentPage"
      @current-change="changeOffset"
      @size-change="changeLimit"
      :total="pagination.total"
      :disabled="loading"
    />
    <el-dialog
      class="bill-history-dialog"
      :visible.sync="billHistoryOpened"
      title="Bill History"
      :lock-scroll="true"
      :fullscreen="customSelectionModalFullscreen"
      :width="modalWidth"
      @close="closeBillHistoryModal"
    >
      <bill-history
        :billHistory="billHistory"
        :customerId="customerId"
        :loading="billHistoryLoading"
      ></bill-history>
    </el-dialog>
    <v-a-more-details
      :dialog-visible="showMoreDetails"
      :virtual-account="virtualAccount"
      @close="closeMoreDetails"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { DateTime } from 'luxon'
import BillHistory from './BillsHistory'
import VAMoreDetails from '../../Modals/VAMoreDetails'
import { isNil } from 'lodash'
import { serializeOrderBySigns } from '../../../../../lib/helpers'
import { bills } from '@/api'

export default {
  components: {
    VAMoreDetails,
    BillHistory,
  },
  props: {
    virtualAccounts: {
      type: Array,
      required: true,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      billHistoryOpened: false,
      newTab: 'virtual_accounts',
      rowIndexScope: null,
      virtualAccount: {},
      showMoreDetails: false,
    }
  },
  filters: {
    dateFormatted(value) {
      if (!value) return ''
      return DateTime.fromISO(value, { zone: 'utc' }).toFormat('LLLL d yyyy')
    },
  },
  computed: {
    ...mapGetters({
      billHistory: 'locations/billHistory',
      billHistoryLoading: 'locations/billHistoryLoading',
      customerId: 'customers/currentCustomerId',
      getPrettyVendorByCode: 'resources/getPrettyVendorByCode',
    }),
    customSelectionModalFullscreen() {
      if (!this.$deviceInfo.isMobileDevice) {
        return false
      }
      return true
    },
    currentPage() {
      return this.pagination.offset / this.pagination.limit + 1
    },
    flagToHighlightTheRow() {
      return this.billHistoryOpened === true || this.showMoreDetails === true
    },
    modalWidth() {
      if (!this.$deviceInfo.isMobileDevice) {
        return '1112px'
      }
      return 'auto'
    },
  },
  methods: {
    ...mapActions({
      setBillHistory: 'locations/setBillHistory',
      loadBillHistory: 'locations/loadBillHistory',
    }),
    openVirtualAccountDetails(virtualAccount) {
      this.virtualAccount = virtualAccount.row
      this.rowIndexScope = virtualAccount.$index
      this.showMoreDetails = true
    },
    closeMoreDetails() {
      this.showMoreDetails = false
    },
    async sortTable({ prop, order }) {
      const { path, query } = this.$route
      if (isNil(prop) || isNil(order)) {
        this.$router.push({
          path: path,
          query: {
            ...query,
            order: undefined,
          },
        })
      } else {
        this.$router.push({
          path: path,
          query: {
            ...query,
            order: [serializeOrderBySigns(prop, order)],
          },
        })
      }
      this.$emit('update-order')
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.flagToHighlightTheRow && rowIndex === this.rowIndexScope) {
        return 'active-va-row'
      }
    },
    isMoreDetailsModalActive(rowIndex) {
      if (this.flagToHighlightTheRow && rowIndex === this.rowIndexScope) {
        return 'row-information'
      }
    },
    isMoreDetailsModalActiveIcon(rowIndex) {
      if (this.flagToHighlightTheRow && rowIndex === this.rowIndexScope) {
        return 'icon icon-info-circle-white'
      }
      return 'icon icon-info-circle'
    },
    async openBillHistory(id, index) {
      this.rowIndexScope = index
      if (!id) return
      this.billHistoryOpened = true
      await this.loadBillHistory({
        locationId: this.$route.params.locationId,
        vaGroupId: id,
      })
    },
    closeBillHistoryModal() {
      this.setBillHistory([])
      this.billHistoryOpened = false
    },
    changeLimit(limit) {
      this.$emit('update-limit', { offset: 0, limit, newTab: this.newTab })
    },
    changeOffset(page) {
      const offset = (page - 1) * +this.pagination.limit
      this.$emit('update-offset', {
        offset,
        limit: +this.$route.query.limit,
        newTab: this.newTab,
      })
    },
    async handleDownload(lastBillId) {
      const { url } = await bills.getPdfDownloadLink({
        customerId: this.customerId,
        billId: lastBillId,
      })
      if (url) {
        const a = window.document.createElement('a')
        a.setAttribute('href', url)
        window.document.body.appendChild(a)
        a.click()
        window.document.body.removeChild(a)
      } else {
        console.error(`GET PDF LINK ERROR: got empty download url`)
      }
    },
  },
}
</script>

<style lang="scss">
.el-tooltip {
  &__popper {
    &.is-light {
      min-width: 140px;
      border-radius: 8px;
      text-align: center;
      padding: 16px;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);

      &.is-light {
        margin-bottom: 6px;
        border-color: #e4e7ed;
      }

      .account-date-tooltip-label {
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
      }

      .account-date-tooltip-value {
        font-size: 14px;
        line-height: 18px;
      }

      .popper__arrow {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import './../../../../../styles/_utilities';
@import './../../../../../styles/components/_pagination';

.location-virtual-accounts {
  ::v-deep.el-dialog {
    border-radius: 10px;
    @include respond-to($phone) {
      border-radius: 0;
    }
    .el-dialog__header {
      padding: 32px;
      @include respond-to($phone) {
        padding-top: 30px;
        padding-bottom: 0;
      }
      .el-dialog__title {
        color: #273a58;
        font-size: 20px;
        font-weight: 900;
        line-height: 33px;
        @include respond-to($phone) {
          margin-left: 0;
        }
      }
    }
  }
  .account-date-row {
    cursor: pointer;
    display: flex;

    .account-date-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .last-bill {
    color: #0080ff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  .el-table {
    &.active-va-row {
      background: #1989fa;
      color: #ffffff;
    }
  }
  ::v-deep.el-table {
    .cell {
      display: flex;
      span.el-tooltip {
        display: flow-root;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        word-break: inherit;
      }
    }
  }
  ::v-deep.virtual-accounts-table {
    td {
      border-bottom: 1px solid #ebeef5;
      cursor: default;
    }

    .el-table {
      &__row {
        &.active-va-row {
          background: #1989fa;
          color: #ffffff;
        }
        &--level-1 {
          background: rgba(151, 151, 151, 0.2);

          &.active-va-row {
            background: #1989fa;
          }

          td {
            border-top: 4px solid #fff;
          }
        }
      }
    }
  }
  .account {
    &-item {
      margin-bottom: 10px;
      font-weight: 600;
      border: 1px solid #dcdfe6;
      border-radius: 2px;
      background-color: #fff;

      &.is-selected {
        border-color: #0080ff;
      }
      &_content,
      &_actions {
        display: flex;
        flex-wrap: wrap;
      }
      &_content {
        padding: 15px 20px 5px;
        box-sizing: border-box;

        .account-item_col {
          margin-bottom: 10px;
        }
      }
      &_col {
        flex: none;
        width: 50%;
        box-sizing: border-box;
      }
      &_label {
        margin-bottom: 2px;
        color: #909399;
        font-size: 12px;
      }
      &_value {
        color: #606266;

        @include respond-to($phone) {
          font-size: 16px;
        }
      }
    }
  }
  .row-information {
    color: #ffffff;
  }
  .date-last-bill {
    color: #0080ff;
    text-decoration: underline;
  }
}
</style>
