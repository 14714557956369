<template lang="pug">
  .payments-details
    el-table(:data="bills", ref="singleTable", @row-click="onRowClick")
      el-table-column(label="Bill ID" prop="billId" sortable="")
        template(slot-scope="scope")
          router-link.internal-link(:to='`/${currentCustomerId}/bills/${scope.row.billId}`') {{ scope.row.billId }}
      el-table-column(prop="dueDate" label="Due Date" sortable="")
        template(slot-scope="scope")
          span {{ scope.row.dueDate | datetime('LL/dd/yyyy') }}
      el-table-column(prop="invoiceDate" label="Invoice Date" sortable="")
        template(slot-scope="scope")
          span {{ scope.row.invoiceDate | datetime('LL/dd/yyyy') }}
      el-table-column(label="Vendor" prop="vendorName" sortable="")
      el-table-column(label="Billing ID" prop="payload.vendorBillingId" :sortable="true" :sort-method="sorter('vendorBillingId')")
        template(slot-scope="scope")
          span {{ scope.row.vendorBillingId }}
      el-table-column(label="Amount Due" prop="payload.totalAmountDue" :sortable="true" :sort-method="sorter('totalAmountDue')")
        template(slot-scope="scope")
          span {{ scope.row.totalAmountDue | unit('USD') }}
      el-table-column(label="A/P Request" v-if="isPaymentsEntitlement" prop="apRequest" :sortable="true" :sort-method="sorter('apRequest')")
        template(slot-scope="scope")
          span {{ scope.row.apRequest | unit('USD') }}
      //el-table-column(label="Amount Paid" v-if="isBillPayEntitlement" prop="amountPaid" :sortable="true" :sort-method="sorter('amountPaid')")
        template(slot-scope="scope")
          span {{ scope.row.amountPaid | unit('USD') }}
      //el-table-column(label="Status" v-if="isBillPayEntitlement" prop="paymentStatus" :sortable="true" :sort-method="sorter('paymentStatus')" width="210px")
        template(slot-scope="scope")
          span(:class="billStatusClass(scope.row.paymentStatus)" class="pill") {{ getReadableStatus(scope.row.paymentStatus) }}
</template>

<script>
import { mapGetters } from 'vuex'
import { csiStatusesForIndividualBills } from './helpers'

export default {
  props: {
    bills: {
      type: Array,
      required: true,
      default: () => [],
    },
    currentCustomerId: {
      type: Number,
      required: true,
      default: () => '',
    },
  },
  data() {
    return {
      apRequest: 0,
      amountPaid: 0,
      status: '',
    }
  },
  computed: {
    ...mapGetters({
      isBillPayEntitlement: 'customers/isBillPayEntitlement',
      isPaymentsEntitlement: 'customers/isPaymentsEntitlement',
    }),
  },
  methods: {
    sorter(key) {
      return (a, b) => {
        return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0
      }
    },
    onRowClick() {
      // Disable default row selection.
      this.$refs.singleTable.setCurrentRow()
    },
    billStatusClass(statusIndex) {
      return 'status__' + csiStatusesForIndividualBills[statusIndex]
    },
    getReadableStatus(status) {
      return status ? status.replaceAll('_', ' ') : '-'
    },
  },
}
</script>

<style lang="scss">
@import './../../../styles/_utilities';
@import './../../../styles/bill-statuses';

.internal-link {
  color: #409eff;
}
</style>
