<template>
  <div class="error-card" v-if="statusMessage">
    <h4 v-if="title" class="error-card-header">{{ title }}</h4>
    <p class="error-card-content">{{ statusMessage }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: [String],
      default: '',
    },
    statusMessage: {
      type: [String, Array],
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.error-card {
  background: #ffefef;
  margin-bottom: 2em;
  padding: 2px 16px;
  &-header {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #f86259;
  }
  &-content {
    font-size: 12px;
    line-height: 16px;
    color: #f86259;
  }
}
</style>
