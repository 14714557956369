<template lang="pug">
  .filter-item-inner
    el-input(v-model="minValue",
      type="number",
      placeholder="Min",
      :data-action="`filter-range-${filterName}`")
    span to
    el-input(v-model="maxValue",
      type="number",
      placeholder="Max",
      :data-action="`filter-range-${filterName}-mobile`")
</template>

<script>
import _ from 'lodash'
import { isNil } from 'ramda'

export default {
  name: 'FilterRange',
  props: {
    filterName: { type: String },
    onChange: { type: Function, required: true },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 0 },
  },
  data() {
    return {
      minValue: null,
      maxValue: null,
    }
  },
  watch: {
    min: {
      immediate: true,
      handler: function (val) {
        if (+val === +this.minValue) {
          return
        }
        this.minValue = val || null
      },
    },
    max: {
      immediate: true,
      handler: function (val) {
        if (+val === +this.maxValue) {
          return
        }
        this.maxValue = val || null
      },
    },
    minValue: _.debounce(function () {
      this.onChangeRange(`${this.filterName}_min`, 'minValue')
    }, 700),
    maxValue: _.debounce(function () {
      this.onChangeRange(`${this.filterName}_max`, 'maxValue')
    }, 700),
  },
  methods: {
    onChangeRange(filterName, prop) {
      if (isNil(this[prop])) {
        this[prop] = null
      }
      this.onChange({ [filterName]: this[prop] })
    },
  },
}
</script>
