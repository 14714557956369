<template lang="pug">
  .network-error
    .network-error--title We're sorry!
    .network-error--content
      p
        span This page cannot be loaded. Please
        el-button(type="text" @click="refresh") refresh
        span the page or try again later
      p
        span If the problem persists, please contact your Pear.ai customer success representative.
</template>

<script>
export default {
  methods: {
    refresh() {
      this.$emit('refresh')
    },
  },
}
</script>

<style lang="scss" scoped>
.network-error {
  text-align: center;
  max-width: 1104px;
  padding-top: 90px;
  letter-spacing: 0.01em;
  color: #3b3e48;

  &--title {
    margin: 0 0 6px 0;
    font-size: 24px;
    font-weight: 700;
  }

  &--content {
    font-size: 18px;

    p {
      margin: 0 0 7px 0;
    }
  }

  .el-button {
    margin: 0 5px;

    ::v-deep span {
      font-size: 18px;
    }
  }
}
</style>
