<template>
  <BaseModal :dialogVisible="showModal" @close="close" :width="'500px'">
    <template #content>
      <ErrorCard :statusMessage="createError" />
      <p class="message" v-if="isBudgetingListTab">
        Are you sure you want to delete this budget? You will not be able to
        download the budget if you delete it.
      </p>
      <p class="message" v-else-if="reportId != ''">
        Are you sure you want to clear this completed report from the list?
      </p>
      <p class="message" v-else>
        Are you sure you want to clear all completed reports from the list?
      </p>
    </template>
    <template #footer class="dialog-footer-center">
      <el-button class="button-size plain-button" @click="close">No</el-button>
      <el-button
        class="button-size"
        type="primary"
        @click="removeReport"
        :loading="isLoading"
      >
        Yes
      </el-button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import ErrorCard from '@/components/Cards/ErrorCard'
import { completedReports } from '@/api'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    reportId: {
      type: String,
      default: '',
    },
    isBudgetingListTab: {
      type: Boolean,
      default: false,
    },
    reportType: {
      type: String,
      default: 'Report',
    },
  },
  components: {
    BaseModal,
    ErrorCard,
  },
  data() {
    return {
      showModal: false,
      createError: '',
      isLoading: false,
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
  },
  methods: {
    close() {
      this.$emit('close')
      this.createError = ''
    },
    async removeReport() {
      const { customerId } = this.$route.params
      try {
        let body
        if (this.reportId) {
          body = { reportIds: [this.reportId] }
        }
        await completedReports.removeReportList({ customerId, body })
        this.isLoading = false
        this.createError = ''
        this.$emit('reload-data')
        this.$message({
          showClose: true,
          message: `${this.reportType} cleared successfully.`,
          type: 'success',
        })
        this.close()
      } catch (e) {
        this.createError = e.response.data.message
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.button-size {
  width: 35%;
}
.plain-button {
  color: #2893f9;
  border-color: #2893f9;
}
.message {
  margin: 0;
  text-align: center;
  word-break: break-word;
}
.bold {
  font-weight: bold;
}
</style>
