import _ from 'lodash'

export const getTokenFromUrl = () => {
  const result = window.location.hash
    .substr(1)
    .split('&')
    .reduce(function (result, item) {
      const [first, last] = item.split('=')
      result[first] = last
      return result
    }, {})
  return _.get(result, 'id_token', null)
}

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const getSsoAuthErrorMessage = (error) => {
  const statusCode = _.get(error, 'response.status', null)
  switch (statusCode) {
    case 422:
      return 'Your Constellation Energy Manager account is not linked to any Pear.ai account. Please log in with your Pear.ai email.'
    case 403:
      return 'Account is disabled'
    case 500:
      return 'An unexpected error occurred, please try again later.'
    default:
      return ''
  }
}
