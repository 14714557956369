<template lang="pug">
  .feed-block
    .feed-block_body
      el-table(:data="list", :default-sort = "{prop: 'date', order: 'ascending'}", size="medium", width="100%")
        el-table-column(label="Date", prop="date", width="200")
          template(slot-scope="scope")
            .weather-cell
              span {{ scope.row.date }}
              .icon(:class="`icon-weather-${scope.row.icon}`")
        el-table-column(label="Min Temp (F)", prop="temperature_min", width="150")
        el-table-column(label="Max Temp (F)", prop="temperature_max", width="150")
        el-table-column(label="HDD", prop="heating", width="150")
        el-table-column(label="CDD", prop="cooling")
    .show-more(v-if="block.length > 10", @click="collapsed = !collapsed")
      span(v-if="collapsed") Show more
        i.el-icon-arrow-down
      span(v-else) Show less
        i.el-icon-arrow-up
    .feed-block_total
      .item.bold Total
      .item
        label Monthly HDD:
        span.value.bold {{ monthlyHDD | total }}
        label HDD Settlement Cost:
        span.value.bold {{ monthlyHDD * settlementCost  | unit('USD') }}
      .item
        label Monthly CDD:
        span.value.bold {{ monthlyCDD | total }}
        label CDD Settlement Cost:
        span.value.bold {{ monthlyCDD * settlementCost  | unit('USD') }}
</template>

<script>
import * as R from 'ramda'
import { mapState } from 'vuex'

export default {
  props: {
    block: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      collapsed: true,
      settlementCost: 20,
    }
  },
  filters: {
    total: function (value) {
      return Number(value).toFixed(2)
    },
  },
  computed: {
    ...mapState({
      currentCustomerId: R.pathOr('', ['customers', 'currentCustomerId']),
    }),
    list() {
      return this.collapsed ? this.block.slice(0, 9) : this.block.slice()
    },
    monthlyHDD() {
      return this.block.reduce((acc, v) => acc + Number(v.heating || 0), 0)
    },
    monthlyCDD() {
      return this.block.reduce((acc, v) => acc + Number(v.cooling || 0), 0)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../../styles/utilities';
.feed-block {
  .feed-block_total {
    display: flex;
    margin: 10px 0;
    padding: 10px 40px;

    .item {
      box-sizing: border-box;

      &:first-child {
        width: 335px;
      }

      &:nth-child(2) {
        padding-right: 50px;
      }

      label {
        padding: 0 5px 0 15px;
      }
    }
  }

  .weather-cell {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 15px;
    }
  }

  .show-more {
    margin: 10px 0;
    text-align: center;
    color: #909399;
    cursor: pointer;

    i {
      padding: 0 5px;
    }
  }

  .feed-block_body {
    background-color: #f7f8fa;

    ::v-deep {
      .el-table {
        th,
        td {
          &:first-child {
            padding-left: 25px;
          }

          &:last-child {
            font-size: 14px;
            padding-right: 15px;
          }
        }

        td {
          cursor: initial;
          background-color: #f4f6fa !important;
        }
      }
    }
  }

  .feed-block_total {
    border-bottom: solid 1px #d3d7de;
  }
}
</style>
