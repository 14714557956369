<template lang="pug">
  .node-container(v-if="!isEmpty", :class="{mobile: $deviceInfo.isMobileDevice}")
    .node-name {{ name }}
    .hierarchy-breadcrumbs
      .hierarchy-breadcrumbs-item(v-for="{name, id} in hierarchyBreadcrumbs", :key="id")
        span.name {{ name }}
        span.nav >
    .node-info(v-if="node.name")
      .node-info-item
        .name Direct Groups
        .value {{ directGroup }}
      .node-info-item
        .name Direct Child Locations
        .value {{ directChildren }}
      .node-info-item
        .name Nested Groups
        .value  {{ nestedGroups }}
      .node-info-item
        .name Nested Locations
        .value  {{ nestedLocations }}
      .node-info-item(v-if="!node.parentId")
        .name Unassigned Locations
        .value  {{ unassignedLocations }}
    .location-view(v-else)
      router-link(:to='`/${currentCustomerId}/locations/${node.location.id}`') View Location Details
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  props: {
    unMatchedLocations: {
      type: Number,
      required: false,
      default: () => {},
    },
    node: {
      required: false,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      hierarchy: 'locations/hierarchy',
      currentCustomerId: 'customers/currentCustomerId',
    }),
    name() {
      return (
        _.get(this.node, ['location', 'name'], null) ||
        _.get(this.node, ['name'], null) ||
        ''
      )
    },
    directGroup() {
      return _.get(this.node, ['children'], []).filter((i) => i && i.name)
        .length
    },
    directChildren() {
      return _.get(this.node, ['children'], []).filter(
        (i) => !!_.get(i, ['location', 'name'], null),
      ).length
    },
    nestedGroups() {
      return this.hierarchy.filter(
        (i) =>
          _.get(i, ['name'], null) &&
          _.get(i, ['path'], []).includes(this.node.id),
      ).length
    },
    nestedLocations() {
      return this.hierarchy.filter(
        (i) =>
          _.get(i, ['location', 'name'], null) &&
          _.get(i, ['path'], []).includes(this.node.id),
      ).length
    },
    unassignedLocations() {
      return this.unMatchedLocations
    },
    hierarchyBreadcrumbs() {
      const hierarchy = _.get(this, 'hierarchy', []).slice()
      return [
        ..._.get(this.node, 'path', []).reduce(
          (acc, item) => [...acc, hierarchy.find((h) => h.id === item)],
          [],
        ),
        { id: this.node.id, name: this.name },
      ]
    },
    isEmpty() {
      return _.isEmpty(this.node)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './../../../../styles/_utilities';

.node-container {
  height: 100%;
  padding: 16px 30px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 4px #edeff4;
  box-sizing: border-box;
}

.hierarchy-breadcrumbs {
  margin-top: 10px;
  margin-left: 0;
  min-height: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  color: #7d89a6;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;

  @include respond-to($phone) {
    margin-left: 24px;
  }

  &-item {
    .name,
    .nav {
      margin-right: 6px;
    }

    &:last-child {
      .nav {
        display: none;
      }
    }
  }
}

.node-name {
  color: #2c2e34;
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
}

.node-info {
  &-item {
    display: flex;
    flex-direction: row;
    color: #4a4a4a;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin: 16px 0;

    .name {
      flex-basis: 240px;
    }

    .value {
      font-weight: 900;
      color: #2893f9;
    }
  }
}

.location-view {
  margin: 16px 0;

  a {
    color: #0080ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-decoration: none;
  }
}

.mobile {
  &.node-container {
    padding: 0;
    box-shadow: none;
  }

  .hierarchy-breadcrumbs {
    margin-left: 0;
  }

  .node-name {
    font-size: 28px;
    color: #273a58;
    line-height: 38px;
  }
}
</style>
